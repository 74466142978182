import React from 'react';
import { useHistory } from 'react-router';
import ClickArea from 'shared/components/ClickArea';
import Loading from 'shared/components/Loading';
import { tablesConfigs } from '../configs';

const Lists = ({ data, fetch, type, changeOrder, order }) => {
  const history = useHistory();
  if (fetch) return <Loading className='mt-5' />;
  if (!data || !data.length)
    return <div className='text-black-50 text-center mt-4'>No Results Found</div>;

  const table = tablesConfigs[type].table;

  const handleOrderChange = item => {
    if (!item.key) return;
    changeOrder({
      orderKey: item.key,
      order: !order.order,
    });
  };

  const onItemClick = item => {
    history.push(`/reports/${type}/edit/${item.id}`);
  };

  return (
    <div className='users-lists with-scroll'>
      <ul className='p-0'>
        <li className='d-flex list-header align-items-center'>
          {table.map((item, index) => (
            <ClickArea
              key={item.name + index}
              className={`d-flex text-body justify-content-between align-items-center ${
                item.className
              }`}
              onClick={handleOrderChange.bind(null, item)}
            >
              {item.name}
              {item.key && order.orderKey === item.key && (
                <button
                  className={`btn ml-2 p-0 dropdown-toggle ${order.order ? '' : 'rotate-180'}`}
                />
              )}
            </ClickArea>
          ))}
        </li>
        {data &&
          data.map(item => {
            return (
              <li
                key={item.id}
                className='d-flex align-items-center list-item pointer'
                onClick={onItemClick.bind(null, item)}
              >
                {table.map(row => {
                  return (
                    <div key={row.key} className={row.className}>
                      {item[row.key]}
                    </div>
                  );
                })}
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default Lists;
