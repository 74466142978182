import React, { useState } from 'react';
import { IconEdit, IconCheck } from './Icons';

const EditBox = ({
  type = 'text',
  label,
  value,
  name,
  placeholder,
  className,
  onSubmit,
  access,
}) => {
  const [field, setField] = useState(value || '');
  const [editMode, setEditMode] = useState(false);

  const editOrSubmitHandle = () => {
    if (editMode && value !== field) onSubmit({ value: field, name });
    setEditMode(val => !val);
  };

  return (
    <div
      className={`blue-box d-flex justify-content-between align-items-center ${className || ''}`}
    >
      <div className='d-flex flex-fill align-items-center col-11 p-0'>
        {label && (
          <div className='col-3 pl-0'>
            <b>{label}</b>
          </div>
        )}
        {editMode ? (
          <input
            type={type}
            placeholder={placeholder}
            value={field}
            onChange={e => setField(e.target.value)}
          />
        ) : (
          <span>{value || '-'}</span>
        )}
      </div>
      {access && (
        <div className='pointer' tabIndex='-1' role='button' onClick={editOrSubmitHandle}>
          {editMode ? <IconCheck /> : <IconEdit />}
        </div>
      )}
    </div>
  );
};

export default EditBox;
