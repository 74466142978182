import React from 'react';
import ClickArea from 'shared/components/ClickArea';
import Loading from 'shared/components/Loading';
import ListActionButtons from 'shared/components/ListActionButtons';
import { hasAccess } from 'utils/permissionHelper';

const headers = [
  {
    name: 'Name',
    className: 'col d-flex justify-content-between align-items-center',
    key: 'name',
  },
  {
    name: 'DICOM Types',
    className: 'col d-flex align-items-center',
    key: '',
  },
  {
    name: 'Step Count',
    className: 'col d-flex align-items-center',
    key: 'steps',
  },
  {
    name: 'Actions',
    className: 'p-2 d-flex action-buttons-container',
  },
];

const SubscriptionsLists = ({
  list,
  order,
  changeOrder,
  fetch,
  onItemRemoveConfirm,
  onItemRemoveDisabled,
  onItemExport,
  onItemExportDisabled,
}) => {
  if (fetch) return <Loading className='mt-5' />;

  if (!list || !list.length)
    return <div className='text-black-50 text-center mt-4'>No Results Found</div>;

  const handleOrderChange = item => {
    if (!item.key) return;
    changeOrder({
      key: item.key,
      order: !order.order,
    });
  };

  const getImageTypes = steps => {
    if (!steps || !steps.length) return '-';
    const items = {};
    let result = '';
    steps.forEach(step => {
      step.frames?.forEach(frame => {
        if (!items[frame.imageType]) {
          items[frame.imageType] = true;
          result += result ? `, ${frame.imageType}` : frame.imageType;
        }
      });
    });
    return result;
  };

  const actions = [
    {
      type: 'link',
      icon: 'view',
      title: 'View',
      to: item => `/protocol/${item.id}/view`,
    },
    {
      type: 'link',
      icon: 'edit',
      to: item => `/protocol/${item.id}/edit`,
      hide: () => !hasAccess('protocol_create'),
    },
    // {
    //   type: 'button',
    //   icon: 'export',
    //   title: 'Export',
    //   disabled: onItemExportDisabled,
    //   onClick: item => onItemExport(item),
    // },
    {
      type: 'link',
      icon: 'copyAndCreate',
      title: 'Copy & Create',
      to: item => `/protocol/${item?.id}/edit`,
      hide: () => !hasAccess('protocol_create'),
    },
    {
      type: 'button',
      icon: 'delete',
      title: 'Remove',
      disabled: onItemRemoveDisabled,
      onClick: item => onItemRemoveConfirm(item),
      hide: () => !hasAccess('protocol_create'),
    },
  ];

  return (
    <div className='users-lists'>
      <ul className='p-0'>
        <li className='d-flex list-header align-items-center'>
          {headers.map((item, index) => (
            <ClickArea
              key={item.name + index}
              className={`text-body d-flex align-items-center ${item.className}`}
              onClick={handleOrderChange.bind(null, item)}
            >
              {item.name}
              {item.key && order.orderKey === item.key && (
                <button
                  className={`btn ml-2 p-0 dropdown-toggle ${order.order ? '' : 'rotate-180'}`}
                />
              )}
            </ClickArea>
          ))}
        </li>
        {list &&
          list.map(item => (
            <li key={item.id} className='d-flex list-item'>
              <div className='d-flex col align-items-center'>{item.name}</div>
              <div className='col d-flex align-items-center'>{getImageTypes(item.steps)}</div>
              <div className='col d-flex align-items-center'>{item.steps?.length}</div>
              <div className='p-2 d-flex justify-content-start align-items-center action-buttons-container'>
                <ListActionButtons actions={actions} item={item} />
              </div>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default SubscriptionsLists;
