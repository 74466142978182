import React from 'react';
import { bindDuration } from 'utils/appHelpers';

const VideoContent = ({ data }) => {
  return (
    !!data.episodes?.length && (
      <div className='my-4'>
        <p className='text-blue text-lg mb-0 fw-700'>Chapters</p>
        <div>
          {data.episodes &&
            data.episodes.map((item, i) => {
              return (
                <div
                  key={i}
                  className='d-flex align-items-center justify-content-between py-3 border-bottom'
                >
                  <div>{item.title}</div>
                  <div className='text-secondary small'>{bindDuration(item.startFrom)}</div>
                </div>
              );
            })}
        </div>
      </div>
    )
  );
};

export default VideoContent;
