import { confirmAlert } from 'react-confirm-alert';

const lessonErrors = {
  no_info: '• Lesson description',
  video_episodes: '• Lesson video episodes',
  no_video: '• Lesson video file',
  no_pdf: '• Lesson pdf file',
  no_img: '• Lesson image file',
  study_tech: '• Lesson study tech',
  cases: '• Lesson cases',
};

export const checkLessonForPublishing = lesson => {
  const errors = [];
  const isVideoLesson = Number(lesson.contentType) === 1;
  const isCaseLesson = Number(lesson.contentType) === 3;
  if (!lesson.info && !lesson.description) errors.push(lessonErrors.no_info);
  if (isVideoLesson && !lesson.episodes?.length) errors.push(lessonErrors.video_episodes);
  if (isVideoLesson && !lesson.uploadContentId && !lesson.contentFile && !lesson.contentUrl)
    errors.push(lessonErrors.no_video);
  if (Number(lesson.contentType) === 2 && !lesson.uploadContentId && !lesson.contentFile)
    errors.push(lessonErrors.no_pdf);
  if (Number(lesson.contentType) === 4 && !lesson.uploadContentId && !lesson.contentFile)
    errors.push(lessonErrors.no_img);
  if (isCaseLesson && !lesson.studyTech) errors.push(lessonErrors.study_tech);
  if (isCaseLesson && !lesson.cases?.length) errors.push(lessonErrors.cases);
  return {
    isValid: !errors.length,
    errors,
    message: errors.join('\n'),
  };
};

export const showLessonInvalidAlert = message => {
  confirmAlert({
    title:
      'The following fields are missing. Please, fill them in, in order to publish the lesson.',
    message,
    buttons: [{ label: 'OK' }],
    overlayClassName: 'error-message-alert',
  });
};
