/* eslint-disable eqeqeq */
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Api } from 'utils/connectors';
import { useSnackbar } from 'notistack';
import { getError } from 'utils/appHelpers';
import { IconClose, IconPairArrow } from 'shared/components/Icons';
import Loading from 'shared/components/Loading';
import Select from 'react-select';

const DevicePairModal = ({ close, onSuccess, initialState, prevSelectedMaster }) => {
  const isEdit = !!initialState;
  const { enqueueSnackbar } = useSnackbar();
  const [fetch, setFetch] = useState(false);
  const [devices, setDevices] = useState([]);
  const [form, setForm] = useState(
    initialState ? initialState : { masterDeviceId: 0, clientDeviceId: 0 },
  );

  const swapDevices = () => {
    setForm({ masterDeviceId: form.clientDeviceId, clientDeviceId: form.masterDeviceId });
  };

  const handleChange = (name, data) => {
    setForm({ ...form, [name]: data.id });
  };

  const getDevices = async () => {
    try {
      const params = { page: 1, limit: 1000 };
      const { data } = await Api.get(`/devices/all`, { params });
      const body = data.data.results.map(item => {
        item.title = `${item.deviceName} ${item.deviceID}`;
        return item;
      });
      setDevices(body);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onFormSubmit = async () => {
    try {
      setFetch(true);
      await Api.post('/devices/pair-devices', form);
      enqueueSnackbar('Successfuly done', { variant: 'success' });
      setFetch(false);
      onSuccess();
      close();
    } catch (err) {
      setFetch(false);
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const customStyles = {
    placeholder: (provided, state) => ({
      ...provided,
      fontWeight: 300,
      color: '#dddddd',
    }),
  };

  useEffect(() => {
    getDevices();
    //eslint-disable-next-line
  }, []);

  const isDisabledBtn =
    fetch ||
    !form.masterDeviceId ||
    !form.clientDeviceId ||
    form.masterDeviceId === form.clientDeviceId;

  return (
    <div className='modal-box d-flex flex-column justify-content-between h-auto'>
      <div className='text-right'>
        <button onClick={close} className='btn p-0 modal-close-btn'>
          <IconClose />
        </button>
      </div>
      <div className='m-header text-center'>
        <h5>{isEdit ? 'Edit Device Pairs' : 'Add Device Pairs'}</h5>
        <p>
          {isEdit
            ? 'Swap between Master and Client, or change the pairs.'
            : 'Choose master and client devices to pair'}
        </p>
      </div>
      <div className='user-creation-form my-16'>
        {devices && devices.length ? (
          <ul className='p-0 mb-0 d-flex align-items-center'>
            <div className='col p-0'>
              <div className='col-3 pl-0'>
                <label className='m-0 fz-16 text-blue mb-1'>Master</label>
              </div>
              <li className='d-flex align-items-center pl-2'>
                <Select
                  styles={customStyles}
                  className='multi-select'
                  classNamePrefix='multi-select-pre'
                  isSearchable={true}
                  getOptionLabel={o => o.title}
                  getOptionValue={o => o.id}
                  value={devices.find(i => i.id == form.masterDeviceId) || ''}
                  onChange={handleChange.bind(null, 'masterDeviceId')}
                  options={devices.filter(device => device.id !== form.clientDeviceId)}
                />
              </li>
            </div>
            <div className='mx-3 mt-4'>
              <button className='btn p-0' onClick={swapDevices}>
                <IconPairArrow color={'#131F6B'} />
              </button>
            </div>
            <div className='col p-0'>
              <div className='col-3 pl-0'>
                <label className='m-0 fz-16 text-blue mb-1'>Client</label>
              </div>
              <li className='d-flex align-items-center pl-2'>
                <Select
                  styles={customStyles}
                  className='multi-select'
                  classNamePrefix='multi-select-pre'
                  isSearchable={true}
                  getOptionLabel={o => o.title}
                  getOptionValue={o => o.id}
                  value={devices.find(i => i.id == form.clientDeviceId)}
                  onChange={handleChange.bind(null, 'clientDeviceId')}
                  options={devices.filter(device => device.id !== form.masterDeviceId)}
                />
              </li>
            </div>
          </ul>
        ) : (
          <Loading className='mt-1' />
        )}
      </div>
      <div className='m-footer text-center'>
        <button
          className={`${isDisabledBtn ? 'btn-blue' : 'btn-primary'} btn px-4`}
          disabled={isDisabledBtn}
          onClick={onFormSubmit}
        >
          {isEdit ? 'Save' : 'Add'}
        </button>
      </div>
    </div>
  );
};

export default DevicePairModal;
