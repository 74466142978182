import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';
import Header from './components/Header';
import ReactPaginate from 'react-paginate';
import Lists from './components/Lists';
import { IconArrowLeft, IconArrowRight } from 'shared/components/Icons';
import AdvancedFilters from 'shared/components/filters/AdvancedFilters';
import useFilters from 'shared/hooks/useFilters';

const Entities = () => {
  const [fetch, setFetch] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(0);

  const { requestParams, filters, changeFilter, filterDependency, originalState } = useFilters({
    page: 0,
    order: false,
    orderKey: 'createdAt',
    search: '',
    limit: 8,
  });

  const downloadData = [
    {
      title: 'Export as CSV',
      url: `/entity/entities`,
      fileName: 'entities_list',
      type: 'csv',
      params: requestParams,
    },
  ];

  const getData = async () => {
    try {
      setFetch(true);
      const { data } = await Api.get(`/entity/entities`, { params: requestParams });
      if (data?.data) {
        setPageCount(Math.ceil(data.data.count / 8));
        setData(data.data.results);
      }
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetch(false);
    }
  };

  const onPageChange = ({ selected }) => {
    changeFilter({ page: selected });
  };

  const handleSearchChange = text => {
    changeFilter({ search: text });
  };

  const onChangeOrder = item => {
    if (!item.key) return;
    changeFilter({ order: !filters.order, orderKey: item.key });
  };

  useEffect(() => {
    getData();
  }, [...filterDependency]);

  return (
    <div>
      <Header search={originalState.search} onSearch={handleSearchChange} />
      <div className='py-3 has-header'>
        <AdvancedFilters
          downloadData={downloadData}
          withExport={true}
          filters={[]}
          onClearAll={() => {}}
        />
        <div className='col-12'>
          <Lists list={data} order={filters} changeOrder={onChangeOrder} fetch={fetch} />
        </div>
        {pageCount > 1 && !fetch && (
          <ReactPaginate
            previousLabel={<IconArrowLeft />}
            nextLabel={<IconArrowRight />}
            breakLabel={'...'}
            forcePage={Number(filters.page)}
            breakClassName={'break-me'}
            pageCount={pageCount}
            marginPagesDisplayed={3}
            pageRangeDisplayed={3}
            onPageChange={onPageChange}
            containerClassName={`pagination show-arrow`}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
          />
        )}
      </div>
    </div>
  );
};

export default Entities;
