import React from 'react';
import ClickArea from 'shared/components/ClickArea';
import Loading from 'shared/components/Loading';
import ListActionButtons from 'shared/components/ListActionButtons';
import { hasAccess } from 'utils/permissionHelper';
import { SUBSCRIPTION_STATUS } from 'app/Main/configs';
import ResponsiveImage from 'shared/components/ResponsiveImage';

const headers = [
  {
    name: 'Subscription plan title',
    className: 'col-3 d-flex justify-content-between align-items-center',
    key: 'title',
  },
  {
    name: 'Status',
    className: 'col-1',
    key: 'subscriptionStatus',
  },
  {
    name: 'Content',
    className: 'col-1',
    key: 'totalCourses',
  },
  {
    name: 'Type',
    className: 'col-1',
    key: 'subscriptionType',
  },
  {
    name: 'Validity',
    className: 'col-1',
    key: 'courseDuration',
  },
  {
    name: 'Key Required',
    className: 'col-1',
    key: 'keyRequired',
  },
  {
    name: 'Use',
    className: 'col-1',
    key: 'totalUsers',
  },
  {
    name: 'Faculty',
    className: 'col',
    key: 'totalAuthors',
  },
  {
    name: 'Actions',
    className: 'col',
    key: '',
  },
];

const subsTypes = {
  1: 'Educational',
  4: 'Event Container',
  5: 'Marketing',
  6: 'Product',
};

const SubscriptionsLists = ({
  list,
  order,
  changeOrder,
  fetch,
  onItemRemoveConfirm,
  onItemRemoveDisabled,
}) => {
  if (fetch) return <Loading className='mt-5' />;

  if (!list || !list.length)
    return <div className='text-black-50 text-center mt-4'>No Results Found</div>;

  const handleOrderChange = item => {
    if (!item.key) return;
    changeOrder({
      key: item.key,
      order: !order.order,
    });
  };

  const rowClass = 'col-1 d-flex align-items-center';

  const actions = [
    {
      hide: () => !hasAccess('subscription_create'),
      type: 'link',
      icon: 'edit',
      disable: item => item.isSystem,
      to: item => `/subscription/${item.id}/edit`,
    },
    { type: 'link', icon: 'view', to: item => `/subscription/${item.id}/view` },
    {
      type: 'button',
      icon: 'delete',
      disabled: onItemRemoveDisabled,
      onClick: item => onItemRemoveConfirm(item),
      disable: item => item.isSystem,
      hide: () => !hasAccess('subscription_create'),
    },
  ];

  return (
    <div className='users-lists'>
      <ul className='p-0'>
        <li className='d-flex list-header align-items-center'>
          {headers.map((item, index) => (
            <ClickArea
              key={item.name + index}
              className={`text-body d-flex align-items-center ${item.className}`}
              onClick={handleOrderChange.bind(null, item)}
            >
              {item.name}
              {item.key && order.orderKey === item.key && (
                <button
                  className={`btn ml-2 p-0 dropdown-toggle ${order.order ? '' : 'rotate-180'}`}
                />
              )}
            </ClickArea>
          ))}
        </li>
        {list &&
          list.map(item => (
            <li key={item.id} className='d-flex list-item h-not-set'>
              <div className='d-flex col-3 align-items-center'>
                <ResponsiveImage
                  imgUrl={item.image}
                  alt={item.firstname}
                  className='user-image no-radius mr-2'
                />
                <div>
                  <b>{item.name}</b>
                </div>
              </div>
              <div className={rowClass}>
                {item.status === SUBSCRIPTION_STATUS.active ? 'Active' : 'Inactive'}
              </div>
              <div className={`${rowClass} text-nowrap`}>{`${item.totalCourses} courses`}</div>
              <div className='col-1 d-flex align-items-center'>{subsTypes[item.type] || '-'}</div>
              <div className={`${rowClass} ${!item.expirable ? 'px-2' : ''} text-nowrap`}>
                {item.expirable ? (item.days ? `${item.days} days` : '-') : 'No Expiration'}
              </div>
              <div className='col-1 d-flex align-items-center'>
                {item.keyRequired ? 'Yes' : '-'}
              </div>
              <div className={`${rowClass}`}>{`${item.totalUsers} users`}</div>
              <div className={'text-nowrap col d-flex align-items-center'}>{`${
                item.totalAuthors
              } Faculty`}</div>
              <div className='col d-flex align-items-center p-2 justify-content-start w-100 action-buttons-container'>
                <ListActionButtons actions={actions} item={item} />
              </div>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default SubscriptionsLists;
