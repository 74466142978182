import React, { useState, useEffect, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';
import Header from './components/Header';
import ReactPaginate from 'react-paginate';
import Lists from './components/Lists';
import { IconArrowLeft, IconArrowRight } from 'shared/components/Icons';
import { confirmAlert } from 'react-confirm-alert';
import DevicePairModal from '../Devices/components/DevicePairModal';
import ReactModal from 'react-modal';
import AdvancedFilters from 'shared/components/filters/AdvancedFilters';
import useFilters from 'shared/hooks/useFilters';
import { EVENT_TYPES } from 'configs';

const eventsCustomFilters = [{ name: 'Any assigned', id: -1 }, { name: 'Not assigned', id: -2 }];

const initialFilters = () => {
  return { events: [], events_state: '' };
};

const DevicesPairs = ({ location }) => {
  const searchParams = new URLSearchParams(location.search);
  const searchValue = searchParams.get('search');
  const [removeFetch, setRemoveFetch] = useState(false);
  const [fetch, setFetch] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState([]);
  const [devicePairState, setDevicePairState] = useState(false);
  const [events, setEvents] = useState([]);

  const [pageCount, setPageCount] = useState(0);

  const {
    requestParams,
    filters,
    dropFilters,
    changeFilter,
    changeDropFilter,
    clearDropFilter,
    filterDependency,
    originalState,
  } = useFilters(
    { page: 0, order: false, orderKey: 'createdAt', search: '', limit: 8 },
    { ...initialFilters() },
  );

  const params = {
    search: originalState.search,
    state: 1,
    status: true,
    ...requestParams,
  };

  if (requestParams.events_state) {
    params.events = requestParams.events_state;
  }

  const downloadData = [
    {
      title: 'Export as CSV',
      url: `/devices/all`,
      fileName: 'devices_pairs_list',
      type: 'csv',
      params,
    },
  ];

  const getData = useCallback(async () => {
    try {
      setFetch(true);
      const { data } = await Api.get(`/devices/all`, { params });
      setPageCount(Math.ceil(data.data.count / 8));
      setData(data.data.results);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetch(false);
    }
  }, [enqueueSnackbar, dropFilters, filters.page, ...filterDependency]);

  const getEvents = async () => {
    const params = { type: EVENT_TYPES.workshop };
    try {
      const { data } = await Api.get('/events/all', { params });
      setEvents(data.data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onPageChange = ({ selected }) => {
    changeFilter({ page: selected });
  };

  const handleSearchChange = text => {
    changeFilter({ search: text });
  };

  const onEditDevice = item => {
    setDevicePairState({
      masterDeviceId: item.id,
      clientDeviceId: item.pairedDevice?.id,
    });
  };

  const onChangeOrder = item => {
    if (!item.key) return;
    changeFilter({ order: !filters.order, orderKey: item.key });
  };

  const onItemRemove = async item => {
    try {
      setRemoveFetch(true);
      await Api.post(`/devices/release-devices`, { serviceUUID: item.serviceUUID });
      enqueueSnackbar('Successfully unpaired', { variant: 'success' });
      await getData();
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setRemoveFetch(false);
    }
  };

  const onItemRemoveConfirm = item => {
    confirmAlert({
      title: 'Confirmation Required',
      message: `Are you sure you want to break ${item.deviceName} - ${
        item.pairedDevice?.deviceName
      } pair?
      Be aware that both devices will be unassigned from all events!`,
      buttons: [
        {
          label: 'Cancel',
        },
        {
          label: 'Delete',
          onClick: () => onItemRemove(item),
        },
      ],
    });
  };

  const onFilterChange = (name, id) => {
    const lonelyId = -2;
    changeDropFilter(name, id, lonelyId);
  };

  const onClearAllFilters = () => {
    clearDropFilter();
  };

  const filtersOptions = [
    {
      type: 'dropdown',
      title: 'Events State',
      name: 'events_state',
      data: eventsCustomFilters,
      value: dropFilters.events_state,
      onFilterChange: onFilterChange,
    },
    {
      type: 'multiDropdown',
      title: 'Events',
      name: 'events',
      data: events,
      value: dropFilters.events,
      onFilterChange: onFilterChange,
    },
  ];

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    getEvents();
  }, []);

  return (
    <div>
      <Header
        search={originalState.search || searchValue}
        onSearch={handleSearchChange}
        createNewPair={() => setDevicePairState(true)}
      />
      <div className='py-3 has-header'>
        <AdvancedFilters
          downloadData={downloadData}
          withExport={true}
          filters={filtersOptions}
          onClearAll={onClearAllFilters}
        />
        <div className='col-12'>
          <Lists
            list={data}
            order={filters}
            changeOrder={onChangeOrder}
            fetch={fetch}
            onEditDevice={onEditDevice}
            onItemRemoveConfirm={onItemRemoveConfirm}
            onItemRemoveDisabled={removeFetch}
          />
        </div>
        {pageCount > 1 && !fetch && (
          <ReactPaginate
            previousLabel={<IconArrowLeft />}
            nextLabel={<IconArrowRight />}
            breakLabel={'...'}
            forcePage={Number(filters.page)}
            breakClassName={'break-me'}
            pageCount={pageCount}
            marginPagesDisplayed={3}
            pageRangeDisplayed={3}
            onPageChange={onPageChange}
            containerClassName={`pagination show-arrow`}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
          />
        )}
      </div>
      {!!devicePairState && (
        <ReactModal isOpen={true} className='custom-modal device-pair-modal'>
          <DevicePairModal
            close={() => setDevicePairState(false)}
            onSuccess={getData}
            initialState={devicePairState === true ? null : devicePairState}
          />
        </ReactModal>
      )}
    </div>
  );
};

export default DevicesPairs;
