import { QUIZ_STEP_COLORS } from 'shared/constants';

export const addColorBasedOnKey = (array, key) => {
  const colors = [...QUIZ_STEP_COLORS];
  const colorsObj = {};
  const resultArray = [...array];

  resultArray.forEach(item => {
    if (colorsObj[item[key]]) {
      item.color = colorsObj[item[key]];
    } else {
      colorsObj[item[key]] = colors.shift();
      item.color = colorsObj[item[key]];
    }
  });

  return resultArray;
};
