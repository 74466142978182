import React, { useRef, useState, useEffect } from 'react';
import { Player, BigPlayButton } from 'video-react';
import { IconPlayFill, IconLock, VideoCamIcon, BookMarkIcon } from 'shared/components/Icons';
import { bindDuration, getEpisodeStartTime } from 'utils/appHelpers';
import EmptyView from '../EmptyView';

let checkTime = 0;

const TypeVideo = ({ lesson }) => {
  const [activePart, setActivePart] = useState(lesson.episodes?.length && lesson.episodes[0]);
  const player = useRef(null);

  const videoSettings = {
    src: lesson.contentUrl,
    fluid: false,
    preload: 'auto',
    height: 465,
  };

  const epsiodeTimes = lesson.episodes?.map(item => item.startFrom);

  const autoSelectEpisode = ({ currentTime, seeking }) => {
    const videoTime = Math.ceil(currentTime);
    if (
      !videoTime ||
      videoTime === checkTime ||
      seeking ||
      !lesson.episodes ||
      !lesson.episodes.length
    )
      return;
    let activeKey = 0;
    checkTime = videoTime;
    epsiodeTimes?.forEach((item, key) => {
      if (checkTime >= item) activeKey = key;
    });
    setActivePart(lesson.episodes[activeKey]);
  };

  const handlePartSelect = episode => {
    player.current.seek(episode.startFrom);
    setActivePart(episode);
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const subs = player.current?.subscribeToStateChange(autoSelectEpisode);
    if (lesson.contentUrl) {
      return function cleanup() {
        player.current.pause();
        subs();
      };
    }
    //eslint-disable-next-line
  }, [lesson]);

  const episodes = lesson.episodes ? lesson.episodes.filter(i => i.duration) : [];

  return (
    <>
      <div className='video-area flex-1 d-flex justify-content-center'>
        {lesson.contentUrl && (
          <Player {...videoSettings} ref={player}>
            <BigPlayButton position='center' />
          </Player>
        )}
        {!lesson.contentUrl && (
          <EmptyView text='Here will be featured the video lesson' Icon={VideoCamIcon} />
        )}
      </div>
      <div className='course-parts d-flex flex-column'>
        <p className='course-title'>Chapters</p>
        <div className='course-parts-lists'>
          {episodes?.length ? (
            episodes.map((item, index) => {
              const active = true;
              const Icon = active ? IconPlayFill : IconLock;
              const selected = activePart && activePart.title === item.title ? 'active' : '';
              const disabled = !active ? 'disabled' : '';
              const startTime = getEpisodeStartTime(episodes, index, true);
              return (
                <div
                  onClick={() => handlePartSelect(item)}
                  role='button'
                  tabIndex='-1'
                  key={item.title + index}
                  className={`part-item d-flex align-items-center px-3 ${disabled} ${selected}`}
                >
                  <div className='mr-2'>
                    <Icon />
                  </div>
                  <div>
                    <p className='name'>{item.name || item.title}</p>
                    <p className='time'>{bindDuration(startTime)}</p>
                  </div>
                </div>
              );
            })
          ) : (
            <div className='empty_chapters_body'>
              <BookMarkIcon />
              <p className='description'>No lesson chapters are configured yet</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default TypeVideo;
