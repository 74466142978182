import React from 'react';
import { QuestionCheckIcon } from 'shared/components/Icons';
import { ENGLISH_UPPERCASE_LETTERS } from 'shared/constants';

export const RadioTypeView = ({ question }) => {
  return (
    <div className='answers-content'>
      <div className='type-block'>
        <span>Answer Type:</span>
        <span className='color-primary'> Text Single Select</span>
      </div>
      <div className='answers-block'>
        {question.answersMulti.map((answer, idx) => {
          const isRightAnswer = answer.isRightAnswer;
          return (
            <div key={answer.id} className='answer-row'>
              <label className='answer-text' htmlFor={`answer_${answer.id}`}>
                {!!isRightAnswer && (
                  <span className='custom-checkbox'>
                    <QuestionCheckIcon className='answer-check' />
                  </span>
                )}
                {ENGLISH_UPPERCASE_LETTERS[idx]}. {answer.answer}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};
