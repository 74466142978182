import React, { useState } from 'react';
import ReactModal from 'react-modal';
import { useSnackbar } from 'notistack';

import ShareCaseModal from './ShareCaseModal';
import { UserOption } from 'shared/components/UsersSelect';
import { HOST } from 'configs';

import { IconCopy } from 'shared/components/Icons';
import { hasAccess } from 'utils/permissionHelper';

const ShareCaseBlock = ({ className, onSuccess, caseData }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [modalState, setModalState] = useState(false);
  const sharePublicUrl = `${HOST.API.LEARNER_DOMAIN}/case/view/${caseData.accessToken}`;

  const onCopyLinkClick = () => {
    navigator.clipboard.writeText(sharePublicUrl);
    enqueueSnackbar('Successfully Copied', { variant: 'success' });
  };

  return (
    <>
      <div
        className={`share-course-block mt-0 d-flex flex-column h-90 justify-content-between ${className ||
          ''} ${caseData.isPublic ? 'is-small-box' : ''}`}
      >
        <div className='d-flex align-items-center justify-content-between'>
          <h1 className='mb-0'>Case Sharing</h1>
        </div>
        {(!caseData.isPublic || !caseData.isShared) && (
          <div className='py-3 max-200-scroll h-200'>
            {caseData.isShared && caseData.caseShares?.length ? (
              caseData.caseShares?.map((item, i) => {
                return (
                  <div key={i} className='mb-2'>
                    <UserOption user={{ ...item, ...item.user }} />
                  </div>
                );
              })
            ) : (
              <div className='course-not-shared-block d-flex align-items-center justify-content-center text-muted text-center'>
                DICOM Case is not shared
              </div>
            )}
          </div>
        )}
        {!!caseData.isPublic && !!caseData.isShared && (
          <div className='public-shared-text-container'>
            <p className='text-center fz-20'>
              This DICOM case is publicly shared. Anyone with the link can access.
            </p>
            {!!caseData.isPublic && !!caseData.isShared && (
              <button
                disabled={!caseData.isShared}
                className={`btn fz-13 fw-600 mt-2 px-0 btn-left-icon-copy-link`}
                onClick={onCopyLinkClick}
              >
                <span>Copy Link</span>
                <IconCopy />
              </button>
            )}
          </div>
        )}
        {hasAccess('course_share') && (
          <div>
            <button className='btn btn-sm btn-primary w-100' onClick={() => setModalState(true)}>
              Sharing Settings
            </button>
          </div>
        )}
      </div>
      {!!modalState && (
        <ReactModal isOpen={true} className='custom-modal'>
          <ShareCaseModal
            sharePublicUrl={sharePublicUrl}
            close={() => setModalState(false)}
            onSuccess={onSuccess}
            caseData={caseData}
            isCaseShared={caseData.isShared}
          />
        </ReactModal>
      )}
    </>
  );
};

export default ShareCaseBlock;
