import React, { useEffect, useState } from 'react';
import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';
import { useSnackbar } from 'notistack';
import AdminCreationForm from './components/AdminCreationForm';
import AdminSubmissionForm from './components/AdminSubmissionForm';
import AdminHeader from './components/AdminHeader';

const AdminNew = ({ history }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [fetch, setFetch] = useState();
  const [data, setData] = useState(null);
  const [entitiesWithoutManager, serEntitiesWithoutManager] = useState([]);
  const [roles, setRoles] = useState([]);
  const [success, setSuccess] = useState(false);

  const handleFormSubmission = async data => {
    try {
      setFetch(true);
      const activeRole = roles?.find(i => Number(i.id) === Number(data.roleId));
      const isEntityManager = activeRole?.title === 'Entity Manager';
      if (isEntityManager) {
        await Api.post('/entity/invitemanager', {
          firstname: data.firstName,
          lastname: data.lastName,
          email: data.email,
          entityId: data.entityId,
        });
      } else {
        await Api.post('/admin/inviteuser', data);
      }
      setData(data);
      setSuccess(true);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      setFetch(false);
    }
  };

  const getRolesList = async () => {
    try {
      const params = { page: 1, limit: 1000, permissions: '37' };
      const { data } = await Api.get('/admin/roles', { params });
      setRoles(data.data.results);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const getSelectDatas = async () => {
    try {
      const params = { page: 1, limit: 1000, excludeMng: true };
      const entities = await Api.get(`/entity/entities`, { params });
      if (entities?.data?.data) serEntitiesWithoutManager(entities.data.data.results);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  useEffect(() => {
    getSelectDatas();
    getRolesList();
    //eslint-disable-next-line
  }, []);

  return (
    <div>
      <AdminHeader />
      <div className='col has-header'>
        <h5 className='p-3 color-blue'>Invite new admin</h5>
        {!success ? (
          <AdminCreationForm
            entities={entitiesWithoutManager}
            roles={roles}
            fetch={fetch}
            onSubmitUser={handleFormSubmission}
          />
        ) : (
          <AdminSubmissionForm data={data} history={history} />
        )}
      </div>
    </div>
  );
};

export default AdminNew;
