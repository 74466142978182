/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';

import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';
import { hasAccess } from 'utils/permissionHelper';

import FooterButtons from './components/FooterButtons';

import { SpotlightGeneralStep } from './components/SpotlightGeneralStep';
import { SpotlightCompleteStep } from './components/SpotlightCompleteStep';
import { Header } from './components/Header';

const MarketingSpotlightNew = ({ history, match, location }) => {
  const { id } = match.params;
  const sampleLicenseId = location?.state?.sampleId;

  const steps = [{ component: SpotlightGeneralStep }, { component: SpotlightCompleteStep }];

  const hasSpotlightAccess = hasAccess('marketing_spotlight_create'); // TEMPORARY ADDED TO BE REWRITTEN
  const { enqueueSnackbar } = useSnackbar();
  const [fetch, setFetch] = useState(false);
  const [fetchDraft, setFetchDraft] = useState(false);
  const [frames, setFrames] = useState([{ id: 1, isNoFrame: true }]);
  const [activeStep, setActiveStep] = useState(0);
  const [form, setForm] = useState({
    name: '',
    selectedFrameId: null,
    selectedFrameType: null,
    image: null,
    animation: 1,
  });

  const canPublish = form.name && form.imageUrl;

  const getSpotlightData = async id => {
    try {
      if (id) {
        const { data } = await Api.get(`/marketing-spotlight/${id}`);
        if (data?.data) {
          setForm({ ...data.data, selectedFrameId: data.data.selectedFrame?.id });
        }
      }
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const getFrames = async () => {
    try {
      const { data } = await Api.get('/marketing-spotlight/frames');
      if (data?.data) {
        setFrames([{ id: 1, isNoFrame: true }, ...data.data]);
      }
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onGoBackStep = () => {
    setActiveStep(step => step - 1);
  };

  const handleStepChange = e => {
    e.preventDefault();
    setActiveStep(step => step + 1);
  };

  const handleSubmit = async e => {
    const submittingBody = { ...form };
    if (!form.selectedFrame?.hasFullName) {
      delete submittingBody.fullName;
    }
    e.preventDefault();
    try {
      setFetch(true);
      if (id) {
        await Api.put(`/marketing-spotlight/${id}`, submittingBody);
        enqueueSnackbar('Successfully updated', { variant: 'success' });
      } else {
        await Api.post(`/marketing-spotlight`, submittingBody);
        enqueueSnackbar('Successfully created', { variant: 'success' });
      }
      history.goBack ? history.goBack() : history.push('/licenses/all');
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetch(false);
    }
  };

  const onProductSave = e => {
    handleSubmit(e);
  };

  useEffect(() => {
    if (id || sampleLicenseId) {
      getSpotlightData(id || sampleLicenseId);
    }
    getFrames();
    //eslint-disable-next-line
  }, [sampleLicenseId]);

  const isLastStep = activeStep === steps.length - 1;
  const Step = steps[activeStep].component;

  return (
    <div>
      <Header steps={steps} activeStep={activeStep} setActiveStep={setActiveStep} form={form} />
      <div className='col-12 p-0'>
        <form
          onSubmit={handleSubmit}
          className={`${
            isLastStep ? 'col-12' : 'col-10'
          } p-0 has-header user-creation-form d-flex flex-column justify-content-between min-vh-100`}
        >
          <Step
            frames={frames}
            form={form}
            setForm={setForm}
            onSave={onProductSave}
            fetching={fetch}
            isEditing={!!id}
          />
          <FooterButtons
            fetchingDraft={fetchDraft}
            canPublish={canPublish}
            canSaveDraft
            activeStep={activeStep}
            isLastStep={isLastStep}
            form={form}
            fetching={fetch}
            onGoBackStep={onGoBackStep}
            onGoNextStep={handleStepChange}
          />
        </form>
      </div>
    </div>
  );
};

export default MarketingSpotlightNew;
