import React from 'react';
import { bindDuration } from 'utils/appHelpers';

const CourseLessonBody = ({ data: { title, duration } }) => {
  return (
    <ul>
      <li className='bg-white p-3'>
        <span className='weight-900 mr-3'>{title}</span>
        <span className='weight-300'>{bindDuration(duration)}</span>
      </li>
    </ul>
  );
};

export default CourseLessonBody;
