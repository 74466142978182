/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { Link } from 'react-router-dom';
import { bindDuration } from 'utils/appHelpers';

const PublishRow = ({ label, data, onClick }) =>
  data || data === 0 ? (
    <p
      className={`d-flex justify-content-between mb-2 ${
        onClick ? 'link-color pointer text-underline' : ''
      }`}
      onClick={onClick}
    >
      <span>{label}</span>
      <span>{label === 'Duration' ? bindDuration(data) : data}</span>
    </p>
  ) : null;

const RightInfo = ({ data, setShowDicomSelectionModal }) => {
  const onClickPreview = () => {
    setShowDicomSelectionModal(true);
  };

  const types = data.steps.map(step => step.frames.map(frame => frame.imageType));
  const uniqueTypes = [...new Set(types.flat())];
  const framesLengts = data.steps.map(step => step.frames.length);

  return (
    <>
      <div className='publish-block p-3 mb-2'>
        <PublishRow label='Steps' data={data.steps?.length} />
        <PublishRow label='Types' data={uniqueTypes.join(', ')} />
        <PublishRow label='Frames' data={framesLengts.join(', ')} />
        <PublishRow label='Scan Years' data={'-'} />
      </div>
      <div className='d-flex align-items-center justify-content-between mb-2 gap_8'>
        <button className={`btn btn-sm btn-blue w-100`} onClick={onClickPreview}>
          Preview
        </button>
        <Link to={`/protocol/${data.id}/edit`} className={`btn btn-sm btn-blue w-100`}>
          Edit
        </Link>
      </div>
    </>
  );
};

export default RightInfo;
