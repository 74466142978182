import React from 'react';
import rating5 from 'assets/dash/rating5.svg';
import rating4 from 'assets/dash/rating4.svg';
import rating3 from 'assets/dash/rating3.svg';
import rating2 from 'assets/dash/rating2.png';
import rating1 from 'assets/dash/rating1.svg';
import AppChart from 'shared/components/Chart';
import Loading from 'shared/components/Loading';
import { IconArrowDown, IconArrowUp } from 'shared/components/Icons';
import { useState } from 'react';

const ratingIcons = { 1: rating1, 2: rating2, 3: rating3, 4: rating4, 5: rating5 };

const generateData = (data, total) => {
  const result = [];
  data.forEach(item => {
    result.unshift({
      rating: item.rating,
      count: item.count,
      icon: ratingIcons[item.rating],
      percent: item.count ? ((item.count / total) * 100).toFixed() : 0,
    });
  });
  return result;
};

const BlockEvaluation = ({ data, fetching }) => {
  const [openState, setOpenState] = useState(true);
  if (!data || !data.length) return null;

  const total = data.reduce((a, b) => a + b['count'], 0);
  const ratings = generateData(data, total);
  const dataArr = ratings.map(item => item.percent);

  const props = {
    id: 'evalChart',
    data: dataArr,
    labels: dataArr,
    width: 280,
    height: 280,
    styles: {
      backgroundColor: ['#2E780A', '#6CB47C', '#9dbf8c', '#f6b02e', '#DE1818'],
      borderColor: ['#2E780A', '#6CB47C', '#9dbf8c', '#f6b02e', '#DE1818'],
      color: [
        'rgb(255, 255, 255)',
        'rgb(255, 255, 255)',
        'rgb(255, 255, 255)',
        'rgb(255, 255, 255)',
        'rgb(255, 255, 255)',
      ],
      borderWidth: 1,
    },
    plugins: {
      datalabels: {
        color: '#FFFFFF',
        textAlign: 'center',
        font: {
          lineHeight: 1.6,
          size: 18,
        },
        formatter: value => (value ? `${value}%` : ''),
      },
    },
  };

  return (
    <div className={`dash-block block-evaluation ${openState ? 'opened' : 'closed'}`}>
      <div className='align-items-center block-title d-flex justify-content-between'>
        Evaluation Summary
        <button className='btn p-0' onClick={() => setOpenState(state => !state)}>
          {openState ? <IconArrowDown /> : <IconArrowUp />}
        </button>
      </div>
      {!fetching && (
        <div className='d-flex align-items-end mt-4 pt-1'>
          <div className='d-flex col-8 justify-content-around'>
            {ratings.map(item => {
              return (
                <div key={item.rating} className='text-center ratings-text px-3'>
                  <img width={69} src={item.icon} alt='icon' />
                  <h3>{item.percent}%</h3>
                  <h4>{item.count}</h4>
                </div>
              );
            })}
          </div>
          <div className='col'>
            <div className='doughnut-chart m-auto'>
              <AppChart {...props} />
              <h4 className='center-text text-center text-positive'>
                {Number(ratings[3].percent) + Number(ratings[4].percent)}% <br />
                <span>Positive</span>
              </h4>
            </div>
            <p className='res-text mb-0'>{total} Responses</p>
          </div>
        </div>
      )}
      {fetching && <Loading className='mt-5' />}
    </div>
  );
};

export default BlockEvaluation;
