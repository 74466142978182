import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';
import Header from './components/Header';
import ReactPaginate from 'react-paginate';
import Lists from './components/Lists';
import { IconArrowLeft, IconArrowRight } from 'shared/components/Icons';
import { confirmAlert } from 'react-confirm-alert';
import ReactModal from 'react-modal';
import ImportProtocolModal from './components/ImportProtocolModal';
import AdvancedFilters from 'shared/components/filters/AdvancedFilters';
import { dicomTypes, filterSteps } from 'configs';
import useFilters from 'shared/hooks/useFilters';

const filterUsage = [{ name: 'Used', id: true }, { name: 'Unused', id: false }];

const initialFilters = () => {
  return { dicom_type: '', used: '', steps: '' };
};

const Protocols = () => {
  const [removeFetch, setRemoveFetch] = useState(false);
  const [exportFetch, setExportFetch] = useState(false);
  const [fetch, setFetch] = useState(false);
  const [importModalState, setImportModalState] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(0);

  const {
    requestParams,
    filters,
    dropFilters,
    changeFilter,
    changeDropFilter,
    clearDropFilter,
    filterDependency,
    originalState,
  } = useFilters(
    { page: 0, order: false, orderKey: 'createdAt', search: '', limit: 8 },
    { ...initialFilters() },
  );

  const downloadData = [
    {
      title: 'Export as CSV',
      url: `/hanging-protocol/all`,
      fileName: 'hanging_protocols_list',
      type: 'csv',
      params: requestParams,
    },
  ];

  const getData = async () => {
    try {
      setFetch(true);
      const { data } = await Api.get(`/hanging-protocol/all`, { params: requestParams });
      setPageCount(Math.ceil(data.data.count / 8));
      setData(data.data.results);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetch(false);
    }
  };

  const onItemRemove = async item => {
    try {
      setRemoveFetch(true);
      await Api.delete(`/wizard/hanging-protocol/${item.id}`);
      await getData();
      enqueueSnackbar('Successfully removed', { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setRemoveFetch(false);
    }
  };

  const onItemRemoveConfirm = item => {
    confirmAlert({
      title: 'Confirmation Required',
      message: 'Are you sure you want to delete George’s Hanging Protocol?',
      buttons: [
        {
          label: 'Cancel',
        },
        {
          label: 'Delete',
          onClick: () => onItemRemove(item),
        },
      ],
    });
  };

  const onItemExport = async item => {
    setExportFetch(true);
  };

  const onClearAllFilters = () => {
    clearDropFilter();
  };

  const onFilterChange = (name, id) => {
    changeDropFilter(name, id);
  };

  const onPageChange = ({ selected }) => {
    changeFilter({ page: selected });
  };

  const handleSearchChange = text => {
    changeFilter({ search: text });
  };

  const onChangeOrder = item => {
    if (!item.key) return;
    changeFilter({ order: !filters.order, orderKey: item.key });
  };

  const filtersOptions = [
    {
      type: 'dropdown',
      title: 'Usage',
      name: 'used',
      data: filterUsage,
      value: dropFilters.used,
      onFilterChange: onFilterChange,
    },
    {
      type: 'dropdown',
      title: 'DICOM Type',
      name: 'dicom_type',
      data: dicomTypes,
      value: dropFilters.dicom_type,
      onFilterChange: onFilterChange,
    },
    {
      type: 'dropdown',
      title: 'Steps',
      name: 'steps',
      data: filterSteps,
      value: dropFilters.steps,
      onFilterChange: onFilterChange,
    },
  ];

  useEffect(() => {
    getData();
  }, [...filterDependency]);

  return (
    <div>
      <Header search={originalState.search} onSearch={handleSearchChange} />
      <div className='py-3 has-header'>
        {/* TODO: temprorary hide - https://trello.com/c/XzSjP31Z/2507-admin-hanging-protocols-hide-import-button-for-now-and-move-export-to-its-proper-position-and-bring-page-to-general-stile-with-o */}
        {/* {hasAccess('protocol_create') && (
            <button
              className='btn btn-outline-blue px-3 d-flex align-items-center'
              onClick={() => setImportModalState(true)}
            >
              Import <IconImport className='ml-3' />
            </button>
          )} */}
        <AdvancedFilters
          filters={filtersOptions}
          onClearAll={onClearAllFilters}
          withExport={true}
          downloadData={downloadData}
        />
        <div className='col-12'>
          <Lists
            list={data}
            order={filters}
            changeOrder={onChangeOrder}
            fetch={fetch}
            onItemRemoveConfirm={onItemRemoveConfirm}
            onItemRemoveDisabled={removeFetch}
            onItemExport={onItemExport}
            onItemExportDisabled={exportFetch}
          />
        </div>
        {pageCount > 1 && !fetch && (
          <ReactPaginate
            previousLabel={<IconArrowLeft />}
            nextLabel={<IconArrowRight />}
            breakLabel={'...'}
            forcePage={Number(filters.page)}
            breakClassName={'break-me'}
            pageCount={pageCount}
            marginPagesDisplayed={3}
            pageRangeDisplayed={3}
            onPageChange={onPageChange}
            containerClassName={`pagination show-arrow`}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
          />
        )}
      </div>
      {!!importModalState && (
        <ReactModal isOpen={true} className='custom-modal import-protocol-modal'>
          <ImportProtocolModal close={() => setImportModalState(false)} onSuccess={getData} />
        </ReactModal>
      )}
    </div>
  );
};

export default Protocols;
