import React from 'react';
import ProgressBar from './ProgressBar';

const PlanProgress = ({ data: { name, start_date, end_date, progress } }) => (
  <>
    <p className='weight-600'>{name}</p>
    <div className='d-flex align-items-end'>
      <div className='col p-0'>
        <div className='d-flex justify-content-between mb-2'>
          <span className='text-date'>{start_date ? `Start ${start_date}` : 'Not Started'}</span>
          <span className='text-date'>{progress >= 100 && end_date && `End ${end_date}`}</span>
        </div>
        <div className='d-flex'>
          <div className='d-flex align-items-center col pl-0'>
            <ProgressBar progress={progress} isHeader />
            <span className='ml-2 fw-600'>{progress}%</span>
          </div>
        </div>
      </div>
    </div>
  </>
);

export default PlanProgress;
