import React from 'react';
import { Link } from 'react-router-dom';
import Button from 'shared/components/Button';
import { IconSquareEditBolded } from 'shared/components/Icons';
import { hasAccess } from 'utils/permissionHelper';

export const EmptyQuestionGroupView = ({ course }) => {
  return (
    <div>
      <h6 className='block_title'>
        Question Group 1: <b>General</b>{' '}
        <Link
          className='ml-2'
          to={{
            pathname: `/course/${course.id}/quiz/question-groups/config`,
            state: { course },
          }}
        >
          <IconSquareEditBolded height='22' />
        </Link>
      </h6>
      <div className='empty_block'>
        <div className='items_container'>
          {['Multi Select', 'Single Select', 'Image Categories'].map((item, idx) => {
            const width = idx === 0 ? '60%' : idx === 1 ? '40%' : '80%';
            return (
              <div key={idx} className='row_item'>
                <span className='number_idx'>{idx + 1}</span>
                <div className='white_rows_block'>
                  <div className='row' />
                  <div style={{ width }} className='row' />
                </div>
                <div className='question_category_label active ml-auto'>{item}</div>
              </div>
            );
          })}
        </div>
        <Link
          to={{
            pathname: `/course/${course.id}/quiz/question-groups/config`,
            state: { course },
          }}
        >
          {hasAccess('quiz_create') && (
            <Button className='btn btn-primary w-100 fz-12'>Add Questions</Button>
          )}
        </Link>
      </div>
    </div>
  );
};
