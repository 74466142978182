import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';

import { checkCourseForPublishing, showCourseInvalidAlert } from '../CourseView/utils';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';

import FooterButtons from './components/FooterButtons';
import Header from './components/Header';
import ProductGeneralStep from './components/ProductGeneralStep';
import ProductCompleteStep from './components/ProductCompleteStep';

const ProductNew = ({ history, match, location }) => {
  const { id } = match.params;
  const sampleProductId = location?.state?.sampleId;
  const { enqueueSnackbar } = useSnackbar();
  const [product, setProduct] = useState();
  const [fetching, setFetching] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  const [data, setData] = useState({
    name: '',
    description: '',
    imageUrl: null,
    productLink: '',
  });
  const [form, setForm] = useState({
    name: '',
    description: '',
    imageUrl: null,
    productLink: '',
  });

  const steps = [{ component: ProductGeneralStep }, { component: ProductCompleteStep }];

  const constructFormData = form => {
    return form;
  };

  const handleSaveProduct = async () => {
    const body = form || constructFormData({ ...form, mode: 2 });
    if (!form) {
      const { isValid, message } = checkCourseForPublishing(body);
      if (!isValid) {
        showCourseInvalidAlert(message);
        return;
      }
    }
    try {
      setFetching(true);
      const productBody = {
        ...form,
        imageUrl: [form.imageUrl],
      };
      if (id) {
        await Api.put(`/product/product/${id}`, productBody);
      } else {
        await Api.post('/product/product', productBody);
      }
      enqueueSnackbar(`Product successfully ${id ? 'updated' : 'created'}`, { variant: 'success' });
      history.push('/products');
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      setFetching(false);
    }
  };

  const onProductSave = () => {
    handleSaveProduct();
  };

  const onGoBackStep = () => {
    setActiveStep(step => step - 1);
  };

  const handleStepChange = async e => {
    e.preventDefault();
    setActiveStep(step => step + 1);
  };

  const setProductInitialData = product => {
    setForm({
      name: product.name || '',
      description: product.description || '',
      imageUrl: product?.images?.[0]?.imageUrl || null,
      productLink: product?.productLink || '',
    });
    setData({
      name: product.name || '',
      description: product.description || '',
      uploadImage: product?.images?.[0]?.imageUrl ? { url: product?.images?.[0]?.imageUrl } : null,
      productLink: product?.productLink || '',
    });
  };

  const getProductData = async id => {
    try {
      const { data } = await Api.get(`/product/product/${id}`);
      const product = data.data;
      if (product) {
        setProduct(product);
        setProductInitialData(product);
      }
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      history.push('/products');
    }
  };

  const onPreviewClick = () => {
    history.push('/courses/preview', { course: { id, form, data }, activeStep });
  };

  const checkAndSetFromPreviewBack = () => {
    const fromPreviewState = location.state?.course;
    if (fromPreviewState) {
      setForm(fromPreviewState.form);
      setData(fromPreviewState.data);
    }
    if (location.state?.activeStep) {
      setActiveStep(location.state.activeStep);
    }
  };

  useEffect(() => {
    if (id) getProductData(id, location.state?.product);
    if (sampleProductId) getProductData(sampleProductId);
    checkAndSetFromPreviewBack();
    //eslint-disable-next-line
  }, [id]);

  const isLastStep = activeStep === steps.length - 1;
  const Step = steps[activeStep].component;

  return (
    <div className='lesson-creation'>
      <Header
        steps={steps}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        form={form}
        onPreviewClick={onPreviewClick}
      />
      <form
        className='has-header d-flex flex-column justify-content-between min-vh-100'
        onSubmit={handleStepChange}
      >
        <div className='col-12'>
          <Step
            form={form}
            setForm={setForm}
            data={data}
            setData={setData}
            onSave={onProductSave}
            fetching={fetching}
            isEditing={!!id}
            product={product}
          />
        </div>
        <FooterButtons
          form={form}
          fetching={fetching}
          onGoBackStep={onGoBackStep}
          activeStep={activeStep}
          isLastStep={isLastStep}
        />
      </form>
    </div>
  );
};

export default ProductNew;
