import moment from 'moment';
import React, { useState } from 'react';
import EditingCheckBox from 'shared/components/editingForms/EditingCheckBox';

const InfoIcon = ({ name, value, className }) => (
  <p className={`m-0 ${className || ''}`}>
    {name}: <b>{value}</b>
  </p>
);

const CourseCertificatesModal = ({
  close,
  certificates,
  activeCertificate,
  onSelectCertificates,
}) => {
  const [activeId, setActiveId] = useState(activeCertificate);

  const onSelectItem = item => {
    setActiveId(item.id);
  };

  const onCloseModal = () => {
    onSelectCertificates(activeId);
    close();
  };

  return (
    <div className='modal-box case-select-modal d-flex flex-column'>
      <div className='m-header'>
        <h5>Select Certificate</h5>
        <p>Choose Certificate that you want to be included in the course</p>
      </div>
      <div className='m-content flex-fill'>
        {certificates &&
          certificates.map((item, i) => {
            return (
              <label
                key={i}
                className='case-item pointer w-100 d-flex align-items-center justify-content-between'
                htmlFor={`radiocheck${i}`}
              >
                <div>
                  <div className='case-item-title d-flex align-items-center mb-1'>{item.name}</div>
                  <div className='d-flex mb-1'>
                    <InfoIcon name='Date' value={moment(item.createdAt).format('MM/DD/YYYY')} />
                    <InfoIcon name='Usage' className='ml-2' value={item.usedCourses} />
                  </div>
                </div>
                <EditingCheckBox
                  onChange={onSelectItem.bind(null, item)}
                  name={`check${i}`}
                  checked={item.id === activeId}
                  editing={true}
                />
              </label>
            );
          })}
      </div>
      <div className='m-footer text-center mt-4 px-4'>
        <button className='btn-blue btn px-4' onClick={onCloseModal}>
          Done
        </button>
      </div>
    </div>
  );
};

export default CourseCertificatesModal;
