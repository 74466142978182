import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

import { getError, saveFile } from 'utils/appHelpers';
import { Api } from 'utils/connectors';

export const useDownload = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [isPreparingDownload, setIsPreparingDownload] = useState(false);

  const download = async ({ url, params, fileName, exportCsv }) => {
    setIsPreparingDownload(true);
    try {
      const { data } = await Api.get(url, {
        params: { ...params, exportCsv },
        responseType: 'blob',
        timeout: 30000,
      });
      const date = moment().format('YYYYMMDDHHmmss');
      const name = `${fileName} ${date}`;
      await saveFile(data, name);
    } catch (err) {
      enqueueSnackbar(getError(err) || 'Failed to Download', { variant: 'error' });
    }
    setIsPreparingDownload(false);
  };
  return { download, isPreparingDownload };
};
