import React, { useState, cloneElement, useRef, useEffect } from 'react';
import useOutsideClick from 'shared/hooks/useOutsideClick';

const DropdownHeader = ({ children, onClick, className }) => {
  return (
    <div className={`app-dropdown-header ${className || ''}`} onClick={onClick} role='presentation'>
      {children}
    </div>
  );
};
const DropdownBody = ({ children, className }) => {
  return <div className={`app-dropdown-body d-flex flex-column ${className}`}>{children}</div>;
};

const DropDown = ({ children, className = '', name = 'default', value }) => {
  const [open, toggleDropdown] = useState(false);
  const content = useRef();

  useOutsideClick(content, () => {
    toggleDropdown(false);
  });

  useEffect(() => {
    toggleDropdown(false);
  }, [value]);

  return (
    <div
      ref={content}
      className={`app-dropdown drop-name-${name} ${className} ${open ? 'active' : ''}`}
    >
      {cloneElement(children[0], { onClick: () => toggleDropdown(!open) })}
      {open && children[1]}
    </div>
  );
};

export { DropDown, DropdownHeader, DropdownBody };
