// storing the token
export const saveLocalToken = token => {
  localStorage.setItem('auth', token);
};

export const getLocalToken = () => {
  return localStorage.getItem('auth');
};

export const removeLocalToken = () => {
  localStorage.removeItem('auth');
};
