import React, { useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import ButtonLine from 'shared/components/ButtonLine';
import EditingInput from 'shared/components/editingForms/EditingInput';
import { tablesConfigs } from '../../Reports/configs';
import { useSnackbar } from 'notistack';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';

const ReportForm = ({ report }) => {
  const match = useRouteMatch();
  const isEdit = match.params.id;
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [fetch, setFetch] = useState(false);
  const [form, setForm] = useState(report || {});
  const { type } = match.params;
  const table = tablesConfigs[type].table;

  const onFormSubmit = async e => {
    e.preventDefault();
    try {
      setFetch(true);
      await Api.post(`/report/${type}/${isEdit ? 'update' : 'add'}`, form);
      enqueueSnackbar(`Successfully ${isEdit ? 'Updated' : 'Created'}`, { variant: 'success' });
      history.push(`/reports/` + type);
    } catch (err) {
      setFetch(false);
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setForm({ ...form, [name]: value });
  };

  return (
    <form className='col-9 user-creation-form mb-5' onSubmit={onFormSubmit}>
      <ul className='p-0 mb-0'>
        {table &&
          table.map(item => {
            return (
              <li className='d-flex align-items-center' key={item.key}>
                <div className='col-4 pl-0'>
                  <label className='m-0'>{item.name}:</label>
                </div>
                <EditingInput
                  type={item.type}
                  name={item.key}
                  value={form[item.key] || ''}
                  onChange={handleChange}
                  className='p-0'
                  required
                  editing={true}
                />
              </li>
            );
          })}
      </ul>
      <div className='d-flex justify-content-end mt-2'>
        <ButtonLine className='btn btn-blue px-4' type='submit' disabled={fetch}>
          {isEdit ? 'Update' : 'Create'}
        </ButtonLine>
      </div>
    </form>
  );
};

export default ReportForm;
