/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { AlertCircleIcon, IconClose, IconCopyWhite, IconSearch } from 'shared/components/Icons';
import { makeStyles } from '@material-ui/core/styles';
import { GlobalTooltip } from 'app/Main/components/Shared/GlobalTooltip';
import { SwitchIOSLike } from 'shared/components/SwitchIOSLike';
import EditingSelect from 'shared/components/editingForms/EditingSelect';
import { getError } from 'utils/appHelpers';
import { useSnackbar } from 'notistack';
import { Api } from 'utils/connectors';
import Button from 'shared/components/Button';
import { HOST } from 'configs';
import EntityItem from 'shared/components/EntityItem';

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  content: {
    padding: '16px 0',
    borderTop: '1px solid #D3D4D7',
    margin: '0 24px',
  },
}));

const URL_KEY_BY_NAME = {
  isIncludeEntity: 'entity',
  isIncludeSpeciality: 'spec',
};

const KEY_BY_NAME = {
  isIncludeEntity: 'entity',
  isIncludeSpeciality: 'speciality',
};

export const CreateLinkDialog = ({
  isOpen,
  onClose,
  urlPath,
  specialties,
  entities,
  selectedSpecialty = null,
  defaultEntity,
  products,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [currentLink, setCurrentLink] = useState(`${HOST.API.LEARNER_DOMAIN}/product`);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [localSpecialties, setLocalSpecialties] = useState(specialties);
  const [localEntities, setLocalEntities] = useState(entities);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [form, setForm] = useState({
    isIncludeEntity: 0,
    isIncludeSpeciality: 0,
    isIncludeProduct: 0,
    speciality: selectedSpecialty,
    entity: null,
  });

  const isCopyDisabled = !urlPath && !selectedProduct;

  const getEntities = async () => {
    try {
      const params = { page: 1, limit: 1000 };
      const { data } = await Api.get(`/entity/entities`, { params });
      if (data?.data) setLocalEntities(data.data.results);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const getSpecialtiesList = async () => {
    try {
      const { data } = await Api.get('/common/specialities');
      setLocalSpecialties(
        data?.data?.length
          ? data.data.map((specialty, idx) => ({ name: specialty, id: specialty }))
          : [],
      );
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const filteredEntities =
    localEntities?.length &&
    localEntities.filter(entity => entity.name.toLowerCase().includes(search.toLowerCase()));

  const handleCopy = () => {
    navigator.clipboard.writeText(currentLink);
    setTooltipOpen(true);
  };

  const handleCopyAndClose = () => {
    handleCopy();
    onClose();
  };

  const handleChangeSwitch = ({ target }) => {
    const { name, checked } = target;
    const { searchParams } = new URL(currentLink);
    if (!checked) {
      setForm({ ...form, [name]: checked, [KEY_BY_NAME[name]]: null });
      removeParam(URL_KEY_BY_NAME[name]);
      if (name === 'isIncludeProduct') {
        setCurrentLink(`${HOST.API.LEARNER_DOMAIN}/product?${searchParams}`);
        setSelectedProduct(null);
      }
    } else {
      setForm({ ...form, [name]: checked });
    }
  };

  const handleCreateAndAssignEntity = async () => {
    try {
      await Api.post('/entity/addentity', { name: search });
      getEntities();
    } catch (err) {
      enqueueSnackbar(getError(err, { variant: 'error' }));
    }
  };

  const addOrUpdateParam = (key, value) => {
    const url = new URL(currentLink);
    url.searchParams.set(key, value);
    setCurrentLink(url.toString());
  };

  const removeParam = key => {
    const url = new URL(currentLink);
    url.searchParams.delete(key);
    setCurrentLink(url.toString());
  };

  const handleSelectSpecialty = ({ target }) => {
    setForm({ ...form, [target.name]: target.value });
    addOrUpdateParam('spec', target.value);
  };

  const handleSelectProduct = ({ target }) => {
    const { searchParams } = new URL(currentLink);
    setSelectedProduct(target.value);
    setCurrentLink(`${HOST.API.LEARNER_DOMAIN}/product/${target.value}?${searchParams}`);
  };

  const handleSelectEntity = entity => {
    setForm({ ...form, entity });
    addOrUpdateParam('entity', entity.name);
  };

  useEffect(() => {
    if (!localEntities?.length) getEntities();
    if (!localSpecialties?.length) getSpecialtiesList();
  }, []);

  useEffect(() => {
    setForm({ ...form, speciality: selectedSpecialty, isIncludeSpeciality: !!selectedSpecialty });
    if (selectedSpecialty) addOrUpdateParam('spec', selectedSpecialty);
    if (urlPath) setCurrentLink(`${HOST.API.LEARNER_DOMAIN}/product/${urlPath}`);
    if (defaultEntity) addOrUpdateParam('entity', defaultEntity.name);
  }, [selectedSpecialty, urlPath, defaultEntity]);

  return (
    <>
      <GlobalTooltip
        open={tooltipOpen}
        handleClose={() => setTooltipOpen(false)}
        message='Link copied!'
      />
      <Dialog
        open={isOpen}
        onClose={onClose}
        aria-labelledby='create-link-dialog-title'
        className='create-link-dialog'
      >
        <DialogTitle id='create-link-dialog-title' className={classes.dialogTitle}>
          Create Link
          <IconButton aria-label='close' onClick={onClose} className={classes.closeButton}>
            <IconClose />
          </IconButton>
        </DialogTitle>
        <DialogContent className={`${classes.content} user-creation-form`} dividers>
          <div className='link-row'>
            <span className='link-txt break-txt-anywhere'>{currentLink}</span>
            <div
              className={`copy-icon-block ${isCopyDisabled && 'global_disabled'}`}
              onClick={handleCopy}
            >
              <IconCopyWhite className='copy-icon' />
            </div>
          </div>
          <Typography variant='subtitle1'>Entity</Typography>
          <li className='select-entity-block regular-form-block list-default'>
            {defaultEntity && <EntityItem noHoverState entity={defaultEntity} />}
            {!defaultEntity && (
              <>
                <div className='d-flex align-items-center form-row'>
                  <div className='col-4 pl-0'>
                    <label className='m-0'>Include Entity:</label>
                  </div>
                  <div className='switch_container'>
                    <SwitchIOSLike
                      name='isIncludeEntity'
                      checkedState={form.isIncludeEntity}
                      handleChange={handleChangeSwitch}
                    />
                  </div>
                  <span className='h_31 d-flex align-items-center'>
                    {form.isIncludeEntity ? 'Included' : 'Not Included'}
                  </span>
                </div>
                <div className='entities-block'>
                  <AlertCircleIcon className='icon' width={28} height={28} color='#737578' />
                  <Typography variant='body2'>
                    When the link includes specific Entity, users who come to the page by that link
                    will see welcome message on behalf of the selected Entity.
                  </Typography>
                </div>
                {!!form.isIncludeEntity && (
                  <div className='entities-section'>
                    <h6 className='title'>Select Entity</h6>
                    <div className='entities-block'>
                      <AlertCircleIcon
                        className='icon'
                        width='16px'
                        height='16px'
                        color='#737578'
                      />
                      <Typography variant='body2'>Please select entity to continue.</Typography>
                    </div>
                    <div className='search-block entity-search-block w-100'>
                      <input
                        type='text'
                        value={search}
                        onChange={({ target }) => setSearch(target.value)}
                        className='form-control has-icon-left'
                        placeholder='Search'
                      />
                      <IconSearch className='left-icon' color='#91939A' />
                    </div>
                    <div className='entity-items-block'>
                      {filteredEntities?.length ? (
                        filteredEntities.map((entity, idx) => (
                          <EntityItem
                            key={idx}
                            entity={entity}
                            handleClick={() => handleSelectEntity(entity)}
                            isActive={form.entity?.id === entity?.id}
                          />
                        ))
                      ) : (
                        <div className='no-result-block'>
                          <p>No Result</p>
                          <div className='btns-block'>
                            <Button
                              onClick={() => setSearch('')}
                              className='btn-outline-primary cancel'
                            >
                              Cancel
                            </Button>
                            <Button onClick={handleCreateAndAssignEntity} className='btn-primary'>
                              Create & Assign "{search}" +
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </>
            )}
          </li>
          {!!defaultEntity && (
            <>
              <Typography variant='subtitle1'>Product*</Typography>
              <li className='select-entity-block regular-form-block list-default'>
                <div className='d-flex align-items-center form-row'>
                  <div className='col-4 pl-0'>
                    <label className='m-0'>Include Product:</label>
                  </div>
                  <div className='switch_container'>
                    <SwitchIOSLike
                      name='isIncludeProduct'
                      checkedState={form.isIncludeProduct}
                      handleChange={handleChangeSwitch}
                    />
                  </div>
                  <span className='h_31 d-flex align-items-center'>
                    {form.isIncludeProduct ? 'Included' : 'Not Included'}
                  </span>
                </div>
                {!!form.isIncludeProduct && (
                  <div className='d-flex align-items-center form-row'>
                    <div className='col-4 pl-0'>
                      <label className='m-0'>Which Product:</label>
                    </div>
                    <EditingSelect
                      name='selectedProduct'
                      onChange={handleSelectProduct}
                      value={selectedProduct || ''}
                      items={products}
                      useProperty='urlPath'
                      displayProperty='productName'
                      editing
                      className='light-bordered-select'
                    />
                  </div>
                )}
                <div className='entities-block'>
                  <AlertCircleIcon className='icon' width={28} height={28} color='#737578' />
                  <Typography variant='body2'>
                    When the link includes also product, for non registered users the product field
                    will be prefilled with the product included in this link.
                  </Typography>
                </div>
              </li>
              <Typography variant='body2'>
                When the link includes also speciality, for non registered users the Speciality
                field will be prefilled with the speciality included in this link.
              </Typography>
            </>
          )}
          <Typography variant='subtitle1'>Speciality</Typography>
          <li className='select-entity-block regular-form-block list-default'>
            <div className='d-flex align-items-center form-row'>
              <div className='col-4 pl-0'>
                <label className='m-0'>Include Specialty:</label>
              </div>
              <div className='switch_container'>
                <SwitchIOSLike
                  name='isIncludeSpeciality'
                  checkedState={form.isIncludeSpeciality}
                  handleChange={handleChangeSwitch}
                />
              </div>
              <span className='h_31 d-flex align-items-center'>
                {form.isIncludeSpeciality ? 'Included' : 'Not Included'}
              </span>
            </div>
            {!!form.isIncludeSpeciality && (
              <div className='d-flex align-items-center form-row'>
                <div className='col-4 pl-0'>
                  <label className='m-0'>Which Specialty:</label>
                </div>
                <EditingSelect
                  name='speciality'
                  onChange={handleSelectSpecialty}
                  value={form.speciality || ''}
                  items={localSpecialties}
                  useProperty='id'
                  displayProperty='name'
                  editing
                  className='light-bordered-select'
                />
              </div>
            )}
            <div className='entities-block'>
              <AlertCircleIcon className='icon' width={28} height={28} color='#737578' />
              <Typography variant='body2'>
                When the link includes specific Entity, users who come to the page by that link will
                see welcome message on behalf of the selected Entity.
              </Typography>
            </div>
          </li>
          <Typography variant='body2'>
            When the link includes also speciality, for non registered users the Speciality field
            will be prefilled with the speciality included in this link.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={isCopyDisabled}
            className={`btn-primary ${isCopyDisabled && 'global_disabled'}`}
            onClick={handleCopyAndClose}
          >
            Copy & Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
