import { HOST } from 'configs';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { createObjectFromUrlParams } from 'utils/stringHelpers';

const CONFIG_LOAD_ERR_MASSAGE = 'Failed to load Configuration';

const RoomPreview = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoaded, setIsLoaded] = useState(false);
  const roomParams = createObjectFromUrlParams(window.location.search);

  const applyConfig = async () => {
    try {
      await document
        .getElementById('integrationCanvas')
        .contentWindow.postMessage(JSON.stringify(roomParams), '*');
    } catch (error) {
      enqueueSnackbar(CONFIG_LOAD_ERR_MASSAGE, { variant: 'error' });
    }
  };

  useEffect(() => {
    const element = document.getElementById('main_sidebar');
    element.classList.add('not_display');
    return () => element.classList.remove('not_display');
  }, [isLoaded]);

  return (
    <div className='preview_container'>
      {isLoaded && (
        <button className='btn_apply_config' onClick={applyConfig}>
          Apply Changes
        </button>
      )}
      <iframe
        allowfullscreen={true}
        title='Room Preview'
        id='integrationCanvas'
        src={HOST.API.ROOM_360S_URL}
        scrolling='no'
        width='100%'
        height='100%'
        onLoad={() => setIsLoaded(true)}
      />
    </div>
  );
};

export default RoomPreview;
