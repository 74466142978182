import React, { useEffect, useState } from 'react';
import HeaderSection from './components/HeaderSection';
import Loading from 'shared/components/Loading';
import TypeVideo from '../CoursePreview/components/TypeVIdeo';
import TypePDF from '../CoursePreview/components/TypePDF';
import TypeCases from '../CoursePreview/components/TypeCases';
import TypeImage from '../CoursePreview/components/TypeImage';
import LessonsListSection from '../CoursePreview/components/LessonsListSection';
import TypeCasesPlay from '../CoursePreview/components/TypeCasesPlay';

const viewsComponents = {
  1: TypeVideo,
  2: TypePDF,
  3: TypeCases,
  4: TypeImage,
};

const LessonPreview = ({ location, history, match }) => {
  const [lesson, setLesson] = useState();
  const isViewer = match.params.type === 'play';

  const onCasePlayClick = () => {
    history.push('/lessons/preview/play', { ...location.state });
  };

  const onClosePreview = () => {
    const lesson = location.state?.lesson;
    if (lesson?.id) history.push(`/lesson/${lesson?.id}/edit`, { ...location.state });
    else {
      history.push('/lessons/new', { ...location.state });
    }
  };

  useEffect(() => {
    if (!location?.state?.lesson) {
      history.push('/lessons/new');
    } else {
      const course = { ...(location.state?.lesson || {}) };
      const stateLesson = { ...course.form, ...course.data, ...course.overwrite };
      setLesson(stateLesson);
    }
  }, []);

  if (!lesson) return <Loading />;

  const LessonView = viewsComponents[lesson.contentType];

  if (isViewer) {
    return (
      <div className='course-view flex-1 d-flex flex-column position-relative'>
        <HeaderSection lesson={lesson} onClosePreview={onClosePreview} mode={lesson.mode} />
        <TypeCasesPlay lesson={lesson} />
      </div>
    );
  }

  return (
    <div className='course-view flex-1 d-flex flex-column position-relative'>
      <HeaderSection lesson={lesson} onClosePreview={onClosePreview} mode={lesson.mode} />
      <div className='body-section overflow-hidden d-flex'>
        {LessonView && <LessonView lesson={lesson} onCasePlayClick={onCasePlayClick} />}
      </div>
      <div className='footer-section d-flex mt-2'>
        <div className='col courses-area p-0'>
          <LessonsListSection
            lessons={[lesson]}
            activeLesson={lesson}
            setActiveLesson={() => {}}
            setIsClickedOnEval={() => {}}
          />
        </div>
        <div className='col info-area' />
      </div>
    </div>
  );
};

export default LessonPreview;
