import React, { useEffect, useState } from 'react';
import { navigation } from './configs';
import { connect } from 'react-redux';
import { logout } from '../../../Auth/actions/index';
import { Link, NavLink, useLocation } from 'react-router-dom';
import SubMenu from './components/SubMenu';
import { hasAccess } from 'utils/permissionHelper';
import topLogo from 'assets/ACE-logo.svg';
import ResponsiveImage from 'shared/components/ResponsiveImage';

const SideBar = ({ account, logout }) => {
  const location = useLocation();
  const [activeState, setActiveState] = useState(null);

  useEffect(() => {
    if (location) setActiveState(location.pathname);
  }, [location]);

  return (
    <>
      <div>
        <div className='info-part d-flex flex-column align-items-center text-center'>
          <Link to='/home' className='mt-4'>
            <ResponsiveImage imgUrl={topLogo} width='80%' alt='topLogo' />
          </Link>
          <div className='profile-info mt-4 align-items-center d-flex flex-column'>
            <Link to='/profile'>
              <div className='avatar m-auto'>
                <ResponsiveImage imgUrl={account.imageUrl} alt={account.name} />
              </div>
              <span>
                {account.firstName} {account.lastName}
              </span>
            </Link>
            <div role='button' tabIndex='-1' onClick={logout} className='pointer log-out'>
              Log out
            </div>
          </div>
        </div>
        <div className='navigation mt-3'>
          <ul>
            {navigation &&
              navigation.map(nav => {
                const Icon = nav.image ? false : nav.icon;
                const activePart = activeState ? activeState.split('/')[1] : '';
                const isActive = nav.activeNavs
                  ? nav.activeNavs.includes(activeState) || nav.activeNavs.includes(activePart)
                  : activeState === nav.path;
                return (
                  hasAccess(nav.permission) && (
                    <li className={`nav-item ${isActive ? 'active' : ''}`} key={nav.name}>
                      <div className='d-flex'>
                        <div className='d-flex col-2 mr-1 p-0 align-items-center'>
                          {Icon ? (
                            <Icon
                              className={nav.iconClassName || ''}
                              color={isActive ? '#ffffff' : '#b5bef6'}
                            />
                          ) : (
                            <img src={nav.icon} height='22' alt='icon' />
                          )}
                        </div>
                        <NavLink to={nav.path}>{nav.name}</NavLink>
                      </div>
                      {isActive && nav.menu && <SubMenu menu={nav.menu} />}
                    </li>
                  )
                );
              })}
          </ul>
        </div>
      </div>
      <div className='text-center text-white fz-14 p-3'>
        Global Medical Education Breast & Skeletal Health
      </div>
    </>
  );
};

const mapStateToProps = ({ account }) => ({
  account,
});

export default connect(
  mapStateToProps,
  { logout },
)(SideBar);
