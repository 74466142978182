import { QUESTION_TYPES_OBJ } from './constants';

export const getQuestionGroupProperties = (group, questions) => {
  return {
    groupTitle: {
      icon: null,
      name: 'Group Name',
      value: group?.name || null,
      key: 'groupTitle',
    },
    questionsCount: {
      icon: null,
      name: 'Questions Number',
      value: questions?.length,
      key: 'questionsCount',
    },
    multiSelectsCount: {
      icon: null,
      name: 'Multi-Select N',
      value: questions?.filter(q => q.type === QUESTION_TYPES_OBJ.multi).length,
      key: 'multiSelectsCount',
    },
    singleSelectsCount: {
      icon: null,
      name: 'Single-Select N',
      value: questions?.filter(q => q.type === QUESTION_TYPES_OBJ.radio).length,
      key: 'singleSelectsCount',
    },
    booleansCount: {
      icon: null,
      name: 'Boolean N',
      value: questions?.filter(q => q.type === QUESTION_TYPES_OBJ.yesno).length,
      key: 'booleansCount',
    },
    imageCategoriesCount: {
      icon: null,
      name: 'Image Category N',
      value: questions?.filter(q => q.type === QUESTION_TYPES_OBJ['image-category']).length,
      key: 'imageCategoriesCount',
    },
    usedInQuiz: {
      icon: null,
      name: 'Used in quiz',
      value: group?.usedInQuiz ? 'Yes' : 'No',
      key: 'usedInQuiz',
    },
  };
};
