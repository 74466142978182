import React from 'react';
import { Api } from 'utils/connectors';
import { useSnackbar } from 'notistack';
import { getError } from 'utils/appHelpers';
import ResponsiveImage from './ResponsiveImage';

const AvatarImage = ({ url, userId, author, getUserData, access }) => {
  const { enqueueSnackbar } = useSnackbar();

  const uploadImage = async ({ target }) => {
    const file = target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    const path = author ? 'uploadauthorimage' : 'uploaduserimage';

    try {
      await Api.post(`/api/image/${path}/${userId}`, formData);
      if (getUserData) getUserData();
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  return (
    <div className='mr-4 avatar-container'>
      <ResponsiveImage className='user-avatar' imgUrl={url} alt='avatar' />
      {access && (
        <label htmlFor='file' className='d-flex align-items-end justify-content-center'>
          <span className='text-center'>
            Change <br />
            Photo
          </span>
        </label>
      )}
      <input id='file' type='file' className='hide' onChange={uploadImage} />
    </div>
  );
};

export default AvatarImage;
