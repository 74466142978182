import React from 'react';
import Button from 'shared/components/Button';

const UserSubmissionForm = ({ data, history }) => {
  return (
    <div className='col'>
      <h5>The invitation was successfully sent to: {data.email}</h5>
      <p>A new admin notification will be generated when user completes the registration</p>
      <Button className='btn-blue px-5' onClick={() => history.push('/users/all')}>
        Ok
      </Button>
    </div>
  );
};

export default UserSubmissionForm;
