import React, { useEffect, useState, useCallback } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import ReactPaginate from 'react-paginate';
import { useSnackbar } from 'notistack';

import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';

import Header from './components/Header';
import Lists from './components/Lists';
import useDebounce from 'shared/hooks/useDebounce';
import { IconArrowLeft, IconArrowRight } from 'shared/components/Icons';

const Regions = ({ match }) => {
  const { filter } = match.params;
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState([]);
  const [order, setOrder] = useState({ order: false, orderKey: 'updatedAt' });
  const [fetch, setFetch] = useState(false);
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [search, setSearch] = useState('');
  const [removeFetch, setRemoveFetch] = useState(false);
  const debouncedSearch = useDebounce(search, 500);

  const getRegions = useCallback(async () => {
    try {
      setFetch(true);
      const userType = filter ? filter : undefined;
      const params = { ...order, page: page + 1, limit: 8, search: debouncedSearch, userType };
      const { data } = await Api.get(`/common/regions`, { params });
      setPageCount(Math.ceil(data.data.count / 8));
      setData(data.data.results);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetch(false);
    }
  }, [debouncedSearch, enqueueSnackbar, order, page, filter]);

  const onPageChange = ({ selected }) => {
    setPage(selected);
  };

  const handleSearchChange = text => {
    setSearch(text);
  };

  const onItemRemove = async item => {
    try {
      setRemoveFetch(true);
      await Api.delete(`/common/region/${item.id}`);
      await getRegions();
      enqueueSnackbar('Successfully removed', { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setRemoveFetch(false);
    }
  };

  const onItemRemoveConfirm = item => {
    confirmAlert({
      title: 'Confirmation Required',
      message: 'Are you sure you want to delete this Region?',
      buttons: [
        {
          label: 'Cancel',
        },
        {
          label: 'Delete',
          onClick: () => onItemRemove(item),
        },
      ],
    });
  };

  useEffect(() => {
    getRegions();
  }, [getRegions, page, order, filter]);

  return (
    <div>
      <Header noSearch={true} onSearch={handleSearchChange} />
      <div className='py-3 has-header'>
        <div className='col-12'>
          <Lists
            data={data}
            order={order}
            changeOrder={setOrder}
            fetch={fetch}
            onItemRemoveDisabled={removeFetch}
            onItemRemoveConfirm={onItemRemoveConfirm}
          />
        </div>
        {pageCount > 1 && !fetch && (
          <ReactPaginate
            previousLabel={<IconArrowLeft />}
            nextLabel={<IconArrowRight />}
            breakLabel={'...'}
            forcePage={page}
            breakClassName={'break-me'}
            pageCount={pageCount}
            marginPagesDisplayed={3}
            pageRangeDisplayed={3}
            onPageChange={onPageChange}
            containerClassName={`pagination show-arrow`}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
          />
        )}
      </div>
    </div>
  );
};

export default Regions;
