import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { formatDate, getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import { Link } from 'react-router-dom';
import Loading from 'shared/components/Loading';
import DicomList from './components/DicomList';
import { IconBirads } from 'shared/components/Icons';
import { hasAccess } from 'utils/permissionHelper';
import ReactModal from 'react-modal';
import UsedDataModal from '../ProtocolsView/components/UsedDataModal';
import PublishRow from 'shared/components/PublishRow';
import ProtocolSelectionModal from '../WarehouseCaseView/components/ProtocolSelectionModal';

const CaseView = ({ match, history }) => {
  const [usedDataModalState, setUsedDataModalState] = useState({ show: false, type: '', data: [] });
  const [showProtocolSelectionModal, setShowDicomSelectionModal] = useState(false);
  const { id } = match.params;
  const { enqueueSnackbar } = useSnackbar();
  const [caseData, setCaseData] = useState();

  const onGetCaseData = async e => {
    try {
      const { data } = await Api.get(`/cases/case/${id}`);
      setCaseData(data.data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      history.push('/cases/all');
    }
  };

  const handleShowUsedLessons = () => {
    setUsedDataModalState({
      show: true,
      type: 'lessons',
      data: caseData.usedInLessons,
    });
  };

  const handleShowUsedEvents = () => {
    setUsedDataModalState({
      show: true,
      type: 'events',
      data: caseData.usedInEvents,
    });
  };

  const onClickPreview = () => {
    setShowDicomSelectionModal(true);
  };

  const onProtocolSelect = protocol => {
    history.push(`/protocol/${protocol.id}/preview`, {
      protocol: protocol,
      cases: [{ ...caseData, caseId: caseData.id }],
    });
  };

  const onCloseModal = () => {
    setUsedDataModalState({ show: false, type: '', data: [] });
  };

  useEffect(() => {
    onGetCaseData();
    //eslint-disable-next-line
  }, [id]);

  if (!caseData) return <Loading className='mt-5' />;

  return (
    <div>
      <div className='users-header d-flex justify-content-between align-items-center'>
        <div className='d-flex breadcrumbs'>
          <Link to='/cases/all'>All Cases</Link>
          <span className='mx-2'>/</span>
          <span>{caseData.scanId}</span>
        </div>
      </div>
      <div className='d-flex mb-3 has-header'>
        <div className='col-8'>
          <ul className='list-default list-settings'>
            <li className='d-flex align-items-center p-3'>
              <div className='col-4 pl-0 weight-700'>Scan ID:</div>
              <div className='col weight-200'>{caseData.scanId}</div>
            </li>
            <li className='d-flex align-items-center p-3'>
              <div className='col-4 pl-0 weight-700'>BIRADS:</div>
              <div className='col weight-200 d-flex justify-content-between'>
                <div>-</div>
                {hasAccess('cases_create') && (
                  <div className='action-buttons'>
                    <Link
                      to={`/case/${caseData.scanId}/birads`}
                      className={`action-item ${caseData.hasBIRADS ? 'fill-blue' : ''}`}
                    >
                      <IconBirads />
                    </Link>
                  </div>
                )}
              </div>
            </li>
            <li className='d-flex align-items-center justify-content-between fw-400'>
              <div className='d-flex align-items-center col-10 p-0'>
                <div className='col-5 pl-0'>
                  <label className='m-0 weight-700'>Used in Lessons:</label>
                </div>
                <span className='weight-200'>{caseData.usedInLessons?.length || 0}</span>
              </div>
              <button
                disabled={!caseData.usedInLessons?.length}
                onClick={handleShowUsedLessons}
                className='btn btn-white btn-sm fw-400 fz-13 rounded-5 color-dark min-100'
              >
                See Lessons
              </button>
            </li>
            <li className='d-flex align-items-center justify-content-between fw-400'>
              <div className='d-flex align-items-center col-10 p-0'>
                <div className='col-5 pl-0'>
                  <label className='m-0 weight-700'>Used in Events:</label>
                </div>
                <span className='weight-200'>{caseData.usedInEvents?.length || 0}</span>
              </div>
              <button
                disabled={!caseData.usedInEvents?.length}
                onClick={handleShowUsedEvents}
                className='btn btn-white btn-sm fw-400 fz-13 rounded-5 color-dark min-100'
              >
                See Events
              </button>
            </li>
          </ul>
        </div>
        <div className='col-4'>
          <div className='publish-block course-publish p-3 mb-2 h-90'>
            <PublishRow label='Dicom Types:' data={caseData.dicomTypes} />
            <PublishRow label='Patient Age:' data={caseData.patientAge} />
            <PublishRow label='Scan Date:' data={formatDate(caseData.scanDate)} />
          </div>
          <button className={`btn btn-sm btn-blue w-100`} onClick={onClickPreview}>
            Preview
          </button>
        </div>
      </div>
      <DicomList
        hasBIRADS={caseData.hasBIRADS}
        sections={caseData.answers?.sections}
        dicoms={caseData.dicoms}
        onGetCaseData={onGetCaseData}
        scanId={caseData.scanId}
      />
      {!!usedDataModalState.show && (
        <ReactModal isOpen={true} className='custom-modal used-data-modal'>
          <UsedDataModal onClose={onCloseModal} state={usedDataModalState} />
        </ReactModal>
      )}
      {showProtocolSelectionModal && (
        <ReactModal isOpen={true} className='custom-modal'>
          <ProtocolSelectionModal
            close={() => setShowDicomSelectionModal(false)}
            onSelectProtocol={onProtocolSelect}
          />
        </ReactModal>
      )}
    </div>
  );
};

export default CaseView;
