import React, { useEffect, useState } from 'react';
import HeaderSection from './components/HeaderSection';
import InfoSection from './components/InfoSection';
import LessonsListSection from './components/LessonsListSection';
import TypeVideo from './components/TypeVIdeo';
import TypePDF from './components/TypePDF';
import TypeCases from './components/TypeCases';
import TypeImage from './components/TypeImage';
import TypeEvaluation from './components/TypeEvaluation';
import Loading from 'shared/components/Loading';
import TagsViewBlock from './components/TagsViewBlock';
import evaluationImg from 'assets/evaluation.jpeg';
import { duplicate } from 'utils/appHelpers';
import TypeCasesPlay from './components/TypeCasesPlay';
import EmptyView from './components/EmptyView';
import { BookMarkIcon, CoursePaperIcon, VideoCamIcon } from 'shared/components/Icons';

const emptyCourseDescription = 'Here will be featured the information related to the course.';

const viewsComponents = {
  1: TypeVideo,
  2: TypePDF,
  3: TypeCases,
  4: TypeImage,
};

const CoursePreview = ({ location, history, match }) => {
  const [course, setCourse] = useState();
  const [activeLesson, setActiveLesson] = useState();
  const [isClickedOnEval, setIsClickedOnEval] = useState(false);
  const isViewer = match.params.type === 'play';

  const onCasePlayClick = () => {
    history.push('/courses/preview/play', { ...location.state });
  };

  const onClosePreview = () => {
    const course = location.state?.course;
    if (course?.id) history.push(`/course/${course?.id}/edit`, { ...location.state });
    else {
      const someCourse = duplicate(course);
      if (someCourse.data?.activeLessons) {
        someCourse.data.activeLessons = someCourse.data.activeLessons.filter(
          i => i.contentType !== 5,
        );
      }
      history.push('/courses/new', { ...location.state, course: someCourse });
    }
  };

  const handleClickBack = () => {
    setIsClickedOnEval(false);
    setActiveLesson(course.activeLessons.length && course.activeLessons[0]);
  };

  useEffect(() => {
    let stateCourse;
    if (location.state?.course) {
      const course = { ...(location.state?.course || {}) };
      stateCourse = { ...course.form, ...course.data };

      if (stateCourse.isEvaluationAvailable && stateCourse.activeLessons) {
        stateCourse.activeLessons.push({
          contentType: 5,
          imageUrl: evaluationImg,
          title: 'Evaluation',
          info: 'Evaluation',
        });
      }

      setCourse(stateCourse);
      if (stateCourse && stateCourse.activeLessons) {
        setActiveLesson(stateCourse.activeLessons.length && stateCourse.activeLessons[0]);
      }
    } else {
      history.push('/courses/new');
    }
    return () => {
      const lastElm = stateCourse?.activeLessons?.length && stateCourse.activeLessons.slice(-1)[0];
      if (lastElm?.contentType === 5) {
        stateCourse.activeLessons = stateCourse.activeLessons.pop();
      }
    };
  }, []);

  if (!course) return <Loading />;

  const LessonView = activeLesson && viewsComponents[activeLesson.contentType];
  const isFeedback = activeLesson && activeLesson.contentType === 5;
  const noAnyLesson = course.activeLessons?.length < 2 && isFeedback;

  if (noAnyLesson && !isClickedOnEval) {
    return (
      <div className='course-view flex-1 d-flex flex-column position-relative'>
        <HeaderSection course={course} onClosePreview={onClosePreview} mode={course.mode} />
        <div className='body-section'>
          <div className='video-area overflow-hidden d-flex justify-content-center mb-2'>
            {!LessonView && (
              <EmptyView text='Here will be featured the lesson content' Icon={VideoCamIcon} />
            )}
            {LessonView && <LessonView lesson={activeLesson} onCasePlayClick={onCasePlayClick} />}
          </div>
          {!activeLesson?.chapters && (
            <div className='empty_chapters_section'>
              <div className='header'>
                <h3 className='title'>Chapters</h3>
              </div>
              <div className='body'>
                <BookMarkIcon />
                <p className='description'>No lesson chapters are configured yet</p>
              </div>
            </div>
          )}
        </div>
        <div className='footer-section d-flex'>
          <div
            className={`${course?.activeLessons?.length ? 'col' : 'col-8'} courses-area p-0 pt-2`}
          >
            {course?.activeLessons?.length ? (
              <LessonsListSection
                lessons={course.activeLessons}
                activeLesson={activeLesson}
                setActiveLesson={setActiveLesson}
                setIsClickedOnEval={setIsClickedOnEval}
              />
            ) : (
              <div className='feedback_section'>
                <div className='course_icon_block'>
                  <CoursePaperIcon />
                </div>
                <div className='course_desc_block'>
                  <h3 className='title'>Feedback</h3>
                  <p className='description'>Leave a feedback about this Course</p>
                </div>
              </div>
            )}
          </div>
          <div className='col info-area p-4'>
            <InfoSection isEmpty info={course.description || emptyCourseDescription} />
            <TagsViewBlock tags={course.learnerTags} />
          </div>
        </div>
      </div>
    );
  }

  if (isViewer) {
    return (
      <div className='course-view flex-1 d-flex flex-column position-relative'>
        <HeaderSection course={course} onClosePreview={onClosePreview} mode={course.mode} />
        <TypeCasesPlay lesson={activeLesson} />
      </div>
    );
  }

  if (isFeedback) {
    return (
      <div className='course-view flex-1 d-flex flex-column position-relative'>
        <HeaderSection
          course={course}
          onClosePreview={onClosePreview}
          mode={course.mode}
          hasBack={true}
          onBackClick={handleClickBack}
        />
        <TypeEvaluation course={course} />
      </div>
    );
  }

  return (
    <div className='course-view flex-1 d-flex flex-column position-relative'>
      <HeaderSection course={course} onClosePreview={onClosePreview} mode={course.mode} />
      <div className='body-section'>
        <div className='video-area overflow-hidden d-flex justify-content-center mb-2'>
          {!LessonView && (
            <EmptyView text='Here will be featured the lesson content' Icon={VideoCamIcon} />
          )}
          {LessonView && <LessonView lesson={activeLesson} onCasePlayClick={onCasePlayClick} />}
        </div>
        {!activeLesson?.chapters && (
          <div className='empty_chapters_section'>
            <div className='header'>
              <h3 className='title'>Chapters</h3>
            </div>
            <div className='body'>
              <BookMarkIcon />
              <p className='description'>No lesson chapters are configured yet</p>
            </div>
          </div>
        )}
      </div>
      <div className='footer-section d-flex'>
        <div className={`${course?.activeLessons?.length ? 'col' : 'col-8'} courses-area p-0 pt-2`}>
          {course?.activeLessons?.length ? (
            <LessonsListSection
              lessons={course.activeLessons}
              activeLesson={activeLesson}
              setActiveLesson={setActiveLesson}
              setIsClickedOnEval={setIsClickedOnEval}
            />
          ) : (
            <div className='feedback_section'>
              <div className='course_icon_block'>
                <CoursePaperIcon />
              </div>
              <div className='course_desc_block'>
                <h3 className='title'>Feedback</h3>
                <p className='description'>Leave a feedback about this Course</p>
              </div>
            </div>
          )}
        </div>
        <div className='col info-area p-4'>
          <InfoSection isEmpty info={course.description || emptyCourseDescription} />
          {course?.learnerTags?.length ? (
            <TagsViewBlock tags={course.learnerTags} />
          ) : (
            <div className='tags_block'>
              <h5 className='title'>Tags</h5>
              <p className='description'>No tags are chosen for this course yet.</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CoursePreview;
