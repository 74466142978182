/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import CheckBox from 'shared/components/CheckBox';
import { DropDown, DropdownHeader, DropdownBody } from 'shared/components/DropDown';

const FilterDropDownMultiselect = ({
  title,
  data,
  activeItems = [],
  onItemClick,
  className,
  name,
  withoutSearch,
  forceLabel,
}) => {
  const [search, setSearch] = useState('');

  const onSearchChange = e => {
    setSearch(e.target.value);
  };

  const filteredData = [...data].filter(i => i.name?.toLowerCase().includes(search.toLowerCase()));
  const selectedItems = Array.isArray(activeItems) ? activeItems : [activeItems];

  return (
    <DropDown
      name={name || title}
      className={`export-drop-down app-dropdown-body d-flex align-items-center ${
        selectedItems.length ? 'selected' : ''
      } ${className || ''}`}
    >
      <DropdownHeader className='align-items-center d-flex mr-3'>
        <span className='mr-2 d-flex'>{title}</span>
      </DropdownHeader>
      <DropdownBody>
        {!withoutSearch && (
          <div className='filter-search-block dont-close-drop-menu'>
            <input
              type='text'
              placeholder='Search...'
              className='form-control'
              value={search}
              onChange={onSearchChange}
            />
          </div>
        )}
        <ul>
          {filteredData.map(item => (
            <li className='drop-item form-radio-circle' key={item.id}>
              <CheckBox
                label={forceLabel || item.name}
                onChange={() => onItemClick(item.id)}
                name={item.name}
                checked={selectedItems.includes(item.id)}
              />
            </li>
          ))}
        </ul>
      </DropdownBody>
    </DropDown>
  );
};

export default FilterDropDownMultiselect;
