import React from 'react';
import ButtonLoading from 'shared/components/ButtonLoading';

const SubsCompleteStep = ({ onSubsSave, fetching }) => {
  return (
    <div className='d-flex flex-column lesson-complete-step text-center'>
      <h4>Subscription Setup is Complete</h4>
      <p>
        If you are sure about completeness of subscription information then press Save, if not press{' '}
        <br /> Back to change any of configurations.
      </p>
      <div>
        <button
          className='btn btn-primary min-100'
          type='button'
          onClick={onSubsSave}
          disabled={fetching}
        >
          {fetching ? <ButtonLoading /> : 'Save'}
        </button>
      </div>
    </div>
  );
};

export default SubsCompleteStep;
