import React from 'react';

const ClickArea = ({ onClick, children, className }) => (
  <div
    className={`text-primary pointer ${className}`}
    onClick={onClick}
    role='button'
    tabIndex='-1'
  >
    {children}
  </div>
);

export default ClickArea;
