import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import CertificateHeader from './components/CertificateHeader';
import Loading from 'shared/components/Loading';
import certificate from 'assets/certificate.png';

const UserCertificate = ({ match }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [fetchBtn, setFetchBtn] = useState(false);
  const [user, setUser] = useState(null);
  const [data, setData] = useState(null);
  const userId = match.params.id;

  const getUserCertificates = async id => {
    try {
      const res = await Api.get(`/auth/getuserbyid/${id}`);
      setUser(res.data.data);
      const { data } = await Api.get(`courses/getusercertificates/${id}`);
      setData(data.data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onRegenerateItem = async courseId => {
    try {
      setFetchBtn(true);
      await Api.get(`/courses/certificate/update/${userId}/${courseId}`);
      enqueueSnackbar('Successfully regenerated', { variant: 'success' });
      getUserCertificates(userId);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetchBtn(false);
    }
  };

  useEffect(() => {
    getUserCertificates(userId);
    //eslint-disable-next-line
  }, [userId]);

  if (!user || !data) return <Loading className='mt-5' />;

  return (
    <div>
      <CertificateHeader user={user} />
      {!data.length && (
        <h4 className='text-center py-5 certificate-page has-header'>
          Certificate will be available after completion of the course.
        </h4>
      )}
      {!!data.length && (
        <div className='p-3 d-flex has-header'>
          {data.map(item => (
            <div key={item.courseId} className='p-2 certificate-item-old mr-3'>
              <div className='hover-item flex-column'>
                <div className='d-flex buttons-area mb-4'>
                  <button
                    className='btn'
                    onClick={onRegenerateItem.bind(null, item.courseId)}
                    disabled={fetchBtn}
                  >
                    Re-Generate
                  </button>
                  <a
                    className='btn'
                    href={item.certificateUrl}
                    target='_blank'
                    rel='noopener noreferrer'
                    download='Certificate'
                  >
                    Download
                  </a>
                </div>
                <span className='text-center font-weight-bold'>{item.title}</span>
              </div>
              <img width='250' src={certificate} alt='certificate' />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default UserCertificate;
