import React, { useState } from 'react';
import { hasAccess } from 'utils/permissionHelper';
import ProgressBar from './ProgressBar';
import { AlertCircleIcon } from 'shared/components/Icons';
import { Link } from 'react-router-dom';

const ProgressEl = ({ data }) => {
  const { passedBy, unpassedBy } = data;
  return (
    <div className='col-6 pl-0'>
      <div className='d-flex justify-content-between'>
        <span className='text-date'>
          {data.start_date ? `Start ${data.start_date}` : 'Not Started'}
        </span>
        <span className='text-date'>
          {data.progress >= 100 && data.end_date && `End ${data.end_date}`}
        </span>
      </div>
      <div className='d-flex align-items-center'>
        <ProgressBar progress={data.progress} />
        <span className='ml-2 fw-600'>{data.progress}%</span>
      </div>
      {passedBy && (
        <span className='mark_passed_label'>
          <AlertCircleIcon color='#FFC84C' /> Marked as passed by{' '}
          <Link className='unset-anchor-styles author_name' to={`/user/${passedBy.id}/`}>
            {passedBy?.fullName}
          </Link>
        </span>
      )}
      {unpassedBy && (
        <span className='mark_passed_label'>
          <AlertCircleIcon /> Marked as unpassed by{' '}
          <Link className='unset-anchor-styles author_name' to={`/user/${unpassedBy.id}/`}>
            {unpassedBy?.fullName}
          </Link>
        </span>
      )}
    </div>
  );
};

const MarkPassButton = ({ disabled, onClick, completed, noUnpass }) => {
  const canUnpass = !noUnpass && completed;
  return (
    <div className={`col-2 p-0 text-right weight-600 pt-2`}>
      {hasAccess('set_passed_course') && (
        <div>
          <button
            disabled={disabled || (completed && noUnpass)}
            className={`btn pass-button text-nowrap ${
              canUnpass ? 'btn-outline-primary' : 'btn-primary'
            } fz-12`}
            onClick={onClick}
          >
            {canUnpass ? 'Mark as Unpassed' : 'Mark as Passed'}
          </button>
        </div>
      )}
    </div>
  );
};

const CourseProgress = ({ actions = {}, states = {}, data }) => {
  const [isOpened, setIsOpened] = useState();

  return (
    <div className='align-items-start course-progress-block'>
      <div
        className={`mb-4 item-header p-0 ${isOpened ? 'active' : ''}`}
        role='button'
        tabIndex='-1'
        onClick={() => setIsOpened(!isOpened)}
      >
        <div className='col-6 pl-0 weight-600'>{data.name}</div>
      </div>
      <div className='d-flex align-items-start justify-content-between'>
        <ProgressEl data={data} />
        <MarkPassButton
          noUnpass={true}
          disabled={states.passCourseFetch}
          onClick={actions.onPassCourse.bind(null, data, data.progress > 0)}
          completed={data.progress > 0}
        />
      </div>
      <div className={`course-lessons-block mt-4 border-top ${isOpened ? 'active' : ''}`}>
        {data.lessons &&
          data.lessons.map((item, index) => {
            const isEvaluation = item.name === 'Evaluation';
            const isCurrentUnPass = item.progress > 0;
            const isNextItemUnPass = data.lessons[index + 1]?.progress > 0;
            return (
              <div className='mb-2 d-flex' key={item.id}>
                <div className='col-4'>{item.name}</div>
                <ProgressEl data={item} />
                {!isEvaluation && (
                  <MarkPassButton
                    noUnpass={false}
                    disabled={states.passLessonFetch || isNextItemUnPass}
                    onClick={actions.onPassLesson.bind(null, item, data, isCurrentUnPass)}
                    completed={isCurrentUnPass}
                  />
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default CourseProgress;
