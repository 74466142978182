import React, { useEffect, useState } from 'react';
import heatMapCreator from 'heatmapjs';

const PointAreaQuestion = props => {
  const { question } = props;
  const [heatmapImgSize, setHeatmapImgSize] = useState({});

  useEffect(() => {
    if (heatmapImgSize.x && heatmapImgSize.y) {
      const heatmapImg = document.getElementById(`heatmap_img_${question?.questionId}`);
      const xCoordSizeChange = heatmapImgSize.x / heatmapImg.naturalWidth;
      const yCoordSizeChange = heatmapImgSize.y / heatmapImg.naturalHeight;
      let heatmapInstance = heatMapCreator.create({
        container: document.querySelector('.heatmap'),
        gradient: {
          '.3': '#3F716E',
          '.5': '#278429',
          '.95': '#B8B224',
          '.99': '#E84C2A',
        },
        radius: 13,
        blur: 0.9999999,
      });

      let points = question.heatmapAnswers.map(answer => ({
        ...answer,
        x: answer.x * xCoordSizeChange,
        y: answer.y * yCoordSizeChange,
      }));
      let data = {
        data: points,
      };
      heatmapInstance.setData(data);
    }
  }, [heatmapImgSize]);

  return (
    <div className='survey-question-card'>
      <p className='questionText'>{question.questionTitle}</p>
      <div id='mydiv' className='heatmap'>
        <img
          onLoad={({ target: img }) =>
            setHeatmapImgSize({ x: img.clientWidth, y: img.clientHeight })
          }
          id={`heatmap_img_${question?.questionId}`}
          src={`http://${window.location.hostname}:8091/content/${question?.img}`}
          alt='heatmap'
        />
      </div>
    </div>
  );
};

export default PointAreaQuestion;
