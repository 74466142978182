import React from 'react';
import moment from 'moment';
import { IconRight } from 'shared/components/Icons';
import ClickArea from 'shared/components/ClickArea';
import { formatDate, getEndOfSubs } from 'utils/appHelpers';
import Loading from 'shared/components/Loading';
import { Link } from 'react-router-dom';

const headers = [
  {
    name: 'First Name',
    className: 'col-2',
    key: 'firstName',
  },
  {
    name: 'Last Name',
    className: 'col-2',
    key: 'lastName',
  },
  {
    name: 'Registered At',
    className: 'col-2',
    key: 'registeredAt',
  },
  {
    name: 'Completed At',
    className: 'col-2',
    key: 'completedAt',
  },
  {
    name: 'Expiration',
    className: 'col',
    key: 'expirationDate',
  },
];

const Lists = ({ users, order, changeOrder, fetch, reActivateSubs, reActivateFetch }) => {
  if (fetch) return <Loading className='mt-5' />;
  if (!users || !users.length)
    return <div className='text-black-50 text-center mt-4'>No Results Found</div>;

  const handleOrderChange = item => {
    if (!item.key) return;
    changeOrder({
      orderKey: item.key,
      order: !order.order,
    });
  };

  return (
    <div className='users-lists'>
      <ul className='p-0'>
        <li className='d-flex list-header align-items-center'>
          {headers.map((item, index) => (
            <ClickArea
              key={item.name + index}
              className={`d-flex text-body justify-content-between align-items-center ${
                item.className
              }`}
              onClick={handleOrderChange.bind(null, item)}
            >
              {item.name}
              {item.key && order.orderKey === item.key && (
                <button
                  className={`btn ml-2 p-0 dropdown-toggle ${order.order ? '' : 'rotate-180'}`}
                />
              )}
            </ClickArea>
          ))}
        </li>
        {users &&
          users.map((item, i) => {
            const isExpired = item.expirable
              ? moment(item.expirationDate).diff(new Date(), 'days') <= 0
              : false;
            return (
              <li key={item.userId + i} className='d-flex align-items-center list-item'>
                <div className='col-2 text-truncate' title={item.firstName}>
                  {item.firstName}
                </div>
                <div className='col-2 text-truncate' title={item.lastName}>
                  {item.lastName}
                </div>
                <div className='col-2 text-truncate' title={item.registeredAt}>
                  {formatDate(item.registeredAt) || '-'}
                </div>
                <div className='col-2 text-truncate' title={item.completedAt}>
                  {formatDate(item.completedAt) || '-'}
                </div>
                <div
                  className='col d-flex justify-content-between align-items-center'
                  title={item.info}
                >
                  <span>{getEndOfSubs(item.expirationDate, !item.expirable) || '-'}</span>
                  <div className='d-flex'>
                    {isExpired && (
                      <button
                        className='align-items-center d-flex is-small justify-content-center link-color more-btn px-2 small text-nowrap'
                        onClick={reActivateSubs.bind(null, item.userId, item.subscriptionId)}
                        disabled={reActivateFetch}
                      >
                        <span className='mr-1'>Re-Activate</span>
                        <IconRight />
                      </button>
                    )}
                    <Link className='more-btn small ml-2' to={`/user/${item.userId}/`}>
                      More <IconRight />
                    </Link>
                  </div>
                </div>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default Lists;
