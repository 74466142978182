/* eslint-disable default-case */
import React from 'react';
import { useSnackbar } from 'notistack';

import { getError } from 'utils/appHelpers';
import { LocalApi } from 'utils/connectors';

import Button from 'shared/components/Button';
import BarChartRateQuestion from '../BarChartRateQuestion';
import PointAreaQuestion from '../PointAreaQuestion';
import SingleAndMultiSelectQuestion from '../SingleAndMultiSelectQuestion';

import { AgreementCheckbox } from 'shared/components/Icons';

const SurveyAccordionBody = props => {
  const { enqueueSnackbar } = useSnackbar();
  const { questions, questionSet, surveyUniqueId, getSurveys, questionCase, isAdmin } = props;

  const is_accessible = questionCase.questionSetResultsAccessible;

  let answersCount = 0;

  if (questions?.[0].questionType === 1 || questions?.[0].questionType === 2) {
    answersCount = questions?.[0]?.textAnswers?.reduce((acc, { score }) => acc + Number(score), 0);
  } else if (questions?.[0].questionType === 4) {
    answersCount = questions?.[0]?.rateAnswers?.reduce((acc, { score }) => acc + Number(score), 0);
  } else if (questions?.[0].questionType === 5) {
    answersCount = questions?.[0].heatmapAnswers?.length;
  }

  const handleGiveOrCloseAccess = async () => {
    try {
      await LocalApi.post('/survey/question/accessibility', {
        caseId: questionCase.caseId,
        surveyUniqueId: surveyUniqueId,
        questionSetUniqueId: questionSet.uniqueId,
        is_accessible: is_accessible ? 0 : 1,
      });
      getSurveys();
    } catch (err) {
      enqueueSnackbar(getError(err) || err.message, { variant: 'error' });
    }
  };
  return (
    <div className='survey-accordion-content'>
      <div className='head-sec'>
        <p className='head-text'>
          Answers Count: <b>{answersCount}</b>
        </p>
        {isAdmin && (
          <div className='right-actions-container'>
            {!!is_accessible && (
              <span className='access-opened-flag'>
                Visibility Allowed <AgreementCheckbox />
              </span>
            )}
            <Button onClick={handleGiveOrCloseAccess} buttonType='btn btn-outline-blue'>
              {is_accessible ? 'Hide Visibility' : 'Allow Visibility'}
            </Button>
          </div>
        )}
      </div>
      <div className='question-cards-container'>
        {questions.map((question, idx) => {
          switch (question.questionType) {
            case 1:
              return <SingleAndMultiSelectQuestion question={question} key={idx} />;
            case 2:
              return <SingleAndMultiSelectQuestion question={question} key={idx} />;
            case 4:
              return <BarChartRateQuestion question={question} key={idx} />;
            case 5:
              return <PointAreaQuestion question={question} key={idx} />;
          }
          return null;
        })}
      </div>
    </div>
  );
};

export default SurveyAccordionBody;
