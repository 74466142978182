import React from 'react';
import { useSelector } from 'react-redux';
import UserImage from './components/UserImage';
import Settings from './components/Settings';
import PasswordManage from './components/PasswordManage';
import Button from 'shared/components/Button';
import moment from 'moment';
import EntityInfoBlock from './components/EntityInfoBlock';
import { HOST } from 'configs';
import { hasAccess } from 'utils/permissionHelper';

const Profile = ({ history }) => {
  const account = useSelector(state => state.account);
  const { imageUrl, email } = account;
  const role = account.roles?.length ? account.roles[0] : null;

  return (
    <div className='d-flex flex-column justify-content-between min-vh-100'>
      <div className='users-header d-flex justify-content-between align-items-center'>
        <div className='d-flex breadcrumbs'>
          <span>Admin Profile</span>
        </div>
      </div>
      <div className='col-12 py-3 has-header'>
        <div className='col-9 d-block'>
          <div className='mb-5 d-flex flex-1 mt-1'>
            <UserImage url={imageUrl} userId={account.id} />
            <Settings data={account} />
          </div>
          {account?.entities?.map((entity, idx) => <EntityInfoBlock key={idx} entity={entity} />)}
          {account?.entity && <EntityInfoBlock entity={account.entity} />}
          <div className='mb-3'>
            <ul className='list-default list-settings'>
              <li className='d-flex align-items-center p-3'>
                <div className='col-4 pl-0 weight-700'>Email:</div>
                <div className='col weight-200'>{account.email}</div>
              </li>
              <li className='d-flex align-items-center p-3'>
                <div className='col-4 pl-0 weight-700'>Role:</div>
                <div className='col weight-200'>{role?.title || '-'}</div>
              </li>
            </ul>
          </div>
          <div className='mb-2'>
            <PasswordManage email={email} />
          </div>
          {hasAccess('email_configuration') && (
            <div className='mb-2'>
              <ul className='list-default'>
                <li className='d-flex px-0 align-items-center'>
                  <div className='col weight-700 fz-16'>Email Notifications</div>
                  <div className='col weight-200 d-flex justify-content-end'>
                    <Button
                      className='btn-white fz-12 min-100 weight-700'
                      onClick={() => history.push('/profile/notifications')}
                    >
                      Configure
                    </Button>
                  </div>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
      <div className='page-footer p-2 d-flex px-4 small'>
        <span>Copyright {moment().format('YYYY')} Hologic, In</span>
        <span className='ml-4'>Version: {HOST.API.VERSION}</span>
      </div>
    </div>
  );
};

export default Profile;
