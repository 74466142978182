import React, { useState } from 'react';
import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';
import { useSnackbar } from 'notistack';
import UserCreationForm from './components/UserCreationForm';
import UserSubmissionForm from './components/UserSubmissionForm';
import UserHeader from './components/UserHeader';

const UserNew = ({ history }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [fetch, setFetch] = useState();
  const [data, setData] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleFormSubmission = async data => {
    try {
      setFetch(true);
      await Api.post('/admin/inviteuser', data);
      setData(data);
      setSuccess(true);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      setFetch(false);
    }
  };

  return (
    <div>
      <UserHeader />
      <div className='col has-header'>
        <h5 className='p-3 color-blue'>Invite new user</h5>
        {!success ? (
          <UserCreationForm fetch={fetch} onSubmitUser={handleFormSubmission} />
        ) : (
          <UserSubmissionForm data={data} history={history} />
        )}
      </div>
    </div>
  );
};

export default UserNew;
