import React from 'react';

const DashComponent = ({ count }) => {
  const dashCountArr = new Array(count).fill(null);
  return (
    <div className='dashes_container'>
      {dashCountArr.map((item, idx) => (
        <div key={idx} className='dash_item' />
      ))}
    </div>
  );
};

export default DashComponent;
