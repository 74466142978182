import React from 'react';
import ClickArea from './ClickArea';

const SimpleListTable = ({
  headers,
  list,
  noRightBorders,
  renderRow = () => {},
  headerClassName,
}) => {
  return (
    <div className={`users-lists w-100 ${noRightBorders && 'table-no-right-borders'}`}>
      <ul className='p-0'>
        <li className={`d-flex list-header align-items-center ${headerClassName}`}>
          {headers.map((item, index) => (
            <ClickArea
              key={item.name + index}
              className={`d-flex text-body justify-content-between align-items-center ${
                item.className
              }`}
            >
              {item.name}
            </ClickArea>
          ))}
        </li>
        {list &&
          list.map(item => {
            return <>{renderRow(item)}</>;
          })}
      </ul>
    </div>
  );
};

export default SimpleListTable;
