import React from 'react';
import Button from 'shared/components/Button';
import Select from 'shared/components/Select';
import attachIcon from 'assets/attach.svg';
import { reportTypes } from 'configs';

const ImportForm = ({ handleSubmit, type, setType, file, setFile, fetch }) => {
  return (
    <form onSubmit={handleSubmit} className='col-7 user-creation-form'>
      <ul className='p-0 mb-0'>
        <li className='d-flex align-items-center'>
          <div className='col-3 pl-0'>
            <label className='m-0'>Report Type:</label>
          </div>
          <Select
            name='coursetype'
            onChange={e => setType(e.target.value)}
            value={type}
            items={reportTypes}
            useProperty='value'
            displayProperty='name'
            label='Choose Type'
            className={type ? 'selected-blue' : ''}
          />
        </li>
        <li className='d-flex align-items-center'>
          <div className='col-3 pl-0'>
            <label className='m-0'>CSV File:</label>
          </div>
          <div className='upload-file-form w-100'>
            <input
              id='file'
              name='file'
              type='file'
              className='flex-fill hide'
              onChange={e => setFile(e.target.files[0])}
            />
            <label htmlFor='file' className='d-flex justify-content-between align-items-center'>
              {!file && 'Choose CSV File'}
              {file && <span>{file.name}</span>}
              <img src={attachIcon} alt='icon' />
            </label>
          </div>
        </li>
      </ul>
      <div className='d-flex justify-content-end mt-2'>
        <Button type='submit' className='btn-blue mt-1 px-4' disabled={fetch || !file || !type}>
          Upload
        </Button>
      </div>
    </form>
  );
};

export default ImportForm;
