import React from 'react';
import placeholder from 'assets/case_placeholder.jpg';
import { IconDicom, IconPlayFill } from 'shared/components/Icons';
import EmptyView from '../EmptyView';
import ResponsiveImage from 'shared/components/ResponsiveImage';

const TypeCases = ({ lesson, onCasePlayClick }) => {
  const cases = lesson.activeCases || lesson.cases || lesson.episodes;
  return (
    <div className='case-part flex-1 d-flex justify-content-center'>
      {lesson.activeCases?.length === 0 && (
        <EmptyView text='Here will be featured the DICOM lesson' Icon={IconDicom} />
      )}
      {lesson.activeCases?.length !== 0 && (
        <>
          <button onClick={onCasePlayClick} disabled={!cases || !cases.length} className='play-btn'>
            <IconPlayFill />
          </button>
          <ResponsiveImage
            height='100%'
            imgUrl={lesson.imageUrl || placeholder}
            alt='placeholder'
          />
        </>
      )}
    </div>
  );
};

export default TypeCases;
