import React from 'react';
import { useState } from 'react';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import EditingCheckBox from 'shared/components/editingForms/EditingCheckBox';
import { IconSearch } from 'shared/components/Icons';

const EventDeviceStep = ({ form, setForm }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [devices, setDevices] = useState([]);
  const [search, setSearch] = useState('');

  const getDevices = async () => {
    try {
      const params = { page: 1, limit: 1000 };
      const { data } = await Api.get(`/wizard/devices`, { params });
      setDevices(data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onChange = item => {
    const ids = new Set([...form.devicePairList]);
    if (ids.has(item.serviceUUID)) {
      ids.delete(item.serviceUUID);
    } else {
      ids.add(item.serviceUUID);
    }
    setForm({ ...form, devicePairList: [...ids] });
  };

  const filterBySearch = (devices, search) => {
    if (!devices) return [];
    return devices.filter(i => {
      const key1 = i.masterDeviceName.toLowerCase();
      const key2 = i.clientDeviceName.toLowerCase();
      const searchLower = search.toLowerCase();
      return key1.includes(searchLower) || key2.includes(search);
    });
  };

  const sortSelectedFirst = devices => {
    return devices.reduce((acc, element) => {
      if (form.devicePairList.includes(element.serviceUUID)) {
        return [element, ...acc];
      }
      return [...acc, element];
    }, []);
  };

  useEffect(() => {
    getDevices();
    //eslint-disable-next-line
  }, []);

  const filteredDevices = filterBySearch(devices, search);
  const sortedDevices = sortSelectedFirst(filteredDevices);

  return (
    <div className='px-2'>
      <h6 className='color-blue mb-3 fz-18'>Select Device Pairs</h6>
      <p className='mb-4'>Choose device pairs that you want to be used during the event</p>
      <div className='search-block col-8 p-0'>
        <input
          type='text'
          className='form-control w-100'
          placeholder='Enter Device Name For Search'
          onChange={({ target }) => setSearch(target.value)}
        />
        <IconSearch />
      </div>
      <div className='col-8 pl-0 pb-4 mt-4 max-500-scroll pr-3'>
        {sortedDevices &&
          sortedDevices.map((item, index) => {
            return (
              <div
                className='event-device-box pb-3 align-items-center row d-flex border-bottom'
                key={index}
              >
                <div className='col-5'>
                  <p className='text-blue font-weight-bold fz-16 mb-2'>Master</p>
                  <p className='mb-0 ml-1 fz-16'>{item.masterDeviceName || '-'}</p>
                  <p className='mb-1 ml-1 text-secondary fz-14'>
                    {item.masterDeviceUniqueId || '-'}
                  </p>
                </div>
                <div className='col-5'>
                  <p className='text-blue font-weight-bold fz-16 mb-2'>Client</p>
                  <p className='mb-0 ml-1 fz-16'>{item.clientDeviceName || '-'}</p>
                  <p className='mb-1 ml-1 text-secondary fz-14'>
                    {item.clientDeviceUniqueId || '-'}
                  </p>
                </div>
                <div className='col d-flex justify-content-end'>
                  <EditingCheckBox
                    name={`pairDevice${item}`}
                    value={item.serviceUUID}
                    onChange={onChange.bind(null, item)}
                    className='p-0'
                    checked={form.devicePairList.includes(item.serviceUUID)}
                    editing={true}
                  />
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default EventDeviceStep;
