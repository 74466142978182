import { useEffect, useRef } from 'react';

const useInitialEffect = (func, deps) => {
  const didMount = useRef();

  useEffect(() => {
    if (!didMount.current) {
      func();
      didMount.current = true;
    }
  }, deps);
};

export default useInitialEffect;
