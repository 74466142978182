/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { useSnackbar } from 'notistack';

import { Api } from 'utils/connectors';
import { getError, reorder } from 'utils/appHelpers';

import EditingInput from 'shared/components/editingForms/EditingInput';
import UploadImageRow from 'shared/components/UploadImageRow';
import EditingSelect from 'shared/components/editingForms/EditingSelect';
import DragList from 'shared/components/DragList';
import DragItem from 'shared/components/DragItem';
import { SwitchIOSLike } from 'shared/components/SwitchIOSLike';
import { IconBin } from 'shared/components/Icons';
import LessonCasesStepModal from '../../LessonsNew/components/LessonCasesStepModal';
import LessonCaseRow from '../../LessonsNew/components/LessonCaseRow';

const getEmptyWorkshop = () => {
  return { title: '', imageId: 0, hangingProtocolId: 0, cases: [], surveyId: 0 };
};

const EventWorkshopStep = ({ form, setForm, isWorkshop }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [protocols, setProtocols] = useState();
  const [surveys, setSurveys] = useState();
  const [caseModal, setCaseModal] = useState(false);
  const [cases, setCases] = useState();

  const getProtocols = async () => {
    try {
      const params = { page: 1, limit: 1000 };
      const { data } = await Api.get('/hanging-protocol/all', { params });
      setProtocols(data.data.results);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const getCases = async () => {
    try {
      const { data } = await Api.get('/wizard/cases');
      setCases(data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const getSurveys = async () => {
    try {
      const { data } = await Api.get('/survey/all');
      setSurveys(data.data.results);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const initWorkshops = () => {
    setForm({ ...form, workshops: [{ ...getEmptyWorkshop() }] });
  };

  const addWorkshop = () => {
    setForm({ ...form, workshops: [...form.workshops, { ...getEmptyWorkshop() }] });
  };

  const removeWorkshop = index => {
    const tempData = form.workshops;
    tempData.splice(index, 1);
    setForm({ ...form, workshops: tempData });
  };

  const onSuccessUpload = (index, image) => {
    const tempData = [...form.workshops];
    tempData[index].imageId = image.id;
    tempData[index].imageUrl = image.url;
    setForm({ ...form, workshops: tempData });
  };

  const handleChange = (index, { target }) => {
    const { name, value } = target;
    const tempData = form.workshops;
    tempData[index][name] = value;
    setForm({ ...form, workshops: tempData });
  };

  const handleSwitchSurveyEnable = (index, { name, checked }) => {
    const tempData = form.workshops;
    tempData[index][name] = checked;
    setForm({ ...form, workshops: tempData });
  };

  const onSelectCases = (index, items) => {
    const tempData = [...form.workshops];
    tempData[index].cases = items;
    setForm({ ...form, workshops: tempData });
  };

  const onDragEnd = (index, e) => {
    if (!e.destination) return;
    const tempData = [...form.workshops];
    tempData[index].cases = reorder(tempData[index].cases, e.source.index, e.destination.index);
    setForm({ ...form, workshops: tempData });
  };

  const handleChangeCaseProcotol = (index, childIndex, { target }) => {
    const tempData = [...form.workshops];
    tempData[index].cases[childIndex].hangingProtocolId = target.value;
    setForm({ ...form, workshops: tempData });
  };

  const handleChangeCaseSurvay = (index, childIndex, { target }) => {
    const tempData = [...form.workshops];
    tempData[index].cases[childIndex].surveyId = target.value;
    setForm({ ...form, workshops: tempData });
  };

  const getCaseItem = id => {
    return cases?.find(item => Number(item.caseId) === Number(id));
  };

  useEffect(() => {
    if (!form.workshops || !form.workshops.length) initWorkshops();
    getProtocols();
    getCases();
    getSurveys();
  }, []);

  return (
    <div className='px-2 pb-3'>
      <h6 className='color-blue mb-3'>Workshop Configuration</h6>
      {form.workshops?.map((item, index) => {
        return (
          <div key={index} className={index < 2 ? 'mt-4' : 'mt-5'}>
            <p className='color-blue mb-2 fz-16 font-weight-bold'>Workshop {index + 1}</p>
            <div className='user-creation-form no-focus episode-form col-7'>
              <ul className='p-0 mb-0'>
                <li className='d-flex align-items-center pl-1'>
                  <div className='col-3 pl-0'>
                    <label className='m-0'>Title*:</label>
                  </div>
                  <EditingInput
                    type='text'
                    name='title'
                    value={item.title}
                    onChange={handleChange.bind(null, index)}
                    className='p-0'
                    placeholder='Enter Title'
                    required
                    editing={true}
                  />
                </li>
                <UploadImageRow
                  title='Image:'
                  image={item.imageUrl}
                  name={`image-${index}`}
                  onSuccessUpload={onSuccessUpload.bind(null, index)}
                  className='pl-1'
                />
                <li className='d-flex align-items-center pl-1'>
                  <div className='col-3 pl-0'>
                    <label className='m-0'>Hanging Protocol*:</label>
                  </div>
                  <EditingSelect
                    name='hangingProtocolId'
                    onChange={handleChange.bind(null, index)}
                    value={item.hangingProtocolId}
                    items={protocols}
                    useProperty='id'
                    displayProperty='name'
                    required={true}
                    valueClassName='pl-0'
                    editing={true}
                  />
                </li>
                <li className='d-flex align-items-center pl-1'>
                  <div className='col-3 pl-0'>
                    <label className='m-0'>Workshop Survey*:</label>
                  </div>
                  <div className='switch_container'>
                    <SwitchIOSLike
                      name='hasSurvey'
                      checkedState={!!item.hasSurvey}
                      handleChange={({ target }) => handleSwitchSurveyEnable(index, target)}
                    />
                  </div>
                  <EditingSelect
                    disabled={!item.hasSurvey}
                    disabledType='gray'
                    name='surveyId'
                    onChange={handleChange.bind(null, index)}
                    value={item.surveyId}
                    items={surveys}
                    useProperty='id'
                    displayProperty='name'
                    required={true}
                    valueClassName='pl-0'
                    editing={true}
                  />
                </li>
                <li className='d-flex pl-1'>
                  <div className='col-3 pl-0'>
                    <label className='m-0'>DICOM Cases:</label>
                  </div>
                  {!item.cases ||
                    (!item.cases.length && (
                      <button
                        type='button'
                        className='btn btn-white btn-sm'
                        onClick={() => setCaseModal({ index, cases: item.cases })}
                      >
                        Choose DICOM Cases
                      </button>
                    ))}
                  {item.cases && !!item.cases.length && (
                    <div className='pb-4'>
                      <DragList onDragEnd={onDragEnd.bind(null, index)} items={item.cases}>
                        {(dicom, childIndex) => {
                          const caseItem = getCaseItem(dicom.caseId) || {};
                          // eslint-disable-next-line
                          const filteredProtocol =
                            protocols && protocols.filter(i => i.id != item.hangingProtocolId);
                          // eslint-disable-next-line
                          const filteredSurveys =
                            surveys && surveys.filter(i => i.id != item.surveyId);
                          return (
                            <DragItem key={childIndex} className='with-drag p-0'>
                              <div key={childIndex} className='mb-3 fz-12'>
                                <p className='text-blue mb-2'>Patient {childIndex + 1}</p>
                                <LessonCaseRow
                                  className={'d-flex flex-wrap case-row-on-event'}
                                  item={caseItem}
                                />
                                <EditingSelect
                                  parentClassName='mt-2'
                                  name={`hangingProtocolId${caseItem.caseUniqueId}`}
                                  onChange={e => handleChangeCaseProcotol(index, childIndex, e)}
                                  value={dicom.hangingProtocolId || ''}
                                  items={filteredProtocol}
                                  useProperty='id'
                                  displayProperty='name'
                                  valueClassName='pl-0'
                                  label={'Default Workshop'}
                                  editing={true}
                                />
                                <EditingSelect
                                  parentClassName='mt-2'
                                  name={`survayId${caseItem.caseUniqueId}`}
                                  onChange={e => handleChangeCaseSurvay(index, childIndex, e)}
                                  value={dicom.surveyId || ''}
                                  items={filteredSurveys}
                                  useProperty='id'
                                  displayProperty='name'
                                  valueClassName='pl-0'
                                  label={'Default Survey'}
                                  editing={true}
                                />
                              </div>
                            </DragItem>
                          );
                        }}
                      </DragList>
                      <button
                        type='button'
                        className='btn btn-primary btn-sm'
                        onClick={() => setCaseModal({ index, cases: item.cases })}
                      >
                        Change
                      </button>
                    </div>
                  )}
                </li>
              </ul>
            </div>
            {index > 0 && (
              <div className='position-absolute col-3 pl-0 z-index-100'>
                <button
                  type='button'
                  className='btn btn-sm pl-0 text-danger d-flex align-items-center'
                  onClick={removeWorkshop.bind(null, index)}
                >
                  <IconBin className={'mr-2'} />
                  Remove Workshop
                </button>
              </div>
            )}
          </div>
        );
      })}
      {caseModal && (
        <ReactModal isOpen={true} className='custom-modal'>
          <LessonCasesStepModal
            title='Choose DICOM Cases'
            subTitle=''
            cases={cases}
            activeCases={caseModal.cases}
            onSelectCases={onSelectCases.bind(null, caseModal.index)}
            close={() => setCaseModal(false)}
          />
        </ReactModal>
      )}
      <div className='text-right col-7 pr-0 mt-3 '>
        <button
          type='button'
          className='btn btn-outline-blue add-new-frames fz-14'
          onClick={addWorkshop}
        >
          Add Workshop +
        </button>
      </div>
    </div>
  );
};

export default EventWorkshopStep;
