import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';
import { IconArrowLeft, IconArrowRight } from 'shared/components/Icons';
import Header from './components/Header';
import ReactPaginate from 'react-paginate';
import Lists from './components/Lists';
import { confirmAlert } from 'react-confirm-alert';
import AdvancedFilters from 'shared/components/filters/AdvancedFilters';
import useFilters from 'shared/hooks/useFilters';

const Surveys = () => {
  const [removeFetch, setRemoveFetch] = useState(false);
  const [fetch, setFetch] = useState(false);
  const [data, setData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [pageCount, setPageCount] = useState(0);

  const { requestParams, filters, changeFilter, filterDependency, originalState } = useFilters({
    page: 0,
    order: false,
    orderKey: 'createdAt',
    search: '',
    limit: 8,
  });

  const downloadData = [
    {
      title: 'Export as CSV',
      url: `/survey/all`,
      fileName: 'surveys_list',
      type: 'csv',
      params: requestParams,
    },
  ];

  const getData = async () => {
    try {
      setFetch(true);
      const { data } = await Api.get(`/survey/all`, { params: requestParams });
      if (data.data) {
        setPageCount(Math.ceil(data.data.count / 8));
        setData(data.data.results);
      } else {
        setData([]);
      }
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetch(false);
    }
  };

  const onPageChange = ({ selected }) => {
    changeFilter({ page: selected });
  };

  const handleSearchChange = text => {
    changeFilter({ search: text });
  };

  const onChangeOrder = item => {
    if (!item.key) return;
    changeFilter({ order: !filters.order, orderKey: item.key });
  };

  const onItemRemove = async item => {
    try {
      setRemoveFetch(true);
      await Api.delete(`/survey/${item.id}`);
      await getData();
      enqueueSnackbar('Successfully removed', { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setRemoveFetch(false);
    }
  };

  const onItemRemoveConfirm = item => {
    confirmAlert({
      title: 'Confirmation Required',
      message: 'Are you sure you want to delete this Survey?',
      buttons: [
        {
          label: 'Cancel',
        },
        {
          label: 'Delete',
          onClick: () => onItemRemove(item),
        },
      ],
    });
  };

  useEffect(() => {
    getData();
  }, [...filterDependency]);

  return (
    <div>
      <Header search={originalState.search} onSearch={handleSearchChange} />
      <div className='py-3 has-header'>
        <AdvancedFilters
          downloadData={downloadData}
          withExport={true}
          filters={[]}
          onClearAll={() => {}}
        />
        <div className='col-12'>
          <Lists
            list={data}
            order={filters}
            changeOrder={onChangeOrder}
            fetch={fetch}
            onItemRemoveDisabled={removeFetch}
            onItemRemoveConfirm={onItemRemoveConfirm}
          />
        </div>
        {pageCount > 1 && !fetch && (
          <ReactPaginate
            previousLabel={<IconArrowLeft />}
            nextLabel={<IconArrowRight />}
            breakLabel={'...'}
            forcePage={Number(filters.page)}
            breakClassName={'break-me'}
            pageCount={pageCount}
            marginPagesDisplayed={3}
            pageRangeDisplayed={3}
            onPageChange={onPageChange}
            containerClassName={`pagination show-arrow`}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
          />
        )}
      </div>
    </div>
  );
};

export default Surveys;
