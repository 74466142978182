import React from 'react';
import ReactTooltip from 'react-tooltip';
import { useSnackbar } from 'notistack';
import EditingInput from 'shared/components/editingForms/EditingInput';
import EditingTextArea from 'shared/components/editingForms/EditingTextArea';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import attachIcon from 'assets/attach.svg';
import WizardImageUpload from 'shared/components/WizardImageUpload';

const ProductGeneralStep = ({ form, setForm, data, setData, isRequired }) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = ({ target }) => {
    const { name, value, checked, type } = target;
    const newForm = { ...form };

    const val = type === 'checkbox' ? (checked ? 1 : 0) : value;
    newForm[name] = val;
    setForm(newForm);
  };

  const onImageUpload = async e => {
    try {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append('file', file);
      formData.append('isdraft', 'true');
      const res = await Api.post('/upload', formData);
      setData({ ...data, uploadImage: res?.data?.data || null });
      setForm({
        ...form,
        uploadImageId: res?.data?.data?.id || null,
        imageUrl: res?.data?.data?.url || null,
      });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const productImg = data.uploadImage && data.uploadImage.url;

  return (
    <div className='px-2'>
      <h6 className='color-blue mb-3'>Product General Settings</h6>
      <div className='col-9 p-0 user-creation-form'>
        <ul className='p-0 mb-0'>
          <li className='d-flex align-items-center'>
            <div className='col-4 pl-0'>
              <label className='m-0'>Product Name*:</label>
            </div>
            <EditingInput
              type='text'
              name='name'
              value={form.name}
              onChange={handleChange}
              className='p-0'
              placeholder='Enter Product Name'
              required
              editing={true}
              maxLength='180'
            />
          </li>
          <li className='d-flex'>
            <div className='col-4 pl-0 pt-1'>
              <label className='m-0'>Description*:</label>
            </div>
            <EditingTextArea
              type='text'
              name='description'
              value={form.description}
              onChange={handleChange}
              className='p-0 is-large'
              placeholder='Enter Product Description'
              required
              editing={true}
              access={true}
              maxLength='1024'
            />
          </li>
          <WizardImageUpload
            image={productImg}
            colSize='col-4'
            title='Product Image'
            isRequired
            onImageUpload={onImageUpload}
          />
          <li className='d-flex align-items-center'>
            <div className='col-4 pl-0'>
              <label className='m-0'>Product Link:</label>
            </div>
            <EditingInput
              type='text'
              name='productLink'
              value={form?.productLink}
              onChange={handleChange}
              className='p-0'
              placeholder='Enter Link'
              required={isRequired}
              editing={true}
              access={true}
            />
          </li>
        </ul>
      </div>
      <ReactTooltip effect='solid' type='light' multiline={true} backgroundColor='#FFF' />
    </div>
  );
};

export default ProductGeneralStep;
