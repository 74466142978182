/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { TimeLimitIcon } from 'shared/components/Icons';

export const StepItem = ({ step, index, selectedStep, handleSelect }) => {
  const backgroundColor = selectedStep?.id === step.id ? '#131F6B' : '#EFF3FC';
  const color = selectedStep?.id === step.id ? '#fff' : step.color;

  return (
    <div
      style={{
        borderColor: step.color,
        backgroundColor,
      }}
      className={`view_step_item ${selectedStep?.id === step.id && 'inner_white'}`}
      onClick={() => handleSelect(step)}
    >
      <span className={`required_sign ${!step.required && 'not_visible'}`}>*</span>
      <h3 className='step_number'>Step {index + 1}</h3>
      <span className='group_txt'>Question Group</span>
      <span style={{ color }} className='group_name'>
        {step.questionGroup}
      </span>
      <div className='time_limit_block'>
        <TimeLimitIcon
          color={selectedStep?.id === step.id ? '#fff' : '#131F6B'}
          width='12'
          height='12'
        />
        <span className='limit_name'>Time Limitation</span>
        <span className='limit_value'>{step.timeLimit ? step.timeLimit + ' min' : 'No'}</span>
      </div>
    </div>
  );
};
