import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { getError, getImageUrl } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import EditingCheckBox from 'shared/components/editingForms/EditingCheckBox';
import { IconSearch } from 'shared/components/Icons';
import ResponsiveImage from 'shared/components/ResponsiveImage';

const EventAuthorsStep = ({ form, setForm }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [authors, setAuthors] = useState();
  const [search, setSearch] = useState('');

  const getAuthors = async () => {
    try {
      const { data } = await Api.get('/wizard/authors');
      setAuthors(data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onSelectAuthors = items => {
    setForm({ ...form, faculties: items });
  };

  const onSelectItem = item => {
    const ids = [...form.faculties];
    if (ids.includes(item.authorId)) {
      ids.splice(ids.indexOf(item.authorId), 1);
    } else {
      ids.push(item.authorId);
    }
    onSelectAuthors(ids);
  };

  const filterBySearch = (devices, search) => {
    if (!devices) return [];
    return devices.filter(i => {
      const key1 = i.firstName.toLowerCase();
      const key2 = i.lastName.toLowerCase();
      const searchLower = search.toLowerCase();
      return key1.includes(searchLower) || key2.includes(search);
    });
  };

  const sortSelectedFirst = devices => {
    return devices.reduce((acc, item) => {
      if (form.faculties.includes(item.authorId)) {
        return [item, ...acc];
      }
      return [...acc, item];
    }, []);
  };

  useEffect(() => {
    getAuthors();
    //eslint-disable-next-line
  }, []);

  const filteredAuthors = filterBySearch(authors, search);
  const sortedAuthors = sortSelectedFirst(filteredAuthors);

  return (
    <div className='px-2'>
      <h6 className='color-blue mb-2'>Select Faculties</h6>
      <p className='mb-4'>Choose Faculty that you want to be included in the event</p>
      <div className='search-block col-8 p-0'>
        <input
          type='text'
          className='form-control w-100'
          placeholder='Enter Faculty Name For Search'
          onChange={({ target }) => setSearch(target.value)}
        />
        <IconSearch />
      </div>
      <div className='content-scroll-max mt-4 flex-fill col-8'>
        {sortedAuthors &&
          sortedAuthors.map((item, i) => {
            return (
              <label
                key={i}
                className='border-bottom pb-2 pointer w-100 d-flex align-items-center justify-content-between'
                htmlFor={`radiocheck${i}`}
              >
                <div className='d-flex align-items-center course-authors-row'>
                  <div className={`image-container`}>
                    <ResponsiveImage imgUrl={getImageUrl(item.imageUrl)} alt='img' />
                  </div>
                  <div className='author-name mb-1'>
                    <h5>
                      {item.firstName} {item.lastName}{' '}
                      <span className='ml-2'>{item.profession}</span>
                    </h5>
                    <p className='text-body'>{item.info}</p>
                  </div>
                </div>
                <EditingCheckBox
                  onChange={onSelectItem.bind(null, item)}
                  name={`check${i}`}
                  checked={form.faculties.includes(item.authorId)}
                  editing={true}
                />
              </label>
            );
          })}
      </div>
      <p className='case-count mt-3'>Select Faculties: {form.faculties.length}</p>
    </div>
  );
};

export default EventAuthorsStep;
