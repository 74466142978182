import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import FooterButtons from './components/FooterButtons';
import Header from './components/Header';
import SurveyGeneralStep from './components/SurveyGeneralStep';
import SurvayCompleteStep from './components/SurvayCompleteStep';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import SurveyQuestionStep from './components/SurveyQuestionStep';
import Loading from 'shared/components/Loading';
import { duplicate } from 'utils/appHelpers';

const SurveysNew = ({ history, match }) => {
  const { id } = match.params;
  const { enqueueSnackbar } = useSnackbar();
  const [survay, setSurvay] = useState();
  const [fetching, setFetching] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [data, setData] = useState({});
  const [form, setForm] = useState({
    name: '',
    subject: '',
    mode: 1,
  });

  const steps = [SurveyGeneralStep, SurveyQuestionStep, SurvayCompleteStep];

  const constructFormData = form => {
    const result = duplicate(form);
    result.sets = [...result.surveySets];
    delete result.surveySets;
    result.sets.forEach((qSet, i) => {
      result.sets[i].mandatory = qSet.mandatory ? 1 : 0;
      result.sets[i].ipaD_Pair = Number(qSet.ipaD_Pair);
      result.sets[i].trigger = Number(qSet.trigger);
      result.sets[i].triggerValue = Number(qSet.triggerValue);
      result.sets[i].questions = [...(qSet.surveySetQuestons || [])];
      delete qSet.surveySetQuestons;
      qSet.questions.forEach((qu, qi) => {
        qSet.questions[qi].questionType = Number(qu.questionType);
        qSet.questions[qi].imageType = Number(qu.imageType);
        if (qu.surveySetQuestonAnswers?.length) {
          qu.answers = qu.surveySetQuestonAnswers.map(i => i.answer).filter(i => i);
          delete qu.surveySetQuestonAnswers;
        }
      });
    });
    return result;
  };

  const onSaveData = async (e, formData) => {
    try {
      e && e.preventDefault();
      setFetching(true);
      const body = formData || constructFormData({ ...form, mode: 2 });
      const endpoint = id ? '/survey/update' : '/survey/init';
      await Api.post(endpoint, { ...body, originId: id ? id : undefined });
      enqueueSnackbar(`Survey successfully ${id ? 'updated' : 'created'}`, { variant: 'success' });
      history.push('/surveys');
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      setFetching(false);
    }
  };

  const onSaveDraft = () => {
    const draftForm = constructFormData({ ...form, mode: 1 });
    onSaveData(null, draftForm);
  };

  const onGoBackStep = () => {
    setActiveStep(step => step - 1);
  };

  const handleStepChange = async e => {
    e.preventDefault();
    setActiveStep(step => step + 1);
  };

  const setSurvayInitialData = item => {
    setForm({
      name: item.name || '',
      subject: item.subject || '',
      mode: item.mode,
      surveySets: item.surveySets || [],
    });
    setData({});
  };

  const getSurveyData = async id => {
    try {
      const { data } = await Api.get(`/survey/getsurvey/${id}`);
      setSurvay(data.data);
      setSurvayInitialData(data.data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      history.push('/surveys');
    }
  };

  const onPreviewClick = () => {};

  useEffect(() => {
    if (id) getSurveyData(id);
    //eslint-disable-next-line
  }, [id]);

  const isLastStep = activeStep === steps.length - 1;
  const Step = steps[activeStep];

  if (id && !survay) return <Loading className='mt-5' />;

  const canSaveDraft = form.mode === 1 || !id;

  return (
    <div className='lesson-creation'>
      <Header
        steps={steps}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        form={form}
        onPreviewClick={onPreviewClick}
      />
      <form
        className='py-3 has-header d-flex flex-column justify-content-between min-vh-100'
        onSubmit={handleStepChange}
      >
        <div className='col-12'>
          <Step
            form={form}
            setForm={setForm}
            data={data}
            setData={setData}
            onSaveData={onSaveData}
            onSaveDraft={onSaveDraft}
            canSaveDraft={canSaveDraft}
            fetching={fetching}
            isEditing={!!id}
          />
        </div>
        <FooterButtons
          fetching={fetching}
          onGoBackStep={onGoBackStep}
          activeStep={activeStep}
          isLastStep={isLastStep}
        />
      </form>
    </div>
  );
};

export default SurveysNew;
