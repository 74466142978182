import React, { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useSnackbar } from 'notistack';

import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';

import EditingInput from 'shared/components/editingForms/EditingInput';
import Loading from 'shared/components/Loading';
import RolesHeader from './components/RolesHeader';
import EditingTextArea from 'shared/components/editingForms/EditingTextArea';
import PermissionsList from './components/PermissionsList';
import Button from 'shared/components/Button';

export const initRolesData = [{ name: 'Manager', value: 1 }, { name: 'Member', value: 2 }];

const EntityUserNew = ({ match, history, location }) => {
  const sampleId = location?.state?.sampleId;
  const isUpdate = !!match.params.id;
  const { enqueueSnackbar } = useSnackbar();
  const [fetching, setFetching] = useState();
  const [permissions, setPermissions] = useState();
  const [role, setRole] = useState({
    title: '',
    description: '',
    permissions: [],
  });
  const [assignedUsersCount, setAssignedUsersCount] = useState(0);

  const handleChange = ({ target }) => {
    const { name, value } = target;
    const tempForm = { ...role };
    tempForm[name] = value;
    setRole(tempForm);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      setFetching(true);
      const permissions = Object.keys(role.permissions).map(key => ({
        accessLevel: role.permissions[key],
        permissionItemId: key,
      }));
      const data = { ...role, permissions };
      const body = isUpdate ? { ...data, originId: match.params.id } : data;
      await Api[isUpdate ? 'put' : 'post']('/admin/role', body);
      enqueueSnackbar(`Successfully ${isUpdate ? 'updated' : 'created'}`, { variant: 'success' });
      history.push('/roles/all');
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      setFetching(false);
    }
  };

  const handleOpenWarning = e => {
    confirmAlert({
      overlayClassName: 'with-icon',
      title: 'Confirmation Needed',
      message:
        'Please note that changes are going to be applied on currently assigned users. And based on changed permissions they may lose access to some resources. \n\nIf you are ready, you can go ahead and Save the changes. Click Cancel if you want to stay on the page and make other changes.',
      buttons: [
        {
          label: 'Cancel',
          className: 'regular_modal_cancel',
        },
        {
          label: 'Save',
          onClick: () => handleSubmit(e),
        },
      ],
    });
  };

  const getRoleData = async id => {
    try {
      const resUsers = await Api.get(`/admin/role/${id}/assigned-users`);
      const res = await Api.get(`/admin/role/${id}`);
      const data = res.data.data;
      const permissions = {};
      data.rolePermissions.forEach(item => {
        permissions[item.permissionItem.key] = item.accessLevel;
      });
      data.permissions = permissions;
      setAssignedUsersCount(resUsers.data.data.count);
      setRole({
        title: data.title,
        description: data.description,
        permissions: permissions,
        isReadOnly: data.isReadOnly,
      });
      setPermissions(
        data.rolePermissions.map(permission => ({
          ...(permission || {}),
          ...(permission?.permissionItem || {}),
        })),
      );
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const getPermissions = async (permissions = {}) => {
    try {
      const res = await Api.get(`/admin/permissions`);
      const allPermissions = res.data.data;
      const defaultPermissionsObj = {};
      allPermissions.forEach(perm => {
        defaultPermissionsObj[perm.key] = 0;
      });
      setPermissions(allPermissions);
      setRole(prev => ({
        ...prev,
        permissions: { ...defaultPermissionsObj, ...permissions },
      }));
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  useEffect(() => {
    if (isUpdate || sampleId) {
      getRoleData(match.params.id || sampleId);
    } else {
      getPermissions();
    }
    //eslint-disable-next-line
  }, [match.params.id]);

  if (isUpdate && !role) return <Loading className='mt-5' />;

  return (
    <div>
      <RolesHeader isUpdate={isUpdate} />
      <form className='py-3 has-header' onSubmit={!assignedUsersCount ? handleSubmit : () => {}}>
        <div className='col-7 user-creation-form'>
          <ul className='p-0 mb-0'>
            <li className='d-flex align-items-center'>
              <div className='col-3 pl-0'>
                <label className='m-0'>Name:</label>
              </div>
              <EditingInput
                type='text'
                name='title'
                value={role.title}
                onChange={handleChange}
                className='p-0'
                placeholder='Enter Title'
                required
                editing={true}
                disabled={!sampleId && isUpdate ? role.isReadOnly : false}
              />
            </li>
            <li className='d-flex'>
              <div className='col-3 pl-0 pt-1'>
                <label className='m-0'>Description:</label>
              </div>
              <EditingTextArea
                type='text'
                name='description'
                value={role.description}
                onChange={handleChange}
                className='p-0 is-large'
                placeholder='Enter Description'
                editing={true}
              />
            </li>
          </ul>
          <h5 className='color-blue mb-3 mt-4'>Role Permissions</h5>
        </div>
        <div className='col'>
          <PermissionsList items={permissions} role={role} setRole={setRole} />
        </div>
        <div className='col text-right'>
          <Button
            className='btn btn-primary px-4 fz-12 font-weight-bold'
            type={assignedUsersCount ? 'button' : 'submit'}
            disabled={fetching}
            onClick={assignedUsersCount ? handleOpenWarning : () => {}}
          >
            {isUpdate ? 'Update' : 'Save'}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default EntityUserNew;
