import React, { useEffect, useState, useCallback } from 'react';
import ReactPaginate from 'react-paginate';
import { useSnackbar } from 'notistack';

import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';

import Header from './components/Header';
import Lists from './components/Lists';
import useDebounce from 'shared/hooks/useDebounce';
import { IconArrowLeft, IconArrowRight } from 'shared/components/Icons';
import { confirmAlert } from 'react-confirm-alert';

const MarketingSpotlights = ({ match }) => {
  const { filter } = match.params;
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState([]);
  const [activeSpotlight, setActiveSpotlight] = useState(null);
  const [order, setOrder] = useState({ order: false, orderKey: 'updatedAt' });
  const [fetch, setFetch] = useState(false);
  const [removeFetch, setRemoveFetch] = useState(false);
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);

  const getMarketingSpotlights = useCallback(
    async needSetOnlyActive => {
      try {
        if (!needSetOnlyActive) {
          setFetch(true);
        }
        const params = { ...order, page: page + 1, limit: 8, search: debouncedSearch };
        const { data } = await Api.get(`/marketing-spotlight/all`, { params });
        if (data?.data) {
          if (!needSetOnlyActive) {
            setData(data.data.results.results);
            setPageCount(Math.ceil(data.data.results.count / 8));
          }
          setActiveSpotlight(data.data.active);
        }
      } catch (err) {
        enqueueSnackbar(getError(err), { variant: 'error' });
      } finally {
        setFetch(false);
      }
    },
    [debouncedSearch, enqueueSnackbar, order, page, filter],
  );

  const onPageChange = ({ selected }) => {
    setPage(selected);
  };

  const handleSearchChange = text => {
    setSearch(text);
  };

  const onItemRemove = async item => {
    try {
      setRemoveFetch(true);
      await Api.delete(`/marketing-spotlight/${item.id}`);
      await getMarketingSpotlights();
      enqueueSnackbar('Successfully removed', { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setRemoveFetch(false);
    }
  };

  const onItemRemoveConfirm = item => {
    confirmAlert({
      title: 'Confirmation Required',
      message: 'Are you sure you want to delete this Spotlight?',
      buttons: [
        {
          label: 'Cancel',
        },
        {
          label: 'Delete',
          onClick: () => onItemRemove(item),
        },
      ],
    });
  };

  const onEnable = async spotlight => {
    try {
      await Api.put(`/marketing-spotlight/enable/${spotlight.id}`);
      getMarketingSpotlights();
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onConfirmEnable = item => {
    confirmAlert({
      title: 'Confirmation Required',
      message: `Are you sure you want to disable ${activeSpotlight.name}`,
      buttons: [
        {
          label: 'Cancel',
        },
        {
          label: 'Confirm',
          onClick: () => onEnable(item),
        },
      ],
    });
  };

  const handleSwitchEnabled = async spotlight => {
    try {
      const newStatus = spotlight.enabled ? 'disable' : 'enable';
      if (newStatus === 'enable' && activeSpotlight) {
        onConfirmEnable(spotlight);
      } else {
        await Api.put(`/marketing-spotlight/${newStatus}/${spotlight.id}`);
        setData(prevData =>
          prevData.map(spt =>
            spt.id === spotlight.id ? { ...spt, enabled: !spotlight.enabled } : spt,
          ),
        );
        const needSetOnlyActive = true;
        await getMarketingSpotlights(needSetOnlyActive);
      }
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  useEffect(() => {
    getMarketingSpotlights();
  }, [getMarketingSpotlights, page, order, filter]);

  return (
    <div>
      <Header onSearch={handleSearchChange} />
      <div className='py-3 has-header'>
        <div className='col-12'>
          <Lists
            data={data}
            order={order}
            changeOrder={setOrder}
            fetch={fetch}
            handleSwitchEnabled={handleSwitchEnabled}
            onItemRemoveConfirm={onItemRemoveConfirm}
          />
        </div>
        {pageCount > 1 && !fetch && (
          <ReactPaginate
            previousLabel={<IconArrowLeft />}
            nextLabel={<IconArrowRight />}
            breakLabel={'...'}
            forcePage={page}
            breakClassName={'break-me'}
            pageCount={pageCount}
            marginPagesDisplayed={3}
            pageRangeDisplayed={3}
            onPageChange={onPageChange}
            containerClassName={`pagination show-arrow`}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
          />
        )}
      </div>
    </div>
  );
};

export default MarketingSpotlights;
