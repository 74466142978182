import React from 'react';

import ClickArea from 'shared/components/ClickArea';
import ListActionButtons from 'shared/components/ListActionButtons';
import Loading from 'shared/components/Loading';

import { formatDate } from 'utils/appHelpers';
import { hasAccess } from 'utils/permissionHelper';

const headers = [
  {
    name: 'Country',
    className: 'col',
    key: 'country',
  },
  {
    name: 'code',
    className: 'col',
    key: 'code',
  },
  {
    name: 'Status',
    className: 'col',
    key: 'status',
  },
  {
    name: 'Responsible Admin',
    className: 'col',
    key: 'admin',
  },
  {
    name: 'Enabling Date',
    className: 'col',
    key: 'updatedAt',
  },
  {
    name: 'Actions',
    className: 'p-2 justify-content-start d-flex action-buttons-container min_60',
    key: '',
  },
];

const types = {
  0: 'Disabled',
  1: 'Enabled',
};

const actions = [
  {
    type: 'link',
    icon: 'edit',
    title: 'Edit',
    to: item => `/system/countries/${item.id}/edit`,
    hide: () => !hasAccess('system_countries_create'),
  },
];

const Lists = ({ data, order, changeOrder, fetch }) => {
  if (fetch) return <Loading className='mt-5' />;
  if (!data || !data.length)
    return <div className='text-black-50 text-center mt-4'>No Results Found</div>;

  const handleOrderChange = item => {
    if (!item.key) return;
    changeOrder({
      orderKey: item.key,
      order: !order.order,
    });
  };

  return (
    <div className='users-lists'>
      <ul className='p-0'>
        <li className='d-flex list-header align-items-center'>
          {headers.map((item, index) => (
            <ClickArea
              key={item.name + index}
              className={`d-flex text-body justify-content-between align-items-center ${
                item.className
              }`}
              onClick={handleOrderChange.bind(null, item)}
            >
              {item.name}
              {item.key && order.orderKey === item.key && (
                <button
                  className={`btn ml-2 p-0 dropdown-toggle ${order.order ? '' : 'rotate-180'}`}
                />
              )}
            </ClickArea>
          ))}
        </li>
        {data &&
          data.map((item, index) => {
            return (
              <li key={index} className='d-flex align-items-center list-item h-not-set'>
                <div className='col text-truncate' title={item.country.name}>
                  {item.country.name}
                </div>
                <div className='col text-truncate'>{item.country.iso}</div>
                <div className='col'>{types[item.status]}</div>
                <div className='col'>{item?.responsibleAdmin?.fullName}</div>
                <div className='col'>{formatDate(item.updatedAt)}</div>
                <div className='p-2 justify-content-start d-flex action-buttons-container min_60'>
                  <ListActionButtons actions={actions} item={item} />
                </div>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default Lists;
