import React from 'react';
import WizardSteps from 'shared/components/WizardSteps';

export const Header = ({ steps, activeStep, setActiveStep, form }) => {
  return (
    <div className='users-header d-flex justify-content-between align-items-center'>
      <WizardSteps steps={steps} activeStep={activeStep} setActiveStep={setActiveStep} />
      <div className='mt-1 d-flex align-items-center'>{/* <ModeStatus mode={form.mode} /> */}</div>
    </div>
  );
};
