import React, { useEffect } from 'react';
import { useState } from 'react';
import { createPortal } from 'react-dom';
import {
  IconClose,
  IconDash3DQCourse,
  IconDashHourCourse,
  IconDashUsers,
} from 'shared/components/Icons';
import { useSnackbar } from 'notistack';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import Loading from 'shared/components/Loading';
import AppChart from 'shared/components/Chart';
import { Bar, Chart } from 'react-chartjs-2';
Chart.defaults.global.defaultFontColor = '#222222';

const EventStatistics = ({ onClose, eventId }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [barVar, setBarVar] = useState('Today');
  const [data, setData] = useState();
  const portalElement = document.querySelector('#main-portal');

  const getData = async () => {
    try {
      const { data } = await Api.get(`events/statistics/${eventId}`);
      setData(data.data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  useEffect(() => {
    getData();
  }, []);

  if (!data)
    return (
      <div className='event-statistics'>
        <div className='stats-block'>
          <Loading className={'mt-5'} />
        </div>
      </div>
    );

  const labels = data.visitByLocation.map(item => item.state);
  const dataArr = data.visitByLocation.map(item => item.total);
  const total = dataArr.reduce((a, b) => a + b, 0);
  const chartProps = { id: 'statusesChart', data: dataArr, labels: labels };

  const barVariants = [
    { name: 'Today', vKey: 'todayVisits', rKey: 'todayVisits' },
    { name: 'Summary', vKey: 'summaryVisits', rKey: 'summaryVisits' },
    { name: '10 Days', vKey: 'tenDaysVisits', rKey: 'tenDaysVisits' },
  ];
  const active = barVariants.find(i => i.name === barVar);

  const barData = {
    labels: ['Registered Users', 'Visitors'],
    datasets: [
      {
        backgroundColor: ['#FFC84C', '#78A0FE'],
        scaleFontColor: '#222222',
        borderColor: ['#FFC84C', '#78A0FE'],
        borderWidth: 1,
        data: [data.visitor[active.vKey], data.registeredUsers[active.rKey]],
      },
    ],
  };

  const barOptions = {
    legend: { display: false },
    scales: {
      yAxes: [
        {
          ticks: {
            stepSize: 1,
            beginAtZero: true,
          },
        },
      ],
    },
  };

  const rednerComponent = () => {
    return (
      <div className='event-statistics'>
        <div className='stats-block'>
          <div className='d-flex align-items-center justify-content-between mb-4'>
            <p className='mb-0 fw-500'>Events statistics</p>
            <button className='btn p-0' onClick={onClose}>
              <IconClose color='#131F6B' />
            </button>
          </div>
          <div>
            <div className='event-tabs'>
              <div className='d-flex mb-2'>
                <div className={`event-tab tab-orange`}>
                  <IconDashHourCourse width='24' height='24' />
                  <h4>{data.registrations}</h4>
                  <p className='mb-0'>Registrasions</p>
                </div>
                <div className={`event-tab tab-blue ml-2`}>
                  <IconDashHourCourse width='24' height='24' color='#477EFF' />
                  <h4>{data.visitors}</h4>
                  <p className='mb-0'>Visitors</p>
                </div>
              </div>
              <div className='d-flex'>
                <div className={`event-tab tab-red`}>
                  <IconDashUsers width='24' height='24' color='#EB4D4D' />
                  <h4>{data.uniqueVisitors}</h4>
                  <p className='mb-0'>Unique Visitors</p>
                </div>
                <div className={`event-tab tab-green ml-2`}>
                  <IconDash3DQCourse width='24' height='24' color='#349A4C' />
                  <h4>{data.programViewers}</h4>
                  <p className='mb-0'>Program Viewers</p>
                </div>
              </div>
            </div>
            <div className='event-visitors'>
              <p className='mb-0 fw-500 fz-12'>Registrations and Visits</p>
              <div className='mt-3'>
                <Bar data={barData} width={315} height={200} options={barOptions} />
                <div className='d-flex align-items-center justify-content-around'>
                  {barVariants.map((item, i) => (
                    <button
                      className={`btn ${item.name === barVar ? 'btn-blue' : ''} fz-12 fw-500`}
                      key={i}
                      onClick={() => setBarVar(item.name)}
                    >
                      {item.name}
                    </button>
                  ))}
                </div>
                <ul className='status-explanation-list is-event-view d-flex mt-3 mb-0 pl-4'>
                  <li>
                    <span className='d-block text-truncate d-flex justify-content-between'>
                      Registered Users
                    </span>
                  </li>
                  <li className='ml-5'>
                    <span className='d-block text-truncate d-flex justify-content-between'>
                      Visitors
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className='event-location'>
              <p className='mb-0 fw-500 fz-12'>By Location</p>
              <div className='w-100 mt-3'>
                <div className='d-flex align-items-center'>
                  <div className='col-5 p-0 doughnut-chart'>
                    <AppChart {...chartProps} />
                    <h4 className='center-text text-center'>
                      Total <br />
                      {total}
                    </h4>
                  </div>
                  <div className='col-7 pr-0 d-flex flex-column justify-content-between'>
                    <div className='d-flex justify-content-center'>
                      <ul className='col-9 mb-0 pl-3 status-explanation-list font-size-13'>
                        {labels.map((item, index) => (
                          <li key={item + index}>
                            <span className='d-block text-truncate d-flex justify-content-between'>
                              {item} <span className='font-weight-bold'>{dataArr[index]}</span>
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return portalElement ? createPortal(rednerComponent(), portalElement) : null;
};

export default EventStatistics;
