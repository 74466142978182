import React from 'react';
import { Link } from 'react-router-dom';
import ModeStatus from 'shared/ModeStatus';

const CourseViewHeader = ({ title, isPreview, mode }) => {
  return (
    <div className='users-header d-flex justify-content-between align-items-center'>
      <div className='d-flex breadcrumbs'>
        <Link to='/courses'>All Courses</Link>
        <span className='mx-2'>/</span>
        <span>{isPreview ? 'Preview' : title}</span>
      </div>
      <div>
        <ModeStatus mode={mode} />
      </div>
    </div>
  );
};

export default CourseViewHeader;
