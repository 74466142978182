import FileSaver from 'file-saver';
import { HOST } from 'configs/host';
import placeholder from 'assets/avatar.png';
import moment from 'moment';
import { extendMoment } from 'moment-range';

export const getError = err => {
  if (err?.response?.data?.data && Array.isArray(err.response.data.data)) {
    return err.response.data.data.map(error => `- ${error}`).join('\n');
  }

  if (err?.response?.data?.message) {
    return err.response.data.message;
  }
  if (err?.response?.statusText) {
    return err.response.statusText;
  }
  if (err?.data?.message) {
    return err.data.message;
  }
  if (err?.message) {
    return err.message;
  }

  return String(err);
};

export const bindDurationFullFormat = duration => {
  const hours = Math.floor(duration / 60 / 60);
  const minutes = Math.floor(duration / 60 - hours * 60);
  const seconds = Math.floor(duration % 60);
  const round = val => (String(val).length === 1 ? `0${val}` : val);
  return `${round(hours)}:${round(minutes)}:${round(seconds)}`;
};

export const bindDuration = duration => {
  const hours = Math.floor(duration / 60 / 60);
  const minutes = Math.floor(duration / 60 - hours * 60);
  const seconds = Math.floor(duration % 60);
  const round = val => (String(val).length === 1 ? `0${val}` : val);
  return `${hours ? round(hours) + ':' : ''}${round(minutes)}:${round(seconds)}`;
};

export const bindDurationMinutes = duration => {
  const minutes = Math.floor(duration / 60);
  const seconds = Math.floor(duration % 60);
  const round = val => (val < 10 ? `0${val}` : val);
  return `${round(minutes)}:${round(seconds)}`;
};

export const arrSum = (arr, key) => {
  return arr.reduce((a, b) => parseInt(a, 10) + (parseInt(b[key], 10) || 0), 0);
};

export const getImageUrl = image => {
  if (!image) return placeholder;
  return image.includes('http') ? `${image}?v=${Date.now()}` : `${HOST.API.URL}/${image}`;
};

export const duplicate = data => JSON.parse(JSON.stringify(data));

export const parseJson = data => {
  if (!data || data.length < 10) return {};
  return JSON.parse(data);
};

export const getBase64 = (file, cb) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function() {
    cb(reader.result);
  };
};

export const formatDate = date => {
  if (!date) return '-';
  return moment(date).format('MM/DD/YYYY');
};

export const formatToDateInput = date => {
  if (!date) return '-';
  return moment(date).format('YYYY-MM-DD');
};

export const formatDateWithDots = date => {
  if (!date) return '-';
  return moment(date).format('MM.DD.YYYY');
};

export const getEndOfSubs = (date, notExpired) => {
  if (notExpired) return 'No Expiration';
  const day = moment(date).diff(new Date(), 'days');
  const exDate = moment(date).format('MM/DD/YYYY');
  return day <= 0 ? `Expired on ${exDate}` : `${day} days left`;
};

export const saveFile = (fileData, fileName) => FileSaver.saveAs(fileData, fileName);

export const courseSubsStatuses = (id, status) => {
  // 1: 'Accepted',
  // 2: 'Unsubscribed',
  // 3: 'Pendding',
  // 4: 'Declined',
  // 5: 'Expired',
  // 6: 'PendingExtend',
  // 7: 'AcceptedExtended',
  // 8: 'DeclinedExtend',
  const statuses = {
    active: [1, 7],
    pending: [3, 6],
    inactive: [2, 4, 8],
    expired: [5],
    declined: [4, 8],
  };

  return statuses[status].includes(id);
};

export const imageUploadProgress = (setProgress, progressEvent) => {
  setProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
};

export const getEpisodeStartTime = (data, index, noFormat) => {
  if (index === 0) return noFormat ? 0 : bindDurationMinutes(0);
  const prevItems = [...data];
  prevItems.splice(index, data.length - index);
  const prevItemsSum = arrSum(prevItems, 'duration');
  return noFormat ? prevItemsSum : bindDurationMinutes(prevItemsSum);
};

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const checkSubsCourseOpened = item => {
  const sub = item.subscription;
  const course = sub && sub.courses && sub.courses[0] && sub.courses[0].course;
  return course && course.isOpened;
};

export const openFullscreen = () => {
  const elem = document.documentElement;
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.mozRequestFullScreen) {
    elem.mozRequestFullScreen();
  } else if (elem.webkitRequestFullscreen) {
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) {
    elem.msRequestFullscreen();
  }
};

export const closeFullscreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen();
  } else if (document.msExitFullscreen) {
    document.msExitFullscreen();
  }
};

export default function debounce(fn, delay = 250) {
  let timeout;

  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      fn(...args);
    }, delay);
  };
}

export const getFormattedDateRange = (startDate, endDate) => {
  const rangeMoment = extendMoment(moment);
  if (!startDate || !endDate) return null;
  return rangeMoment.range(moment.utc(startDate), moment.utc(endDate));
};

const isObject = object => {
  return object != null && typeof object === 'object';
};

export function isDeepEqual(object1, object2) {
  const objKeys1 = Object.keys(object1);
  const objKeys2 = Object.keys(object2);

  if (objKeys1.length !== objKeys2.length) return false;

  for (let key of objKeys1) {
    const value1 = object1[key];
    const value2 = object2[key];

    const isObjects = isObject(value1) && isObject(value2);

    if ((isObjects && !isDeepEqual(value1, value2)) || (!isObjects && value1 !== value2)) {
      return false;
    }
  }
  return true;
}

export const getLicensesDefaultRange = () => {
  const defaultStartDate = moment().startOf('day');
  const defaultEndDate = moment()
    .startOf('day')
    .add(10, 'days');
  const defaultRange = {
    start: defaultStartDate.toISOString(),
    end: defaultEndDate.toISOString(),
  };

  return defaultRange;
};
