import React, { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useSnackbar } from 'notistack';

import { adaptQuestionGroups, adaptSettingQGroups } from './adapters';
import { validateGroupsConfiguration } from './validators';
import { scrollHorizontallyToContainerEnd } from 'utils/domHelpers';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import { QUESTION_TYPES, QUESTION_TYPES_OBJ } from './constants';
import Button from 'shared/components/Button';
import SetsBlock from './components/SetsBlock';
import EditingInput from 'shared/components/editingForms/EditingInput';
import EditingTextArea from 'shared/components/editingForms/EditingTextArea';
import EditingSelect from 'shared/components/editingForms/EditingSelect';
import DragList from 'shared/components/DragList';
import DragItem from 'shared/components/DragItem';
import { RightSidebar } from './components/RightSidebar';
import Loading from 'shared/components/Loading';
import { ImageCategoryDnDBlock } from './components/ImageCategoryDnDBlock';

import { ReactComponent as RemoveIcon } from 'assets/bin.svg';
import { RemoveRedIcon } from 'shared/components/Icons';
import { getQuestionGroupProperties } from './helpers';
import NavigableHeaderRoutes from 'shared/components/NavigableHeaderRoutes';

const emptyAnswer = { answer: '' };

const getEmptyQuestionObj = () => ({
  question: '',
  answers: [{ ...emptyAnswer }],
  type: QUESTION_TYPES_OBJ.yesno,
});

const getInitialSet = () => ({
  name: '',
  description: '',
  questions: [getEmptyQuestionObj()],
});

export const CourseQuizQuestionGroupsConfig = ({ history, match, location, questionSets }) => {
  const defaultSelectedGroupId = location.state?.selectedGroupId || null;
  const { enqueueSnackbar } = useSnackbar();
  const [course, setCourse] = useState(location?.state?.course || {});
  const [currentGroupIdx, setCurrentGroupIdx] = useState(0);
  const [qGroups, setQGroups] = useState(questionSets ? [...questionSets] : [getInitialSet()]);
  const [isFetching, setIsFetching] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const currentQGroup = qGroups[currentGroupIdx] || {};
  const currentQuestions = currentQGroup?.questions || [];
  const filledTitledQuestions = currentQuestions?.length
    ? currentQuestions.filter(q => q.title)
    : [];
  const courseId = match.params.id;
  const QGROUP_PROPERTIES = getQuestionGroupProperties(currentQGroup, filledTitledQuestions);

  const headerRouteItems = [
    { label: 'Courses', path: '/courses/all' },
    { label: course.title, path: `/course/${courseId}/view` },
    { label: 'Quiz', path: `/course/${courseId}/quiz-view` },
    { label: 'Question Groups Configuration' },
  ];

  const getCourseData = async () => {
    try {
      setIsFetching(true);
      const { data } = await Api.get(`/courses/getcourse/${courseId}`);
      setCourse(data.data);
    } catch (err) {
      history.push('/no-access');
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setIsFetching(false);
    }
  };

  const getQGroupsData = async () => {
    try {
      const { data } = await Api.get(`/courses/${courseId}/question-groups`);
      if (data.data.length) {
        const adaptedGroups = adaptSettingQGroups(data.data);
        setQGroups(adaptedGroups);
        if (defaultSelectedGroupId) {
          const defaultSelectedIdx = adaptedGroups.findIndex(
            group => group.id === defaultSelectedGroupId,
          );
          setCurrentGroupIdx(defaultSelectedIdx !== -1 ? defaultSelectedIdx : 0);
        }
      }
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const goBack = () => history.push(`/course/${courseId}/quiz-view`);

  const handleAddNewGroup = () => {
    const tempSets = [...qGroups];
    tempSets.push(getInitialSet());
    setQGroups(tempSets);
    setCurrentGroupIdx(tempSets.length - 1);
  };

  const setAllAnswersIncorrect = arr =>
    arr.map(item => {
      item.isRightAnswer = false;
      return item;
    });

  const onRemoveGroup = async id => {
    try {
      if (!currentQGroup.id && !currentQGroup.courseId) {
        setQGroups(prevGroups => prevGroups.toSpliced(currentGroupIdx, 1));
      } else {
        await Api.delete(`/courses/${courseId}/question-group/${currentQGroup?.id}`);
        enqueueSnackbar('Successfully deleted', { variant: 'success' });
        await getQGroupsData();
      }
      setCurrentGroupIdx(prevIdx => prevIdx - 1);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const handleChange = ({ target }) => {
    const { name, value } = target;
    const currentQGroupData = { ...currentQGroup, [name]: value };
    setQGroups(prev => prev.toSpliced(currentGroupIdx, 1, currentQGroupData));
  };

  const handleChangeQuestion = ({ name, value }, idx) => {
    setQGroups(prevGroups => {
      const updatedQuestion = {
        ...prevGroups[currentGroupIdx].questions[idx],
        [name]: value,
      };
      if (value === QUESTION_TYPES_OBJ['image-category']) {
        updatedQuestion.unCategorizedImages = updatedQuestion.unCategorizedImages?.length
          ? [...updatedQuestion.unCategorizedImages]
          : [];
        updatedQuestion.imageColumns = updatedQuestion.imageColumns?.length
          ? updatedQuestion.imageColumns
          : [{ id: Date.now(), name: 'General', items: [] }];
      }
      if (
        name === 'type' &&
        updatedQuestion.type !== QUESTION_TYPES_OBJ.yesno &&
        prevGroups[currentGroupIdx].questions[idx].type === QUESTION_TYPES_OBJ.yesno
      ) {
        // If the type is changing and it's moving away from 'yesno', reset answers
        updatedQuestion.answers = [{ ...emptyAnswer }];
        updatedQuestion.correctAnswers = [];
      }

      const currentGroup = {
        ...prevGroups[currentGroupIdx],
        questions: prevGroups[currentGroupIdx].questions.toSpliced(idx, 1, updatedQuestion),
      };
      return [...prevGroups.toSpliced(currentGroupIdx, 1, { ...currentGroup })];
    });
  };

  const handleAddQuestion = () => {
    setQGroups(prevGroups => {
      const currentGroup = {
        ...prevGroups[currentGroupIdx],
        questions: [...prevGroups[currentGroupIdx].questions, getEmptyQuestionObj()],
      };
      return [...prevGroups.toSpliced(currentGroupIdx, 1, { ...currentGroup })];
    });
  };

  const onAddAnwser = index => {
    setQGroups(prevGroups => {
      const updatedQGroups = [...prevGroups];
      const currentQuestion = updatedQGroups[currentGroupIdx].questions[index];

      updatedQGroups[currentGroupIdx].questions[index] = {
        ...currentQuestion,
        answers: [...currentQuestion.answers, { ...emptyAnswer }],
      };

      return updatedQGroups;
    });
  };

  const onAnswerChange = (qIndex, index, { name, value }) => {
    setQGroups(prevGroups => {
      const updatedQGroups = [...prevGroups];
      const currentQuestion = { ...updatedQGroups[currentGroupIdx].questions[qIndex] };

      currentQuestion.answers[index] = {
        ...currentQuestion.answers[index],
        [name]: value,
      };

      updatedQGroups[currentGroupIdx].questions[qIndex] = currentQuestion;

      return updatedQGroups;
    });
  };

  const onRemoveAnswer = (qIndex, aIndex) => {
    setQGroups(prevGroups => {
      const updatedQGroups = [...prevGroups];
      const currentQuestion = { ...updatedQGroups[currentGroupIdx].questions[qIndex] };

      currentQuestion.answers = currentQuestion.answers.filter((_, index) => index !== aIndex);

      updatedQGroups[currentGroupIdx].questions[qIndex] = currentQuestion;

      return updatedQGroups;
    });
  };

  const onDragEnd = (qIndex, result) => {
    const { source, destination } = result;

    // Drop outside the list
    if (!destination) {
      return;
    }

    if (source.index === destination.index) {
      return;
    }

    setQGroups(prevGroups => {
      const updatedQGroups = [...prevGroups];
      const currentQuestion = { ...updatedQGroups[currentGroupIdx].questions[qIndex] };

      // Reorder our answer array
      const [removed] = currentQuestion.answers.splice(source.index, 1);
      currentQuestion.answers.splice(destination.index, 0, removed);

      updatedQGroups[currentGroupIdx].questions[qIndex] = currentQuestion;

      return updatedQGroups;
    });
  };

  const onAnswerCheck = (qIndex, index) => {
    setQGroups(prevGroups => {
      const updatedQGroups = [...prevGroups];
      const currentQuestion = { ...updatedQGroups[currentGroupIdx].questions[qIndex] };
      if (currentQuestion.type === QUESTION_TYPES_OBJ.radio) {
        currentQuestion.answers = setAllAnswersIncorrect(currentQuestion.answers);
      }

      currentQuestion.answers = currentQuestion.answers.map((answer, answerIdx) => ({
        ...answer,
        isRightAnswer: answerIdx === index ? !answer.isRightAnswer : answer.isRightAnswer,
      }));
      updatedQGroups[currentGroupIdx].questions[qIndex] = currentQuestion;

      return updatedQGroups;
    });
  };

  const handleRemoveQuestion = qIdx => {
    setQGroups(prevGroups => {
      const updatedQGroups = [...prevGroups];
      const currentUpdatedQuestions = updatedQGroups[currentGroupIdx].questions.toSpliced(qIdx, 1);

      if (!currentUpdatedQuestions.length) {
        currentUpdatedQuestions.push(getEmptyQuestionObj());
      }

      updatedQGroups[currentGroupIdx].questions = currentUpdatedQuestions;

      return updatedQGroups;
    });
  };

  const handleAddUnCategorizedImages = ({ id, url, fileName, qIdx }) => {
    setQGroups(prevGroups => {
      // Clone the current question
      let currentQuestion = { ...prevGroups[currentGroupIdx].questions[qIdx] };

      // Initialize unCategorizedImages if it doesn't exist
      if (!currentQuestion.unCategorizedImages) {
        currentQuestion.unCategorizedImages = [];
      }

      // Add the new image
      currentQuestion.unCategorizedImages.push({ id, url, fileName });

      // Clone and update the current group
      const currentGroup = {
        ...prevGroups[currentGroupIdx],
        questions: prevGroups[currentGroupIdx].questions
          .slice(0, qIdx)
          .concat([currentQuestion])
          .concat(prevGroups[currentGroupIdx].questions.slice(qIdx + 1)),
      };

      // Update the whole qGroups
      return [
        ...prevGroups.slice(0, currentGroupIdx),
        currentGroup,
        ...prevGroups.slice(currentGroupIdx + 1),
      ];
    });
  };

  const handleUnCategorizedImagesChange = async (e, qIdx) => {
    try {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append('file', file);
      const {
        data: { data },
      } = await Api.post('/upload', formData);
      handleAddUnCategorizedImages({
        id: data.id,
        url: data.url,
        fileName: data.fileName,
        qIdx,
      });
      scrollHorizontallyToContainerEnd('add_steps_draggable');
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const handleAddImageColumn = qIdx => {
    const updatedQuestion = {
      ...currentQuestions[qIdx],
      imageColumns: [
        ...currentQuestions[qIdx].imageColumns,
        { id: Date.now(), name: '', items: [] },
      ],
    };
    const updatedGroup = {
      ...currentQGroup,
      questions: currentQuestions.toSpliced(qIdx, 1, updatedQuestion),
    };
    setQGroups(prevGroups => prevGroups.toSpliced(currentGroupIdx, 1, updatedGroup));
    scrollHorizontallyToContainerEnd('img_cat_edit_columns');
  };

  const handleRemoveImgColumn = (qIdx, colIdx) => {
    const updatedQuestion = {
      ...currentQuestions[qIdx],
      imageColumns: [...currentQuestions[qIdx].imageColumns.toSpliced(colIdx, 1)],
      unCategorizedImages: [
        ...currentQuestions[qIdx].unCategorizedImages,
        ...currentQuestions[qIdx].imageColumns[colIdx].items.map(item => ({
          ...item,
          category: null,
        })),
      ],
    };
    const updatedGroup = {
      ...currentQGroup,
      questions: currentQuestions.toSpliced(qIdx, 1, updatedQuestion),
    };
    setQGroups(prevGroups => prevGroups.toSpliced(currentGroupIdx, 1, updatedGroup));
    scrollHorizontallyToContainerEnd('add_steps_draggable');
  };

  const handleSearch = () => {};

  const handleSave = async () => {
    try {
      setIsSubmitting(true);
      const errors = validateGroupsConfiguration(qGroups);
      if (Object.keys(errors).length) {
        let errorMessages = [];

        for (const groupIdx in errors) {
          const groupErrors = errors[groupIdx];
          if (groupErrors.name) errorMessages.push(groupErrors.name);
          if (groupErrors.description) errorMessages.push(groupErrors.description);

          if (groupErrors.questions && Array.isArray(groupErrors.questions)) {
            groupErrors.questions.forEach(qError => {
              errorMessages.push(qError.errMessage);
            });
          }
        }

        const bulletListErrors = errorMessages.map(err => `- ${err}`).join('\n');

        confirmAlert({
          overlayClassName: 'with-icon',
          title: 'Validation failed',
          message: `${bulletListErrors}`,
          buttons: [
            {
              className: `btn-cancel-regular`,
              label: 'Ok',
            },
          ],
        });
        return;
      }
      await Api.post(`/courses/${courseId}/question-group`, adaptQuestionGroups(qGroups));
      enqueueSnackbar('The group is successfully saved', { variant: 'success' });
      history.push(`/course/${courseId}/quiz/question-groups`);
      getQGroupsData();
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (!course?.id) {
      getCourseData();
    }
    getQGroupsData();
  }, []);

  return (
    <div className='d-flex justify-content-between quiz_page_container'>
      <div className='users-header d-flex justify-content-between align-items-center'>
        <NavigableHeaderRoutes items={headerRouteItems} />
      </div>
      {isFetching && <Loading />}
      {!isFetching && (
        <>
          <div className='col-8 question_config_content'>
            <h6 className='color-blue mb-3'>Question Groups</h6>
            <SetsBlock
              items={qGroups}
              activeItemIdx={currentGroupIdx}
              onClickItem={setCurrentGroupIdx}
              onAddNewItem={handleAddNewGroup}
            />
            <div className='mb-3 mt-3 col-12 d-flex align-items-center justify-content-between'>
              <h6 className='color-blue mb-0 p-0'>Questions Group Settings</h6>
              <button
                disabled={qGroups.length < 2}
                type='button'
                className='btn btn-text-remove d-flex align-items-center p-0'
                onClick={() => onRemoveGroup(currentQGroup.id)}
              >
                Remove Question Group <RemoveIcon className='ml-2' width='12' />
              </button>
            </div>
            <div className='col-12 p-0 user-creation-form'>
              <ul className='p-0 mb-0'>
                <li className='d-flex align-items-center mb-2'>
                  <div className='col-3 pl-0'>
                    <label className='m-0'>Group Title:</label>
                  </div>
                  <EditingInput
                    type='text'
                    name='name'
                    value={currentQGroup.name}
                    onChange={handleChange}
                    className='p-0'
                    placeholder='Enter Name'
                    required
                    editing={true}
                    maxLength='180'
                  />
                </li>
                <li className='d-flex align-items-center'>
                  <div className='col-3 pl-0'>
                    <label className='m-0'>Group Description:</label>
                  </div>
                  <EditingTextArea
                    type='text'
                    name='description'
                    value={currentQGroup.description}
                    onChange={handleChange}
                    className='p-0'
                    placeholder='Enter Description'
                    required
                    editing={true}
                    access={true}
                    maxLength='1500'
                  />
                </li>
              </ul>
            </div>
            <div className='mb-3 mt-3 col-12 d-flex align-items-center justify-content-between'>
              <h6 className='color-blue mb-0 p-0'>Group Questions</h6>
            </div>
            <div className='col-12 p-0 user-creation-form'>
              {currentQuestions.map((question, qIdx) => {
                const isLast = qIdx + 1 === currentQuestions.length;
                const answers = question.answers;
                return (
                  <ul key={qIdx} className='p-0 mb-0'>
                    <div className='d-flex mb-2'>
                      <li className='d-flex col-12 align-items-center'>
                        <div className='col-3 pl-0'>
                          <label className='m-0'>Question {qIdx + 1}:</label>
                        </div>
                        <EditingInput
                          type='text'
                          name='title'
                          value={question.title}
                          onChange={({ target }) => handleChangeQuestion(target, qIdx)}
                          className='p-0'
                          placeholder='Enter Title'
                          required={true}
                          editing={true}
                          maxLength='180'
                        />
                      </li>
                      <button
                        onClick={() => handleRemoveQuestion(qIdx)}
                        // disabled={disableQuestion || isInUse}
                        type='button'
                        className='btn btn-remove-small focus_no_shadowed'
                      >
                        <RemoveRedIcon />
                      </button>
                    </div>
                    <li className='d-flex align-items-center w-100 mb-2'>
                      <div className='col-3 pl-0'>
                        <label className='m-0'>Answer {qIdx + 1} Type*:</label>
                      </div>
                      <EditingSelect
                        name='type'
                        onChange={({ target }) => handleChangeQuestion(target, qIdx)}
                        value={question.type}
                        items={QUESTION_TYPES}
                        useProperty='type'
                        displayProperty='name'
                        editing={true}
                        hideDefault={true}
                      />
                    </li>
                    {question.type === QUESTION_TYPES_OBJ.yesno && (
                      <li className='d-flex align-items-center w-100 mb-2'>
                        <div className='col-3 pl-0'>
                          <label className='m-0'>Correct Answer:</label>
                        </div>
                        <EditingSelect
                          name={QUESTION_TYPES_OBJ.yesno}
                          onChange={({ target }) => handleChangeQuestion(target, qIdx)}
                          value={question.yesno || ''}
                          items={[{ title: 'Yes' }, { title: 'No' }]}
                          useProperty='title'
                          displayProperty='title'
                          editing={true}
                          required={true}
                        />
                      </li>
                    )}
                    {(question.type === QUESTION_TYPES_OBJ.radio ||
                      question.type === QUESTION_TYPES_OBJ.multi) && (
                      <DragList
                        onDragEnd={e => onDragEnd(qIdx, e)}
                        // disabled={isInUse}
                        items={answers}
                        rowClassName='mb-2'
                      >
                        {(item, index) => {
                          return (
                            <DragItem
                              buttonsMarginLeft='ml-2'
                              buttonWidth={28}
                              buttonHeight={28}
                              onItemRemove={() => onRemoveAnswer(qIdx, index)}
                              onItemCheck={() => onAnswerCheck(qIdx, index)}
                              isItemCheck={item.isRightAnswer}
                              className='drag-is-lesson-answer'
                            >
                              <li className='d-flex align-items-center mb-0 w-100'>
                                <div className='col-3 pl-0'>
                                  <label className='m-0'>Answer Variant {index + 1}*:</label>
                                </div>
                                <EditingInput
                                  type='text'
                                  name='answer'
                                  value={item.answer}
                                  onChange={({ target }) => onAnswerChange(qIdx, index, target)}
                                  className='p-0'
                                  editing={true}
                                  access={true}
                                  maxLength='100'
                                />
                              </li>
                            </DragItem>
                          );
                        }}
                      </DragList>
                    )}
                    {question.type === QUESTION_TYPES_OBJ['image-category'] && (
                      <>
                        <div className='image_cat_btns_container'>
                          <Button
                            onClick={() => handleAddImageColumn(qIdx)}
                            className='btn-primary'
                          >
                            Add Category +
                          </Button>
                          <Button
                            onClick={() =>
                              document.getElementById(`hiddenDnDImgFileInput${qIdx}`).click()
                            }
                            className='btn btn-blue position-relative'
                          >
                            <input
                              id={`hiddenDnDImgFileInput${qIdx}`}
                              type='file'
                              className='hidden_file_inp'
                              accept='.png, .jpg, .jpeg'
                              onChange={e => handleUnCategorizedImagesChange(e, qIdx)}
                            />
                            Add Image +
                          </Button>
                        </div>
                        <ImageCategoryDnDBlock
                          handleRemoveImgColumn={handleRemoveImgColumn}
                          groupIdx={currentGroupIdx}
                          questionIdx={qIdx}
                          setQGroups={setQGroups}
                          imageColumns={question.imageColumns}
                          unCategorizedImages={question.unCategorizedImages}
                        />
                      </>
                    )}
                    <div className='d-flex justify-content-between add-new-items-btns mb-4'>
                      {(question.type === QUESTION_TYPES_OBJ.radio ||
                        question.type === QUESTION_TYPES_OBJ.multi) && (
                        <button
                          type='button'
                          className='btn btn-blue'
                          onClick={() => onAddAnwser(qIdx)}
                        >
                          Add Answer +
                        </button>
                      )}
                      {isLast && (
                        <button
                          type='button'
                          className='btn btn-outline-blue ml-auto'
                          onClick={handleAddQuestion}
                        >
                          Add Question +
                        </button>
                      )}
                    </div>
                  </ul>
                );
              })}
            </div>
            <div className='col-12 p-0 d-flex justify-content-end'>
              <Button
                disabled={isSubmitting}
                onClick={handleSave}
                className='btn btn-primary fz-12 align-right p-0'
              >
                Save
              </Button>
            </div>
          </div>
          <RightSidebar
            isSaveDisabled={isSubmitting}
            handleSearch={handleSearch}
            handleSave={handleSave}
            containerClassName='col-4'
            isEditState
            firstBlockTitle='Selected Group Info'
            propertyDetails={QGROUP_PROPERTIES}
            courseId={courseId}
          />
        </>
      )}
    </div>
  );
};
