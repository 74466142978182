import React from 'react';
import { Link } from 'react-router-dom';
import Button from 'shared/components/Button';
import { hasAccess } from 'utils/permissionHelper';

export const EmptyQuizConfigView = ({ courseId }) => {
  return (
    <div className='empty_sec_block'>
      <h4 className='block_title'>The quiz of this course is not configured yet</h4>
      <Link to={`/course/${courseId}/quiz-config`}>
        {hasAccess('quiz_create') && (
          <Button className='btn btn-primary no_set_width fz-12 px-4'>Config the quiz</Button>
        )}
      </Link>
    </div>
  );
};
