import React from 'react';
import { Link } from 'react-router-dom';

const UserHeader = () => {
  return (
    <div className='users-header d-flex justify-content-between align-items-center'>
      <div className='d-flex breadcrumbs'>
        <Link to='/users/all'>All Users</Link>
        <span className='mx-2'>/</span>
        <span>Invite New User</span>
      </div>
    </div>
  );
};

export default UserHeader;
