export const getIsOnlyHiddenFilter = ({ filtersOptions, hiddenOptionsNames }) => {
  // Filter to only include visible (non-hidden) options
  const visibleFilters = filtersOptions.filter(option => !hiddenOptionsNames.includes(option.name));

  const areFiltersEmpty = visibleFilters.map(option => {
    switch (option?.type) {
      case 'dropdown':
        // A dropdown is considered empty if its value is undefined, null, or an empty string
        return option?.value === undefined || option?.value === null || option?.value === '';
      case 'multiDropdown':
        // A multiDropdown is empty if its value array is empty
        return !option?.value || option?.value?.length === 0;
      case 'date':
        // A date filter is empty if it lacks a value, or start/end dates are missing
        return !option?.value || !option?.value?.start || !option?.value?.end;
      default:
        return false;
    }
  });

  // Return true if all visible filters are "empty", otherwise false
  return areFiltersEmpty.every(isEmpty => isEmpty);
};
