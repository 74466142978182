import React, { useRef, useState } from 'react';
import { CircularProgress } from '@material-ui/core';

import { useDownload } from 'shared/hooks/useDownload';
import Button from '../Button';

import { IconDocumentPDF, IconDocumentXLS, IconExportFile } from '../Icons';
import useOutsideClick from 'shared/hooks/useOutsideClick';

const ExportTableData = ({ downloadData = [] }) => {
  const [isActive, setIsActive] = useState(false);

  const listBlockRef = useRef(null);

  useOutsideClick(listBlockRef, () => setIsActive(false), 'export_btn');

  const { download, isPreparingDownload } = useDownload();

  const handleDownload = data => {
    const exportCsv = data.type === 'csv';
    download({ ...data, exportCsv });
  };

  return (
    <Button
      id='export_btn'
      onClick={() => setIsActive(prev => !prev)}
      className={`export_tb_btn ${isActive && 'active'}`}
    >
      <span>Export</span>
      {isPreparingDownload ? (
        <CircularProgress className={'inner_icon'} size={15} />
      ) : (
        <IconExportFile color={isActive ? '#fff' : undefined} className={'inner_icon'} />
      )}
      {isActive && (
        <div ref={listBlockRef} className='drop_list_block'>
          {downloadData.map((data, idx) => {
            return (
              // eslint-disable-next-line jsx-a11y/no-static-element-interactions
              <div onClick={() => handleDownload(data)} key={idx} className='drop_list_item'>
                <span>{data.title}</span>
                {data.type === 'pdf' && <IconDocumentPDF />}
                {data.type === 'csv' && <IconDocumentXLS />}
              </div>
            );
          })}
        </div>
      )}
    </Button>
  );
};

export default ExportTableData;
