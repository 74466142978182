import React from 'react';
import {
  ProductBiopsyIcon,
  ProductBoneCrackIcon,
  ProductBonesIcon,
  ProductCarbonReminderIcon,
  ProductFolderIcon,
  ProductGuidanceLabIcon,
  ProductHeartIcon,
  ProductMammographyIcon,
  ProductMedicalBagIcon,
} from 'shared/components/Icons';

const useProductPageTitleTypes = () => {
  const TitleTypes = {
    folder: <ProductFolderIcon className='shrink-0' />,
    medicalBag: <ProductMedicalBagIcon className='shrink-0' />,
    biopsy: <ProductBiopsyIcon className='shrink-0' />,
    mammography: <ProductMammographyIcon className='shrink-0' />,
    bones: <ProductBonesIcon className='shrink-0' />,
    guidanceLab: <ProductGuidanceLabIcon className='shrink-0' />,
    boneCrack: <ProductBoneCrackIcon className='shrink-0' />,
    carbonReminder: <ProductCarbonReminderIcon className='shrink-0' />,
    heart: <ProductHeartIcon className='shrink-0' />,
  };

  return TitleTypes;
};

export default useProductPageTitleTypes;
