import React from 'react';
import ReactDOM from 'react-dom';
import csTools from 'cornerstone-tools';
import * as cornerstone from 'cornerstone-core';
import cornerstoneMath from 'cornerstone-math';
// State
import { getToolState, addToolState, removeToolState } from './stateManagement/toolState.js';
// import toolColors from './stateManagement/toolColors.js';
// Drawing
import { getNewContext, draw } from './drawing/index.js';
import drawHandles from './drawing/drawHandles.js';
// Utilities
import { probeCursor } from './cursors/index.js';
import MeasurementDialog from './components/MeasurementDialog.js';

const BaseAnnotationTool = csTools.importInternal('base/BaseAnnotationTool');

export default class GenuineTool extends BaseAnnotationTool {
  constructor(props = {}) {
    const defaultProps = {
      name: 'GenuineTool',
      supportedInteractionTypes: ['Mouse', 'Touch'],
      svgCursor: probeCursor,
      configuration: {
        drawHandles: true,
      },
    };

    super(props, defaultProps);
    this.touchPressCallback = this._onDBClickPoint.bind(this);
    this.doubleClickCallback = this._onDBClickPoint.bind(this);
    this.isCreatingMeasurement = false;
    this.usedDesignators = this._options.usedDesignators;
  }

  requestInput = (parameters = {}) => {
    const { percentage, designator, imageId, x, y, shape, pointId } = parameters;
    const { onGetDicom } = this.initialConfiguration;
    return new Promise(resolve => {
      const modalRoot = document.getElementById('portal-root');

      const cleanupAndResolve = ({ percentage, designator, id }) => {
        ReactDOM.unmountComponentAtNode(modalRoot);
        resolve({ percentage, designator, id });
      };

      const cleanupAndReject = () => {
        ReactDOM.unmountComponentAtNode(modalRoot);
        resolve();
      };

      const onSubmit = () => {
        this.isCreatingMeasurement = false;
      };

      const onCancel = () => {
        this.isCreatingMeasurement = false;
        cleanupAndReject('Operation cancelled by the user');
      };

      const outerUsedDesignators = this._options.usedDesignators.filter(des => des.shape !== shape);

      ReactDOM.render(
        <MeasurementDialog
          isOpen
          defaultPercent={percentage}
          defaultDesignator={designator}
          usedDesignators={[...outerUsedDesignators, ...this.usedDesignators]}
          onSubmit={onSubmit}
          imageId={imageId}
          handles={{ end: { x, y, shape, id: pointId } }}
          cleanupAndResolve={cleanupAndResolve}
          onCancel={onCancel}
          onGetDicom={onGetDicom}
        />,
        modalRoot,
      );
    });
  };

  activeCallback(element, data) {
    this.shape = data.shape;
  }

  disabledCallback(element) {}

  createEventData({ x, y, shape, percentage, isActive, designator, id }) {
    return {
      visible: true,
      active: true,
      color: undefined,
      invalidated: true,
      handles: {
        end: {
          percentage: percentage,
          shape: shape || this.shape,
          x,
          y,
          highlight: true,
          active: true,
          isActive,
          designator,
          id,
        },
      },
    };
  }

  createNewMeasurement = eventData => {
    if (this.isCreatingMeasurement) {
      return;
    }

    this.isCreatingMeasurement = true;

    if (this._options.isRemoveMode || this._options.isDisabledCreate) {
      return;
    }

    const goodEventData = eventData && eventData.currentPoints && eventData.currentPoints.image;
    if (!goodEventData) {
      // eslint-disable-next-line consistent-return
      return {};
    }

    const currentDataLength = getToolState(eventData.element, this.name)?.data.length;

    setTimeout(async () => {
      const toolData = getToolState(eventData.element, this.name);
      const viewport = cornerstone.getViewport(eventData.element);
      const currentImage = viewport.images[(eventData.image?.imageId)];

      this.usedDesignators = toolData?.data?.length
        ? toolData.data.map(data => ({
            designator: data.handles.end.designator,
            shape: data.handles.end.shape,
          }))
        : [];

      if (!toolData || toolData.data?.length === currentDataLength) return;
      const data = await this.requestInput({
        imageId: currentImage?.id,
        x: eventData.currentPoints.image.x,
        y: eventData.currentPoints.image.y,
        shape: this.shape,
      });

      this.isCreatingMeasurement = false;
      const percentage = data?.percentage;
      const designator = data?.designator;
      if (designator && this.usedDesignators.length) {
        this.usedDesignators[this.usedDesignators.length - 1].designator = designator;
      }
      const isCreate = true;
      if (this._options.setUsedDesignators) {
        this._options.setUsedDesignators([
          ...this._options.usedDesignators,
          ...this.usedDesignators,
        ]);
      }
      this._onUpdatePoint(eventData, percentage, !percentage, designator, isCreate, data?.id);
    }, 400);

    // eslint-disable-next-line consistent-return
    return this.createEventData({ ...eventData.currentPoints.image, percentage: null });
  };

  removeMeasurement(eventData) {
    this._onRemovePoint(eventData, null, true);
  }

  _onRemovePoint(eventData) {
    this._onUpdatePoint(eventData, null, true);
  }

  pointNearTool(element, data, coords) {
    const hasEndHandle = data && data.handles && data.handles.end;
    const validParameters = hasEndHandle;
    if (!validParameters || data.visible === false) {
      return false;
    }
    const probeCoords = cornerstone.pixelToCanvas(element, data.handles.end);
    return cornerstoneMath.point.distance(probeCoords, coords) < 30;
  }

  updateCachedStats(image, element, data) {
    const { onArrowMove } = this.initialConfiguration;
    clearTimeout(this.updateEvent);

    this.updateEvent = setTimeout(() => {
      if (onArrowMove) onArrowMove(image, data); // This causes point glitch during point addition
    }, 500);
  }

  createInitialData(handles) {
    const data = [];
    handles.forEach(item => {
      data.push(this.createEventData(item));
    });
    return this.createEventData(handles[0]);
  }

  addedToolInitialData(target) {
    const { data } = this.initialConfiguration;
    if (this.addedInitialData || !data || !data.length) return;
    data.forEach(item => {
      addToolState(target, this.name, this.createEventData(item));
    });
    this.addedInitialData = true;
  }

  renderToolData(evt) {
    this.addedToolInitialData(evt.currentTarget);
    const eventData = evt.detail;
    const { handleRadius } = this.configuration;
    const toolData = getToolState(evt.currentTarget, this.name);

    if (!toolData) {
      return;
    }

    const context = getNewContext(eventData.canvasContext.canvas);

    for (let i = 0; i < toolData.data.length; i++) {
      const data = toolData.data[i];
      const color = '#C8C8C8';
      const borderColor = '#000';
      const fillColor = '#C8C8C8';

      if (data.visible === false) {
        continue;
      }

      draw(context, context => {
        if (this.configuration.drawHandles) {
          drawHandles(context, eventData, data.handles, {
            handleRadius,
            color,
            fill: fillColor,
            borderColor,
            isSelected: data?.handles?.end?.isActive,
          });
        }
      });
    }
  }

  calculateBoundingBox(handles) {
    return {
      x: handles.end.x,
      y: handles.end.y,
      width: 80,
      height: 100,
    };
  }

  _onDBClickPoint = async evt => {
    if (this.isCreatingMeasurement || this._options.isDisabledCreate) {
      return;
    }

    const eventData = evt.detail;
    const { element, currentPoints } = eventData;

    // Find the nearest annotation data to the clicked point
    const nearestData = this.findNearestAnnotationData(element, currentPoints);
    const toolData = getToolState(evt.currentTarget, this.name);

    if (!nearestData) {
      return;
    }

    // If in remove mode, remove the measurement
    if (this._options.isRemoveMode) {
      this.removeMeasurement(eventData);
    } else {
      this.usedDesignators = toolData?.data?.length
        ? toolData.data.map(data => ({
            designator: data.handles.end.designator,
            shape: data.handles.end.shape,
          }))
        : [];

      const { percentage, designator, x, y, id } = nearestData?.handles?.end;
      const viewport = cornerstone.getViewport(element);
      const currentImage = viewport.images[(eventData.image?.imageId)];
      const inputData = await this.requestInput({
        percentage,
        usedDesignators: [],
        designator,
        imageId: currentImage?.id,
        shape: this.shape,
        x,
        y,
        pointId: id,
      });
      if (inputData) {
        const getUpdatingPointIndex = designators =>
          designators.findIndex(des => des.designator === nearestData.handles.end.designator);
        const updatingItemIdx = getUpdatingPointIndex(this.usedDesignators);
        const updatingItemInitialIdx = getUpdatingPointIndex(this._options.usedDesignators);

        this.usedDesignators.splice(updatingItemIdx, 1, {
          designator: nearestData.handles.end.designator,
          shape: nearestData.handles.end.shape,
        });
        this._options.usedDesignators.splice(updatingItemInitialIdx, 1);
        this._onUpdatePoint(
          eventData,
          inputData.percentage,
          false,
          inputData.designator,
          false,
          inputData.id,
        );
      }
    }

    evt.preventDefault();
    evt.stopPropagation();
  };

  findNearestAnnotationData = (element, currentPoints) => {
    const toolState = getToolState(element, this.name);
    if (!toolState || !toolState.data || !toolState.data.length) {
      return null;
    }

    const canvasCoords = cornerstone.pixelToCanvas(element, currentPoints.image);
    let nearestData = null;
    let minDistance = Infinity;

    toolState.data.forEach(data => {
      const handleCoords = cornerstone.pixelToCanvas(element, data.handles.end);
      const distance = cornerstoneMath.point.distance(handleCoords, canvasCoords);
      if (distance < minDistance) {
        minDistance = distance;
        nearestData = data;
      }
    });

    return nearestData;
  };

  _onUpdatePoint = async (eventData, percentage, isRemove, designator, isCreate, pointId) => {
    const { onArrowMove, onArrowAdd, onArrowRemove } = this.initialConfiguration;
    const { element, currentPoints } = eventData;

    const coords = currentPoints.canvas;
    const toolData = getToolState(element, this.name);

    // Now check to see if there is a handle we can move
    if (!toolData) {
      return;
    }

    for (let i = 0; i < toolData.data.length; i++) {
      const data = toolData.data[i];
      if (this.pointNearTool(element, data, coords)) {
        const viewport = cornerstone.getViewport(element);
        const currentImage = viewport.images[eventData.image.imageId];
        if (isRemove) {
          removeToolState(element, this.name, data);
          if (currentImage.geniusAIDataList) {
            currentImage.geniusAIDataList = currentImage.geniusAIDataList.filter(
              point => point?.id !== data?.handles?.end?.id,
            );
          }
          if (onArrowRemove) onArrowRemove(eventData.image, data);
        } else {
          toolData?.data?.forEach(pointData => (pointData.handles.end.isActive = false));
          data.handles.end.percentage = percentage;
          data.handles.end.designator = designator;
          data.handles.end.id = pointId;
          if (isCreate) {
            if (onArrowAdd) {
              const nearestData = this.findNearestAnnotationData(element, eventData.currentPoints);
              nearestData.handles.end.isActive = true;
              onArrowAdd(eventData.image, data);

              if (!currentImage.geniusAIDataList) {
                currentImage.geniusAIDataList = [];
              }
              currentImage.geniusAIDataList.push(data.handles.end);
            }
          } else if (onArrowMove) {
            onArrowMove(eventData.image, data);
          }
        }

        cornerstone.updateImage(element);

        return;
      }
    }
  };
}
