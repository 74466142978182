import React from 'react';
import Button from 'shared/components/Button';
import ButtonLoading from 'shared/components/ButtonLoading';

const FooterButtons = ({
  onGoBackStep,
  onGoNextStep,
  activeStep,
  isLastStep,
  fetching,
  form,
  onSaveDraft,
  canSaveDraft,
  fetchingDraft,
  canPublish,
}) => {
  return (
    <div
      className={`${isLastStep ? 'col-12 pb-5' : 'col-12'} px-4 d-flex ${!isLastStep &&
        'new-wizard-footer'} ${isLastStep ? 'justify-content-center' : 'justify-content-between'}`}
    >
      <button
        className={`btn btn-blue min-100 fz-12 font-weight-bold py-2`}
        type='button'
        onClick={onGoBackStep}
        disabled={activeStep === 0}
      >
        Back
      </button>
      {!isLastStep && (
        <div>
          {/* {canSaveDraft && (
            <button
              className='btn btn-outline-blue min-100 fz-12 mr-3 btn-border-2 font-weight-bold py-2'
              type='button'
              onClick={onSaveDraft}
              disabled={fetching || !form.name || fetchingDraft}
            >
              {fetchingDraft ? <ButtonLoading /> : 'Save Draft'}
            </button>
          )} */}
          <Button
            className={`btn-primary btn-restyle min-100 fz-12 font-weight-bold py-2`}
            type='button'
            onClick={onGoNextStep}
            disabled={fetching || !canPublish}
          >
            {fetching ? <ButtonLoading /> : 'Next'}
          </Button>
        </div>
      )}
    </div>
  );
};

export default FooterButtons;
