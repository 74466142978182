import React from 'react';
import moveIcon from 'assets/move.svg';
import { IconCheckBold, RemoveRedIcon } from './Icons';

const DragItem = ({
  children,
  className,
  onItemRemove,
  onItemCheck,
  isItemCheck,
  onItemRemoveDisabled,
  disabled,
  buttonsMarginLeft,
  buttonWidth = 50,
  buttonHeight = 50,
}) => {
  return (
    <div className={`d-flex drag-item align-items-center ${className}`}>
      {children}
      <div
        className={`action-buttons d-flex align-items-center ${onItemRemove ? 'has-remove' : ''}`}
      >
        <div className={`btn btn-drag ${disabled ? 'disabled' : ''}`}>
          <img src={moveIcon} alt='icon' />
        </div>
        {onItemCheck && (
          <button
            disabled={disabled}
            onClick={onItemCheck}
            type='button'
            className={`btn btn-check ${buttonsMarginLeft || 'ml-4'}`}
            style={{ width: buttonWidth, height: buttonHeight }}
          >
            <IconCheckBold
              width={buttonHeight / 2}
              height={buttonHeight / 2}
              className={isItemCheck ? 'opc-full' : 'opc-2'}
            />
          </button>
        )}
        {onItemRemove && (
          <button
            onClick={onItemRemove}
            disabled={onItemRemoveDisabled}
            type='button'
            className={`btn ${onItemCheck ? 'ml-2' : `${buttonsMarginLeft || 'ml-3'}`}`}
            style={{ width: buttonWidth, height: buttonHeight }}
          >
            <RemoveRedIcon width={buttonWidth} height={buttonHeight} />
          </button>
        )}
      </div>
    </div>
  );
};

export default DragItem;
