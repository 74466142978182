import React from 'react';
import ClickArea from 'shared/components/ClickArea';
import Loading from 'shared/components/Loading';
import moment from 'moment';

const headers = [
  {
    name: 'id',
    className: 'col-1',
    key: 'name',
  },
  {
    name: 'Action',
    className: 'col-2',
    key: 'actionType',
  },
  {
    name: 'Details',
    className: 'col',
    key: 'details',
  },
  {
    name: 'Date',
    className: 'col-1 min-130',
    key: 'actionDate',
  },
];

const Lists = ({ items, order, changeOrder, fetch }) => {
  if (fetch) return <Loading className='mt-5' />;
  if (!items || !items.length)
    return <div className='text-black-50 text-center mt-4'>No Results Found</div>;

  const handleOrderChange = item => {
    if (!item.key) return;
    changeOrder({
      orderKey: item.key,
      order: !order.order,
    });
  };

  const types = {
    0: 'Unknown',
    1: 'Login',
    2: 'Logout',
    3: 'Generate Certificate',
    4: 'Start Video',
    5: 'Start Case Viewer',
    6: 'Invalid Login',
    7: 'Inactive User Login',
    8: 'Registration Code',
    9: 'Invite',
    10: 'Confirm Email',
    11: 'Add New Key',
    12: 'Delete Key',
  };

  return (
    <div className='users-lists'>
      <ul className='p-0'>
        <li className='d-flex list-header align-items-center'>
          {headers.map((item, index) => (
            <ClickArea
              key={item.name + index}
              className={`d-flex text-body justify-content-between align-items-center ${
                item.className
              }`}
              onClick={handleOrderChange.bind(null, item)}
            >
              {item.name}
              {item.key && order.orderKey === item.key && (
                <button
                  className={`btn ml-2 p-0 dropdown-toggle ${order.order ? '' : 'rotate-180'}`}
                />
              )}
            </ClickArea>
          ))}
        </li>
        {items &&
          items.map(item => {
            return (
              <li key={item.id} className='d-flex align-items-center list-item'>
                <div className='col-1 text-truncate'>{item.id}</div>
                <div className='col-2 text-truncate'>{types[item.actionType]}</div>
                <div className='col text-truncate'>{item.details}</div>
                <div className='col-1 min-130 text-truncate'>
                  {moment(item.actionDate).format('MM/DD/YYYY hh:mm')}
                </div>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default Lists;
