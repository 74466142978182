import React from 'react';
import EditingInput from 'shared/components/editingForms/EditingInput';
import EditingSelect from 'shared/components/editingForms/EditingSelect';
import DateRange from 'shared/components/DateRangePicker';
import { entranceTypes, eventTypes, realizations } from 'app/Main/configs';
import { SwitchIOSLike } from 'shared/components/SwitchIOSLike';
import { HOST } from 'configs';

const CourseGeneralStep = ({ form, setForm, data, setData, subscriptions, isEditing }) => {
  const onDateRangeChange = date => {
    setForm({ ...form, startDate: date.start.format(), endDate: date.end.format(), programs: [] });
    setData({ ...data, dateRange: date });
  };

  const updateWebinarUrl = (newForm, name, newVal, isEditing) => {
    const nameForUrl =
      name === 'name' ? newVal.replaceAll(' ', '_') : form.name.replaceAll(' ', '_');

    if (!isEditing) {
      if (
        (name === 'realization' && newVal === '0') ||
        (name === 'name' && Number(form.realization) === 0)
      ) {
        newForm.webinarUrl = data?.initialWebinarUrl || `${HOST.API.LEARNER_DOMAIN}`;
        newForm.webinarUrlPath = `/events/${nameForUrl}`;
      } else if (name === 'realization' && newVal !== '0') {
        newForm.webinarUrl = `${HOST.API.LEARNER_DOMAIN}/events/${nameForUrl}`;
      }
    } else {
      if (name === 'realization') {
        if (newVal === '0' && Number(form.realization) === 1) {
          newForm.webinarUrl = data?.initialWebinarUrl || `${HOST.API.LEARNER_DOMAIN}`;
          newForm.webinarUrlPath = `/events/${nameForUrl}`;
        }
        if (newVal === '1' && Number(form.realization) === 0) {
          newForm.webinarUrl = `${HOST.API.LEARNER_DOMAIN}/events/${nameForUrl}`;
        }
      }
    }
  };

  const handleChange = ({ target }) => {
    const { name, value, checked, type } = target;
    const newVal = type === 'checkbox' ? (checked ? 1 : 0) : value;
    const newForm = { ...form, [name]: newVal };

    updateWebinarUrl(newForm, name, newVal, isEditing);

    setForm(newForm);
  };

  const handleSwitchShowToLearner = () => {
    setForm(prev => ({ ...prev, showToLearner: !prev.showToLearner }));

    setData(prev => {
      const isLastEmpty = prev.banners?.[prev.banners.length - 1] === 0;
      const shouldAddBanner = !prev.showToLearner && prev.banners?.length <= 1;
      const shouldRemoveBanner = prev.showToLearner && isLastEmpty;

      let updatedBanners = prev.banners;
      if (shouldAddBanner) {
        updatedBanners = [...prev.banners, 0];
      } else if (shouldRemoveBanner) {
        updatedBanners = prev.banners.slice(0, -1);
      }

      return { ...prev, banners: updatedBanners };
    });
  };

  return (
    <div className='px-2'>
      <h6 className='color-blue mb-3'>Event General Settings</h6>
      <div className='col-9 p-0 user-creation-form'>
        <ul className='p-0 mb-0'>
          <li className='d-flex align-items-center'>
            <div className='col-3 pl-0'>
              <label className='m-0'>Name*:</label>
            </div>
            <EditingInput
              type='text'
              name='name'
              value={form.name}
              onChange={handleChange}
              className='p-0'
              placeholder='Enter Name'
              required
              editing={true}
              maxLength='180'
            />
          </li>
          <li className='d-flex align-items-center'>
            <div className='col-3 pl-0'>
              <label className='m-0'>Subject*:</label>
            </div>
            <EditingInput
              type='text'
              name='subject'
              value={form.subject}
              onChange={handleChange}
              className='p-0'
              placeholder='Enter Subject'
              required
              editing={true}
              maxLength='180'
            />
          </li>
          <li className='d-flex align-items-center'>
            <div className='col-3 pl-0'>
              <label className='m-0'>Date Range*:</label>
            </div>
            <DateRange
              className='form-date-range'
              placeholder='Choose Dates'
              onChange={onDateRangeChange}
              value={data.dateRange}
            />
          </li>
          <li className='d-flex align-items-center'>
            <div className='col-3 pl-0'>
              <label className='m-0'>Type*:</label>
            </div>
            <EditingSelect
              name='eventType'
              onChange={handleChange}
              value={form.eventType}
              items={eventTypes}
              useProperty='id'
              displayProperty='name'
              required={true}
              hideDefault={true}
              valueClassName='pl-0'
              editing={true}
            />
          </li>
          <li className='d-flex align-items-center'>
            <div className='col-3 pl-0'>
              <label className='m-0'>Realization*:</label>
            </div>
            <EditingSelect
              name='realization'
              onChange={handleChange}
              value={form.realization}
              items={realizations}
              useProperty='id'
              displayProperty='name'
              required={true}
              hideDefault={true}
              valueClassName='pl-0'
              editing={true}
            />
          </li>
          <li className='d-flex align-items-center'>
            <div className='col-3 pl-0'>
              <label className='m-0'>Participation Type*:</label>
            </div>
            <EditingSelect
              name='entranceType'
              onChange={handleChange}
              value={form.entranceType}
              items={entranceTypes}
              useProperty='id'
              displayProperty='name'
              required={true}
              hideDefault={true}
              valueClassName='pl-0'
              editing={true}
            />
          </li>
          {Number(form.eventType) === 0 && (
            <li className='d-flex align-items-center'>
              <div className='col-3 pl-0'>
                <label className='m-0'>Related Subscription Plan*:</label>
              </div>
              <EditingSelect
                name='subscriptionPlanId'
                onChange={handleChange}
                value={form.subscriptionPlanId}
                items={subscriptions}
                useProperty='id'
                displayProperty='title'
                required={true}
                hideDefault={false}
                valueClassName='pl-0'
                editing={true}
              />
            </li>
          )}
          <li className='d-flex align-items-center'>
            <div className='col-3 pl-0'>
              <label className='m-0'>Show to Learners:</label>
            </div>
            <div className='switch_container'>
              <SwitchIOSLike
                checkedState={!!form.showToLearner}
                handleChange={handleSwitchShowToLearner}
              />
            </div>
            <span className='switch_label'>{form.showToLearner ? 'Enabled' : 'Disabled'}</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default CourseGeneralStep;
