import React, { useState, useRef } from 'react';
import moment from 'moment';
import DateRangePicker from 'react-daterange-picker';
import useOutsideClick from 'shared/hooks/useOutsideClick';
import 'react-daterange-picker/dist/css/react-calendar.css'; // For some basic styling. (OPTIONAL)
import iconCalendar from 'assets/calendar.svg';
import { IconArrowDown, IconArrowUp } from './Icons';
import { useEffect } from 'react';

const DateRange = ({ onChange, value = null, className = '', placeholder = 'Not Selected' }) => {
  const [open, setOpen] = useState(false);
  const content = useRef();

  const addFocusEvent = () => {
    const el = document.querySelector('.DateRangePicker__MonthHeaderSelect');
    if (!el) return;
    el.addEventListener('focus', () => {
      setOpen(true);
    });
  };

  useOutsideClick(content, () => setOpen(false));

  useEffect(() => {
    addFocusEvent();
  }, []);

  return (
    <div className={`calendar ${open ? 'is-opened' : ''} ${className}`} ref={content}>
      <div
        className={`calendar-header d-flex align-items-center justify-content-between ${
          !value ? 'no-value' : ''
        }`}
        onClick={() => setOpen(!open)}
        role='presentation'
      >
        <img src={iconCalendar} alt='Calendar' />
        {value ? (
          `${moment(value.start).format('MM/DD/YYYY')} - ${moment(value.end).format('MM/DD/YYYY')}`
        ) : (
          <span>{placeholder}</span>
        )}
        {open ? <IconArrowUp /> : <IconArrowDown />}
      </div>
      <div className={`calendar-body ${open ? 'open' : ''}`}>
        <DateRangePicker
          singleDateRange={true}
          onSelect={values => {
            onChange(values);
            setOpen(false);
          }}
          value={value}
        />
      </div>
    </div>
  );
};

export default DateRange;
