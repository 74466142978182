/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import SetsBlock from './components/SetsBlock';
import { QUESTION_TYPES_OBJ } from '../CourseQuizQuestionGroupsConfig/constants';
import { RightSidebar } from './components/RightSidebar';
import { adaptSettingQGroups } from '../CourseQuizQuestionGroupsConfig/adapters';
import Loading from 'shared/components/Loading';
import { QuestionAccordion } from 'shared/components/QuestionAccordion';
import { RESPONSE_STATUS_CODES } from 'configs';
import { getQuestionGroupProperties } from '../CourseQuizQuestionGroupsConfig/helpers';
import NavigableHeaderRoutes from 'shared/components/NavigableHeaderRoutes';

const emptyAnswer = { answer: '' };

const getEmptyQuestionObj = () => ({
  question: '',
  answers: [{ ...emptyAnswer }],
  type: QUESTION_TYPES_OBJ.yesno,
});

const getInitialSet = ({ withoutAnyQuestion }) => ({
  name: '',
  description: '',
  questions: withoutAnyQuestion ? [] : [getEmptyQuestionObj()],
});

export const CourseQuizQuestionGroupsView = ({ history, match, location, questionSets }) => {
  const defaultSelectedQuestion = location?.state?.selectedQuestion || null;
  const defaultSelectedGroupId = location.state?.selectedGroupId || null;
  const { enqueueSnackbar } = useSnackbar();
  const [course, setCourse] = useState(location?.state?.course || {});
  const [currentGroupIdx, setCurrentGroupIdx] = useState(0);
  const [qGroups, setQGroups] = useState(
    questionSets ? [...questionSets] : [getInitialSet({ withoutAnyQuestion: true })],
  );
  const [isFetching, setIsFetching] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);

  const isObserverScrolled = useRef(null);

  const currentQGroup = qGroups[currentGroupIdx] || {};
  const currentQuestions = currentQGroup?.questions || [];
  const filledTitledQuestions = currentQuestions?.length
    ? currentQuestions.filter(q => q.title)
    : [];
  const courseId = match.params.id;
  const QGROUP_PROPERTIES = getQuestionGroupProperties(currentQGroup, filledTitledQuestions);

  const headerRouteItems = [
    { label: 'Courses', path: '/courses/all' },
    { label: course.title, path: `/course/${courseId}/view` },
    { label: 'Quiz', path: `/course/${courseId}/quiz-view` },
    { label: 'Question Groups Configuration' },
  ];

  const getCourseData = async () => {
    try {
      setIsFetching(true);
      const { data } = await Api.get(`/courses/getcourse/${courseId}`);
      setCourse(data.data);
    } catch (err) {
      history.push('/no-access');
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setIsFetching(false);
    }
  };

  const getQGroupsData = async () => {
    try {
      const { data } = await Api.get(`/courses/${courseId}/question-groups`);
      if (data.data.length) {
        const hasNoAnyQuestion = true;
        const adaptedGroups = adaptSettingQGroups(data.data, hasNoAnyQuestion);
        setQGroups(adaptedGroups);
        if (defaultSelectedGroupId) {
          const defaultSelectedIdx = adaptedGroups.findIndex(
            group => group.id === defaultSelectedGroupId,
          );
          setCurrentGroupIdx(defaultSelectedIdx !== -1 ? defaultSelectedIdx : 0);
        }
      }
    } catch (err) {
      if (err.response.status === RESPONSE_STATUS_CODES.accessDenied) {
        history.push('/no-access');
        return;
      }
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const goBack = () => history.goBack();

  const handleAddNewGroup = () => {
    const tempSets = [...qGroups];
    tempSets.push(getInitialSet());
    setQGroups(tempSets);
  };

  const handleSearch = () => {};

  const handleSelectQuestion = question => {
    setSelectedQuestion(question);
    const selectedQuestionAccordion = document.getElementById(question?.id);
    if (selectedQuestionAccordion) {
      selectedQuestionAccordion.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  useEffect(() => {
    if (!course?.id) {
      getCourseData();
    }
    getQGroupsData();
  }, []);

  useEffect(() => {
    if (defaultSelectedQuestion) {
      const defaultGroupIdx = qGroups.findIndex(
        group => group.id === defaultSelectedQuestion.groupId,
      );
      if (defaultGroupIdx !== -1) {
        setCurrentGroupIdx(defaultGroupIdx);
        handleSelectQuestion({
          ...defaultSelectedQuestion,
          id: defaultSelectedQuestion.questionId,
        });
      }
    }
  }, [defaultSelectedQuestion, qGroups]);

  useEffect(() => {
    if (isFetching || isObserverScrolled.current) return;
    const observer = new MutationObserver((mutationsList, observer) => {
      for (let mutation of mutationsList) {
        if (mutation.type === 'childList' || mutation.type === 'attributes') {
          const selectedQuestionAccordion = document.getElementById(selectedQuestion?.id);
          if (selectedQuestionAccordion) {
            selectedQuestionAccordion.scrollIntoView({ behavior: 'smooth', block: 'center' });
            isObserverScrolled.current = true;
            observer.disconnect();
          }
        }
      }
    });

    const targetNode = document.getElementById('questions_contain_block');
    if (targetNode) {
      observer.observe(targetNode, { attributes: true, childList: true, subtree: true });
    }

    return () => observer.disconnect();
  }, [selectedQuestion, isFetching]);

  return (
    <div className='d-flex justify-content-between quiz_page_container pr-5'>
      <div className='users-header d-flex justify-content-between align-items-center'>
        <NavigableHeaderRoutes items={headerRouteItems} />
      </div>
      {isFetching && <Loading />}
      {!isFetching && (
        <>
          <div className='col-8 question_config_content'>
            <h6 className='color-blue mb-3'>Question Groups</h6>
            <SetsBlock
              noPlus
              items={qGroups}
              activeItemIdx={currentGroupIdx}
              onClickItem={setCurrentGroupIdx}
              onAddNewItem={handleAddNewGroup}
            />
            <div className='mb-3 mt-3 col-12 d-flex align-items-center justify-content-between'>
              <div className='group_details_block'>
                <h6 className='group_title color-blue mb-0 p-0'>
                  Questions Group - {currentQGroup.name}
                </h6>
                <p className='group_description'>{currentQGroup.description}</p>
              </div>
            </div>
            <div id='questions_contain_block' className='questions_container'>
              {currentQGroup.questions.map((question, qIdx) => {
                const isSelected = selectedQuestion?.id === question.id;
                return (
                  <QuestionAccordion
                    color={'transparent'}
                    backgroundColor={isSelected ? '#EFF4FB' : '#F8F8F8'}
                    courseId={courseId}
                    question={question}
                    key={qIdx}
                    index={qIdx}
                  />
                );
              })}
            </div>
          </div>
          <RightSidebar
            haveAllRowsBorderOnHover
            currentQGroup={currentQGroup}
            selectedQuestion={selectedQuestion}
            handleSelectQuestion={handleSelectQuestion}
            handleSearch={handleSearch}
            containerClassName='col-4 p-0'
            firstBlockTitle='Selected Group Info'
            propertyDetails={QGROUP_PROPERTIES}
            courseId={courseId}
          />
        </>
      )}
    </div>
  );
};
