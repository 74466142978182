import React, { useEffect, useState } from 'react';
import { Api } from 'utils/connectors';
import { formatDate, getError } from 'utils/appHelpers';
import { useSnackbar } from 'notistack';
import Loading from 'shared/components/Loading';

const DicomSelectionModal = ({ close, onSelectCases }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [cases, setCases] = useState([]);
  const [activeIds, setActiveIds] = useState([]);

  const onSelectItem = item => {
    const ids = [...activeIds];
    if (ids.includes(item.caseId)) {
      ids.splice(ids.indexOf(item.caseId), 1);
    } else {
      ids.push(item.caseId);
    }
    setActiveIds(ids);
  };

  const getCases = async () => {
    try {
      const { data } = await Api.get('/wizard/cases');
      setCases(data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onCloseModal = () => {
    const items = cases.filter(i => activeIds.includes(i.caseId));
    onSelectCases(items);
    close();
  };

  useEffect(() => {
    getCases();
  }, []);

  return (
    <div className='modal-box case-select-modal d-flex flex-column'>
      <div className='m-header'>
        <h5>Choose DICOM Cases</h5>
      </div>
      <div className='m-content flex-fill'>
        {cases &&
          cases.map((item, i) => {
            return (
              <label
                key={i}
                className='case-item pointer w-100 d-flex align-items-center justify-content-between'
                htmlFor={`radio${i}`}
              >
                <LessonCaseRow item={item} />
                <div className='d-flex'>
                  <input
                    type='checkbox'
                    id={`radio${i}`}
                    className='input-custom is-blue'
                    onChange={onSelectItem.bind(null, item)}
                    checked={activeIds.includes(item.caseId)}
                  />
                  <label className='m-0' htmlFor={`radio${i}`} />
                </div>
              </label>
            );
          })}
        {!cases.length && <Loading className='mt-5' />}
      </div>
      <p className='case-count text-right'>Select Cases: {activeIds.length}</p>
      <div className='m-footer text-center px-4'>
        <button className='btn-blue btn px-4' onClick={onCloseModal}>
          Done
        </button>
      </div>
    </div>
  );
};

const InfoIcon = ({ name, value, className }) => (
  <p className={`mb-0 info-row-item ${className || ''}`}>
    {name}: <b className='text-blue'>{value}</b>
  </p>
);

const LessonCaseRow = ({ item, className }) => {
  const types = item.dicoms && new Set([...item.dicoms.map(i => i.imageType)]);
  const typeStr = types ? [...types].join(',') : false;
  return (
    <div className={className}>
      <div className='case-item-title d-flex align-items-center mb-2'>
        {item.caseUniqueId} • <span className='ml-2 text-uppercase'>{typeStr || ''}</span>
      </div>
      <div className='d-flex mb-1'>
        <InfoIcon name='ID' value={item.patientId} />
        <InfoIcon name='Age' value={item.patientAge} />
        <InfoIcon name='Updated Date' value={formatDate(item.updatedAt) || '-'} />
      </div>
    </div>
  );
};

export default DicomSelectionModal;
