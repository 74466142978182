import { getImagesSortedSlices, getPrimarySlices } from '../components/PrePostSlices';

export const openFullscreen = () => {
  const elem = document.documentElement;
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.mozRequestFullScreen) {
    elem.mozRequestFullScreen();
  } else if (elem.webkitRequestFullscreen) {
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) {
    elem.msRequestFullscreen();
  }
};

export const closeFullscreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen();
  } else if (document.msExitFullscreen) {
    document.msExitFullscreen();
  }
};

export const zoomImageMouseClick = (cornerstone, element, bool, scale, e) => {
  const step = 0.05;
  const viewport = cornerstone.getViewport(element);
  viewport.scale -= bool ? -step : step;
  if (scale) viewport.scale = scale;
  cornerstone.setViewport(element, viewport);
  return false;
};

export const getDefaultImageScale = (element, image) => {
  const { clientWidth, clientHeight } = element;
  const { width, height } = image;
  if (width > height) return width > clientWidth ? clientWidth / width : clientHeight / height;
  else return clientHeight / height;
};

export const maximazeElement = (cornerstone, element, toggle) => {
  element.classList[toggle ? 'toggle' : 'add']('maximize');
  cornerstone.fitToWindow(element);
  cornerstone.resize(element);
};

const getCompletedSteps = (episodes, id) => {
  if (!episodes) return [];
  let body = episodes.filter(item => item.caseId === id);
  body = body.sort((a, b) => a.orderNum - b.orderNum);
  const result = [];
  body.forEach((item, index) => {
    if (item.status === 1) result.push(index);
  });
  return result;
};

const setFindings = (data, userLessonId) => {
  try {
    if (data && data.length) {
      data = data.map(f => {
        f.vectorData = JSON.parse(f.vectorData);
        f.vectorData = f.vectorData.map(i => ({ ...i, x: i.pointX, y: i.pointY }));
        return f;
      });
      return data.filter(item => userLessonId === item.userLessonId)[0];
    } else return null;
  } catch (err) {
    return null;
  }
};

const checkDicomNameOnViews = (name, newTpe, views) => {
  if (!views[name]) return name;
  const { dicomImageType: currentType } = views[name];
  if (currentType === 'i2d') return false;
  if (currentType === 'g2d' && newTpe === 'r2d') return false;
  return name;
};

const getDicomDataName = dicomItem => {
  const { imageLaterality, viewPosition, dicomImageType } = dicomItem;
  const types = { '3dq': '_3DQ', i2d: '_2D', g2d: '_2D', r2d: '_2D', tomo: '_TOMO', sub: '_TOMO' };
  const type = types[dicomImageType];
  return `${imageLaterality}${viewPosition}${type}`;
};

export const generateViewerData = (data, lesson) => {
  const result = [];
  const views = {};
  data.caseDicomItems.forEach(({ dicomItem }, index) => {
    const tempName = getDicomDataName(dicomItem);
    const name = checkDicomNameOnViews(tempName, dicomItem.dicomImageType, views);
    if (!name) return;
    views[name] = {
      name,
      position: name[0] === 'R' ? 'right' : 'left',
      protocolName: dicomItem.protocolName,
      imageLaterality: dicomItem.imageLaterality,
      viewPosition: dicomItem.viewPosition,
      dicomImageType: dicomItem.dicomImageType,
      id: dicomItem.id,
      patient: {
        name: dicomItem.patientName,
        age: dicomItem.patientAge,
        sex: dicomItem.patientSex,
        id: dicomItem.patientId,
        birthDate: dicomItem.patientBirthDate,
      },
      images: dicomItem.dicomItemImages.map(img => {
        const emptyFinding = {
          caseId: data.id,
          dicomId: dicomItem.id,
          imageId: img.id,
          userLessonId: lesson.userLessonId,
        };
        return {
          id: img.id,
          url: img.lutUrl || img.url,
          finding: setFindings(img.vectorDatas, lesson.userLessonId),
          emptyFinding,
        };
      }),
    };
  });
  const completed_steps = getCompletedSteps(lesson.episodes, data.id);
  result.push({ id: data.id, name: data.title, completed_steps, views });
  return result;
};

(function(target) {
  if (!target || !target.prototype) return;
  target.prototype.arrow = function(startX, startY, endX, endY, controlPoints) {
    var dx = endX - startX;
    var dy = endY - startY;
    var len = Math.sqrt(dx * dx + dy * dy);
    var sin = dy / len;
    var cos = dx / len;
    var a = [];
    a.push(0, 0);
    for (var i = 0; i < controlPoints.length; i += 2) {
      var x1 = controlPoints[i];
      var y1 = controlPoints[i + 1];
      a.push(x1 < 0 ? len + x1 : x1, y1);
    }
    a.push(len, 0);
    for (var j = controlPoints.length; j > 0; j -= 2) {
      var x2 = controlPoints[j - 2];
      var y2 = controlPoints[j - 1];
      a.push(x2 < 0 ? len + x2 : x2, -y2);
    }
    a.push(0, 0);
    for (var k = 0; k < a.length; k += 2) {
      var x3 = a[k] * cos - a[k + 1] * sin + startX;
      var y3 = a[k] * sin + a[k + 1] * cos + startY;
      if (i === 0) this.moveTo(x3, y3);
      else this.lineTo(x3, y3);
    }
  };
})(CanvasRenderingContext2D);

export const drawArrow = (c, viewport, e) => {
  c.lineWidth = 1;
  c.strokeStyle = '#00b5ec';
  c.fillStyle = '#00b5ec';
  c.setTransform(1, 0, 0, 1, 10, 0);

  c.translate(0, 0);
  c.beginPath();
  c.arrow(50, 50, 0, 0, [0, 2, -10, 2, -10, 5]);

  c.fill();
};

export const createGenuieEventData = (
  { x, y, shape, percentage, isActive, designator, id },
  additions = {},
) => {
  return {
    visible: true,
    active: true,
    color: undefined,
    invalidated: true,
    ...additions,
    handles: {
      end: {
        x,
        y,
        percentage: percentage,
        shape: shape,
        designator,
        isActive,
        highlight: true,
        active: true,
        id,
      },
    },
  };
};

export const getDicomImagesObj = images => {
  const result = {};
  images.forEach((item, i) => {
    item.index = i;
    result[item.url] = item;
  });
  return result;
};

export const sumShapes = (images, shape) => {
  let sum = 0;
  images.forEach(item => {
    if (item.geniusAIDataList && item.geniusAIDataList.length) {
      const shapes = item.geniusAIDataList.filter(i => Number(i?.shape) === Number(shape));
      sum += shapes ? shapes.length : 0;
    }
  });
  return sum;
};

export const getCurrentImageMarksForPreOrPostSlice = (imgURL, images) => {
  const mainMarks = getPrimarySlices(images);
  const prePostSlices = getImagesSortedSlices(images);
  const currentImgIndex = images.findIndex(i => i.url === imgURL);
  const existMarkIndexes = [];
  prePostSlices.forEach((items, curentIndex) => {
    if (items.includes(currentImgIndex)) {
      existMarkIndexes.push(curentIndex);
    }
  });
  const markGeniusData = [];
  existMarkIndexes.forEach(index => {
    if (mainMarks[index] === currentImgIndex) return;
    const markImage = images[mainMarks[index]];
    if (markImage) markGeniusData.push(markImage.geniusAIDataList);
  });
  return markGeniusData?.length ? markGeniusData : null;
};
