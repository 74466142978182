import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import Header from './components/Header';
import Loading from 'shared/components/Loading';
import Viewer from 'shared/components/Viewer';

const CaseViewGenuine = ({ match, history, location }) => {
  const { state } = location;
  const { id, dicomId } = match.params;
  const { enqueueSnackbar } = useSnackbar();
  const [dicom, setDicom] = useState();

  const onGetDicom = async e => {
    try {
      const { data } = await Api.get(`/dicom/${dicomId}`);
      setDicom(data.data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      history.push(`/case/${id}`);
    }
  };

  const onUpdateGenuine = async data => {
    let res;
    try {
      if (data?.data?.handles?.end?.id) {
        res = await Api.put(
          `/dicom/geniusData/${data.imageId}/${data?.data?.handles?.end?.id}`,
          data?.data?.handles?.end,
        );
      } else {
        res = await Api.post(`/dicom/geniusData/${data.imageId}`, data?.data?.handles?.end);
      }
      const tempDicom = { ...dicom };
      const index = tempDicom.dicomItemImages.findIndex(i => i.id === data.imageId);
      tempDicom.dicomItemImages[index].geniusAIDataList.push(res?.data?.data);
      setDicom(tempDicom);
      await onGetDicom();
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onRemoveGaid = async data => {
    try {
      const { imageId, gaidId } = data;
      await Api.delete(`/dicom/geniusData/${imageId}/${gaidId}`);
      const tempDicom = { ...dicom };
      const index = tempDicom.dicomItemImages.findIndex(i => i.id === imageId);
      tempDicom.dicomItemImages[index].geniusAIDataList = tempDicom.dicomItemImages[
        index
      ].geniusAIDataList.filter(gaidItem => gaidItem?.id !== gaidId);
      setDicom(tempDicom);
      await onGetDicom();
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onUpdateSlices = async data => {
    try {
      await Api.post(`/dicom/geniusData/sliceinfo/${data.imageId}`, data.slices);
      const tempDicom = { ...dicom };
      const index = tempDicom.dicomItemImages.findIndex(i => i.id === data.imageId);
      tempDicom.dicomItemImages[index].postSlices = data.slices.post;
      tempDicom.dicomItemImages[index].preSlices = data.slices.pre;
      setDicom(tempDicom);
      return await onGetDicom();
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  useEffect(() => {
    onGetDicom();
    //eslint-disable-next-line
  }, [dicomId]);

  if (!dicom) return <Loading className='mt-5' />;

  const title = `${dicom.fileName} | ${dicom.imageType} | ${dicom.imageLaterality}${
    dicom.viewPosition
  }`;

  return (
    <div>
      <Header scanId={id} />
      <div className='has-header no-top-padding col-12 p-0'>
        <Viewer
          onGetDicom={onGetDicom}
          hasBIRADS={state?.hasBIRADS}
          sections={state?.sections}
          caseId={id}
          title={title}
          imageLaterality={dicom?.imageLaterality}
          dicomData={{ images: dicom.dicomItemImages, viewPosition: dicom.viewPosition }}
          onUpdateGenuine={onUpdateGenuine}
          onRemoveGaid={onRemoveGaid}
          onUpdateSlices={onUpdateSlices}
          patientId={dicom?.patientId}
        />
      </div>
    </div>
  );
};

export default CaseViewGenuine;
