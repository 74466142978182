import React from 'react';
import Button from 'shared/components/Button';
import Select from 'shared/components/Select';
import { reportTypes } from 'configs';

const ImportForm = ({ handleSubmit, type, setType, fetch }) => {
  return (
    <form onSubmit={handleSubmit} className='col-7 user-creation-form'>
      <ul className='p-0 mb-0'>
        <li className='d-flex align-items-center'>
          <div className='col-3 pl-0'>
            <label className='m-0'>Report Type:</label>
          </div>
          <Select
            name='coursetype'
            onChange={e => setType(e.target.value)}
            value={type}
            items={reportTypes}
            useProperty='value'
            displayProperty='name'
            label='Choose Type'
            className={type ? 'selected-blue' : ''}
          />
        </li>
      </ul>
      <div className='d-flex justify-content-end mt-2'>
        <Button type='submit' className='btn-blue mt-1 px-4' disabled={fetch || !type}>
          Generate
        </Button>
      </div>
    </form>
  );
};

export default ImportForm;
