import React from 'react';
import ClickArea from 'shared/components/ClickArea';
import Loading from 'shared/components/Loading';
import moment from 'moment';

const headers = [
  {
    name: 'Date',
    className: 'col',
    key: 'eventDate',
  },
  {
    name: 'Report Category',
    className: 'col',
    key: 'category',
  },
  {
    name: 'User Type',
    className: 'col-1',
    key: 'userType',
  },
  {
    name: 'Subject',
    className: 'col-2',
    key: 'subject',
  },
  {
    name: 'Device',
    className: 'col-3',
    key: 'device',
  },
  {
    name: 'IP Address',
    className: 'col',
    key: 'ipAddress',
  },
  {
    name: 'Details',
    className: 'col-2',
    key: 'details',
  },
];

const categories = {
  0: 'error',
  1: 'warning',
  2: 'report',
};

const types = {
  0: 'admin',
  1: 'learner',
};

const Lists = ({ data, order, changeOrder, fetch }) => {
  if (fetch) return <Loading className='mt-5' />;
  if (!data || !data.length)
    return <div className='text-black-50 text-center mt-4'>No Results Found</div>;

  const handleOrderChange = item => {
    if (!item.key) return;
    changeOrder({
      orderKey: item.key,
      order: !order.order,
    });
  };

  return (
    <div className='users-lists'>
      <ul className='p-0'>
        <li className='d-flex list-header align-items-center'>
          {headers.map((item, index) => (
            <ClickArea
              key={item.name + index}
              className={`d-flex text-body justify-content-between align-items-center ${
                item.className
              }`}
              onClick={handleOrderChange.bind(null, item)}
            >
              {item.name}
              {item.key && order.orderKey === item.key && (
                <button
                  className={`btn ml-2 p-0 dropdown-toggle ${order.order ? '' : 'rotate-180'}`}
                />
              )}
            </ClickArea>
          ))}
        </li>
        {data &&
          data.map((item, index) => {
            return (
              <li key={index} className='d-flex align-items-center list-item h-not-set'>
                <div className='col text-truncate' title={item.name}>
                  {item.eventDate ? moment(item.eventDate).format('MM/DD/YYYY') : ''}
                </div>
                <div className='col text-truncate'>{categories[item.category]}</div>
                <div className='col-1 p-0 text-center'>{types[item.userType]}</div>
                <div className='col-2'>{item.subject || '-'}</div>
                <div className='col-3'>{item.device}</div>
                <div className='col'>{item.ipAddress}</div>
                <div className='col-2'>{item.details}</div>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default Lists;
