import React from 'react';
import ResponsiveImage from 'shared/components/ResponsiveImage';

const SpecialtySection = props => {
  const { specialty, specialityMessage, specialityImages } = props.currentPage || {};
  const specialtyBannerImg = specialityImages && specialityImages?.[0]?.imageUrl;

  return (
    <div className='specialty-section'>
      <div className='inner-block first-block'>
        <h2 className='section-title'>{specialty}</h2>
        <p className='regular-text'>{specialityMessage}</p>
      </div>
      <div className='inner-block second-block'>
        {specialtyBannerImg && (
          <ResponsiveImage className='img-cover' alt={specialty} imgUrl={specialtyBannerImg} />
        )}
      </div>
    </div>
  );
};

export default SpecialtySection;
