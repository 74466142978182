import React from 'react';
import { HorizontalBar } from 'react-chartjs-2';

import RateStarComponent from './components/RateStarComponent';

const colors = ['#FFC84C', '#78A0FE', '#FA7E61', '#00F5D4', '#C45BAA'];

const BarChartRateQuestion = props => {
  const { question } = props;

  const formatDataLabel = data => {
    return `${data}%`;
  };

  const answerScores = question.rateAnswers ? question.rateAnswers.map(answer => answer.score) : [];
  const sumOfScores = answerScores.reduce((acc, score) => {
    return acc + Number(score);
  }, 0);
  const percentAnswerScores = answerScores.map(score => {
    if (sumOfScores === 0) {
      return 0;
    }
    return Number(((score / sumOfScores) * 100).toFixed(0));
  });
  const rates = question.rateAnswers ? question.rateAnswers.map(answer => answer.rate) : [];

  const barData = {
    labels: ['', '', '', '', '', ''],
    datasets: [
      {
        backgroundColor: colors,
        borderColor: 'none',
        borderWidth: 1,
        // hoverBackgroundColor: 'rgba(255,99,132,0.4)',
        // hoverBorderColor: 'rgba(255,99,132,1)',
        data: percentAnswerScores,
      },
    ],
  };

  const options = {
    layout: {
      padding: {
        right: 35,
      },
    },
    plugins: {
      datalabels: {
        display: true,
        color: 'black',
        formatter: formatDataLabel,
        anchor: 'end',
        offset: -35,
        align: 'start',
      },
    },
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          display: false,
          gridLines: {
            display: false,
          },
          ticks: {
            beginAtZero: true,
            max: 100,
            min: 0,
          },
        },
      ],
      yAxes: [
        {
          barThickness: 50,
          gridLines: {
            display: false,
          },
        },
      ],
    },
  };

  return (
    <div className='survey-question-card rate-question-type'>
      <p className='questionText'>{question.questionTitle}</p>
      <HorizontalBar height={230} data={barData} options={options} />
      <div className='star-container'>
        {rates.map((rate, idx) => {
          return (
            <RateStarComponent
              key={idx}
              rate={rate}
              color={colors[idx]}
              label={`${percentAnswerScores[idx]}%`}
            />
          );
        })}
      </div>
    </div>
  );
};

export default BarChartRateQuestion;
