import React, { useRef, useState } from 'react';
import ReactDOM from 'react-dom';

import { Api } from 'utils/connectors';
import { SHAPE_ICONS } from '../../constants';
import { getError } from 'utils/appHelpers';
import { showAlertMessage } from 'shared/components/AlertMessage';
import useOutsideClick from 'shared/hooks/useOutsideClick';

const englishAlphabetArr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

const MeasurementDialog = ({
  isOpen,
  defaultPercent = '',
  defaultDesignator = '',
  usedDesignators = [],
  onSubmit,
  onCancel,
  imageId,
  handles,
  cleanupAndResolve,
  onGetDicom,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [percentage, setPercentage] = useState(defaultPercent);
  const [selectedDesignator, setSelectedDesignator] = useState(defaultDesignator);

  const designatorDialogRef = useRef(null);

  useOutsideClick(designatorDialogRef, onCancel);

  const handleDesignatorClick = designator => {
    setSelectedDesignator(designator);
  };

  const handleInputChange = e => {
    const value = e.target.value;
    if (value === '') {
      setPercentage('');
    } else if (!isNaN(value) && !isNaN(parseFloat(value))) {
      const numericValue = Math.max(0, Math.min(100, Number(value)));
      setPercentage(numericValue.toString());
    }
  };

  const handleSubmit = async () => {
    const pointId = handles?.end?.id;
    let res;
    setIsSubmitting(true);
    try {
      if (pointId) {
        res = await Api.put(`/dicom/geniusData/${imageId}/${pointId}`, {
          percentage,
          designator: selectedDesignator,
          ...(handles?.end || {}),
        });
      } else {
        res = await Api.post(`/dicom/geniusData/${imageId}`, {
          percentage,
          designator: selectedDesignator,
          ...(handles?.end || {}),
        });
      }
      setIsSubmitting(false);
      onSubmit();
      onGetDicom();
      cleanupAndResolve({ designator: selectedDesignator, percentage, id: res?.data?.data?.id });
    } catch (err) {
      showAlertMessage({
        message: getError(err),
        duration: 5000,
        type: 'error',
      });
      setIsSubmitting(false);
    }
  };

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div ref={designatorDialogRef} className='measurement-dialog-container'>
      <div className='viewer-input-group'>
        <label>Probability:</label>
        <input
          type='text'
          value={percentage}
          onChange={handleInputChange}
          placeholder='Enter %'
          className='probability-input'
        />
        <span className='input-addon'>%</span>
      </div>
      <div className='designator-block'>
        <h6 className='block-title'>Select Designator</h6>
        <div className='designator-items-block'>
          {englishAlphabetArr.map(char => {
            const usedDesignatorItem = usedDesignators.find(item => item.designator === char);
            const shape = usedDesignatorItem?.shape;
            return (
              <button
                disabled={!!usedDesignatorItem}
                key={char}
                className={`designator-button ${
                  selectedDesignator === char ? 'selected' : ''
                } ${!!usedDesignatorItem && 'global_disabled'}`}
                onClick={() => handleDesignatorClick(char)}
              >
                {char}
                {!!shape && <img className='shape-icon' src={SHAPE_ICONS[shape]} alt='shape' />}
              </button>
            );
          })}
        </div>
      </div>
      <div className='measurement-dialog-actions'>
        <button onClick={onCancel} className='cancel-button'>
          Cancel
        </button>
        <button
          disabled={!percentage || isSubmitting}
          onClick={handleSubmit}
          className={`submit-button ${(!percentage || isSubmitting) && 'global_disabled'}`}
        >
          Add
        </button>
      </div>
    </div>,
    document.body,
  );
};

export default MeasurementDialog;
