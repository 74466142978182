/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import { bindDuration, getError } from 'utils/appHelpers';
import { useHistory } from 'react-router';
import { courseModes, lessonTypesObj } from 'app/Main/configs';
import { Api } from 'utils/connectors';
import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import { IconBin } from 'shared/components/Icons';
import { confirmAlert } from 'react-confirm-alert';
import { checkLessonForPublishing, showLessonInvalidAlert } from '../utils';
import { hasAccess } from 'utils/permissionHelper';
import { DEFAULT_IMG_URLS } from 'configs';
import ResponsiveImage from 'shared/components/ResponsiveImage';

const PublishRow = ({ label, data, onClick }) =>
  data || data === 0 ? (
    <p
      className={`d-flex justify-content-between mb-1 ${
        onClick ? 'link-color pointer text-underline' : ''
      }`}
      onClick={onClick}
    >
      <span>{label}</span>
      <span className='fw-600'>{label === 'Duration' ? bindDuration(data) : data}</span>
    </p>
  ) : null;

const PublishPreview = ({ imageUrl, lesson, getLessonData }) => {
  const [fetching, setFetching] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const hasNoEditAccess =
    (lesson.isMarketingMaterial && !hasAccess('marketing_lessons_create')) ||
    (!lesson.isMarketingMaterial && !hasAccess('education_lessons_create'));

  const onLessonTogglePublish = async () => {
    try {
      setFetching(true);
      const action = lesson.mode === 2 ? 'unpublish' : 'publish';
      await Api.post(`/wizard/${action}/lesson/${lesson.id}`);
      await getLessonData();
      enqueueSnackbar('Successfully done', { variant: 'success' });
    } catch (err) {
      const messages = err.response?.data?.data;
      if (messages && messages.length) {
        showLessonInvalidAlert(messages.join('\n'));
      } else {
        enqueueSnackbar(getError(err), { variant: 'error' });
      }
    } finally {
      setFetching(false);
    }
  };

  const onItemRemove = async () => {
    try {
      setFetching(true);
      await Api.delete(`/courses/lesson/${lesson.id}`);
      enqueueSnackbar('Successfully removed', { variant: 'success' });
      history.push('/lessons/all');
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetching(false);
    }
  };

  const onItemRemoveConfirm = () => {
    if (lesson.usage.length > 0) {
      confirmAlert({
        title: 'Can not be deleted',
        message: 'This Lesson is in use and can not be deleted now.',
        buttons: [{ label: 'OK' }],
      });
      return;
    }

    confirmAlert({
      title: 'Confirmation Required',
      message: 'Are you sure you want to delete this Lesson?',
      buttons: [
        {
          label: 'Cancel',
        },
        {
          label: 'Delete',
          onClick: () => onItemRemove(),
        },
      ],
    });
  };

  return (
    <>
      <div className='publish-block course-publish p-3 mb-2'>
        <ResponsiveImage imgUrl={imageUrl || DEFAULT_IMG_URLS.lesson} alt='preview' />
        <h6 className='mt-3 mb-4'>{lesson.nickName}</h6>
        <PublishRow label='Lesson Type' data={lessonTypesObj[lesson.contentType]} />
        <PublishRow label='Chapters' data={lesson.chapters} />
        <PublishRow label='Status' data={courseModes[lesson.mode]} />
      </div>
      {hasAccess('lessons_create') && (
        <div className='d-flex my-2 justify-conten-between'>
          <button
            disabled={fetching || hasNoEditAccess}
            className={`btn btn-sm btn-blue text-orange fz-12 mr-2 fw-600 w-100 d-flex align-items-center justify-content-center ${hasNoEditAccess &&
              'global_disabled'}`}
            onClick={onItemRemoveConfirm}
          >
            <IconBin className='mr-2' />
            Delete
          </button>
          <Link
            disabled={fetching}
            to={`/lesson/${lesson.id}/edit`}
            className={`btn btn-sm btn-blue ml-2 fz-12 fw-600 w-100 ${hasNoEditAccess &&
              'global_disabled'}`}
          >
            Edit
          </Link>
        </div>
      )}
      {hasAccess('lesson_publish') && (
        <button
          className={`btn btn-sm btn-primary w-100 `}
          disabled={fetching}
          onClick={onLessonTogglePublish}
        >
          {lesson.mode === 2 ? 'Unpublish' : 'Publish'}
        </button>
      )}
    </>
  );
};

export default PublishPreview;
