import React from 'react';
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  makeStyles,
} from '@material-ui/core';
import { IconExpandMore } from '../Icons';

const useStyles = makeStyles({
  root: {
    border: 'none',
    boxShadow: 'none',
  },
  summaryContainer: {
    height: '61px',
    background: '#EFF3FC 0% 0% no-repeat padding-box',
    borderRadius: '2px',
    color: '#131F6B',
    '& .MuiAccordionSummary-content': {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  title: {
    letterSpacing: '0px',
    color: '#131F6B',
    fontWeight: 'bold',
  },
});

const SimpleAccordion = props => {
  const classes = useStyles();
  const {
    expanded,
    title,
    headContent,
    handleChange,
    BodyContent,
    questions,
    questionSet,
    surveyUniqueId,
    noAccess,
    getSurveys,
    questionCase,
    isAdmin,
    isIncompleted,
    isMockSet,
  } = props;

  return (
    <Accordion className={classes.root} expanded={expanded} onChange={handleChange}>
      <AccordionSummary
        className={classes.summaryContainer}
        expandIcon={<IconExpandMore />}
        aria-controls='panel3bh-content'
        id='panel3bh-header'
      >
        <Typography className={classes.title} sx={{ width: '33%', flexShrink: 0 }}>
          {title}
        </Typography>
        <Typography>{headContent}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {isIncompleted ? (
          <div className='no-access-block'>You haven’t answered it yet</div>
        ) : noAccess || isMockSet ? (
          <div className='no-access-block'>These results are not yet published.</div>
        ) : (
          <BodyContent
            questions={questions}
            questionSet={questionSet}
            surveyUniqueId={surveyUniqueId}
            getSurveys={getSurveys}
            questionCase={questionCase}
            isAdmin={isAdmin}
          />
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default SimpleAccordion;
