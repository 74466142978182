import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { Button } from '@material-ui/core';

import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';
import { hasAccess } from 'utils/permissionHelper';

import EditingSelect from 'shared/components/editingForms/EditingSelect';
import EditingInput from 'shared/components/editingForms/EditingInput';
import { SwitchIOSLike } from 'shared/components/SwitchIOSLike';
import SmallCounter from 'shared/components/SmallCounter';
import ValidityPeriod from '../Licenses/components/ValidityPeriod';

import removeIcon from 'assets/bin.svg';

const LicensesNew = ({ history, match, location }) => {
  const { entityId } = match.params;
  const sampleLicenseId = location?.state?.sampleId;
  const account = useSelector(state => state.account);
  const hasEntityAccess = hasAccess('entity_list_view');
  const { enqueueSnackbar } = useSnackbar();
  const [fetch, setFetch] = useState(false);
  const [entities, setEntities] = useState([]);
  const [subs, setSubs] = useState([]);
  const [isEnabledNumOfSits, setIsEnabledNumOfSits] = useState(false);
  const [numberOfSits, setNumberOfSits] = useState(1);
  const [isValidPeriodEnabled, setIsValidPeriodEnabled] = useState(false);

  const [license, setLicense] = useState({
    subscriptionId: '',
    entityId: account.entity ? account.entity.id : entityId ? entityId : undefined,
    // maxUsageCount: 1,
    codes: [''],
  });

  const getSampleLicenseData = async id => {
    try {
      const { data } = await Api.get(`/entity/license/${sampleLicenseId}`);
      const body = data.data;
      body.user = body?.assigned ? body?.assigned.id : '';
      body.entityName = body.entity ? body.entity.name : '';
      body.entity = body.entity ? body.entity?.id || body.entity : '';
      body.userIdList =
        data?.userIdList || (body?.assigned?.length && body?.assigned?.map(user => user?.id)) || [];
      body.assignedUsers = [...body?.assigned];
      if (typeof body === 'object') {
        setLicense(prev => ({
          ...prev,
          ...body,
          subscriptionId: body?.subscription?.id,
          entityId: body?.entity,
          codes: [body?.licenseKey],
          dateRange: { start: body?.startDate, end: body?.endDate },
        }));
      }
      setIsValidPeriodEnabled(!!body?.startDate && !!body?.endDate);
      setNumberOfSits(body?.numOfSits);
      setIsEnabledNumOfSits(body?.numOfSits > 1);
    } catch (err) {
      history.push(`/licenses/all`);
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const getSelectDatas = async () => {
    try {
      const params = { page: 1, limit: 1000 };
      if (hasEntityAccess) {
        const entities = await Api.get(`/entity/entities`, { params });
        const resEntities = entities?.data?.data ? [...entities.data.data.results] : [];
        setEntities(resEntities);
      }
      const subscriptions = await Api.get(`/entity/subscriptions`, { params });
      setSubs(subscriptions.data.data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setLicense({ ...license, [name]: value });
  };

  const handleSubmit = async e => {
    const sendingLicense = {
      ...license,
      startDate: isValidPeriodEnabled ? license?.dateRange?.start : null,
      endDate: isValidPeriodEnabled ? license?.dateRange?.end : null,
      numOfSits: isEnabledNumOfSits ? numberOfSits : null,
    };
    e.preventDefault();
    try {
      setFetch(true);
      await Api.post(`/entity/licensekey/add`, sendingLicense);
      enqueueSnackbar('Successfully created', { variant: 'success' });
      history.goBack ? history.goBack() : history.push('/licenses/all');
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetch(false);
    }
  };

  const onChangeKey = (index, { target }, trim) => {
    const tempData = { ...license };
    const value = trim ? target.value.trim() : target.value;
    tempData.codes[index] = value;
    setLicense(tempData);
  };

  const onRemoveKey = index => {
    const tempData = { ...license };
    tempData.codes.splice(index, 1);
    setLicense(tempData);
  };

  const onAddKey = () => {
    const tempData = { ...license };
    tempData.codes.push('');
    setLicense(tempData);
  };

  const handleSwitchNumOfSits = () => {
    setIsEnabledNumOfSits(prev => !prev);
  };

  useEffect(() => {
    getSelectDatas();
    if (sampleLicenseId) {
      getSampleLicenseData(sampleLicenseId);
    }
    //eslint-disable-next-line
  }, [sampleLicenseId]);

  return (
    <div>
      <div className='users-header d-flex justify-content-between align-items-center'>
        <div className='d-flex breadcrumbs'>
          <Link to='/licenses/all'>License Keys</Link>
          <span className='mx-2'>/</span>
          <span>Add New Key</span>
        </div>
      </div>
      <div className='col-12 py-3 has-header'>
        <form onSubmit={handleSubmit} className='col-8 p-0 user-creation-form'>
          <ul className='p-0 mb-0'>
            <li className='d-flex align-items-center'>
              <div className='col-4 pl-0'>
                <label className='m-0'>Subscription Plan:</label>
              </div>
              <EditingSelect
                name='subscriptionId'
                onChange={handleChange}
                value={license.subscriptionId}
                items={subs}
                useProperty='id'
                displayProperty='title'
                required={true}
                editing={true}
              />
            </li>
            <li className='d-flex align-items-center'>
              <div className='col-4 pl-0'>
                <label className='m-0'>Entity:</label>
              </div>
              <EditingSelect
                name='entityId'
                onChange={handleChange}
                value={license.entityId || ''}
                items={entities}
                useProperty='id'
                displayProperty='name'
                editing={hasEntityAccess}
                forceValue={!hasEntityAccess && account.entity ? account.entity.name : false}
              />
            </li>
            <li className='d-flex align-items-center'>
              <ValidityPeriod
                license={license}
                setLicense={setLicense}
                isValidPeriodEnabled={isValidPeriodEnabled}
                setIsValidPeriodEnabled={setIsValidPeriodEnabled}
                setIsEnabledNumOfSits={setIsEnabledNumOfSits}
              />
            </li>
            <li className='list-default'>
              <div className='d-flex align-items-center'>
                <div className='col-4 pl-0'>
                  <label className='m-0'>Number Of Assignments:</label>
                </div>
                <div className='switch_container'>
                  <SwitchIOSLike
                    disabled={license.timeRange && isValidPeriodEnabled}
                    checkedState={isEnabledNumOfSits}
                    handleChange={handleSwitchNumOfSits}
                  />
                </div>
                {isEnabledNumOfSits ? (
                  <SmallCounter
                    disabled={!isEnabledNumOfSits}
                    count={numberOfSits}
                    setCount={setNumberOfSits}
                  />
                ) : (
                  <span className='form_gray_text h_31 d-flex align-items-center'>
                    {isValidPeriodEnabled ? 'No Limitation' : '1'}
                  </span>
                )}
              </div>
            </li>
          </ul>
          <h5 className='p-3 color-blue'>License Keys</h5>
          <ul className='p-0 mb-0'>
            {license.codes.map((item, index) => {
              return (
                <div className='d-flex drag-item' key={index}>
                  <li className='d-flex align-items-center w-100'>
                    <div className='col-4 pl-0'>
                      <label className='m-0'>License Key {index + 1}:</label>
                    </div>
                    <EditingInput
                      type='text'
                      name='item'
                      value={item}
                      onChange={e => {
                        onChangeKey(index, e, true);
                      }}
                      className='p-0'
                      placeholder='Enter Key'
                      required
                      editing={true}
                    />
                  </li>
                  <button
                    onClick={onRemoveKey.bind(null, index)}
                    disabled={license.codes.length < 2}
                    type='button'
                    className='btn btn-remove ml-2'
                  >
                    <img src={removeIcon} alt='icon' />
                  </button>
                </div>
              );
            })}
          </ul>
          <div className='d-flex justify-content-end text-right add-new-items-btns mt-0 mr-2 pr-5'>
            <button type='button' className='btn btn btn-outline-blue' onClick={onAddKey}>
              Add key +
            </button>
          </div>
          <div className='d-flex justify-content-end mt-4 border-top pt-4'>
            <Button
              className='btn btn-blue ml-2 px-4 fz-12 font-weight-bold'
              type='submit'
              disabled={fetch}
            >
              Save
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LicensesNew;
