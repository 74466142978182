import React, { useState } from 'react';
import ReactModal from 'react-modal';
import { useSnackbar } from 'notistack';

import ShareCourseModal from './ShareCourseModal';
import { UserOption } from 'shared/components/UsersSelect';
import { HOST } from 'configs';

import { IconCopy } from 'shared/components/Icons';
import { hasAccess } from 'utils/permissionHelper';

const ShareCourse = ({ className, onSuccess, course }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [modalState, setModalState] = useState(false);
  const sharePublicUrl = `${HOST.API.LEARNER_DOMAIN}/courses/${course.accessToken}`;

  const onCopyLinkClick = () => {
    navigator.clipboard.writeText(sharePublicUrl);
    enqueueSnackbar('Successfully Copied', { variant: 'success' });
  };

  return (
    <>
      <div
        className={`share-course-block d-flex flex-column justify-content-between ${className ||
          ''} ${course.isPublic ? 'is-small-box' : ''}`}
      >
        <div className='d-flex align-items-center justify-content-between'>
          <h1 className='mb-0'>Course Sharing</h1>
        </div>
        {(!course.isPublic || !course.isShared) && (
          <div className='py-3 max-200-scroll h-200'>
            {course.isShared && course.courseShares?.length ? (
              course.courseShares?.map((item, i) => {
                return (
                  <div key={i} className='mb-2'>
                    <UserOption user={{ ...item, ...item.user }} />
                  </div>
                );
              })
            ) : (
              <div className='course-not-shared-block d-flex align-items-center justify-content-center text-muted text-center'>
                This course is not shared with anyone yet
              </div>
            )}
          </div>
        )}
        {!!course.isPublic && !!course.isShared && (
          <div className='public-shared-text-container'>
            <p className='text-center fz-20'>
              This course is publicly shared. Anyone with the link can access.
            </p>
            {!!course.isPublic && !!course.isShared && (
              <button
                disabled={!course.isShared}
                className={`btn fz-13 fw-600 mt-2 px-0 btn-left-icon-copy-link`}
                onClick={onCopyLinkClick}
              >
                <span>Copy Link</span>
                <IconCopy />
              </button>
            )}
          </div>
        )}
        {hasAccess('course_share') && (
          <div>
            <button className='btn btn-sm btn-primary w-100' onClick={() => setModalState(true)}>
              Sharing Settings
            </button>
          </div>
        )}
      </div>
      {!!modalState && (
        <ReactModal isOpen={true} className='custom-modal'>
          <ShareCourseModal
            sharePublicUrl={sharePublicUrl}
            close={() => setModalState(false)}
            onSuccess={onSuccess}
            course={course}
            isCourseShared={course.isShared}
          />
        </ReactModal>
      )}
    </>
  );
};

export default ShareCourse;
