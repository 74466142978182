import { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router';
import useDebounce from './useDebounce';
import { structureDropFilter } from 'shared/components/filters/AdvancedFilters';
import moment from 'moment';

const getUrlParam = (name, defaultVal, initialDropFilters = {}) => {
  const defaultValue = Array.isArray(defaultVal) ? [] : defaultVal;
  const url = new URL(window.location.href);
  const param = url.searchParams.get(name);

  let result = param;
  if (param && name === 'date') {
    const [start, end] = param.split('/');
    if (!start || !end) {
      result = null;
    } else {
      result = moment().range(new Date(start), new Date(end));
    }
  } else if (param?.includes(',')) {
    result = param.split(',').map(i => {
      return !isNaN(i) ? Number(i) : i;
    });
  } else if (param && !isNaN(param)) {
    result = Number(param);
  } else if (param === 'true' || param === 'false') {
    result = JSON.parse(param);
  }

  if (result && Array.isArray(initialDropFilters[name]) && !Array.isArray(result)) {
    result = [result];
  }

  const isEmptyString = typeof result === 'string' && !result;
  return result === undefined || result === null || isEmptyString ? defaultValue : result;
};

const useFilters = (initialState, initialDropFilters = {}) => {
  const history = useHistory();
  const location = useLocation();
  const lastSearch = useRef();
  const [filters, setFilters] = useState({
    page: Number(getUrlParam('page', initialState?.page, initialDropFilters)),
    order: Boolean(getUrlParam('order', initialState?.order, initialDropFilters)),
    orderKey: getUrlParam('orderKey', initialState?.orderKey, initialDropFilters),
    search: getUrlParam('search', initialState?.search, initialDropFilters),
    limit: getUrlParam('limit', initialState?.limit, initialDropFilters),
  });

  const [dropFilters, setDropFilters] = useState({ ...initialDropFilters });
  const debouncedSearch = useDebounce(filters.search, 500);

  const pushNewUrl = (url, isReplace) => {
    if (window.location.search !== url.search) {
      history[isReplace ? 'replace' : 'push'](`${window.location.pathname}${url.search}`);
      lastSearch.current = url.search;
    }
  };

  const addUrlParams = (name, value) => {
    const url = new URL(window.location.href);
    url.searchParams.set(name, value);
    pushNewUrl(url);
  };

  const changeFilter = newFilters => {
    const url = new URL(window.location.href);
    const termpFilters = { ...filters };
    Object.keys(newFilters).forEach(key => {
      termpFilters[key] = newFilters[key];
      url.searchParams.set(key, newFilters[key]);
    });
    setFilters({ ...termpFilters });
    pushNewUrl(url);
  };

  const changeDropFilter = (name, value, lonelyId) => {
    addUrlParams('page', '0');
    addUrlParams(name, value);
    setDropFilters(filters => ({ ...filters, [name]: value }));
    setFilters(filters => ({ ...filters, page: 0 }));
  };

  const clearDropFilter = () => {
    const url = new URL(window.location.href);
    setDropFilters({ ...initialDropFilters });
    Object.keys(initialDropFilters).forEach(key => {
      url.searchParams.set(key, initialDropFilters[key]);
    });
    pushNewUrl(url);
  };

  const updateDropFiltersFromUrl = url => {
    const dropFilterFromUrl = {};
    Object.keys(dropFilters).forEach(key => {
      const initialValue = initialDropFilters[key];
      dropFilterFromUrl[key] = getUrlParam(key, initialValue, initialDropFilters);
      url.searchParams.set(key, dropFilterFromUrl[key]);
    });
    setDropFilters({ ...dropFilterFromUrl });
  };

  const updateFiltersFromUrl = url => {
    const filtersFromUrl = {};
    Object.keys(filters).forEach(key => {
      filtersFromUrl[key] = getUrlParam(key, '');
      url.searchParams.set(key, filtersFromUrl[key]);
    });
    setFilters({ ...filtersFromUrl });
  };

  useEffect(() => {
    const url = new URL(window.location.href);
    Object.keys(filters).forEach(key => {
      url.searchParams.set(key, filters[key]);
    });
    updateDropFiltersFromUrl(url);
    pushNewUrl(url, true);
  }, []);

  useEffect(() => {
    if (location.search && lastSearch.current !== location.search) {
      const url = new URL(window.location.href);
      updateDropFiltersFromUrl(url);
      updateFiltersFromUrl(url);
      pushNewUrl(url);
    }
  }, [location.search]);

  const resultFilters = { ...filters };
  resultFilters.search = debouncedSearch;

  const dependencyList = [...Object.values(dropFilters), ...Object.values(resultFilters)];

  const requestParams = {
    ...resultFilters,
    page: filters.page + 1,
    ...structureDropFilter({ ...dropFilters }),
  };

  return {
    requestParams: requestParams,
    filters: resultFilters,
    dropFilters: dropFilters,
    changeFilter: changeFilter,
    changeDropFilter: changeDropFilter,
    clearDropFilter: clearDropFilter,
    filterDependency: dependencyList,
    originalState: filters,
  };
};

export default useFilters;
