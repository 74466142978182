/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import Slider from 'react-slick';
import { BookCheckIcon, CalendarDaysIcon } from 'shared/components/Icons';
import { SLIDE_SETTINGS } from '../swiperSettings';
import useProductPageTitleTypes from '../../Products/useProductPageTypes';

const DetailsCard = ({ data, activeId, handleSelectPage, selectedSpecialtyId }) => {
  const PageIconTypes = useProductPageTitleTypes();
  return (
    <div
      onClick={() => handleSelectPage(data.id)}
      className={`page-details-card ${data?.id === selectedSpecialtyId && 'active'}`}
    >
      <div className='head-part'>
        <div className='icon-bloc'>{PageIconTypes[data.titleIcon]}</div>
        <h6 className='card-title'>{data.title}</h6>
      </div>
      <div className='bottom-part'>
        <div className='desc max-row-4'>{data.description}</div>
        <div className='footer-part'>
          <div className='footer-item'>
            <div className='icon-block'>
              <CalendarDaysIcon />
            </div>
            <span className='key-name'>Assigned:</span>
            <span className='value'>05.09.2019</span>
          </div>
          <div className='footer-item'>
            <div className='icon-block'>
              <BookCheckIcon />
            </div>
            <span className='key-name'>Courses:</span>
            <span className='value'>13</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const Pages = props => {
  const { productPages, currentPage, handleSelectPage, selectedSpecialtyId } = props;

  return (
    <div className='pages-container'>
      <h2 className='section-title'>Product Pages</h2>
      <div className='page-container'>
        <div className='page-cards-sec'>
          {!!productPages && (
            <Slider {...SLIDE_SETTINGS}>
              {productPages.map((page, idx) => (
                <DetailsCard
                  selectedSpecialtyId={selectedSpecialtyId}
                  handleSelectPage={handleSelectPage}
                  activeId={currentPage?.id}
                  key={idx}
                  data={page}
                />
              ))}
            </Slider>
          )}
        </div>
      </div>
    </div>
  );
};

export default Pages;
