/* eslint-disable no-console */
import React, { useRef, useEffect, useState } from 'react';
import {
  zoomImageMouseClick,
  maximazeElement,
  getFindingsCoordinates,
  createFindingEventData,
  getDicomImagesObj,
  createGenuieEventData,
  sumShapes,
} from '../utils';
import Thumbnail from './Thumbnail';
// import ScaleRules from './ScaleRules';
import StackControl from './StackControl';

import * as cornerstone from 'cornerstone-core';
import cornerstoneMath from 'cornerstone-math';
import cornerstoneTools from 'cornerstone-tools/dist/cornerstoneTools';
import * as cornerstoneWebImageLoader from 'cornerstone-web-image-loader';
import Hammer from 'hammerjs';
import Loading from 'shared/components/Loading';
import ArrowTool from '../plugins/ArrowTool';
import GenuineTool from '../plugins/GenuineTool';

import iconMax from 'assets/arrows/2p.svg';
import { genuieShapes, getDefaultType, getToolByType } from '../configs';
import { useDispatch } from 'react-redux';
import { getToolState } from '../plugins/stateManagement/toolState';
import { IconGenuine } from 'shared/components/Icons';
import { setCanBeActive } from 'shared/components/Viewer/actions';

cornerstoneWebImageLoader.external.cornerstone = cornerstone;
cornerstoneTools.external.cornerstone = cornerstone;
cornerstoneTools.external.Hammer = Hammer;
cornerstoneTools.external.cornerstoneMath = cornerstoneMath;

cornerstoneTools.init({ mouseEnabled: true, showSVGCursors: false, autoResizeViewports: true });
cornerstoneTools.toolStyle.setToolWidth('4');

const switchers = [
  { name: '2D', value: '2D' },
  { name: 'Tomo', value: 'tomo' },
  { name: '3DQ', value: '3DQ' },
];

const CanvasView = ({
  dataTomo,
  data2D,
  data3DQ,
  activeTool,
  step,
  fullScreen,
  viewsCount,
  setActiveTool,
  viewSizesForced,
  currentImageIndex = 0,
}) => {
  const dicomTypeData = {
    tomo: dataTomo,
    '2D': data2D,
    '3DQ': data3DQ,
  };

  const dispatch = useDispatch();
  const isGenuine = activeTool && activeTool.type === 'genuine';
  const [play, setPlay] = useState(false);
  const [showGenuie, setShowGenuie] = useState(true);
  const [imageFetch, setImageFetch] = useState(false);
  const [stackControl, setStackControl] = useState(getDefaultType(step.dataType, dicomTypeData));
  const [viewportState, setViewportState] = useState();
  const element = useRef(null);
  const isFirstRun = useRef(true);

  const dicomData = dicomTypeData[stackControl];

  const getViewSizes = () => {
    if (viewSizesForced) return viewSizesForced();
    return {
      width: (window.innerWidth - (fullScreen ? 0 : 240)) / viewsCount,
      height: window.innerHeight - 95 - 80,
    };
  };

  const onArrowUpdate = image => {
    setTimeout(() => {
      try {
        const viewport = cornerstone.getViewport(element.current);
        const currentImage = viewport.images[image.imageId];
        if (!currentImage) return;
        const { emptyFinding, finding } = currentImage;
        const cuurentArrowData = getToolState(element.current, 'ArrowTool');
        const body = { ...(finding || emptyFinding) };
        body.vectorData = getFindingsCoordinates(cuurentArrowData);
      } catch (err) {
        console.log(err);
      }
    }, 100);
  };

  const updateCornerstone = () => {
    try {
      const viewport = cornerstone.getViewport(element.current);
      if (!viewport) return;
      cornerstone.setViewport(element.current, viewport);
    } catch (err) {
      console.log(err);
    }
  };

  const initFindings = () => {
    try {
      cornerstoneTools.addToolForElement(element.current, ArrowTool, {
        onArrowMove: onArrowUpdate,
        onArrowAdd: onArrowUpdate,
        onArrowRemove: onArrowUpdate,
      });
      dispatch(setCanBeActive('findingToggle', false));
      cornerstoneTools.setToolEnabledForElement(element.current, 'ArrowTool');
      setActiveToolEvent();
      setTimeout(updateCornerstone, 1000);
    } catch (err) {
      console.log(err);
    }
  };

  const initGenuines = () => {
    try {
      cornerstoneTools.addToolForElement(element.current, GenuineTool, {});
      cornerstoneTools.setToolEnabledForElement(element.current, 'GenuineTool');
      setActiveToolEvent();
      setTimeout(updateCornerstone, 1000);
    } catch (err) {
      console.log(err);
    }
  };

  const updateCurrentImageFindings = ({ image }, viewport) => {
    const imageInfo = viewport.images[image.imageId];
    if (!imageInfo) return;
    const findings = imageInfo.finding;
    if (findings && findings.vectorData && !imageInfo.inited) {
      findings.vectorData.forEach(item => {
        cornerstoneTools.addToolState(element.current, 'ArrowTool', createFindingEventData(item));
      });
    }
    imageInfo.inited = true;
  };

  const updateCurrentImageGenuine = ({ image }, viewport) => {
    const imageInfo = viewport.images[image.imageId];
    if (!imageInfo) return;
    const points = imageInfo.geniusAIDataList;
    if (points && points.length && !imageInfo.genuineInited) {
      points.forEach(item => {
        cornerstoneTools.addToolState(element.current, 'GenuineTool', createGenuieEventData(item));
      });
    }
    imageInfo.genuineInited = true;
  };

  const onImageRendered = (e, v) => {
    try {
      if (!element.current) return;
      const viewport = cornerstone.getViewport(element.current);
      updateCurrentImageFindings(e.detail, viewport);
      updateCurrentImageGenuine(e.detail, viewport);
      setViewportState(viewport);
    } catch (err) {
      console.log(err);
    }
  };

  const onWindowResize = () => {
    cornerstone.resize(element.current);
    setElementDisplayPosition();
  };

  const setElementDisplayPosition = notMove => {
    const viewport = cornerstone.getViewport(element.current);
    if (!viewport) return;
    // const isLeft = step.position === 'left' && step.thumbpos === 'left';
    const { image, scale } = viewport;
    const { clientWidth } = element.current;
    const imageWidth = image.width; //!stackControl ? image.width : image.width + (isLeft ? -1000 : 0);
    const translation = { x: clientWidth / scale / 2 - imageWidth / 2, y: 0 };
    if (step.position === 'left') translation.x = -translation.x;
    if (!notMove) viewport.translation = translation;
    viewport.initialScale = scale;
    viewport.voi = { windowWidth: 255, windowCenter: 128 };
    cornerstone.setViewport(element.current, viewport);
  };

  const setImageStack = element => {
    const imageIds = dicomData.images.map(item => item.url);
    const stack = { currentImageIdIndex: 0, imageIds };
    cornerstoneTools.addStackStateManager(element, ['stack']);
    cornerstoneTools.addToolState(element, 'stack', stack);
  };

  const loadImage = async () => {
    if (!element.current) return;
    setImageFetch(true);
    try {
      const images = getDicomImagesObj(dicomData.images);
      const dicomImage = dicomData.images[currentImageIndex] || dicomData.images[0];
      const image = await cornerstone.loadAndCacheImage(dicomImage.url);
      const viewportOptions = { pixelReplication: false, image, dicomImage, images };
      await cornerstone.displayImage(element.current, image, viewportOptions);
    } catch (err) {
      console.log(err);
    } finally {
      setImageFetch(false);
    }
  };

  const onRightClick = (el, e) => {
    if (e.which !== 3) return;
    e.preventDefault();
    let tool;
    setActiveTool(item => {
      tool = item;
      return getToolByType('pan');
    });

    const handleMouseUp = (tool, e) => {
      if (e.which !== 3) return;
      el.removeEventListener('mouseup', handleMouseUp.bind(null, tool), false);
      const setTool = tool && tool.type && tool.type !== 'reset' ? tool : { type: 'none' };
      setActiveTool(setTool);
    };

    el.addEventListener('mouseup', handleMouseUp.bind(null, tool), false);
    // eslint-disable-next-line consistent-return
    return false;
  };

  const initElement = async element => {
    try {
      await cornerstone.enable(element);
      await cornerstone.resize(element);
      element.addEventListener('cornerstoneimagerendered', onImageRendered);
      window.addEventListener('resize', onWindowResize);
      element.addEventListener('mousedown', onRightClick.bind(null, element), false);
      element.addEventListener('contextmenu', e => e.preventDefault(), false);
    } catch (err) {
      console.log(err);
    }
  };

  const initData = async element => {
    try {
      if (!dicomData) return;
      await element.classList.remove('maximize');
      await setImageStack(element);
      await loadImage();
      await cornerstone.fitToWindow(element);
      await cornerstone.resize(element);
      setElementDisplayPosition();
      setActiveToolEvent();
      setTimeout(initFindings, 100);
      setTimeout(initGenuines, 100);
    } catch (err) {
      console.log(err);
    }
  };

  const unmountElement = async () => {
    try {
      element.current.removeEventListener('cornerstoneimagerendered', onImageRendered);
      window.removeEventListener('resize', onWindowResize);
      cornerstone.disable(element.current);
    } catch (err) {
      console.log(err);
    }
  };

  const removeTool = name => {
    const { current } = element;
    if (cornerstoneTools.getToolForElement(current, name)) {
      cornerstoneTools.removeToolForElement(current, name);
    }
  };

  const setActiveToolEvent = async () => {
    try {
      const viewport = cornerstone.getViewport(element.current);
      if (!viewport || !activeTool.type) return;
      const { type, onTimeAction, noResetFindings } = activeTool;
      const { current } = element;
      current.onclick = null;

      removeTool('Pan');
      removeTool('Wwwc');
      removeTool('Zoom');
      removeTool('ZoomMouseWheel');

      if (type === 'none' || (onTimeAction && !noResetFindings)) {
        cornerstoneTools.setToolEnabledForElement(current, 'ArrowTool');
      }

      if (type === 'reset') {
        current.classList.remove('maximize');
        setTimeout(async () => {
          await cornerstone.resize(current);
          await cornerstone.fitToWindow(current);
          await setElementDisplayPosition();
        });
      } else if (type === 'pan') {
        cornerstoneTools.addToolForElement(current, cornerstoneTools.PanTool);
        cornerstoneTools.setToolActive('Pan', { mouseButtonMask: 1 });
        cornerstoneTools.setToolActive('Pan', { mouseButtonMask: 2 });
      } else if (type === 'zoomInteractive') {
        const options = {
          configuration: {
            invert: false,
            preventZoomOutsideImage: false,
            minScale: 0.01,
            maxScale: 5.0,
          },
        };
        cornerstoneTools.addToolForElement(current, cornerstoneTools.ZoomMouseWheelTool, options);
        cornerstoneTools.addToolForElement(current, cornerstoneTools.ZoomTool, options);
        cornerstoneTools.setToolActive('ZoomMouseWheel', { mouseButtonMask: 1 });
        cornerstoneTools.setToolActive('Zoom', { mouseButtonMask: 1 });
      } else if (type === 'zoomMagnifier') {
        current.onclick = zoomImageMouseClick.bind(null, cornerstone, current, true, false);
      } else if (type === 'windowLevel') {
        cornerstoneTools.addToolForElement(current, cornerstoneTools.WwwcTool);
        cornerstoneTools.setToolActive('Wwwc', { mouseButtonMask: 1 });
      } else if (type === 'finding') {
        cornerstoneTools.setToolActive('ArrowTool', { mouseButtonMask: 1 });
      } else if (type === 'findingToggle') {
        const tool = cornerstoneTools.getToolForElement(current, 'ArrowTool');
        const isDisabled = tool && tool.mode === 'disabled';
        dispatch(setCanBeActive(type, !isDisabled));
        if (isDisabled) {
          cornerstoneTools.setToolEnabledForElement(current, 'ArrowTool');
        } else {
          cornerstoneTools.setToolDisabledForElement(current, 'ArrowTool');
        }
        setTimeout(updateCornerstone, 500);
      } else if (type === 'findingReset') {
        cornerstoneTools.clearToolState(current, 'ArrowTool');
        setTimeout(updateCornerstone, 100);
      } else if (type === 'zoom1_1') {
        current.onclick = () => {
          maximazeElement(cornerstone, current);
          zoomImageMouseClick(cornerstone, current, true, 1);
        };
      }
    } catch (err) {
      console.log(err);
    }
  };

  const toggleMax = async () => {
    maximazeElement(cornerstone, element.current, true);
    setElementDisplayPosition(true);
  };

  const toggleGenuie = () => {
    if (showGenuie) {
      cornerstoneTools.setToolDisabledForElement(element.current, 'GenuineTool');
    } else {
      cornerstoneTools.setToolEnabledForElement(element.current, 'GenuineTool');
    }
    setTimeout(updateCornerstone, 200);
    setShowGenuie(!showGenuie);
  };

  const fullScreenChange = async () => {
    if (!element || !element.current) return;
    try {
      setTimeout(() => {
        cornerstone.resize(element.current);
        cornerstone.fitToWindow(element.current);
        setElementDisplayPosition();
      }, 500);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    initElement(element.current);
    return () => unmountElement();
    //eslint-disable-next-line
  }, [stackControl]);

  useEffect(() => {
    initData(element.current);
    //eslint-disable-next-line
  }, [dataTomo, step, stackControl]);

  useEffect(() => {
    if (element.current) onWindowResize();
    //eslint-disable-next-line
  }, [step]);

  useEffect(() => {
    setActiveToolEvent();
    //eslint-disable-next-line
  }, [activeTool]);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    fullScreenChange();
    //eslint-disable-next-line
  }, [fullScreen]);

  const showSlice = element && viewportState && stackControl !== '2D' && !currentImageIndex;
  const hasGenuie = !!dicomData?.images.filter(item => !!item.geniusAIDataList?.length).length;

  return (
    <div
      className={`viewport-element ${step.thumbpos}`}
      style={{ ...getViewSizes() }}
      ref={element}
      key={stackControl}
    >
      {hasGenuie && (
        <div className={`genuie-button-area ${step.thumbpos}`}>
          <button className={`btn p-0 toggle-genuie-button`} onClick={toggleGenuie}>
            <IconGenuine color={showGenuie ? '#05a6db' : undefined} />
          </button>
          {showGenuie && (
            <div className='genuie-modes-items d-flex flex-column'>
              {genuieShapes.map((item, i) => {
                const Icon = item.icon;
                const shapesCount = sumShapes(dicomData.images, item.shape);
                return (
                  <button key={i} className={`btn btn-sm p-0`}>
                    <Icon />
                    {!!shapesCount && <span>{shapesCount}</span>}
                  </button>
                );
              })}
            </div>
          )}
        </div>
      )}
      {viewportState && <Thumbnail viewport={viewportState} />}
      {dicomData && (
        <div className='dicom-number d-flex align-items-center justify-content-between'>
          <div className='d-flex align-items-center'>
            <button onClick={toggleMax} className='btn maximize-icon mr-2'>
              <img src={iconMax} alt='maximize' />
            </button>
            <span>
              0{dicomData.patient.age}Y {dicomData.imageLaterality} {dicomData.viewPosition}
            </span>
          </div>
        </div>
      )}
      {step.has_switcher && (
        <div className='tomo-switch d-flex justify-content-center'>
          <div>
            {switchers.map(item => (
              <button
                key={item.value}
                disabled={!dicomTypeData[item.value]}
                onClick={() => setStackControl(item.value)}
                className={stackControl === item.value ? 'active' : ''}
              >
                {item.name}
              </button>
            ))}
          </div>
        </div>
      )}
      {/* {viewportState && (
        <ScaleRules number={viewportState.scale * 1000} date={dicomData.patient.birthDate} />
      )} */}
      {showSlice && dicomData && (
        <StackControl
          setActiveTool={setActiveTool}
          cornerstone={cornerstone}
          cornerstoneTools={cornerstoneTools}
          element={element.current}
          images={dicomData.images}
          imageType={dicomData.viewPosition}
          play={play}
          setPlay={setPlay}
          className={'right'}
          isGenuine={isGenuine}
        />
      )}
      {imageFetch && (
        <Loading className='h-100 d-flex justify-content-center flex-column align-items-center' />
      )}
      {dicomData && <canvas className={`cornerstone-canvas cursor-${activeTool.cursor}`} />}
      {!dicomData && (
        <div className='viewer-no-image d-flex align-items-center justify-content-center text-white'>
          No Image Available
        </div>
      )}
    </div>
  );
};

export default CanvasView;
