import React from 'react';

const CheckBox = ({ name, label, value, onChange, className, checked }) => {
  return (
    <div className={`align-items-center d-flex ${className || ''}`}>
      <input
        type='checkbox'
        id={`radio${name}`}
        className='input-custom is-checkbox'
        name={name}
        onChange={onChange}
        value={value}
        checked={checked}
      />
      {label && <label htmlFor={`radio${name}`}>{label}</label>}
    </div>
  );
};

export default CheckBox;
