import React from 'react';

import tool1Icon from 'assets/tools/1p.svg';
import tool1IconActive from 'assets/tools/1a.svg';
import tool2Icon from 'assets/tools/2p.svg';
import tool2IconActive from 'assets/tools/2a.svg';
// import tool3Icon from 'assets/tools/3p.svg';
// import tool3IconActive from 'assets/tools/3a.svg';
import tool4Icon from 'assets/tools/4p.svg';
import tool4IconActive from 'assets/tools/4a.svg';
import tool5Icon from 'assets/tools/5p.svg';
import tool5IconActive from 'assets/tools/5a.svg';
import tool6Icon from 'assets/tools/6p.svg';
import tool6IconActive from 'assets/tools/6a.svg';
import tool7Icon from 'assets/tools/7p.svg';
import tool7IconActive from 'assets/tools/7a.svg';
import tool8Icon from 'assets/tools/8p.svg';
import tool8IconActive from 'assets/tools/8a.svg';
import tool9Icon from 'assets/tools/9p.svg';
import tool9IconActive from 'assets/tools/9a.svg';
import tool10Icon from 'assets/tools/10p.svg';
import tool10IconActive from 'assets/tools/10p.svg';

import { IconGenuineCross, IconGenuineFlake, IconGenuineTriangle } from 'shared/components/Icons';
import {
  IconLCC,
  IconLCCFill,
  IconLMLO,
  IconLMLOFill,
  IconRCC,
  IconRCCFill,
  IconRMLO,
  IconRMLOFill,
} from '../components/Icons';

export const genuieShapes = [
  { icon: IconGenuineTriangle, shape: 2 },
  { icon: IconGenuineCross, shape: 1 },
  { icon: IconGenuineFlake, shape: 3 },
];

const typeIcons = {
  RCC: <IconRCC className='mx--2' />,
  RCCFill: <IconRCCFill className='mx--2' />,
  LCC: <IconLCC className='mx--2' />,
  LCCFill: <IconLCCFill className='mx--2' />,
  RMLO: <IconRMLO className='mx--2' />,
  RMLOFill: <IconRMLOFill className='mx--2' />,
  LMLO: <IconLMLO className='mx--2' />,
  LMLOFill: <IconLMLOFill className='mx--2' />,
};

const typeIconsActive = {
  RCC: <IconRCC color='rgba(0, 145, 156, 1)' className='mx--2' />,
  RCCFill: <IconRCCFill color='rgba(0, 145, 156, 1)' className='mx--2' />,
  LCC: <IconLCC color='rgba(0, 145, 156, 1)' className='mx--2' />,
  LCCFill: <IconLCCFill color='rgba(0, 145, 156, 1)' className='mx--2' />,
  RMLO: <IconRMLO color='rgba(0, 145, 156, 1)' className='mx--2' />,
  RMLOFill: <IconRMLOFill color='rgba(0, 145, 156, 1)' className='mx--2' />,
  LMLO: <IconLMLO color='rgba(0, 145, 156, 1)' className='mx--2' />,
  LMLOFill: <IconLMLOFill color='rgba(0, 145, 156, 1)' className='mx--2' />,
};

const dicomTypes = {
  '3dq': '3DQ',
  i2d: '2D',
  g2d: '2D',
  r2d: '2D',
  tomo: 'tomo',
  sub: 'tomo',
};

export const tools = [
  {
    name: 'FT',
    title: 'Show/Hide Finding Tool',
    icon: tool1Icon,
    iconActive: tool1IconActive,
    type: 'findingToggle',
    cursor: 'default',
    height: 23,
    onTimeAction: true,
    canStayActive: true,
    noResetFindings: true,
  },
  {
    name: 'F',
    title: 'Activate Finding Tool',
    icon: tool2Icon,
    iconActive: tool2IconActive,
    type: 'finding',
    cursor: 'arrow',
    height: 23,
    onTimeAction: false,
  },
  {
    name: 'FR',
    title: 'Reset Finding Tool',
    icon: tool10Icon,
    iconActive: tool10IconActive,
    type: 'findingReset',
    cursor: 'default',
    height: 23,
    onTimeAction: true,
  },
  {
    type: 'separator',
  },
  {
    name: 'R',
    title: 'Reset Viewers',
    icon: tool4Icon,
    iconActive: tool4IconActive,
    type: 'reset',
    cursor: 'default',
    onTimeAction: true,
  },
  {
    name: 'P',
    title: 'Activate Panning Tool',
    icon: tool5Icon,
    iconActive: tool5IconActive,
    type: 'pan',
    cursor: 'pan',
    onTimeAction: false,
  },
  {
    name: 'I Z',
    title: 'Activate Interactive Zoom',
    icon: tool6Icon,
    iconActive: tool6IconActive,
    type: 'zoomInteractive',
    cursor: 'izoom',
    onTimeAction: false,
  },
  {
    name: 'Z 1:1',
    title: 'Activate 1:1 Tool',
    icon: tool7Icon,
    iconActive: tool7IconActive,
    type: 'zoom1_1',
    cursor: 'fullzoom',
    onTimeAction: false,
  },
  {
    name: 'M',
    title: 'Activate Magnifier Tool',
    icon: tool8Icon,
    iconActive: tool8IconActive,
    type: 'zoomMagnifier',
    cursor: 'mzoom',
    onTimeAction: false,
  },
  {
    name: 'W/L',
    title:
      'Activate Window/Level Tool (W; for presets move mouse over viewer and press numeric keys 1, 2, 3, ...)',
    icon: tool9Icon,
    iconActive: tool9IconActive,
    type: 'windowLevel',
    cursor: 'wlevel',
    onTimeAction: false,
  },
];

export const getToolByType = type => {
  return tools.find(item => item.type === type);
};

export const getDefaultType = (type, datas) => {
  if (type === '3DQ' && !datas['3DQ'] && datas['tomo']) return 'tomo';
  return type;
};

export const hangingProtocolToStepsStructure = protocol => {
  return protocol.steps.map((step, index) => {
    const views = {};
    step.frames.forEach(frame => {
      const key = `${frame.imageLaterality}${frame.viewPosition}`;
      const dataType = dicomTypes[frame.imageType];
      views[key] = {
        active: true,
        position: frame.imageLaterality === 'R' ? 'right' : 'left',
        thumbpos: frame.imageLaterality === 'R' ? 'left' : 'right',
        key,
        has_switcher: true,
        dataType,
        // imageType: frame.imageType,
      };
    });

    const getIcons = object => {
      return (
        <>
          {Object.values(views).map(view => {
            const key = view.dataType === '2D' ? view.key : `${view.key}Fill`;
            return object[key];
          })}
        </>
      );
    };

    return {
      id: step.step,
      name: `Step ${step.step}`,
      icon: getIcons(typeIcons),
      iconActive: getIcons(typeIconsActive),
      views: views,
    };
  });
};
