import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Api } from 'utils/connectors';
import UsersHeader from './components/UsersHeader';
import ReactPaginate from 'react-paginate';
import UsersLists from './components/UsersLists';
import { getError } from 'utils/appHelpers';
import { IconArrowLeft, IconArrowRight } from 'shared/components/Icons';
import AdvancedFilters from 'shared/components/filters/AdvancedFilters';
import useFilters from 'shared/hooks/useFilters';
import useDidUpdateEffect from 'shared/hooks/useDidUpdateEffect';

const filterStatus = [
  { name: 'Active', id: 1 },
  { name: 'Inactive', id: 2 },
  { name: 'Invited', id: 4 },
];

const initialFilters = () => {
  return { status: undefined, role: undefined };
};

const Admins = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [fetch, setFetch] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [users, setUsers] = useState();
  const [rolesList, setRolesList] = useState([]);

  const {
    requestParams,
    filters,
    dropFilters,
    changeFilter,
    changeDropFilter,
    clearDropFilter,
    filterDependency,
    originalState,
  } = useFilters(
    { page: 0, order: false, orderKey: 'createdAt', search: '', limit: 8 },
    { ...initialFilters() },
  );

  const fetch_all = Number(dropFilters.status) === 4 || !dropFilters.status;

  const parameters = {
    ...requestParams,
    fetch_all,
    type: 0,
  };

  const downloadData = [
    {
      title: 'Export as CSV',
      url: '/admin/getusers',
      fileName: 'admins_list',
      type: 'csv',
      params: parameters,
    },
  ];

  const getRolesList = async () => {
    try {
      const params = {
        limit: 10000,
      };
      const { data } = await Api.get('/admin/roles', { params });
      const results = data.data.results.map(i => ({ name: i.title, id: i.id }));
      setRolesList(results);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const getUsersList = async toPage => {
    try {
      setFetch(true);
      const params = { ...parameters };
      const { data } = await Api.get('/admin/getusers', { params });
      setPageCount(data.data.pageCount);
      setUsers(data.data.results);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetch(false);
    }
  };

  const onClearAllFilters = () => {
    clearDropFilter();
  };

  const onFilterChange = (name, id) => {
    changeDropFilter(name, id);
  };

  const onPageChange = ({ selected }) => {
    changeFilter({ page: selected });
  };

  const handleSearchChange = text => {
    changeFilter({ search: text });
  };

  const onChangeOrder = item => {
    if (!item.key) return;
    changeFilter({ order: !filters.order, orderKey: item.key });
  };

  const filtersOptions = [
    {
      type: 'dropdown',
      title: 'Type',
      name: 'status',
      data: filterStatus,
      value: dropFilters.status,
      onFilterChange: onFilterChange,
    },
    {
      type: 'dropdown',
      title: 'Role',
      name: 'role',
      data: rolesList,
      value: dropFilters.role,
      showSearch: true,
      onFilterChange: onFilterChange,
    },
  ];

  useDidUpdateEffect(() => {
    getUsersList();
  }, [...filterDependency]);

  useEffect(() => {
    getUsersList();
    getRolesList();
  }, []);

  return (
    <div>
      <UsersHeader search={originalState.search} onSearch={handleSearchChange} />
      <div className='py-3 has-header'>
        <AdvancedFilters
          downloadData={downloadData}
          withExport={true}
          filters={filtersOptions}
          onClearAll={onClearAllFilters}
        />
        <div className='col-12'>
          <UsersLists
            users={users}
            order={filters}
            status={dropFilters.status}
            changeOrder={onChangeOrder}
            fetch={fetch}
          />
        </div>
        {pageCount > 1 && !fetch && (
          <ReactPaginate
            previousLabel={<IconArrowLeft />}
            nextLabel={<IconArrowRight />}
            breakLabel={'...'}
            forcePage={Number(filters.page)}
            breakClassName={'break-me'}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={2}
            onPageChange={onPageChange}
            containerClassName={'pagination show-arrow'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
          />
        )}
      </div>
    </div>
  );
};

export default Admins;
