import store from 'redux/store';
import { createReducer } from 'utils/reduxHelpers';

const ON_DICOM_UPLOAD = '@@cases/ON_DICOM_UPLOAD';

export const onCasesUpload = payload => ({ type: ON_DICOM_UPLOAD, payload });

const uploadHandlers = {
  [ON_DICOM_UPLOAD]: (state, action) => ({ ...action.payload }),
};

const uploadMediaInitialState = {
  downloadStarted: false,
  files: [],
  progress: {},
};

export const uploadMediaReducer = createReducer(uploadMediaInitialState, uploadHandlers);

export const startDownloading = bool => {
  const settings = store.getState().uploader;
  store.dispatch(onCasesUpload({ ...settings, downloadStarted: bool }));
};

export const setCaseFiles = files => {
  const settings = store.getState().uploader;
  store.dispatch(onCasesUpload({ ...settings, files: [...files] }));
};

export const removeCaseFile = fileName => {
  const settings = store.getState().uploader;
  const tempFiles = [...settings.files].filter(i => i.name !== fileName);
  store.dispatch(onCasesUpload({ ...settings, files: [...tempFiles] }));
  return tempFiles;
};

export const setCaseFilesProgress = (fileName, percent) => {
  const settings = store.getState().uploader;
  store.dispatch(
    onCasesUpload({ ...settings, progress: { ...settings.progress, [fileName]: percent } }),
  );
};
