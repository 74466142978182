import React, { useState, useEffect } from 'react';
import Button from 'shared/components/Button';
import InputGroup from 'shared/components/InputGroup';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';
import { getAndUpdateProfile } from 'app/Auth/actions';
import { IconEdit } from 'shared/components/Icons';

const Settings = ({ data, getAndUpdateProfile }) => {
  const { enqueueSnackbar } = useSnackbar();
  const profile = data.learnerProfile || {};
  const [fetch, setFecth] = useState(false);
  const [isEdit, toggleEdit] = useState(false);
  const [info, setInfo] = useState({
    firstName: data.firstName || '',
    lastName: data.lastName || '',
  });

  const [learner, setLearner] = useState({
    phone: profile.phone || '',
  });

  const handleInfoChange = ({ target }) => {
    setInfo({ ...info, [target.name]: target.value });
  };

  const handleLearnerChange = ({ target }) => {
    setLearner({ ...learner, [target.name]: target.value });
  };

  const onSubmitHandle = async () => {
    try {
      setFecth(true);
      const body = { userId: data.id, ...info, learnerProfile: learner };
      await Api.post('/auth/edituser', body);
      await getAndUpdateProfile();
      enqueueSnackbar('Successfully updated', { variant: 'success' });
      toggleEdit(false);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFecth(false);
    }
  };

  return (
    <div className='flex-fill ml-2'>
      <ul className='list-default list-settings d-flex flex-wrap'>
        <li className='d-flex align-items-center px-0 p-2 col-6'>
          <div className='col weight-700'>
            {isEdit ? (
              <InputGroup
                type='text'
                name='firstName'
                value={info.firstName}
                onChange={handleInfoChange}
                className='no-min-w'
              />
            ) : (
              data.firstName || '-'
            )}
          </div>
        </li>
        <li className='d-flex align-items-center px-0 p-2 col ml-2'>
          <div className='col weight-700'>
            {isEdit ? (
              <InputGroup
                type='text'
                name='lastName'
                value={info.lastName}
                onChange={handleInfoChange}
                className='no-min-w'
              />
            ) : (
              data.lastName || '-'
            )}
          </div>
        </li>
        <li className='d-flex align-items-center px-0 p-2 col-12'>
          <div className='col weight-700'>
            {isEdit ? (
              <InputGroup
                type='text'
                name='phone'
                value={learner.phone}
                onChange={handleLearnerChange}
                placeholder='Phone Number'
                className='no-min-w'
              />
            ) : (
              profile.phone || '-'
            )}
          </div>
        </li>
      </ul>
      <div className='d-flex justify-content-end mt-2'>
        {isEdit ? (
          <>
            <Button disabled={fetch} className='btn-blue mr-3' onClick={() => toggleEdit(false)}>
              Cancel
            </Button>
            <Button disabled={fetch} className='btn-blue' onClick={onSubmitHandle}>
              Save
            </Button>
          </>
        ) : (
          <Button
            className='btn-blue px-2 px-3 d-flex align-items-center'
            onClick={() => toggleEdit(true)}
          >
            <IconEdit className='mr-1' /> Edit
          </Button>
        )}
      </div>
    </div>
  );
};

export default connect(
  null,
  { getAndUpdateProfile },
)(Settings);
