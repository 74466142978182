import csTools from 'cornerstone-tools';
import * as cornerstone from 'cornerstone-core';
import cornerstoneMath from 'cornerstone-math';
// State
import { getToolState, addToolState, removeToolState } from './stateManagement/toolState.js';
import toolColors from './stateManagement/toolColors.js';
// Drawing
import { getNewContext, draw } from './drawing/index.js';
import drawHandles from './drawing/drawHandles.js';
// Utilities
import { probeCursor } from './cursors/index.js';

const BaseAnnotationTool = csTools.importInternal('base/BaseAnnotationTool');

export default class ArrowTool extends BaseAnnotationTool {
  constructor(props = {}) {
    const defaultProps = {
      name: 'ArrowTool',
      supportedInteractionTypes: ['Mouse', 'Touch'],
      svgCursor: probeCursor,
      configuration: {
        drawHandles: true,
      },
    };

    super(props, defaultProps);
    this.touchPressCallback = this._onClickArrow.bind(this);
    this.doubleClickCallback = this._onClickArrow.bind(this);
  }

  createEventData({ x, y }) {
    return {
      visible: true,
      active: true,
      color: undefined,
      invalidated: true,
      handles: {
        end: {
          x,
          y,
          highlight: true,
          active: true,
        },
      },
    };
  }

  createNewMeasurement(eventData) {
    const { onArrowAdd } = this.initialConfiguration;
    const goodEventData = eventData && eventData.currentPoints && eventData.currentPoints.image;
    if (!goodEventData) {
      return {};
    }

    if (onArrowAdd) onArrowAdd(eventData.image, eventData);
    return this.createEventData(eventData.currentPoints.image);
  }

  pointNearTool(element, data, coords) {
    const hasEndHandle = data && data.handles && data.handles.end;
    const validParameters = hasEndHandle;
    if (!validParameters || data.visible === false) {
      return false;
    }
    const probeCoords = cornerstone.pixelToCanvas(element, data.handles.end);
    return cornerstoneMath.point.distance(probeCoords, coords) < 30;
  }

  updateCachedStats(image, element, data) {
    const { onArrowMove } = this.initialConfiguration;
    clearTimeout(this.updateEvent);

    this.updateEvent = setTimeout(() => {
      if (onArrowMove) onArrowMove(image, data);
    }, 500);
  }

  createInitialData(handles) {
    const data = [];
    handles.forEach(item => {
      data.push(this.createEventData(item));
    });
    return this.createEventData(handles[0]);
  }

  addedToolInitialData(target) {
    const { data } = this.initialConfiguration;
    if (this.addedInitialData || !data || !data.length) return;
    data.forEach(item => {
      addToolState(target, this.name, this.createEventData(item));
    });
    this.addedInitialData = true;
  }

  renderToolData(evt) {
    this.addedToolInitialData(evt.currentTarget);
    const eventData = evt.detail;
    const { handleRadius } = this.configuration;
    const toolData = getToolState(evt.currentTarget, this.name);

    if (!toolData) {
      return;
    }

    const context = getNewContext(eventData.canvasContext.canvas);

    for (let i = 0; i < toolData.data.length; i++) {
      const data = toolData.data[i];

      if (data.visible === false) {
        continue;
      }

      draw(context, context => {
        const color = toolColors.getColorIfActive(data);
        if (this.configuration.drawHandles) {
          drawHandles(context, eventData, data.handles, {
            handleRadius,
            color,
          });
        }
      });
    }
  }

  _onClickArrow(evt) {
    const { onArrowRemove } = this.initialConfiguration;
    const eventData = evt.detail;
    const { element, currentPoints } = eventData;

    const coords = currentPoints.canvas;
    const toolData = getToolState(element, this.name);

    // Now check to see if there is a handle we can move
    if (!toolData) {
      return;
    }

    for (let i = 0; i < toolData.data.length; i++) {
      const data = toolData.data[i];
      if (this.pointNearTool(element, data, coords)) {
        removeToolState(element, this.name, data);
        if (onArrowRemove) onArrowRemove(eventData.image, data);
        cornerstone.updateImage(element);

        evt.stopImmediatePropagation();
        evt.preventDefault();
        evt.stopPropagation();

        return;
      }
    }
  }
}
