import React, { useEffect, useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor';

const editorConfiguration = {
  removePlugins: ['ImageUpload'],
};

const EventAboutStep = ({ form, setForm }) => {
  const [editorData, setEditorData] = useState(form.about);

  useEffect(() => {
    setEditorData(form.about);
  }, [form.about]);

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setForm({ ...form, about: data });
    setEditorData(data);
  };

  return (
    <div className='px-2'>
      <h6 className='color-blue mb-3'>About Event*</h6>
      <div className='email-editor col-8 p-0'>
        <CKEditor
          editor={ClassicEditor}
          data={form.about}
          config={editorConfiguration}
          onChange={handleEditorChange}
        />
        {!!form.showToLearner && (
          <input
            type='text'
            value={editorData}
            onChange={() => {}}
            required
            className='hidden_visual top_right'
          />
        )}
      </div>
    </div>
  );
};

export default EventAboutStep;
