import React from 'react';

const PublishRow = ({ label, data, onClick }) => {
  return (
    <p
      className={`d-flex justify-content-between mb-2 ${
        onClick ? 'link-color pointer text-underline' : ''
      }`}
      onClick={onClick}
    >
      <span>{label}</span>
      <span>{data}</span>
    </p>
  );
};

export default PublishRow;
