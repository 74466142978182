import React from 'react';
import ReactDOM from 'react-dom';
import Button from 'shared/components/Button';
import { IconClose, IconInfoOutlined } from './Icons';

const defaultText =
  'Lorem ipsum dolor sit amet consectetur. Risus lacus ut sagittis porttitor purus elementum ac ullamcorper. Lectus eget ultrices elementum eget.';

const ClosePreview = ({ text = defaultText, onClose }) => {
  const portalRoot = document.getElementById('portal-root');

  return (
    portalRoot &&
    ReactDOM.createPortal(
      <div className='close-preview'>
        <IconInfoOutlined />
        <p className='desc'>{text}</p>
        <Button className='btn-purple-filled btn-close' onClick={onClose}>
          <IconClose width={12} height={12} color='#fff' /> Close Preview
        </Button>
      </div>,
      portalRoot,
    )
  );
};

export default ClosePreview;
