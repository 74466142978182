import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { reportTypes } from 'configs';

const Header = () => {
  const match = useRouteMatch();
  const { type } = match.params;
  const isEdit = !!match.params.id;
  const activeItem = reportTypes.find(i => i.value === type);

  return (
    <div className='users-header d-flex justify-content-between align-items-center'>
      <div className='d-flex breadcrumbs'>
        <Link to={`/reports/` + type}>{activeItem.name}</Link>
        <span className='mx-2'>/</span>
        <span>{isEdit ? 'Edit Report' : 'Create New Report'}</span>
      </div>
    </div>
  );
};

export default Header;
