import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { confirmAlert } from 'react-confirm-alert';

import { Api } from 'utils/connectors';
import { formatDate, getError, getLicensesDefaultRange } from 'utils/appHelpers';
import { hasAccess } from 'utils/permissionHelper';

import { BUTTON_SIZES, BUTTON_TYPES, WARNING_DIALOGUE_DETAILS } from 'shared/constants';

import Loading from 'shared/components/Loading';
import { IconEdit } from 'shared/components/Icons';
import EditingSelect from 'shared/components/editingForms/EditingSelect';
import ValidityPeriod from '../Licenses/components/ValidityPeriod';
import { SwitchIOSLike } from 'shared/components/SwitchIOSLike';
import SmallCounter from 'shared/components/SmallCounter';
import Button from 'shared/components/Button';

const defaultRange = getLicensesDefaultRange();

const LicensesView = ({ match, history, location }) => {
  const { enqueueSnackbar } = useSnackbar();

  const { id } = match.params;
  const licenseId = location.state?.editId;
  const [fetch, setFetch] = useState(false);
  const [updateFetch, setUpdateFetch] = useState(false);
  const [editing, setEditing] = useState(!!licenseId);
  const [license, setLicense] = useState({ userIdList: [], assignedUsers: [] });
  const [entities, setEntities] = useState([]);
  const [users, setUsers] = useState([]);
  const [isValidPeriodEnabled, setIsValidPeriodEnabled] = useState(false);
  const [isEnabledNumOfSits, setIsEnabledNumOfSits] = useState(false);
  const [numberOfSits, setNumberOfSits] = useState(null);
  const [tempKeptUsers, setTempKeptUsers] = useState([]);
  const [assignedEntity, setAssignedEntity] = useState(null);
  const [assigningUsersCount, setAssigningUsersCount] = useState(license?.assigned?.length);
  const [numberOfSitsArr, setNumberOfSitsArr] = useState(
    new Array(isEnabledNumOfSits ? numberOfSits : assigningUsersCount).fill(0),
  );
  const [initialData, setInitialData] = useState(null);

  const hasEntityAccess = hasAccess('entity_list_view');

  const clearLicenseIdFromLocState = () => {
    history.replace({
      ...history.location,
      state: {
        ...(location.state || {}),
        editId: undefined,
      },
    });
  };

  const getData = async (id, initialData) => {
    try {
      setFetch(true);
      let data;
      if (!initialData) {
        const resData = await Api.get(`/entity/license/${id}`);
        data = resData.data;
        setInitialData(data);
      } else {
        data = initialData;
      }
      const body = data.data;
      body.user = body?.assigned ? body?.assigned.id : '';
      body.entityName = body.entity ? body.entity?.name || body.entityName : '';
      body.entity = body.entity ? body.entity?.id || body.entity : '';
      body.userIdList =
        data?.userIdList || (body?.assigned?.length && body?.assigned?.map(user => user?.id)) || [];
      body.assignedUsers = [...body?.assigned];
      setAssignedEntity(body?.entity?.id || body?.entity || null);
      setLicense(body || {});
      setNumberOfSits(body?.numOfSits);
      setNumberOfSitsArr(new Array(body?.numOfSits || body?.assigned?.length || 1).fill(0));
      setIsEnabledNumOfSits(!!body?.numOfSits);
      setAssigningUsersCount(body?.numOfSits ? body?.numOfSits : body?.assigned?.length);
      setFetch(false);
      if (licenseId) {
        setIsEnabledNumOfSits(body?.numOfSits > 1);
        setIsValidPeriodEnabled(!!body?.startDate && !!body?.endDate);
      }
    } catch (err) {
      history.push(`/licenses/all`);
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const getUsers = async isNoEntity => {
    try {
      const entityId = assignedEntity || '';
      const users = await Api.get(`/entity/assignees/${!isNoEntity ? entityId : ''}`, {
        params: {
          limit: 1000,
        },
      });
      setUsers(users.data.data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const getSelectDatas = async () => {
    try {
      const params = { page: 1, limit: 1000 };
      const entities = await Api.get(`/entity/entities`, { params });
      let resEntities = entities?.data?.data ? [...entities.data.data.results] : [];
      setEntities(resEntities);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const handleChange = (reset, { target }) => {
    const temp = { ...license };
    if (reset) temp.user = '';
    const { name, value } = target;
    const entityNotAssignedYet = name === 'entity';
    setLicense({ ...temp, [name]: value, entityNotAssignedYet });
  };

  const handleChangeAssignedUsers = ({ value }, idx) => {
    const currentUser = users.find(user => user.id === Number(value));
    let res = license.userIdList;
    let resAssignedUsers = license.assignedUsers;
    res[idx] = Number(value);
    resAssignedUsers[idx] = currentUser;
    setLicense({ ...license, userIdList: res, assignedUsers: resAssignedUsers });
  };

  const handleClickEdit = () => {
    setEditing(!editing);
    setIsEnabledNumOfSits(license?.numOfSits > 1);
    setIsValidPeriodEnabled(!!license?.startDate && !!license?.endDate);
  };

  const handleClickCancel = () => {
    getData(id, initialData);
    setEditing(false);
    setTempKeptUsers([]);
    clearLicenseIdFromLocState();
  };

  const handleOpenWarningDialogue = ({ name }) => {
    const label = WARNING_DIALOGUE_DETAILS[name].key;
    const firstWord = label.split(/[A-Z]/)[0];
    confirmAlert({
      overlayClassName: 'with-icon',
      title: 'Confirmation Needed',
      message: WARNING_DIALOGUE_DETAILS[name].description,
      buttons: [
        {
          label: 'Cancel',
        },
        {
          className: `btn-${WARNING_DIALOGUE_DETAILS[name].key} btn-disable`,
          label: firstWord,
          onClick: () => handleConfirmDialogue(WARNING_DIALOGUE_DETAILS[name].key),
        },
      ],
    });
  };

  const handleDecreaseNumOfSits = () => {
    if (license?.assigned?.length === numberOfSits) {
      handleOpenWarningDialogue({ name: WARNING_DIALOGUE_DETAILS.decrease.key });
    } else {
      const tempAddingUser = license?.assignedUsers?.[license?.assignedUsers?.length - 1];
      if (tempAddingUser) {
        if (numberOfSits <= license.userIdList?.length) {
          setTempKeptUsers(prev => [tempAddingUser, ...prev]);
          setLicense(prev => ({
            ...prev,
            userIdList: license?.userIdList?.slice(0, license?.userIdList?.length - 1),
            assignedUsers: license?.assignedUsers?.slice(0, license?.assignedUsers?.length - 1),
          }));
        }
      }
      setNumberOfSits(prev => prev - 1);
      setNumberOfSitsArr(prev => prev.slice(1));
    }
  };

  const handleIncreaseNumOfSits = () => {
    if (tempKeptUsers?.length) {
      const addingUser = tempKeptUsers?.[0];
      setLicense(prev => ({
        ...prev,
        userIdList: [...prev.userIdList, addingUser?.id],
        assignedUsers: [...prev.assignedUsers, addingUser],
      }));
      setTempKeptUsers(prev => prev.slice(1));
    }
    setNumberOfSits(prev => prev + 1);
    setNumberOfSitsArr(prev => [...prev, 0]);
  };

  const handleSetPeriod = (timeRange = null) => {
    setLicense(prev => ({ ...prev, dateRange: timeRange, numOfSits: null }));
  };

  const handleAddAssignment = () => {
    setAssigningUsersCount(prev => prev + 1);
    setNumberOfSitsArr(prev => [...prev, 0]);
  };

  const handleConfirmDialogue = async key => {
    try {
      if (
        key === WARNING_DIALOGUE_DETAILS.assign.key ||
        key === WARNING_DIALOGUE_DETAILS.unassign.key
      ) {
        await Api.post(`/entity/license/${license?.id}/${key}/${license?.entity}`);
        await getData(license?.id);
        setAssignedEntity(license?.entity);
        await getUsers(key === WARNING_DIALOGUE_DETAILS.unassign.key);
      }
      if (key === WARNING_DIALOGUE_DETAILS.disable.key) {
        setNumberOfSits(null);
        setIsEnabledNumOfSits(false);
        setLicense(prev => ({
          ...prev,
          userIdList: [license?.userIdList[0]],
          assignedUsers: [license?.assigned?.[0]],
        }));
        setTempKeptUsers(license?.assigned?.slice(1));
      }
      if (key === WARNING_DIALOGUE_DETAILS.decrease.key) {
        setLicense(prev => ({
          ...prev,
          userIdList: license?.userIdList.slice(0, license?.userIdList?.length - 1),
          assignedUsers: license?.assigned?.slice(0, license?.assigned?.length - 1),
        }));
        const tempAddingUser = license?.assigned?.[license?.assigned?.length - 1];
        setTempKeptUsers(prev => [...prev, tempAddingUser]);
        setNumberOfSits(prev => prev - 1);
        setNumberOfSitsArr(numberOfSitsArr.slice(1));
      }
      if (key === WARNING_DIALOGUE_DETAILS.disableValidPeriod.key) {
        setIsValidPeriodEnabled(false);
        setTempKeptUsers(license?.assignedUsers.slice(1));
        setLicense(prev => ({
          ...prev,
          userIdList: [license?.userIdList?.[0]],
          assignedUsers: [license?.assignedUsers?.[0]],
        }));
        setNumberOfSits(1);
        setNumberOfSitsArr(numberOfSitsArr.slice(0, 1));
      }
      if (key === WARNING_DIALOGUE_DETAILS.disableNumberOfSits.key) {
        setIsValidPeriodEnabled(false);
        setIsEnabledNumOfSits(false);
        setTempKeptUsers(license?.assignedUsers.slice(1));
        setLicense(prev => ({
          ...prev,
          userIdList: [license?.userIdList?.[0]],
          assignedUsers: [license?.assignedUsers?.[0]],
        }));
        setNumberOfSits(1);
        setNumberOfSitsArr(numberOfSitsArr.slice(0, 1));
      }
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const handleSwitcher = ({ isValidPeriod }) => {
    const isSetValidityPeriod = license?.dateRange || (license?.startDate && license?.endDate);
    const isAssignedMoreThanOne = license?.assigned?.length > 1;
    if (isValidPeriod) {
      if (!isEnabledNumOfSits && isValidPeriodEnabled && isAssignedMoreThanOne) {
        handleOpenWarningDialogue({ name: WARNING_DIALOGUE_DETAILS.disableValidPeriod.key });
      } else {
        setIsValidPeriodEnabled(prev => !prev);
        if (!isSetValidityPeriod) {
          handleSetPeriod(defaultRange);
        }
      }
    } else {
      if (isAssignedMoreThanOne) {
        if (isValidPeriodEnabled) {
          setIsEnabledNumOfSits(prev => !prev);
          setNumberOfSits(license?.assignedUsers?.length || license?.assigned?.length || 1);
        } else {
          if (isEnabledNumOfSits) {
            handleOpenWarningDialogue({ name: WARNING_DIALOGUE_DETAILS.disableNumberOfSits.key });
          } else {
            setIsEnabledNumOfSits(prev => !prev);
            setNumberOfSits(license?.assignedUsers?.length || license?.assigned?.length || 1);
          }
        }
      } else {
        setIsEnabledNumOfSits(prev => !prev);
        if (isEnabledNumOfSits && !isValidPeriodEnabled) {
          setNumberOfSits(1);
          setNumberOfSitsArr(numberOfSitsArr.slice(0, 1));
        }
        if (!isEnabledNumOfSits) {
          setNumberOfSits(prev => prev || 1);
        }
      }
      return;
    }
  };

  const handleSwitchNumOfSits = () => {
    handleSwitcher({ isValidPeriod: false });
  };

  const handleSwitchValidPeriod = () => {
    handleSwitcher({ isValidPeriod: true });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (
      isValidPeriodEnabled &&
      !license?.dateRange?.start &&
      !license?.startDate &&
      !license?.dateRange?.start &&
      !license?.startDate
    ) {
      enqueueSnackbar('Valid period is not specified', { variant: 'error' });
      return;
    }
    try {
      setUpdateFetch(true);
      await Api.post(`/entity/licensekey/assign`, {
        entityId: license.entity && !license.entityNotAssignedYet ? Number(license.entity) : null,
        originId: license?.id,
        startDate: isValidPeriodEnabled
          ? license?.dateRange?.start || license?.startDate || null
          : null,
        endDate: isValidPeriodEnabled ? license?.dateRange?.end || license?.endDate || null : null,
        numOfSits: isEnabledNumOfSits ? numberOfSits : isValidPeriodEnabled ? null : 1,
        userIdList: license?.userIdList?.filter(id => !!id),
        userId: license.user ? Number(license.user) : undefined,
      });
      enqueueSnackbar('Successfully updated', { variant: 'success' });
      getData(id);
      setEditing(false);
      setTempKeptUsers([]);
      clearLicenseIdFromLocState();
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setUpdateFetch(false);
    }
  };

  useEffect(() => {
    getData(id);
    //eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (hasEntityAccess) getSelectDatas();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    getUsers();
    //eslint-disable-next-line
  }, [assignedEntity]);

  if (fetch || !license) return <Loading className='w-100 mt-5' />;

  return (
    <div>
      <div className='users-header d-flex justify-content-between align-items-center'>
        <div className='d-flex breadcrumbs'>
          <Link to='/licenses/all'>License Keys</Link>
          <span className='mx-2'>/</span>
          <span>View Key</span>
        </div>
      </div>
      <div className='col-12 py-3 has-header'>
        <form onSubmit={handleSubmit} className='col-9 p-0 user-creation-form'>
          <ul className='p-0 mb-0'>
            <li className='d-flex align-items-center'>
              <div className='col-4 pl-0'>
                <label className='m-0'>License Key:</label>
              </div>
              <span className='result'>{license.licenseKey}</span>
            </li>
            <li className='d-flex align-items-center'>
              <div className='col-4 pl-0'>
                <label className='m-0'>Subscription Plan:</label>
              </div>
              <span className='result'>
                {license.subscription ? license.subscription.title : '-'}
              </span>
            </li>
            <li className='d-flex align-items-center'>
              <div className='col-4 pl-0'>
                <label className='m-0'>Date Added:</label>
              </div>
              <span className='result'>{formatDate(license.addedAt) || '-'}</span>
            </li>
            <li className='d-flex align-items-center'>
              <div className='col-4 pl-0'>
                <label className='m-0'>Assigned Entity:</label>
              </div>
              {editing ? (
                license?.entity && !license?.entityNotAssignedYet ? (
                  <div className='assigned-entity-edit-container'>
                    <span className='result'>{license?.entityName}</span>
                    <Button
                      name='unassign'
                      size={BUTTON_SIZES.medium2}
                      buttonType={BUTTON_TYPES.delete_1}
                      className={'btn-danger no-outline center-aligned'}
                      onClick={({ target }) => handleOpenWarningDialogue(target)}
                    >
                      Unassign{' '}
                    </Button>
                  </div>
                ) : (
                  <div className='assigned-entity-edit-container'>
                    <EditingSelect
                      name='entity'
                      onChange={handleChange.bind(null, true)}
                      value={license.entity || ''}
                      forceValue={!hasEntityAccess ? license.entityName : false}
                      items={entities}
                      useProperty='id'
                      displayProperty='name'
                      editing={editing && hasEntityAccess}
                    />
                    <Button
                      name='assign'
                      size={BUTTON_SIZES.medium2}
                      buttonType={license.entity ? BUTTON_TYPES.primary : BUTTON_TYPES.disabled}
                      className='no-outline center-aligned'
                      disabled={!license?.entity}
                      onClick={({ target }) => handleOpenWarningDialogue(target)}
                    >
                      Assign{' '}
                    </Button>
                  </div>
                )
              ) : (
                <span className='result'>{license.entity ? license.entityName : '-'}</span>
              )}
            </li>
            <li className='d-flex align-items-center'>
              <ValidityPeriod
                license={license}
                setLicense={setLicense}
                handleSwitchExternal={handleSwitchValidPeriod}
                handleSetPeriod={handleSetPeriod}
                isValidPeriodEnabled={isValidPeriodEnabled}
                setIsValidPeriodEnabled={setIsValidPeriodEnabled}
                editing={editing}
              />
            </li>
            <li className='d-flex align-items-center'>
              <>
                <div className='col-4 pl-0'>
                  <label className='m-0'>Number of Assignments:</label>
                </div>
                {editing ? (
                  <>
                    <div className='switch_container'>
                      <SwitchIOSLike
                        checkedState={isEnabledNumOfSits}
                        handleChange={handleSwitchNumOfSits}
                      />
                    </div>
                    {isEnabledNumOfSits ? (
                      <SmallCounter
                        disabled={!isEnabledNumOfSits}
                        count={numberOfSits}
                        setCount={setNumberOfSits}
                        handleDecreaseCount={handleDecreaseNumOfSits}
                        handleIncreaseCount={handleIncreaseNumOfSits}
                      />
                    ) : (
                      <span className='result'>
                        {isValidPeriodEnabled && !isEnabledNumOfSits ? 'No Limitation' : '1'}
                      </span>
                    )}
                  </>
                ) : (
                  <span className='result'>{license?.numOfSits || 'No Limitation'}</span>
                )}
              </>
            </li>
            {numberOfSitsArr.map((item, idx) => {
              const usersList = [...users].filter(user => {
                if (user.id === license?.assignedUsers?.[idx]?.id) return true;
                return (
                  !license?.userIdList?.includes(user?.id) ||
                  tempKeptUsers.some(({ id }) => id === user?.id)
                );
              });
              return (
                <li key={idx} className='d-flex align-items-center'>
                  <div className='col-4 pl-0'>
                    <label className='m-0'>{`Assigned User ${idx + 1}:`}</label>
                  </div>
                  <EditingSelect
                    name='user'
                    onChange={({ target }) => handleChangeAssignedUsers(target, idx)}
                    value={license?.assignedUsers?.[idx]?.id}
                    forceValue={license?.assignedUsers?.[idx]?.fullName}
                    items={usersList}
                    useProperty='id'
                    displayProperty='fullName'
                    label='Choose...'
                    editing={editing}
                    hideDefault={false}
                  />
                </li>
              );
            })}
          </ul>
          {editing && (!isEnabledNumOfSits && isValidPeriodEnabled) && (
            <Button
              onClick={handleAddAssignment}
              className='btn-blue mt-1 px-5 pt-2 pb-2 fz-12 font-weight-bold'
            >
              Add Assignment +
            </Button>
          )}
          {hasAccess('license_create') && (
            <div className='d-flex justify-content-end mt-2'>
              {!editing ? (
                <Button
                  className='btn-blue mt-1 px-4 fz-12 font-weight-bold'
                  onClick={handleClickEdit}
                >
                  <IconEdit className='mr-1' /> Edit
                </Button>
              ) : (
                <Button
                  className='btn-blue mt-1 px-4 fz-12 font-weight-bold'
                  onClick={handleClickCancel}
                >
                  Cancel
                </Button>
              )}
              {editing && (
                <Button
                  className='btn btn-primary ml-2 px-4 fz-12 font-weight-bold'
                  type='submit'
                  disabled={updateFetch}
                >
                  Save
                </Button>
              )}
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default LicensesView;
