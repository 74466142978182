import React from 'react';
import attachIcon from 'assets/attach.svg';
import ResponsiveImage from './ResponsiveImage';

const WizardImageUpload = ({
  onImageUpload = () => {},
  image,
  isRequired,
  title = 'Image',
  colSize = 'col-3',
}) => {
  return (
    <li className='d-flex align-items-center'>
      <div className={`${colSize} pl-0`}>
        <label className='m-0'>
          {title}
          {isRequired ? '*' : ''}:
        </label>
      </div>
      <div className='upload-file-form w-100 overflow-hidden'>
        <input
          id='file'
          name='file'
          type='file'
          className='flex-fill fixed-hidden-input'
          onChange={onImageUpload}
          required={isRequired && !image}
        />
        <label
          htmlFor='file'
          className={`d-flex justify-content-between align-items-center text-nowrap ${
            image ? 'bg-transparent pl-0' : ''
          }`}
        >
          {image && <ResponsiveImage height='40' className='mr-2' imgUrl={image} alt='img' />}
          {!image && 'Upload Image'}
          <div>
            {image && 'Change Image'}
            <img src={attachIcon} className='ml-2' alt='icon' />
          </div>
        </label>
      </div>
    </li>
  );
};

export default WizardImageUpload;
