import React, { useState } from 'react';
import { ForwardUrlIcon, IconCopyWhite } from './Icons';
import { GlobalTooltip } from 'app/Main/components/Shared/GlobalTooltip';
import { useSnackbar } from 'notistack';
import { getError } from 'utils/appHelpers';

const VirtualCopyRow = ({ title, url }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const copyHtmlToClipboardFallback = html => {
    const tempElement = document.createElement('textarea');
    tempElement.value = html;
    document.body.appendChild(tempElement);
    tempElement.select();
    document.execCommand('copy');
    document.body.removeChild(tempElement);
  };

  const copyHtmlToClipboard = async html => {
    if (!navigator.clipboard) {
      copyHtmlToClipboardFallback(html);
      setTooltipOpen(true);
      return;
    }

    try {
      const item = new ClipboardItem({
        'text/html': new Blob([html], { type: 'text/html' }),
        'text/plain': new Blob([url], { type: 'text/plain' }),
      });
      await navigator.clipboard.write([item]);
      setTooltipOpen(true);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const htmlToCopy = `<a href="${url}">${title}</a>`;

  return (
    <>
      <GlobalTooltip
        open={tooltipOpen}
        handleClose={() => setTooltipOpen(false)}
        message='Link copied!'
      />
      <div className='virtual-copy-row'>
        <a href={url} className='text-elips copy-text'>
          {url}
        </a>
        <div className='action-buttons actions-block'>
          <button
            onClick={() => copyHtmlToClipboard(htmlToCopy)}
            className='btn action-item p-0 m-0'
          >
            <IconCopyWhite />
          </button>
          <button onClick={() => window.open(url, '_blank')} className='btn action-item p-0 m-0'>
            <ForwardUrlIcon />
          </button>
        </div>
      </div>
    </>
  );
};

export default VirtualCopyRow;
