import React from 'react';
import { useDroppable } from '@dnd-kit/core';
import { IconSquareEditBolded, RemoveRedIcon } from 'shared/components/Icons';
import Button from 'shared/components/Button';
import { RegularInput } from 'shared/components/Input/RegularInput';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

export const SortableColumn = ({
  children,
  id,
  overColumnId,
  column,
  handleClickNameEdit,
  handleChangeColumnName,
  handleSubmitName,
  questionIdx,
  columnIndex,
  handleRemoveColumn,
}) => {
  const { setNodeRef: setFirstDroppableRef } = useDroppable({
    id,
  });
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  const isColumnOvered = overColumnId === id || column.items.some(item => item.id === overColumnId);

  return (
    <div
      style={style}
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      className={`category_column ${isColumnOvered && 'overed'}`}
    >
      <div className='header'>
        {!column.isEditing && (
          <Button className='btn btn_transparent' onClick={() => handleClickNameEdit(column.id)}>
            <IconSquareEditBolded width='16' height='16' />
          </Button>
        )}
        {!column.isEditing && <h6 className='title'>{column.name}</h6>}
        {column.isEditing && (
          <RegularInput
            autoFocus
            inputId={column.id}
            inputClassName='small'
            handleChange={({ target }) => handleChangeColumnName(target, column.id)}
            onEnter={() => handleSubmitName(column.id)}
            name='name'
            placeholder='Type and press Enter'
            value={column.name}
          />
        )}
        <Button
          className='btn btn-remove-small focus_no_shadowed ml-auto not_visible'
          onClick={() => handleRemoveColumn(questionIdx, columnIndex)}
        >
          <RemoveRedIcon width='16' height='16' />
        </Button>
      </div>
      <div ref={setFirstDroppableRef} className='body'>
        {children}
      </div>
    </div>
  );
};
