/* eslint-disable radix */

export const createObjectFromUrlParams = str => {
  const obj = {};
  const queryParams = str.slice(1).split(',');
  queryParams.forEach(param => {
    const [key, value] = param.split('=');
    obj[key] = isNaN(value) ? value : parseInt(value);
  });
  return obj;
};
