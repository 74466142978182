import React from 'react';
import { Link } from 'react-router-dom';
import { hasAccess } from 'utils/permissionHelper';
import { IconSearch } from 'shared/components/Icons';
import BackButton from 'shared/components/BackButton';

const LicensesHeader = ({ onSearch, search, entityId, history }) => {
  return (
    <div className='users-header d-flex justify-content-between align-items-center'>
      <div className='d-flex'>
        {entityId && (
          <BackButton className='pt-2' history={history} route={`/entity/${entityId}`} />
        )}
        {hasAccess('license_create') && (
          <Link className='btn btn-invite' to={`/licenses/new/${entityId || ''}`}>
            + Add New License
          </Link>
        )}
      </div>
      <div className='d-flex'>
        <div className='search-block'>
          <input
            value={search}
            type='text'
            onChange={({ target }) => onSearch(target.value)}
            className='form-control'
            placeholder=''
          />
          <IconSearch />
        </div>
      </div>
    </div>
  );
};

export default LicensesHeader;
