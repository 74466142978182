import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

const alertRoot = document.getElementById('alert-root');

const AlertMessageComponent = ({
  message = 'Something went wrong',
  duration = 3000,
  type = 'error',
  onClose,
}) => {
  const [visible, setVisible] = useState(false);
  const [animateOut, setAnimateOut] = useState(false);

  useEffect(() => {
    setTimeout(() => setVisible(true), 10);

    const animateOutTimer = setTimeout(() => {
      setAnimateOut(true);
    }, duration - 500);

    const closeTimer = setTimeout(() => {
      onClose();
    }, duration);

    return () => {
      clearTimeout(animateOutTimer);
      clearTimeout(closeTimer);
    };
  }, [duration, onClose]);

  return (
    <div
      className={`alert-message ${type} ${visible ? 'animate-in' : ''} ${
        animateOut ? 'animate-out' : ''
      }`}
    >
      {message}
    </div>
  );
};

export const showAlertMessage = ({ message, duration = 3000, type = 'success' }) => {
  const div = document.createElement('div');
  alertRoot.appendChild(div);

  const handleClose = () => {
    ReactDOM.unmountComponentAtNode(div);
    div.remove();
  };

  ReactDOM.render(
    <AlertMessageComponent
      message={message}
      duration={duration}
      type={type}
      onClose={handleClose}
    />,
    div,
  );
};
