import { QUESTION_TYPES_OBJ } from './constants';

export const validateGroupsConfiguration = groups => {
  let res = {};
  const pushQuestionError = (gIdx, message) => {
    res[gIdx] = {
      ...res[gIdx],
      questions: [
        ...(res[gIdx]?.questions || []),
        {
          errMessage: message,
        },
      ],
    };
  };

  groups.forEach((group, gIdx) => {
    if (!group.name) {
      const name = `The name of the group Number: ${gIdx + 1} is not provided.`;
      res[gIdx] = res[gIdx] ? { ...res[gIdx], name } : { name };
    }

    const questionNameInValidation = group.name ? group.name : `Number ${gIdx + 1}`;

    if (group.questions && Array.isArray(group.questions)) {
      group.questions.forEach((q, qIdx) => {
        if (!q.title) {
          pushQuestionError(
            gIdx,
            `The description of the question Number: ${qIdx +
              1} in the group  "${questionNameInValidation}" is not provided.`,
          );
        } else if (q.type === QUESTION_TYPES_OBJ.radio || q.type === QUESTION_TYPES_OBJ.multi) {
          if (q.answers.some(a => !a.answer)) {
            pushQuestionError(
              gIdx,
              `There is empty answer field in the question Number: ${qIdx +
                1} in the group "${questionNameInValidation}". Please fill them or remove.`,
            );
          } else if (!q.answers.some(a => a.isRightAnswer)) {
            pushQuestionError(
              gIdx,
              `There is no any right answer selected in the question Number: ${qIdx +
                1} in the group "${questionNameInValidation}". Please select at least one.`,
            );
          }
        } else if (q.type === QUESTION_TYPES_OBJ.yesno) {
          if (!q.yesno) {
            pushQuestionError(
              gIdx,
              `There is not selected the correct answer in the question Number: ${qIdx +
                1} in the group Number: "${group.name || `Number ${gIdx + 1}`}".`,
            );
          }
        } else if (q.type === QUESTION_TYPES_OBJ['image-category']) {
          if (!q.imageColumns.length) {
            pushQuestionError(
              gIdx,
              `There is no any image column in the question Number: ${qIdx +
                1}, the group Number: "${questionNameInValidation}". Please provide them or remove the question.`,
            );
          } else if (q.imageColumns.some(col => !col.items?.length)) {
            pushQuestionError(
              gIdx,
              `There is an image column which has no any image provided in the question Number: ${qIdx +
                1}, the group Number: "${questionNameInValidation}". Please provide the name or remove.`,
            );
          } else if (q.imageColumns.some(col => col.items?.some(imgItem => !imgItem.description))) {
            pushQuestionError(
              gIdx,
              `There is an image which has no description provided in the question Number: ${qIdx +
                1}, the group Number: "${questionNameInValidation}". Please provide the name or remove.`,
            );
          } else if (q.imageColumns.some(col => !col.name)) {
            pushQuestionError(
              gIdx,
              `There is an image column which name is not provided in the question Number: ${qIdx +
                1}, the group Number: "${questionNameInValidation}". Please provide the name or remove.`,
            );
          }
        }
      });
    }
  });
  return res;
};
