import React, { useEffect, useState } from 'react';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ShapesGroupIcon, VerticalGripIcon } from 'shared/components/Icons';
import { SHAPE_ICONS } from '../constants';
import { useSnackbar } from 'notistack';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';

const AssociationItem = ({ association, details, index, id, isDragging, isActive }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: isDragging ? transition : 'none',
  };

  return (
    <div style={style} className={`association-item ${isActive && 'active'}`}>
      <div ref={setNodeRef} {...listeners} {...attributes} className='drag-icon-block'>
        <VerticalGripIcon color={isActive ? '#000' : undefined} />
      </div>
      <div className='content-block'>
        <div className='dcm-name'>
          DICOM: <span className='fw-600'>{association.fileName}</span>
        </div>
        <div className='row-1'>
          <span>
            Image View: <span className='fw-600'>{association.imageView}</span>
          </span>
          <span>
            Image Type: <span className='fw-600'>{association.imageType}</span>
          </span>
        </div>
        <div className='row-2'>
          <span>
            Slice N:{' '}
            <span className='fw-600'>
              {association.currentSlice}/{association.totalSlices}
            </span>
          </span>
          <span>
            Prediction: <span className='fw-600'>{association.percentage}%</span>
          </span>
        </div>
      </div>
    </div>
  );
};

const AssociationsBlock = ({ associations, selectedPoint, details }) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates }),
  );
  const { enqueueSnackbar } = useSnackbar();

  const [items, setItems] = useState(associations.map(asocc => ({ ...asocc, id: asocc.gaidId })));
  const [draggingItemId, setDraggingItemId] = useState(null);

  const handleDragStart = event => {
    const { active } = event;
    setDraggingItemId(active.id);
  };

  const handleDragEnd = event => {
    const { active, over } = event;
    let sortedItems = items;

    if (active?.id && over?.id && active.id !== over.id) {
      setItems(items => {
        const oldIndex = items.findIndex(item => item.id === active.id);
        const newIndex = items.findIndex(item => item.id === over.id);
        sortedItems = arrayMove(items, oldIndex, newIndex);

        return sortedItems;
      });
      try {
        Api.put('/dicom/geniusData/reorder', {
          gaids: sortedItems.map(({ gaidId }) => gaidId),
        });
      } catch (err) {
        enqueueSnackbar(getError(err), { variant: 'error' });
      }
    }
    setDraggingItemId(null);
  };

  useEffect(() => {
    setItems(associations.map(asocc => ({ ...asocc, id: asocc.gaidId })));
  }, [associations]);

  if (!items.length) {
    return (
      <div className='associations-dialog empty'>
        <div className='empty-content'>
          <ShapesGroupIcon />
          <div className='empty-associations'>Select marking to see results</div>
        </div>
      </div>
    );
  }

  return (
    <div className='associations-dialog'>
      {!!details && (
        <div className='title-row'>
          <div className='details-row'>
            <img alt='shape' src={SHAPE_ICONS[details.shape]} />
            <span>
              Designator: <span className='designator'>{details.designator}</span>
            </span>
          </div>
          <div className='case-name'>Case: {details.case.title}</div>
        </div>
      )}
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={items} strategy={verticalListSortingStrategy}>
          <div className='associations-container'>
            {items.map((association, index) => (
              <AssociationItem
                key={index}
                id={association.id}
                isActive={selectedPoint?.handles?.end?.id === association?.id}
                isDragging={!!draggingItemId}
                index={index}
                association={association}
              />
            ))}
          </div>
        </SortableContext>
      </DndContext>
    </div>
  );
};

export default AssociationsBlock;
