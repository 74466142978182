import React from 'react';
import { formatDate } from 'utils/appHelpers';
import { IconGenuine, IconVideos } from 'shared/components/Icons';
import { Link } from 'react-router-dom';

const headers = [
  {
    name: 'Image View',
    className: 'col',
  },
  {
    name: 'Image Type',
    className: 'col',
  },
  {
    name: 'DICOM Name',
    className: 'col-5',
  },
  {
    name: 'Uploaded Date',
    className: 'col-4',
  },
  {
    name: '',
    className: 'col mw_60',
    key: '',
  },
];

const DicomList = ({ hasBIRADS, sections, dicoms, onGetCaseData, scanId }) => {
  // const { enqueueSnackbar } = useSnackbar();
  // const [removeFetch, setRemoveFetch] = useState(false);
  // const [exportFetch, setExportFetch] = useState(false);

  if (!dicoms || !dicoms.length) return null;

  // const onItemRemove = async item => {
  //   try {
  //     setRemoveFetch(true);
  //     await Api.delete(`/cases/dicom/${item.id}`);
  //     await onGetCaseData();
  //     enqueueSnackbar('Successfully removed', { variant: 'success' });
  //   } catch (err) {
  //     enqueueSnackbar(getError(err), { variant: 'error' });
  //   } finally {
  //     setRemoveFetch(false);
  //   }
  // };

  // const onItemRemoveConfirm = item => {
  //   confirmAlert({
  //     title: 'DICOM Removal',
  //     message: 'Are you sure you want to remove the DICOM file',
  //     buttons: [
  //       {
  //         label: 'CANCEL',
  //       },
  //       {
  //         label: 'REMOVE',
  //         onClick: () => onItemRemove(item),
  //       },
  //     ],
  //   });
  // };

  // const onItemExport = async item => {
  //   try {
  //     setExportFetch(true);
  //     const { data } = await Api.get(`/dicom/export?file=${item.fileName}`, {
  //       responseType: 'blob',
  //       timeout: 30000,
  //     });
  //     await saveFile(data, item.fileName);
  //     enqueueSnackbar('Successfully removed', { variant: 'success' });
  //   } catch (err) {
  //     enqueueSnackbar('No Such File', { variant: 'error' });
  //   } finally {
  //     setExportFetch(false);
  //   }
  // };

  return (
    <div>
      <h5 className='p-3 color-blue mb-0'>Related Dicoms</h5>
      <div className='users-lists col-12'>
        <ul className='p-0'>
          <li className='d-flex list-header align-items-center'>
            {headers.map((item, index) => (
              <div
                key={item.name + index}
                className={`col d-flex text-body justify-content-between align-items-center ${
                  item.className
                }`}
              >
                {item.name}
              </div>
            ))}
          </li>
          {dicoms &&
            dicoms.map((item, i) => {
              return (
                <li key={i} className='d-flex list-item h-not-set'>
                  <div className='d-flex col align-items-center'>
                    {item.imageLaterality}
                    {item.viewPosition}
                  </div>
                  <div className='d-flex col align-items-center justify-content-between'>
                    {item.imageType || '-'}
                    <span>
                      {item.numberOfSlices}
                      <IconVideos color='#131F6B' className='ml-1' />
                    </span>
                  </div>
                  <div className='d-flex col-5 align-items-center'>
                    <div className='text-word-break'>{item.fileName || '-'}</div>
                  </div>
                  <div className='d-flex col-4 align-items-center'>
                    {formatDate(item.uploadedAt) || '-'}
                  </div>
                  <div className='col d-flex align-items-center justify-content-center p-2 w-100 action-buttons mw_60'>
                    <Link
                      state={(hasBIRADS, sections)}
                      to={{
                        pathname: `/case/${scanId}/genius/${item.id}`,
                        state: { hasBIRADS, sections },
                      }}
                      className='btn action-item'
                    >
                      <IconGenuine />
                    </Link>
                  </div>
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};

export default DicomList;
