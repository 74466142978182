import ReactDOM from 'react-dom';
import React, { useEffect } from 'react';
import { IconButton, Snackbar, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  snackbar: {
    zIndex: 99999,
  },
}));

export const GlobalTooltip = ({ message = 'Copied', open, handleClose, duration = 3000 }) => {
  const classes = useStyles();

  useEffect(() => {
    let timer;
    if (open && duration) {
      timer = setTimeout(() => {
        handleClose();
      }, duration);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [open, duration, handleClose]);

  return ReactDOM.createPortal(
    <Snackbar
      classes={{
        root: classes.snackbar,
      }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={open}
      autoHideDuration={duration}
      onClose={handleClose}
      message={message}
      action={
        <IconButton size='small' aria-label='close' color='inherit' onClick={handleClose}>
          <CloseIcon fontSize='small' />
        </IconButton>
      }
    />,
    document.body,
  );
};
