import React from 'react';

const Header = () => {
  return (
    <div className='users-header d-flex justify-content-between align-items-center'>
      <div className='d-flex breadcrumbs'>System Emails</div>
    </div>
  );
};

export default Header;
