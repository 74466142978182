import { WithMouseFollowOrb, useMouseFollowOrb } from 'HOC/WithMouseFollowOrb';
import React from 'react';
import { IconForward } from 'shared/components/Icons';

const LinkTitleSection = () => {
  const canvasRef = useMouseFollowOrb();
  return (
    <div className='link-title-sec'>
      <canvas className='canvas-elm' ref={canvasRef} style={{ width: '100%', height: '100%' }} />
      <div className='content'>
        <h3>Product link title</h3>
        <p className='regular-text-sm'>
          Learn more about how Hologic is helping black and hispanic women receive the care they
          deserve.
        </p>
        <div>
          <IconForward />
        </div>
      </div>
    </div>
  );
};

export default WithMouseFollowOrb(LinkTitleSection);
