// import tool1Icon from 'assets/tools/1p.svg';
// import tool1IconActive from 'assets/tools/1a.svg';
// import tool2Icon from 'assets/tools/2p.svg';
// import tool2IconActive from 'assets/tools/2a.svg';
// import tool3Icon from 'assets/tools/3p.svg';
// import tool3IconActive from 'assets/tools/3a.svg';
import tool4Icon from 'assets/tools/4p.svg';
import tool4IconActive from 'assets/tools/4a.svg';
import tool5Icon from 'assets/tools/5p.svg';
import tool5IconActive from 'assets/tools/5a.svg';
import tool6Icon from 'assets/tools/6p.svg';
import tool6IconActive from 'assets/tools/6a.svg';
import tool7Icon from 'assets/tools/7p.svg';
import tool7IconActive from 'assets/tools/7a.svg';
import tool8Icon from 'assets/tools/8p.svg';
import tool8IconActive from 'assets/tools/8a.svg';
import tool9Icon from 'assets/tools/9p.svg';
import tool9IconActive from 'assets/tools/9a.svg';
import percentIcon from 'assets/tools/percentage.svg';
// import tool10Icon from 'assets/tools/10p.svg';
// import tool10IconActive from 'assets/tools/10p.svg';
import tool11Icon from 'assets/tools/11p.svg';
import tool11IconActive from 'assets/tools/11a.svg';
import tool12Icon from 'assets/tools/12p.svg';
import tool12IconActive from 'assets/tools/12a.svg';
import tool13Icon from 'assets/tools/13p.svg';
import tool13IconActive from 'assets/tools/13a.svg';
import tool14Icon from 'assets/tools/14p.svg';
import tool14IconActive from 'assets/tools/14a.svg';
import { IconGenuineCross, IconGenuineFlake, IconGenuineTriangle } from 'shared/components/Icons';

export const tools = [
  {
    name: 'BIRADS',
    title: 'Show BIRADS',
    icon: tool13Icon,
    iconActive: tool13IconActive,
    type: 'birads',
    cursor: 'default',
    onTimeAction: false,
  },
  {
    type: 'separator',
  },
  {
    name: 'Genius',
    title: 'Show/Hide Genius Tool',
    icon: tool11Icon,
    iconActive: tool11IconActive,
    type: 'genuine',
    cursor: 'genuine',
    height: 32,
    canStayActive: true,
    shape: 2,
    shapes: [
      { icon: IconGenuineTriangle, shape: 2 },
      { icon: IconGenuineCross, shape: 1 },
      { icon: IconGenuineFlake, shape: 3 },
    ],
  },
  {
    name: 'Remove Genius',
    title: 'Show/Hide Genius Tool',
    icon: tool12Icon,
    iconActive: tool12IconActive,
    type: 'RemoveGenius',
    cursor: 'RemoveGenius',
    height: 32,
    canStayActive: true,
    shape: 2,
    shapes: [
      { icon: IconGenuineTriangle, shape: 2 },
      { icon: IconGenuineCross, shape: 1 },
      { icon: IconGenuineFlake, shape: 3 },
    ],
  },
  {
    name: 'Designators',
    title: 'Show/Hide Designator List',
    icon: tool14Icon,
    iconActive: tool14IconActive,
    type: 'associations',
    cursor: 'associations',
    height: 32,
    canStayActive: true,
  },
  {
    name: 'PERCENT',
    title: 'Case Percantage',
    icon: percentIcon,
    iconActive: percentIcon,
    height: 14,
    type: 'percent',
    cursor: 'default',
    onTimeAction: false,
  },
  // {
  //   name: 'FT',
  //   title: 'Show/Hide Finding Tool',
  //   icon: tool1Icon,
  //   iconActive: tool1IconActive,
  //   type: 'findingToggle',
  //   cursor: 'default',
  //   height: 23,
  //   onTimeAction: true,
  //   canStayActive: true,
  //   noResetFindings: true,
  // },
  // {
  //   name: 'F',
  //   title: 'Activate Finding Tool',
  //   icon: tool2Icon,
  //   iconActive: tool2IconActive,
  //   type: 'finding',
  //   cursor: 'arrow',
  //   height: 23,
  //   onTimeAction: false,
  // },
  // {
  //   name: 'FR',
  //   title: 'Reset Finding Tool',
  //   icon: tool10Icon,
  //   iconActive: tool10IconActive,
  //   type: 'findingReset',
  //   cursor: 'default',
  //   height: 23,
  //   onTimeAction: true,
  // },
  {
    type: 'separator',
  },
  {
    name: 'R',
    title: 'Reset Viewers',
    icon: tool4Icon,
    iconActive: tool4IconActive,
    type: 'reset',
    cursor: 'default',
    onTimeAction: true,
  },
  {
    name: 'P',
    title: 'Activate Panning Tool',
    icon: tool5Icon,
    iconActive: tool5IconActive,
    type: 'pan',
    cursor: 'pan',
    onTimeAction: false,
  },
  {
    name: 'I Z',
    title: 'Activate Interactive Zoom',
    icon: tool6Icon,
    iconActive: tool6IconActive,
    type: 'zoomInteractive',
    cursor: 'izoom',
    onTimeAction: false,
  },
  {
    name: 'Z 1:1',
    title: 'Activate 1:1 Tool',
    icon: tool7Icon,
    iconActive: tool7IconActive,
    type: 'zoom1_1',
    cursor: 'fullzoom',
    onTimeAction: false,
  },
  {
    name: 'M',
    title: 'Activate Magnifier Tool',
    icon: tool8Icon,
    iconActive: tool8IconActive,
    type: 'zoomMagnifier',
    cursor: 'mzoom',
    onTimeAction: false,
  },
  {
    name: 'W/L',
    title:
      'Activate Window/Level Tool (W; for presets move mouse over viewer and press numeric keys 1, 2, 3, ...)',
    icon: tool9Icon,
    iconActive: tool9IconActive,
    type: 'windowLevel',
    cursor: 'wlevel',
    onTimeAction: false,
  },
];

export const getToolByType = type => {
  return tools.find(item => item.type === type);
};

export const getDefaultType = (type, datas) => {
  if (type === '3DQ' && !datas['3DQ'] && datas['tomo']) return 'tomo';
  return type;
};
