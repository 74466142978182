import React from 'react';
import ButtonLoading from 'shared/components/ButtonLoading';
import { hasAccess } from 'utils/permissionHelper';

const MODE_NAMES = { 1: 'draft', 2: 'published' };
const MODE_BY_NAMES = { draft: 1, published: 2 };

const COMPLETE_DETAILS = {
  draft: {
    description: (
      <p>
        If you are finished configurations, you can go ahead and{' '}
        <span className='highlighted-action-text'>Save and Publish</span> the Product. <br /> If you
        want to make additional changes later click{' '}
        <span className='highlighted-action-text'>Save</span>, or click{' '}
        <span className='highlighted-action-text'>Back</span> to make additional changes now.
      </p>
    ),
  },
  published: {
    description: (
      <p>
        If you are finished configurations, you can go ahead and{' '}
        <span className='highlighted-action-text'>Update</span> the Product. <br /> If you want to
        make additional changes click <span className='highlighted-action-text'>Back</span>.
      </p>
    ),
  },
};

const ProductCompleteStep = ({ onSave, fetching, form, isEditing }) => {
  const { mode } = form;
  return (
    <div className='d-flex flex-column lesson-complete-step text-center justify-content-center w-100'>
      <h4>Product Setup is Complete</h4>
      {COMPLETE_DETAILS[MODE_NAMES[mode]]?.description}
      {hasAccess('products_create') && (
        <div>
          {mode === MODE_BY_NAMES.draft && (
            <>
              <button
                className='btn btn btn-outline-blue btn-border-2 mr-3 min-100'
                type='button'
                onClick={() => onSave(MODE_BY_NAMES.draft)}
                disabled={fetching}
              >
                Save Draft
              </button>
              <button
                className='btn btn-primary min-100 m-auto'
                type='button'
                onClick={() => onSave(MODE_BY_NAMES.published)}
                disabled={fetching}
              >
                Save and Publish
              </button>
            </>
          )}
          {isEditing && mode === MODE_BY_NAMES.published && (
            <>
              <button
                className='btn btn-primary min-100 m-auto'
                type='button'
                onClick={() => onSave(MODE_BY_NAMES.published)}
                disabled={fetching}
              >
                Update
              </button>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default ProductCompleteStep;
