import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import ReactModal from 'react-modal';
import IconCertificates from 'assets/course/select_certificates.svg';
import CourseCertificatesModal from './CourseCertificatesModal';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import { Document, Page, pdfjs } from 'react-pdf';
import Loading from 'shared/components/Loading';

const pdfVersion = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
pdfjs.GlobalWorkerOptions.workerSrc = pdfVersion;

const CourseCertificates = ({ form, setForm }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [authorsModal, setAuthorsModal] = useState(false);
  const [certificates, setCertificates] = useState();
  const [previewItem, setPreviewItem] = useState();
  const [fetch, setFetch] = useState(false);
  const [notEditable, setNotEditable] = useState(false);

  const getData = async () => {
    try {
      const { data } = await Api.get('/wizard/certificates');
      setCertificates(data.data);
      // If the course is ASRT accessible, then we need to select the ASRT certificate
      if (form.isASRTAccessible) {
        const asrtCertificate = data.data.find(certificate => certificate.template === 'asrt');
        if (asrtCertificate) {
          setNotEditable(true);
          setForm({ ...form, certificateId: asrtCertificate.id });
        }
      }
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const getCertificate = async id => {
    try {
      setFetch(true);
      const { data } = await Api.get(`/wizard/certificate/preview/${id}`);
      setPreviewItem(data.data.us);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetch(false);
    }
  };

  const onSelectCertificate = id => {
    setForm({ ...form, certificateId: id });
  };

  useEffect(() => {
    getData();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (form.certificateId) getCertificate(form.certificateId);
  }, [form.certificateId]);

  return (
    <>
      <div className='px-2'>
        <h6 className='color-blue mb-3'>Manage Certificate</h6>
        {!previewItem && !fetch && (
          <label
            className='col-7 upload-wizard-block pointer'
            onClick={notEditable ? null : () => setAuthorsModal(true)}
          >
            <div className='d-flex flex-column align-items-center'>
              <img src={IconCertificates} alt='icon' className='mb-2' />
              Select Certificate
            </div>
          </label>
        )}
        {previewItem && !fetch && (
          <div className='course-pdf-step'>
            <div className='d-flex flex-column align-items-center mt-5 mb-5'>
              {previewItem && (
                <Document file={previewItem} id='certificate'>
                  <Page width={530} pageNumber={1} id='certificatePage' />
                </Document>
              )}
              <p>Please make sure the certificate looks properly</p>
              {!notEditable && (
                <button
                  className='btn btn-outline-blue px-4 mt-2'
                  type='button'
                  onClick={() => setAuthorsModal(true)}
                >
                  Change
                </button>
              )}
            </div>
          </div>
        )}
        {fetch && <Loading className='mt-5' />}
      </div>
      <ReactModal isOpen={authorsModal} className='custom-modal'>
        <CourseCertificatesModal
          certificates={certificates}
          activeCertificate={form.certificateId}
          onSelectCertificates={onSelectCertificate}
          close={() => setAuthorsModal(false)}
        />
      </ReactModal>
    </>
  );
};

export default CourseCertificates;
