import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import Login from './routes/Login';
import Forgot from './routes/Forgot';
import Reset from './routes/Reset';
import { WithTitleRoute } from 'utils/permissionHelper';
import EmailVerification from './routes/EmailVerification';

const Auth = () => {
  return (
    <div className='auth-pages'>
      <Switch>
        <WithTitleRoute path='/login' component={Login} />
        <WithTitleRoute path='/forgot' component={Forgot} />
        <WithTitleRoute path='/auth/reset/:code' component={Reset} />
        <WithTitleRoute path='/email-verification/:code?' component={EmailVerification} />
        <Redirect from='*' to='/login' />
      </Switch>
    </div>
  );
};

export default Auth;
