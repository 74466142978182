import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import LicensesHeader from './components/LicensesHeader';
import ReactPaginate from 'react-paginate';
import { IconArrowLeft, IconArrowRight } from 'shared/components/Icons';
import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';
import { confirmAlert } from 'react-confirm-alert';
import { hasAccess } from 'utils/permissionHelper';
import { useSelector } from 'react-redux';
import LicensesLists from './components/LicensesLists';
import AdvancedFilters from 'shared/components/filters/AdvancedFilters';
import useFilters from 'shared/hooks/useFilters';

const filtersStatus = [{ name: 'Assigned', id: 1 }, { name: 'Unused', id: 2 }];

const Licenses = ({ match, history }) => {
  const { entityId } = match.params;
  const account = useSelector(state => state.account);
  const initialEntity = account.entity ? account.entity.id : entityId ? Number(entityId) : '';
  const initialFilters = () => ({
    entity: hasAccess('entity_list_view') ? initialEntity : '',
    assigned: '',
    subPlan: '',
  });
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState([]);
  const [entities, setEntities] = useState([]);
  const [fetch, setFetch] = useState(0);
  const [removeFetch, setRemoveFetch] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [subPlans, setSubPlans] = useState([]);

  const {
    requestParams,
    filters,
    dropFilters,
    changeFilter,
    changeDropFilter,
    clearDropFilter,
    filterDependency,
    originalState,
  } = useFilters(
    { page: 0, order: false, orderKey: 'createdAt', search: '', limit: 8 },
    { ...initialFilters() },
  );

  const downloadData = [
    {
      title: 'Export as CSV',
      url: `/entity/licenses`,
      fileName: 'licenses_list',
      type: 'csv',
      params: requestParams,
    },
  ];

  const getSubPlanList = async () => {
    try {
      const { data } = await Api.get(`/subscription/all`, {
        params: { limit: 10000 },
      });
      if (data?.data) {
        setSubPlans(data.data.results);
      }
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const getData = async () => {
    try {
      setFetch(true);
      const { data } = await Api.get(`/entity/licenses`, { params: requestParams });
      if (data?.data) {
        setPageCount(Math.ceil(data.data.count / 8));
        setData(data.data.results);
      }
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetch(false);
    }
  };

  const getEntities = async () => {
    try {
      const params = { page: 1, limit: 1000 };
      const { data } = await Api.get(`/entity/entities`, { params });
      if (data?.data) setEntities(data.data.results);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onItemRemove = async item => {
    try {
      setRemoveFetch(true);
      await Api.delete(`/entity/licensekey/${item.id}`);
      await getData();
      enqueueSnackbar('Successfully removed', { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setRemoveFetch(false);
    }
  };

  const onItemRemoveConfirm = item => {
    if (item.used > 0) {
      confirmAlert({
        message: 'The scan is used in lesson(s) Detach it from lesson before removing.',
        buttons: [{ label: 'OK' }],
      });
      return;
    }

    confirmAlert({
      title: 'Key Removal',
      message: 'Do you really want to remove the key from the System?',
      buttons: [
        {
          label: 'CANCEL',
        },
        {
          label: 'REMOVE',
          onClick: () => onItemRemove(item),
        },
      ],
    });
  };

  const onClearAllFilters = () => {
    clearDropFilter();
  };

  const onFilterChange = (name, id) => {
    changeDropFilter(name, id);
  };

  const onPageChange = ({ selected }) => {
    changeFilter({ page: selected });
  };

  const handleSearchChange = text => {
    changeFilter({ search: text });
  };

  const onChangeOrder = item => {
    if (!item.key) return;
    changeFilter({ order: !filters.order, orderKey: item.key });
  };

  const filtersOptions = [
    {
      type: 'dropdown',
      title: 'Status',
      name: 'assigned',
      data: filtersStatus,
      value: dropFilters.assigned,
      onFilterChange: onFilterChange,
    },
    {
      type: 'dropdown',
      title: 'Entity',
      name: 'entity',
      data: entities,
      value: dropFilters.entity,
      showSearch: true,
      onFilterChange: onFilterChange,
      hide: !hasAccess('entity_list_view'),
    },
    {
      type: 'dropdown',
      title: 'Subscription Plan',
      name: 'subPlan',
      data: subPlans,
      value: dropFilters.subPlan,
      showSearch: true,
      onFilterChange: onFilterChange,
      hide: !hasAccess('subscriptions_list_view'),
    },
  ];

  if (entityId) {
    filtersOptions.splice(1, 1);
  }

  useEffect(() => {
    getData();
    //eslint-disable-next-line
  }, [...filterDependency]);

  useEffect(() => {
    if (hasAccess('entity_list_view')) getEntities();
    if (hasAccess('subscriptions_list_view')) getSubPlanList();
    //eslint-disable-next-line
  }, []);

  return (
    <div>
      <LicensesHeader
        search={originalState.search}
        onSearch={handleSearchChange}
        entityId={entityId}
        history={history}
      />
      <div className='py-3 has-header'>
        <AdvancedFilters
          downloadData={downloadData}
          withExport={true}
          filters={filtersOptions}
          onClearAll={onClearAllFilters}
        />
        <div className='col-12'>
          <LicensesLists
            list={data}
            order={filters}
            changeOrder={onChangeOrder}
            fetch={fetch}
            onItemRemoveConfirm={onItemRemoveConfirm}
            onItemRemoveDisabled={removeFetch}
          />
        </div>
        {pageCount > 1 && !fetch && (
          <ReactPaginate
            previousLabel={<IconArrowLeft />}
            nextLabel={<IconArrowRight />}
            breakLabel={'...'}
            forcePage={Number(filters.page)}
            breakClassName={'break-me'}
            pageCount={pageCount}
            marginPagesDisplayed={3}
            pageRangeDisplayed={3}
            onPageChange={onPageChange}
            containerClassName={`pagination show-arrow`}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
          />
        )}
      </div>
    </div>
  );
};

export default Licenses;
