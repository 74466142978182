// Load vendors
import axios from 'axios';

// Load configs
import { HOST } from 'configs/host';

// Set config defaults when creating the instance
export const Api = axios.create({
  baseURL: `${HOST.API.URL}/${HOST.API.API_PREFIX}`,
  // THIS HEADERS IS IMPORTANT SEE THE URL ABOVE
  headers: {
    common: {
      platform: 'admin',
    },
  },
});

export const LocalApi = axios.create({
  baseURL: `http://${window.location.hostname}:8091`,
});

Api.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      document.cookie = 'authToken=; max-age=0'; // Use the name that you use to store the authToken in cookies
    }

    return Promise.reject(error);
  },
);

export const setAuthToken = (authToken, api) => {
  api.defaults.headers.common[HOST.API.AUTH_HEADER] = `${HOST.API.AUTH_PREFIX} ${authToken}`;
};
