/* eslint-disable eqeqeq */
import React from 'react';
import ClickArea from 'shared/components/ClickArea';

const accessLevels = {
  0: 'None',
  1: 'View',
  2: 'Edit',
};

const headers = [
  {
    name: 'Name',
    className: 'col-5',
  },
  {
    name: 'Description',
    className: 'col-5',
  },
  {
    name: 'Permission',
    className: 'col-2 text-center',
  },
];

const PermissionsList = ({ items }) => {
  if (!items || !items.length)
    return <div className='text-black-50 text-center mt-4'>No Results Found</div>;

  return (
    <div className='users-lists'>
      <ul className='p-0'>
        <li className='d-flex list-header align-items-center'>
          {headers.map((item, index) => (
            <ClickArea key={item.name + index} className={`text-body ${item.className}`}>
              {item.name}
            </ClickArea>
          ))}
        </li>
        {items &&
          items.map(item => {
            return (
              <li key={item.id} className='d-flex align-items-center list-item'>
                <div className='col-5'>{item.permissionItem.name}</div>
                <div className='col-5'>{item.permissionItem.description}</div>
                <div className='col-2 text-center'>{accessLevels[item.accessLevel]}</div>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default PermissionsList;
