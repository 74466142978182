import React, { useEffect, useState } from 'react';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  horizontalListSortingStrategy,
} from '@dnd-kit/sortable';

import { SortableItem } from './SortableItem';
import Button from 'shared/components/Button';
import { PlusIcon } from 'shared/components/Icons';

export function StepsContainerBlock({
  handleAddStepItem,
  items,
  setQuizConfigObj,
  selectedStepId,
  handleSelectStep,
  setSelectedStepIndex,
  selectedStepIndex,
  handleRemoveStep,
  categories = [],
}) {
  const [hasStepsContainerScroll, setHasStepsContainerScroll] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isAtEnd, setIsAtEnd] = useState(false);

  let animationFrameId = null;

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 100,
        tolerance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEnd = event => {
    const { active, over } = event;

    if (active?.id !== over?.id) {
      setQuizConfigObj(prev => {
        const oldIndex = items.findIndex(item => item.id === active?.id);
        const newIndex = items.findIndex(item => item.id === over?.id);
        setSelectedStepIndex(newIndex);
        return { ...prev, steps: arrayMove(items, oldIndex, newIndex) };
      });
    }
  };

  const handleScroll = e => {
    // Cancel the previous frame if there was one
    if (animationFrameId) {
      cancelAnimationFrame(animationFrameId);
    }

    const element = e.target;
    animationFrameId = requestAnimationFrame(() => {
      setIsScrolled(element.scrollLeft > 0);

      const totalScrollWidth = element.scrollWidth - element.clientWidth;
      setIsAtEnd(element.scrollLeft >= totalScrollWidth);
    });
  };

  useEffect(() => {
    const stepsContainer = document.getElementById('add_steps_draggable');
    if (stepsContainer) {
      setHasStepsContainerScroll(stepsContainer?.scrollWidth > stepsContainer?.clientWidth);
    }
  }, [items]);

  useEffect(() => {
    return () => {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
    };
  }, []);

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <div className='steps_part_block'>
        <div className='absolute_overlay'>
          {hasStepsContainerScroll && isScrolled && <div className='gradient start' />}
          {hasStepsContainerScroll && !isAtEnd && <div className='gradient end' />}
        </div>
        <div
          id='add_steps_draggable'
          className='draggable_block custom_scrollbar_block '
          onScroll={handleScroll}
        >
          <div className='left_sticky_div'>
            <Button onClick={handleAddStepItem} className='add_step_btn'>
              <PlusIcon />
            </Button>
          </div>

          <SortableContext items={items} strategy={horizontalListSortingStrategy}>
            {items.map((item, index) => {
              const isSelected = item.id === selectedStepId || index === selectedStepIndex;
              return (
                <SortableItem
                  isSelected={isSelected}
                  selectedStepIndex={selectedStepIndex}
                  selectedStepId={selectedStepId}
                  handleSelectStep={handleSelectStep}
                  handleRemoveStep={handleRemoveStep}
                  categoryTitle={item.assignedCategory}
                  color={item.color}
                  size='small'
                  key={item.id}
                  id={item.id}
                  index={index}
                  {...item}
                />
              );
            })}
          </SortableContext>
        </div>
      </div>
    </DndContext>
  );
}
