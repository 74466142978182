import React from 'react';
import { Link } from 'react-router-dom';
import { hasAccess } from 'utils/permissionHelper';
import { IconSearch } from 'shared/components/Icons';

const LessonHeader = ({ search, onSearch }) => {
  return (
    <div className='users-header d-flex justify-content-between align-items-center'>
      <div className='d-flex'>
        {hasAccess('lessons_create') && (
          <Link className='btn btn-invite' to='/lessons/new'>
            + Add New Lesson
          </Link>
        )}
      </div>
      <div className='d-flex'>
        <div className='search-block'>
          <input
            type='text'
            value={search}
            onChange={({ target }) => onSearch(target.value)}
            className='form-control'
            placeholder='Case ID / Name'
          />
          <IconSearch />
        </div>
      </div>
    </div>
  );
};

export default LessonHeader;
