import moment from 'moment';
import placeholder from 'assets/video.jpg';

const findSubscriptionAuthors = courses => {
  let result = [];
  courses.forEach(({ course }) => {
    const authors = course.courseAuthors || [];
    result = [...result, ...authors];
  });
  return result;
};

export const formatSubscription = data => {
  return {
    id: data.id,
    name: data.title,
    status: data.subscriptionStatus,
    type: data.subscriptionType,
    questions: data.testQuestions,
    image: data.imageUrl || placeholder,
    info: data.description,
    days: data.courseDuration,
    time: data.planDuration,
    authors: findSubscriptionAuthors(data.courses),
    userSubs: data.userSubscriptions || [],
    courses: data.courses,
    created: data.createdAt,
    totalCourses: data.totalCourses,
    totalUsers: data.totalUsers,
    totalAuthors: data.totalAuthors,
    expirable: data.expirable,
    isSystem: data.isSystem,
    keyRequired: data.keyRequired,
  };
};

// A function to format date
export const formatDateForQuiz = dateStr => {
  return dateStr && moment(dateStr).format('MMM. D, YYYY');
};

export const formatDateWithSlashes = dateStr => {
  return moment(dateStr).format('MM/D/YYYY');
};
