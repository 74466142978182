import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import FooterButtons from './components/FooterButtons';
import Header from './components/Header';
import SubsGeneralStep from './components/SubsGeneralStep';
import SubsCompleteStep from './components/SubsCompleteStep';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import SubsCoursesStep from './components/SubsCoursesStep';
import Loading from 'shared/components/Loading';

const SubscriptionNew = ({ history, match }) => {
  const { id } = match.params;
  const { enqueueSnackbar } = useSnackbar();
  const [isInUse, setIsInUse] = useState(false);
  const [course, setSubs] = useState();
  const [subscriptionStatus, setSubscriptionStatus] = useState();
  const [fetching, setFetching] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [data, setData] = useState({
    activeCases: [],
    courses: [],
  });
  const [form, setForm] = useState({
    title: '',
    description: '',
    subscriptionType: 1,
    courseDuration: null,
    planDuration: null,
    uploadImageId: null,
    expirable: 1,
    keyRequired: 0,
  });

  const steps = [SubsGeneralStep, SubsCoursesStep, SubsCompleteStep];

  const constructFormData = form => {
    const submittingCourses =
      form.subscriptionType === 5
        ? data?.activeCourses?.filter(course => course?.isMarketingMaterial)
        : data?.activeCourses?.filter(course => !course?.isMarketingMaterial);
    form.courses = submittingCourses?.length ? submittingCourses.map(i => i.courseId) : [];
    if (!form.expirable) {
      delete form.courseDuration;
    }
    if (Number(form.subscriptionType) !== 1) {
      form.keyRequired = 0;
    }
    return form;
  };

  const onSubsSave = async () => {
    try {
      setFetching(true);
      const body = constructFormData(form);
      const endpoint = id ? '/wizard/subscription/update' : '/wizard/subscription/init';
      await Api.post(endpoint, { ...body, originId: id ? id : undefined });
      enqueueSnackbar(`Subscription successfully ${id ? 'updated' : 'created'}`, {
        variant: 'success',
      });
      history.push('/subscriptions/all');
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      setFetching(false);
    }
  };

  const onGoBackStep = () => {
    setActiveStep(step => step - 1);
  };

  const handleStepChange = async e => {
    e.preventDefault();
    setActiveStep(step => step + 1);
  };

  const setInitialCourses = data => {
    if (!data) return [];
    return data.map(({ course: item }) => {
      item.courseId = item.id;
      return item;
    });
  };

  const setSubsInitialData = subs => {
    setForm({
      subscriptionType: subs.subscriptionType,
      title: subs.title || '',
      description: subs.description || '',
      courseDuration: subs.courseDuration || null,
      planDuration: subs.planDuration || null,
      expirable: subs.expirable || 0,
      keyRequired: subs.keyRequired || 0,
      uploadImageId: subs.uploadImageId || null,
    });
    setData({
      courses: [],
      subsImage: {
        url: subs.imageUrl,
      },
      activeCourses: setInitialCourses(subs.courses),
    });
  };

  const getSubsData = async id => {
    try {
      const { data } = await Api.get(`/wizard/subscription/${id}`);
      const { subscription, inUse } = data.data;
      setIsInUse(inUse);
      setSubs(subscription);
      setSubsInitialData(subscription);
      setSubscriptionStatus(subscription?.subscriptionStatus);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      history.push('/subscriptions/all');
    }
  };

  useEffect(() => {
    if (id) getSubsData(id);
    //eslint-disable-next-line
  }, [id]);

  const isLastStep = activeStep === steps.length - 1;
  const Step = steps[activeStep];

  if (id && !course) return <Loading className='mt-5' />;

  return (
    <div className='lesson-creation'>
      <Header steps={steps} activeStep={activeStep} setActiveStep={setActiveStep} />
      <form
        className='py-3 has-header d-flex flex-column justify-content-between min-vh-100'
        onSubmit={handleStepChange}
      >
        <div className='col-12'>
          <Step
            form={form}
            setForm={setForm}
            data={data}
            setData={setData}
            onSubsSave={onSubsSave}
            fetching={fetching}
            isInUse={!!isInUse}
            subscriptionStatus={subscriptionStatus}
          />
        </div>
        <FooterButtons
          fetching={fetching}
          onGoBackStep={onGoBackStep}
          activeStep={activeStep}
          isLastStep={isLastStep}
        />
      </form>
    </div>
  );
};

export default SubscriptionNew;
