import React from 'react';
import LandingCircleProgress from 'shared/components/LandingCircleProgress';

const PROGRESSES = [100, 25, 50, 0, 3, 75];

const CoursesProgressCircle = props => {
  const { progresses = PROGRESSES } = props;
  return (
    <div className='course-progress-container'>
      {progresses.map((progress, index) => (
        <React.Fragment key={index}>
          <LandingCircleProgress
            containerClassName={`circle-item ${progress === 100 && 'active'}`}
            progress={progress}
            key={index}
          />
        </React.Fragment>
      ))}
    </div>
  );
};

export default CoursesProgressCircle;
