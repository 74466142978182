import React, { useState } from 'react';
import { useSnackbar } from 'notistack';

import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';

import Select from 'shared/components/Select';
import UsersSelect, { UserOption } from 'shared/components/UsersSelect';
import { IconBin, IconClose, IconCopy } from 'shared/components/Icons';
import { SwitchIOSLike } from 'shared/components/SwitchIOSLike';

const accessLevels = [{ name: 'Only View', value: '0' }, { name: 'Manage', value: '1' }];

const ShareCourseModal = ({ course, close, onSuccess, sharePublicUrl, isCourseShared }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [fetching, setFetching] = useState(false);
  const [isFetchingSharingToggle, setIsFetchingSharingToggle] = useState(false);
  const [isPublic, setIsPublic] = useState(course.isPublic);
  const [users, setUsers] = useState(course.courseShares || []);
  const [formAccessLevel, setFormAccessLevel] = useState(0);

  const onSelectUser = user => {
    setUsers([{ ...user, accessLevel: formAccessLevel }, ...users]);
  };

  const onChangeItemAccessLevel = (index, { target }) => {
    const tempUsers = [...users];
    tempUsers[index].accessLevel = target.value;
    setUsers(tempUsers);
  };

  const onRemoveItem = index => {
    const tempUsers = [...users];
    tempUsers.splice(index, 1);
    setUsers(tempUsers);
  };

  const onCopyLinkClick = async () => {
    try {
      setIsFetchingSharingToggle(true);
      navigator.clipboard.writeText(sharePublicUrl);
      if (onSuccess) await onSuccess();
      isPublic && close();
      setIsFetchingSharingToggle(false);
      enqueueSnackbar('Successfully Copied', { variant: 'success' });
    } catch (err) {
      setIsFetchingSharingToggle(false);
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onShareHandle = async () => {
    try {
      setFetching(true);
      const shares = [...users].map(i => {
        return {
          userId: i?.user?.id || i?.id,
          email: i.email,
          accessLevel: Number(i.accessLevel),
        };
      });
      if (isPublic) {
        await Api.post('/courses/share/course/public', {
          courseId: course?.id,
          isPublic: true,
        });
        enqueueSnackbar('Successfully Shared', { variant: 'success' });
      } else {
        await Api.post('/courses/share/course/add', {
          courseId: course?.id,
          isPublic: false,
          shares,
        });
      }
      if (onSuccess) await onSuccess();
      close();
      setFetching(false);
    } catch (err) {
      setFetching(false);
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onToggleCourseSharing = async bool => {
    try {
      setFetching(true);
      const action = !bool ? 'disable' : 'enable';
      await Api.post(`/courses/share/course/${action}/${course?.id}`);
      await onSuccess();
      setFetching(false);
      enqueueSnackbar('Successfully updated', { variant: 'success' });
    } catch (err) {
      setFetching(false);
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  return (
    <div className='modal-box share-modal d-flex flex-column justify-content-between h-auto p-3'>
      <div className='d-flex justify-content-between align-items-center'>
        <div className='d-flex justify-content-between align-items-center share-modal-left-head'>
          <p className='fz-20 fw-600 mb-0'>Course Sharing</p>
          <SwitchIOSLike
            disabled={isFetchingSharingToggle}
            handleChange={() => onToggleCourseSharing(!isCourseShared)}
            checkedState={!!isCourseShared}
          />
        </div>
        <button onClick={close} className='btn p-0 modal-close-btn'>
          <IconClose />
        </button>
      </div>
      {isCourseShared ? (
        <>
          <div className='mt-2'>
            <div className='d-flex mb-2 mt-3 pb-1'>
              <div className='d-flex align-items-center col-6 p-0'>
                <input
                  type='radio'
                  id='radioPublic'
                  name='radioIsPublic'
                  onChange={() => setIsPublic(true)}
                  value={isPublic}
                  checked={isPublic}
                />
                <div className='ml-1'>
                  <label htmlFor='radioPublic' className='m-0 pointer'>
                    <b>Public</b>: anyone with the link can access
                  </label>
                </div>
              </div>
              <div className='d-flex align-items-center col-6 p-0'>
                <input
                  type='radio'
                  id='radioPrivate'
                  name='radioIsPublic'
                  onChange={() => setIsPublic(false)}
                  value={isPublic}
                  checked={!isPublic}
                />
                <div className='ml-1'>
                  <label htmlFor='radioPrivate' className='m-0 pointer'>
                    <b>Private</b>: Only configured KOLs can access
                  </label>
                </div>
              </div>
            </div>
            {!isPublic && (
              <div className='d-flex user-selection'>
                <div className='col-8'>
                  <UsersSelect
                    isKOLUsers={true}
                    onChange={onSelectUser}
                    activeUsers={users}
                    placeholder='Choose from list or enter email address'
                  />
                </div>
                <div className='col ml-2'>
                  <Select
                    name='accessLevel'
                    onChange={e => setFormAccessLevel(e.target.value)}
                    value={formAccessLevel}
                    items={accessLevels}
                    useProperty='value'
                    displayProperty='name'
                    required={true}
                    editing={true}
                    hideDefault
                  />
                </div>
              </div>
            )}
            {!isPublic && (
              <div className='pb-3 pt-1 selected-users'>
                {users.map((item, i) => {
                  return (
                    <div className='mt-2 d-flex align-items-center justify-content-between' key={i}>
                      <div className='col-9'>
                        <UserOption user={item} />
                      </div>
                      <div className='col pr-0 d-flex justify-content-end'>
                        <Select
                          parentClassName='mw-100px'
                          className='fz-11 border-0'
                          name='accessLevel'
                          onChange={onChangeItemAccessLevel.bind(null, i)}
                          value={item.accessLevel}
                          items={accessLevels}
                          useProperty='value'
                          displayProperty='name'
                          required={true}
                          editing={true}
                          hideDefault
                        />
                      </div>
                      <button
                        className='btn p-0 ml-2 mr-1 btn-no-shadow'
                        onClick={onRemoveItem.bind(null, i)}
                      >
                        <IconBin />
                      </button>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </>
      ) : (
        <div className='not-shared-modal-content border-bottom'>
          <b>Sharing is inactive:</b> To be able to share this course with others first enable
          Course Sharing.
        </div>
      )}
      <div className='border-top pt-3'>
        {!!isPublic && !!isCourseShared && (
          <p className='fz-13 mb-0'>
            Copy the generated link and send it to the user with whom you want to share the course
          </p>
        )}
        <div
          className={`d-flex justify-content-${
            isCourseShared ? 'between' : 'end'
          } align-items-center px-4`}
        >
          {!!isCourseShared && (
            <button
              disabled={fetching}
              className={`btn fz-13 fw-600 mt-2 px-0 btn-copy-link`}
              onClick={onCopyLinkClick}
            >
              <IconCopy />
              <span>Copy Link</span>
            </button>
          )}

          <button
            className={`btn btn-md btn-primary px-3 btn-save ${!isCourseShared && 'btn-disabled'}`}
            onClick={onShareHandle}
            disabled={fetching}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default ShareCourseModal;
