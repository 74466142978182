import React from 'react';
import WizardSteps from 'shared/components/WizardSteps';
import ModeStatus from 'shared/ModeStatus';

const Header = ({ steps, activeStep, setActiveStep, form, onPreviewClick, isEditing }) => {
  return (
    <div className='users-header d-flex justify-content-between align-items-center'>
      <WizardSteps steps={steps} activeStep={activeStep} setActiveStep={setActiveStep} />
      <div className='mt-1 d-flex align-items-center'>
        <ModeStatus mode={form.mode} />
        {!!isEditing && (
          <button
            onClick={onPreviewClick}
            type='button'
            className='btn btn-md btn-outline-blue font-weight-bold btn-border-2'
            disabled={!isEditing}
          >
            Preview
          </button>
        )}
      </div>
    </div>
  );
};

export default Header;
