import React, { useState, useEffect } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useSnackbar } from 'notistack';
import moment from 'moment';

import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';

import ProgressHeader from './components/ProgressHeader';
import ProgressList from './components/ProgressList';
import Loading from 'shared/components/Loading';

const generateLessons = data => {
  const result = [];
  if (!data || !data.length) return [];
  data.forEach(({ lesson }) => {
    const userLesson = lesson.userLesson;
    if (!userLesson) return;
    result.push({
      id: lesson.id,
      progress: userLesson.progress,
      passedBy: userLesson.passedBy,
      unpassedBy: userLesson.unpassedBy,
      start_date: moment(userLesson.startDate).format('MM/DD/YYYY'),
      end_date: moment(userLesson.endDate).format('MM/DD/YYYY'),
      name: lesson.title,
    });
  });
  return result;
};

const generateCourses = data => {
  const result = [];
  data.forEach(course => {
    result.push({
      id: course.course.id,
      name: course.course.title,
      progress: course.progress,
      passedBy: course.passedBy,
      unpassedBy: course.unpassedBy,
      start_date: moment(course.startDate).format('MM/DD/YYYY'),
      end_date: moment(course.endDate).format('MM/DD/YYYY'),
      lessons: generateLessons(course.course.courseLessons),
    });
  });
  return result;
};

const generatePlansData = data => {
  const result = [];

  data.forEach(plan => {
    const sub = plan?.userSubscriptions?.[0] || {};
    result.push({
      id: plan.id,
      name: plan.title,
      progress: sub.progress,
      start_date: moment(sub.startDate).format('MM/DD/YYYY'),
      end_date: moment(sub.endDate).format('MM/DD/YYYY'),
      courses: generateCourses(plan.userCourses),
    });
  });
  return result;
};

const UserProgress = ({ match }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState();
  const [user, setUser] = useState();
  const [passCourseFetch, setPassCourseFetch] = useState(false);
  const [passLessonFetch, setPassLessonFetch] = useState(false);

  const getUserData = async id => {
    const res = await Api.get(`/auth/getuserbyid/${id}`);
    setUser(res.data.data);
  };

  const getUserSubs = async id => {
    const res = await Api.get(`/subscription/getusersubscriptionsbyuserid/${id}`);
    if (res?.data?.data) setData(generatePlansData(res.data.data));
  };

  const onPassCourse = (item, isUnpass) => {
    if (isUnpass) {
      handlePassCourse(item, isUnpass);
      return;
    }
    confirmAlert({
      overlayClassName: 'with-icon',
      title: 'Confirmation Needed',
      message: 'This will mark selected and all prior lessons as passed for current user.',
      buttons: [
        {
          className: `btn-cancel-regular`,
          label: 'Cancel',
        },
        {
          className: `btn-primary`,
          label: 'Mark as Passed',
          onClick: () => handlePassCourse(item, isUnpass),
        },
      ],
    });
  };

  const onPassLesson = (lesson, course, isUnpass) => {
    if (isUnpass) {
      handlePassLesson(lesson, course, isUnpass);
      return;
    }
    confirmAlert({
      overlayClassName: 'with-icon',
      title: 'Confirmation Needed',
      message: 'This will mark selected and all prior lessons as passed for current user.',
      buttons: [
        {
          className: `btn-cancel-regular`,
          label: 'Cancel',
        },
        {
          className: `btn-primary`,
          label: 'Mark as Passed',
          onClick: () => handlePassLesson(lesson, course, isUnpass),
        },
      ],
    });
  };

  const handlePassCourse = async (item, isUnpass) => {
    try {
      setPassCourseFetch(true);
      const body = { userId: match.params.id, courseId: item.id };
      await Api.post(`/courses/autopass-course`, body);
      await getUserData(match.params.id);
      await getUserSubs(match.params.id);
      setPassCourseFetch(false);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      setPassCourseFetch(false);
    }
  };

  const handlePassLesson = async (lesson, course, isUnpass) => {
    try {
      setPassLessonFetch(true);
      const body = { userId: match.params.id, courseId: course.id, lessonId: lesson.id };
      const action = isUnpass ? '/courses/unpass-lesson' : '/courses/autopass-lesson';
      await Api.post(action, body);
      await getUserData(match.params.id);
      await getUserSubs(match.params.id);
      setPassLessonFetch(false);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      setPassLessonFetch(false);
    }
  };

  useEffect(() => {
    getUserData(match.params.id);
    getUserSubs(match.params.id);
  }, [match.params.id]);

  if (!user || !data) return <Loading className='mt-5' />;

  return (
    <div>
      <ProgressHeader user={user} />
      <div className='py-3 has-header'>
        <div className='col-9'>
          <ProgressList
            data={data}
            passCourseFetch={passCourseFetch}
            onPassCourse={onPassCourse}
            onPassLesson={onPassLesson}
            passLessonFetch={passLessonFetch}
          />
        </div>
      </div>
    </div>
  );
};

export default UserProgress;
