import React from 'react';
import { NavLink } from 'react-router-dom';
import { IconSearch } from 'shared/components/Icons';

const filters = [
  { name: 'All', path: '/system/logs/' },
  { name: 'User', path: '/system/logs/1' },
  { name: 'Admin', path: '/system/logs/0' },
];

const Header = ({ onSearch }) => {
  return (
    <div className='users-header d-flex justify-content-between align-items-center'>
      <div className='d-flex breadcrumbs'>
        <div className='align-items-center d-flex mx-3 filters'>
          {filters.map(item => (
            <NavLink key={item.path} exact className='mx-3' to={item.path} activeClassName='active'>
              {item.name}
            </NavLink>
          ))}
        </div>
      </div>
      <div className='d-flex'>
        <div className='search-block'>
          <input
            type='text'
            onChange={({ target }) => onSearch(target.value)}
            className='form-control'
          />
          <IconSearch />
        </div>
      </div>
    </div>
  );
};

export default Header;
