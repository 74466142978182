import React, { useState, useEffect } from 'react';
import { Api } from 'utils/connectors';
import Loading from 'shared/components/Loading';
import CourseViewHeader from './components/CourseViewHeader';
import Accordion from 'app/Main/components/Accordion';
import CourseLessonHeader from './components/CourseLessonHeader';
import CourseLessonBody from './components/CourseLessonBody';
import AuthorsList from './components/AuthorsList';
import PublishPreview from './components/PublishPreview';
import { Link } from 'react-router-dom';
import { IconKey } from 'shared/components/Icons';
import TagsViewBlock from '../CoursePreview/components/TagsViewBlock';
import { DEFAULT_IMG_URLS, courseTags } from 'configs';
import { useSnackbar } from 'notistack';
import { getError } from 'utils/appHelpers';
import ShareCourse from './components/ShareCourseBlock';
import { SUBSCRIPTION_PLAN_TYPES } from 'configs/constants';

const Courses = ({ match, history }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState();
  const isPreview = match.url.split('/').pop() === 'preview';

  const getCourseData = async () => {
    try {
      const { data } = await Api.get(`/courses/getcourse/${match.params.id}`);
      if (data?.data) {
        if (data.data.courseLessons?.length) {
          data.data.courseLessons = data.data.courseLessons.map(item =>
            item.lesson?.imageUrl
              ? item.lesson
              : { ...(item.lesson || {}), imageUrl: DEFAULT_IMG_URLS.lesson },
          );
          data.data.duration = data.data.courseLessons.reduce((a, b) => ({
            duration: a.duration + b.duration,
          })).duration;
        }
        const courseData = data?.data?.imageUrl
          ? data.data
          : { ...data.data, imageUrl: DEFAULT_IMG_URLS.course };
        setData(courseData);
      }
    } catch (err) {
      history.push('/no-access');
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  useEffect(() => {
    getCourseData();
  }, [match.params.id]);

  if (!data) return <Loading />;

  const learnerTags = data.courseTags?.filter(i => i.tag.type === courseTags.learner);
  const adminTags = data.courseTags?.filter(i => i.tag.type === courseTags.admin);

  return (
    <div>
      <CourseViewHeader title={data.title} isPreview={isPreview} mode={data.mode} />
      <div className='d-flex has-header'>
        <div className='col-9'>
          <div className='px-1'>
            <h5 className='color-blue'>{data.title}</h5>
            {data.info && (
              <p>
                <span className='d-block mb-2 text-lg text-blue fw-400'>About course</span>
                <span className='d-block white-space-pre text-black'>{data.info}</span>
              </p>
            )}
            {data.courseAuthors && data.courseAuthors.length > 0 && (
              <div className='my-4'>
                <p className='d-block mb-2 text-lg text-blue fw-400'>Faculty</p>
                <div>
                  <AuthorsList data={data.courseAuthors} />
                </div>
              </div>
            )}
            {data.subscriptionPlans && (
              <div className='my-4'>
                <p className='d-block mb-2 text-lg text-blue fw-400'>Subscription plans</p>
                <div>
                  <table className='table table-custom'>
                    <thead>
                      <tr>
                        <th scope='col' className='px-1'>
                          Subscriptions plans
                        </th>
                        <th scope='col'>Type</th>
                        <th scope='col'>Content</th>
                        <th scope='col'>Duration</th>
                        <th scope='col'>Validity</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.subscriptionPlans &&
                        data.subscriptionPlans.map(item => {
                          return (
                            <tr key={item.subscriptionId}>
                              <td>
                                <Link to={`/subscription/${item?.subscriptionId}/view`}>
                                  {item.title}
                                </Link>
                              </td>
                              <td className='text-nowrap'>
                                {SUBSCRIPTION_PLAN_TYPES[item.subscriptionType]}
                                {!!item.keyRequired && (
                                  <>
                                    <IconKey /> Key Required
                                  </>
                                )}
                              </td>
                              <td className='text-nowrap'>{item.courses} Courses</td>
                              <td className='text-nowrap'>{item.courseDuration} Hours</td>
                              <td className='text-nowrap'>
                                <div className='d-flex justify-content-between align-items-center'>
                                  <div>{item.planDuration} Days</div>
                                  <Link
                                    to={`/subscription/${item.subscriptionId}/view`}
                                    className='bg-white btn btn-sm ml-3 weight-600'
                                  >
                                    More
                                  </Link>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
            {data.courseLessons && data.courseLessons.length > 0 && (
              <div className='my-4'>
                <p className='d-block mb-2 text-lg text-blue fw-400'>Parts</p>
                <div>
                  <Accordion
                    data={data.courseLessons}
                    HeaderComponent={CourseLessonHeader}
                    BodyComponent={CourseLessonBody}
                    childrenKey='episodes'
                    className='parts-accordion'
                    childrenClassName='mb-2'
                    contentClassName='pt-0'
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='col-3 py-3'>
          <PublishPreview
            course={data}
            title={data.title}
            imageUrl={data.imageUrl}
            duration={data.duration}
            courseLessons={`${data.courseLessons.length} parts`}
            registeredUsers={data?.statistics?.registeredUsers?.total}
            courseAuthors={`${data.courseAuthors.length}`}
            courseId={match.params.id}
            getCourseData={getCourseData}
          />
          <ShareCourse onSuccess={getCourseData} course={data} />
          <TagsViewBlock className='course-view-tags' title='Admin Tags' tags={adminTags} />
          <TagsViewBlock className='course-view-tags' title='Learner Tags' tags={learnerTags} />
        </div>
      </div>
    </div>
  );
};

export default Courses;
