import { RegularCheckbox } from 'app/Main/components/Shared/RegularCheckbox';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import Button from 'shared/components/Button';
import { PlusIcon } from 'shared/components/Icons';
import SmallCounter from 'shared/components/SmallCounter';
import { SwitchIOSLike } from 'shared/components/SwitchIOSLike';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import { StepsContainerBlock } from './components/StepsContainerBlock';
import EditingSelect from 'shared/components/editingForms/EditingSelect';
import { adaptQuizConfigData, adaptedQuizConfigData } from './adapters';
import { QUIZ_STEP_COLORS } from 'shared/constants';
import NavigableHeaderRoutes from 'shared/components/NavigableHeaderRoutes';

export const CourseQuizConfiguration = ({ match, history, location }) => {
  const courseId = match.params.id;
  const { enqueueSnackbar } = useSnackbar();

  const [course, setCourse] = useState(location?.state?.course || {});
  const [selectedStepIndex, setSelectedStepIndex] = useState(null);
  const [questionGroups, setQuestionGroups] = useState([]);
  const [allQuestions, setAllQuestions] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [quizConfigObj, setQuizConfigObj] = useState({
    attempts: { isActive: false, value: 1 },
    passingThreshold: { isActive: true, value: 100, isAllSelected: true },
    timeLimit: { isActive: false, value: 1 },
    showWhatIsWrong: { isActive: true },
    terminateOnLeave: { isActive: false },
    steps: [],
  });

  const selectedStepData = quizConfigObj.steps[selectedStepIndex];
  const selectedStepId = selectedStepData?.id;
  const hasNoAnyStep = !quizConfigObj.steps.length;
  const headerRouteItems = [
    { label: 'Courses', path: '/courses/all' },
    { label: course.title, path: `/course/${courseId}/view` },
    { label: 'Quiz', path: `/course/${courseId}/quiz-view` },
    { label: 'Quiz Config' },
  ];

  const getCourseData = async () => {
    try {
      const { data } = await Api.get(`/courses/getcourse/${courseId}`);
      setCourse(data.data);
    } catch (err) {
      history.push('/no-access');
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const getQuestionGroups = async () => {
    try {
      const { data } = await Api.get(`/courses/${courseId}/question-groups`);
      setQuestionGroups(
        data.data
          .filter(({ id }) => id || id === 0)
          .map(group => ({ id: group.id, category: group.name })),
      );
      setAllQuestions(
        data.data
          .filter(({ id }) => id)
          .map(group => group.questions.map(q => ({ ...q, category: group.name })))
          .flat(),
      );
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const getQuizConfig = async () => {
    try {
      const { data } = await Api.get(`/courses/${courseId}/quiz`);
      if (data?.data) setQuizConfigObj(adaptedQuizConfigData(data.data));
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const handleSwitch = name => {
    setQuizConfigObj(prev => ({
      ...prev,
      [name]: { ...prev[name], isActive: !prev[name].isActive },
    }));
  };

  const handleSwitchStepConfig = name => {
    setQuizConfigObj(prev => ({
      ...prev,
      steps: prev.steps.toSpliced(selectedStepIndex, 1, {
        ...prev.steps[selectedStepIndex],
        [name]: !prev.steps[selectedStepIndex][name],
      }),
    }));
  };

  const handleToggleAllSelected = name => {
    setQuizConfigObj(prev => ({
      ...prev,
      [name]: {
        ...prev[name],
        isActive: !prev[name].isAllSelected || prev[name].isActive,
        isAllSelected: !prev[name].isAllSelected,
        value: !prev[name].isAllSelected ? 100 : prev[name].value,
      },
    }));
  };

  const handleDecreaseCount = (name, stepCount = 1) => {
    if (quizConfigObj[name].value <= stepCount) return;
    setQuizConfigObj(prev => {
      return {
        ...prev,
        [name]: {
          ...prev[name],
          value: parseFloat((prev[name].value - stepCount).toFixed(2)),
        },
      };
    });
  };

  const handleIncreaseCount = (name, stepCount = 1, maxValue) => {
    if (maxValue && quizConfigObj[name].value >= maxValue) return;
    setQuizConfigObj(prev => ({
      ...prev,
      [name]: {
        ...prev[name],
        value: parseFloat((prev[name].value + stepCount).toFixed(2)),
      },
    }));
  };

  const handleDecreaseStepCount = (name, stepCount = 1) => {
    setQuizConfigObj(prev => ({
      ...prev,
      steps: prev.steps.toSpliced(selectedStepIndex, 1, {
        ...selectedStepData,
        [name]: parseFloat((selectedStepData[name] - stepCount).toFixed(2)),
      }),
    }));
  };

  const handleIncreaseStepCount = (name, stepCount = 1) => {
    setQuizConfigObj(prev => ({
      ...prev,
      steps: prev.steps.toSpliced(selectedStepIndex, 1, {
        ...selectedStepData,
        [name]: parseFloat((selectedStepData[name] + stepCount).toFixed(2)),
      }),
    }));
  };

  const handleAddStepItem = () => {
    const addingStep = { id: Date.now(), category: null };
    setQuizConfigObj(prev => ({
      ...prev,
      steps: [
        ...prev.steps,
        {
          ...addingStep,
          assignedCategory: null,
          isRequired: true,
          timeLimit: 0.1,
          hasTimeLimit: false,
          isActivePickQuestionRandomly: true,
          pickQuestionId: null,
        },
      ],
    }));
    setSelectedStepIndex(quizConfigObj.steps.length);
    requestAnimationFrame(() => {
      const stepsContainer = document.getElementById('add_steps_draggable');
      if (stepsContainer) {
        stepsContainer.scrollLeft = stepsContainer.scrollWidth;
      }
    });
  };

  const handleSelectStep = index => {
    setSelectedStepIndex(index);
  };

  const handleRemoveStep = index => {
    const newSteps = quizConfigObj.steps.toSpliced(index, 1);
    setQuizConfigObj({ ...quizConfigObj, steps: newSteps });
  };

  const handleChangeStepData = ({ name, value }, index) => {
    setQuizConfigObj(prev => {
      let existSameCategory, existColor;
      const usedCategoriesObj = {};
      if (name === 'assignedCategory') {
        prev.steps.forEach(step => {
          if (!usedCategoriesObj[step.assignedCategory]) {
            usedCategoriesObj[step.assignedCategory] = step.assignedCategory;
          }
        });
        existSameCategory = prev.steps.find(step => step.assignedCategory === value);
        existColor = existSameCategory
          ? existSameCategory.color
          : QUIZ_STEP_COLORS[Object.keys(usedCategoriesObj).length];
      }
      return {
        ...prev,
        steps: prev.steps.toSpliced(index, 1, {
          ...prev.steps[index],
          [name]: value,
          color: existColor,
        }),
      };
    });
  };

  const goBack = () => history.push(`/course/${courseId}/quiz-view`);

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      const body = adaptQuizConfigData({ ...quizConfigObj });

      await Api.post(`/courses/${courseId}/quiz/add`, body);
      await getQuizConfig();
      enqueueSnackbar('Successfully saved', { variant: 'success' });
      history.push(`/course/${courseId}/quiz-view`);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (!course?.id) getCourseData();
  }, []);

  useEffect(() => {
    if (courseId) {
      getQuestionGroups();
      getQuizConfig();
    }
  }, [courseId]);

  useEffect(() => {
    if (!quizConfigObj.steps[selectedStepIndex]) {
      setSelectedStepIndex(0);
    }
  }, [quizConfigObj]);

  const groupQuestions = allQuestions.filter(
    q => q.category === selectedStepData?.assignedCategory,
  );

  return (
    <div className='quiz_page_container'>
      <div className='users-header d-flex justify-content-between align-items-center'>
        <NavigableHeaderRoutes items={headerRouteItems} />
      </div>
      <form onSubmit={() => {}} className='col-8 p-0 user-creation-form'>
        <div className='quiz_content_block'>
          <h2 className='title'>
            Quiz for: <i>" </i>
            {course.title}
            <i>"</i>
          </h2>
          <div className='block regular_block'>
            <h4>Quiz Properties</h4>
            <ul className='p-0 mb-0'>
              <li className='list-default'>
                <div className='d-flex align-items-center'>
                  <div className='col-4 pl-0'>
                    <label className='m-0'>Attempts Number:</label>
                  </div>
                  <div className='switch_container'>
                    <SwitchIOSLike
                      name='attempts'
                      checkedState={quizConfigObj?.attempts?.isActive}
                      handleChange={() => handleSwitch('attempts')}
                    />
                  </div>
                  <div className='right_block'>
                    <SmallCounter
                      disabled={!quizConfigObj?.attempts?.isActive}
                      count={quizConfigObj?.attempts?.value}
                      handleDecreaseCount={() => handleDecreaseCount('attempts')}
                      handleIncreaseCount={() => handleIncreaseCount('attempts')}
                    />
                  </div>
                </div>
              </li>
              <li className='list-default'>
                <div className='d-flex align-items-center'>
                  <div className='col-4 pl-0'>
                    <label className='m-0'>Passing Threshold:</label>
                  </div>
                  <div className='switch_container'>
                    <SwitchIOSLike
                      name='passingThreshold'
                      checkedState={quizConfigObj.passingThreshold.isActive}
                      handleChange={() => {
                        if (
                          !(
                            quizConfigObj.passingThreshold.isActive &&
                            quizConfigObj.passingThreshold.isAllSelected
                          )
                        ) {
                          handleSwitch('passingThreshold');
                        }
                      }}
                    />
                  </div>
                  <div className='right_block'>
                    <SmallCounter
                      disabled={
                        !quizConfigObj.passingThreshold.isActive ||
                        quizConfigObj.passingThreshold.isAllSelected
                      }
                      count={quizConfigObj.passingThreshold.value}
                      handleDecreaseCount={() => handleDecreaseCount('passingThreshold', 5)}
                      handleIncreaseCount={() => handleIncreaseCount('passingThreshold', 5, 100)}
                      measurementUnit='%'
                      stepCount={5}
                    />
                    <RegularCheckbox
                      isChecked={quizConfigObj.passingThreshold.isAllSelected}
                      handler={() => handleToggleAllSelected('passingThreshold')}
                      label='All'
                    />
                  </div>
                </div>
              </li>
              <li className='list-default'>
                <div className='d-flex align-items-center'>
                  <div className='col-4 pl-0'>
                    <label className='m-0'>Time Limit:</label>
                  </div>
                  <div className='switch_container'>
                    <SwitchIOSLike
                      name='timeLimit'
                      checkedState={quizConfigObj.timeLimit.isActive}
                      handleChange={() => handleSwitch('timeLimit')}
                    />
                  </div>
                  {quizConfigObj.timeLimit.isActive ? (
                    <div className='right_block'>
                      <SmallCounter
                        disabled={!quizConfigObj.timeLimit.isActive}
                        count={quizConfigObj.timeLimit.value}
                        handleDecreaseCount={() => handleDecreaseCount('timeLimit')}
                        handleIncreaseCount={() => handleIncreaseCount('timeLimit')}
                        measurementUnit=' min'
                      />
                    </div>
                  ) : (
                    <span>No Limit</span>
                  )}
                </div>
              </li>
              <li className='list-default'>
                <div className='d-flex align-items-center'>
                  <div className='col-4 pl-0'>
                    <label className='m-0'>"What did I do wrong?":</label>
                  </div>
                  <div className='switch_container'>
                    <SwitchIOSLike
                      name='showWhatIsWrong'
                      checkedState={quizConfigObj.showWhatIsWrong.isActive}
                      handleChange={() => handleSwitch('showWhatIsWrong')}
                    />
                  </div>
                  <div className='right_block'>
                    <span>{quizConfigObj.showWhatIsWrong.isActive ? 'Active' : 'Inactive'}</span>
                  </div>
                </div>
              </li>
              <li className='list-default'>
                <div className='d-flex align-items-center'>
                  <div className='col-4 pl-0'>
                    <label className='m-0'>Terminate on Leave:</label>
                  </div>
                  <div className='switch_container'>
                    <SwitchIOSLike
                      name='terminateOnLeave'
                      checkedState={quizConfigObj.terminateOnLeave.isActive}
                      handleChange={() => handleSwitch('terminateOnLeave')}
                    />
                  </div>
                  <div className='right_block'>
                    <span>{quizConfigObj.terminateOnLeave.isActive ? 'Yes' : 'No'}</span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div className='block step_block'>
            <h4>Quiz Flow</h4>
            {hasNoAnyStep ? (
              <div className='d-flex justify-content-center flex-column align-items-center empty_step_items_block'>
                <Button onClick={handleAddStepItem} className='add_step_btn'>
                  <PlusIcon />
                </Button>
                <p>Add quiz steps</p>
              </div>
            ) : (
              <StepsContainerBlock
                handleSelectStep={handleSelectStep}
                selectedStepId={selectedStepId}
                selectedStepIndex={selectedStepIndex}
                items={quizConfigObj.steps}
                setQuizConfigObj={setQuizConfigObj}
                handleAddStepItem={handleAddStepItem}
                setSelectedStepIndex={setSelectedStepIndex}
                handleRemoveStep={handleRemoveStep}
                categories={questionGroups}
              />
            )}
          </div>
          {!!selectedStepId && !hasNoAnyStep && (
            <div className='block regular_block'>
              <h4>Step {selectedStepIndex + 1} Properties</h4>
              <ul className='p-0 mb-0'>
                <li className='list-default'>
                  <div className='d-flex align-items-center'>
                    <div className='col-3 pl-0'>
                      <label className='m-0'>Assigned Category *:</label>
                    </div>
                    <EditingSelect
                      name='assignedCategory'
                      onChange={({ target }) => handleChangeStepData(target, selectedStepIndex)}
                      value={selectedStepData?.assignedCategory || ''}
                      items={questionGroups}
                      useProperty='category'
                      displayProperty='category'
                      required={true}
                      editing={true}
                      label={'Choose category'}
                    />
                  </div>
                </li>
                <li className='list-default'>
                  <div className='d-flex align-items-center'>
                    <div className='col-4 pl-0'>
                      <label className='m-0'>Required:</label>
                    </div>
                    <div className='switch_container'>
                      <SwitchIOSLike
                        name='isRequired'
                        checkedState={selectedStepData?.isRequired}
                        handleChange={() => handleSwitchStepConfig('isRequired')}
                      />
                    </div>
                    <div className='right_block'>
                      <span>{selectedStepData?.isRequired ? 'Yes' : 'No'}</span>
                    </div>
                  </div>
                </li>
                <li className='list-default'>
                  <div className='d-flex align-items-center'>
                    <div className='col-4 pl-0'>
                      <label className='m-0'>Time Limit:</label>
                    </div>
                    <div className='switch_container'>
                      <SwitchIOSLike
                        name='timeLimit'
                        checkedState={selectedStepData?.hasTimeLimit}
                        handleChange={() => handleSwitchStepConfig('hasTimeLimit')}
                      />
                    </div>
                    <div className='right_block'>
                      {selectedStepData?.hasTimeLimit ? (
                        <>
                          <SmallCounter
                            disabled={!selectedStepData?.hasTimeLimit}
                            count={selectedStepData?.timeLimit}
                            handleDecreaseCount={() => handleDecreaseStepCount('timeLimit', 0.1)}
                            handleIncreaseCount={() => handleIncreaseStepCount('timeLimit', 0.1)}
                            stepCount={0.1}
                            measurementUnit=' min'
                          />
                          <span>Min</span>
                        </>
                      ) : (
                        <span>No Limit</span>
                      )}
                    </div>
                  </div>
                </li>
                <li className='list-default'>
                  <div className='d-flex align-items-center'>
                    <div className='col-4 pl-0'>
                      <label className='m-0'>Pick Question Randomly:</label>
                    </div>
                    <div className='switch_container'>
                      <SwitchIOSLike
                        name='isActivePickQuestionRandomly'
                        checkedState={!!selectedStepData?.isActivePickQuestionRandomly}
                        handleChange={() => handleSwitchStepConfig('isActivePickQuestionRandomly')}
                      />
                    </div>
                    <div className='right_block w-100'>
                      <EditingSelect
                        disabledType={selectedStepData?.isActivePickQuestionRandomly && 'opc_4'}
                        disabled={selectedStepData?.isActivePickQuestionRandomly}
                        name='questionId'
                        onChange={({ target }) => handleChangeStepData(target, selectedStepIndex)}
                        value={selectedStepData?.questionId || ''}
                        items={groupQuestions || []}
                        useProperty='id'
                        displayProperty='title'
                        required={true}
                        editing={true}
                        label='Select a question'
                      />
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          )}
          {hasNoAnyStep && (
            <div className='block regular_block disabled'>
              <h4>Step {selectedStepId} Properties</h4>
              <ul className='p-0 mb-0'>
                <li className='list-default'>
                  <div className='d-flex align-items-center'>
                    <div className='col-3 pl-0'>
                      <label className='m-0'>Assigned Category *:</label>
                    </div>
                    <EditingSelect
                      name='assignedCategory'
                      onChange={({ target }) => handleChangeStepData(target, selectedStepIndex)}
                      value={selectedStepData?.assignedCategory || ''}
                      items={questionGroups}
                      useProperty='category'
                      displayProperty='category'
                      required={true}
                      editing={true}
                      label={'Choose category'}
                      disabled
                    />
                  </div>
                </li>
                <li className='list-default'>
                  <div className='d-flex align-items-center'>
                    <div className='col-4 pl-0'>
                      <label className='m-0'>Required:</label>
                    </div>
                    <div className='switch_container'>
                      <SwitchIOSLike
                        name='isRequired'
                        checkedState={selectedStepData?.isRequired}
                        handleChange={() => handleSwitchStepConfig('isRequired')}
                        disabled
                      />
                    </div>
                    <div className='right_block'>
                      <span>{selectedStepData?.isRequired ? 'Yes' : 'No'}</span>
                    </div>
                  </div>
                </li>
                <li className='list-default'>
                  <div className='d-flex align-items-center'>
                    <div className='col-4 pl-0'>
                      <label className='m-0'>Time Limit:</label>
                    </div>
                    <div className='switch_container'>
                      <SwitchIOSLike
                        name='timeLimit'
                        checkedState={selectedStepData?.hasTimeLimit}
                        handleChange={() => handleSwitchStepConfig('hasTimeLimit')}
                        disabled
                      />
                    </div>
                    <div className='right_block'>
                      {selectedStepData?.hasTimeLimit ? (
                        <SmallCounter
                          disabled={!selectedStepData?.hasTimeLimit}
                          count={selectedStepData?.timeLimit}
                          handleDecreaseCount={() => handleDecreaseStepCount('timeLimit', 0.1)}
                          handleIncreaseCount={() => handleIncreaseStepCount('timeLimit', 0.1)}
                          stepCount={0.1}
                        />
                      ) : (
                        <span>No Limit</span>
                      )}
                    </div>
                  </div>
                </li>
                <li className='list-default'>
                  <div className='d-flex align-items-center'>
                    <div className='col-4 pl-0'>
                      <label className='m-0'>Pick Question Randomly:</label>
                    </div>
                    <div className='switch_container'>
                      <SwitchIOSLike
                        name='isActivePickQuestionRandomly'
                        checkedState={selectedStepData?.isActivePickQuestionRandomly}
                        handleChange={() => handleSwitchStepConfig('isActivePickQuestionRandomly')}
                        disabled
                      />
                    </div>
                    <div className='right_block w-100'>
                      <EditingSelect
                        disabledType={selectedStepData?.isActivePickQuestionRandomly && 'opc_4'}
                        name='pickQuestionId'
                        onChange={({ target }) => handleChangeStepData(target, selectedStepIndex)}
                        value={selectedStepData?.pickQuestionId}
                        items={
                          allQuestions.filter(
                            q => q.category === selectedStepData?.assignedCategory,
                          ) || []
                        }
                        useProperty='id'
                        displayProperty='title'
                        required={true}
                        editing={true}
                        disabled
                        label='Select a question'
                      />
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          )}
          <div className='d-flex justify-content-end pt-4'>
            <Button
              disabled={isSubmitting}
              onClick={handleSubmit}
              className='btn btn-primary px-4 fz-13 font-weight-bold'
            >
              Save
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};
