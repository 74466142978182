import React from 'react';
import ButtonLoading from 'shared/components/ButtonLoading';

const FooterButtons = ({
  onGoBackStep,
  onGoNextStep,
  activeStep,
  isLastStep,
  fetching,
  form,
  onSaveDraft,
  canSaveDraft,
}) => {
  return (
    <div
      className={`${isLastStep ? 'col-12 pb-5' : 'col-9'} px-4 d-flex ${!isLastStep &&
        'new-wizard-footer'} ${isLastStep ? 'justify-content-center' : 'justify-content-between'}`}
    >
      <button
        className='btn btn-blue min-100 fz-12 font-weight-bold py-2'
        type='button'
        onClick={onGoBackStep}
        disabled={activeStep === 0}
      >
        Back
      </button>
      {!isLastStep && (
        <div>
          {canSaveDraft && (
            <button
              className='btn btn-outline-blue min-100 fz-12 mr-3 btn-border-2 font-weight-bold py-2'
              type='button'
              onClick={onSaveDraft}
              disabled={fetching || !form.name}
            >
              {fetching === 1 ? <ButtonLoading /> : 'Save Draft'}
            </button>
          )}
          <button
            className='btn btn-blue min-100 fz-12 font-weight-bold py-2'
            type='submit'
            onClick={onGoNextStep}
            disabled={fetching}
          >
            {fetching === 2 ? <ButtonLoading /> : 'Next'}
          </button>
        </div>
      )}
    </div>
  );
};

export default FooterButtons;
