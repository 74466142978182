import React, { memo } from 'react';

const BarChart = ({ className, data, isVertical }) => {
  const colors = ['#0099cb', '#91c5ce', '#131f6b', '#8d8d8d', '#b5bef6'];

  const getBgColor = index => ({
    backgroundColor: colors[index],
  });

  return (
    <div className={`bar-chart-area d-flex ${className || ''} ${isVertical ? 'is-vertical' : ''}`}>
      <div className='bar-chart mr-4 d-flex justify-content-between'>
        {data &&
          data.map(({ name, value, trueValue }, index) => (
            <div
              key={name + index}
              className='d-flex flex-column align-items-center justify-content-end'
            >
              <div className='bar h-100'>
                <div className='pulp' style={{ ...getBgColor(index), height: `${value}%` }} />
              </div>
              <div className='percent'>{trueValue || `${value}%`}</div>
            </div>
          ))}
      </div>
      <div className='names col'>
        {data &&
          data.map(({ name }, index) => (
            <div key={name + index} className='mb-3 text-nowrap'>
              <span className='color-box' style={getBgColor(index)} />
              {name}
            </div>
          ))}
      </div>
    </div>
  );
};

export default memo(BarChart);
