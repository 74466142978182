import { createReducer } from 'utils/reduxHelpers';

const ON_VIEWER_OPTIONS = '@@viwer/ON_VIEWER_OPTIONS';

export const onSetViewerOptions = payload => ({ type: ON_VIEWER_OPTIONS, payload });

const viewerHandlers = {
  [ON_VIEWER_OPTIONS]: (state, action) => action.payload,
};

export const viewerInitialState = {
  canBeActive: {},
};

export const setCanBeActive = (type, bool) => async (dispatch, getState) => {
  const viewerOptions = { ...getState().viewerOptions };
  if (viewerOptions.canBeActive[type] === bool) return;
  viewerOptions.canBeActive[type] = bool;
  dispatch(onSetViewerOptions(viewerOptions));
};

export const viewerReducer = createReducer(viewerInitialState, viewerHandlers);
