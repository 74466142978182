import React from 'react';

export const RegularCheckbox = ({ size = 'medium', label, isChecked, handler }) => {
  return (
    <label className='regular-checkbox-container'>
      <input type='checkbox' className={`${size}`} checked={isChecked} onChange={handler} />
      {label}
    </label>
  );
};
