import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { backRolesFrontMap, globalPermissions } from './permissions/backRoles';
import { permissionsWithRolesNames } from './permissions/frontRoles';
import PageTitle from '../shared/components/PageTitle';

export let userPermissions = {};

export const setUserAppPermissionsWithRoleName = rolePer => {
  const roleName = rolePer?.length ? rolePer[0].title : '';
  const permissionsToAdd = permissionsWithRolesNames[roleName]?.add;
  permissionsToAdd?.forEach(item => {
    userPermissions[item] = true;
  });
};

export const removeUserAppPermissionsWithRoleName = rolePer => {
  const roleName = rolePer?.length ? rolePer[0].title : '';
  const permissionsToRemove = permissionsWithRolesNames[roleName]?.remove;
  permissionsToRemove?.forEach(item => {
    delete userPermissions[item];
  });
};

export const setUserAppPermissionsWithBackRoles = rolePer => {
  let activePermissions = [...globalPermissions];
  Object.keys(rolePer).forEach(key => {
    if (!backRolesFrontMap[key]) return;
    const { edit, view } = backRolesFrontMap[key];
    const fullAccess = rolePer[key] === 'full';
    const currentPermission = fullAccess ? [...view, ...edit] : [...view];
    activePermissions = [...activePermissions, ...currentPermission];
  });
  activePermissions.forEach(item => {
    userPermissions[item] = true;
  });
};

export const hasAccess = name => {
  return !!userPermissions[name];
};

export const PRoute = props => {
  if (props.access && !hasAccess(props.access)) return <Redirect to='/' />;
  return <WithTitleRoute {...props} />;
};

export const clearPermissions = () => {
  userPermissions = {};
};

export const WithTitleRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => (
        <>
          <PageTitle match={props.match} />
          <Component {...props} />
        </>
      )}
    />
  );
};
