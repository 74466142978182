import React from 'react';

const FeedCheckbox = ({ index, data, onChange, answers, isDisabled, isAnswered }) => {
  const items = data?.labels?.map(item => ({ name: item, id: item }));

  const handleChange = e => {
    onChange({ id: index, value: e.target.checked });
  };

  return (
    <li className='d-flex'>
      <div className='col-6 pl-0 pt-1'>
        <label className='m-0'>{data.question}</label>
      </div>
      <div className='d-flex'>
        {!isAnswered
          ? items &&
            items.map(item => (
              <div key={item.id} className='d-flex align-items-center mr-3'>
                <input
                  id={`${item.id}_check`}
                  type='checkbox'
                  name='feedcheck'
                  checked={Boolean(answers[index])}
                  onChange={handleChange}
                  disabled={isDisabled}
                />
                <label htmlFor={`${item.id}_check`} className={`ml-2 mb-0 weight-200`}>
                  {item.name}
                </label>
              </div>
            ))
          : items &&
            items.map(item => (
              <div key={item.id} className='d-flex align-items-center mr-3'>
                {answers[index] ? 'Yes' : 'No'}
              </div>
            ))}
      </div>
    </li>
  );
};

export default FeedCheckbox;
