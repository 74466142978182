export const BUTTON_SIZES = {
  small: 'sm',
  medium: 'md',
  medium2: 'md2',
  big: 'bg',
};

export const BUTTON_TYPES = {
  delete_1: 'btn-delete-1',
  delete_2: 'btn-delete-2',
  disabled: 'btn-disabled',
  primary: 'btn-primary',
};

export const WARNING_DIALOGUE_DETAILS = {
  assign: {
    title: 'Confirmation Needed',
    description:
      'Assigning the key to Entity will result in revoking the access currently assigned to user(s), leading to loss of access to all courses previously granted through this key.',
    key: 'assign',
    confirmBtnType: 'btn-confirm-primary',
  },
  unassign: {
    title: 'Confirmation Needed',
    description:
      'This key is assigned to member(s) of current Entity. Unassigning key from Entity will remove the key from all members, which will result loosing accesses to all courses that were granted them by this key.',
    key: 'unassign',
    confirmBtnType: 'btn-confirm-danger',
  },
  disable: {
    title: 'Confirmation Needed',
    description:
      'This key is assigned to more than one user. Disabling multi assignment possibility will change this key to single usage. All currently assigned users will be removed except the first in the list, which will result loosing accesses to all courses that were granted them by this key.',
    key: 'disable',
    confirmBtnType: 'btn-confirm-danger',
  },
  decrease: {
    title: 'Confirmation Needed',
    description:
      'Decreased Number of Assignments is less than assigned users. Decreasing it less than assigned users will remove users from the end of list, which will result loosing accesses to all courses that were granted to them by this key.',
    key: 'decrease',
    confirmBtnType: 'btn-confirm-danger',
  },
  disableValidPeriod: {
    title: 'Confirmation Needed',
    description: `This key is assigned to more than one user. Disabling Validity Period is going to disable also multi assignment possibility and change this key to single usage. 
      
All currently assigned users will be removed except the first in the list, which will result loosing accesses to all courses that were granted them by this key.`,
    key: 'disableValidPeriod',
    confirmBtnType: 'btn-confirm-danger',
  },
  disableNumberOfSits: {
    title: 'Confirmation Needed',
    description: `This key is assigned to more than one user. Disabling the Number of Assignments is going to disable also multi assignment possibility and change this key to single usage. 
      
All currently assigned users will be removed except the first in the list, which will result loosing accesses to all courses that were granted them by this key.`,
    key: 'disableNumberOfSits',
    confirmBtnType: 'btn-confirm-danger',
  },
};

export const QUIZ_STEP_COLORS = [
  '#54D8A8',
  '#545AD8',
  '#D8547C',
  '#ECAA2B',
  '#5499D8',
  '#D854CB',
  '#9747FD',
  '#FF736A',
  '#39E6F1',
  '#F87933',
];

export const ENGLISH_UPPERCASE_LETTERS = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
];

export const PRODUCT_PAGE_STATUSES = {
  1: 'In Progress',
  2: 'Not Started',
  3: 'Passed',
};

export const IMAGE_RESIZE_WIDTHS = {
  default: [150, 400, 1000],
  eventBanner: [150, 1000, 1200],
};
