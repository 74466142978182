import React from 'react';

import AppChart from 'shared/components/Chart';
import { isDeepEqual } from 'utils/appHelpers';

const customPlugins = {
  datalabels: {
    color: '#FFFFFF',
    textAlign: 'center',
    font: {
      lineHeight: 1.6,
      size: 14,
    },
    formatter: value => (value ? value + '%' : ''),
  },
};

const SingleAndMultiSelectQuestion = React.memo(function(props) {
  const { question } = props;

  const answerScores = question.textAnswers ? question.textAnswers.map(answer => answer.score) : [];
  const sumOfScores = answerScores.reduce((acc, score) => {
    return acc + Number(score);
  }, 0);
  const percentAnswerScores = answerScores.map(score =>
    Number(((score / sumOfScores) * 100).toFixed(0)),
  );
  const labels = question.textAnswers ? question.textAnswers.map(answer => answer.text) : [];
  const chartProps = {
    id: `question${question.QuestionID}chart`,
    data: percentAnswerScores,
    labels: labels,
  };

  return (
    <div className='survey-question-card'>
      <p className='questionText'>{question.questionTitle}</p>
      <AppChart customPlugins={customPlugins} width={300} {...chartProps} />
      <div className='pr-0 d-flex flex-column justify-content-between'>
        <div className='d-flex justify-content-center'>
          <ul className='col-12 mb-0 pl-3 status-explanation-list font-size-13'>
            {labels.map((item, index) => (
              <li key={item + index}>
                <span className='d-block d-flex answer-row'>
                  {item}: <span className='font-weight-bold'>{percentAnswerScores[index]}%</span>
                </span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}, isDeepEqual);

export default SingleAndMultiSelectQuestion;
