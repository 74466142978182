import React from 'react';
import EmptyView from '../EmptyView';
import { IconImage } from 'shared/components/Icons';
import ResponsiveImage from 'shared/components/ResponsiveImage';

const TypeImage = ({ lesson }) => {
  return (
    <>
      <div className='video-area flex-1 d-flex justify-content-center'>
        <ResponsiveImage imgUrl={lesson.contentUrl} height='100%' alt='lesson' />
      </div>
      {!lesson.contentUrl && (
        <div className='video-area flex-1 d-flex justify-content-center h-100'>
          <EmptyView text='No image file specified.' Icon={IconImage} />
        </div>
      )}
    </>
  );
};

export default TypeImage;
