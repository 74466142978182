/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { useSnackbar } from 'notistack';
import { SwitchIOSLike } from 'shared/components/SwitchIOSLike';
import WizardImageUpload from 'shared/components/WizardImageUpload';
import EditingInput from 'shared/components/editingForms/EditingInput';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import FormNoFrame from 'assets/marketing/spotlight-form-empty-frame.png';
import ResponsiveImage from 'shared/components/ResponsiveImage';

export const SpotlightGeneralStep = ({ frames, form, setForm }) => {
  const { enqueueSnackbar } = useSnackbar();
  const spotlightImg = form?.imageUrl;

  const handleSwitchAnimation = ({ target }) => {
    setForm({ ...form, animation: target.checked ? 1 : 0 });
  };
  const handleSelectFrame = frame => {
    setForm({ ...form, selectedFrameId: frame.id, selectedFrame: frame });
  };

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setForm({ ...form, [name]: value });
  };
  const onImageUpload = async e => {
    try {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append('file', file);
      formData.append('isdraft', 'true');
      const res = await Api.post('/upload', formData);
      setForm({
        ...form,
        uploadImageId: res?.data?.data?.id || null,
        imageUrl: res?.data?.data?.url || null,
      });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const handleImageLoad = e => {
    const img = e.target;
    if (img.naturalHeight > img.naturalWidth) {
      img.style.height = '100%';
      img.style.width = 'auto';
    } else {
      img.style.height = 'auto';
      img.style.width = '100%';
    }
  };

  return (
    <ul className='pl-6 mb-0'>
      <li className='d-flex align-items-center'>
        <div className='col-4 pl-0'>
          <label className='m-0'>Name*:</label>
        </div>
        <EditingInput
          type='text'
          name='name'
          value={form.name}
          onChange={handleChange}
          className='p-0'
          placeholder='Enter name'
          required
          editing={true}
        />
      </li>
      <li className='d-flex align-items-center'>
        <div className='col-4 pl-0'>
          <label className='m-0'>Select Frame*:</label>
        </div>
        <div className='form-frames-block'>
          {frames.map(item => {
            return (
              <div
                key={item.id}
                onClick={() => handleSelectFrame(item)}
                className={`frame-item ${item?.isNoFrame && 'no-frame'} ${item.id ===
                  form.selectedFrameId && 'active'}`}
              >
                {item?.isNoFrame && (
                  <>
                    <img className='img' alt='No frame' src={FormNoFrame} />
                    <span className='no-frame-text'>No Frame</span>
                  </>
                )}
                {!item?.isNoFrame && (
                  <ResponsiveImage
                    className='img'
                    alt='frame'
                    imgUrl={item.imageUrl}
                    onLoad={handleImageLoad}
                  />
                )}
              </div>
            );
          })}
        </div>
      </li>
      {!!form.selectedFrame?.hasFullName && (
        <>
          <li className='d-flex align-items-center'>
            <div className='col-4 pl-0'>
              <label className='m-0'>Name Surname*:</label>
            </div>
            <EditingInput
              type='text'
              name='fullName'
              value={form.fullName || ''}
              onChange={handleChange}
              className='p-0'
              placeholder='Enter Name and Surname'
              required
              editing={true}
            />
          </li>
        </>
      )}
      <WizardImageUpload
        image={spotlightImg}
        title='Upload Image'
        colSize='col-4'
        isRequired
        onImageUpload={onImageUpload}
      />
      <li className='list-default'>
        <div className='d-flex align-items-center'>
          <div className='col-4 pl-0'>
            <label className='m-0'>Animation:</label>
          </div>
          <div className='switch_container'>
            <SwitchIOSLike
              disabled={false} // check if any animation available to disable the enabling for it
              checkedState={!!form.animation}
              handleChange={handleSwitchAnimation}
            />
          </div>

          <span className='form-switch-label-regular h_31 d-flex align-items-center'>
            {form.animation ? 'Enabled' : 'Disabled'}
          </span>
        </div>
      </li>
    </ul>
  );
};
