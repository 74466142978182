import React, { useState } from 'react';
import ReactModal from 'react-modal';
import { hasAccess } from 'utils/permissionHelper';
import { IconClose } from 'shared/components/Icons';

export const getPrePostSliceItems = (index, pre, post) => {
  const preSlices = new Array(pre)
    .fill(index)
    .map((r, i) => r - i)
    .reverse();
  const postSlices = new Array(post).fill(index).map((r, i) => r + i + 2);
  return { preSlices, postSlices };
};

export const getImagesPrePostSlices = images => {
  let result = new Set();
  images.forEach((img, index) => {
    const hasGeniusShapes = !!img.geniusAIDataList?.length;
    if (!hasGeniusShapes) return;
    const { preSlices, postSlices } = getPrePostSliceItems(
      index - 1,
      img.preSlices,
      img.postSlices,
    );
    result = new Set([...result, ...preSlices, ...postSlices]);
  });
  return [...result];
};

export const getPrimarySlices = images => {
  const result = new Set();
  images.forEach((img, index) => {
    const hasGeniusShapes = !!img.geniusAIDataList?.length;
    if (hasGeniusShapes || (hasGeniusShapes && (!!img.preSlices || !!img.postSlices))) {
      result.add(index);
    }
  });
  return [...result];
};

export const getImagesSortedSlices = images => {
  const all = [];
  images.forEach((img, index) => {
    const hasGeniusShapes = !!img.geniusAIDataList?.length;
    if (!hasGeniusShapes) return;
    const { preSlices, postSlices } = getPrePostSliceItems(
      index - 1,
      img.preSlices,
      img.postSlices,
    );
    all.push([...new Set([...preSlices, index, ...postSlices])]);
  });
  return all;
};

const PrePostSlices = ({ onSaveSlice, activeImage, imagesCount, playing, removeMode }) => {
  const [activeTab, setActiveTab] = useState('preSlices');
  const [modalState, setModalState] = useState(false);
  const [imageData, setImageData] = useState();

  const onOpenModal = () => {
    setImageData({ index: activeImage.index, image: { ...activeImage } });
    setModalState(true);
  };

  const onSliceChange = e => {
    const tempData = { ...imageData };
    imageData.image[activeTab] = e.target.value;
    setImageData(tempData);
  };

  const onChangeSliceNumber = increase => {
    const tempData = { ...imageData };
    imageData.image[activeTab] += increase ? 1 : -1;
    setImageData(tempData);
  };

  const onSaveHandle = () => {
    setModalState(false);
    onSaveSlice({ ...imageData });
  };

  const currentValue = imageData ? imageData.image[activeTab] : 0;

  const minuseButtonDisabled = currentValue === 0;
  const addButtonDisabled =
    (activeTab === 'postSlices' && imageData?.index + currentValue >= imagesCount) ||
    (activeTab === 'preSlices' && imageData?.index - currentValue <= 0);

  const getChosenSlices = () => {
    const { preSlices, postSlices } = getPrePostSliceItems(
      imageData.index,
      imageData.image.preSlices,
      imageData.image.postSlices,
    );
    const slices = [...preSlices, ...postSlices];
    return slices.join(',');
  };

  const hasGeniusShapes = !!activeImage?.geniusAIDataList?.length;

  return (
    <div className='pre-post-slices'>
      {!playing && hasAccess('gaid_create') && (
        <button
          className='btn add-slice-btn no_set_width'
          onClick={removeMode ? () => {} : onOpenModal}
          disabled={!hasGeniusShapes}
        >
          {removeMode ? 'Double Click to Remove Shapes' : 'Pre/Post Slices Editing'}
        </button>
      )}
      {imageData && (
        <ReactModal isOpen={modalState && !!imageData} className='custom-modal'>
          <div className='modal-box pre-post-modal d-flex flex-column justify-content-between h-auto'>
            <div className='d-flex align-items-center justify-content-between mb-3'>
              <div className='m-header text-center'>
                <h5 className='mb-0'>Pre/Post Slices Editing</h5>
              </div>
              <button onClick={() => setModalState(false)} className='btn p-0 modal-close-btn'>
                <IconClose />
              </button>
            </div>
            <p className='pr-3'>
              Please, indicate how many pre-slices you want to mark for{' '}
              <b>Slice {imageData.index + 1}</b>.
            </p>
            <div className='mt-1'>
              <div className='d-flex'>
                <button
                  onClick={() => setActiveTab('preSlices')}
                  className={`btn slice-tab ${activeTab === 'preSlices' ? 'active' : ''}`}
                >
                  Pre Slice
                </button>
                <button
                  onClick={() => setActiveTab('postSlices')}
                  className={`btn slice-tab ml-3 ${activeTab === 'postSlices' ? 'active' : ''}`}
                >
                  Post Slice
                </button>
              </div>
              <div className='mt-2 slice-form d-flex align-items-center justify-content-between'>
                <button
                  disabled={minuseButtonDisabled}
                  onClick={() => onChangeSliceNumber(false)}
                  className='btn btn-slice-change'
                >
                  -
                </button>
                <div>
                  <input
                    type='number'
                    value={currentValue}
                    className='form-control'
                    onChange={onSliceChange}
                  />
                </div>
                <button
                  disabled={addButtonDisabled}
                  onClick={() => onChangeSliceNumber(true)}
                  className='btn btn-slice-change'
                >
                  +
                </button>
              </div>
              <p className='mt-2 fz-12 mb-0'>
                Chosen Slices: <b>{getChosenSlices()}</b>
              </p>
            </div>
            <div className='m-footer text-right mt-3 px-4'>
              <button className={`btn-primary btn px-4`} onClick={onSaveHandle}>
                Save
              </button>
            </div>
          </div>
        </ReactModal>
      )}
    </div>
  );
};

export default PrePostSlices;
