import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';

import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import { SUBSCRIPTION_STATUS, modeStates } from 'app/Main/configs';

import { IconSearch } from 'shared/components/Icons';
import CourseLessonsRow from '../../CourseNew/components/CourseLessonsRow';
import Loading from 'shared/components/Loading';
import { SwitchIOSLike } from 'shared/components/SwitchIOSLike';
import { DEFAULT_IMG_URLS } from 'configs';

const getFiteredCourses = (courses, { showDraft, activeIds, search }) => {
  let tempCourses = [...courses];
  if (!showDraft) {
    tempCourses = tempCourses.filter(
      course => course.mode !== modeStates.DRAFT || activeIds.includes(course.courseId),
    );
  }
  if (search) {
    tempCourses = tempCourses.filter(
      course =>
        course.title.toLowerCase().includes(search.trim()) ||
        course.info.toLowerCase().includes(search.trim()),
    );
  }

  return tempCourses;
};

const SubsCoursesStep = ({ data, setData, isInUse, subscriptionStatus, form }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [search, setSearch] = useState('');
  const [showDraft, setShowDraft] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [activeIds, setActiveIds] = useState(
    data.activeCourses ? data.activeCourses.map(i => i.courseId) : [],
  );

  const isMarketingType = form.subscriptionType === 5;

  const isSubscriptionPublished = subscriptionStatus === SUBSCRIPTION_STATUS.active;

  const getCourses = async () => {
    setIsFetching(true);
    try {
      const res = await Api.get(
        `/wizard/courses${isMarketingType ? '?isMarketingMaterial=1' : '?isEducational=1'}`,
      );
      setData({ ...data, courses: res.data });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setIsFetching(false);
    }
  };

  const onSelectItem = item => {
    const ids = [...activeIds];
    if (ids.includes(item.courseId)) {
      ids.splice(ids.indexOf(item.courseId), 1);
      setData(prev => ({
        ...prev,
        activeCourses: prev.activeCourses.filter(course => course.courseId !== item.courseId),
      }));
    } else {
      ids.push(item.courseId);
      setData(prev => ({
        ...prev,
        activeCourses: prev.activeCourses ? [...prev.activeCourses, item] : [item],
      }));
    }
    setActiveIds(ids);
  };

  const handleSwitchDraft = () => {
    if (isSubscriptionPublished || isFetching) return;
    setShowDraft(!showDraft);
  };

  const filteredCourses = getFiteredCourses(data.courses, {
    showDraft,
    activeIds,
    search,
  });

  useEffect(() => {
    getCourses();
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <div className='px-2'>
        <h6 className='color-blue mb-3'>Select Courses</h6>
        <p>Choose Courses that you want to be included in the Subscription Plan</p>
        <div className='col-8 mb-3 p-0'>
          <div className='search-block p-0'>
            <input
              type='text'
              onChange={({ target }) => setSearch(target.value)}
              className='form-control w-100'
              placeholder='Search by Course Name'
              value={search}
            />
            <IconSearch />
          </div>
        </div>
        <div className='p-2'>
          <SwitchIOSLike
            label='Show Draft Courses'
            checkedState={showDraft}
            handleChange={handleSwitchDraft}
            disabled={isSubscriptionPublished}
          />
        </div>
        <div className='courses_container m-content flex-fill col-8 p-0'>
          {!isFetching ? (
            !!filteredCourses.length &&
            filteredCourses?.map((item, i) => {
              return (
                <label
                  key={item.courseId}
                  className={`${activeIds.includes(item.courseId) &&
                    'first_ordered'} case-item lesson-item pointer w-100 d-flex align-items-center justify-content-between p-0`}
                  htmlFor={`radiocheck${i}`}
                >
                  <CourseLessonsRow
                    defaultImageUrl={DEFAULT_IMG_URLS.course}
                    activeIds={activeIds}
                    item={item}
                    index={i}
                    onSelectItem={onSelectItem}
                    checkField='courseId'
                  />
                </label>
              );
            })
          ) : (
            <Loading className='mt-5' />
          )}
          {!isFetching && !!filteredCourses?.length && <p>Selected Courses: {activeIds.length}</p>}
        </div>
      </div>
    </>
  );
};

export default SubsCoursesStep;
