/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import ClickArea from 'shared/components/ClickArea';
import { formatDate } from 'utils/appHelpers';
import Loading from 'shared/components/Loading';
import ListActionButtons from 'shared/components/ListActionButtons';
import { hasAccess } from 'utils/permissionHelper';

const headers = [
  {
    name: 'Scan ID',
    className: 'col-3',
    key: 'scanId',
  },
  {
    name: 'DICOM Types',
    className: 'col',
    key: 'dicomTypes',
  },
  {
    name: 'Used In',
    className: 'col',
    key: 'used',
  },
  {
    name: 'Related DICOMs',
    className: 'col',
    key: 'totalDicomsCount',
  },
  {
    name: 'Scan Date',
    className: 'col',
    key: 'scanDate',
  },
  {
    name: 'Actions',
    className: 'd-flex align-items-center p-2 action-buttons-container',
    key: '',
  },
];

const CasesLists = ({
  list,
  order,
  changeOrder,
  fetch,
  onItemRemoveConfirm,
  onItemRemoveDisabled,
}) => {
  if (fetch) return <Loading className='mt-5' />;
  if (!list || !list.length)
    return <div className='text-black-50 text-center mt-4'>No Results Found</div>;

  const handleOrderChange = item => {
    if (!item.key) return;
    changeOrder({
      key: item.key,
      order: !order.order,
    });
  };

  const actions = [
    { type: 'link', icon: 'view', to: item => `/case/${item.scanId}` },
    {
      type: 'link',
      icon: 'birads',
      to: item => `/case/${item.scanId}/birads`,
      actionClassName: item => (item.hasBIRADS ? 'fill-blue' : ''),
      hide: () => !hasAccess('cases_create'),
    },
    {
      type: 'button',
      icon: 'delete',
      disabled: onItemRemoveDisabled,
      onClick: item => onItemRemoveConfirm(item),
      hide: () => !hasAccess('cases_create'),
    },
  ];

  return (
    <div className='users-lists'>
      <ul className='p-0'>
        <li className='d-flex list-header align-items-center'>
          {headers.map((item, index) => (
            <ClickArea
              key={item.name + index}
              className={`d-flex text-body justify-content-between align-items-center ${
                item.className
              }`}
              onClick={handleOrderChange.bind(null, item)}
            >
              {item.name}
              {item.key && order.orderKey === item.key && (
                <button
                  className={`btn ml-2 p-0 dropdown-toggle ${order.order ? '' : 'rotate-180'}`}
                />
              )}
            </ClickArea>
          ))}
        </li>
        {list &&
          list.map(item => {
            return (
              <li key={item.scanId} className='d-flex list-item h-not-set'>
                <div className='d-flex col-3 align-items-center force_wrap'>
                  {item.scanId || '-'}
                </div>
                <div className='d-flex col align-items-center'>{item.dicomTypes || '-'}</div>
                <div className='d-flex col align-items-center'>
                  {item.usedCourses && !!item.usedCourses.length
                    ? item.usedCourses.map((item, i) => (
                        <React.Fragment key={i}>
                          {item.course.name}
                          <br />
                        </React.Fragment>
                      ))
                    : '-'}
                </div>
                <div className='d-flex col align-items-center'>{item.totalDicomsCount || '-'}</div>
                <div className='d-flex col align-items-center'>
                  {formatDate(item.scanDate) || '-'}
                </div>
                <div className='d-flex align-items-center p-2 action-buttons-container'>
                  <ListActionButtons actions={actions} item={item} />
                </div>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default CasesLists;
