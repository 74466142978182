import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import Button from 'shared/components/Button';
import { VectorBackBold } from 'shared/components/Icons';

const NavigableHeaderRoutes = ({ items }) => {
  const history = useHistory();

  const lastNavigableItem = items.reduce((acc, curr) => (curr.path ? curr : acc), {});
  const lastNavigableUrl = lastNavigableItem.path;

  const goBack = () => {
    if (lastNavigableUrl) {
      history.push(lastNavigableUrl);
    } else {
      history.goBack();
    }
  };

  return (
    <div className='d-flex breadcrumbs align-items-center'>
      <Button onClick={goBack}>
        <VectorBackBold />
      </Button>
      {items.map((item, index) => (
        <React.Fragment key={index}>
          {item.path ? <Link to={item.path}>{item.label}</Link> : <span>{item.label}</span>}
          {index < items.length - 1 && <span className='mx-2'>/</span>}
        </React.Fragment>
      ))}
    </div>
  );
};

export default NavigableHeaderRoutes;
