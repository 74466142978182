import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { IconTick } from 'shared/components/Icons';

const WizardSteps = ({ steps, activeStep, setActiveStep }) => {
  const history = useHistory();
  const location = useLocation();
  const lastSearch = useRef();

  const pushNewUrl = (url, isReplace = false) => {
    if (window.location.search !== url.search) {
      history[isReplace ? 'replace' : 'push'](`${window.location.pathname}${url.search}`);
      lastSearch.current = url.search;
    }
  };

  const setStep = (isReplace = false) => {
    const url = new URL(window.location.href);
    url.searchParams.set('step', activeStep);
    pushNewUrl(url, isReplace);
  };

  // useEffect(() => {
  //   setStep();
  // }, [activeStep]);

  useEffect(() => {
    if (location.search && lastSearch.current !== location.search) {
      const url = new URL(window.location.href);
      const newStep = url.searchParams.get('step');
      if (activeStep !== newStep) setActiveStep(Number(newStep));
    }
  }, [location.search]);

  return (
    <div className='d-flex pl-3'>
      <ul className='align-items-center d-flex mx-3 filters steps'>
        {steps.map((item, index) => {
          const isActive = activeStep === index;
          const isCompleted = activeStep > index;
          return (
            <li
              key={index}
              className={`d-flex align-items-center ${isActive ? 'active' : ''} ${
                isCompleted ? 'completed' : ''
              }`}
            >
              Step {index + 1} {isCompleted && <IconTick className='ml-1' />}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default WizardSteps;
