import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import CasesHeader from './components/CasesHeader';
import ReactPaginate from 'react-paginate';
import { IconArrowLeft, IconArrowRight } from 'shared/components/Icons';
import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';
import CasesLists from './components/CasesLists';
import { confirmAlert } from 'react-confirm-alert';
import { dicomTypes, filterPatientAges, scanTypes } from 'configs';
import AdvancedFilters from 'shared/components/filters/AdvancedFilters';
import useFilters from 'shared/hooks/useFilters';
import useDidUpdateEffect from 'shared/hooks/useDidUpdateEffect';

const filterUsage = [{ name: 'Used', id: true }, { name: 'Unused', id: false }];

const initialFilters = () => {
  return { dicomType: '', scanType: '', used: '', age: '', date: null };
};

const WarehouseCases = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [cases, setCases] = useState([]);
  const [fetch, setFetch] = useState(0);
  const [removeFetch, setRemoveFetch] = useState(false);
  const [pageCount, setPageCount] = useState(0);

  const {
    requestParams,
    filters,
    dropFilters,
    changeFilter,
    changeDropFilter,
    clearDropFilter,
    filterDependency,
    originalState,
  } = useFilters(
    { page: 0, order: false, orderKey: 'createdAt', search: '', limit: 8 },
    { ...initialFilters() },
  );

  const params = {
    ...requestParams,
    scanId: requestParams.search,
  };

  const getCases = async () => {
    try {
      setFetch(true);
      const { data } = await Api.get(`/cases/allcases`, { params });
      setPageCount(Math.ceil(data.data.count / 8));
      setCases(data.data.results);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetch(false);
    }
  };

  const onItemRemove = async item => {
    try {
      setRemoveFetch(true);
      await Api.delete(`/cases/case/${item.scanId}`);
      await getCases();
      enqueueSnackbar('Successfully removed', { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setRemoveFetch(false);
    }
  };

  const onItemRemoveConfirm = item => {
    if (item.used > 0) {
      confirmAlert({
        message: 'The scan is used in lesson(s) Detach it from lesson before removing.',
        buttons: [{ label: 'OK' }],
      });
      return;
    }

    confirmAlert({
      title: 'Case Removal',
      message: 'Are you sure you want to remove the case and all related DICOM files?',
      buttons: [
        {
          label: 'CANCEL',
        },
        {
          label: 'REMOVE',
          onClick: () => onItemRemove(item),
        },
      ],
    });
  };

  const onClearAllFilters = () => {
    clearDropFilter();
  };

  const onFilterChange = (name, id) => {
    changeDropFilter(name, id);
  };

  const onPageChange = ({ selected }) => {
    changeFilter({ page: selected });
  };

  const handleSearchChange = text => {
    changeFilter({ search: text });
  };

  const onChangeOrder = item => {
    if (!item.key) return;
    changeFilter({ order: !filters.order, orderKey: item.key });
  };

  const filtersOptions = [
    {
      type: 'dropdown',
      title: 'Usage',
      name: 'used',
      data: filterUsage,
      value: dropFilters.used,
      onFilterChange: onFilterChange,
    },
    {
      type: 'dropdown',
      title: 'DICOM Type',
      name: 'dicomType',
      data: dicomTypes,
      value: dropFilters.dicomType,
      onFilterChange: onFilterChange,
    },
    {
      type: 'dropdown',
      title: 'Scan Type',
      name: 'scanType',
      data: scanTypes,
      value: dropFilters.scanType,
      onFilterChange: onFilterChange,
    },
    {
      type: 'dropdown',
      title: 'Patient Age',
      name: 'age',
      data: filterPatientAges,
      value: dropFilters.age,
      onFilterChange: onFilterChange,
    },
    {
      type: 'date',
      title: 'Scan Date',
      name: 'date',
      data: [],
      value: dropFilters.date,
      onFilterChange: onFilterChange,
    },
  ];

  useDidUpdateEffect(() => {
    getCases();
  }, [...filterDependency]);

  useEffect(() => {
    getCases();
  }, []);

  return (
    <div>
      <CasesHeader search={originalState.search} onSearch={handleSearchChange} />
      <div className='py-3 has-header'>
        <AdvancedFilters filters={filtersOptions} onClearAll={onClearAllFilters} />
        <div className='col-12'>
          <CasesLists
            list={cases}
            order={filters}
            changeOrder={onChangeOrder}
            fetch={fetch}
            onItemRemoveConfirm={onItemRemoveConfirm}
            onItemRemoveDisabled={removeFetch}
          />
        </div>
        {pageCount > 1 && !fetch && (
          <ReactPaginate
            previousLabel={<IconArrowLeft />}
            nextLabel={<IconArrowRight />}
            breakLabel={'...'}
            forcePage={Number(filters.page)}
            breakClassName={'break-me'}
            pageCount={pageCount}
            marginPagesDisplayed={3}
            pageRangeDisplayed={3}
            onPageChange={onPageChange}
            containerClassName={`pagination show-arrow`}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
          />
        )}
      </div>
    </div>
  );
};

export default WarehouseCases;
