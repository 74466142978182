import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Header from './components/Header';
import Banner from './components/Banner';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import Pages from './components/Pages';
import SpecialtySection from './components/SpecialtySection';
import Loading from 'shared/components/Loading';
import LinkTitleSection from './components/LinkTitleSection';
import ExamPreparationSection from './components/ExamPreparationSection';
import FAQ from './components/FAQ';
import SendQuestionSection from './components/SendQuestionSection';
import Footer from './components/Footer';
import RequiredCoursesSection from './components/RequiredCourses';
import ClosePreview from 'shared/components/ClosePreview';

const formatCurrentPage = currentPage => {
  return currentPage
    ? {
        ...currentPage,
        requiredCourses: currentPage.requiredCourses.map(({ id, course }) => ({
          ...course,
          id,
        })),
      }
    : {};
};

const ProductPagePreview = () => {
  const { enqueueSnackbar } = useSnackbar();

  const history = useHistory();

  const { id, productId } = useParams();
  const [product, setProduct] = useState(null);
  const [selectedSpecialtyId, setSelectedSpecialtyId] = useState('');

  const getProductData = async () => {
    try {
      const { data } = await Api.get(`/product/product/${productId}`);
      if (data?.data) {
        const currentPage = data?.data?.pages?.find(page => Number(page.id) === Number(id));
        setSelectedSpecialtyId(currentPage.id);
        const updatedCurrPage = formatCurrentPage(currentPage);
        setProduct({
          ...data.data,
          bannerImg: data.data?.images?.[0]?.imageUrl,
          currentPage: updatedCurrPage,
        });
      } else {
        enqueueSnackbar(getError(data), { variant: 'error' });
        history.push('/home');
      }
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const handleSelectPage = selectedId => {
    setSelectedSpecialtyId(selectedId);
    const currPage = product?.pages?.find(page => Number(page.id) === Number(selectedId));
    const updatedCurrPage = formatCurrentPage(currPage);
    setProduct({ ...product, currentPage: updatedCurrPage });
  };

  useEffect(() => {
    getProductData();
  }, []);

  if (!product) {
    return <Loading />;
  }

  return (
    <div className='product-page-container'>
      <ClosePreview onClose={() => history.push(`/product/${productId}/view`)} />
      <Header />
      <Pages
        selectedSpecialtyId={selectedSpecialtyId}
        handleSelectPage={handleSelectPage}
        currentPage={product.currentPage}
        productPages={product?.pages}
      />
      <Banner {...product} />
      <SpecialtySection {...product} />
      <RequiredCoursesSection product={product} />
      <LinkTitleSection />
      <ExamPreparationSection />
      <FAQ />
      <SendQuestionSection />
      <Footer />
    </div>
  );
};

export default ProductPagePreview;
