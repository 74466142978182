import React, { useState } from 'react';
import { Api } from 'utils/connectors';
import ImportForm from './components/ImportForm';
import Button from 'shared/components/Button';
import ReportHeader from '../Reports/components/ReportHeader';

const ReportImport = ({ match }) => {
  const [fetch, setFetch] = useState(false);
  const [type, setType] = useState(match.params.type || '');
  const [file, setFile] = useState('');
  const [success, setSuccess] = useState(null);

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      setFetch(true);
      const form = new FormData();
      form.append('file', file);
      form.append('coursetype', type);
      await Api.post('/upload/gds', form);
      setSuccess(true);
    } catch (err) {
      setSuccess(false);
    } finally {
      setFetch(false);
    }
  };

  return (
    <div>
      <ReportHeader />
      <div className='py-3 has-header'>
        <div className='col-12'>
          <h5 className='color-blue mb-3 col-12'>Import File</h5>
          {success === null && (
            <ImportForm
              file={file}
              setFile={setFile}
              fetch={fetch}
              handleSubmit={handleSubmit}
              type={type}
              setType={setType}
            />
          )}
          {success === false && (
            <div className='px-3 report-success-status error'>
              <h4>Import Failed</h4>
              <p className='mb-2'>
                The structure of the file or the data is incorrect.
                <br /> No any data was saved.
              </p>
              <Button type='button' className='btn-blue mt-1 px-4' onClick={() => setSuccess(null)}>
                Start Over
              </Button>
            </div>
          )}
          {success === true && (
            <div className='px-3 report-success-status'>
              <h4>Successfully Imported</h4>
              <p className='mb-2'>
                The structure of the file and data was checked to be correct. <br />
                The data was successfully imported.
              </p>
              <Button type='button' className='btn-blue mt-1 px-4' onClick={() => setSuccess(null)}>
                Import Another File
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReportImport;
