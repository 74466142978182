import React from 'react';
import { RateStarIcon } from 'shared/components/Icons';

const RateStarComponent = props => {
  const { rate, color, label } = props;
  const quantityArr = new Array(rate).fill(0);

  return (
    <div className='rate-container'>
      {quantityArr.map((item, idx) => {
        return <RateStarIcon key={idx} color={color} />;
      })}
      <b className='rate-label'>{label}</b>
    </div>
  );
};

export default RateStarComponent;
