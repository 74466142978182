export const saveToStore = (name, value) => {
  if (!localStorage || !localStorage.setItem) return;
  try {
    localStorage.setItem(name, value);
  } catch (err) {}
};

export const getFromStore = name => {
  if (!localStorage || !localStorage.getItem) return false;
  try {
    return localStorage.getItem(name) || false;
  } catch (err) {
    return false;
  }
};

export const removeFromStore = name => {
  if (!localStorage || !localStorage.removeItem) return;
  try {
    localStorage.removeItem(name);
  } catch (err) {}
};

export const removeAllStore = () => {
  if (!localStorage || !localStorage.clear) return;
  try {
    localStorage.clear();
  } catch (err) {}
};
