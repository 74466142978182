import React from 'react';
import { LandingTickIcon } from './Icons';
import { CircularProgress } from '@material-ui/core';

const LandingCircleProgress = props => {
  const {
    progress,
    size = 80,
    thickness = 5,
    notFillColor = '#fff',
    fillColor = '#AF3DB2',
    valueColor = '#AF3DB2',
    iconWidth = 34,
    iconHeight = 27,
    containerClassName,
  } = props;
  return (
    <div className={`progress-container ${containerClassName}`}>
      <div
        className='landing-circle-progress'
        style={{
          width: `${size}px`,
          height: `${size}px`,
        }}
      >
        <div
          className='white-item-block'
          style={{
            color: notFillColor,
          }}
        >
          {progress === 100 && (
            <LandingTickIcon
              style={{
                top: `calc(50% - ${iconHeight / 2}px)`,
                left: `calc(50% - ${iconWidth / 2}px)`,
              }}
              width={iconWidth}
              height={iconHeight}
              className='progress-tick'
            />
          )}
          <CircularProgress
            variant='determinate'
            value={100}
            size={size}
            thickness={thickness}
            style={{ color: '#fff' }}
          />
        </div>
        <CircularProgress
          variant='static'
          value={progress}
          size={size}
          thickness={thickness}
          style={{ color: fillColor, position: 'absolute', zIndex: 2, left: 0 }}
        />
      </div>
      <div
        style={{
          zIndex: 3,
        }}
      >
        <p className='percent-txt' style={{ color: valueColor }}>{`${Math.round(progress)}%`}</p>
      </div>
    </div>
  );
};

export default LandingCircleProgress;
