import React, { Fragment, useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { IconArrowUp, QuestionCheckIcon, RadioIcon } from './Icons';
import { QUESTION_TYPES_OBJ } from 'app/Main/routes/CourseQuizQuestionGroupsConfig/constants';
import { QuizViewImageColumn } from './QuizImageColumnView';

const ANSWER_BY_TYPE = {
  radio: 'Text Single Select',
  multi: 'Text Multi Select',
  yesno: 'Boolean Answer',
  'image-category': 'Image Categories',
};

const useStyles = makeStyles(theme => ({
  accordionContainer: {
    position: 'relative',
    paddingLeft: '24px',
    paddingRight: '24px',
    margin: '0 !important',
    boxShadow: 'unset !important',
    borderTop: '2px solid',
  },
  summaryBorder: {
    // New class for the AccordionSummary's pseudo-element
    '&::before': {
      content: '""',
      position: 'absolute',
      opacity: 0.4,
      bottom: 0,
      left: '0',
      right: '0',
      borderBottom: '2px dashed #2B2D42',
    },
  },
  questionIndex: {
    position: 'absolute',
    left: 0,
  },
  questionText: {
    width: '70%',
    fontSize: '16px',
    fontWeight: 500,
    marginLeft: '30px',
  },
  answersBlock: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '16px',
    paddingLeft: '44px',
    '&::before': {
      // This pseudo-element will act as the bottom border
      content: '""',
      position: 'absolute',
      opacity: 0.4,
      bottom: 0,
      left: '24px',
      right: '24px',
      borderBottom: '2px dashed #2B2D42',
    },
  },
  answerRow: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '12px',
  },
  iconSpan: {
    display: 'inline-block',
    width: '24px',
    backgroundColor: '#fff',
    borderRadius: '50%',
  },
  answersTypeBlock: {
    display: 'flex',
    fontSize: '14px',
    gap: '4px',
    marginTop: '12px',
  },
  typeTxt: {
    color: '#777777',
  },
  type: {
    color: '#131f6b',
  },
}));

export const QuestionAccordion = ({
  question,
  index,
  courseId,
  color,
  backgroundColor = '#EFF4FB',
}) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const classes = useStyles();
  const columnsObj = {};
  if (question.answersImageCategories) {
    question.answersImageCategories.forEach(imgCat => {
      if (!columnsObj[imgCat.category] && imgCat.category) {
        columnsObj[imgCat.category] = {
          name: imgCat.category,
          items: [{ imageId: imgCat.imageId, imageUrl: imgCat.imageUrl }],
        };
      } else if (imgCat.category) {
        columnsObj[imgCat.category] = {
          ...columnsObj[imgCat.category],
          items: [
            ...columnsObj[imgCat.category].items,
            { imageId: imgCat.imageId, imageUrl: imgCat.imageUrl },
          ],
        };
      }
    });
  }
  const imageColumns = Object.values(columnsObj);

  const handleAccordionChange = (event, isExpanded) => {
    setIsExpanded(isExpanded);
  };

  return (
    <Accordion
      id={question.id}
      style={{ borderColor: index === 0 ? color : 'transparent', backgroundColor }}
      className={classes.accordionContainer}
      defaultExpanded
      onChange={handleAccordionChange}
    >
      <AccordionSummary
        className={!isExpanded ? classes.summaryBorder : ''}
        expandIcon={<IconArrowUp color='#131f6b' />}
        aria-controls={`panel${index + 1}-content`}
        id={`panel${index + 1}-header`}
      >
        <Typography className={classes.questionText} variant='body1'>
          <span className={classes.questionIndex}>{index + 1}</span>
          <span>{`Q: ${question.title}`}</span>
          <div className={classes.answersTypeBlock}>
            <span className={classes.typeTxt}>Answer Type:</span>
            <span className={classes.type}> {ANSWER_BY_TYPE[question.type]}</span>
          </div>
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.answersBlock}>
        {question.answersMulti &&
          question.answersMulti.map((answer, idx) => {
            return (
              <Fragment key={idx}>
                {(question.type === QUESTION_TYPES_OBJ.radio ||
                  question.type === QUESTION_TYPES_OBJ.yesno) && (
                  <div className={classes.answerRow} key={idx}>
                    <span className={classes.iconSpan}>
                      {!!answer.isRightAnswer && <RadioIcon />}
                    </span>
                    <span>{answer.answer}</span>
                  </div>
                )}
                {question.type === QUESTION_TYPES_OBJ.multi && (
                  <div className={classes.answerRow} key={idx}>
                    <span className={classes.iconSpan}>
                      {!!answer.isRightAnswer && <QuestionCheckIcon />}
                    </span>
                    <span>{answer.answer}</span>
                  </div>
                )}
              </Fragment>
            );
          })}
        <div className='images_columns_container custom_scrollbar_block'>
          {question.answersImageCategories &&
            imageColumns.map((column, colIdx) => {
              return <QuizViewImageColumn column={column} key={colIdx} />;
            })}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};
