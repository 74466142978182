import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { IconKey } from 'shared/components/Icons';
import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';
import { formatSubscription } from 'utils/formatHelpers';
import Loading from 'shared/components/Loading';
import AuthorsList from './components/AuthorsList';
import CoursesList from './components/CoursesList';
import { Link } from 'react-router-dom';
import { hasAccess } from 'utils/permissionHelper';
import ResponsiveImage from 'shared/components/ResponsiveImage';

const subsTypes = {
  1: 'No Key Required',
  2: 'Paid',
  3: (
    <>
      <IconKey /> Key Required
    </>
  ),
};

// const statuses = {
//   0: 'None',
//   1: 'Active',
//   2: 'Inactive',
//   3: 'Publish',
//   4: 'Unpublish'
// };

const SubscriptionView = ({ history, match }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { id } = match.params;
  const [subscription, setSubscription] = useState();
  const [fetch, setFetch] = useState(false);

  const fetchData = async () => {
    try {
      const { data } = await Api.get(`/subscription/getsubscription/${id}`);
      setSubscription(formatSubscription(data.data));
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onToggleSubs = async publish => {
    try {
      setFetch(true);
      const action = publish ? 'publish' : 'unpublish';
      await Api.post(`/subscription/${action}/${id}`);
      enqueueSnackbar('Successfully updated', { variant: 'success' });
      await fetchData();
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetch(false);
    }
  };

  useEffect(() => {
    fetchData();
    //eslint-disable-next-line
  }, [id]);

  if (!subscription) return <Loading classView='mt-5' />;

  const canToggle = hasAccess('publish_subscription');
  const canPublish = canToggle && (subscription.status === 2 || subscription.status === 4);
  const canUnPublish = (canToggle && subscription.status === 1) || subscription.status === 3;

  return (
    <>
      <div className='users-header d-flex justify-content-between align-items-center'>
        <div className='d-flex breadcrumbs'>
          <Link to='/subscriptions/all'>Subscriptions</Link>
          <span className='mx-2'>/</span>
          <span>{subscription.name}</span>
        </div>
      </div>
      <div className='d-flex p-3 row has-header subs-view-page overflow-x-scroll'>
        <div className='col-8'>
          <div className='section-header mb-2'>
            <h2 className='align-items-center d-flex'>{subscription.name}</h2>
            <p className='mt-2 text-secondary mb-0'>About Plan</p>
          </div>
          <p className='text-desc'>{subscription.info}</p>
          <p className='text-secondary'>Faculty</p>
          {subscription.authors && subscription.authors.length > 0 && (
            <AuthorsList data={subscription.authors} history={history} />
          )}
          <p className='text-secondary'>Courses</p>
          {subscription.courses && subscription.courses.length > 0 && (
            <CoursesList data={subscription.courses} />
          )}
        </div>
        <div className='col-4'>
          <div className='plan-info-box'>
            <ResponsiveImage imgUrl={subscription.image} alt='subscription' />
            <p className='mt-3 mb-2'>
              <b>{subscription.name}</b>
            </p>
            <div className='subs-type mb-3'>{subsTypes[subscription.type] || '-'}</div>
            <div className='d-flex justify-content-between'>
              <span>Duration</span>
              <span>{subscription.time} hours</span>
            </div>
            <div className='d-flex justify-content-between'>
              <span>Parts</span>
              <span>{subscription.courses ? subscription.courses.length : '0'} parts</span>
            </div>
            <div className='d-flex justify-content-between'>
              <span>Course Duration</span>
              {subscription.expirable ? (
                <span>{subscription.days} days</span>
              ) : (
                <span>No Expiration</span>
              )}
            </div>
            <div className='d-flex justify-content-between'>
              <span>Faculty</span>
              <span>{subscription.authors.length} Faculty</span>
            </div>
            {canPublish && !subscription.isSystem && (
              <div className='d-flex justify-content-center'>
                <button
                  disabled={fetch}
                  className='btn mt-3 btn-outline-primary px-4 btn-sm'
                  onClick={onToggleSubs.bind(null, true)}
                >
                  Publish
                </button>
              </div>
            )}
            {canUnPublish && !subscription.isSystem && (
              <div className='d-flex justify-content-center'>
                <button
                  disabled={fetch}
                  className='btn mt-3 btn-outline-danger px-4 btn-sm'
                  onClick={onToggleSubs.bind(null, false)}
                >
                  UnPublish
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SubscriptionView;
