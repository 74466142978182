import React, { useEffect, useState, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { Api } from 'utils/connectors';
import Header from './components/Header';
import ReactPaginate from 'react-paginate';
import Lists from './components/Lists';
import { getError } from 'utils/appHelpers';
import useDebounce from 'shared/hooks/useDebounce';
import { IconArrowLeft, IconArrowRight } from 'shared/components/Icons';

const SystemLogs = ({ match }) => {
  const { filter } = match.params;
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState([]);
  const [order, setOrder] = useState({ order: false, orderKey: 'eventDate' });
  const [fetch, setFetch] = useState(false);
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);

  const getTicketsList = useCallback(async () => {
    try {
      setFetch(true);
      const userType = filter ? filter : undefined;
      const params = { ...order, page: page + 1, limit: 8, search: debouncedSearch, userType };
      const { data } = await Api.get(`/common/system-events`, { params });
      setPageCount(Math.ceil(data.data.count / 8));
      setData(data.data.results);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetch(false);
    }
  }, [debouncedSearch, enqueueSnackbar, order, page, filter]);

  const onPageChange = ({ selected }) => {
    setPage(selected);
  };

  const handleSearchChange = text => {
    setSearch(text);
  };

  useEffect(() => {
    getTicketsList();
  }, [getTicketsList, page, order, filter]);

  return (
    <div>
      <Header onSearch={handleSearchChange} />
      <div className='py-3 has-header'>
        <div className='col-12'>
          <Lists data={data} order={order} changeOrder={setOrder} fetch={fetch} />
        </div>
        {pageCount > 1 && !fetch && (
          <ReactPaginate
            previousLabel={<IconArrowLeft />}
            nextLabel={<IconArrowRight />}
            breakLabel={'...'}
            forcePage={page}
            breakClassName={'break-me'}
            pageCount={pageCount}
            marginPagesDisplayed={3}
            pageRangeDisplayed={3}
            onPageChange={onPageChange}
            containerClassName={`pagination show-arrow`}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
          />
        )}
      </div>
    </div>
  );
};

export default SystemLogs;
