import React, { useState, useEffect } from 'react';
import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';
import { useSnackbar } from 'notistack';
import { confirmAlert } from 'react-confirm-alert';
import CreationForm from './components/CreationForm';
import Header from './components/Header';
import Loading from 'shared/components/Loading';
import {
  getEmptyAnswerObj,
  getEmptyDescObj,
  getEmptyStepObj,
  setDicomIds,
  setInitialConfigs,
} from './utils';

const CaseBiradsNew = ({ history, match }) => {
  const { id } = match.params;
  const { enqueueSnackbar } = useSnackbar();
  const [fetching, setFetching] = useState(false);
  const [caseData, setCaseData] = useState();
  const [form, setForm] = useState({
    caseId: id,
    answers: { ...getEmptyAnswerObj() },
  });

  const setInitialCaseAnswers = caseData => {
    const dataSections = caseData.answers.sections;
    const sections = dataSections.length
      ? dataSections.map(item => {
          item.descriptions = item.descriptions.join('\n');
          return item;
        })
      : [{ ...getEmptyDescObj() }];

    const steps = setInitialConfigs(caseData.answers.steps, caseData.dicoms).map(step => {
      return { ...getEmptyStepObj(), ...step };
    });

    setForm({
      ...form,
      answers: {
        sections: sections,
        steps: steps,
      },
    });
  };

  const onGetCaseData = async e => {
    try {
      const { data } = await Api.get(`/cases/case/${id}?images=true`);
      setCaseData(data.data);
      if (data.data.hasBIRADS && data.data.answers) {
        setInitialCaseAnswers(data.data);
      }
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      history.push('/cases/all');
    }
  };

  const onItemRemove = async () => {
    try {
      setFetching(true);
      await Api.delete(`/wizard/cases/birads/${caseData.id}`);
      history.push('/cases/all');
      enqueueSnackbar('Successfully removed', { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetching(false);
    }
  };

  const onRemoveBiards = () => {
    confirmAlert({
      title: 'Birads Removal',
      message: 'Are you sure you want to remove the Birads?',
      buttons: [
        {
          label: 'CANCEL',
        },
        {
          label: 'REMOVE',
          onClick: () => onItemRemove(),
        },
      ],
    });
  };

  const handleFormSubmission = async () => {
    try {
      setFetching(true);
      const body = JSON.parse(JSON.stringify(form));
      body.answers.sections.forEach((item, key) => {
        body.answers.sections[key].descriptions = item.descriptions.split(/(?:\r\n|\r|\n)/g);
      });
      body.caseId = caseData.id;
      body.answers = setDicomIds(caseData.dicoms, body.answers);
      await Api.post('/wizard/cases/update-birads', body);
      enqueueSnackbar('Successfully updated', { variant: 'success' });
      history.push(`/case/${id}`);
    } catch (err) {
      setFetching(false);
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onSaveWithDraft = async () => {};

  useEffect(() => {
    onGetCaseData();
    //eslint-disable-next-line
  }, [id]);

  if (!caseData) return <Loading className='mt-5' />;

  return (
    <div>
      <Header caseId={id} />
      <div className='col mt-2 pb-5 has-header'>
        <CreationForm
          fetch={fetching}
          setForm={setForm}
          form={form}
          onSubmitUser={handleFormSubmission}
          caseData={caseData}
          onRemoveBiards={onRemoveBiards}
          onSaveWithDraft={onSaveWithDraft}
        />
      </div>
    </div>
  );
};

export default CaseBiradsNew;
