import React from 'react';
import { Switch } from 'react-router-dom';

import SurveyList from 'app/Public/routes/SurveyList';
import { WithTitleRoute } from 'utils/permissionHelper';

const Public = () => {
  return (
    <div className='public-pages'>
      <Switch>
        <WithTitleRoute path='/survey/list' component={SurveyList} />
      </Switch>
    </div>
  );
};

export default Public;
