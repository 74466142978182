import React from 'react';
import BackButton from 'shared/components/BackButton';
import AuthorsDropdown from './AuthorsDropdown';
import ModeStatus from 'shared/ModeStatus';

const HeaderSection = ({ course, onClosePreview, mode, hasBack, onBackClick }) => {
  return (
    <div className='header-section align-items-center d-flex w-100 px-4 justify-content-between'>
      <div className='d-flex align-items-center'>
        <div className='course-name pr-4 text-two-line d-flex align-items-center'>
          {hasBack && <BackButton onClick={onBackClick} className='mr-2' />}
          (Preview) {course.name}
        </div>
        <div className='align-items-center course-authors d-flex pl-4'>
          <AuthorsDropdown data={course.activeAuthors} />
        </div>
      </div>
      <div className='d-flex align-items-center'>
        <ModeStatus mode={mode} />
        <button
          onClick={onClosePreview}
          type='button'
          className='btn btn-md btn-outline-blue font-weight-bold btn-border-2'
        >
          Close Preview
        </button>
      </div>
    </div>
  );
};
export default HeaderSection;
