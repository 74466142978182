import React from 'react';

const ProgressBar = ({ progress, isHeader }) => (
  <span
    className={`plan-progress-box ${
      !isHeader && progress && progress < 100 ? 'progress-incomplete' : ''
    }`}
  >
    <span className='progress-completed' style={{ width: `${progress || 0}%` }} />
  </span>
);

export default ProgressBar;
