import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { IconClose } from 'shared/components/Icons';
import { getError, getEndOfSubs, courseSubsStatuses } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import { confirmAlert } from 'react-confirm-alert';
import moment from 'moment';
import { formatSubscription } from 'utils/formatHelpers';
import Loading from 'shared/components/Loading';
import ConfirmRequestModal from '../../SubscriptionRequest/components/ConfirmRequestModal';

const SubActionButton = ({ show, className, onClick, disabled, loading, name }) => {
  if (!show) return null;
  return (
    <button className={`btn ${className}`} onClick={onClick} disabled={disabled}>
      {loading && <div className='spinner-border spinner-grow-sm mr-2' />}
      <span className='mr-1'>{name}</span>
    </button>
  );
};

const UserSubscriptionModal = ({ userSubs, close, onSuccess, user }) => {
  const [reActivateFetch, setReActivateFetch] = useState(false);
  const [revokeFetch, setRevokeFetch] = useState(false);
  const [refreshFetch, setRefreshFetch] = useState(false);
  const [provideFetch, setProvideFetch] = useState(false);
  const [code, setCode] = useState({});
  const [subs, setSubscriptions] = useState([]);
  const [modalState, setModalState] = useState({});
  const { enqueueSnackbar } = useSnackbar();

  const reActivateSubs = async subId => {
    try {
      setReActivateFetch(subId);
      const { data } = await Api.post(`subscription/extendusersubscription/${user.id}/${subId}`);
      if (onSuccess) await onSuccess();
      enqueueSnackbar(data.message, { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setReActivateFetch(false);
    }
  };

  const revokeSubs = async id => {
    try {
      setRevokeFetch(id);
      const { data } = await Api.post(`subscription/revoke/${id}`);
      if (onSuccess) await onSuccess();
      enqueueSnackbar(data.message, { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setRevokeFetch(false);
    }
  };

  const revokeSubsConfirmation = id => {
    confirmAlert({
      message: 'Revoke the license of the user course',
      buttons: [
        {
          label: 'Close',
        },
        {
          label: 'Continue',
          onClick: () => revokeSubs(id),
        },
      ],
    });
  };

  const getSubscriptions = async () => {
    try {
      const params = { page: 1, limit: 100, status: 'active' };
      const { data } = await Api.get(`/subscription/getsubscriptions`, { params });
      const tempData = data.data.results.map(item => formatSubscription(item));
      setSubscriptions(tempData);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const refreshSubscription = async id => {
    try {
      setRefreshFetch(id);
      const { data } = await Api.post(`/subscription/refresh/${id}`);
      if (onSuccess) await onSuccess();
      enqueueSnackbar(data.message, { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setRefreshFetch(false);
    }
  };

  const onProvideSubmit = async (uniqueKey, id, e) => {
    e.preventDefault();
    try {
      setProvideFetch(true);
      const { data } = await Api.post(`/subscription/provide`, {
        subscriptionId: id,
        userId: user.id,
        uniqueKey,
      });
      if (onSuccess) await onSuccess();
      enqueueSnackbar(data.message, { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setProvideFetch(false);
    }
  };

  const onTogglePending = (type, data) => {
    setModalState({ type, data });
  };

  useEffect(() => {
    getSubscriptions();
    //eslint-disable-next-line
  }, []);

  const subscriptions = {
    ids: userSubs.map(i => i.subscriptionId),
    active: userSubs.filter(i => courseSubsStatuses(i.userSubscriptionStatus, 'active')),
    pending: userSubs.filter(i => courseSubsStatuses(i.userSubscriptionStatus, 'pending')),
  };

  const withoutActive = subs ? subs.filter(i => !subscriptions.ids.includes(i.id)) : [];

  return (
    <>
      <div className='modal-box deactivate-modal d-flex flex-column'>
        <div className='align-items-start d-flex header justify-content-between'>
          <div>Manage Subscription plan</div>
          <div onClick={close} tabIndex='-1' role='button' className='mt-1 pointer'>
            <IconClose />
          </div>
        </div>

        <div className='content flex-fill overflow-auto'>
          <p className='mt-3 mb-2 weight-500 fz-16'>Enrolled:</p>
          <div className='subs-plan-area'>
            {subscriptions.active.length ? (
              subscriptions.active.map(item => {
                const isExpired = moment(item.endDate).diff(new Date(), 'days') <= 0;
                const isExpirable = item.subscription.expirable;
                const isSystemSubscription = item.subscription?.isSystem;
                return (
                  <div
                    className='plan-box align-items-center d-flex justify-content-between'
                    key={item.id}
                  >
                    <div className='plan-name col-4 p-0'>{item.subscription.title}</div>
                    <div className='align-items-center d-flex flex-fill'>
                      {!!isExpirable && (
                        <div className='col d-flex plan-dates text-nowrap'>
                          <span className='col-6 text-right'>
                            {item.subscription.courseDuration} days
                          </span>
                          <span className='col-6'>{getEndOfSubs(item.endDate)}</span>
                        </div>
                      )}
                      {!isExpirable && (
                        <div className='col plan-dates text-nowrap text-center'>No Expiration</div>
                      )}
                      <div className='d-flex justify-content-end p-0 plan-button-area'>
                        <SubActionButton
                          name='REVOKE'
                          loading={revokeFetch === item.id}
                          disabled={revokeFetch || isSystemSubscription}
                          onClick={revokeSubsConfirmation.bind(null, item.id)}
                          className={`text-danger ${isSystemSubscription &&
                            'global_disabled opacity_2'}`}
                          show={!isExpired}
                        />
                        <SubActionButton
                          name='REFRESH'
                          loading={refreshFetch === item.id}
                          disabled={refreshFetch || !isExpirable || isSystemSubscription}
                          onClick={refreshSubscription.bind(null, item.id)}
                          className={`text-primary ml-2 no-min-w ${isSystemSubscription &&
                            'global_disabled opacity_2'}`}
                          show={!isExpired}
                        />
                        <SubActionButton
                          name='Re-Activate'
                          loading={reActivateFetch === item.id}
                          disabled={reActivateFetch || !isExpirable || isSystemSubscription}
                          onClick={reActivateSubs.bind(null, item.id)}
                          className={`text-primary ${isSystemSubscription &&
                            'global_disabled opacity_2'}`}
                          show={isExpired}
                        />
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className='plan-box'>No Active Subscriptions</div>
            )}
          </div>
          {!!subscriptions.pending.length && (
            <div>
              <p className='mt-3 mb-2 weight-500 fz-16'>Pendings:</p>
              <div className='subs-plan-area'>
                {subscriptions.pending.map(item => {
                  const status = item.userSubscriptionStatus;
                  const type = [6, 7, 8].includes(status) ? 'Extend' : 'Subscription';
                  const isSystemSubscription = item.subscription?.isSystem;
                  return (
                    <div
                      className='plan-box align-items-center d-flex justify-content-between'
                      key={item.id}
                    >
                      <div className='plan-name'>{item.subscription.title}</div>
                      <div className='d-flex align-items-center'>
                        <div className='plan-dates text-nowrap col text-center'>
                          <span>{type}</span>
                        </div>
                        <div className='d-flex'>
                          <SubActionButton
                            name='DECLINE'
                            disabled={isSystemSubscription}
                            onClick={onTogglePending.bind(null, 'decline', item)}
                            className={`text-danger ${isSystemSubscription &&
                              'global_disabled opacity_2'}`}
                            show={true}
                          />
                          <SubActionButton
                            name='ACCEPT'
                            disabled={isSystemSubscription}
                            onClick={onTogglePending.bind(null, 'accept', item)}
                            className={`text-primary ml-2 no-min-w ${isSystemSubscription &&
                              'global_disabled opacity_2'}`}
                            show={true}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          <p className='mt-3 mb-2 weight-500 fz-16'>Available:</p>
          <div className='subs-plan-area'>
            {withoutActive && !!withoutActive.length ? (
              withoutActive.map(item => {
                const isSystemSubscription = item.isSystem;
                return (
                  <div
                    className='plan-box align-items-center d-flex justify-content-between'
                    key={item.id}
                  >
                    <div className='plan-name'>{item.name}</div>
                    <div className='d-flex align-items-center'>
                      <div className='plan-dates'>
                        <span>{item.days} days</span>
                      </div>
                      <form
                        onSubmit={onProvideSubmit.bind(null, code[item.id], item.id)}
                        className={item.keyRequired ? 'has-key' : ''}
                      >
                        {item.keyRequired && (
                          <input
                            type='text'
                            name='code'
                            value={code[item.id] || ''}
                            placeholder='Enter Key'
                            onChange={({ target }) => setCode({ ...code, [item.id]: target.value })}
                            required
                          />
                        )}
                        <button
                          type='submit'
                          className={`btn ${isSystemSubscription && 'global_disabled opacity_2'}`}
                          disabled={provideFetch || isSystemSubscription}
                        >
                          PROVIDE
                        </button>
                      </form>
                    </div>
                  </div>
                );
              })
            ) : (
              <Loading className='mt-3' />
            )}
          </div>
        </div>
        <div className='footer text-right px-4'>
          <button className='btn mt-2 text-blue weight-600' onClick={close}>
            Done
          </button>
        </div>
      </div>
      {modalState.type && (
        <ConfirmRequestModal
          data={modalState.data}
          type={modalState.type}
          fromUserProfile={true}
          onModalClose={() => setModalState({})}
          onSuccess={onSuccess}
        />
      )}
    </>
  );
};

export default UserSubscriptionModal;
