import React from 'react';
import SpinnerRayAnimated from 'assets/wizard/SpinnerRayAnimated.svg';
import DashComponent from 'shared/components/DashComponent';

const DeviceBlock = ({ count = 6, deviceID, deviceName, isCreating, hasCreated, title }) => {
  return (
    <div className='device_block'>
      <p className='id_block_title'>{title}:</p>
      <div className='name_block'>
        <span>Name:</span>
        {hasCreated && <span>{deviceName}</span>}
      </div>
      <div className='id_block'>
        <div className='title'>{'ID:'}</div>
        {!!isCreating && <img src={SpinnerRayAnimated} alt='spin' width={62} height={62} />}
        {!isCreating && (
          <>
            {hasCreated ? (
              <div className='device_id'>{deviceID}</div>
            ) : (
              <DashComponent count={count} />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default DeviceBlock;
