import React from 'react';
import { Link } from 'react-router-dom';

const Header = ({ author, isUpdate }) => {
  return (
    <div className='users-header d-flex justify-content-between align-items-center'>
      <div className='d-flex breadcrumbs'>
        <Link to='/authors/all'>All Faculty</Link>
        <span className='mx-2'>/</span>
        <span>{isUpdate ? author.name : 'Add New Faculty'}</span>
      </div>
    </div>
  );
};

export default Header;
