/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import ClickArea from 'shared/components/ClickArea';
import { formatDate } from 'utils/appHelpers';
import Loading from 'shared/components/Loading';
import ListActionButtons from 'shared/components/ListActionButtons';
import { headers } from './constants';
import { hasAccess } from 'utils/permissionHelper';

const LicensesLists = ({
  list,
  order,
  changeOrder,
  fetch,
  onItemRemoveConfirm,
  onItemRemoveDisabled,
}) => {
  if (fetch) return <Loading className='mt-5' />;
  if (!list || !list.length)
    return <div className='text-black-50 text-center mt-4'>No Results Found</div>;

  const handleOrderChange = item => {
    if (!item.key) return;
    changeOrder({
      key: item.key,
      order: !order.order,
    });
  };

  const actions = [
    { type: 'link', icon: 'view', title: 'View', to: item => `/license/${item.id}` },
    {
      type: 'link',
      icon: 'edit',
      title: 'Edit',
      addIdInLocationState: item => item.id,
      to: item => `/license/${item.id}`,
      hide: () => !hasAccess('license_create'),
    },
    {
      type: 'button',
      icon: 'delete',
      title: 'Remove',
      disabled: onItemRemoveDisabled,
      onClick: item => onItemRemoveConfirm(item),
      hide: () => !hasAccess('license_create'),
    },
    {
      type: 'link',
      icon: 'copyAndCreate',
      title: 'Copy & Create',
      to: () => `/licenses/new`,
      hide: () => !hasAccess('license_create'),
    },
  ];

  return (
    <div className='users-lists'>
      <ul className='p-0 full_width'>
        <li className='d-flex list-header align-items-center'>
          {headers.map((item, index) => (
            <ClickArea
              key={item.name + index}
              className={`d-flex text-body justify-content-between align-items-center ${
                item.className
              }`}
              onClick={handleOrderChange.bind(null, item)}
            >
              {item.name}
              {item.key && order.orderKey === item.key && (
                <button
                  className={`btn ml-2 p-0 dropdown-toggle ${order.order ? '' : 'rotate-180'}`}
                />
              )}
            </ClickArea>
          ))}
        </li>
        {list &&
          list.map(item => {
            return (
              <li key={item.id} className='d-flex list-item h-not-set'>
                <div className='d-flex col align-items-center'>{item.licenseKey || '-'}</div>
                <div className='d-flex col align-items-center'>
                  {item.entity ? item.entity.name : '-'}
                </div>
                <div className='d-flex col align-items-center'>
                  {item.subscription && item.subscription.title}
                </div>
                <div className='d-flex col align-items-center justify-content-center'>
                  {item?.startDate && item?.endDate
                    ? `${formatDate(item.startDate)} - ${formatDate(item.endDate)}`
                    : '-'}
                </div>
                <div className='d-flex col align-items-center'>{item.numOfSits || '-'}</div>
                <div className='d-flex col align-items-center'>{item.used ? 'Yes' : 'No'}</div>
                <div className='d-flex align-items-center action-buttons-container p-2'>
                  <ListActionButtons actions={actions} item={item} />
                </div>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default LicensesLists;
