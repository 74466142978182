import React, { useState, useRef } from 'react';
import DateRangePicker from 'react-daterange-picker';
import useOutsideClick from 'shared/hooks/useOutsideClick';
import 'react-daterange-picker/dist/css/react-calendar.css'; // For some basic styling. (OPTIONAL)
import iconCalendar from 'assets/calendar-new.svg';

const DateRangePickerFilter = ({
  onChange,
  disabled,
  isSelectedRange,
  isNotAllowedRange,
  value = null,
  label = '',
  customClasses = '',
}) => {
  const [open, setOpen] = useState(false);
  const content = useRef();

  useOutsideClick(content, () => setOpen(false));

  return (
    <div
      className={`${customClasses} ${!disabled && 'white-bg'} calendar is-filter mr-4 ${
        open ? 'is-opened' : ''
      }`}
      ref={content}
    >
      <div
        className={`calendar-header d-flex align-items-center justify-content-between ${
          !value && disabled ? 'no-value' : ''
        } ${!!isNotAllowedRange && 'not_allowed'} ${isSelectedRange && 'range_selected'}`}
        onClick={() => !disabled && setOpen(!open)}
        role='presentation'
      >
        {!isSelectedRange ? label : label}
        <img className='ml-2' width='16' src={iconCalendar} alt='Calendar' />
      </div>
      <div className={`calendar-body ${open ? 'open' : ''}`} key={value}>
        <DateRangePicker
          disabled={disabled}
          onSelect={values => {
            onChange(values);
            setOpen(false);
          }}
          value={value}
        />
      </div>
    </div>
  );
};

export default DateRangePickerFilter;
