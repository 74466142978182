import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';
import Header from './components/Header';
import ReactPaginate from 'react-paginate';
import Lists from './components/Lists';
import { IconArrowLeft, IconArrowRight } from 'shared/components/Icons';
import EventStatistics from './components/EventStatistics';
import AdvancedFilters from 'shared/components/filters/AdvancedFilters';
import useFilters from 'shared/hooks/useFilters';

const filtersStatus = [
  { name: 'Published', id: 2 },
  { name: 'Unpublished', id: 3 },
  { name: 'Draft', id: 1 },
];

const filtersType = [{ name: 'Workshop', id: 1 }, { name: 'Webinar', id: 0 }];

const filtersRealization = [{ name: 'Non Branded', id: 0 }, { name: 'Branded', id: 1 }];

const Events = ({ match }) => {
  const [statsModale, setStatsModale] = useState(false);
  const [fetch, setFetch] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState([]);
  const { filter } = match.params;

  const [pageCount, setPageCount] = useState(0);
  const status = filter === 'all' ? null : filter;

  const initialFilters = () => ({
    status: '',
    type: '',
    realization: '',
    date: '',
  });

  const {
    requestParams,
    filters,
    dropFilters,
    changeFilter,
    changeDropFilter,
    clearDropFilter,
    filterDependency,
    originalState,
  } = useFilters(
    { page: 0, order: false, orderKey: 'createdAt', search: '', limit: 8 },
    { ...initialFilters() },
  );

  const params = { ...requestParams };

  const downloadData = [
    {
      title: 'Export as CSV',
      url: `/events/events`,
      fileName: 'events_list',
      type: 'csv',
      params,
    },
  ];

  const getData = async () => {
    try {
      setFetch(true);
      const { data } = await Api.get(`/events/events`, { params });
      if (data.data) {
        setPageCount(Math.ceil(data.data.count / 8));
        setData(data.data.results);
      } else {
        setData([]);
      }
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetch(false);
    }
  };

  const onClearAllFilters = () => {
    clearDropFilter();
  };

  const onFilterChange = (name, id) => {
    changeDropFilter(name, id);
  };

  const onOpenEventStats = id => {
    setStatsModale(id);
  };

  const onPageChange = ({ selected }) => {
    changeFilter({ page: selected });
  };

  const handleSearchChange = text => {
    changeFilter({ search: text });
  };

  const onChangeOrder = item => {
    if (!item.key) return;
    changeFilter({ order: !filters.order, orderKey: item.key });
  };

  const onStatsClose = () => {
    setStatsModale(false);
  };

  useEffect(() => {
    getData();
  }, [...filterDependency, status]);

  const filtersOptions = [
    {
      type: 'dropdown',
      title: 'Status',
      name: 'status',
      data: filtersStatus,
      value: dropFilters.status,
      onFilterChange: onFilterChange,
    },
    {
      type: 'multiDropdown',
      title: 'Type',
      name: 'type',
      data: filtersType,
      value: dropFilters.type,
      withoutSearch: true,
      onFilterChange: onFilterChange,
    },
    {
      type: 'dropdown',
      title: 'Realization',
      name: 'realization',
      data: filtersRealization,
      value: dropFilters.realization,
      onFilterChange: onFilterChange,
    },
    {
      type: 'date',
      title: 'Date',
      name: 'date',
      data: [],
      value: dropFilters.date,
      onFilterChange: onFilterChange,
    },
  ];

  return (
    <div>
      <Header search={originalState.search} onSearch={handleSearchChange} />
      <div className='py-3 has-header'>
        <AdvancedFilters
          downloadData={downloadData}
          withExport={true}
          filters={filtersOptions}
          onClearAll={onClearAllFilters}
        />
        <div className='col-12'>
          <Lists
            list={data}
            order={filters}
            changeOrder={onChangeOrder}
            fetch={fetch}
            openEventStats={onOpenEventStats}
          />
        </div>
        {pageCount > 1 && !fetch && (
          <ReactPaginate
            previousLabel={<IconArrowLeft />}
            nextLabel={<IconArrowRight />}
            breakLabel={'...'}
            forcePage={Number(filters.page)}
            breakClassName={'break-me'}
            pageCount={pageCount}
            marginPagesDisplayed={3}
            pageRangeDisplayed={3}
            onPageChange={onPageChange}
            containerClassName={`pagination show-arrow`}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
          />
        )}
      </div>
      {statsModale && <EventStatistics onClose={onStatsClose} eventId={statsModale} />}
    </div>
  );
};

export default Events;
