import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { Button } from '@material-ui/core';

import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';

import EditingSelect from 'shared/components/editingForms/EditingSelect';
import EditingInput from 'shared/components/editingForms/EditingInput';
import Loading from 'shared/components/Loading';

import removeIcon from 'assets/bin.svg';

const RegionsNew = ({ history, match, location }) => {
  const regionId = match?.params?.id;

  const { enqueueSnackbar } = useSnackbar();

  const [fetch, setFetch] = useState(false);
  const [regionName, setRegionName] = useState('');
  const [allCountries, setAllCountries] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([null]);

  const getRegionData = async () => {
    setFetch(true);
    try {
      const res = await Api.get(`/common/region/${regionId}`);
      const region = res.data.data;
      setRegionName(region.name);
      setSelectedCountries(region.countries.map(item => item.country.id));
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
    setFetch(false);
  };

  const handleChangeRegionName = ({ target }) => {
    const { value } = target;
    setRegionName(value);
  };

  const handleSelectCountry = ({ target, index }) => {
    const { value } = target;
    const tempCountries = [...selectedCountries];
    tempCountries.splice(index, 1, value);
    setSelectedCountries(tempCountries);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const sendingRegion = {
      name: regionName,
      countries: selectedCountries,
    };
    try {
      setFetch(true);
      if (regionId) {
        await Api.put(`/common/region`, { ...sendingRegion, originId: regionId });
      } else {
        await Api.post(`/common/region`, sendingRegion);
      }
      enqueueSnackbar('Successfully created', { variant: 'success' });
      history.push('/system/regions');
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetch(false);
    }
  };

  const handleRemoveCountry = index => {
    const tempCountries = [...selectedCountries];
    tempCountries.splice(index, 1);
    setSelectedCountries(tempCountries);
  };

  const handleAddCountry = () => {
    const tempCountries = [...selectedCountries, null];
    setSelectedCountries(tempCountries);
  };

  const getAllCountries = async () => {
    try {
      const res = await Api.get('/common/getcountries');
      setAllCountries(res.data.data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  useEffect(() => {
    if (regionId) {
      getRegionData();
    }
    getAllCountries();
  }, [regionId]);

  return (
    <div>
      <div className='users-header d-flex justify-content-between align-items-center'>
        <div className='d-flex breadcrumbs'>
          <Link to='/system/regions'>All Regions</Link>
          <span className='mx-2'>/</span>
          <span>{`${regionId ? 'Edit' : 'Add New'} Region`}</span>
        </div>
      </div>
      <div className='col-12 py-3 has-header'>
        {fetch ? (
          <Loading />
        ) : (
          <form onSubmit={handleSubmit} className='col-8 p-0 user-creation-form'>
            <ul className='p-0 mb-0'>
              <li className='d-flex align-items-center'>
                <div className='col-4 pl-0'>
                  <label className='m-0'>Region Name:</label>
                </div>
                <EditingInput
                  className={'pb-0'}
                  name='region_name'
                  onChange={handleChangeRegionName}
                  placeholder='Enter region name'
                  value={regionName}
                  required={true}
                  editing={true}
                />
              </li>
            </ul>
            <h5 className='p-3 color-blue'>Included Countries</h5>
            <ul className='p-0 mb-0'>
              {selectedCountries.map((item, index) => {
                const currentCountriesList = allCountries.filter(country => {
                  if (Number(country.id) === Number(selectedCountries[index])) {
                    return true;
                  }
                  if (selectedCountries.includes(String(country.id))) {
                    return false;
                  }
                  return true;
                });
                return (
                  <div className='d-flex drag-item' key={index}>
                    <li className='d-flex align-items-center w-100'>
                      <div className='col-4 pl-0 mr-3'>
                        <label className='m-0'>Country:</label>
                      </div>
                      <EditingSelect
                        name='country'
                        onChange={({ target }) => handleSelectCountry({ target, index })}
                        value={selectedCountries[index]}
                        items={currentCountriesList}
                        useProperty='id'
                        displayProperty='name'
                        required
                        editing
                      />
                    </li>
                    <button
                      onClick={() => handleRemoveCountry(index)}
                      disabled={selectedCountries.length < 2}
                      type='button'
                      className='btn btn-remove ml-2'
                    >
                      <img src={removeIcon} alt='icon' />
                    </button>
                  </div>
                );
              })}
            </ul>
            <div className='d-flex justify-content-end text-right add-new-items-btns mt-0 mr-2 pr-5'>
              <button type='button' className='btn btn btn-outline-blue' onClick={handleAddCountry}>
                Add Country +
              </button>
            </div>
            <div className='d-flex justify-content-end mt-4 border-top pt-4'>
              <Button
                className='btn btn-blue ml-2 px-4 fz-12 font-weight-bold'
                type='submit'
                disabled={fetch}
              >
                Save
              </Button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default RegionsNew;
