import React, { useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useSnackbar } from 'notistack';
import { formatDate, getError } from 'utils/appHelpers';
import { hasAccess } from 'utils/permissionHelper';
import { Api } from 'utils/connectors';
import { HOST } from 'configs';
import { IconBin, IconVideos } from 'shared/components/Icons';
import downloadIcon from 'assets/download.svg';

const headers = [
  {
    name: 'Image View',
    className: 'col-2',
  },
  {
    name: 'Image Type',
    className: 'col-2',
  },
  {
    name: 'DICOM Name',
    className: 'col-5',
  },
  {
    name: 'Uploaded Date',
    className: 'col-2',
  },
  {
    name: 'Actions',
    className: 'col',
    key: '',
  },
];

const DicomList = ({ dicoms, onGetCaseData }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [removeFetch, setRemoveFetch] = useState(false);
  // const [exportFetch, setExportFetch] = useState(false);

  if (!dicoms || !dicoms.length) return null;

  const onItemRemove = async item => {
    try {
      setRemoveFetch(true);
      await Api.delete(`/cases/dicom/${item.id}`);
      await onGetCaseData();
      enqueueSnackbar('Successfully removed', { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setRemoveFetch(false);
    }
  };

  const onItemRemoveConfirm = item => {
    confirmAlert({
      title: 'DICOM Removal',
      message: 'Are you sure you want to remove the DICOM file',
      buttons: [
        {
          label: 'CANCEL',
        },
        {
          label: 'REMOVE',
          onClick: () => onItemRemove(item),
        },
      ],
    });
  };

  // const onItemExport = async item => {
  //   try {
  //     setExportFetch(true);
  //     const { data } = await Api.get(`/dicom/export?file=${item.fileName}`, {
  //       responseType: 'blob',
  //       timeout: 30000,
  //     });
  //     await saveFile(data, item.fileName);
  //     enqueueSnackbar('Successfully removed', { variant: 'success' });
  //   } catch (err) {
  //     enqueueSnackbar('No Such File', { variant: 'error' });
  //   } finally {
  //     setExportFetch(false);
  //   }
  // };

  return (
    <div>
      <h5 className='p-3 color-blue mb-0'>Related Dicoms</h5>
      <div className='users-lists col-12'>
        <ul className='p-0'>
          <li className='d-flex list-header align-items-center'>
            {headers.map((item, index) => (
              <div
                key={item.name + index}
                className={`d-flex text-body justify-content-between align-items-center ${
                  item.className
                }`}
              >
                {item.name}
              </div>
            ))}
          </li>
          {dicoms &&
            dicoms.map((item, i) => {
              return (
                <li key={i} className='d-flex list-item h-not-set'>
                  <div className='d-flex col-2 align-items-center'>
                    {item.imageLaterality}
                    {item.viewPosition}
                  </div>
                  <div className='d-flex col-2 align-items-center justify-content-between'>
                    {item.imageType || '-'}
                    <span>
                      {item.numberOfSlices}
                      <IconVideos color='#131F6B' className='ml-1' />
                    </span>
                  </div>
                  <div className='d-flex col-5 align-items-center'>
                    <div className='text-word-break'>{item.fileName || '-'}</div>
                  </div>
                  <div className='d-flex col-2 align-items-center'>
                    {formatDate(item.uploadedAt) || '-'}
                  </div>
                  <div className='d-flex align-items-center p-2 justify-content-end w-100 action-buttons'>
                    <a
                      className='btn btn-sm bg-white mr-3 p-0 action-item'
                      href={`${HOST.API.CONTENT}/dicom/${item.fileName}`}
                      target='_blank'
                      rel='noopener noreferrer'
                      download={item.fileName}
                    >
                      <img width='30' src={downloadIcon} alt='icon' />
                    </a>
                    {/* <button
                      onClick={onItemExport.bind(null, item)}
                      disabled={exportFetch}
                      type='button'
                      className='btn btn-sm bg-white mr-3 p-0'
                    /> */}
                    {hasAccess('warehouse_cases_create') && (
                      <button
                        onClick={onItemRemoveConfirm.bind(null, item)}
                        disabled={removeFetch}
                        type='button'
                        className='btn btn-sm btn-red list-remove-btn action-item remove'
                      >
                        <IconBin />
                      </button>
                    )}
                  </div>
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};

export default DicomList;
