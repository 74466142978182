import { hasAccess } from 'utils/permissionHelper';

export const PERMISSION_ACCESS_LEVELS = {
  None: 0,
  View: 1,
  Edit: 2,
  0: 'None',
  1: 'View',
  2: 'Edit',
};

export const canKolManageCourse = (course, account) => {
  const shareItem = course?.courseShares?.find(i => i.email === account.email);
  if (shareItem) {
    return shareItem.accessLevel === 1;
  }
  return false;
};

export const hideCourseManageActions = (account, course) => {
  const isShared = !!course?.courseShares?.length;
  if (hasAccess('kol_user') && isShared) {
    return !canKolManageCourse(course, account);
  }

  return !hasAccess('courses_create');
};

export const canKolManageCase = (caseData, account) => {
  const shareItem = caseData?.caseShares.find(i => i.email === account.email);
  if (shareItem) {
    return shareItem.accessLevel === 1;
  }
  return false;
};

export const hideCaseManageActions = (account, caseData) => {
  const isShared = !!caseData?.caseShares?.length;
  if (hasAccess('kol_user') && isShared) {
    return !canKolManageCase(caseData, account);
  }

  return !hasAccess('warehouse_cases_create');
};
