import React from 'react';
import LandingFooter from 'shared/components/LandingFooter';
import HologicLogo from 'assets/logos/hologic_white_logo.png';
import HealthLogo from 'assets/logos/hologic_health_white.png';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div className='footer-container'>
      <div className='top-part'>
        <div className='sec-1'>
          <img alt='Hologic' src={HologicLogo} />
          <p className='regular-text-sm mb-0'>
            © Copyright {currentYear} Hologic, Inc. All rights reserved.
          </p>
        </div>
        <div className='sec-2'>
          <img alt='health' src={HealthLogo} />
        </div>
      </div>
      <LandingFooter />
    </div>
  );
};

export default Footer;
