import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';
import { Link } from 'react-router-dom';
import Loading from 'shared/components/Loading';
import LessonCasesBlock from '../LessonsNew/components/LessonCasesBlock';
import ResponsiveImage from 'shared/components/ResponsiveImage';

const LessonsHeatmaps = ({ match, history }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { id } = match.params;
  const [lesson, setLesson] = useState();
  const [fetching, setFetching] = useState(false);
  const [fetchHeat, setFetchHeat] = useState(false);
  const [currentCase, setCurrentCase] = useState();
  const [currentImg, setCurrentImg] = useState();

  const getLessonData = async id => {
    try {
      setFetching(true);
      const { data } = await Api.get(`/wizard/lesson/${id}`);
      const { lesson } = data.data;
      if (lesson.contentType !== 3) throw new Error('No Cases Type Lesson');
      if (lesson.cases && lesson.cases.length) {
        setCurrentCase(lesson.cases[0].caseId);
      }
      setLesson(lesson);
      setFetching(false);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      history.push('/lessons/all');
    }
  };

  const getCaseHeatmapImage = async caseId => {
    try {
      setFetchHeat(true);
      const { data } = await Api.get(`/dicom/lessonheatmapoverlay/${id}/${caseId}`, {
        responseType: 'arraybuffer',
        params: {
          overlay: false,
          isLesson: true,
        },
      });
      setCurrentImg(`data:image/png;base64,${Buffer.from(data, 'binary').toString('base64')}`);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetchHeat(false);
    }
  };

  useEffect(() => {
    if (id) getLessonData(id);
    //eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (currentCase) getCaseHeatmapImage(currentCase);
    //eslint-disable-next-line
  }, [currentCase]);

  if (!lesson || fetching) return <Loading className='mt-5 w-100' />;

  return (
    <div>
      <div className='users-header d-flex justify-content-between align-items-center'>
        <div className='d-flex breadcrumbs'>
          <Link to='/lessons/all'>All Lessons</Link>
          <span className='mx-2'>/</span>
          <Link to={`/lesson/${lesson.id}/edit`}>{lesson.title}</Link>
          <span className='mx-2'>/</span>
          <span>Heatmap</span>
        </div>
      </div>
      <div className='has-header col-12'>
        <LessonCasesBlock
          cases={lesson.cases}
          activeCase={currentCase}
          onClickCase={setCurrentCase}
        />
        {!fetchHeat && (
          <div className='col-12 p-0'>
            {currentImg ? (
              <ResponsiveImage imgUrl={currentImg} width='100%' alt='img' />
            ) : (
              <div className='text-center fz-16 text-black-50 border-top p-3'>
                No Heatmap data for this case
              </div>
            )}
          </div>
        )}
        {fetchHeat && <Loading className='mt-2' />}
      </div>
    </div>
  );
};

export default LessonsHeatmaps;
