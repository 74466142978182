import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import HomeHeader from './components/HomeHeader';
import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';
import DashTabs from './components/DashTabs';
import BlockAttendance from './components/BlockAttendance';
import BlockUserStatuses from './components/BlockUserStatuses';
// import BlockAttendanceOUS from './components/BlockAttendanceOUS';
import BlockEngaged from './components/BlockEngaged';
import BlockEvaluation from './components/BlockEvaluation';
import BlockEvaluationAnswers from './components/BlockEvaluationAnswers';

const Home = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [fetching, setFetching] = useState(false);
  const [activeTab, setActiveTab] = useState('total');
  const [counts, setCounts] = useState();

  const getData = async () => {
    try {
      setFetching(true);
      const { data } = await Api.get('/admin/getdashbordstatistics', {
        params: { page: activeTab },
      });
      setCounts(data.data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    getData();
    //eslint-disable-next-line
  }, [activeTab]);

  if (!counts) return null;

  const isTotal = counts.userStatus && activeTab === 'total';

  return (
    <>
      <HomeHeader />
      <div className='col-12 dash-page min-vh-100 has-header'>
        <DashTabs activeTab={activeTab} setActiveTab={setActiveTab} data={counts} />
        <div className='d-flex'>
          <div className='col-7 pl-0 pr-2 d-flex'>
            <BlockAttendance fetching={fetching} data={counts.usAttendance} />
          </div>
          <div className='col-5 pr-0 pl-3 d-flex'>
            <BlockUserStatuses
              fetching={fetching}
              isTotal={isTotal}
              data={!isTotal && counts.courseStatus ? counts.courseStatus : counts.userStatus}
            />
          </div>
        </div>
        {/* <BlockAttendanceOUS fetching={fetching} data={counts.ousAttendance} /> */}
        {isTotal && <BlockEngaged data={counts.engagedUsers} />}
        <BlockEvaluationAnswers fetching={fetching} data={counts.evaluationData} />
        <BlockEvaluation fetching={fetching} data={counts.evaluationSummary} />
      </div>
    </>
  );
};

export default Home;
