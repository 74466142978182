import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';

import EditingSelect from 'shared/components/editingForms/EditingSelect';
import { SwitchIOSLike } from 'shared/components/SwitchIOSLike';
import UsersSelect from 'shared/components/UsersSelect';
import { PERMISSION_KEY_BY_NAME } from 'utils/permissions/backRoles';
import { PERMISSION_ACCESS_LEVELS } from 'utils/permissions';

const controlStyles = { border: 'none', minHeight: 'unset' };
const placeholderStyles = { color: '#242836', opacity: '0.5', fontWeight: 'normal' };

const CountriesNew = ({ history, match, location }) => {
  const { enqueueSnackbar } = useSnackbar();
  const countryId = match.params.id;

  const [fetch, setFetch] = useState(false);
  const [countries, setCountries] = useState([]);
  // const [admins, setAdmins] = useState([]);
  const [accessOriginDetails, setAccessOriginDetails] = useState({});
  const [lastOptions, setLastOptions] = useState([]);
  const [form, setForm] = useState({
    countryId: '',
    responsibleUserId: '',
    status: 0,
    fullName: '',
  });

  const getCountries = async () => {
    try {
      const { data } = await Api.get(`/common/access-origins`);
      const results = data.data.results;
      const addedCountriesIds = results.map(res => res.country.id);
      const res = await Api.get('/common/getcountries');
      const filteredCountries = res.data.data.filter(country => {
        return !addedCountriesIds.includes(country.id);
      });
      setCountries(filteredCountries);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  // const getAdmins = async () => {
  //   try {
  //     const res = await Api.get('/admin/users-with-permission?permissions=24');
  //     setAdmins(res.data.data.results);
  //   } catch (err) {
  //     enqueueSnackbar(getError(err), { variant: 'error' });
  //   }
  // };

  const getAccessOriginDetails = async () => {
    try {
      const res = await Api.get(`/common/access-origin/${countryId}`);
      const details = res.data.data;
      setAccessOriginDetails(details);
      setForm({
        originId: details.id,
        countryId: details.country.id,
        responsibleUserId: details.responsibleAdmin.id,
        fullName: details.responsibleAdmin.fullName,
        status: details.status,
      });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setForm({ ...form, [name]: value });
  };

  const handleResAdminInputChange = (val, eObject, key) => {
    if (eObject?.action === 'input-change') {
      setForm(prev => ({ ...prev, [key]: val }));
    }
  };

  const handleSelectUser = user => {
    setForm(prev => ({ ...prev, ...user, responsibleUserId: user.id }));
  };

  const handleToggleEnable = () => {
    setForm(prev => ({ ...prev, status: prev.status ? 0 : 1 }));
  };

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      setFetch(true);
      if (countryId) {
        await Api.put('/common/access-origin', form);
      } else {
        await Api.post('/common/access-origin', form);
      }
      enqueueSnackbar('Successfully created', { variant: 'success' });
      history.push('/system/countries');
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetch(false);
    }
  };

  useEffect(() => {
    if (countryId) {
      getAccessOriginDetails();
    } else {
      getCountries();
    }
    // getAdmins();
  }, []);

  return (
    <div>
      <div className='users-header d-flex justify-content-between align-items-center'>
        <div className='d-flex breadcrumbs'>
          <Link to='/system/countries'>Countries</Link>
          <span className='mx-2'>/</span>
          <span>{countryId ? 'Edit' : 'Add New Country'}</span>
        </div>
      </div>
      <div className='col-12 py-3 has-header'>
        <form onSubmit={handleSubmit} className='col-8 p-0 user-creation-form'>
          <p>
            Start entering Admin credentials. For properly setting all parameters the Admin should
            be select from the suggestions list that will opened based on the entered text.
          </p>
          <ul className='p-0 mb-0'>
            <li className='d-flex align-items-center'>
              <div className='col-4 pl-0'>
                <label className='m-0'>Country*:</label>
              </div>
              {countryId ? (
                <span className='form_right_text'>{accessOriginDetails?.country?.name || ''}</span>
              ) : (
                <EditingSelect
                  name='countryId'
                  onChange={handleChange}
                  value={form.countryId}
                  items={countries}
                  useProperty='id'
                  displayProperty='name'
                  required
                  editing
                />
              )}
            </li>
            <li className='d-flex align-items-center'>
              <div className='col-4 pl-0'>
                <label className='m-0'>Responsible Admin*:</label>
              </div>
              <UsersSelect
                permissionAccessLevel={PERMISSION_ACCESS_LEVELS.Edit}
                permissions={[PERMISSION_KEY_BY_NAME.Lesson, PERMISSION_KEY_BY_NAME.Course]}
                isAdmins={true}
                name={'fullName'}
                inputValue={form.fullName}
                handleInputChange={handleResAdminInputChange}
                value={[form.responsibleUserId]}
                customClasses='w-100'
                isKOLUsers={false}
                onChange={handleSelectUser}
                placeholder='Choose the responsible admin'
                lastOptions={lastOptions}
                setLastOptions={setLastOptions}
                controlStyles={controlStyles}
                placeholderStyles={placeholderStyles}
                isHiddenRightIcons={true}
              />
            </li>
            <li className='list-default'>
              <div className='d-flex align-items-center'>
                <div className='col-4 pl-0'>
                  <label className='m-0'>Status:</label>
                </div>
                <div className='switch_container'>
                  <SwitchIOSLike checkedState={form.status} handleChange={handleToggleEnable} />
                </div>
                <span className='form_right_text'>{form.status ? 'Enabled' : 'Disabled'}</span>
              </div>
            </li>
          </ul>
          <div className='d-flex justify-content-end'>
            <button
              className='btn btn-primary ml-2 px-4 fz-12 font-weight-bold'
              type='submit'
              disabled={fetch}
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CountriesNew;
