import React from 'react';
import InputGroup from '../InputGroup';

const EditingMultiRow = ({ editing, name, data = [], onChange }) => {
  if (!editing) {
    return data.map((item, index) => (
      <div key={index} className={`pl-3 ${index + 1 < data.length ? 'mb-2' : ''}`}>
        <p>{item.title}</p>
        <p className='font-weight-light'>{item.info}</p>
      </div>
    ));
  } else {
    return data.map((item, index) => (
      <div key={index} className={index + 1 < data.length ? 'mb-2' : ''}>
        <InputGroup
          type='text'
          onChange={onChange.bind(null, index, name, 'title')}
          placeholder='Enter Institute, Univercity , etc.'
          value={item.title}
        />
        <textarea
          onChange={onChange.bind(null, index, name, 'info')}
          placeholder='Enter Description'
          value={item.info}
        />
      </div>
    ));
  }
};

export default EditingMultiRow;
