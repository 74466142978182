import React, { memo } from 'react';

const SvgIcon = memo(({ width, height, viewBox, children, className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    viewBox={viewBox}
    className={className}
  >
    {children}
  </svg>
));

export const IconRCC = ({ className, color = '#CECECE' }) => (
  <SvgIcon width='13' height='25' viewBox='0 0 13 25' className={className}>
    <path
      d='M12.7692 24.1005V24.9084C8.64646 24.7615 5.18634 23.2191 2.75689 19.8405C-0.335136 15.5072 -0.629615 10.88 1.94707 6.25282C4.3029 2.21322 8.05751 0.230146 12.7692 0.083252V0.891171C9.08818 0.891171 5.70168 2.6539 3.49309 5.59179C1.79983 7.79521 0.990018 10.5128 1.21088 13.2303C1.35812 16.1682 2.68327 18.9592 4.81824 21.0157C6.95321 22.9988 9.82438 24.1005 12.7692 24.1005Z'
      fill={color}
    />
  </SvgIcon>
);

export const IconRCCFill = ({ className, color = '#CECECE' }) => (
  <SvgIcon width='16' height='30' viewBox='0 0 16 30' className={className}>
    <path
      d='M15.0428 28.3535V29.3073C6.98541 29.3073 0.556641 22.7175 0.556641 14.5669C0.556641 11.9657 1.24238 9.45115 2.52813 7.19675C5.09964 2.68794 9.89978 0 15.0428 0V0.953786C10.8427 0.953786 6.81397 2.94807 4.32818 6.41638C2.35669 8.93091 1.41381 12.0524 1.58524 15.2606C1.84239 22.544 7.84258 28.3535 15.0428 28.3535Z'
      fill={color}
    />
    <path d='M15.0425 15.5195V17.0803H3.72785L3.4707 15.5195H15.0425Z' fill={color} />
    <path
      d='M15.0427 12.1377V13.6984H3.55664C3.64236 13.1782 3.64236 12.7447 3.81379 12.3111C3.81379 12.2244 4.07094 12.1377 4.24238 12.1377H15.0427Z'
      fill={color}
    />
    <path
      d='M4.24219 10.4038C4.49934 9.88351 4.67077 9.44997 4.92792 8.92973C5.01364 8.84302 5.09936 8.84302 5.18507 8.84302H14.9568H15.0425V10.4038H4.24219Z'
      fill={color}
    />
    <path
      d='M4.32812 18.8142H15.1285V20.3749C15.0427 20.3749 14.8713 20.3749 14.7856 20.3749C11.6998 20.3749 8.61397 20.3749 5.52816 20.3749C5.27101 20.3749 5.01386 20.2882 5.01386 20.0281C4.67099 19.6813 4.49956 19.3345 4.32812 18.8142Z'
      fill={color}
    />
    <path
      d='M15.1277 22.1956V23.7563H14.6991C12.5562 23.7563 10.4132 23.7563 8.27032 23.7563C8.01317 23.7563 7.84173 23.6696 7.6703 23.4962C7.24171 23.0627 6.81313 22.6291 6.29883 22.1089L15.1277 22.1956Z'
      fill={color}
    />
    <path
      d='M6.29883 6.93818L7.84173 5.46415C7.92745 5.37744 8.01316 5.37744 8.09888 5.37744H14.9562H15.1277V7.02489H6.38454L6.29883 6.93818Z'
      fill={color}
    />
    <path
      d='M15.1281 2.94638V3.72675H11.0137C12.2994 3.20651 13.6709 2.85968 15.1281 2.94638Z'
      fill={color}
    />
    <path
      d='M15.0424 25.4907V26.2711C13.6709 26.2711 12.2994 26.011 11.0137 25.4907H15.0424Z'
      fill={color}
    />
  </SvgIcon>
);

export const IconLCC = ({ className, color = '#CECECE' }) => (
  <SvgIcon width='13' height='25' viewBox='0 0 13 25' className={className}>
    <path
      d='M0.333386 24.1005V24.9084C4.45609 24.7615 7.91621 23.2191 10.272 19.8405C13.3641 15.5072 13.6585 10.88 11.0819 6.25282C8.79965 2.21322 5.04504 0.230146 0.259766 0.083252V0.891171C3.94075 0.891171 7.40087 2.6539 9.53584 5.59179C11.2291 7.79521 12.0389 10.5128 11.8181 13.2303C11.6708 16.1682 10.4193 18.9592 8.21069 21.0157C6.14934 22.9988 3.27817 24.1739 0.333386 24.1005Z'
      fill={color}
    />
  </SvgIcon>
);

export const IconLCCFill = ({ className, color = '#CECECE' }) => (
  <SvgIcon width='16' height='29' viewBox='0 0 16 29' className={className}>
    <path
      d='M0.90918 0.916673V0C5.40717 0 9.57197 2.08335 12.2374 5.75004C15.6526 10.3334 15.9858 16.5834 12.9038 21.4168C10.155 25.8335 6.07354 28.0002 0.90918 28.2502V27.3335C4.8241 27.3335 8.57242 25.5835 11.0713 22.5001C13.0704 20.1668 14.07 17.2501 14.07 14.1668C14.07 8.83339 10.9047 4.08336 5.99024 2.00001C4.32432 1.33334 2.6584 0.916673 0.90918 0.916673Z'
      fill={color}
    />
    <path d='M12.0709 14.9177L11.821 16.4177H0.90918V14.9177H12.0709Z' fill={color} />
    <path
      d='M12.0709 13.249H0.90918V11.749H1.32566C4.7408 11.749 8.07264 11.749 11.4878 11.749C11.821 11.749 11.9876 11.8324 11.9876 12.1657C11.9876 12.499 12.0709 12.8324 12.0709 13.249Z'
      fill={color}
    />
    <path
      d='M0.90918 10.0826V8.49927H1.32566C4.32432 8.49927 7.32298 8.49927 10.3216 8.49927C10.5715 8.49927 10.8214 8.5826 10.9047 8.8326C11.0713 9.24927 11.2379 9.66594 11.4878 10.0826H0.90918Z'
      fill={color}
    />
    <path
      d='M0.90918 18.1672H11.4045C11.1546 18.6672 10.9047 19.1672 10.6548 19.5839C10.6548 19.6672 10.4882 19.6672 10.4049 19.6672H0.992476H0.90918V18.1672Z'
      fill={color}
    />
    <path
      d='M0.90918 22.9168V21.4167H9.40538L9.48868 21.5001C8.9889 22.0001 8.48912 22.4168 7.90605 22.9168C7.82275 22.9168 7.73946 22.9168 7.65616 22.9168H1.07577H0.90918Z'
      fill={color}
    />
    <path
      d='M9.40538 6.83399H0.90918V5.33398C1.07577 5.33398 1.15907 5.33398 1.32566 5.33398C3.40806 5.33398 5.49046 5.33398 7.57287 5.33398C7.82275 5.33398 7.98935 5.41732 8.23923 5.58399C8.65571 5.91732 9.0722 6.41732 9.40538 6.83399Z'
      fill={color}
    />
    <path
      d='M0.90918 3.66537V2.83203C2.24192 2.83203 3.65795 3.08203 4.90739 3.58204L0.90918 3.66537Z'
      fill={color}
    />
    <path
      d='M0.90918 25.3323V24.5823H4.8241C3.57465 25.0823 2.24192 25.3323 0.90918 25.3323Z'
      fill={color}
    />
  </SvgIcon>
);

export const IconRMLO = ({ className, color = '#CECECE' }) => (
  <SvgIcon width='16' height='29' viewBox='0 0 16 29' className={className}>
    <path
      d='M15.2971 26.6169C14.3401 27.5717 13.1622 28.2328 11.9106 28.6C7.41983 29.9955 2.63455 27.4249 1.23577 22.9446C1.08854 22.5773 1.01492 22.1367 0.941297 21.7694C0.499579 19.1988 1.38301 16.6281 3.22351 14.7919C6.38915 11.4868 9.48118 8.25514 12.5732 4.95001C13.4566 4.06865 13.5303 2.7466 12.7204 1.79179C12.3523 1.27766 11.7634 0.983866 11.1744 0.910419L10.8063 0.836972V0.0290527C10.88 0.0290527 10.9536 0.0290527 11.1008 0.0290527C11.4689 0.0290527 11.837 0.1025 12.1315 0.249394C13.7511 0.983866 14.4873 2.89349 13.8247 4.50933C13.6775 4.80312 13.4566 5.17036 13.2358 5.3907C10.1438 8.69582 6.97811 12.0009 3.81246 15.3061C2.63455 16.4812 1.89835 17.9502 1.67749 19.6395C1.3094 22.2836 2.41369 24.8542 4.54866 26.47C5.87381 27.5717 7.56707 28.1593 9.33394 28.1593C11.0272 28.1593 12.6468 27.5717 14.0456 26.5435C14.2665 26.3966 14.4873 26.1763 14.7082 25.9559L15.2971 26.6169Z'
      fill={color}
    />
  </SvgIcon>
);

export const IconRMLOFill = ({ className, color = '#CECECE' }) => (
  <SvgIcon width='16' height='30' viewBox='0 0 16 30' className={className}>
    <path
      d='M12.1596 26.6609C11.2614 27.1905 10.1386 27.4932 9.09072 27.4932C8.0428 27.4932 6.99489 27.1905 6.09668 26.6609H12.1596Z'
      fill={color}
    />
    <path
      d='M14.3296 23.7874V25.1493H4.29958C3.92532 24.771 3.62592 24.2413 3.40137 23.7874H14.3296Z'
      fill={color}
    />
    <path
      d='M14.3295 20.9136V22.2755H2.87734C2.80249 21.8216 2.72764 21.3676 2.80249 20.9136H14.3295Z'
      fill={color}
    />
    <path
      d='M14.3298 18.0366V19.3986H3.02734C3.17705 18.8689 3.4016 18.4149 3.701 18.0366H14.3298Z'
      fill={color}
    />
    <path d='M14.3296 15.1628V16.5248H4.89844L6.1709 15.1628H14.3296Z' fill={color} />
    <path d='M14.3303 12.2859V13.6479H7.59375L8.86621 12.2859H14.3303Z' fill={color} />
    <path d='M14.33 9.41211V10.7741H10.2881L11.5606 9.41211H14.33Z' fill={color} />
    <path
      d='M14.3297 6.45972V7.82169H12.9824C13.3567 7.44336 13.7309 6.98937 14.1052 6.61105C14.18 6.61105 14.2549 6.53538 14.3297 6.45972Z'
      fill={color}
    />
    <path
      d='M15.1533 27.4181C14.1803 28.4017 12.9827 29.0827 11.7102 29.461C7.14431 30.8987 2.279 28.2504 0.856835 23.6348C0.707133 23.2565 0.632282 22.8025 0.557431 22.4242C0.108326 19.7759 1.00654 17.1276 2.87781 15.236C6.02154 11.8311 9.24013 8.50183 12.3839 5.0969C13.2821 4.18892 13.3569 2.82695 12.5336 1.8433C12.1593 1.31365 11.5605 1.01099 10.9617 0.935324L10.6623 0.859659V0.0273438C10.7371 0.0273438 10.812 0.0273438 10.9617 0.0273438C11.336 0.0273438 11.7102 0.103009 12.0096 0.254339C13.6563 1.01099 14.4048 2.97828 13.6563 4.64291C13.5066 4.94557 13.2821 5.3239 13.0575 5.55089C9.91379 8.95582 6.6952 12.3607 3.47661 15.7657C2.279 16.9763 1.53049 18.4896 1.30594 20.2299C0.931686 22.9538 2.05445 25.6021 4.22512 27.2668C5.57244 28.4017 7.29401 29.0071 9.09043 29.0071C10.812 29.0071 12.4587 28.4017 13.8809 27.3424C14.1054 27.1911 14.33 26.9641 14.5545 26.7371L15.1533 27.4181Z'
      fill={color}
    />
  </SvgIcon>
);

export const IconLMLO = ({ className, color = '#CECECE' }) => (
  <SvgIcon width='15' height='29' viewBox='0 0 15 29' className={className}>
    <path
      d='M4.65899 0.835462V0.0275427C4.21727 -0.0459045 3.77555 0.0275427 3.33384 0.247884C1.7142 0.982356 0.904389 2.89198 1.64059 4.50782C1.78783 4.87506 2.00868 5.16885 2.22954 5.46263L11.6529 15.378C12.8308 16.5532 13.567 18.0221 13.7878 19.7114C14.0823 22.3555 13.0516 24.9261 10.9167 26.542C8.19274 28.8188 4.21727 28.8188 1.41972 26.6889C1.19887 26.542 0.978006 26.3216 0.757147 26.1747L0.31543 26.6154C1.27249 27.5702 2.4504 28.2313 3.70193 28.5985C8.19273 29.994 12.978 27.4233 14.3768 22.8696C14.524 22.5024 14.5976 22.1351 14.6713 21.6945C15.113 19.1238 14.2295 16.5532 12.3891 14.717C9.22341 11.4119 6.13138 8.10673 3.03936 4.87506C2.15592 3.99369 2.0823 2.67164 2.89212 1.71683C3.26022 1.2027 3.84917 0.908909 4.43813 0.835462H4.65899Z'
      fill={color}
    />
  </SvgIcon>
);

export const IconLMLOFill = ({ className, color = '#CECECE' }) => (
  <SvgIcon width='16' height='29' viewBox='0 0 16 29' className={className}>
    <path
      d='M3.60156 25.8799C4.47981 26.394 5.57761 26.6878 6.60223 26.6878C7.62684 26.6878 8.65146 26.394 9.52971 25.8799H3.60156Z'
      fill={color}
    />
    <path
      d='M1.55273 23.0894V24.4114H11.433C11.7989 24.0442 12.0917 23.53 12.3112 23.0894H1.55273Z'
      fill={color}
    />
    <path
      d='M1.55273 20.2988V21.6209H12.7503C12.8235 21.1802 12.8967 20.7395 12.8235 20.2988H1.55273Z'
      fill={color}
    />
    <path
      d='M1.55273 17.5085V18.8306H12.604C12.4576 18.3165 12.238 17.8758 11.9453 17.5085H1.55273Z'
      fill={color}
    />
    <path d='M1.55273 14.718V16.0401H10.7743L9.53011 14.718H1.55273Z' fill={color} />
    <path d='M1.55273 11.9275V13.2496H8.13956L6.89538 11.9275H1.55273Z' fill={color} />
    <path d='M1.55273 9.13379V10.4559H5.50483L4.26065 9.13379H1.55273Z' fill={color} />
    <path
      d='M1.55273 6.27222V7.59428H2.79691C2.43098 7.22704 2.06504 6.78635 1.69911 6.41911C1.62592 6.41911 1.55273 6.34566 1.55273 6.27222Z'
      fill={color}
    />
    <path
      d='M0.74707 26.6172C1.6985 27.5721 2.86949 28.2331 4.11367 28.6003C8.57807 29.9958 13.3352 27.4252 14.7258 22.9448C14.8721 22.5776 14.9453 22.1369 15.0185 21.7697C15.4576 19.199 14.5794 16.6283 12.7497 14.7921C9.67588 11.4869 6.52884 8.25523 3.45499 4.95007C2.57674 4.06869 2.50356 2.74663 3.30861 1.7918C3.67455 1.27767 4.26004 0.983877 4.84554 0.910429L5.21147 0.836981V0.0290527C5.13829 0.0290527 5.0651 0.0290527 4.91873 0.0290527C4.55279 0.0290527 4.18686 0.102501 3.89411 0.249397C2.284 0.983877 1.55213 2.89352 2.21081 4.50938C2.35718 4.87662 2.50356 5.17041 2.7963 5.39076C5.94334 8.69592 9.01719 12.0011 12.1642 15.3062C13.3352 16.4814 14.0671 17.9504 14.2867 19.6397C14.6526 22.2838 13.5548 24.8545 11.4324 26.4703C10.115 27.572 8.4317 28.1596 6.67521 28.1596C4.99191 28.1596 3.3818 27.572 1.99125 26.5438C1.77169 26.3969 1.55213 26.1765 1.33257 25.9562L0.74707 26.6172Z'
      fill={color}
    />
  </SvgIcon>
);
