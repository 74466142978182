import React from 'react';
import { formatDate } from 'utils/appHelpers';

const InfoIcon = ({ name, value, className }) => (
  <p className={`m-0 ${className || ''}`}>
    {name}: <b className='text-blue'>{value}</b>
  </p>
);

const LessonCaseRow = ({ item, className }) => {
  const types = item.dicoms && new Set([...item.dicoms.map(i => i.imageType)]);
  const typeStr = types ? [...types].join(',') : false;
  return (
    <div className={className}>
      <div className='case-item-title d-flex align-items-center mb-1'>
        {item.caseUniqueId} • <span className='ml-2 text-uppercase'>{typeStr || ''}</span>
      </div>
      <div className='d-flex mb-1'>
        <InfoIcon name='ID' value={item.patientId} />
        <InfoIcon name='Age' className='ml-2' value={item.patientAge} />
      </div>
      <div className='d-flex'>
        <InfoIcon name='Updated Date' value={formatDate(item.updatedAt) || '-'} />
      </div>
    </div>
  );
};

export default LessonCaseRow;
