import React from 'react';
import { IconBin, IconVideoPair } from 'shared/components/Icons';
import ClickArea from 'shared/components/ClickArea';
import Loading from 'shared/components/Loading';
import { hasAccess } from 'utils/permissionHelper';

const headers = [
  {
    name: 'Device Name',
    className: 'col d-flex',
    key: 'deviceName',
  },
  {
    name: 'Device ID',
    className: 'col',
    key: 'deviceID',
  },
  {
    name: 'Pair State',
    className: 'col',
    key: 'pairedDevice',
  },
  {
    name: 'Event',
    className: 'col',
    key: 'event',
  },
  {
    name: 'Actions',
    className: 'p-2 d-flex justify-content-start min-100',
  },
];

const SubscriptionsLists = ({
  list,
  order,
  changeOrder,
  fetch,
  onEditDevice,
  onItemRemoveConfirm,
  onItemRemoveDisabled,
}) => {
  if (fetch) return <Loading className='mt-5' />;

  if (!list || !list.length)
    return <div className='text-black-50 text-center mt-4'>No Results Found</div>;

  const handleOrderChange = item => {
    if (!item.key) return;
    changeOrder({
      key: item.key,
      order: !order.order,
    });
  };

  return (
    <div className='users-lists'>
      <ul className='p-0'>
        <li className='d-flex list-header align-items-center'>
          {headers.map((item, index) => (
            <ClickArea
              key={item.name + index}
              className={`text-body d-flex align-items-center ${item.className}`}
              onClick={handleOrderChange.bind(null, item)}
            >
              {item.name}
              {item.key && order.orderKey === item.key && (
                <button
                  className={`btn ml-2 p-0 dropdown-toggle ${order.order ? '' : 'rotate-180'}`}
                />
              )}
            </ClickArea>
          ))}
        </li>
        {list &&
          list.map(item => (
            <li key={item.id} className='d-flex list-item'>
              <div className='col d-flex align-items-center'>{item.deviceName}</div>
              <div className='col d-flex align-items-center'>{item.deviceID}</div>
              <div className='col d-flex align-items-center'>
                {item.pairedDevice ? 'Paired' : 'Unpaired'}
              </div>
              <div className='col d-flex align-items-center'>
                {item.events && !!item.events.length
                  ? item.events.map((event, index) => {
                      const isLast = item.events.length - 1 === index;
                      return `${event.name}${isLast ? '' : ', '}`;
                    })
                  : '-'}
              </div>
              <div className='p-2 d-flex justify-content-start min-100 action-buttons'>
                {hasAccess('device_pair') && (
                  <button
                    className='btn bg-white px-1 py-0 mx-1 action-item'
                    onClick={onEditDevice.bind(null, item)}
                  >
                    <IconVideoPair />
                  </button>
                )}
                {hasAccess('device_remove') && (
                  <button
                    onClick={onItemRemoveConfirm.bind(null, item)}
                    disabled={onItemRemoveDisabled}
                    type='button'
                    className='btn ml-2 action-item remove'
                  >
                    <IconBin />
                  </button>
                )}
              </div>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default SubscriptionsLists;
