import React from 'react';
import { formatDate } from 'utils/appHelpers';

const CasesContent = ({ data }) => {
  return (
    !!data.cases?.length && (
      <div className='my-4'>
        <p className='text-blue text-lg mb-0 fw-700'>Cases</p>
        <div>
          {data.cases &&
            data.cases.map((item, i) => {
              return (
                <div
                  key={i}
                  className='d-flex align-items-center justify-content-between py-3 border-bottom'
                >
                  <div>
                    <p className='fw-600 mb-1'>{item.scanId}</p>
                    <div>
                      <div className='d-flex align-items-center pt-1'>
                        <p className='text-small mb-0 mr-2'>
                          <span className='text-secondary'>Scan Date:</span>
                          <span className='text-blue fw-600 ml-1'>{formatDate(item.scanDate)}</span>
                        </p>
                        <p className='text-small mb-0'>
                          <span className='text-secondary'>Upload Date:</span>
                          <span className='text-blue fw-600 ml-1'>
                            {formatDate(item.uploadDate)}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <p className='fw-600 text-blue mb-1 text-right'>{item.dicomTypes}</p>
                    <div>
                      <div className='d-flex align-items-center pt-1'>
                        <p className='text-small mb-0 mr-2'>
                          <span className='text-secondary'>ID:</span>
                          <span className='text-blue fw-600 ml-1'>{item.scanId}</span>
                        </p>
                        <p className='text-small mb-0'>
                          <span className='text-secondary'>Age:</span>
                          <span className='text-blue fw-600 ml-1'>{item.patientAge}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    )
  );
};

export default CasesContent;
