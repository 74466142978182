import React from 'react';
import moment from 'moment';
import ClickArea from 'shared/components/ClickArea';
import { hasAccess } from 'utils/permissionHelper';

const NotificationItem = ({ data, onRead }) => {
  return (
    <div className='notification-item d-flex flex-column justify-content-center'>
      <p>{data.text}</p>
      <div className='d-flex justify-content-end'>
        <span>{moment(`${data.dateTime}Z`).format('hh:mm')}</span>
        {data.isNew && hasAccess('notification_read') && (
          <ClickArea className='ml-2 mark-read' onClick={onRead.bind(null, data.id)}>
            Mark as read
          </ClickArea>
        )}
      </div>
    </div>
  );
};

export default NotificationItem;
