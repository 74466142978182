import { addColorBasedOnKey } from 'utils/quizHelpers';

export const adaptQuizConfigData = data => {
  const result = {
    attempts: data.attempts.isActive ? data.attempts.value : 1,
    passingThreshold: data.passingThreshold.isActive ? data.passingThreshold.value : 0,
    showWhatIsWrong: data.showWhatIsWrong.isActive,
    terminateOnLeave: data.terminateOnLeave.isActive,
    timeLimit: data.timeLimit.isActive ? data.timeLimit.value : 0,
    steps: data.steps.map(step => ({
      group: step.assignedCategory,
      required: step.isRequired,
      timeLimit: step.hasTimeLimit ? step.timeLimit : 0,
      questionId: !step.isActivePickQuestionRandomly ? step.questionId : null,
    })),
  };
  return { ...result };
};

export const adaptedQuizConfigData = data => {
  const result = {
    attempts: { isActive: !!data.attempts, value: data.attempts || 1 },
    passingThreshold: {
      isActive: !!data.passingThreshold,
      value: data.passingThreshold || 5,
      isAllSelected: data.passingThreshold === 100,
    },
    showWhatIsWrong: { isActive: !!data.showWhatIsWrong },
    terminateOnLeave: { isActive: !!data.terminateOnLeave },
    timeLimit: { isActive: !!data.timeLimit, value: data.timeLimit || 1 },
    steps: addColorBasedOnKey(
      data.steps.map(step => ({
        id: step.id,
        assignedCategory: step.questionGroup,
        isRequired: step.required,
        hasTimeLimit: !!step.timeLimit,
        timeLimit: step.timeLimit || 0.1,
        isActivePickQuestionRandomly: !step.questionId,
        pickQuestionRandomly: step.pickQuestionRandomly,
        questionId: step.questionId || null,
      })),
      'assignedCategory',
    ),
  };
  return { ...result };
};
