import React from 'react';
import Button from 'shared/components/Button';
import { RegularInput } from 'shared/components/Input/RegularInput';
import { QuestionTypeLabel } from 'shared/components/QuestionTypeLabel';

const Q_TYPES = [
  { type: 'multiSelect', title: 'Multi Select' },
  { type: 'singleSelect', title: 'Single Select' },
  { type: 'image', title: 'Image Categories' },
  { type: 'boolean', title: 'Boolean' },
];

export const RightSidebar = ({
  propertyDetails,
  courseId,
  firstBlockTitle,
  isEditState,
  handleSave,
  containerClassName,
  isSaveDisabled,
}) => {
  return (
    <div className={`right_sidebar_container ${containerClassName}`}>
      <div className='side_regular_block quiz_prop_block'>
        <h6 className='side_block_title'>{firstBlockTitle}</h6>
        <div className='list_container'>
          {Object.keys(propertyDetails).map((key, idx) => {
            const prop = propertyDetails[key];
            return (
              <div key={idx} className='prop_list_item'>
                {prop.icon}
                <span>{prop.name}</span>
                <span className='prop_value'>{prop.value || '-'}</span>
              </div>
            );
          })}
        </div>
      </div>
      {isEditState ? (
        <Button
          disabled={isSaveDisabled}
          onClick={handleSave}
          className='btn btn-primary w-100 fz-12'
        >
          Save
        </Button>
      ) : (
        <></>
      )}
      {!isEditState && (
        <div className='side_regular_block search_block'>
          <h6 className='side_block_title left_m_4'>Search in all question groups</h6>
          <RegularInput withSearchIcon placeholder='Search in question titles' />
          <div className='q_groups_block'>
            {Q_TYPES.map((group, idx) => (
              <QuestionTypeLabel key={idx} title={group.title} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
