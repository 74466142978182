import React from 'react';
import WizardSteps from 'shared/components/WizardSteps';

const Header = ({ steps, activeStep, setActiveStep, form, onPreviewClick }) => {
  return (
    <div className='users-header d-flex justify-content-between align-items-center'>
      <WizardSteps steps={steps} activeStep={activeStep} setActiveStep={setActiveStep} />
    </div>
  );
};

export default Header;
