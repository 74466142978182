import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import FooterButtons from './components/FooterButtons';
import Header from './components/Header';
import EventGeneralStep from './components/EventGeneralStep';
import EventCompleteStep from './components/EventCompleteStep';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import { extendMoment } from 'moment-range';
import Loading from 'shared/components/Loading';
import EventOrganizerStep from './components/EventOrganizerStep';
import EventAuthorsStep from './components/EventAuthorsStep';
import EventAboutStep from './components/EventAboutStep';
import moment from 'moment';
import EventCMEStep from './components/EventCMEStep';
import EventProgramStep from './components/EventProgramStep';
import EventDeviceStep from './components/EventDeviceStep';
import EventWorkshopStep from './components/EventWorkshopStep';
// TODO: real hologic logo
import hologicLogo from 'assets/ACE-logo-blue.svg';
import { HOST } from 'configs';

const getInitialRange = form => {
  const rangeMoment = extendMoment(moment);
  if (!form || !form.startDate || !form.endDate) return null;
  return rangeMoment.range(moment(form.startDate), moment(form.endDate));
};

const EventsNew = ({ history, match, location }) => {
  const { id } = match.params;
  const sampleEventId = location?.state?.sampleId;
  const { enqueueSnackbar } = useSnackbar();
  const [event, setEvent] = useState();
  const [fetching, setFetching] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [subscriptions, setSubscriptions] = useState([]);
  const [data, setData] = useState({
    banners: [0],
    dateRange: null,
    organazerImage: {
      url: hologicLogo,
    },
    urlCheckState: 'warning',
    initialWebinarUrl: '',
  });
  const [serverHostIP, setServerHostIP] = useState('');
  const [form, setForm] = useState({
    name: '',
    subject: '',
    eventType: 0,
    realization: 1,
    entranceType: 0,
    subscriptionPlanId: 0,
    startDate: '',
    endDate: '',
    organizer: 'Hologic',
    organizerLogoId: 0,
    webinarUrl: '',
    venue: '',
    city: '',
    state: '',
    country: '226',
    capacity: 0,
    banners: [0],
    faculties: [],
    about: '',
    workshops: [],
    programs: [],
    devicePairList: [],
    cmEs: [],
    showToLearner: false,
  });

  const steps = [
    EventGeneralStep,
    EventOrganizerStep,
    EventAuthorsStep,
    EventAboutStep,
    EventProgramStep, // only for Webinar
    EventCMEStep, // only for Webinar
    EventWorkshopStep, // only for Workshop
    EventDeviceStep, // only for Workshop
    EventCompleteStep,
  ];

  const formatDateTime = (iDate, iTime) => {
    const date = moment(iDate).format('MM/DD/YYYY');
    return moment(`${date} ${iTime}`).format('MM/DD/YYYY hh:mm:ss A');
  };

  const constructFormData = form => {
    form.startDate = moment(`${form.startDate}`).format('MM/DD/YYYY hh:mm:ss A');
    form.endDate = moment(`${form.endDate}`).format('MM/DD/YYYY hh:mm:ss A');
    form.capacity = form.capacity === '' ? 0 : form.capacity;
    form.programs = form.programs?.map(item => {
      item.startDate = formatDateTime(item.startDate, item.startTime);
      item.endDate = formatDateTime(item.endDate, item.endTime);
      return item;
    });

    //if is Workshop remove webinar releated data
    if (Number(form.eventType) === 1) {
      form.programs = [];
      form.cmEs = [];
    }

    //if is Webinar remove workshop releated data
    if (Number(form.eventType) === 0) {
      form.workshops = [];
      form.devicePairList = [];
    }

    if (Number(form.realization) === 0) {
      form.webinarUrl = form.webinarUrl.split('/events')[0];
    }

    return form;
  };

  const checkProgramValidity = programs => {
    const validitySet = new Set();
    programs.forEach(program => {
      validitySet.add(!!program.startTime);
      validitySet.add(!!program.endTime);
      validitySet.add(program.courseId !== 0);
      validitySet.add(!!program.description);
      validitySet.add(program.speakerId !== 0);
    });
    return validitySet.has(false);
  };

  const onEventSave = async () => {
    if (!checkProgramValidity(form.programs) || form.programs.length === 0) {
      try {
        setFetching(true);
        const body = constructFormData({ ...form });
        const endpoint = id ? '/wizard/event/update' : '/wizard/event/init';
        await Api.post(endpoint, { ...body, originId: id ? id : undefined });
        enqueueSnackbar(`Event successfully ${id ? 'updated' : 'created'}`, { variant: 'success' });
        history.push('/events/all');
      } catch (err) {
        enqueueSnackbar(getError(err), { variant: 'error' });
        setFetching(false);
      }
    } else {
      enqueueSnackbar('Please fill data', { variant: 'warning' });
    }
  };

  const onGoBackStep = () => {
    setActiveStep(step => step - 1);
  };

  const handleStepChange = async e => {
    e.preventDefault();
    if (activeStep === 0 && !id) {
      const isUnbranded = Number(form.realization) === 0;
      setForm({
        ...form,
        webinarUrl: isUnbranded ? form.webinarUrl.split('/events/')[0] : form.webinarUrl,
        webinarUrlPath:
          isUnbranded && !form.webinarUrlPath
            ? `/events/${form.webinarUrl.split('events/')[1]}`
            : form.webinarUrlPath || null,
      });
    }
    if (activeStep === 4) {
      if (!checkProgramValidity(form.programs) || form.programs.length === 0) {
        setActiveStep(step => step + 1);
      } else {
        enqueueSnackbar('Please fill data', { variant: 'warning' });
      }
    } else {
      setActiveStep(step => step + 1);
    }
  };

  const fillCME = (data = []) =>
    data.map(item => {
      item.link = item.link || item.externalUrl;
      return item;
    });

  const fillPrograms = (data = []) =>
    data.map(item => {
      item.startDate = item.startTime;
      item.endDate = item.endTime;
      item.startTime = moment.utc(item.startDate).format('HH:mm:ss');
      item.endTime = moment.utc(item.endDate).format('HH:mm:ss');
      item.courseId = item.course?.id;
      item.speakerId = item.authorProfile?.id;
      return item;
    });

  const fillWorkshop = (data = []) =>
    data.map(workshop => {
      workshop.hangingProtocolId = workshop.hangingProtocol?.id;
      workshop.surveyId = workshop.hasSurvey ? workshop.survey?.id : null;
      workshop.cases = workshop.workshopCases?.map(caseItem => {
        return {
          hangingProtocolId: caseItem.hangingProtocol?.id,
          surveyId: caseItem.survey?.id,
          caseId: caseItem.case.id,
        };
      });
      return workshop;
    });

  const setEventInitialData = event => {
    const pairDevices = event.devices?.filter(({ device }) => device.serviceUUID);
    const devicePairList = [...new Set(pairDevices?.map(({ device }) => device.serviceUUID))];
    const isUnbranded = event.realization === 0;
    setForm({
      name: event.name,
      subject: event.subject,
      eventType: event.eventType,
      realization: event.realization,
      entranceType: event.entranceType,
      subscriptionPlanId: event.subscriptionPlanId,
      startDate: event.startDate,
      endDate: event.endDate,
      organizer: event.organizer,
      webinarUrl: isUnbranded ? event.webinarUrl.split('/events/')[0] : event.webinarUrl,
      webinarUrlPath: isUnbranded ? `/events/${event.webinarUrl.split('events/')[1]}` : null,
      venue: event.venue,
      city: event.city,
      state: event.state,
      country: event.country,
      capacity: event.capacity,
      about: event.about,
      banners: event.banners ? event.banners.map(item => item.id) : [0],
      faculties: event.speakers ? event.speakers.map(({ authorProfile }) => authorProfile.id) : [],
      programs: fillPrograms(event.programs),
      workshops: fillWorkshop(event.workshops),
      devicePairList: devicePairList,
      cmEs: fillCME(event.cmEs),
      showToLearner: event.showToLearner,
    });
    setData({
      banners: event.banners ? event.banners.map(item => item.bannerUrl) : [0],
      organazerImage: {
        url: event.organizerLogoUrl || hologicLogo,
      },
      dateRange: getInitialRange(event),
      urlCheckState: event.webinarUrl ? 'succeed' : 'warning',
      initialWebinarUrl: event?.webinarUrl?.split('/events')?.[0] || `${HOST.API.LEARNER_DOMAIN}`,
    });
  };

  const getEventData = async id => {
    try {
      const { data } = await Api.get(`/events/event/${id}`);
      setEvent(data.data);
      setEventInitialData(data.data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      history.push('/events/all');
    }
  };

  const getSubscriptions = async () => {
    try {
      const params = { page: 1, limit: 1000, type: 4 };
      const { data } = await Api.get('/subscription/getsubscriptions', { params });
      setSubscriptions(data.data.results);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const getServerHostIP = async () => {
    try {
      const { data } = await Api.get('/common/configured-ip');
      if (data?.data) setServerHostIP(data?.data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  useEffect(() => {
    if (id || sampleEventId) getEventData(id || sampleEventId);
    getServerHostIP();
    getSubscriptions();
    //eslint-disable-next-line
  }, [id]);

  const isWorkshop = Number(form.eventType) === 1;
  const isWebinar = Number(form.eventType) === 0;

  if (isWorkshop) {
    steps.splice(steps.length - 5, 2);
  } else if (isWebinar) {
    steps.splice(steps.length - 3, 2);
  } else {
    steps.splice(steps.length - 5, 4);
  }

  const isLastStep = activeStep === steps.length - 1;
  const Step = steps[activeStep];

  if (id && !event) return <Loading className='mt-5' />;

  return (
    <div className='lesson-creation'>
      <Header steps={steps} activeStep={activeStep} setActiveStep={setActiveStep} />
      <form
        className='py-3 has-header d-flex flex-column justify-content-between min-vh-100'
        onSubmit={handleStepChange}
      >
        <div className='col-12'>
          <Step
            form={form}
            setForm={setForm}
            data={data}
            setData={setData}
            onEventSave={onEventSave}
            fetching={fetching}
            subscriptions={subscriptions}
            isWorkshop={isWorkshop}
            id={id}
            isEditing={!!id}
            serverHostIP={serverHostIP}
          />
        </div>
        <FooterButtons
          fetching={fetching}
          onGoBackStep={onGoBackStep}
          activeStep={activeStep}
          isLastStep={isLastStep}
          isNextDisabled={
            (!form.faculties.length && activeStep === 2) ||
            (Number(form.realization) === 0 && data.urlCheckState !== 'succeed' && activeStep === 1)
          }
        />
      </form>
    </div>
  );
};

export default EventsNew;
