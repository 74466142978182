export const infoKeys = {
  x00100010: 'PN',
  x00100020: '', //'',
  x00100030: '', //'DA',
  x00100040: '', //'',
  x00081030: '', //'LO',
  x00181030: '', //'LO',
  x00080050: '', //'SH',
  x00200010: '', //'SH',
  x00080020: '', //'DA',
  x00080030: '', //'TM',
  x0008103e: '', //'LO',
  x00200011: '', //'',
  x00080060: '', //'',
  x00180015: '', //'',
  x00080021: '', //'DA',
  x00080031: '', //'TM',
};

const makeRandomString = length => {
  var text = '';
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (var i = 0; i < length; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  return text;
};

const pad = (num, size) => {
  var s = num + '';
  while (s.length < size) s = '0' + s;
  return s;
};

export const makeDeIdentifiedValue = (length, vr) => {
  if (vr === 'LO' || vr === 'SH' || vr === 'PN') {
    return makeRandomString(length);
  } else if (vr === 'DA') {
    const now = new Date();
    return now.getYear() + 1900 + pad(now.getMonth() + 1, 2) + pad(now.getDate(), 2);
  } else if (vr === 'TM') {
    const now = new Date();
    return pad(now.getHours(), 2) + pad(now.getMinutes(), 2) + pad(now.getSeconds(), 2);
  } else {
    return '';
  }
};
