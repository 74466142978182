/* eslint-disable eqeqeq */
import React, { useState } from 'react';
import { IconAttachment, IconClose, IconImport, IconReload } from 'shared/components/Icons';
import { useSnackbar } from 'notistack';
import { getError } from 'utils/appHelpers';

const ImportProtocolModal = ({ close, onSuccess }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [fetching, setFetching] = useState(false);
  const [file, setFile] = useState();

  const onFileSelect = async e => {
    try {
      const file = e.target.files[0];
      setFile(file);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onUploadFile = async e => {
    try {
      setFetching(true);
      onSuccess && onSuccess();
      setFetching(false);
    } catch (err) {
      setFetching(false);
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  return (
    <div className='modal-box d-flex flex-column justify-content-between h-auto p-3'>
      <div className='d-flex justify-content-between align-items-center'>
        <p className='fz-16 mb-0'>Import Hanging Protocol</p>
        <button onClick={close} className='btn p-0 modal-close-btn'>
          <IconClose />
        </button>
      </div>
      <div className='mt-2'>
        <label
          className={`col-12 upload-wizard-block import-protocol pointer ${file ? 'has-file' : ''}`}
          htmlFor='file'
        >
          <input
            id='file'
            name='file'
            type='file'
            accept='.xls'
            className='flex-fill hide'
            onChange={onFileSelect}
          />
          <div className='d-flex flex-column align-items-center'>
            <IconImport className='mb-3' width='28' height='28' />
            Click or drag .xls file to this area to import
            {file && (
              <span className='d-flex align-items-center mt-1'>
                <IconAttachment className='mr-2' /> {file.name}
              </span>
            )}
            {file && (
              <div className='text-blue fz-12 mt-3 d-flex align-items-center'>
                Replace File <IconReload className='ml-1' />
              </div>
            )}
          </div>
        </label>
      </div>
      <div className='mt-2 d-flex justify-content-between align-items-center'>
        <button className='btn btn-md btn-outline-blue' onClick={close}>
          Cancel
        </button>
        <button className='btn btn-md btn-blue' disabled={!file || fetching} onClick={onUploadFile}>
          Import
        </button>
      </div>
    </div>
  );
};

export default ImportProtocolModal;
