import React, { useEffect, useState } from 'react';
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragOverlay,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  rectSortingStrategy,
} from '@dnd-kit/sortable';

import { SortableImgItem } from './SortableImgItem';
import { SortableColumn } from './SortableColumn';
import { DroppableUncategorizedArea } from './DroppableUncategorizedArea';
import { IconSquareEditBolded } from 'shared/components/Icons';
import Button from 'shared/components/Button';
import { RegularInput } from 'shared/components/Input/RegularInput';
import { scrollVerticallyToContainerEnd } from 'utils/domHelpers';

export const ImageCategoryDnDBlock = props => {
  const {
    unCategorizedImages = [],
    handleSelectStep,
    categories = [],
    imageColumns = [],
    setQGroups,
    groupIdx,
    questionIdx,
    handleRemoveImgColumn,
  } = props;

  const [hasStepsContainerScroll, setHasStepsContainerScroll] = useState(false);
  const [isDroppedFromColumn, setIsDroppedFromColumn] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isAtEnd, setIsAtEnd] = useState(false);
  const [activeItemId, setActiveItemId] = useState(null);
  const [overItemId, setOverItemId] = useState(null);
  const [selectedUnCategorizedItemId, setSelectedUnCategorizedItemId] = useState(null);
  const [selectedItemIdFromColumn, setSelectedItemIdFromColumn] = useState(null);
  const [isEditingCategorizedImgDesc, setIsEditingCategorizedImgDesc] = useState(false);
  const [isEditingUnCategorizedImgDesc, setIsEditingUnCategorizedImgDesc] = useState(false);

  const allColumnItems = imageColumns.map(c => c.items).flat();
  const unCategorizedRowId = 'uncategorized_row';
  let animationFrameId = null;

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 200,
        tolerance: 10,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  // Helper function to update the quiz groups
  const updateQuizGroups = (updatedUncategorized, updatedColumns) => {
    setQGroups(prevGroups => {
      const currentQuestion = prevGroups[groupIdx].questions[questionIdx];
      if (updatedUncategorized) {
        currentQuestion.unCategorizedImages = updatedUncategorized;
      }
      if (updatedColumns) {
        currentQuestion.imageColumns = updatedColumns;
      }
      const currentGroup = {
        ...prevGroups[groupIdx],
        questions: prevGroups[groupIdx].questions.toSpliced(questionIdx, 1, currentQuestion),
      };
      return prevGroups.toSpliced(groupIdx, 1, currentGroup);
    });
  };

  // Helper to get over column index
  const getOverColumnIndex = overId =>
    imageColumns.findIndex(
      column => column.id === overId || column.items.some(item => item.id === overId),
    );

  // Helper function to handle drag from a column to unCategorizedImages
  const handleFromColumnToUncategorized = (active, over) => {
    // Find the item in the column
    const columnIndex = imageColumns.findIndex(column =>
      column.items.some(item => item.id === active.id),
    );
    const itemIndex = imageColumns[columnIndex].items.findIndex(item => item.id === active.id);
    const item = imageColumns[columnIndex].items[itemIndex];

    // Remove from the column
    imageColumns[columnIndex].items.splice(itemIndex, 1);

    // Find the index in unCategorizedImages where the item will be inserted
    const indexToInsertAt = unCategorizedImages.findIndex(item => item.id === over.id);

    // Insert into unCategorizedImages
    const updatedUnCategorizedImages = [...unCategorizedImages];
    updatedUnCategorizedImages.splice(indexToInsertAt, 0, item);

    // Update state
    // setQuizConfigObj(updatedUnCategorizedImages); // Update this function to set unCategorizedImages
    updateQuizGroups(updatedUnCategorizedImages, imageColumns);
  };

  // Helper function to handle drag from unCategorizedImages to a column
  const handleFromUncategorizedToColumn = (active, over) => {
    // Find the item and remove it from unCategorizedImages
    const item = unCategorizedImages.find(image => image.id === active.id);
    const overColumnIndex = getOverColumnIndex(over.id);

    // Add to the new column
    const updatedColumnItems = [...imageColumns[overColumnIndex].items, item];
    const updatedColumns = [...imageColumns];
    updatedColumns[overColumnIndex].items = updatedColumnItems;

    // Update state
    const updatedUnCategorizedImages = [...unCategorizedImages];
    const itemIdx = updatedUnCategorizedImages.findIndex(image => image.id === active.id);
    updatedUnCategorizedImages.splice(itemIdx, 1);
    updateQuizGroups(updatedUnCategorizedImages, imageColumns);
    scrollVerticallyToContainerEnd(`column_body_items_block${imageColumns?.[overColumnIndex]?.id}`);
  };

  const handleFromColumnToColumn = (active, over) => {
    const fromColumnIndex = imageColumns.findIndex(column =>
      column.items.some(item => item.id === active.id),
    );
    const itemIndex = imageColumns[fromColumnIndex].items.findIndex(item => item.id === active.id);
    const item = imageColumns[fromColumnIndex].items[itemIndex];
    const overColumnIndex = getOverColumnIndex(over.id);

    // Only proceed if the item is not dropped in the same column
    if (fromColumnIndex === overColumnIndex) {
      return;
    }

    // Remove from the fromColumn
    imageColumns[fromColumnIndex].items.splice(itemIndex, 1);

    // Insert into the new column
    const updatedColumnItems = [...imageColumns[overColumnIndex].items, item];
    const updatedColumns = [...imageColumns];
    updatedColumns[overColumnIndex].items = updatedColumnItems;

    // Update state
    updateQuizGroups(null, imageColumns);
    scrollVerticallyToContainerEnd(`column_body_items_block${imageColumns?.[overColumnIndex]?.id}`);
  };

  const handleSortInUncategorizedList = (active, over) => {
    const fromIndex = unCategorizedImages.findIndex(item => item.id === active.id);
    const toIndex = unCategorizedImages.findIndex(item => item.id === over.id);

    // Reorder the items
    const updatedUnCategorizedImages = arrayMove(unCategorizedImages, fromIndex, toIndex);

    // Update state
    updateQuizGroups(updatedUnCategorizedImages, null);
  };

  const handleSortInsideColumn = (active, over) => {
    const fromIndex = imageColumns.findIndex(col => col.id === active.id);
    const toIndex = imageColumns.findIndex(col => col.id === over.id);
    const updatedColumns = arrayMove(imageColumns, fromIndex, toIndex);

    updateQuizGroups(null, updatedColumns);
  };

  const handleDragEnd = event => {
    const { active, over } = event;

    // Reset these regardless of whether or not `over` is null.
    setActiveItemId(null);
    setOverItemId(null);

    // If drop is outside any container, return
    if (!over) {
      return;
    }

    // Check if dragged from unCategorizedImages
    const isFromUnCategorized = unCategorizedImages.some(item => item.id === active.id);
    // Check is dragged from a column
    const isFromAColumn = allColumnItems.some(item => item.id === active.id);
    // Check if dropped on unCategorizedImages
    const isOverUnCategorized = unCategorizedImages.some(item => item.id === over.id);
    const isOveredEmptyUnCategorizedRow = over.id === unCategorizedRowId;
    // Check if dropped on a column
    const overColumnIndex = imageColumns.findIndex(
      column => column.id === over.id || column.items.some(item => item.id === over.id),
    );
    const isOverColumn = overColumnIndex !== -1;
    // Dropped on the uncategorized list from a column
    if (isFromAColumn && isOverUnCategorized) {
      handleFromColumnToUncategorized(active, over);
    }

    if (isFromUnCategorized && isOverColumn) {
      handleFromUncategorizedToColumn(active, over);
      if (selectedItemIdFromColumn === active.id) {
        setSelectedItemIdFromColumn(null);
      }
    }
    if (isFromAColumn && isOverColumn) {
      handleFromColumnToColumn(active, over);
    }

    if (isFromUnCategorized && isOverUnCategorized) {
      handleSortInUncategorizedList(active, over);
    }
    if (
      imageColumns.some(col => col.id === active.id) &&
      imageColumns.some(col => col.id === over.id)
    ) {
      handleSortInsideColumn(active, over);
    }
    if (isFromAColumn && isOveredEmptyUnCategorizedRow) {
      handleFromColumnToUncategorized(active, over);
    }
  };

  const handleDragStart = event => {
    const { active } = event;
    const isFromAColumn = allColumnItems.some(item => item.id === active.id);
    setIsDroppedFromColumn(isFromAColumn);
    setActiveItemId(active.id);
  };

  const handleDragOver = ({ over }) => {
    setOverItemId(over?.id ? over.id : null);
  };

  const handleClickCategoryNameEdit = id => {
    const currentCategoryIndex = imageColumns.findIndex(cat => cat.id === id);
    setQGroups(prevGroups => {
      const currentQuestion = prevGroups[groupIdx].questions[questionIdx];
      const updatedColumns = currentQuestion.imageColumns.toSpliced(currentCategoryIndex, 1, {
        ...currentQuestion.imageColumns[currentCategoryIndex],
        isEditing: true,
      });
      currentQuestion.imageColumns = updatedColumns;
      const currentGroup = {
        ...prevGroups[groupIdx],
        questions: prevGroups[groupIdx].questions.toSpliced(questionIdx, 1, currentQuestion),
      };
      return prevGroups.toSpliced(groupIdx, 1, currentGroup);
    });
  };

  const handleChangeColumnName = ({ value }, id) => {
    const currentCategoryIndex = imageColumns.findIndex(cat => cat.id === id);
    setQGroups(prevGroups => {
      const currentQuestion = prevGroups[groupIdx].questions[questionIdx];
      const updatedColumns = currentQuestion.imageColumns.toSpliced(currentCategoryIndex, 1, {
        ...currentQuestion.imageColumns[currentCategoryIndex],
        name: value,
      });
      currentQuestion.imageColumns = updatedColumns;
      const currentGroup = {
        ...prevGroups[groupIdx],
        questions: prevGroups[groupIdx].questions.toSpliced(questionIdx, 1, currentQuestion),
      };
      return prevGroups.toSpliced(groupIdx, 1, currentGroup);
    });
  };

  const handleSubmitCategoryName = id => {
    const currentCategoryIndex = imageColumns.findIndex(cat => cat.id === id);
    setQGroups(prevGroups => {
      const currentQuestion = prevGroups[groupIdx].questions[questionIdx];
      const updatedColumns = currentQuestion.imageColumns.toSpliced(currentCategoryIndex, 1, {
        ...currentQuestion.imageColumns[currentCategoryIndex],
        isEditing: false,
      });
      currentQuestion.imageColumns = updatedColumns;
      const currentGroup = {
        ...prevGroups[groupIdx],
        questions: prevGroups[groupIdx].questions.toSpliced(questionIdx, 1, currentQuestion),
      };
      return prevGroups.toSpliced(groupIdx, 1, currentGroup);
    });
  };

  const handleDragCancel = () => {
    setActiveItemId(null);
  };

  const handleScroll = e => {
    if (animationFrameId) {
      cancelAnimationFrame(animationFrameId);
    }

    const element = e.target;
    animationFrameId = requestAnimationFrame(() => {
      setIsScrolled(element.scrollLeft > 0);

      const totalScrollWidth = element.scrollWidth - element.clientWidth;
      setIsAtEnd(element.scrollLeft >= totalScrollWidth);
    });
  };

  const handleSelectItem = id => {
    setSelectedUnCategorizedItemId(id === selectedUnCategorizedItemId ? null : id);
    setIsEditingUnCategorizedImgDesc(false);
  };

  const handleSelectItemFromColumn = id => {
    setSelectedItemIdFromColumn(id === selectedItemIdFromColumn ? null : id);
    setIsEditingCategorizedImgDesc(false);
  };

  const handleRemoveImgItem = id => {
    const unCategorizedIndex = unCategorizedImages.findIndex(item => item.id === id);
    const isFromUnCategorized = unCategorizedIndex !== -1;

    // Check if the item is in imageColumns
    const columnIndex = imageColumns.findIndex(column => column.items.some(item => item.id === id));
    const itemIndex =
      columnIndex !== -1 ? imageColumns[columnIndex].items.findIndex(item => item.id === id) : -1;
    const isFromAColumn = columnIndex !== -1 && itemIndex !== -1;

    // Update the state based on the item's location
    setQGroups(prevGroups => {
      const currentQuestion = { ...prevGroups[groupIdx].questions[questionIdx] };

      if (isFromUnCategorized) {
        currentQuestion.unCategorizedImages.splice(unCategorizedIndex, 1);
      }

      if (isFromAColumn) {
        currentQuestion.imageColumns[columnIndex].items.splice(itemIndex, 1);
      }

      const currentGroup = {
        ...prevGroups[groupIdx],
        questions: prevGroups[groupIdx].questions.toSpliced(questionIdx, 1, currentQuestion),
      };

      return prevGroups.toSpliced(groupIdx, 1, currentGroup);
    });
  };

  const handleClickDescriptionEdit = () => {
    setIsEditingUnCategorizedImgDesc(true);
  };

  const handleClickCategorizedDescEdit = () => setIsEditingCategorizedImgDesc(true);

  const handleChangeDescription = ({ name, value }, id) => {
    setQGroups(prevGroups => {
      const currentGroup = { ...prevGroups[groupIdx] };
      const currentQuestion = { ...currentGroup.questions[questionIdx] };

      // Find in unCategorizedImages and update
      const unCategorizedIndex = currentQuestion.unCategorizedImages.findIndex(
        item => item.id === id,
      );
      if (unCategorizedIndex !== -1) {
        currentQuestion.unCategorizedImages[unCategorizedIndex][name] = value;
      }

      // Find in imageColumns and update
      const columnIndex = currentQuestion.imageColumns.findIndex(column =>
        column.items.some(item => item.id === id),
      );
      if (columnIndex !== -1) {
        const itemIndex = currentQuestion.imageColumns[columnIndex].items.findIndex(
          item => item.id === id,
        );
        currentQuestion.imageColumns[columnIndex].items[itemIndex][name] = value;
      }

      // Update currentGroup and prevGroups
      currentGroup.questions = currentGroup.questions.map((q, idx) =>
        idx === questionIdx ? currentQuestion : q,
      );
      return prevGroups.map((g, idx) => (idx === groupIdx ? currentGroup : g));
    });
  };

  useEffect(() => {
    const stepsContainer = document.getElementById('add_steps_draggable');
    if (stepsContainer) {
      setHasStepsContainerScroll(stepsContainer?.scrollWidth > stepsContainer?.clientWidth);
    }
  }, [unCategorizedImages]);

  useEffect(() => {
    return () => {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
    };
  }, []);

  return (
    <div className='dnd_block_container'>
      <h6 className='title'>Drag and drop your image in to corresponding category</h6>

      <div className='selected_item_desc_block'>
        <Button
          className='btn btn_transparent'
          onClick={e => {
            if (
              !selectedUnCategorizedItemId ||
              !unCategorizedImages.some(img => img.id === selectedUnCategorizedItemId)
            ) {
              return;
            }
            e.preventDefault();
            handleClickDescriptionEdit(selectedUnCategorizedItemId);
          }}
        >
          <IconSquareEditBolded />
        </Button>
        {isEditingUnCategorizedImgDesc ? (
          <RegularInput
            autoFocus
            className='w-100'
            inputClassName='pl-0'
            name='description'
            handleChange={({ target }) =>
              handleChangeDescription(target, selectedUnCategorizedItemId)
            }
            value={
              unCategorizedImages.find(img => img.id === selectedUnCategorizedItemId)?.description
            }
            onEnter={() => setIsEditingUnCategorizedImgDesc(false)}
            placeholder='Please type description and press Enter'
          />
        ) : (
          <span className='img_desc'>
            {selectedUnCategorizedItemId &&
              (unCategorizedImages.find(img => img.id === selectedUnCategorizedItemId)
                ?.description || (
                <span className='disabled_img_desc'>
                  The item has no description. Click edit to add.
                </span>
              ))}
            {!selectedUnCategorizedItemId && (
              <span className='disabled_img_desc'>Select image for entering related text.</span>
            )}
          </span>
        )}
      </div>
      <DndContext
        sensors={sensors}
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
        onDragCancel={handleDragCancel}
        onDragOver={handleDragOver}
      >
        <SortableContext
          items={[
            ...unCategorizedImages,
            ...imageColumns.reduce((acc, column) => acc.concat(column.items), []),
          ]}
          strategy={rectSortingStrategy}
        >
          <div className='steps_part_block'>
            <div className='absolute_overlay'>
              {hasStepsContainerScroll && isScrolled && <div className='gradient start' />}
              {hasStepsContainerScroll && !isAtEnd && <div className='gradient end' />}
            </div>
            <DroppableUncategorizedArea id={unCategorizedRowId} handleScroll={handleScroll}>
              <SortableContext items={unCategorizedImages} strategy={rectSortingStrategy}>
                {unCategorizedImages.map((item, index) => {
                  const assignedCategoryTitle = categories.find(
                    cat => Number(cat.id) === Number(item.assignedCategory),
                  )?.category;
                  return (
                    <SortableImgItem
                      handleSelectItem={handleSelectItem}
                      activeItemId={activeItemId}
                      isSelected={selectedUnCategorizedItemId === item.id}
                      overItemId={isDroppedFromColumn && overItemId}
                      handleSelectStep={handleSelectStep}
                      handleRemoveItem={handleRemoveImgItem}
                      categoryTitle={assignedCategoryTitle}
                      size='medium'
                      key={item.id}
                      id={item.id}
                      index={index}
                      {...item}
                    />
                  );
                })}
              </SortableContext>
            </DroppableUncategorizedArea>
          </div>

          <SortableContext items={imageColumns.map(col => col.id)} strategy={rectSortingStrategy}>
            <div id='img_cat_edit_columns' className='columns_container custom_scrollbar_block'>
              {imageColumns.map((column, columnIndex) => {
                return (
                  <SortableColumn
                    questionIdx={questionIdx}
                    columnIndex={columnIndex}
                    handleRemoveColumn={handleRemoveImgColumn}
                    handleChangeColumnName={handleChangeColumnName}
                    handleClickNameEdit={handleClickCategoryNameEdit}
                    handleSubmitName={handleSubmitCategoryName}
                    column={column}
                    overColumnId={!imageColumns.some(({ id }) => id === activeItemId) && overItemId}
                    id={column.id}
                    key={column.id}
                  >
                    <SortableContext
                      items={column.items.map(item => item.id)}
                      strategy={rectSortingStrategy}
                    >
                      <div
                        id={`column_body_items_block${column.id}`}
                        className='column_body_items_block'
                      >
                        <div className='empty_col_img_space' />
                        {column.items.map((item, index) => (
                          <SortableImgItem
                            handleSelectItem={handleSelectItemFromColumn}
                            size='small'
                            handleRemoveItem={handleRemoveImgItem}
                            isSelected={selectedItemIdFromColumn === item.id}
                            activeItemId={activeItemId}
                            key={item.id}
                            id={item.id}
                            index={index}
                            {...item}
                          />
                        ))}
                      </div>
                    </SortableContext>
                  </SortableColumn>
                );
              })}
            </div>
          </SortableContext>
          <div className='selected_item_desc_block mt-4'>
            <Button
              className='btn btn_transparent'
              onClick={e => {
                if (
                  !selectedItemIdFromColumn ||
                  !allColumnItems.some(imgItem => imgItem.id === selectedItemIdFromColumn)
                ) {
                  return;
                }
                e.preventDefault();
                handleClickCategorizedDescEdit(selectedItemIdFromColumn);
              }}
            >
              <IconSquareEditBolded />
            </Button>
            {isEditingCategorizedImgDesc ? (
              <RegularInput
                autoFocus
                className='w-100'
                inputClassName='pl-0'
                name='description'
                handleChange={({ target }) =>
                  handleChangeDescription(target, selectedItemIdFromColumn)
                }
                value={allColumnItems.find(img => img.id === selectedItemIdFromColumn)?.description}
                onEnter={() => setIsEditingCategorizedImgDesc(false)}
                placeholder='Please type description and press Enter'
              />
            ) : (
              <span className='img_desc'>
                {selectedItemIdFromColumn &&
                  (allColumnItems.find(img => img.id === selectedItemIdFromColumn)?.description || (
                    <span className='disabled_img_desc'>
                      The item has no description. Click edit to add.
                    </span>
                  ))}
                {!selectedItemIdFromColumn && (
                  <span className='disabled_img_desc'>Select image for entering related text.</span>
                )}
              </span>
            )}
          </div>
        </SortableContext>
        {!imageColumns.some(({ id }) => id === activeItemId) && (
          <DragOverlay dropAnimation={null}>
            {activeItemId ? (
              <SortableImgItem
                {...[...unCategorizedImages, ...allColumnItems].find(
                  item => item.id === activeItemId,
                )}
                size={allColumnItems.some(item => item.id === activeItemId) ? 'small' : 'medium'}
                isActiveDragging={true} // You can use this prop to style the overlay if you want
              />
            ) : null}
          </DragOverlay>
        )}
      </DndContext>
    </div>
  );
};
