import React from 'react';

const Button = ({
  id,
  children,
  name,
  disabled = false,
  type = 'button',
  className = '',
  onClick,
  size = '',
  buttonType = '',
}) => (
  <button
    id={id}
    name={name}
    onClick={onClick}
    className={`btn ${className} -${size} ${buttonType}`}
    type={type}
    disabled={disabled}
  >
    {children}
  </button>
);

export default Button;
