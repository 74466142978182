import React from 'react';
import { Link } from 'react-router-dom';

const TestHeader = ({ user }) => {
  return (
    <div className='users-header d-flex justify-content-between align-items-center'>
      <div className='d-flex breadcrumbs'>
        <Link to='/users/all'>All Users</Link>
        <span className='mx-2'>/</span>
        <span>
          <Link to={`/user/${user.id}`}>
            {user.firstName} {user.lastName}
          </Link>
        </span>
        <span className='mx-2'>/</span>
        <span>Connection and Device test</span>
      </div>
    </div>
  );
};

export default TestHeader;
