import moment from 'moment';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import EditingSelect from 'shared/components/editingForms/EditingSelect';
import EditingTextArea from 'shared/components/editingForms/EditingTextArea';
import { Api } from 'utils/connectors';
import EventProgramDateBlock from './EventProgramDateBlock';
import { useSnackbar } from 'notistack';
import { getError } from 'utils/appHelpers';
import { IconBin } from 'shared/components/Icons';
import TimePicker from 'react-time-picker';

const momentUTC = date => moment(date).format('MM/DD/YYYY');

const constructPrograms = programs => {
  if (!programs || !programs.length) return false;
  const dates = {};
  programs.forEach(item => {
    const date = momentUTC(item.startDate);
    dates[date] = dates[date] || [];
    dates[date].push(item);
  });
  return dates;
};

const getEmptyProgram = date => {
  return {
    description: '',
    startDate: date,
    endDate: date,
    courseId: 0,
    speakerId: 0,
    id: Date.now(),
  };
};

const getEmptyProgramsWithDate = ({ startDate, endDate }) => {
  const programs = [];
  const now = new Date(endDate);
  for (var d = new Date(startDate); d <= now; d.setDate(d.getDate() + 1)) {
    programs.push(getEmptyProgram(moment(new Date(d)).format()));
  }
  return programs;
};

const EventProgramStep = ({ subscriptions, form, setForm }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [authors, setAuthors] = useState([]);
  const [activeDate, setActiveDate] = useState();

  const initPrograms = initialData => {
    if (initialData && initialData.length) {
      setActiveDate(momentUTC(initialData[initialData.length - 1].startDate));
      return;
    }
    const programs = [...getEmptyProgramsWithDate(form)].map(item => {
      const startDate = momentUTC(item.startDate);
      const initItem = initialData.find(k => momentUTC(k.startDate) === startDate);
      return initItem || item;
    });
    setForm({ ...form, programs });
    if (programs && programs.length) setActiveDate(momentUTC(programs[0].startDate));
  };

  const onProgramChange = (item, { target }) => {
    const tempPrograms = [...form.programs];
    const currentIndex = tempPrograms.findIndex(
      i => i.id === item.id && i.endDate === item.endDate,
    );
    const { name, value } = target;
    tempPrograms[currentIndex][name] = value;
    setForm({ ...form, programs: [...tempPrograms] });
  };

  const onProgramTimeChange = (item, index, name, value) => {
    onProgramChange(item, { target: { name, value } });
  };

  const addProgram = () => {
    const tempPrograms = [...form.programs];
    tempPrograms.push(getEmptyProgram(moment(new Date(activeDate)).format()));
    setForm({ ...form, programs: [...tempPrograms] });
  };

  const removeProgram = item => {
    const tempPrograms = [...form.programs];
    const index = tempPrograms.findIndex(i => i.id === item.id && i.endDate === item.endDate);
    tempPrograms.splice(index, 1);
    setForm({ ...form, programs: [...tempPrograms] });
  };

  const getAuthors = async () => {
    try {
      const { data } = await Api.get('/wizard/authors');
      const result = data.map(i => {
        i.fullname = `${i.firstName} ${i.lastName}`;
        return i;
      });
      setAuthors(result);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  useEffect(() => {
    initPrograms([...form.programs]);
  }, []);

  useEffect(() => {
    getAuthors();
  }, []);

  const programs = constructPrograms(form.programs);
  const activePrograms = programs[activeDate];
  const activeAuthors = authors ? authors?.filter(i => form.faculties.includes(i.authorId)) : [];
  const activeSubs = subscriptions.find(i => i.id === Number(form.subscriptionPlanId));
  const courses = activeSubs ? activeSubs.courses : [];
  const activeCourses = courses.map(i => ({ ...i, ...i.course }));

  return (
    <div className='px-2'>
      <h6 className='color-blue mb-3'>Program Configuration</h6>
      <EventProgramDateBlock
        data={Object.keys(programs)}
        activeItem={activeDate}
        onClickItem={setActiveDate}
      />
      {activePrograms &&
        activePrograms.map((item, index) => {
          const isLast = index + 1 === activePrograms.length;
          return (
            <div className='col-9 p-0 user-creation-form mb-4' key={index}>
              <p className='text-blue font-weight-bold mb-2'>
                {moment(activeDate).format('MMM DD:')}
              </p>
              <ul className='p-0 mb-0'>
                <div className='d-flex'>
                  <li className='d-flex align-items-center col-6 justify-content-between'>
                    <div className='col-6 pl-0'>
                      <label className='m-0'>Start Time*:</label>
                    </div>
                    <TimePicker
                      onChange={onProgramTimeChange.bind(null, item, index, 'startTime')}
                      disableClock={true}
                      value={item.startTime}
                    />
                  </li>
                  <li className='d-flex align-items-center col ml-3 justify-content-between'>
                    <div className='col-6 pl-0'>
                      <label className='m-0'>End Time*:</label>
                    </div>
                    <TimePicker
                      onChange={onProgramTimeChange.bind(null, item, index, 'endTime')}
                      disableClock={true}
                      value={item.endTime}
                    />
                  </li>
                </div>
                <li className='d-flex align-items-center'>
                  <div className='col-3 pl-0'>
                    <label className='m-0'>Course Selection*:</label>
                  </div>
                  <EditingSelect
                    name='courseId'
                    onChange={onProgramChange.bind(null, item)}
                    value={item.courseId}
                    items={activeCourses}
                    useProperty='courseId'
                    displayProperty='title'
                    required={true}
                    valueClassName='pl-0'
                    editing={true}
                  />
                </li>
                <li className='d-flex align-items-center'>
                  <div className='col-3 pl-0'>
                    <label className='m-0'>Faculty Selection*:</label>
                  </div>
                  <EditingSelect
                    name='speakerId'
                    onChange={onProgramChange.bind(null, item)}
                    value={item.speakerId}
                    items={activeAuthors}
                    useProperty='authorId'
                    displayProperty='fullname'
                    required={true}
                    valueClassName='pl-0'
                    editing={true}
                  />
                </li>
                <li className='d-flex'>
                  <div className='col-3 pl-0'>
                    <label className='m-0'>Short Description*:</label>
                  </div>
                  <EditingTextArea
                    type='text'
                    name='description'
                    value={item.description || ''}
                    onChange={onProgramChange.bind(null, item)}
                    className='p-0 is-large'
                    placeholder='Enter Description'
                    required
                    editing={true}
                    access={true}
                  />
                </li>
              </ul>
              <div className='mt-3 d-flex align-items-center justify-content-between'>
                <button
                  type='button'
                  className='btn btn-sm pl-0 text-danger d-flex align-items-center'
                  onClick={removeProgram.bind(null, item)}
                  disabled={activePrograms.length < 2}
                >
                  <IconBin className={'mr-2'} />
                  Remove Program
                </button>
                {isLast && (
                  <button
                    type='button'
                    className='btn btn-outline-blue add-new-frames fz-14'
                    onClick={addProgram}
                  >
                    Add Program +
                  </button>
                )}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default EventProgramStep;
