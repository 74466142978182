export const rolesKayes = {
  1: 'Lesson',
  2: 'Lesson_Status',
  3: 'Course',
  4: 'Course_Status',
  5: 'Course_Share',
  6: 'Subscription',
  7: 'Hanging_Protocols',
  8: 'Certificates',
  9: 'Dicoms',
  10: 'Cases',
  11: 'User',
  12: 'Entity',
  13: 'License',
  14: 'Faculty',
  15: 'Admin',
  16: 'Event',
  17: 'Device',
  18: 'Room',
  19: 'Survey',
  20: 'Support',
  21: 'Report',
  22: 'System',
  23: 'Role',
  24: 'Access Origin Access',
  25: 'Region Access',
  26: 'Webinar Access',
  27: 'Notification Access',
  28: 'Email Configuration Access',
  29: 'User Action Access',
  30: 'System Events Access',
  31: 'User Request Access',
  32: 'Dashboard Statistics Access',
  33: 'Banner Configuration Access',
  34: 'Lesson Share Access',
  38: 'Learner Login Access',
  41: 'Quiz Access',
  43: 'Marketing Material Lessons Access',
  44: 'Marketing Material Courses Access',
  45: 'Product Access',
  46: 'GAID Access',
};

export const backRolesFrontMap = {
  1: {
    view: ['lessons_list_view', 'education_lessons_view'],
    edit: ['lessons_create', 'education_lessons_create'],
  },
  2: {
    view: [],
    edit: ['lesson_publish'],
  },
  3: {
    view: ['courses_list_view', 'course_view', 'education_course_view'],
    edit: ['courses_create', 'education_course_create'],
  },
  4: {
    view: [],
    edit: ['course_publish'],
  },
  5: {
    view: [],
    edit: ['course_share'],
  },
  6: {
    view: ['subscriptions_list_view', 'subscriptions_view', 'subscription_view'],
    edit: ['subscription_create', 'subscription_request_confirm', 'publish_subscription'],
  },
  7: {
    view: ['protocols_list_view'],
    edit: ['protocol_create'],
  },
  8: {
    view: ['certificates_list_view'],
    edit: ['certificates_create'],
  },
  9: {
    view: ['warehouse_cases_list_view'],
    edit: ['warehouse_cases_create'], // (This is may be about permission for uploading dicom file, because case is created automatically)
  },
  10: {
    view: ['cases_list_view'],
    edit: ['cases_create'],
  },
  11: {
    view: ['users_list_view', 'user_view', 'user_progress_view', 'user_test_history_view'],
    edit: ['users_create', 'users_edit'],
  },
  12: {
    view: ['entity_list_view', 'entity_user_list_view'],
    edit: ['entity_create', 'entity_user_create'],
  },
  13: {
    view: ['license_list_view'],
    edit: ['license_create'],
  },
  14: {
    view: ['authors_list_view', 'author_view'],
    edit: ['author_create'],
  },
  15: {
    view: ['admins_list_view', 'admin_view'],
    edit: ['admin_create'],
  },
  16: {
    view: ['events_list_view'],
    edit: ['event_create'],
  },
  17: {
    view: ['devices_list_pairs_view', 'devices_list_view'],
    edit: ['device_remove', 'device_pair'],
  },
  18: {
    view: ['room_360_list'],
    edit: ['room_360_create'],
  },
  19: {
    view: ['surveys_list_view'],
    edit: ['survey_create'],
  },
  20: {
    view: ['support_tickets_view'],
    edit: [],
  },
  21: {
    view: ['reports_export'],
    edit: ['reports_import'],
  },
  22: {
    view: ['system_emails_view', 'system_logs_view', 'system_spotlights_view'],
    edit: ['system_emails_edit', 'system_spotlights_edit'],
  },
  23: {
    view: ['admins_roles_view'],
    edit: ['admins_roles_create'],
  },
  24: {
    view: [],
    edit: [],
  },
  25: {
    view: ['system_countries_view', 'system_regions_view'],
    edit: ['system_countries_create', 'system_regions_create'],
  },
  26: {
    view: [],
    edit: [],
  },
  27: {
    view: ['notification_read'],
    edit: [],
  },
  28: {
    view: [],
    edit: ['email_configuration'],
  },
  29: {
    view: [],
    edit: [],
  },
  30: {
    view: [],
    edit: [],
  },
  31: {
    view: ['subscriptions_join_request_view'],
    edit: [],
  },
  32: {
    view: ['home_view'],
    edit: [],
  },
  33: {
    view: [],
    edit: [],
  },
  34: {
    view: [],
    edit: ['can_invite_entity_from_learners'],
  },
  38: {
    view: ['learner_login_access'],
    edit: ['learner_login_access'],
  },
  41: {
    view: ['quiz_view'],
    edit: ['quiz_create'],
  },
  43: {
    view: ['lessons_list_view', 'marketing_lessons_view'],
    edit: ['lessons_create', 'marketing_lessons_create'],
  },
  44: {
    view: ['courses_list_view', 'course_view', 'marketing_course_view'],
    edit: ['courses_create', 'marketing_course_create'],
  },
  45: {
    view: ['products_view'],
    edit: ['products_create'],
  },
  46: {
    view: ['gaid_view'],
    edit: ['gaid_create'],
  },
};

export const globalPermissions = [];

export const PERMISSION_KEY_BY_NAME = {
  Lesson: 1,
  Lesson_Status: 2,
  Course: 3,
  Course_Status: 4,
  Course_Share: 5,
  Subscription: 6,
  Hanging_Protocols: 7,
  Certificates: 8,
  Dicoms: 9,
  Cases: 10,
  User: 11,
  Entity: 12,
  License: 13,
  Faculty: 14,
  Admin: 15,
  Event: 16,
  Device: 17,
  Room: 18,
  Survey: 19,
  Support: 20,
  Report: 21,
  System: 22,
  Role: 23,
  Access_Origin_Access: 24,
  Region_Access: 25,
  Webinar_Access: 26,
  Notification_Access: 27,
  Email_Configuration_Access: 28,
  User_Action_Access: 29,
  System_Events_Access: 30,
  User_Request_Access: 31,
  Dashboard_Statistics_Access: 32,
  Banner_Configuration_Access: 33,
  Lesson_Share_Access: 34,
  Learner_Login_Access: 38,
  Marketing_Material_Courses_Access: 44,
  Marketing_Material_Lessons_Access: 43,
  Product_Access: 45,
  GAID_Access: 46,
};
