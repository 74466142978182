import React from 'react';
import { Draggable, DragDropContext, Droppable } from 'react-beautiful-dnd';

const DragList = ({
  onDragEnd,
  items,
  children,
  className,
  rowClassName,
  disabled,
  direction = 'vertical',
  grid = 14,
}) => {
  const isHorizontal = direction === 'horizontal';

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    margin: `0 ${grid}px 0 0`,
    ...draggableStyle,
  });

  const getListStyle = isDraggingOver => ({
    display: 'flex',
    padding: '16px 0px',
    overflow: 'auto',
  });

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='droppable' direction={direction}>
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            className={className}
            ref={provided.innerRef}
            style={isHorizontal ? getListStyle(snapshot.isDraggingOver) : {}}
          >
            {items &&
              items.map((item, index) => (
                <Draggable
                  key={index}
                  draggableId={String(index + 1)}
                  index={index}
                  isDragDisabled={disabled}
                >
                  {(provided, snapshot) => {
                    return (
                      <div
                        className={rowClassName || 'mb-3'}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={
                          isHorizontal
                            ? getItemStyle(snapshot.isDragging, provided.draggableProps.style)
                            : { ...provided.draggableProps.style }
                        }
                      >
                        {children(item, index, snapshot)}
                      </div>
                    );
                  }}
                </Draggable>
              ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DragList;
