import React, { memo } from 'react';

const LandingSelect = memo(
  ({
    name,
    value,
    onChange,
    onBlur,
    label,
    items,
    className,
    selectClassName,
    disabled,
    topLabel,
    uiType,
    useProperty,
    displayProperty = 'name',
  }) => (
    <div className={`form-select ${className} ${value ? 'selected' : 'non-selected'} ${uiType}`}>
      {topLabel && <label>{topLabel}</label>}
      <select
        className={selectClassName}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        name={name}
        id={name}
        autoComplete='off'
        disabled={disabled}
        required
      >
        {label && <option value=''>{label}</option>}
        {items &&
          items.length &&
          items.map((item, index) => {
            const { code, id } = item;
            const value = useProperty ? item[useProperty] : code || id;
            return (
              <option disabled={item.disabled} key={item[displayProperty] + index} value={value}>
                {item[displayProperty]}
              </option>
            );
          })}
      </select>
    </div>
  ),
);

export default LandingSelect;
