import React from 'react';
import { useState } from 'react';
import Chart from 'react-google-charts';
import { IconArrowDown, IconArrowUp } from 'shared/components/Icons';
import Loading from 'shared/components/Loading';

const colors = [
  '#FFB100',
  '#008AAD',
  '#E3E35D',
  '#7C9A34',
  '#477EFF',
  '#8B4A8E',
  '#EB4D4D',
  '#349A4C',
  '#131F6B',
  '#264653',
  '#2a9d8f',
  '#e9c46a',
  '#f4a261',
  '#e76f51',
  '#8ecae6',
  '#219ebc',
  '#023047',
  '#ffb703',
  '#fb8500',
  '#480ca8',
  '#e07a5f',
  '#81b29a',
  '#ef476f',
];

const BlockAttendance = ({ data, fetching }) => {
  const [openState, setOpenState] = useState(true);
  const sortedData = data.sort((a, b) => parseFloat(b.count) - parseFloat(a.count));
  const dataArr = sortedData.map((item, i) => [item.state, i + 1, item.count]);
  const chartData = [['State', 'Index', 'Users'], ...dataArr];
  const chartColors = [...colors].slice(0, dataArr.length);
  const noData = [['Country', 'Latitude'], ['USA', 0]];

  const chartOptions = {
    colorAxis: { colors: chartColors },
    datalessRegionColor: '#ECECEC',
    legend: 'none',
    displayMode: 'regions',
    resolution: 'provinces',
    region: 'US',
  };

  return (
    <div
      className={`dash-block block-attendance d-flex flex-column w-100 ${
        openState ? 'opened' : 'closed'
      }`}
    >
      <div className='align-items-center block-title d-flex justify-content-between'>
        Attendance US
        <button className='btn p-0' onClick={() => setOpenState(state => !state)}>
          {openState ? <IconArrowDown /> : <IconArrowUp />}
        </button>
      </div>
      {!fetching && (
        <div className='d-flex'>
          <div className='col-9'>
            <Chart
              chartType='GeoChart'
              width='100%'
              height='250px'
              data={data && data.length ? chartData : noData}
              options={chartOptions}
              mapsApiKey='AIzaSyA6hE6lXrnKL0oOw0ludhroGqfP6ow4iHE'
            />
          </div>
          <div className='col-3 align-items-center col-3 d-flex p-0'>
            <ul className='courses-list w-100'>
              {sortedData.map((item, i) => {
                return (
                  <li key={i} className='d-flex align-items-center justify-content-between'>
                    <span className='circle' style={{ backgroundColor: chartColors[i] }} />
                    <p>{item.state}</p>
                    <b>{item.count}</b>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      )}
      {fetching && <Loading className='mt-5' />}
    </div>
  );
};

export default BlockAttendance;
