import React, { useEffect, useState } from 'react';
import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';
import { useSnackbar } from 'notistack';
import Loading from 'shared/components/Loading';

const ProtocolSelectionModal = ({ close, onSelectProtocol }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [protocols, setProtocols] = useState([]);
  const [activeId, setActiveId] = useState();

  const onSelectItem = item => {
    setActiveId(item.id);
  };

  const getProtocols = async () => {
    try {
      const { data } = await Api.get(`/hanging-protocol/all`, { params: { limit: 1000 } });
      setProtocols(data.data.results);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onCloseModal = () => {
    const item = protocols.find(item => item.id === activeId);
    onSelectProtocol(item);
    close();
  };

  useEffect(() => {
    getProtocols();
  }, []);

  return (
    <div className='modal-box case-select-modal d-flex flex-column'>
      <div className='m-header'>
        <h5>Choose Hanging Protocol</h5>
      </div>
      <div className='m-content flex-fill'>
        {protocols &&
          protocols.map((item, i) => {
            return (
              <label
                key={i}
                className='case-item pointer w-100 d-flex align-items-center justify-content-between'
                htmlFor={`radio${i}`}
              >
                <LessonCaseRow item={item} />
                <div className='d-flex'>
                  <input
                    type='checkbox'
                    id={`radio${i}`}
                    className='input-custom is-blue'
                    onChange={onSelectItem.bind(null, item)}
                    checked={activeId === item.id}
                  />
                  <label className='m-0' htmlFor={`radio${i}`} />
                </div>
              </label>
            );
          })}
        {!protocols.length && <Loading className='mt-5' />}
      </div>
      <div className='m-footer text-center px-4 pt-3'>
        <button className='btn-blue btn px-4' onClick={onCloseModal}>
          Done
        </button>
      </div>
    </div>
  );
};

const InfoIcon = ({ name, value, className }) => (
  <p className={`mb-0 info-row-item ${className || ''}`}>
    {name}: <b className='text-blue'>{value}</b>
  </p>
);

const LessonCaseRow = ({ item, className }) => {
  return (
    <div className={className}>
      <div className='case-item-title d-flex align-items-center mb-2'>{item.name}</div>
      <div className='d-flex mb-1'>
        <InfoIcon name='ID' value={item.id} />
        <InfoIcon name='Step Count' value={item.steps.length} />
      </div>
    </div>
  );
};

export default ProtocolSelectionModal;
