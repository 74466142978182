import React from 'react';
import { useSnackbar } from 'notistack';
import * as IconList from 'shared/components/Icons';

const Icons = () => {
  const { enqueueSnackbar } = useSnackbar();
  const keys = Object.keys(IconList);
  const bindKey = key => `<${key} />`;

  const copyToClipboard = str => {
    const el = document.createElement('textarea');
    el.value = `<${str} />`;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    enqueueSnackbar(`The <${str} /> Successfully Copied!`, { variant: 'success' });
  };

  return (
    <div className='p-5 col-8 icons-list'>
      <ul className='list-group'>
        {keys.map(key => {
          const Icon = IconList[key];
          return (
            <li className='list-group-item d-flex px-0 align-items-center' key={key}>
              <div className='col-6 d-flex'>
                <Icon />
              </div>
              <div className='col-4'>
                <span className='text-lg text-primary'>{bindKey(key)}</span>
              </div>
              <div className='col-2 text-right'>
                <button
                  type='button'
                  className='btn btn-primary btn-sm ml-2'
                  onClick={() => copyToClipboard(key)}
                >
                  Copy
                </button>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Icons;
