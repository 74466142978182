import React from 'react';
import ClickArea from 'shared/components/ClickArea';
import Loading from 'shared/components/Loading';
import moment from 'moment';
import ListActionButtons from 'shared/components/ListActionButtons';
import { hasAccess } from 'utils/permissionHelper';

const headers = [
  {
    name: 'Name',
    className: 'col d-flex justify-content-between align-items-center',
    key: 'name',
  },
  {
    name: 'Date',
    className: 'col',
    key: 'startDate',
  },
  {
    name: 'Type',
    className: 'col',
    key: 'eventType',
  },
  {
    name: 'Status',
    className: 'col',
    key: 'startDate',
  },
  {
    name: 'Actions',
    className: 'p-2 d-flex action-buttons-container min_132',
  },
];

const eventTypes = {
  0: 'Webinar',
  1: 'WorkShop',
};

const SubscriptionsLists = ({ list, order, changeOrder, fetch, openEventStats }) => {
  if (fetch) return <Loading className='mt-5' />;

  if (!list || !list.length)
    return <div className='text-black-50 text-center mt-4'>No Results Found</div>;

  const handleOrderChange = item => {
    if (!item.key) return;
    changeOrder({
      key: item.key,
      order: !order.order,
    });
  };

  const getStatus = (startDate, endDate) => {
    const isOnGoing =
      moment(startDate).diff(new Date()) <= 0 && moment(endDate).diff(new Date()) > 0;
    const isExpired = moment(endDate).diff(new Date()) <= 0;
    return isExpired ? 'Archived' : isOnGoing ? 'Ongoing' : 'Upcoming';
  };

  const actions = [
    {
      type: 'button',
      icon: 'profit',
      title: 'Profit',
      onClick: item => openEventStats(item.id),
    },
    { type: 'link', icon: 'view', title: 'View', to: item => `/event/${item.id}` },
    {
      type: 'link',
      icon: 'edit',
      title: 'Edit',
      to: item => `/events/${item.id}/edit`,
      hide: () => !hasAccess('event_create'),
    },
    {
      type: 'link',
      icon: 'copyAndCreate',
      title: 'Copy & Create',
      to: () => `/events/new`,
      hide: () => !hasAccess('event_create'),
    },
  ];

  return (
    <div className='users-lists'>
      <ul className='p-0'>
        <li className='d-flex list-header align-items-center'>
          {headers.map((item, index) => (
            <ClickArea
              key={item.name + index}
              className={`text-body d-flex align-items-center ${item.className}`}
              onClick={handleOrderChange.bind(null, item)}
            >
              {item.name}
              {item.key && order.orderKey === item.key && (
                <button
                  className={`btn ml-2 p-0 dropdown-toggle ${order.order ? '' : 'rotate-180'}`}
                />
              )}
            </ClickArea>
          ))}
        </li>
        {list &&
          list.map(item => (
            <li key={item.id} className='d-flex list-item'>
              <div className='d-flex col align-items-center'>{item.name}</div>
              <div className='col d-flex align-items-center'>
                {moment(item.startDate).format('MM/DD/YYYY')} -{' '}
                {moment(item.endDate).format('MM/DD/YYYY')}
              </div>
              <div className='col d-flex align-items-center'>{eventTypes[item.eventType]}</div>
              <div className='col d-flex align-items-center'>
                {getStatus(item.startDate, item.endDate)}
              </div>
              <div className='p-2 d-flex action-buttons-container align-items-center min_132'>
                <ListActionButtons actions={actions} item={item} />
              </div>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default SubscriptionsLists;
