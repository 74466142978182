import React from 'react';
import { sumShapes } from '../utils';

const GenuineMode = ({ dicomData, activeTool, changeGenuieMode, removeMode }) => {
  return (
    <>
      {!removeMode && (
        <div className='genuie-modes d-flex flex-column'>
          {activeTool?.shapes.map((item, i) => {
            const active = activeTool?.shape === item?.shape;
            const Icon = item.icon;
            const shapesCount = sumShapes(dicomData.images, item?.shape);
            return (
              <button
                key={i}
                onClick={changeGenuieMode.bind(null, item?.shape)}
                className={`btn btn-sm`}
              >
                <Icon color={active ? '#00B5ED' : undefined} />
                {!!shapesCount && <span>{shapesCount}</span>}
              </button>
            );
          })}
        </div>
      )}
    </>
  );
};

export default GenuineMode;
