import React from 'react';
import Slider from 'react-slick';
import Author from './Author';

const sliderSettings = {
  speed: 500,
  infinite: true,
  slidesToShow: 4,
  slidesToScroll: 1,
};

const AuthorsList = ({ data }) => {
  sliderSettings.slidesToShow = data.length < 4 ? data.length : 4;
  const col = data.length < 4 ? data.length * 3 : 12;

  return (
    <div className={`px-4 col-${col}`}>
      <Slider {...sliderSettings}>
        {data.map(({ id, authorProfile }, index) => (
          <Author data={authorProfile} key={`${index}_${index}`} />
        ))}
      </Slider>
    </div>
  );
};

export default AuthorsList;
