import React from 'react';
import PlanProgress from './PlanProgress';
import CourseProgress from './CourseProgress';
import Accordion from 'app/Main/components/Accordion';

const ProgressList = ({
  data = [],
  onPassCourse,
  onPassLesson,
  passCourseFetch,
  passLessonFetch,
}) => {
  return (
    <Accordion
      data={data}
      HeaderComponent={PlanProgress}
      BodyComponent={CourseProgress}
      actions={{ onPassCourse, onPassLesson }}
      states={{ passCourseFetch, passLessonFetch }}
      childrenKey='courses'
      className='course-accordion'
    />
  );
};

export default ProgressList;
