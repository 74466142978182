/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, makeStyles } from '@material-ui/core';
import ClickArea from 'shared/components/ClickArea';
import Loading from 'shared/components/Loading';

const useStyles = makeStyles({
  root: {
    margin: '0 0 16px 0 !important',
    borderRadius: 10,
  },
  listItem: {
    display: 'block',
    padding: '0',
    margin: '0 !important',
  },
  summary: {
    padding: '0',
  },
  expandIcon: {
    position: 'absolute',
    right: -4,
  },
  details: {
    backgroundColor: '#EFF4FB',
    padding: '8px 0',
  },
});

const AccordionTable = ({
  list,
  order,
  changeOrder = () => {},
  fetch,
  onItemRemoveConfirm,
  onItemRemoveDisabled,
  handleCreateLinkClick,
  headers = [],
  actions = [],
  renderSummaryChild,
  renderDetailsChild,
  containerClassName,
}) => {
  const classes = useStyles();
  if (fetch) return <Loading className='mt-5' />;
  if (!list || !list.length)
    return <div className='text-black-50 text-center mt-4'>No Results Found</div>;

  return (
    <div className={`users-lists table-accordion-container ${containerClassName}`}>
      <ul className='p-0'>
        <li className='d-flex list-header align-items-center'>
          {headers.map((item, index) => (
            <ClickArea
              key={item.name + index}
              className={`d-flex text-body justify-content-between align-items-center ${
                item.className
              }`}
              onClick={changeOrder.bind(null, item)}
            >
              {item.name}
              {item?.key && order?.orderKey === item?.key && (
                <button
                  className={`btn ml-2 p-0 dropdown-toggle ${order?.order ? '' : 'rotate-180'}`}
                />
              )}
            </ClickArea>
          ))}
        </li>
        {list &&
          list.map((item, idx) => {
            return (
              <Accordion className={classes.root} key={item.id} square>
                <AccordionSummary
                  aria-controls={`panel${idx}a-content`}
                  id={`panel${idx}a-header`}
                  className={classes.summary}
                  classes={{ content: classes.listItem }}
                >
                  {renderSummaryChild(item)}
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                  {renderDetailsChild(item)}
                </AccordionDetails>
              </Accordion>
            );
          })}
      </ul>
    </div>
  );
};

export default AccordionTable;
