import React, { useState, useEffect, useCallback } from 'react';
import ReactModal from 'react-modal';
import { useSnackbar } from 'notistack';
import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';
import Header from './components/Header';
import ReactPaginate from 'react-paginate';
import Lists from './components/Lists';
import useDebounce from 'shared/hooks/useDebounce';
import { IconArrowLeft, IconArrowRight } from 'shared/components/Icons';
import { confirmAlert } from 'react-confirm-alert';
import UserLicenseKeysModal from './components/UserLicenseKeysModal';

const EntityUsers = ({ match, history }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { id } = match.params;
  const [fetch, setFetch] = useState(false);
  const [removeFetch, setRemoveFetch] = useState(false);
  const [data, setData] = useState([]);
  const [keysModal, setKeysModal] = useState(null);

  const [order, setOrder] = useState({ order: true, orderKey: 'title' });
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);

  const getData = useCallback(async () => {
    try {
      setFetch(true);
      const params = { ...order, page: page + 1, limit: 8, search: debouncedSearch };
      const { data } = await Api.get(`/entity/users/entity/${id}`, { params });
      setPageCount(Math.ceil(data.data.count / 8));
      setData(data.data.results);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetch(false);
    }
  }, [debouncedSearch, enqueueSnackbar, order, page, id]);

  const onPageChange = ({ selected }) => {
    setPage(selected);
  };

  const handleSearchChange = text => {
    setSearch(text);
  };

  const onItemRemove = async item => {
    try {
      setRemoveFetch(true);
      await Api.post(`/entity/users/entity/remove`, {
        userId: item.userId,
        email: item.email,
        entityId: id,
      });
      await getData();
      enqueueSnackbar('Successfully removed', { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setRemoveFetch(false);
    }
  };

  const onItemRemoveConfirm = item => {
    const message =
      item.isManager && item.userStatus === 1
        ? 'This Entity Manager will be deactivated and will not be able to login to platform anymore. Are you sure you what to deactivate Entity Manager now?'
        : 'Do you really want to remove the user from the Entity?';
    const confirmBtn = item.isManager ? 'DEACTIVATE' : 'REMOVE';

    confirmAlert({
      title: 'User Removal',
      message,
      buttons: [
        {
          label: 'CANCEL',
        },
        {
          label: confirmBtn,
          onClick: () => onItemRemove(item),
        },
      ],
    });
  };

  const onItemKeysClick = item => {
    setKeysModal(item.userId);
  };

  useEffect(() => {
    getData();
    //eslint-disable-next-line
  }, [getData, page, order]);

  return (
    <div>
      <Header onSearch={handleSearchChange} history={history} entityId={id} />
      <div className='py-3 has-header'>
        <div className='col-12'>
          <Lists
            list={data}
            order={order}
            changeOrder={setOrder}
            fetch={fetch}
            onItemRemoveConfirm={onItemRemoveConfirm}
            onItemRemoveDisabled={removeFetch}
            onItemKeysClick={onItemKeysClick}
            getData={getData}
            entityId={id}
          />
        </div>
        {pageCount > 1 && !fetch && (
          <ReactPaginate
            previousLabel={<IconArrowLeft />}
            nextLabel={<IconArrowRight />}
            breakLabel={'...'}
            forcePage={page}
            breakClassName={'break-me'}
            pageCount={pageCount}
            marginPagesDisplayed={3}
            pageRangeDisplayed={3}
            onPageChange={onPageChange}
            containerClassName={`pagination show-arrow`}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
          />
        )}
      </div>
      <ReactModal isOpen={!!keysModal} className='custom-modal'>
        <UserLicenseKeysModal
          user={data.find(item => item.userId === keysModal)}
          entityId={id}
          close={() => setKeysModal(null)}
          onSuccess={getData}
        />
      </ReactModal>
    </div>
  );
};

export default EntityUsers;
