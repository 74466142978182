/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import ClickArea from 'shared/components/ClickArea';
import Loading from 'shared/components/Loading';
import ListActionButtons from 'shared/components/ListActionButtons';
import { formatDate } from 'utils/appHelpers';
import { courseModes } from 'app/Main/configs';
import { hasAccess } from 'utils/permissionHelper';
import { IconUsers } from 'shared/components/Icons';
import { useSelector } from 'react-redux';
import { hideCourseManageActions } from 'utils/permissions';

const headers = [
  {
    name: 'Course title',
    className: 'col',
    key: 'title',
  },
  {
    name: 'Faculty',
    className: 'col',
    key: 'faculty',
  },
  {
    name: 'Status',
    className: 'col',
    key: 'mode',
  },
  {
    name: 'Registered',
    className: 'col',
    key: 'registeredUsers',
  },
  {
    name: 'Completed',
    className: 'col',
    key: 'completedUsers',
  },
  {
    name: 'Date Added',
    className: 'col',
    key: 'createdAt',
  },
  {
    name: 'Actions',
    className: 'p-2 d-flex action-buttons-container min_132',
    key: '',
  },
];

const CoursesLists = ({
  list,
  order,
  changeOrder,
  fetch,
  onItemRemoveConfirm,
  onItemRemoveDisabled,
}) => {
  const account = useSelector(state => state.account);
  if (fetch) return <Loading className='mt-5' />;
  if (!list || !list.length)
    return <div className='text-black-50 text-center mt-4'>No Results Found</div>;

  const actions = [
    { type: 'link', icon: 'view', title: 'View', to: item => `/course/${item.id}/view` },
    {
      type: 'link',
      icon: 'quizView',
      getIcon: item => (item?.isQuizAvailable ? 'hasQuizView' : 'quizView'),
      title: 'QuizView',
      getClassName: item => (item?.isQuizAvailable ? 'action_btn_filled' : ''),
      to: item => `/course/${item.id}/quiz-view`,
      hide: () => !hasAccess('quiz_view'),
    },
    {
      type: 'link',
      icon: 'edit',
      title: 'Edit',
      disable: item =>
        (item.isMarketingMaterial && !hasAccess('marketing_course_create')) ||
        (!item.isMarketingMaterial && !hasAccess('education_course_create')),
      to: item => `/course/${item.id}/edit`,
      hide: hideCourseManageActions.bind(null, account),
    },
    {
      type: 'link',
      icon: 'copyAndCreate',
      title: 'Copy & Create',
      disable: item =>
        (item.isMarketingMaterial && !hasAccess('marketing_course_create')) ||
        (!item.isMarketingMaterial && !hasAccess('education_course_create')),
      to: () => `/courses/new`,
      hide: hideCourseManageActions.bind(null, account),
    },
    {
      type: 'button',
      icon: 'delete',
      title: 'Remove',
      disabled: onItemRemoveDisabled,
      onClick: item => onItemRemoveConfirm(item),
      hide: hideCourseManageActions.bind(null, account),
    },
  ];

  return (
    <div className='users-lists'>
      <ul className='p-0'>
        <li className='d-flex list-header align-items-center'>
          {headers.map((item, index) => (
            <ClickArea
              key={item.name + index}
              className={`d-flex text-body justify-content-between align-items-center ${
                item.className
              }`}
              onClick={changeOrder.bind(null, item)}
            >
              {item.name}
              {item.key && order.orderKey === item.key && (
                <button
                  className={`btn ml-2 p-0 dropdown-toggle ${order.order ? '' : 'rotate-180'}`}
                />
              )}
            </ClickArea>
          ))}
        </li>
        {list &&
          list.map(item => {
            const isShared = !!item.courseShares.length;
            return (
              <li key={item.id} className='d-flex list-item h-not-set'>
                <div className='d-flex col align-items-center justify-content-between'>
                  {item.title}
                  {isShared && hasAccess('kol_user') && (
                    <span title='Shared' className='ml-2'>
                      <IconUsers color='#131f6b' />
                    </span>
                  )}
                </div>
                <div className='col align-items-center d-flex'>{item.faculty || '0'} faculty</div>
                <div className='col align-items-center d-flex'>{courseModes[item.mode]}</div>
                <div className='col align-items-center d-flex'>
                  {item?.statistics?.registeredUsers?.total || '0'} users
                </div>
                <div className='col align-items-center d-flex'>
                  {item?.statistics?.completedUsers?.total || '0'} users
                </div>
                <div className='col align-items-center d-flex'>
                  {formatDate(item.createdAt) || '-'}
                </div>
                <div className='p-2 d-flex action-buttons-container align-items-center min_132'>
                  <ListActionButtons actions={actions} item={item} />
                </div>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default CoursesLists;
