import React from 'react';
import ResponsiveImage from 'shared/components/ResponsiveImage';

const ImageContent = ({ data }) => {
  return (
    <div>
      <ResponsiveImage imgUrl={data.contentUrl} alt='img' />
    </div>
  );
};

export default ImageContent;
