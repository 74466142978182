import React, { useState } from 'react';

const isRequired = key => TypeError(`the ${key} prop is required!`);

const Accordion = ({
  data = [],
  HeaderComponent = isRequired('HeaderComponent'),
  BodyComponent = isRequired('BodyComponent'),
  childrenKey = isRequired('childrenKey'),
  childrenClassName = 'mb-4',
  contentClassName = '',
  className = '',
  actions,
  states,
  noDefaultActiveIndex,
}) => {
  const [activeIndex, setActiveIndex] = useState(noDefaultActiveIndex ? null : 0);

  const toggleAccordion = index => setActiveIndex(activeIndex === index ? null : index);

  return (
    <div className={`accordion ${className}`}>
      {data?.length > 0 &&
        data.map((group, index) => {
          return (
            <ul className='accordion-item' key={`plan-${group.id || index}`}>
              <li
                className={`item-header ${activeIndex === index ? 'active' : ''}`}
                onClick={() => toggleAccordion(index)}
                role='presentation'
              >
                <HeaderComponent data={group} />
              </li>
              {group[childrenKey].length > 0 && activeIndex === index && (
                <li className={`item-content ${contentClassName}`}>
                  {group?.info && <p className='mb-0 mb-3 text-muted info-dsc'>{group.info}</p>}
                  <ul className='accordion-sub-item'>
                    {group[childrenKey].map(item => (
                      <li key={`item-${item.id || item.QuestionID}`} className={childrenClassName}>
                        <BodyComponent actions={actions} states={states} data={item} />
                      </li>
                    ))}
                  </ul>
                </li>
              )}
            </ul>
          );
        })}
    </div>
  );
};

export default Accordion;
