import {
  IconUsers,
  IconHome,
  IconCourses,
  IconLessons,
  IconAdmins,
  IconRoles,
  IconAuthorsNew2,
  IconStatistics,
  IconKey,
  IconSubscription,
  IconContacts,
  IconReports,
  IconWarehouse,
  IconEntity,
  IconCertificates,
  IconCog,
  IconHanging,
  IconCases,
  IconEvents,
  Room360Icon,
  IconProducts,
} from 'shared/components/Icons';
import { reportTypes } from 'configs';

const reportNav = reportTypes.map(item => {
  return {
    name: item.name,
    path: `/reports/${item.value}`,
    permission: 'reports_import',
  };
});

export const navigation = [
  {
    name: 'Home',
    path: '/home',
    permission: 'home_view',
    icon: IconHome,
  },
  {
    name: 'Entity',
    path: '/entity',
    permission: 'entity_manager',
    icon: IconEntity,
  },
  {
    name: 'Subscriptions',
    path: '/subscriptions/all',
    permission: 'subscriptions_view',
    activeNavs: [
      '/subscriptions/new',
      '/subscription/requests/all',
      '/subscriptions/all',
      'subscription',
    ],
    icon: IconSubscription,
    menu: [
      {
        name: 'Add New plan',
        path: '/subscriptions/new',
        permission: 'subscription_create',
      },
      {
        name: 'Requests',
        path: '/subscription/requests/all',
        permission: 'subscriptions_join_request_view',
      },
      {
        name: 'All plans',
        path: '/subscriptions/all',
        permission: 'subscriptions_list_view',
      },
    ],
  },
  {
    name: 'Courses',
    path: '/courses/all',
    permission: 'courses_list_view',
    activeNavs: [
      '/courses/new',
      '/courses/all',
      '/courses/statistics',
      'course',
      '/courses/preview',
      'courses',
    ],
    icon: IconCourses,
    menu: [
      {
        name: 'Add New Course',
        path: '/courses/new',
        permission: 'courses_create',
      },
      {
        name: 'All Courses',
        path: '/courses/all',
        permission: 'courses_list_view',
      },
      {
        name: 'Courses Statistics',
        path: '/courses/statistics',
        permission: 'courses_statistics_view',
      },
    ],
  },
  {
    name: 'Lessons',
    path: '/lessons/all',
    permission: 'lessons_list_view',
    activeNavs: ['/lessons/new', '/lessons/all', 'lesson', 'lessons'],
    icon: IconLessons,
    menu: [
      {
        name: 'Add New Lesson',
        path: '/lessons/new',
        permission: 'lessons_create',
      },
      {
        name: 'All Lessons',
        path: '/lessons/all',
        permission: 'lessons_list_view',
      },
    ],
  },
  {
    name: 'Hanging Protocols',
    path: '/protocols/all',
    permission: 'protocols_list_view',
    activeNavs: ['/protocols/new', '/protocols/all', 'protocol'],
    icon: IconHanging,
    menu: [
      {
        name: 'Add Hanging Protocol',
        path: '/protocols/new',
        permission: 'protocol_create',
      },
      {
        name: 'All Hanging Protocols',
        path: '/protocols/all',
        permission: 'protocols_list_view',
      },
    ],
  },
  {
    name: 'Certificates',
    path: '/certificates',
    permission: 'certificates_list_view',
    activeNavs: ['/certificate/new', '/certificates', 'certificate'],
    icon: IconCertificates,
    menu: [
      {
        name: 'Add New Certificates',
        path: '/certificate/new',
        permission: 'certificates_create',
      },
      {
        name: 'All Certificates',
        path: '/certificates',
        permission: 'certificates_list_view',
      },
    ],
  },
  {
    name: 'Media Warehouse',
    path: '/warehouse/cases/all',
    permission: 'warehouse_cases_list_view',
    activeNavs: ['/warehouse/cases/new', '/warehouse/cases/all', 'warehouse'],
    icon: IconWarehouse,
    iconClassName: 'ml-1',
    menu: [
      {
        name: 'Upload New DICOM',
        path: '/warehouse/cases/new',
        permission: 'warehouse_cases_create',
      },
      {
        name: 'All Cases',
        path: '/warehouse/cases/all',
        permission: 'warehouse_cases_list_view',
      },
    ],
  },
  {
    name: 'Cases',
    path: '/cases/all',
    permission: 'cases_list_view',
    activeNavs: ['/cases/all', 'case'],
    icon: IconCases,
    iconClassName: 'ml-1',
    menu: [
      {
        name: 'All Cases',
        path: '/cases/all',
        permission: 'cases_list_view',
      },
    ],
  },
  {
    name: 'Users',
    path: '/users/all',
    permission: 'users_list_view',
    activeNavs: ['/users/all', '/users/new', 'user', 'users'],
    icon: IconUsers,
    menu: [
      {
        name: 'Invite New User',
        path: '/users/new',
        permission: 'users_create',
      },
      {
        name: 'All Users',
        path: '/users/all',
        permission: 'users_list_view',
      },
    ],
  },
  {
    name: 'Entities',
    path: '/entities',
    permission: 'entity_list_view',
    activeNavs: ['/entity/new', '/entities', 'entity'],
    icon: IconEntity,
    iconClassName: 'ml-1',
    menu: [
      {
        name: 'Add New Entity',
        path: '/entity/new',
        permission: 'entity_create',
      },
      {
        name: 'All Entities',
        path: '/entities',
        permission: 'entity_list_view',
      },
    ],
  },
  {
    name: 'Licenses',
    path: '/licenses/all',
    permission: 'license_list_view',
    activeNavs: ['/licenses/new', '/licenses/all', 'licenses', 'license'],
    icon: IconKey,
    iconClassName: 'ml-1',
    menu: [
      {
        name: 'Add New License',
        path: '/licenses/new',
        permission: 'license_create',
      },
      {
        name: 'All License Keys',
        path: '/licenses/all',
        permission: 'license_list_view',
      },
    ],
  },
  {
    name: 'Faculty',
    path: '/authors/all',
    permission: 'authors_list_view',
    icon: IconAuthorsNew2,
    activeNavs: ['/authors/new', '/authors/all', 'author'],
    menu: [
      {
        name: 'Create New Faculty',
        path: '/authors/new',
        permission: 'author_create',
      },
      {
        name: 'All Faculty',
        path: '/authors/all',
        permission: 'authors_list_view',
      },
    ],
  },
  {
    name: 'Statistics',
    path: '/statistics',
    permission: 'statistics_view',
    icon: IconStatistics,
  },
  {
    name: 'Admins',
    path: '/admins/all',
    permission: 'admins_list_view',
    activeNavs: ['/admins/all', '/admins/new', 'admin', 'admins'],
    icon: IconAdmins,
    menu: [
      {
        name: 'Invite New Admin',
        path: '/admins/new',
        permission: 'admin_create',
      },
      {
        name: 'All Admins',
        path: '/admins/all',
        permission: 'admins_list_view',
      },
    ],
  },
  {
    name: 'User Roles',
    path: '/roles/all',
    permission: 'admins_roles_view',
    activeNavs: ['/roles/new', '/roles/all', 'roles', 'role'],
    icon: IconRoles,
    menu: [
      {
        name: 'Add new role',
        path: '/roles/new',
        permission: 'admins_roles_create',
      },
      {
        name: 'All roles',
        path: '/roles/all',
        permission: 'admins_roles_view',
      },
    ],
  },
  {
    name: 'Products',
    path: '/products',
    permission: 'products_view',
    activeNavs: ['product', '/products', '/product/new'],
    icon: IconProducts,
    menu: [
      {
        name: 'Add New Product',
        path: '/product/new',
        permission: 'products_create',
      },
      {
        name: 'All Products',
        path: '/products',
        permission: 'products_view',
      },
    ],
  },
  {
    name: 'Events',
    path: '/events/all',
    permission: 'events_list_view',
    activeNavs: ['/events/new', '/events/all', '/events/1', '/events/2', 'events', 'event'],
    icon: IconEvents,
    menu: [
      {
        name: 'Create New Event',
        path: '/events/new',
        permission: 'event_create',
      },
      {
        name: 'All Events',
        path: '/events/all',
        permission: 'events_list_view',
      },
      {
        name: 'Upcoming',
        path: '/events/1',
        permission: 'events_list_view',
      },
      {
        name: 'Past',
        path: '/events/2',
        permission: 'events_list_view',
      },
    ],
  },
  {
    name: 'Devices',
    path: '/devices/all',
    permission: 'devices_list_view',
    activeNavs: ['/devices/all', '/devices/pairs'],
    icon: IconAdmins,
    menu: [
      {
        name: 'All Devices',
        path: '/devices/all',
        permission: 'devices_list_view',
      },
      {
        name: 'Device Pairs',
        path: '/devices/pairs',
        permission: 'devices_list_pairs_view',
      },
    ],
  },
  {
    name: '360 Rooms',
    path: '/rooms',
    permission: 'room_360_list',
    activeNavs: ['/rooms'],
    icon: Room360Icon,
  },
  {
    name: 'Surveys',
    path: '/surveys',
    permission: 'surveys_list_view',
    activeNavs: ['/surveys', '/survey/new', 'survey'],
    icon: IconEvents,
    menu: [
      {
        name: 'Add New Survey',
        path: '/survey/new',
        permission: 'survey_create',
      },
      {
        name: 'All surveys',
        path: '/surveys',
        permission: 'surveys_list_view',
      },
    ],
  },
  {
    name: 'Support',
    path: '/support/tickets',
    permission: 'support_tickets_view',
    activeNavs: ['/support/tickets', 'support'],
    icon: IconContacts,
    menu: [
      {
        name: 'All Requests',
        path: '/support/tickets',
        permission: 'support_tickets_view',
      },
    ],
  },
  {
    name: 'Reports',
    path: reportNav[0].path,
    permission: 'reports_import',
    activeNavs: reportNav.map(i => i.path),
    icon: IconReports,
    menu: [...reportNav],
  },
  {
    name: 'System',
    path: '/system/logs',
    permission: 'system_logs_view',
    activeNavs: ['system'],
    icon: IconCog,
    menu: [
      {
        name: 'Logs',
        path: '/system/logs',
        permission: 'system_logs_view',
      },
      {
        name: 'Countries',
        path: '/system/countries',
        permission: 'system_countries_view',
      },
      {
        name: 'Regions',
        path: '/system/regions',
        permission: 'system_regions_view',
      },
      {
        name: 'System Emails',
        path: '/system/emails',
        permission: 'system_emails_view',
      },
      {
        name: 'Marketing Spotlight',
        path: '/system/marketing-spotlights',
        permission: 'system_spotlights_view',
      },
    ],
  },
];
