import React from 'react';
import { IconSearch } from 'shared/components/Icons';

const Header = ({ search, onSearch, createNewDevice }) => {
  return (
    <div className='users-header d-flex justify-content-between align-items-center'>
      <div className='d-flex'>
        <button className='btn btn-invite' onClick={createNewDevice}>
          + Add New Device
        </button>
      </div>
      <div className='d-flex'>
        <div className='search-block'>
          <input
            type='text'
            value={search}
            className='form-control'
            placeholder='Search by Device Name or ID'
            onChange={({ target }) => onSearch(target.value)}
          />
          <IconSearch />
        </div>
      </div>
    </div>
  );
};

export default Header;
