import React from 'react';
import { Link, NavLink, useRouteMatch } from 'react-router-dom';
import { reportTypes } from 'configs';

const filters = [{ name: 'Import', path: '/import' }, { name: 'Export', path: '/export' }];

const ReportHeader = () => {
  const match = useRouteMatch();
  const { type } = match.params;

  const activeItem = reportTypes.find(i => i.value === type);

  return (
    <div className='users-header d-flex justify-content-between align-items-center'>
      <div className='d-flex'>
        <Link className='btn btn-invite' to={`/reports/` + type + '/create'}>
          + Create New
        </Link>
        <div className='d-flex breadcrumbs'>
          <div className='align-items-center d-flex mx-3 filters'>
            <NavLink exact className='mx-3' to={`/reports/` + type} activeClassName='active'>
              {activeItem && activeItem.name}
            </NavLink>
          </div>
        </div>
      </div>
      <div className='d-flex'>
        <div className='align-items-center d-flex mx-3 filters'>
          {filters.map(item => (
            <NavLink
              key={item.path}
              exact
              className='mx-3'
              to={`/reports/` + type + item.path}
              activeClassName='active'
            >
              {item.name}
            </NavLink>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ReportHeader;
