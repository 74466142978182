import React from 'react';
import { Link, useParams } from 'react-router-dom';

const ProductUsersHeader = ({ productName, isCompleted }) => {
  const { productId } = useParams();

  return (
    <div className='users-header d-flex justify-content-between align-items-center'>
      <div className='d-flex breadcrumbs'>
        <Link to='/products'>Products</Link>
        <span className='mx-2'>/</span>
        <Link to={`/product/${productId}/view`}>{productName}</Link>
        <span className='mx-2'>/</span>
        <span>{`${isCompleted ? 'Completed' : 'Registered'} Users`}</span>
      </div>
    </div>
  );
};

export default ProductUsersHeader;
