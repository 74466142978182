import React from 'react';
import Select from 'shared/components/Select';

const EditingSelect = ({
  value,
  onChange,
  name,
  items,
  required,
  useProperty,
  displayProperty,
  className,
  editing,
  hideDefault,
  label,
  valueClassName,
  forceValue,
  disabled,
  parentClassName,
  emptyValueClassName,
  disabledType,
  lightGrayLabel,
}) => {
  const getName = value => {
    let result = items.find(i => Number(i[useProperty]) === Number(value));
    return result ? result[displayProperty] : value;
  };

  return editing ? (
    <Select
      emptyValueClassName={emptyValueClassName}
      parentClassName={parentClassName}
      name={name}
      onChange={onChange}
      value={value}
      items={items}
      required={required}
      useProperty={useProperty}
      label={label}
      displayProperty={displayProperty}
      className={className}
      hideDefault={hideDefault}
      disabled={disabled}
      disabledType={disabledType}
      lightGrayLabel={lightGrayLabel}
    />
  ) : (
    <span className={`result ${valueClassName || ''}`}>
      {forceValue ? forceValue : getName(value)}
    </span>
  );
};
export default EditingSelect;
