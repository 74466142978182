import React, { useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { Switch, BrowserRouter as Router } from 'react-router-dom';

import { WithTitleRoute } from 'utils/permissionHelper';
import { getAuthState } from './Auth/actions';
import { Api, setAuthToken } from 'utils/connectors';
import { saveLocalToken } from 'utils/tokenHelpers';
import Loading from 'shared/components/Loading';

// Load Routes
import Auth from './Auth';
import Main from './Main';
import Public from './Public';

const App = ({ getAuthState, isAuthenticated }) => {
  useEffect(() => {
    // Get the authToken from cookies
    const cookie = document.cookie.split(';').find(item => item.trim().startsWith('authToken='));
    const authToken = cookie?.split('=')[1];

    if (authToken) {
      // Save the authToken to local storage and set auth header
      saveLocalToken(authToken);
      setAuthToken(authToken, Api);
    }

    if (isAuthenticated === null) getAuthState();
  }, [getAuthState, isAuthenticated]);

  return (
    <Fragment>
      <Router>
        {isAuthenticated !== null && (
          <Switch>
            <WithTitleRoute path='/survey/list' component={Public} />
            <WithTitleRoute path='/' component={isAuthenticated ? Main : Auth} />
          </Switch>
        )}
        {isAuthenticated == null && <Loading />}
      </Router>
    </Fragment>
  );
};

const mapStateToProps = ({ isAuthenticated }) => ({
  isAuthenticated,
});

export default connect(
  mapStateToProps,
  { getAuthState },
)(App);
