export const getQuestionSetTriggerText = (type, value) => {
  switch (type) {
    case 0:
      return `at the completion of ${value}% of the Workshop`;

    case 1:
      return `at the completion of ${value}% of the Case`;
    case 2:
      return `at the opening of the Step ${value}`;
    default:
      return null;
  }
};
