/* eslint-disable eqeqeq */
import React from 'react';
import ClickArea from 'shared/components/ClickArea';
import RadioBox from 'shared/components/RadioBox';

const headers = [
  {
    name: 'Name',
    className: 'col-4',
  },
  {
    name: 'Description',
    className: 'col-5',
  },
  {
    name: 'View',
    className: 'col-1 text-center',
  },
  {
    name: 'Edit',
    className: 'col-1 text-center',
  },
  {
    name: 'None',
    className: 'col-1 text-center',
  },
];

const PermissionsList = ({ items, role, setRole }) => {
  if (!items || !items.length)
    return <div className='text-black-50 text-center mt-4'>No Results Found</div>;

  const handleItemChange = (key, access) => {
    const tempRole = { ...role };
    if (access) {
      tempRole.permissions[key] = access;
    } else {
      delete tempRole.permissions[key];
    }
    setRole(tempRole);
  };

  return (
    <div className='users-lists'>
      <ul className='p-0'>
        <li className='d-flex list-header align-items-center'>
          {headers.map((item, index) => (
            <ClickArea key={item.name + index} className={`text-body ${item.className}`}>
              {item.name}
            </ClickArea>
          ))}
        </li>
        {items &&
          items.map(item => {
            const currentAccess = role.permissions[item.key];
            return (
              <li key={item.id} className='d-flex align-items-center list-item'>
                <div className='col-4'>{item.name}</div>
                <div className='col-5'>{item.description}</div>
                <div className='col-1 form-radio-circle'>
                  <RadioBox
                    className='mb-2 checkbox-item short_height mr-3'
                    name={`permission1${item.key}`}
                    value={item.key}
                    label=' '
                    checked={Number(currentAccess) === 1}
                    onChange={handleItemChange.bind(null, item.key, 1)}
                  />
                </div>
                <div className='col-1 form-radio-circle'>
                  <RadioBox
                    className='mb-2 checkbox-item short_height mr-3'
                    name={`permission2${item.key}`}
                    value={item.key}
                    label=' '
                    checked={Number(currentAccess) === 2}
                    onChange={handleItemChange.bind(null, item.key, 2)}
                  />
                </div>
                <div className='col-1 form-radio-circle'>
                  <RadioBox
                    className='mb-2 checkbox-item short_height mr-3'
                    name={`permission3${item.key}`}
                    value={item.key}
                    label=' '
                    checked={!currentAccess}
                    onChange={handleItemChange.bind(null, item.key, 0)}
                  />
                </div>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default PermissionsList;
