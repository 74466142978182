import React from 'react';
import { DropDown, DropdownHeader, DropdownBody } from 'shared/components/DropDown';
import ResponsiveImage from 'shared/components/ResponsiveImage';

const avatarPlaceholder =
  'https://i1.wp.com/ggrmlawfirm.com/wp-content/uploads/avatar-placeholder.png?fit=256%2C256&ssl=1';

const AuthorsDropdown = ({ data = [] }) => {
  return (
    <DropDown>
      <DropdownHeader>
        <span className='mr-2 d-flex'>Faculty</span>
      </DropdownHeader>
      <DropdownBody>
        <ul className='authors-dropdown'>
          {data.length > 0 &&
            data.map((item, index) => {
              const { name = '', surname, profession = '', imageUrl } = item;
              return (
                <li className='drop-item' key={index}>
                  <div className='d-flex'>
                    <div className='author-image'>
                      <ResponsiveImage imgUrl={imageUrl || avatarPlaceholder} alt={name} />
                    </div>
                    <p className='text-truncate d-flex flex-column justify-content-center m-0'>
                      <span className='text-truncate item-name'>
                        {name} {surname}
                      </span>
                      <span className='text-truncate item-description'>{profession}</span>
                    </p>
                  </div>
                </li>
              );
            })}
          {!data.length && <li className='drop-item disabled text-center'>No results found...</li>}
        </ul>
      </DropdownBody>
    </DropDown>
  );
};

export default AuthorsDropdown;
