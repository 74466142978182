/* eslint-disable eqeqeq */
import React, { useState } from 'react';
import { useSnackbar } from 'notistack';

import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';

import { SwitchIOSLike } from 'shared/components/SwitchIOSLike';
import Button from 'shared/components/Button';
import DeviceBlock from './DeviceBlock';

import { IconClose } from 'shared/components/Icons';

const AddDeviceModal = ({ close, onSuccess, onAssignPair }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isCheckedPairDevice, setIsCheckedPairDevice] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [createdDevice, setCreatedDevice] = useState(null);
  const [devicesPair, setDevicesPair] = useState(null);

  const handleSwitchPairDevice = () => setIsCheckedPairDevice(prev => !prev);

  const handleCreate = async () => {
    setIsCreating(true);
    try {
      if (isCheckedPairDevice) {
        const res = await Api.post('/devices/request', {
          deviceNamePrefix: 'Manual Created',
          deviceCount: 2,
          createPair: true,
        });
        const masterDevice = res.data.data.find(device => device.isMaster);
        const clientDevice = res.data.data.find(device => !device.isMaster);
        setDevicesPair({ masterDevice, clientDevice });
      } else {
        const res = await Api.post('/devices/request', {
          deviceNamePrefix: 'Manual Created',
          deviceCount: 1,
          createPair: false,
        });
        const deviceDetails = res?.data?.data?.[0];
        setCreatedDevice({
          id: deviceDetails?.id,
          deviceID: deviceDetails?.deviceID,
          deviceName: deviceDetails?.deviceName,
        });
      }
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }

    setIsCreating(false);
  };

  const handleClickAssignPair = () => {
    onAssignPair(createdDevice);
    close();
  };

  const handleClickDone = () => {
    close();
    onSuccess();
  };

  return (
    <div className='modal-box d-flex flex-column justify-content-between h-auto'>
      <div className='d-flex justify-content-between align-items-center text-right'>
        <h2 className='modal_title mb-0'>New Device</h2>
        <button onClick={close} className='btn p-0 modal-close-btn'>
          <IconClose />
        </button>
      </div>
      <div className='modal_parts_separator_line' />
      <div className='m-content'>
        <div
          className={`switch_type_container ${(!!createdDevice || !!devicesPair) && 'disabled'}`}
        >
          <SwitchIOSLike
            disabled={!!createdDevice || !!devicesPair}
            handleChange={handleSwitchPairDevice}
            checkedState={isCheckedPairDevice}
          />
          <span className='switch_label'>Create Pair Devices</span>
        </div>
        <div className='id_block_container'>
          {isCheckedPairDevice ? (
            <>
              <DeviceBlock
                title={'New Master Device'}
                deviceName={devicesPair?.masterDevice?.deviceName}
                deviceID={devicesPair?.masterDevice?.deviceID}
                isCreating={isCreating}
                hasCreated={!!devicesPair}
              />
              <DeviceBlock
                title={'New Client Device'}
                deviceName={devicesPair?.clientDevice?.deviceName}
                deviceID={devicesPair?.clientDevice?.deviceID}
                isCreating={isCreating}
                hasCreated={!!devicesPair}
              />
            </>
          ) : (
            <DeviceBlock
              title={'New Device ID'}
              deviceName={createdDevice?.deviceName}
              deviceID={createdDevice?.deviceID}
              isCreating={isCreating}
              hasCreated={!!createdDevice}
            />
          )}
        </div>
        <div className='info_block'>
          <p className='info_block_title font-weight-bold'>Info:</p>
          <div className='inputs_block_description'>
            {isCheckedPairDevice ? (
              <>
                {devicesPair ? (
                  'Two new devices with shown IDs are created and paired. This pair can be assigned to the Events now. Also these Device IDs can be used on real devices for getting configurations and events assigned to them.'
                ) : (
                  <>
                    <span>
                      Two new devices will be created and Device IDs will be assigned to them. A
                      pair with this new devices will be created.
                    </span>
                    <br /> Those Device IDs later can be used on real devices for getting
                    configurations.'
                  </>
                )}
              </>
            ) : (
              <>
                {createdDevice
                  ? 'New device is created with shown Device ID. It can be already paired with other device. This Device ID can be used on real device for getting configurations and events assigned to it.'
                  : 'New device will be created and Device ID will be assigned automatically. That Device ID later can be used on real device for getting configurations and events assigned to this device.'}
              </>
            )}
          </div>
        </div>
      </div>
      <div className='modal_parts_separator_line' />
      <div className='m-footer'>
        {createdDevice ? (
          <div className='footer_btns_block'>
            <Button
              disabled={isCreating}
              onClick={handleClickAssignPair}
              className='btn btn-outline-blue with_hover_style'
            >
              Assign Pair
            </Button>
            <Button disabled={isCreating} onClick={handleClickDone} className='btn btn-primary'>
              Done
            </Button>
          </div>
        ) : (
          <Button
            disabled={isCreating}
            onClick={devicesPair ? handleClickDone : handleCreate}
            className='btn btn-primary'
          >
            {devicesPair ? 'Done' : 'Create'}
          </Button>
        )}
      </div>
    </div>
  );
};

export default AddDeviceModal;
