import React, { useState } from 'react';

const ReuseTabExternalLink = ({ href, target, children, ...props }) => {
  const [openedWindow, setOpenedWindow] = useState(null);

  const handleClick = e => {
    e.preventDefault();

    if (openedWindow && !openedWindow.closed) {
      openedWindow.location.href = href;
      openedWindow.focus();
    } else {
      const newWindow = window.open(href, target);
      setOpenedWindow(newWindow);
    }
  };

  return (
    <a href={href} target={target} onClick={handleClick} {...props}>
      {children}
    </a>
  );
};

export default ReuseTabExternalLink;
