import React, { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useSnackbar } from 'notistack';

import { checkQuestionaryCaseCompleted } from './utils';
import { getError, getEpisodeStartTime } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import { checkLessonForPublishing, showLessonInvalidAlert } from '../LessonView/utils';
import { hasAccess } from 'utils/permissionHelper';

import FooterButtons from './components/FooterButtons';
import Header from './components/Header';
import LessonGeneralStep from './components/LessonGeneralStep';
import LessonContentFileStep from './components/LessonContentFileStep';
import LessonCompleteStep from './components/LessonCompleteStep';
import LessonVideoEpisodes from './components/LessonVideoEpisodes';
import LessonCasesStep from './components/LessonCasesStep';
import LessonCasesQuestions from './components/LessonCasesQuestions';
import LessonCasesHeatmaps from './components/LessonCasesHeatmaps';
import Loading from 'shared/components/Loading';

const publishMode = 2;

const LessonsNew = ({ history, match, location }) => {
  const id = match.params.id;
  const sampleLessonId = location?.state?.sampleId;
  const { enqueueSnackbar } = useSnackbar();
  const [isInUse, setIsInUse] = useState(false);
  const [lesson, setLesson] = useState();
  const [fetching, setFetching] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [data, setData] = useState({
    caseHeatmaps: {},
    activeCases: [],
    hasFinding: {},
    tags: [],
  });
  const [form, setForm] = useState({
    mode: 1,
    name: '',
    nickName: '',
    description: '',
    uploadImageId: 0,
    uploadContentId: 0,
    contentType: 1,
    duration: 0,
    isMarketingMaterial:
      hasAccess('marketing_lessons_create') && !hasAccess('education_lessons_create') ? 1 : 0,
  });

  const typeData = {
    1: {
      type: 'video',
      steps: [
        { component: LessonGeneralStep },
        { component: LessonContentFileStep },
        { component: LessonVideoEpisodes },
        { component: LessonCompleteStep },
      ],
    },
    2: {
      type: 'pdf',
      steps: [
        { component: LessonGeneralStep },
        { component: LessonContentFileStep },
        { component: LessonCompleteStep },
      ],
    },
    3: {
      type: 'case',
      steps: [
        { component: LessonGeneralStep },
        { component: LessonCasesStep },
        { component: LessonCasesQuestions },
        { component: LessonCasesHeatmaps },
        { component: LessonCompleteStep },
      ],
    },
    4: {
      type: 'image',
      steps: [
        { component: LessonGeneralStep },
        { component: LessonContentFileStep },
        { component: LessonCompleteStep },
      ],
    },
  };

  const constructCaseFormData = (form, data) => {
    const result = { ...form, cases: [] };
    const { activeCases, caseQuestions, caseHeatmaps, hasFinding } = data;

    const constructQuestions = id => {
      const hasQuestions = hasFinding && Number(hasFinding[id] || 0) === 2;
      if (!caseQuestions || !caseQuestions[id]) return undefined;
      return hasQuestions ? caseQuestions[id] : [];
    };

    const constructHeatmaps = id => {
      if (!caseHeatmaps || !caseHeatmaps[id]) return undefined;
      const data = caseHeatmaps[id];
      if (!data.hasHeatmap) return undefined;
      return [{ dicomId: data.dicomId, imageId: data.imageId }];
    };

    activeCases.forEach(item => {
      result.cases.push({
        caseId: item.caseId,
        caseUniqueId: item.caseUniqueId,
        questions: constructQuestions(item.caseId),
        heatmaps: constructHeatmaps(item.caseId),
      });
    });
    return result;
  };

  const constructFormData = form => {
    form.contentType = parseInt(form.contentType, 10);
    form.tags = data.tags && data.tags.map(i => i.value);
    if (form.contentType === 3) return constructCaseFormData(form, data);
    if (form.episodes && form.episodes.length) {
      form.episodes = form.episodes.filter(i => i.name);
      form.episodes = form.episodes.map((item, i) => ({
        ...item,
        canSkipAfter: parseInt(item.canSkipAfter, 10),
        duration: parseInt(item.duration, 10),
        startTime: getEpisodeStartTime(form.episodes, i, true),
      }));
    }
    return form;
  };

  const handleSaveLesson = async formData => {
    const isEditing = !!id;
    const body = formData || constructFormData({ ...form, mode: isEditing ? form.mode : 2 });
    if (!formData) {
      const { isValid, message } = checkLessonForPublishing({ ...body, ...data });
      if (!isValid) {
        showLessonInvalidAlert(message);
        return;
      }
    }
    try {
      setFetching(formData ? 1 : 2);
      const endpoint = id ? '/wizard/lesson/update' : '/wizard/lesson/init';
      await Api.post(endpoint, { ...body, originId: id ? id : undefined });
      enqueueSnackbar(`Lesson successfully ${id ? 'updated' : 'created'}`, { variant: 'success' });
      history.push('/lessons/all');
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      setFetching(false);
    }
  };

  const onLessonSave = async (formData, isUpdate) => {
    if (isUpdate && lesson.mode === publishMode && isInUse) {
      confirmAlert({
        overlayClassName: 'with-icon',
        title: 'Confirmation Needed',
        message:
          'The users enrolled to this Lesson will be AFFECTED. Are you sure you want to update?',
        buttons: [
          {
            label: 'Cancel',
          },
          {
            label: 'Update Anyway',
            onClick: () => handleSaveLesson(formData),
          },
        ],
      });
    } else {
      handleSaveLesson(formData);
    }
  };

  const onSaveDraft = () => {
    const draftForm = constructFormData({ ...form, mode: 1 });
    const cleanForm = Object.fromEntries(
      Object.entries(draftForm).filter(([_, v]) => v !== null && v !== undefined),
    );
    onLessonSave(cleanForm);
  };

  const onGoBackStep = () => {
    setActiveStep(step => step - 1);
  };

  const handleStepChange = async e => {
    e.preventDefault();
    setActiveStep(step => step + 1);
  };

  const setInitialEpsiodes = data => {
    if (!data || !data.length) return null;
    return data.map(item => {
      item.name = item.title;
      return item;
    });
  };

  const setInitialQuestions = data => {
    if (!data) return {};
    const result = {};
    data.forEach(item => {
      result[item.caseId] = item.questions;
    });
    return result;
  };

  const setInitialHasFindinsg = data => {
    if (!data) return {};
    const result = {};
    data.forEach(item => {
      result[item.caseId] = item.questions && item.questions.length ? 2 : 1;
    });
    return result;
  };

  const setInitialCaseHeatmaps = data => {
    if (!data) return {};
    const result = {};
    data.forEach(item => {
      if (item.hasHeatmap) {
        result[item.caseId] = (item.heatmaps && item.heatmaps[0]) || {};
      } else {
        result[item.caseId] = { hasHeatmap: 0 };
      }
    });
    return result;
  };

  const setInitialTags = data => {
    if (!data) return [];
    return data.map(tag => ({ label: tag.name, value: tag.id }));
  };

  const setLessonInitialData = lesson => {
    setForm({
      mode: sampleLessonId ? 1 : lesson.mode,
      name: lesson.title || '',
      nickName: lesson.nickName || '',
      description: lesson.info || '',
      contentType: lesson.contentType,
      episodes: setInitialEpsiodes(lesson.episodes),
      studyTech: lesson.studyTech,
      duration: lesson.duration,
      uploadContentUrl: id || sampleLessonId ? lesson.contentUrl : null,
      isMarketingMaterial: lesson.isMarketingMaterial,
    });
    const body = {
      activeCases: lesson.cases,
      caseQuestions: setInitialQuestions(lesson.cases),
      hasFinding: setInitialHasFindinsg(lesson.cases),
      // caseAnswers: setInitialCaseAnswers(lesson.cases),
      caseHeatmaps: setInitialCaseHeatmaps(lesson.cases),
      tags: setInitialTags(lesson.lessonTags),
    };
    if (lesson.imageUrl) {
      body.imageUrl = { url: lesson.imageUrl };
    }
    if (lesson.contentUrl) {
      body.contentFile = {
        duration: lesson.duration,
        fileName: lesson.contentUrl,
        url: lesson.contentUrl,
      };
    }
    setData(body);
  };

  const getLessonData = async (id, formExist) => {
    try {
      const { data } = await Api.get(`/wizard/lesson/${id}`);
      const { lesson, inUse } = data.data;
      setIsInUse(inUse);
      setLesson(lesson);
      if (!formExist) setLessonInitialData(lesson);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      history.push('/lessons/all');
    }
  };

  const onPreviewClick = () => {
    history.push('/lessons/preview', {
      activeStep,
      lesson: {
        id,
        form,
        data,
        overwrite: {
          duration: form.duartion || data.contentFile?.duration,
          contentUrl: data.contentFile?.url,
          imageUrl: data.lessonImage?.url,
          title: form.name,
        },
      },
    });
  };

  const checkAndSetFromPreviewBack = () => {
    const fromPreviewState = location.state?.lesson;
    if (fromPreviewState) {
      setForm(fromPreviewState.form);
      setData(fromPreviewState.data);
    }
    if (location.state?.activeStep) {
      setActiveStep(location.state.activeStep);
    }
  };

  useEffect(() => {
    if (id) {
      getLessonData(id, location.state?.lesson);
    } else if (sampleLessonId) {
      getLessonData(sampleLessonId);
    }
    checkAndSetFromPreviewBack();
    //eslint-disable-next-line
  }, [id]);

  const activeType = typeData[form.contentType];
  const typeSteps = activeType.steps;
  const isLastStep = activeStep === typeSteps.length - 1;
  const Step = activeType.steps[activeStep].component;

  const isDisableNext = form => {
    if (!!id && isInUse) return false;
    const isCases = Number(form.contentType) === 3;
    if (!isCases) return false;
    if (activeStep === 1) {
      return !data.activeCases || !data.activeCases.length;
    }
    if (activeStep === 2) {
      const items = data.activeCases.map(item => checkQuestionaryCaseCompleted(data, item.caseId));
      const failds = items.filter(item => item === false);
      return failds.length;
    }
    if (activeStep === 3) {
      const items = data.activeCases.filter(item => {
        const caseI = data.caseHeatmaps && data.caseHeatmaps[item.caseId];
        return caseI && (caseI.imageId || !caseI.hasHeatmap);
      });
      return items.length !== data.activeCases.length;
    }
    return false;
  };

  if (id && !lesson) return <Loading className='mt-5' />;

  const canSaveDraft = form.mode === 1 || !id;
  const isRequired = form.mode === 2;

  return (
    <div className='lesson-creation'>
      <Header
        steps={typeSteps}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        form={form}
        onPreviewClick={onPreviewClick}
      />
      <form
        className='py-3 has-header d-flex flex-column justify-content-between min-vh-100'
        onSubmit={handleStepChange}
      >
        <div className='col-12'>
          <Step
            form={form}
            setForm={setForm}
            data={data}
            setData={setData}
            onLessonSave={onLessonSave}
            fetching={fetching}
            isInUse={!!isInUse}
            isEditing={!!id}
            onSaveDraft={onSaveDraft}
            canSaveDraft={canSaveDraft}
            isRequired={isRequired}
            sampleLessonId={sampleLessonId}
            lesson={lesson}
          />
        </div>
        <FooterButtons
          form={form}
          canSaveDraft={canSaveDraft}
          fetching={fetching}
          onGoBackStep={onGoBackStep}
          activeStep={activeStep}
          isLastStep={isLastStep}
          disableNext={isDisableNext(form)}
          onSaveDraft={onSaveDraft}
        />
      </form>
    </div>
  );
};

export default LessonsNew;
