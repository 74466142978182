import React from 'react';
import CoursesProgressCircle from './CoursesProgressCircles';
import SpecialistCourses from './SpecialistCourses';

const RequiredCoursesSection = props => {
  const { product } = props;

  return (
    <div className='required-courses-section'>
      <div className='title-row'>
        <h2 className='section-title'>Required Courses</h2>
        <p className='regular-text'>
          Depending on your age, the Well Woman exam may cover different health topics. Let’s take a
          look at what you can expect.
        </p>
      </div>
      <CoursesProgressCircle progresses={product.progresses} />
      <SpecialistCourses requiredCourses={product?.currentPage?.requiredCourses} />
    </div>
  );
};

export default RequiredCoursesSection;
