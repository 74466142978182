/* eslint-disable eqeqeq */
import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import {
  getImageUrl,
  getEndOfSubs,
  getError,
  courseSubsStatuses,
  checkSubsCourseOpened,
} from 'utils/appHelpers';
import ClickArea from 'shared/components/ClickArea';
import ProgressBar from '../../UserProgress/components/ProgressBar';
import { userStatuses } from 'app/Main/configs';
import { Api } from 'utils/connectors';
import Loading from 'shared/components/Loading';
import ListActionButtons from 'shared/components/ListActionButtons';
import ResponsiveImage from 'shared/components/ResponsiveImage';

const headers = [
  {
    name: 'First Name',
    className: 'col',
    key: 'firstName',
  },
  {
    name: 'Surname',
    className: 'col',
    key: 'lastName',
  },
  {
    name: 'Entities',
    className: 'col',
    key: 'entities',
  },
  {
    name: 'Status',
    className: 'col',
    key: 'userStatus',
  },
  {
    name: 'Subscription',
    className: 'col',
    key: false,
  },
  {
    name: 'Progress',
    className: 'col',
    key: false,
  },
  {
    name: 'End of subscription',
    className: 'col',
    key: '',
  },
  {
    name: 'Actions',
    className: 'p-2 d-flex action-buttons-container min_60',
    key: '',
  },
];

const inviteHeaders = [
  {
    name: 'First Name',
    className: 'col',
    key: 'firstName',
  },
  {
    name: 'Surname',
    className: 'col',
    key: 'lastName',
  },
  {
    name: 'Email',
    className: 'col',
    key: 'email',
  },
  {
    name: 'Status',
    className: 'col',
    key: 'userStatus',
  },
  {
    name: 'Actions',
    className: 'col-1 p-2 d-flex action-buttons-container min_60',
    key: '',
  },
];

const UsersLists = ({ users, order, changeOrder, status, fetch }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [userInviteFetch, setUserInviteFetch] = useState(false);

  if (fetch) return <Loading className='mt-5' />;

  if (!users || !users.length)
    return <div className='text-black-50 text-center mt-4'>No Results Found</div>;

  const handleOrderChange = item => {
    if (!item.key) return;
    changeOrder({ key: item.key, order: !order.order });
  };

  const userResetInviteEmail = async id => {
    try {
      setUserInviteFetch(true);
      await Api.post(`/admin/resendinvitation/${id}`, {});
      enqueueSnackbar('Invitation sent successfully', { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setUserInviteFetch(false);
    }
  };

  const showSubscription = ({ userSubscriptionStatus: status }) => {
    return courseSubsStatuses(status, 'pending') || courseSubsStatuses(status, 'active');
  };

  const invitedStatus = Number(status) === 4;
  const rowHeaders = invitedStatus ? inviteHeaders : headers;

  const actions = [
    {
      hide: () => !invitedStatus,
      type: 'button',
      text: 'resend',
      disabled: userInviteFetch,
      onClick: item => userResetInviteEmail(item.learnerProfile?.userId || item.id),
    },
    {
      type: 'link',
      icon: 'view',
      to: item =>
        `/user/${item.learnerProfile?.userId || item.id}/${
          invitedStatus || item.userStatus == 4 ? 'invited' : ''
        }`,
    },
  ];

  return (
    <div className='users-lists'>
      <ul className='p-0'>
        <li className='d-flex list-header align-items-center'>
          {rowHeaders.map((item, index) => (
            <ClickArea
              key={item.name + index}
              className={`d-flex text-body justify-content-between align-items-center ${
                item.className
              }`}
              onClick={handleOrderChange.bind(null, item)}
            >
              {item.name}
              {item.key && order.orderKey === item.key && (
                <button
                  className={`btn ml-2 p-0 dropdown-toggle ${order.order ? '' : 'rotate-180'}`}
                />
              )}
            </ClickArea>
          ))}
        </li>
        {users &&
          users.map(item => {
            const subs = item.userSubscriptions ? item.userSubscriptions : [];
            return (
              <li key={item.id} className='d-flex list-item h-not-set'>
                <div className='col d-flex align-items-center'>
                  <ResponsiveImage
                    imgUrl={getImageUrl(item.imageUrl)}
                    alt={item.firstname}
                    className='user-image mr-2'
                  />
                  <div className='text-elips'>{item.firstName}</div>
                </div>
                <div className={`align-items-center col d-flex`}>
                  <span className='text-elips'>{item.lastName}</span>
                </div>
                {invitedStatus && (
                  <div className={`col d-flex align-items-center`}>
                    <span className='text-elips'>{item.email}</span>
                  </div>
                )}
                {!invitedStatus && (
                  <div
                    className={`align-items-center col d-flex flex-column justify-content-center`}
                  >
                    {item?.entities?.map((entity, idx) => (
                      <span key={idx} className='text-elips'>
                        {entity?.name}
                      </span>
                    ))}
                  </div>
                )}
                <div className={`col d-flex align-items-center`}>
                  {invitedStatus && 'Invitation Sent'}
                  {!invitedStatus && (userStatuses[item.userStatus] || '-')}
                </div>
                {!invitedStatus && (
                  <>
                    <div className='col d-flex flex-column justify-content-center'>
                      {subs.map(item => {
                        if (!showSubscription(item)) return '';
                        return <div key={item.id}>{item.subscription.title}</div>;
                      })}
                      {!subs.length && '-'}
                    </div>
                    <div className='col d-flex flex-column justify-content-center'>
                      {subs.map(item => {
                        if (!showSubscription(item)) return '';
                        const isCourseOpened = checkSubsCourseOpened(item);
                        if (isCourseOpened) return <div key={item.id}>No Progress</div>;
                        return (
                          <div key={item.id} className='align-items-center d-flex'>
                            <b className='col'>{item.progress}%</b>
                            <ProgressBar progress={item.progress} />
                          </div>
                        );
                      })}
                      {!subs.length && '-'}
                    </div>
                    <div className='col d-flex flex-column justify-content-center'>
                      {subs.map(item => {
                        if (!showSubscription(item)) return '';
                        const expirable = item.subscription && item.subscription.expirable;
                        return (
                          <div key={item.id}>{getEndOfSubs(item.endDate, !expirable) || '-'}</div>
                        );
                      })}
                      {!subs.length && '-'}
                    </div>
                  </>
                )}
                <div
                  className={`p-2 d-flex action-buttons-container min_60 align-items-center ${
                    invitedStatus ? 'col-1' : ''
                  }`}
                >
                  <ListActionButtons actions={actions} item={item} />
                </div>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default UsersLists;
