export const QUESTION_TYPES = [
  { type: 'radio', name: 'Single Select' },
  { type: 'multi', name: 'Multi Select' },
  { type: 'yesno', name: 'Boolean' },
  { type: 'image-category', name: 'Image Categories' },
];

export const QUESTION_TYPES_OBJ = {
  radio: 'radio',
  multi: 'multi',
  yesno: 'yesno',
  'image-category': 'image-category',
};

export const QUESTION_TYPES_BY_VALUE = {
  radio: 'radio',
  multi: 'multi',
  yesno: 'yesno',
  'image-category': 'image-category',
};

export const QUESTION_TYPES_VALUES = {
  radio: 1,
  multi: 2,
  yesno: 3,
  'image-category': 4,
};
