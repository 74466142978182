import { courseModes, modeIcons } from 'app/Main/configs';
import React from 'react';

const ModeStatus = ({ mode, className = 'mr-4' }) => {
  const Icon = modeIcons[mode];

  return (
    courseModes[mode] && (
      <div className={`d-flex align-items-center ${className} fz-17 fw-400 text-blue`}>
        <Icon className='mr-2' />
        {courseModes[mode]}
      </div>
    )
  );
};

export default ModeStatus;
