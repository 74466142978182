import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';

import { checkCourseForPublishing, showCourseInvalidAlert } from '../CourseView/utils';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';

import FooterButtons from './components/FooterButtons';
import Header from './components/Header';
import ProductPageGeneralStep from './components/ProductPageGeneralStep';
import ProductPageCompleteStep from './components/ProductPageCompleteStep';
import CourseConfigurationStep from './components/CourseConfigurationStep';
import DetailsBlock from '../ProductView/components/DetailsBlock';
import { useDialog } from 'shared/hooks/useDialog';
import { CreateLinkDialog } from '../Products/components/CreateLinkDialog';

const draftMode = 1;
const publishMode = 2;

const ProductPageNew = ({ history, match, location }) => {
  const { id, productId } = match.params;
  const isEditing = !!id;
  const { enqueueSnackbar } = useSnackbar();
  const [product, setProduct] = useState();
  const [fetching, setFetching] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [productPage, setProductPage] = useState({});
  const [urlPath, setUrlPath] = useState('');
  const [courses, setCourses] = useState([]);
  const [form, setForm] = useState({
    mode: draftMode,
    productId,
    specialities: [],
    specialityMessage: '',
    specialityImageUrl: [],
    productLink: '',
    subscriptionId: null,
    title: '',
    titleIcon: 'folder',
    externalLink: [{ title: '', description: '', productLink: '' }],
    recomendedCourses: 0,
    recentlyWatchedCourses: 0,
    activeCourseIds: [],
  });

  const createLinkDialog = useDialog();

  const steps = [
    { component: ProductPageGeneralStep },
    { component: CourseConfigurationStep },
    { component: ProductPageCompleteStep },
  ];

  const handleSaveProductPage = async mode => {
    const body = form && { ...form, mode };
    if (!form) {
      const { isValid, message } = checkCourseForPublishing(body);
      if (!isValid) {
        showCourseInvalidAlert(message);
        return;
      }
    }
    try {
      setFetching(true);
      const productBody = {
        ...body,
        specialty: id ? body.specialities[0] : null,
      };
      if (id) {
        await Api.put(`/product/product-page/${id}`, productBody);
      } else {
        await Api.post('/product/product-page', productBody);
      }
      enqueueSnackbar(`Product successfully ${id ? 'updated' : 'created'}`, { variant: 'success' });
      history.push(`/product/${productId}/view`);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      setFetching(false);
    }
  };

  const onProductPageSave = mode => {
    handleSaveProductPage(mode);
  };

  const onSaveDraft = () => {
    handleSaveProductPage(draftMode);
  };

  const onGoBackStep = () => {
    setActiveStep(step => step - 1);
  };

  const handleStepChange = async e => {
    e.preventDefault();
    setActiveStep(step => step + 1);
  };

  const setProductInitialData = productPage => {
    setForm({
      ...form,
      productId,
      mode: productPage?.mode || draftMode,
      specialities: [productPage?.specialty],
      specialityMessage: productPage?.specialityMessage || '',
      subscriptionId: productPage?.subscription?.id,
      title: productPage?.title || '',
      titleIcon: productPage?.titleIcon || 'folder',
      description: productPage?.description,
      productLink: productPage?.productLink || '',
      externalLink: (productPage?.externalLinks?.length && productPage.externalLinks) || [
        { title: '', description: '', productLink: '' },
      ],
      recomendedCourses: productPage?.recomendedCourses || 0,
      recentlyWatchedCourses: productPage?.recentlyWatchedCourses || 0,
      specialityImageUrl: productPage?.specialityImages?.length
        ? [productPage?.specialityImages?.[0]?.imageUrl]
        : [],
      activeCourseIds: productPage?.requiredCourses?.length
        ? [...productPage.requiredCourses.map(({ course }) => course.id)]
        : [],
      courses: productPage?.requiredCourses?.length
        ? [...productPage.requiredCourses.map(({ course }) => course.id)]
        : [],
    });
  };

  const getCourses = async subscriptionId => {
    const params = { subscription: subscriptionId, limit: 1000 };
    try {
      const { data } = await Api.get('/wizard/courses', { params });
      if (data?.length) setCourses(data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const getProductData = async id => {
    try {
      const { data } = await Api.get(`/product/product/${id}`);
      const product = data?.data;
      if (product) {
        setProduct(product);
        setUrlPath(product?.urlPath);
      }
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      history.push('/products');
    }
  };

  const getProductPageData = async id => {
    try {
      const { data } = await Api.get(`/product/product-page/${id}`);
      const productPage = data.data;
      setProductPage(productPage);
      setProductInitialData(productPage);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      history.push('/products');
    }
  };

  const onPreviewClick = () => {
    history.push(`/product/${productId}/product-page/${id}/preview`);
  };

  const checkAndSetFromPreviewBack = () => {
    const fromPreviewState = location.state?.course;
    if (fromPreviewState) {
      setForm(fromPreviewState.form);
    }
    if (location.state?.activeStep) {
      setActiveStep(location.state.activeStep);
    }
  };

  const handleCloseCreateLink = () => {
    createLinkDialog.closeDialog();
  };

  const handleOpenCreateLink = () => {
    createLinkDialog.openDialog({ specialty: form?.specialty?.[0] });
  };

  useEffect(() => {
    if (form.subscriptionId) getCourses(form.subscriptionId);
  }, [form.subscriptionId]);

  useEffect(() => {
    if (productId) getProductData(productId, location.state?.product);
    if (id) getProductPageData(id);
    checkAndSetFromPreviewBack();
  }, [id, productId]);

  const isLastStep = activeStep === steps.length - 1;
  const Step = steps[activeStep].component;

  return (
    <div className='lesson-creation'>
      <Header
        isEditing={isEditing}
        steps={steps}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        form={form}
        onPreviewClick={onPreviewClick}
      />
      <form
        className='has-header d-flex flex-column justify-content-between min-vh-100'
        onSubmit={handleStepChange}
      >
        <div className='d-flex col-12'>
          <Step
            form={form}
            setForm={setForm}
            onSave={onProductPageSave}
            fetching={fetching}
            isEditing={!!id}
            product={product}
            courses={courses}
          />
          {!isLastStep && (
            <div className='col-3 py-3'>
              <DetailsBlock
                handleOpenCreateLink={handleOpenCreateLink}
                className='details-view-block'
                title='Product'
                data={product}
                hideActions
              />
            </div>
          )}
        </div>
        <FooterButtons
          form={form}
          fetching={fetching}
          onSaveDraft={onSaveDraft}
          onGoBackStep={onGoBackStep}
          activeStep={activeStep}
          isLastStep={isLastStep}
          canSaveDraft={form.mode !== publishMode}
        />
      </form>
      <CreateLinkDialog
        isOpen={createLinkDialog.isOpen}
        onClose={handleCloseCreateLink}
        urlPath={urlPath}
        selectedSpecialty={createLinkDialog.dialogData?.specialty}
      />
    </div>
  );
};

export default ProductPageNew;
