import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { confirmAlert } from 'react-confirm-alert';
import { useSnackbar } from 'notistack';

import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';
import useFilters from 'shared/hooks/useFilters';

import { IconArrowLeft, IconArrowRight } from 'shared/components/Icons';
import ProductsHeader from './components/ProductsHeader';
import ProductsList from './components/ProductsList';
import AdvancedFilters from 'shared/components/filters/AdvancedFilters';
import useDidUpdateEffect from 'shared/hooks/useDidUpdateEffect';
import { hasAccess } from 'utils/permissionHelper';
import { CreateLinkDialog } from './components/CreateLinkDialog';

const filterState = [
  { name: 'Published', id: 2 },
  { name: 'Unpublished', id: 3 },
  { name: 'Draft', id: 1 },
];

const initialFilters = () => {
  return {
    entities: '',
    specialty: [],
    mode: '',
  };
};

const Products = ({ match, history }) => {
  const [removeFetch, setRemoveFetch] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [products, setProducts] = useState([]);
  const [fetch, setFetch] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [entities, setEntities] = useState([]);
  const [specialties, setSpecialties] = useState([]);
  const [isCreateLinkOpen, setIsCreateLinkOpen] = useState(false);
  const [urlPath, setUrlPath] = useState('');

  const hasOnlyMarketingCoursesAccess =
    hasAccess('marketing_course_view') && !hasAccess('education_course_view');
  const hasOnlyEducationCoursesAccess =
    !hasAccess('marketing_course_view') && hasAccess('education_course_view');

  const {
    requestParams,
    filters,
    dropFilters,
    changeFilter,
    changeDropFilter,
    clearDropFilter,
    filterDependency,
    originalState,
  } = useFilters(
    {
      page: 0,
      order: false,
      orderKey: 'createdAt',
      search: '',
      limit: 8,
    },
    { ...initialFilters() },
  );

  const downloadData = [
    {
      title: 'Export as CSV',
      url: '/product/products',
      fileName: 'products_list',
      type: 'csv',
      params: requestParams,
    },
  ];

  const getProducts = async () => {
    try {
      setFetch(true);
      const { data } = await Api.get(`/product/products`, { params: requestParams });
      if (data?.data) {
        setPageCount(Math.ceil(data.data.count / 8));
        setProducts(data.data.results);
      }
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetch(false);
    }
  };

  const getEntities = async () => {
    try {
      const params = { page: 1, limit: 1000 };
      const { data } = await Api.get(`/entity/entities`, { params });
      if (data?.data) setEntities(data.data.results);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const getSpecialtiesList = async () => {
    try {
      const { data } = await Api.get('/common/specialities');
      setSpecialties(
        data?.data?.length
          ? data.data.map((specialty, idx) => ({ name: specialty, id: specialty }))
          : [],
      );
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const handleCreateLinkClick = item => {
    setUrlPath(() => item.urlPath, setIsCreateLinkOpen(true));
  };

  const handleCloseCreateLink = () => {
    setIsCreateLinkOpen(false);
  };

  const onPageChange = ({ selected }) => {
    changeFilter({ page: selected });
  };

  const handleSearchChange = text => {
    changeFilter({ search: text });
  };

  const onChangeOrder = item => {
    if (!item.key) return;
    changeFilter({ order: !filters.order, orderKey: item.key });
  };

  const onItemRemove = async item => {
    try {
      setRemoveFetch(true);
      await Api.delete(`/product/product/${item.id}`);
      await getProducts();
      enqueueSnackbar('Successfully removed', { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setRemoveFetch(false);
    }
  };

  const onItemRemoveConfirm = item => {
    confirmAlert({
      overlayClassName: 'wide',
      title: 'Confirmation Required',
      message:
        'This is going to remove products and all its product pages. All user progresses related to  the product and its  product page are not  going to be available after deletion. Are you sure you want  to delete the Product and all its Product Pages?',
      buttons: [
        {
          label: 'Cancel',
        },
        {
          label: 'Delete',
          onClick: () => onItemRemove(item),
        },
      ],
    });
  };

  const onClearAllFilters = () => {
    clearDropFilter();
  };

  const onFilterChange = (name, id) => {
    changeDropFilter(name, id);
  };

  const filtersOptions = [
    {
      type: 'dropdown',
      title: 'State',
      name: 'mode',
      data: filterState,
      value: dropFilters.mode,
      onFilterChange: onFilterChange,
    },
    {
      type: 'multiDropdown',
      title: 'Specialty',
      name: 'specialty',
      data: specialties,
      value: dropFilters.specialty || [],
      onFilterChange: onFilterChange,
    },
    {
      type: 'dropdown',
      title: 'Entity',
      name: 'entity',
      data: entities,
      value: dropFilters.entity,
      onFilterChange: onFilterChange,
    },
  ];

  useDidUpdateEffect(() => {
    getProducts();
  }, [...filterDependency]);

  useEffect(() => {
    if (hasOnlyMarketingCoursesAccess) changeDropFilter('isMarketingMaterial', [1]);
    if (hasOnlyEducationCoursesAccess) changeDropFilter('isMarketingMaterial', [null]);
  }, [hasOnlyEducationCoursesAccess, hasOnlyMarketingCoursesAccess]);

  useEffect(() => {
    getEntities();
    getSpecialtiesList();
  }, []);

  return (
    <div>
      <ProductsHeader search={originalState.search} onSearch={handleSearchChange} />
      <div className='py-3 has-header'>
        <AdvancedFilters
          downloadData={downloadData}
          withExport
          filters={filtersOptions}
          onClearAll={onClearAllFilters}
        />
        <div className='col-12'>
          <ProductsList
            list={products}
            order={filters}
            changeOrder={onChangeOrder}
            fetch={fetch}
            onItemRemoveConfirm={onItemRemoveConfirm}
            onItemRemoveDisabled={removeFetch}
            handleCreateLinkClick={handleCreateLinkClick}
          />
        </div>
        {pageCount > 1 && !fetch && (
          <ReactPaginate
            previousLabel={<IconArrowLeft />}
            nextLabel={<IconArrowRight />}
            breakLabel={'...'}
            forcePage={Number(filters.page)}
            breakClassName={'break-me'}
            pageCount={pageCount}
            marginPagesDisplayed={3}
            pageRangeDisplayed={3}
            onPageChange={onPageChange}
            containerClassName={`pagination show-arrow`}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
          />
        )}
        <CreateLinkDialog
          isOpen={isCreateLinkOpen}
          onClose={handleCloseCreateLink}
          specialties={specialties}
          entities={entities}
          urlPath={urlPath}
        />
      </div>
    </div>
  );
};

export default Products;
