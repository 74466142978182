export const checkQuestionaryCaseCompleted = (data, caseId) => {
  if (!data.hasFinding || (data.hasFinding && !data.hasFinding[caseId])) return false;
  if (Number(data.hasFinding[caseId]) === 1) return true;
  const items = data.caseQuestions[caseId];
  let checkItems = {};
  items.forEach(({ answers, question_type }, index) => {
    const filled = answers.filter(i => !!i.name);
    const correct = answers.filter(i => i.correct);
    checkItems[index] = [filled.length === answers.length, correct.length > 0];
  });

  const allCheck = Object.values(checkItems).flat();
  const failds = allCheck.filter(item => item === false);
  return !failds.length;
};

export const findDicom = (dicoms, form) => {
  if (!dicoms) return null;
  return dicoms.find(
    i =>
      i.imageType === form.imageType &&
      i.imageLaterality === form.imageLaterality &&
      i.viewPosition === form.viewPosition,
  );
};

export const getDicomsSelects = (dicoms, form) => {
  if (!dicoms || !form) return {};
  const types = [];
  const sides = [];
  const angles = [];
  const slices = [];
  let dicom;
  let image;
  dicoms.forEach(item => {
    types.push(item.imageType);
    if (form.imageType && item.imageType !== form.imageType) return;
    sides.push(item.imageLaterality);
    angles.push(item.viewPosition);
  });
  if (form.imageType && form.imageLaterality && form.viewPosition) {
    dicom = findDicom(dicoms, form);
    if (dicom && dicom.dicomImages) {
      dicom.dicomImages.forEach((img, index) => {
        slices.push({ name: index + 1, value: index + 1 });
      });

      if (form.slice) {
        image = dicom.dicomImages[form.slice - 1];
      }
    }
  }
  return {
    types: [...new Set(types)].map(i => ({ name: i, value: i })),
    sides: [...new Set(sides)].map(i => ({ name: i, value: i })),
    angles: [...new Set(angles)].map(i => ({ name: i, value: i })),
    slices: slices,
    image: image,
    dicom,
  };
};

export const setHeatmapsIds = (dicoms, heatmaps) => {
  if (!dicoms) return {};
  Object.keys(heatmaps).forEach(caseId => {
    const data = dicoms[caseId];
    const form = heatmaps[caseId];
    if (form.imageType && form.imageLaterality && form.viewPosition) {
      const dicom = findDicom(data, form);
      if (dicom) {
        heatmaps[caseId].dicomId = dicom.id;
        if (form.slice) {
          const image = dicom.dicomImages[form.slice - 1];
          if (image) heatmaps[caseId].imageId = image.id;
        }
      }
    }
  });
  return heatmaps;
};
