import React from 'react';

const RadioBox = ({
  name,
  label,
  value,
  onChange,
  className,
  checked,
  classNameLabel,
  disabled,
}) => {
  return (
    <div
      className={`align-items-center d-flex ${className || ''} ${disabled &&
        'radio_checkbox_disabled'}`}
    >
      <input
        type='checkbox'
        id={`radio${name}`}
        className='input-custom is-radiobox'
        name={name}
        onChange={onChange}
        value={value}
        checked={checked}
        disabled={disabled}
      />
      {label && (
        <label className={classNameLabel} htmlFor={`radio${name}`}>
          {label}
        </label>
      )}
    </div>
  );
};

export default RadioBox;
