import React from 'react';
import moment from 'moment';

import { formatDate, getFormattedDateRange, getLicensesDefaultRange } from 'utils/appHelpers';

import DateRangePickerFilter from 'shared/components/DateRangePickerFilter';
import { SwitchIOSLike } from 'shared/components/SwitchIOSLike';

const defaultRange = getLicensesDefaultRange();

const ValidityPeriod = props => {
  const {
    license,
    setLicense,
    editing = true,
    isValidPeriodEnabled,
    setIsValidPeriodEnabled,
    isNotAllowedRange,
    handleSetPeriod,
    handleSwitchExternal,
  } = props;

  const handleSwitch = () => {
    if (isValidPeriodEnabled) {
      if (handleSetPeriod) {
        handleSetPeriod(null);
      }
    }
    if (!license.dateRange && !license.startDate && !license?.endDate) {
      setLicense(license => ({ ...license, dateRange: defaultRange }));
    }
    setIsValidPeriodEnabled(prev => !prev);
  };

  const handleChangeValidityPeriod = (timeRange = null) => {
    if (handleSetPeriod) {
      handleSetPeriod(timeRange);
    } else {
      setLicense({
        ...license,
        dateRange: timeRange,
      });
    }
  };

  const formattedStartDate = license.dateRange
    ? moment(license.dateRange.start).format('YYYY/MM/DD')
    : moment(license.startDate).format('YYYY/MM/DD');
  const formattedEndDate = license.dateRange
    ? moment(license.dateRange.end).format('YYYY/MM/DD')
    : moment(license.endDate).format('YYYY/MM/DD');
  const value = license.dateRange
    ? getFormattedDateRange(license.dateRange.start, license.dateRange.end)
    : getFormattedDateRange(license.startDate, license.endDate);

  const datePickerLabel =
    isValidPeriodEnabled && license.dateRange
      ? `${formattedStartDate} - ${formattedEndDate}`
      : isValidPeriodEnabled && !!license?.startDate && !!license?.endDate
      ? `${formatDate(license?.startDate)} - ${formatDate(license?.endDate)}`
      : 'No Limitation';

  return (
    <>
      <div className='col-4 pl-0'>
        <label className='m-0'>Validity Period:</label>
      </div>
      {editing ? (
        <div className='date_part_container'>
          <div className='switch_container'>
            <SwitchIOSLike
              handleChange={() => (handleSwitchExternal ? handleSwitchExternal() : handleSwitch())}
              checkedState={isValidPeriodEnabled}
            />
          </div>
          <DateRangePickerFilter
            isNotAllowedRange={isNotAllowedRange}
            disabled={!isValidPeriodEnabled}
            name='validityPeriod'
            onChange={handleChangeValidityPeriod}
            label={datePickerLabel}
            customClasses={`grey_datepicker ${license.dateRange ||
              (isValidPeriodEnabled ? 'white-bg' : 'no-value')}`}
            isSelectedRange={!!license.dateRange}
            value={value}
          />
        </div>
      ) : (
        <span className={`result `}>
          {!!license?.startDate && !!license?.endDate
            ? `${formatDate(license?.startDate)} - ${formatDate(license?.endDate)}`
            : 'No Limitation'}
        </span>
      )}
    </>
  );
};

export default ValidityPeriod;
