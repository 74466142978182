import React, { useState, useEffect } from 'react';
import ButtonLine from 'shared/components/ButtonLine';
import Button from 'shared/components/Button';
import EditingInput from 'shared/components/editingForms/EditingInput';
import EditingTextArea from 'shared/components/editingForms/EditingTextArea';
import { getBase64, duplicate } from 'utils/appHelpers';
import EditingMultiRow from 'shared/components/editingForms/EditingMultiRow';
import { hasAccess } from 'utils/permissionHelper';
import ResponsiveImage from 'shared/components/ResponsiveImage';

const CreationForm = ({ onSubmitUser, fetch, initialData }) => {
  const isUpdate = !!initialData.id;
  const [editing, setEditing] = useState(hasAccess('author_create'));
  const [form, setForm] = useState(initialData);

  const handleChange = ({ target }) => {
    const { name, value } = target;
    const tempForm = { ...form };
    tempForm[name] = value;
    setForm(tempForm);
  };

  const handleImageSelect = file => {
    getBase64(file, result => {
      const tempForm = { ...form };
      tempForm.base64Image = result;
      setForm(tempForm);
    });
  };

  const handleChangeMultiRow = (index, name, key, e) => {
    const tempForm = { ...form };
    const tempName = duplicate([...tempForm[name]]);
    tempName[index][key] = e.target.value;
    tempForm[name] = tempName;
    setForm(tempForm);
  };

  const handleaddMultiRow = name => {
    const tempForm = { ...form };
    const tempName = duplicate([...tempForm[name]]);
    tempName.push({ title: '', info: '' });
    tempForm[name] = tempName;
    setForm(tempForm);
  };

  const handleSubmit = e => {
    e.preventDefault();
    setEditing(false);
    if (!editing) onSubmitUser(form);
  };

  useEffect(() => {
    setForm(initialData);
    setEditing(hasAccess('author_create'));
  }, [initialData]);

  if (!form) return null;

  return (
    <form onSubmit={handleSubmit} className='col-9 user-creation-form'>
      <ul className='p-0 mb-0'>
        <li className='d-flex align-items-center'>
          <div className='col-3 pl-0'>
            <label className='m-0'>Name:</label>
          </div>
          <EditingInput
            type='text'
            name='name'
            value={form.name}
            onChange={handleChange}
            className='p-0'
            placeholder='Enter Name'
            required
            editing={editing}
            access={hasAccess('author_create')}
          />
        </li>
        <li className='d-flex align-items-center'>
          <div className='col-3 pl-0'>
            <label className='m-0'>Surname:</label>
          </div>
          <EditingInput
            type='text'
            name='surname'
            value={form.surname}
            onChange={handleChange}
            className='p-0'
            placeholder='Enter Surname'
            required
            editing={editing}
            access={hasAccess('author_create')}
          />
        </li>
        <li className='d-flex align-items-center'>
          <div className='col-3 pl-0'>
            <label className='m-0'>Degree:</label>
          </div>
          <EditingInput
            type='text'
            name='profession'
            value={form.profession}
            onChange={handleChange}
            className='p-0'
            placeholder='Enter Profession'
            required
            editing={editing}
            access={hasAccess('author_create')}
          />
        </li>
        <li className='d-flex'>
          <div className='col-3 pl-0 pt-1'>
            <label className='m-0'>Education:</label>
          </div>
          <div className='col pl-0'>
            <EditingMultiRow
              editing={editing}
              data={form.authorEducations}
              name='authorEducations'
              onChange={handleChangeMultiRow}
              access={hasAccess('author_create')}
            />
          </div>
          {editing && (
            <div className='pb-1 align-items-end d-flex justify-content-end'>
              <button
                className='btn-sm px-3 mb-1 btn btn-white m-0 pointer'
                type='button'
                onClick={() => handleaddMultiRow('authorEducations')}
              >
                Add Education
              </button>
            </div>
          )}
        </li>
        <li className='d-flex'>
          <div className='col-3 pl-0 pt-1'>
            <label className='m-0'>Experience:</label>
          </div>
          <div className='col pl-0'>
            <EditingMultiRow
              editing={editing}
              data={form.authorExperience}
              name='authorExperience'
              onChange={handleChangeMultiRow}
              access={hasAccess('author_create')}
            />
          </div>
          {editing && (
            <div className='pb-1 align-items-end d-flex justify-content-end'>
              <button
                className='btn-sm px-3 mb-1 btn btn-white m-0 pointer'
                type='button'
                onClick={() => handleaddMultiRow('authorExperience')}
              >
                Add Experience
              </button>
            </div>
          )}
        </li>
        <li className='d-flex'>
          <div className='col-3 pl-0 pt-1'>
            <label className='m-0'>Bio:</label>
          </div>
          <EditingTextArea
            type='text'
            name='bio'
            value={form.bio}
            onChange={handleChange}
            className='p-0'
            placeholder='Enter Bio'
            editing={editing}
            access={hasAccess('author_create')}
          />
        </li>
        <li className='d-flex'>
          <div className='col-3 pl-0 pt-1'>
            <label className='m-0'>Info:</label>
          </div>
          <EditingTextArea
            type='text'
            name='info'
            value={form.info}
            onChange={handleChange}
            className='p-0'
            placeholder='Enter Info'
            required
            editing={editing}
            access={hasAccess('author_create')}
          />
        </li>
        <li className='d-flex align-items-center'>
          <div className='col-3 pl-0'>
            <label className='m-0'>Image:</label>
          </div>
          <div className='flex-fill d-flex align-items-center justify-content-between'>
            <span className='text-secondary font-weight-light pl-2'>
              {form.imageUrl || form.base64Image ? (
                <ResponsiveImage
                  height='40'
                  className='mr-2'
                  imgUrl={form.base64Image || form.imageUrl}
                  alt='img'
                />
              ) : (
                'Not Selected'
              )}
            </span>
            {editing && (
              <input
                id='file'
                name='file'
                type='file'
                className='flex-fill hide'
                onChange={e => handleImageSelect(e.target.files[0])}
              />
            )}
            {editing && (
              <label htmlFor='file' className='btn-sm px-4 btn btn-white m-0 pointer'>
                Choose Photo
              </label>
            )}
          </div>
        </li>
      </ul>
      {hasAccess('author_create') && (
        <div className='d-flex justify-content-end mt-2'>
          {!editing && (
            <Button
              className='btn-blue mr-2 mt-1 px-4'
              disabled={fetch}
              onClick={() => setEditing(!editing)}
            >
              Edit
            </Button>
          )}
          <ButtonLine className='btn btn-blue px-4' type='submit' disabled={fetch}>
            {editing ? 'Preview' : isUpdate ? 'Update' : 'Create'}
          </ButtonLine>
        </div>
      )}
    </form>
  );
};

export default CreationForm;
