import React, { useEffect, useState } from 'react';
import DragList from 'shared/components/DragList';
import DragItem from 'shared/components/DragItem';
import EditingInput from 'shared/components/editingForms/EditingInput';
import EditingSelect from 'shared/components/editingForms/EditingSelect';
import removeIcon from 'assets/bin.svg';
import { reorder } from 'utils/appHelpers';
import LessonCasesBlock from './LessonCasesBlock';
import { checkQuestionaryCaseCompleted } from '../utils';
import ResponsiveImage from 'shared/components/ResponsiveImage';

const findingQuestions = [{ name: 'No Findings', id: 1 }, { name: 'Findings Available', id: 2 }];
const questionTypes = [
  {
    name: 'Checkboxes',
    value: 'checkbox',
  },
  {
    name: 'Radio',
    value: 'radio',
  },
];

const emptyAnswer = {
  name: '',
  correct: false,
};

const getEmptyQuestionObj = () => ({
  question_type: 'checkbox',
  answers: [{ ...emptyAnswer }, { ...emptyAnswer }],
});

const setAllInccorect = arr =>
  arr.map(item => {
    item.correct = false;
    return item;
  });

const LessonCasesQuestions = ({ data, setData, isInUse, isRequired }) => {
  const [currentCase, setCurrentCase] = useState(data.activeCases[0] && data.activeCases[0].caseId);
  const [caseQuestions, setCaseQuestions] = useState(data.caseQuestions || {});

  const fillQuestions = () => {
    if (caseQuestions[currentCase]?.length) return;
    setCaseQuestions({
      ...caseQuestions,
      [currentCase]: [{ ...getEmptyQuestionObj() }],
    });
  };

  const onSelectFinding = ({ target }) => {
    setData({ ...data, hasFinding: { ...data.hasFinding, [currentCase]: target.value } });
  };

  const onSelectQuestionType = (qIndex, { target }) => {
    const tempData = { ...caseQuestions };
    const row = tempData[currentCase][qIndex];
    row.question_type = target.value;
    row.answers = setAllInccorect(row.answers);
    setCaseQuestions(tempData);
  };

  const onRemoveQuestion = index => {
    const tempData = { ...caseQuestions };
    tempData[currentCase].splice(index, 1);
    setCaseQuestions(tempData);
  };

  const onAddQuestion = () => {
    const tempData = { ...caseQuestions };
    tempData[currentCase].push({ ...getEmptyQuestionObj() });
    setCaseQuestions(tempData);
  };

  const onRemoveAnwser = (qIndex, index) => {
    const tempData = { ...caseQuestions };
    tempData[currentCase][qIndex].answers.splice(index, 1);
    setCaseQuestions(tempData);
  };

  const onAddAnwser = index => {
    const tempData = { ...caseQuestions };
    tempData[currentCase][index].answers.push({ ...emptyAnswer });
    setCaseQuestions(tempData);
  };

  const onAnswerChange = (qIndex, index, { target }) => {
    const { name, value } = target;
    const tempData = { ...caseQuestions };
    tempData[currentCase][qIndex].answers[index][name] = value;
    setCaseQuestions(tempData);
  };

  const onAnswerCheck = (qIndex, index) => {
    const tempData = { ...caseQuestions };
    const row = tempData[currentCase][qIndex];
    if (row.question_type === 'radio') {
      row.answers = setAllInccorect(row.answers);
    }
    const answer = row.answers[index];
    answer.correct = !answer.correct;
    setCaseQuestions(tempData);
  };

  const onDragEnd = (qIndex, e) => {
    if (!e.destination) return;
    const tempData = { ...caseQuestions };
    tempData[currentCase][qIndex].answers = reorder(
      tempData[currentCase][qIndex].answers,
      e.source.index,
      e.destination.index,
    );
    setCaseQuestions(tempData);
  };

  useEffect(() => {
    fillQuestions();
    //eslint-disable-next-line
  }, [currentCase]);

  useEffect(() => {
    setData({ ...data, caseQuestions });
    //eslint-disable-next-line
  }, [caseQuestions]);

  const activeItems = caseQuestions[currentCase];
  const showQuestions = data.hasFinding && Number(data.hasFinding[currentCase] || 0) === 2;

  const checkCaseCompleted = ({ caseId }) => {
    return checkQuestionaryCaseCompleted(data, caseId);
  };

  return (
    <div className='px-2 lesson-question-create'>
      <h6 className='color-blue mb-3'>Imagery Feedback Settings</h6>
      <LessonCasesBlock
        cases={data.activeCases}
        activeCase={currentCase}
        onClickCase={setCurrentCase}
        isCompletedCase={checkCaseCompleted}
      />
      <div className='col-8 p-0 user-creation-form'>
        <ul className='p-0 mb-0'>
          <li className='d-flex align-items-center'>
            <div className='col-3 pl-0'>
              <label className='m-0'>Findings Availability*:</label>
            </div>
            <EditingSelect
              name='contentType'
              onChange={onSelectFinding}
              value={(data.hasFinding && data.hasFinding[currentCase]) || ''}
              items={findingQuestions}
              useProperty='id'
              displayProperty='name'
              required={isRequired}
              editing={!isInUse}
            />
          </li>
        </ul>
        {showQuestions &&
          activeItems &&
          activeItems.length &&
          activeItems.map(({ question_type, answers }, qIndex) => {
            const disableQuestion = qIndex === 0 && activeItems.length < 2;
            const isLast = qIndex + 1 === activeItems.length;
            return (
              <ul className='p-0 mb-0 mt-3 mb-4' key={qIndex}>
                <div className='d-flex drag-item mb-2'>
                  <li className='d-flex align-items-center w-100'>
                    <div className='col-3 pl-0'>
                      <label className='m-0'>Question Type*:</label>
                    </div>
                    <EditingSelect
                      name='type'
                      onChange={onSelectQuestionType.bind(null, qIndex)}
                      value={question_type}
                      items={questionTypes}
                      useProperty='value'
                      displayProperty='name'
                      required={isRequired}
                      editing={!isInUse}
                      hideDefault={true}
                    />
                  </li>
                  <button
                    onClick={onRemoveQuestion.bind(null, qIndex)}
                    disabled={disableQuestion || isInUse}
                    type='button'
                    className='btn btn-remove ml-2'
                  >
                    <ResponsiveImage imgUrl={removeIcon} alt='icon' />
                  </button>
                </div>
                <DragList
                  onDragEnd={onDragEnd.bind(null, qIndex)}
                  disabled={isInUse}
                  items={answers}
                  rowClassName='mb-2'
                >
                  {(item, index) => {
                    return (
                      <DragItem
                        onItemRemove={() => onRemoveAnwser(qIndex, index)}
                        onItemRemoveDisabled={isInUse}
                        onItemCheck={() => onAnswerCheck(qIndex, index)}
                        isItemCheck={item.correct}
                        className='drag-is-lesson-answer'
                        disabled={isInUse}
                      >
                        <li className='d-flex align-items-center mb-0 w-100'>
                          <div className='col-3 pl-0'>
                            <label className='m-0'>Answer Variant {index + 1}*:</label>
                          </div>
                          <EditingInput
                            type='text'
                            name='name'
                            value={item.name}
                            onChange={onAnswerChange.bind(null, qIndex, index)}
                            className='p-0'
                            required={isRequired}
                            editing={!isInUse}
                            access={true}
                            maxLength='100'
                          />
                        </li>
                      </DragItem>
                    );
                  }}
                </DragList>
                <div className='d-flex justify-content-between add-new-items-btns'>
                  <button
                    disabled={isInUse}
                    type='button'
                    className='btn btn-blue'
                    onClick={onAddAnwser.bind(null, qIndex)}
                  >
                    Add Answer +
                  </button>
                  {isLast && (
                    <button
                      type='button'
                      disabled={isInUse}
                      className='btn btn-outline-blue'
                      onClick={onAddQuestion}
                    >
                      Add Question +
                    </button>
                  )}
                </div>
              </ul>
            );
          })}
      </div>
    </div>
  );
};

export default LessonCasesQuestions;
