import React, { useState } from 'react';
import ButtonLine from 'shared/components/ButtonLine';
import { IconNotification } from 'shared/components/Icons';

import FeedCheckbox from './components/FeedCheckbox';
import FeedRating from './components/FeedRating';
import FeedSelect from './components/FeedSelect';
import FeedText from './components/FeedText';

const feedItemsComp = {
  rating: FeedRating,
  radio: FeedSelect,
  checkbox: FeedCheckbox,
  text: FeedText,
};

const TypeEvaluation = ({ course }) => {
  const [isAnswered, setIsAnswered] = useState(false);
  const [answers, setAnswers] = useState({});

  const handleChange = data => {
    const tempAnswers = { ...answers };
    tempAnswers[data.id] = data.value;
    setAnswers(tempAnswers);
  };

  const onFormSubmit = e => {
    e.preventDefault();
    setIsAnswered(true);
  };

  const hasFeedback = !!course.feedbacks?.length;

  return (
    <div>
      {hasFeedback && (
        <div className='p-4 col-9'>
          <form onSubmit={onFormSubmit}>
            <ul className='default-form p-0'>
              {course.feedbacks &&
                course.feedbacks.map((item, index) => {
                  const FeedItem = feedItemsComp[item.type || item.feedbackType];
                  if (!FeedItem) return null;
                  return (
                    <FeedItem
                      index={index}
                      key={index}
                      data={item}
                      onChange={handleChange}
                      answers={answers}
                      isDisabled={isAnswered}
                      isAnswered={isAnswered}
                    />
                  );
                })}
            </ul>
            <div className='d-flex justify-content-end mt-2'>
              {!isAnswered && (
                <ButtonLine type='submit' className='btn-contact-send btn-blue'>
                  Send
                </ButtonLine>
              )}
            </div>
          </form>
        </div>
      )}
      {!hasFeedback && (
        <div className='text-center mt-100'>
          <IconNotification width='90px' height='84px' className='mb-4' color='#131F6B' />
          <p className='fz-20'>
            Feedback form is not configured yet. <br /> Please, add content to see the preview.
          </p>
        </div>
      )}
    </div>
  );
};

export default TypeEvaluation;
