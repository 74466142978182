export const patientModels = {
  'Woman 1': 'Woman 1',
};

export const johnnyTypes = {
  Gown: 'Gown',
  Robe: 'Robe',
};

export const operatorModels = {
  'Operator 1': 'Operator 1',
};

export const gantryModels = {
  'Dimensions with Biopsy': 'Dimensions with Biopsy',
  'Standard Dimensions': 'Standard Dimensions',
};

export const patientPositions = {
  0: 'Sitting',
  1: 'Standing',
  2: 'Other',
};

export const patientPaddles = {
  0: 'Number One',
  1: 'Transparent with Measures',
  2: 'Number Two',
};

export const roomScanAngels = {
  0: 'Horizontal / MLO',
  1: 'Vertical / CC',
  2: 'Other',
};

export const transitionEffects = {
  0: 'Blur',
  1: 'Dissolve',
  2: 'None',
};

export const biopsyUsage = {
  0: 'Not Include',
  1: 'Include',
};

export const affirmProneBiopsyUsage = {
  0: 'Not Include',
  1: 'Include',
};

export const discoveryDXAUsage = {
  0: 'Not Include',
  1: 'Include',
};

export const faxitronCoreSpecimenUsage = {
  0: 'Not include',
  1: 'include',
};

export const faxitronPathSpecimenUsage = {
  0: 'Not include',
  1: 'include',
};

export const fluoroscanMiniCArmUsage = {
  0: 'Not include',
  1: 'include',
};

export const appMarkers = {
  0: 'Marker 1',
  1: 'Marker 2',
  2: 'Marker 3',
};

export const awsVersions = {
  'v10.247': 'v10.247',
  'v10.248': 'v10.248',
  'v10.249': 'v10.249',
  'AWS 1.2.356': 'AWS 1.2.356',
};

export const wallTypes = {
  'Wooden Paneling': 'Wooden Paneling',
  Wallpaper: 'Wallpaper',
  'Glass Walls': 'Glass Walls',
};

export const floorTypes = {
  Parquet: 'Parquet',
  Vinyl: 'Vinyl',
  'Stone tiles': 'Stone tiles',
};

export const clockTypes = {
  Metal: 'Metal',
  Digital: 'Digital',
  traditional: 'Traditional',
  Trending: 'Trending',
};

export const decors = {
  0: 'Paintings',
  1: 'Flowers',
  2: 'Mirror',
  3: 'Window Shades',
  4: 'Bookshelf',
};

export const breastSizes = {
  A: 'A',
  B: 'B',
  C: 'C',
  D: 'D',
  E: 'E',
};

export const skinColors = {
  White: 'White',
  Yellow: 'Yellow',
  Brown: 'Brown',
};

export const ethnicities = {
  American: 'American Indian',
  Latino: 'Hispanic',
  Asian: 'Asian',
  Black: 'Black or African American',
  Native: 'Native Hawaiian or Other Pacific Islander',
  White: 'White',
};

export const pathologies = {
  Classifications: 'Classifications',
};

export const optionsToList = obj => {
  const results = [];
  Object.keys(obj).forEach(key => {
    results.push({ name: obj[key], id: key });
  });
  return results;
};
