import React from 'react';
import UploadProgress from 'shared/components/UploadProgress';
import IconCases from 'assets/wizard/select_cases.svg';
import { useSelector } from 'react-redux';
import { setCaseFiles } from '../actions';
import { useSnackbar } from 'notistack';
import { getError } from 'utils/appHelpers';

const InfoIcon = ({ name, value, className }) => (
  <span className={`mr-2 mb-0 fz-13 ${className || ''}`}>
    <span className='text-secondary text-blue'>{name}</span>:{' '}
    <b className='font-weight-normal'>{value}</b>
  </span>
);

const UploadInProgress = ({ filesExist }) => {
  const { enqueueSnackbar } = useSnackbar();
  const uploader = useSelector(state => state.uploader);
  const files = uploader?.files;
  const uploadProgress = uploader?.progress;

  const onFileSelect = async e => {
    try {
      const newFiles = [...e.target.files];
      setCaseFiles([...files, ...newFiles]);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  return (
    <div>
      <div className='users-header d-flex justify-content-between align-items-center'>
        <div className='d-flex breadcrumbs'>
          <span>Upload New DICOM</span>
        </div>
      </div>
      <div className='has-header'>
        <div className='d-flex'>
          <div className='px-3 col-7' key={files?.length}>
            <label className={`col-12 upload-wizard-block pointer`} htmlFor='file'>
              <input
                id='file'
                name='file'
                type='file'
                accept='.dcm'
                className='flex-fill hide'
                multiple='multiple'
                onChange={onFileSelect}
              />
              <div className='d-flex flex-column align-items-center'>
                <img src={IconCases} alt='icon' />
                Select Files
              </div>
            </label>
          </div>
        </div>
        <div className='mb-2 mt-3 px-3'>
          <h6 className='color-blue mb-3'>Uploading Progress</h6>
          {!!files?.length &&
            files.map((file, i) => {
              if (i > 0) return null;
              return (
                <div className='blue-box col-7 mb-2'>
                  <p className='mb-1 mt-1'>{file.name}</p>
                  <div className='mb-2'>
                    <InfoIcon name='Size' value={`${(file.size / (1024 * 1024)).toFixed(2)} mb`} />
                  </div>
                  {uploadProgress?.[file?.name] && (
                    <UploadProgress className='w-100' progress={uploadProgress?.[file?.name]} />
                  )}
                </div>
              );
            })}
          <h6 className='color-blue mb-3 mt-4'>Files Queue</h6>
          {!!files?.length &&
            files.map((file, i) => {
              if (i === 0) return null;
              const isFileExist = filesExist.includes(file?.name);
              return (
                <div className='blue-box col-7 mb-2'>
                  <p className='mb-1 mt-1'>{file.name}</p>
                  <div className='mb-2'>
                    <InfoIcon name='Size' value={`${(file.size / (1024 * 1024)).toFixed(2)} mb`} />
                  </div>
                  {isFileExist && (
                    <div className='text-danger fz-11'>
                      This file can not be uploaded, you should first delete the existing DICOM to
                      be able to upload a new one.
                    </div>
                  )}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default UploadInProgress;
