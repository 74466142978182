import React from 'react';
import { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { IconArrowDown, IconArrowUp } from 'shared/components/Icons';
// Chart.defaults.global.defaultFontColor = '#fff';

const colors = [
  '#FFB100',
  '#008AAD',
  '#E3E35D',
  '#7C9A34',
  '#477EFF',
  '#8B4A8E',
  '#EB4D4D',
  '#349A4C',
  '#131F6B',
  '#264653',
  '#2a9d8f',
  '#e9c46a',
  '#f4a261',
  '#e76f51',
  '#8ecae6',
  '#219ebc',
  '#023047',
  '#ffb703',
  '#fb8500',
  '#480ca8',
  '#e07a5f',
  '#81b29a',
  '#ef476f',
];

const BlockEvaluationAnswers = ({ data }) => {
  const [openState, setOpenState] = useState(true);

  const getBarSettings = data => {
    const dataArr = data.map(item => item.count);
    const barData = {
      labels: dataArr,
      datasets: [
        {
          backgroundColor: colors,
          scaleFontColor: '#fff',
          borderColor: colors,
          borderWidth: 1,
          data: dataArr,
        },
      ],
    };

    const barOptions = {
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      scales: {
        xAxes: [
          {
            ticks: { display: false },
            scaleLabel: {
              display: true,
              labelString: 'Selection',
              fontColor: '#898989',
            },
          },
        ],
        yAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: 'Count',
              fontColor: '#898989',
            },
          },
        ],
      },
    };
    return { barData, barOptions };
  };

  return data?.map(item => {
    const { barData, barOptions } = getBarSettings(item.summary);
    return (
      <div
        className={`dash-block block-engaged d-flex flex-column w-100 ${
          openState ? 'opened' : 'closed'
        }`}
      >
        <div className='align-items-center block-title d-flex justify-content-between'>
          {item.title}
          <button className='btn p-0' onClick={() => setOpenState(state => !state)}>
            {openState ? <IconArrowDown /> : <IconArrowUp />}
          </button>
        </div>
        <div className='d-flex mt-4'>
          <div className='col-8 pl-0'>
            <Bar data={barData} width={100} height={280} options={barOptions} />
          </div>
          <div className='col-4'>
            <ul className='courses-list'>
              {item.summary.map((item, i) => {
                return (
                  <li key={i} className='d-flex align-items-center justify-content-between'>
                    <span className='circle' style={{ backgroundColor: colors[i] }} />
                    <p>{item.selection}</p>
                    <b>{item.count}</b>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    );
  });
};

export default BlockEvaluationAnswers;
