import React, { useState } from 'react';
import { connect } from 'react-redux';
import InputGroup from 'shared/components/InputGroup';
import { loginUser } from './../actions/index';
import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';
import ButtonLine from 'shared/components/ButtonLine';
import AuthBlock from '../components/AuthBlock';
import { IconMail, IconPassword } from 'shared/components/Icons';

const Login = ({ loginUser }) => {
  const [error, setError] = useState();
  const [fetch, setFetch] = useState();
  const [auth, setAuth] = useState({
    email: '',
    password: '',
  });

  const authOptions = {
    title: ' Welcome to Admin Panel',
    links: { reset: true },
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setFetch(true);
    try {
      const res = await Api.post('/admin/login', auth);
      await loginUser(res.data);
      setFetch(false);
    } catch (err) {
      setError(getError(err));
      setFetch(false);
    }
  };

  const handleChange = ({ target }) => {
    setError('');
    const { name, value } = target;
    const tempAuth = { ...auth };
    tempAuth[name] = value;
    setAuth(tempAuth);
  };

  return (
    <AuthBlock {...authOptions}>
      <form onSubmit={handleSubmit} className='mt-5'>
        <InputGroup
          type='text'
          name='email'
          onChange={handleChange}
          className='mb-4'
          placeholder='Email address'
          value={auth.email}
          icon={<IconMail />}
          autoFocus
          required
        />
        <InputGroup
          hasError={error}
          type='password'
          name='password'
          onChange={handleChange}
          placeholder='Password'
          value={auth.password}
          icon={<IconPassword />}
          required
        />
        {error && <div className='error-text'>{error}</div>}
        <ButtonLine type='submit' disabled={fetch}>
          Sign In
        </ButtonLine>
      </form>
    </AuthBlock>
  );
};

export default connect(
  null,
  { loginUser },
)(Login);
