import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import InformationPart from './components/InformationPart';
import AnswerViewer from './components/AnswerViewer';
import DisabledPart from './components/DisabledPart';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import Loading from 'shared/components/Loading';
import { mergeSteps } from './configs';

const ExplanationModal = ({ isDisabled, updateStep, caseData, caseId, caseViews }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [fetch, setFetch] = useState(false);
  const [data, setData] = useState();
  const [activeStep, setActiveStep] = useState(0);

  const sendUpdate = async () => {
    await updateStep(10);
  };

  const getAnswersData = async () => {
    try {
      setFetch(true);
      const { data } = await Api.get(`/cases/getanswers/${caseId}`);
      data.data.mergedSteps = mergeSteps(caseViews, data.data.answers);
      setData(data.data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetch(false);
    }
  };

  useEffect(() => {
    if (!isDisabled) sendUpdate();
    getAnswersData();
    //eslint-disable-next-line
  }, []);

  if (isDisabled) return <DisabledPart />;
  if (fetch) return <Loading classView='w-100 mt-5' />;

  if (!data)
    return (
      <div className='p-4 text-center text-white w-100'>No Answers Provided for this lesson</div>
    );

  return (
    <div className='modal-custom modal__fullscreen modal__fluid modal-explanation p-0'>
      <div className='inner-content'>
        <div className='d-flex explanation-modal'>
          <InformationPart activeStep={activeStep} data={data} caseId={caseData.caseUniqueId} />
          <AnswerViewer
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            data={caseViews}
            mergedSteps={data.mergedSteps}
          />
        </div>
      </div>
    </div>
  );
};

export default ExplanationModal;
