import React from 'react';
import { Link } from 'react-router-dom';
import { IconSearch } from 'shared/components/Icons';

const CourseUsersHeader = ({ role, onSearch, enqueueSnackbar }) => {
  return (
    <div className='users-header d-flex justify-content-between align-items-center'>
      <div className='d-flex breadcrumbs'>
        <Link to='/roles/all'>All Roles</Link>
        <span className='mx-2'>/</span>
        <Link to={`/roles/${role.id}/view`}>{role.title}</Link>
        <span className='mx-2'>/</span>
        <span>Assigned Users</span>
      </div>
      <div className='d-flex'>
        <div className='search-block'>
          <input
            type='text'
            onChange={({ target }) => onSearch(target.value)}
            className='form-control'
          />
          <IconSearch />
        </div>
      </div>
    </div>
  );
};

export default CourseUsersHeader;
