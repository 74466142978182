import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Api } from 'utils/connectors';
import UsersHeader from './components/UsersHeader';
import ReactPaginate from 'react-paginate';
import UsersLists from './components/UsersLists';
import { getError } from 'utils/appHelpers';
import { IconArrowLeft, IconArrowRight } from 'shared/components/Icons';
import AdvancedFilters from 'shared/components/filters/AdvancedFilters';
import { hasAccess } from 'utils/permissionHelper';
import useFilters from 'shared/hooks/useFilters';
import useDidUpdateEffect from 'shared/hooks/useDidUpdateEffect';

const filterCert = [{ name: 'Received', id: true }, { name: 'Not Received', id: false }];

const filterStatus = [
  { name: 'Active', id: 1 },
  { name: 'Pending', id: 3 },
  { name: 'Invited', id: 4 },
  { name: 'Archive', id: 2 },
];

const completedLearnersDownloadData = {
  title: 'Completed Learners',
  url: 'common/exportusers/1',
  type: 'pdf',
  fileName: 'completed_learners',
};

const initialFilters = () => {
  return {
    entity: '',
    regDate: '',
    enrolled: [],
    cert: '',
    status: '',
  };
};

const Users = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [fetch, setFetch] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [users, setUsers] = useState();
  const [courses, setCourses] = useState([]);
  const [entities, setEntities] = useState([]);

  const {
    requestParams,
    filters,
    dropFilters,
    changeFilter,
    changeDropFilter,
    clearDropFilter,
    filterDependency,
    originalState,
  } = useFilters(
    { page: 0, order: false, orderKey: 'createdAt', search: '', limit: 8 },
    { ...initialFilters() },
  );

  const params = {
    ...requestParams,
    fetch_all: true,
  };

  const downloadData = [
    {
      title: 'Export as CSV',
      url: '/admin/getusers',
      fileName: 'users_list',
      type: 'csv',
      params,
    },
    completedLearnersDownloadData,
  ];

  const getUsersList = async () => {
    try {
      setFetch(true);
      const { data } = await Api.get('/admin/getusers', { params });
      setPageCount(Math.ceil(data?.data?.count / 8));
      setUsers(data?.data?.results || []);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetch(false);
    }
  };

  const getCourses = async () => {
    try {
      const { data } = await Api.get('/wizard/courses');
      const result = data.map(item => ({ name: item.title, id: item.courseId }));
      setCourses(result);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const getEntities = async () => {
    try {
      const params = { page: 1, limit: 1000 };
      const { data } = await Api.get(`/entity/entities`, { params });
      if (data?.data) setEntities(data.data.results);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onClearAllFilters = () => {
    clearDropFilter();
  };

  const onFilterChange = (name, id) => {
    changeDropFilter(name, id);
  };

  const onPageChange = ({ selected }) => {
    changeFilter({ page: selected });
  };

  const handleSearchChange = text => {
    changeFilter({ search: text });
  };

  const onChangeOrder = item => {
    if (!item.key) return;
    changeFilter({ order: !filters.order, orderKey: item.key });
  };

  const filtersOptions = [
    {
      type: 'dropdown',
      title: 'Entities',
      name: 'entities',
      data: entities,
      value: dropFilters.entities,
      showSearch: true,
      onFilterChange: onFilterChange,
      hide: hasAccess('entity_manager'),
    },
    {
      type: 'multiDropdown',
      title: 'Enrollment',
      name: 'enrolled',
      data: courses,
      value: dropFilters.enrolled,
      showSearch: true,
      onFilterChange: onFilterChange,
      hide: hasAccess('entity_manager'),
    },
    {
      type: 'dropdown',
      title: 'Certificate',
      name: 'cert',
      data: filterCert,
      value: dropFilters.cert,
      onFilterChange: onFilterChange,
    },
    {
      type: 'date',
      title: 'Registration Date',
      name: 'regDate',
      data: [],
      value: dropFilters.regDate,
      onFilterChange: onFilterChange,
    },
    {
      type: 'dropdown',
      title: 'Status',
      name: 'status',
      data: filterStatus,
      value: dropFilters.status,
      onFilterChange: onFilterChange,
    },
  ];

  useDidUpdateEffect(() => {
    getUsersList();
  }, [...filterDependency]);

  useEffect(() => {
    if (!hasAccess('entity_manager')) {
      getCourses();
      getEntities();
    }
  }, []);

  return (
    <div>
      <UsersHeader search={originalState.search} onSearch={handleSearchChange} />
      <div className='py-3 has-header'>
        <AdvancedFilters
          withExport={true}
          downloadData={downloadData}
          filters={filtersOptions}
          onClearAll={onClearAllFilters}
        />
        <div className='col-12'>
          <UsersLists
            users={users}
            order={filters}
            status={dropFilters.status}
            changeOrder={onChangeOrder}
            fetch={fetch}
          />
        </div>
        {pageCount > 1 && !fetch && (
          <ReactPaginate
            previousLabel={<IconArrowLeft />}
            nextLabel={<IconArrowRight />}
            breakLabel={'...'}
            forcePage={Number(filters.page)}
            breakClassName={'break-me'}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={2}
            onPageChange={onPageChange}
            containerClassName={'pagination show-arrow'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
          />
        )}
      </div>
    </div>
  );
};

export default Users;
