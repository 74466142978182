import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { IconClose, IconSearch } from 'shared/components/Icons';
import debounce, { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import { confirmAlert } from 'react-confirm-alert';
import ConfirmRequestModal from '../../SubscriptionRequest/components/ConfirmRequestModal';

import SpinnerAnimatedBlue from 'assets/wizard/SpinnerAnimatedBlue.svg';

const SubActionButton = ({ show, className, onClick, disabled, loading, name }) => {
  if (!show) return null;
  return (
    <button className={`btn ${className}`} onClick={onClick} disabled={disabled}>
      {loading && <div className='spinner-border spinner-grow-sm mr-2' />}
      <span className='mr-1'>{name}</span>
    </button>
  );
};

const UserLicenseKeysModal = ({
  close,
  onSuccess = () => {},
  user,
  setUserLicenses,
  userLicenses,
}) => {
  const [assignFetch, setAssignFetch] = useState(false);
  const [unAssignFetch, setUnassignFetch] = useState(false);
  const [licenses, setLicenses] = useState([]);
  const [modalState, setModalState] = useState({});
  const [isFetching, setIsFetching] = useState(false);
  const [search, setSearch] = useState('');
  const lastSearch = useRef('');
  const { enqueueSnackbar } = useSnackbar();

  let availableLicensesPage = 1;

  const availableContainerRef = useRef(null);

  const assignKey = async id => {
    try {
      setAssignFetch(id);
      const { data } = await Api.post(`/auth/assign-license/${user.id}/${id}`);
      if (onSuccess) await onSuccess();
      getAssignedKeys();
      getLicenses(search, true);
      enqueueSnackbar(data.added, { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setAssignFetch(false);
    }
  };

  const unAssignKey = async id => {
    try {
      setUnassignFetch(id);
      const { data } = await Api.post(`/auth/unassign-license/${user.id}/${id}`);
      if (onSuccess) await onSuccess();
      getAssignedKeys();
      getLicenses(search, true);
      enqueueSnackbar(data.message, { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setUnassignFetch(false);
    }
  };

  const unAssignKeyConfirmation = id => {
    confirmAlert({
      message: `Unassign the key will block user's access to all courses that were accessible by this key. Are you sure your want proceed?`,
      buttons: [
        {
          label: 'Close',
        },
        {
          label: 'Continue',
          onClick: () => unAssignKey(id),
        },
      ],
    });
  };

  const debouncedSearch = useCallback(
    debounce(value => {
      setSearch(value);
      getLicenses(value);
    }, 300),
    [],
  );

  const getAssignedKeys = async () => {
    try {
      const { data } = await Api.get(`/auth/getuserbyid/${user.id}`);
      setUserLicenses(data.data.licenses);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const getLicenses = async (searchVal = '', newList) => {
    try {
      setIsFetching(true);
      const { data } = await Api.get(
        `/auth/available-licenses/${
          user.id
        }?page=${availableLicensesPage}&search=${searchVal}&limit=5`,
      );
      if (searchVal === lastSearch.current && !newList) {
        setLicenses(prev => [...prev, ...data.data.results]);
      } else {
        setLicenses(data.data.results);
        availableLicensesPage = 1;
        lastSearch.current = searchVal;
      }
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    let fetching = false; // Add this flag

    const handleScroll = () => {
      const container = availableContainerRef.current;
      if (container) {
        const { scrollTop, scrollHeight, clientHeight } = container;

        // Check if we're not already fetching and the user has scrolled to the bottom
        if (!fetching && scrollTop + clientHeight >= scrollHeight - 5) {
          fetching = true; // Set fetching to true when starting the fetch
          fetchMoreData();
        }
      }
    };

    const fetchMoreData = async () => {
      try {
        availableLicensesPage += 1;
        setIsFetching(true);
        await getLicenses(lastSearch.current, false);
      } catch (err) {
        enqueueSnackbar(getError(err), { variant: 'error' });
      } finally {
        setIsFetching(false);
        fetching = false; // Set fetching back to false when the fetch is done
      }
    };
    // Attach the scroll event listener to the container div
    availableContainerRef.current.addEventListener('scroll', handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      availableContainerRef.current.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    getLicenses(search);
    getAssignedKeys();
    //eslint-disable-next-line
  }, []);

  if (!user) return null;

  const availableSits =
    licenses &&
    licenses.filter(licenseKey => !licenseKey.entity || licenseKey.entity.id === user?.entity?.id);

  return (
    <>
      <div className='modal-box deactivate-modal d-flex flex-column unset_height users_keys_modal'>
        <div className='align-items-start d-flex header justify-content-between'>
          <div>Manage License Keys</div>
          <div onClick={close} tabIndex='-1' role='button' className='mt-1 pointer'>
            <IconClose />
          </div>
        </div>

        <div className='content flex-fill overflow-auto'>
          <p className='mt-3 mb-2 weight-500 fz-16'>Assigned:</p>
          <div className='subs-plan-area'>
            {userLicenses.length ? (
              userLicenses?.map(item => {
                return (
                  <div
                    className='plan-box align-items-center d-flex justify-content-between'
                    key={item.id}
                  >
                    <div className='plan-name'>{item.licenseKey}</div>
                    <div className='d-flex align-items-center'>
                      <div className='d-flex'>
                        <SubActionButton
                          name='UNASSIGN'
                          loading={unAssignFetch === item.id}
                          disabled={unAssignFetch}
                          onClick={unAssignKeyConfirmation.bind(null, item.id)}
                          className='text-danger'
                          show={true}
                        />
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className='plan-box'>No Active Keys</div>
            )}
          </div>
          <p className='mt-3 mb-2 weight-500 fz-16'>Available:</p>
          <div className='search-block'>
            <input
              type='text'
              onChange={({ target }) => debouncedSearch(target.value)}
              className='form-control w-100'
              placeholder='Search license key'
            />
            <IconSearch />
          </div>
          <div className='subs-plan-area' ref={availableContainerRef}>
            {availableSits && !!availableSits.length ? (
              <>
                {availableSits.map(item => (
                  <div
                    className='plan-box align-items-center d-flex justify-content-between'
                    key={item.licenseKey}
                  >
                    <div className='plan-name'>{item.licenseKey}</div>
                    <div className='d-flex align-items-center'>
                      <SubActionButton
                        name='Assign'
                        loading={assignFetch === item.id}
                        disabled={assignFetch}
                        onClick={assignKey.bind(null, item.id)}
                        className='text-primary'
                        show={true}
                      />
                    </div>
                  </div>
                ))}
                {isFetching && (
                  <div className='d-flex w-100 justify-content-center align-items-center load_more'>
                    <img width={24} height={24} src={SpinnerAnimatedBlue} alt='spin' />
                    <p>Load 5 more</p>
                  </div>
                )}
              </>
            ) : (
              <>
                {isFetching ? (
                  <div className='d-flex w-100 justify-content-center align-items-center load_more'>
                    <img width={24} height={24} src={SpinnerAnimatedBlue} alt='spin' />
                    <p>Loading...</p>
                  </div>
                ) : (
                  <div className='plan-box'>No Keys</div>
                )}
              </>
            )}
          </div>
        </div>
        <div className='footer text-right px-4'>
          <button className='btn mt-2 text-blue weight-600' onClick={close}>
            Done
          </button>
        </div>
      </div>
      {modalState.type && (
        <ConfirmRequestModal
          data={modalState.data}
          type={modalState.type}
          fromUserProfile={false}
          onModalClose={() => setModalState({})}
          onSuccess={onSuccess}
        />
      )}
    </>
  );
};

export default UserLicenseKeysModal;
