import React from 'react';
import Button from './Button';

const SmallCounter = props => {
  const {
    count,
    setCount,
    disabled,
    handleDecreaseCount,
    handleIncreaseCount,
    customClasses,
    stepCount = 1,
    measurementUnit = '',
  } = props;

  return (
    <div
      className={`small-count-container ${
        disabled ? 'disabled' : 'white-bg-children'
      } ${customClasses}`}
    >
      <Button
        className='cube-radiused small'
        onClick={
          count > stepCount
            ? handleDecreaseCount
              ? () => handleDecreaseCount()
              : () => setCount(prev => prev - stepCount)
            : null
        }
      >
        -
      </Button>
      <Button className='cube-radiused'>
        {count}
        {measurementUnit}
      </Button>
      <Button
        className='cube-radiused small'
        onClick={
          handleIncreaseCount
            ? () => handleIncreaseCount()
            : () => setCount(prev => prev + stepCount)
        }
      >
        +
      </Button>
    </div>
  );
};

export default SmallCounter;
