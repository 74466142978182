import React, { useState, useEffect } from 'react';
import AdminHeader from './components/AdminHeader';
import AdminInfo from './components/AdminInfo';
import Loading from 'shared/components/Loading';
import { Api } from 'utils/connectors';
import { useSnackbar } from 'notistack';
import { getError } from 'utils/appHelpers';

const AdminView = ({ match }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [user, setUser] = useState();
  const isInvited = match.params.type === 'invited';

  const getUserData = async () => {
    try {
      const { id } = match.params;
      const path = isInvited ? '/admin/getinvitationbyid' : '/auth/getuserbyid';
      const res = await Api.get(`${path}/${id}`);
      const data = res.data.data;
      data.roleId = data.roles?.length ? data.roles[0].id : null;
      setUser(data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  useEffect(() => {
    getUserData();
    //eslint-disable-next-line
  }, [match.params.id]);

  if (!user) return <Loading className='mt-5' />;

  return (
    <div>
      <AdminHeader user={user} />
      <div className='col-12 py-4 has-header'>
        <AdminInfo user={user} isInvited={isInvited} getUserData={getUserData} />
      </div>
    </div>
  );
};

export default AdminView;
