import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Api } from 'utils/connectors';
import ReactPaginate from 'react-paginate';
import UsersLists from './components/UsersLists';
import { getError } from 'utils/appHelpers';
import { IconArrowLeft, IconArrowRight } from 'shared/components/Icons';
import useFilters from 'shared/hooks/useFilters';
import ProductUsersHeader from './components/ProductUsersHeader';
import useQuery from 'shared/hooks/useQuery';
import AdvancedFilters from 'shared/components/filters/AdvancedFilters';

const initialFilters = ({ completed }) => {
  return { completed };
};

const Users = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [fetch, setFetch] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [users, setUsers] = useState([]);
  const [productName, setProductName] = useState('');
  const [isFirstMount, setIsFirstMount] = useState(true);

  const params = useParams();
  const { completed } = useQuery();

  const isCompleted = Number(completed) === 1;

  const filterState = [{ name: 'Yes', id: 1 }, { name: 'No', id: 0 }];

  const {
    requestParams,
    filters,
    dropFilters,
    changeFilter,
    changeDropFilter,
    clearDropFilter,
    filterDependency,
    originalState,
  } = useFilters(
    { page: 0, order: false, orderKey: 'createdAt', search: '', limit: 8, completed },
    { ...initialFilters({ completed: isFirstMount ? completed : '' }) },
  );

  const onFilterChange = (name, id) => {
    changeDropFilter(name, id);
  };

  const onClearAllFilters = () => {
    clearDropFilter();
  };

  const filtersOptions = [
    {
      type: 'dropdown',
      title: 'Completion',
      name: 'completed',
      data: filterState,
      value: dropFilters.completed,
      onFilterChange: onFilterChange,
    },
  ];

  const downloadData = [
    {
      title: 'Export as CSV',
      url: `/product/engaged-users/${params.productId}`,
      fileName: 'product_users_list',
      type: 'csv',
      params: requestParams,
    },
  ];

  const getProductData = async () => {
    try {
      const { data } = await Api.get(`/product/product/${params?.productId}`);
      setProductName(data?.data?.name || '');
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const getUsersList = async () => {
    try {
      setFetch(true);
      const { data } = await Api.get(`/product/engaged-users/${params?.productId}`, {
        params: requestParams,
      });
      setPageCount(data?.data?.pageCount);
      setUsers(data?.data?.results.map(user => ({ ...user, id: user?.userId })));
      if (data?.data?.productName) {
        setProductName(data?.data?.productName);
      } else {
        getProductData();
      }
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetch(false);
    }
  };

  const onPageChange = ({ selected }) => {
    changeFilter({ page: selected });
  };

  const handleSearchChange = text => {
    changeFilter({ search: text });
  };

  const onChangeOrder = item => {
    if (!item.key) return;
    changeFilter({ order: !filters.order, orderKey: item.key });
  };

  useEffect(() => {
    getUsersList();
    setIsFirstMount(false);
  }, [...filterDependency]);

  return (
    <div>
      <ProductUsersHeader
        productName={productName}
        isCompleted={isCompleted}
        onSearch={handleSearchChange}
      />
      <div className='py-3 has-header'>
        <AdvancedFilters
          withExport
          encounterZero
          downloadData={downloadData}
          filters={filtersOptions}
          onClearAll={onClearAllFilters}
        />
        <div className='col-12'>
          <UsersLists
            isCompleted={isCompleted}
            users={users}
            order={filters}
            status={dropFilters.status}
            changeOrder={onChangeOrder}
            fetch={fetch}
          />
        </div>
        {pageCount > 1 && !fetch && (
          <ReactPaginate
            previousLabel={<IconArrowLeft />}
            nextLabel={<IconArrowRight />}
            breakLabel={'...'}
            forcePage={Number(filters.page)}
            breakClassName={'break-me'}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={2}
            onPageChange={onPageChange}
            containerClassName={'pagination show-arrow'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
          />
        )}
      </div>
    </div>
  );
};

export default Users;
