import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';

import Button from 'shared/components/Button';
import SimpleAccordion from 'shared/components/SimpleAccordion';
import SurveyAccordionBody from './components/SurveyAccordionBody';

import { getError, duplicate } from 'utils/appHelpers';
import { LocalApi } from 'utils/connectors';
import { getQuestionSetTriggerText } from './helpers';

const getFilter = name => {
  return new URL(window.location.href).searchParams.get(name);
};

/*
  1) {domain}/survey/list?workshopID={id}
  example: http://localhost:8090/survey/list?workshopID=d6b35963-f7fe-42d7-a74d-db4f5a1424a7

  2) {domain}/survey/list?caseID={id}
  example: http://localhost:8090/survey/list?caseID=RSNA2019_3DQ_02

  3) {domain}/survey/list?setCaseCombos={questionSetID}|{caseID},{questionSetID}|{caseID}|1/0
  example: http://localhost:8090/survey/list?setCaseCombos=43d37dcf-aa71-4860-b38f-540da8acafe2|RSNA2019_3DQ_09|1,d521d5de-d709-4ac6-ac3c-fabf290ae9ee|RSNA2019_3DQ_04|0
*/

const staticQSetCaseIds = [];

const getFilteredList = data => {
  const list = duplicate(data);
  const workshopID = getFilter('workshopID');
  const caseID = getFilter('caseID');
  const setCaseCombos = getFilter('setCaseCombos')?.split(',');
  const mockResults = [];

  if (workshopID) {
    return list.map(survay => {
      survay.questionSets = survay.questionSets.filter(set => set.uniqueId === workshopID);
      return survay;
    });
  }

  if (caseID) {
    return list.filter(survay => {
      survay.questionSets = survay.questionSets.filter(set => {
        set.cases = set.cases.filter(cas => cas.caseId === caseID);
        return set.cases.length ? set : false;
      });
      return survay.questionSets.length ? survay : false;
    });
  }

  if (setCaseCombos?.length) {
    let result = [];
    setCaseCombos.forEach((combo, idx) => {
      const [setID, caseID, status] = combo.split('|');
      staticQSetCaseIds.push(setID + '_' + caseID);
      let tempRes = {
        uniqueId: combo + idx,
        questionSets: [
          {
            isMockSet: true,
            uniqueId: setID,
            cases: [
              {
                caseId: caseID,
              },
            ],
          },
        ],
      };
      mockResults.push(tempRes);
      const survays = [...duplicate(list)].filter(survay => {
        survay.questionSets = survay.questionSets.filter(set => {
          if (set.uniqueId !== setID) return false;
          set.isIncompleted = status === '0';
          set.cases = set.cases.filter(cas => cas.caseId === caseID);
          return set.cases.length ? set : false;
        });
        return survay.questionSets.length ? survay : false;
      });
      result = [...result, ...survays];
    });
    return [...result, ...mockResults];
  }

  return [...list, ...mockResults];
};

const SurveyList = () => {
  const { enqueueSnackbar } = useSnackbar();
  const isAdmin = window.location.hostname === 'localhost';

  const [expandedQuestionCaseIds, setExpandedQuestionCaseIds] = useState([]);
  const [surveyList, setSurveyList] = useState([]);
  const [questionSetIds, setQuestionSetIds] = useState([]);

  const haveAllAccess =
    surveyList?.length &&
    surveyList.every(({ questionSets }) =>
      questionSets.every(({ cases }) => cases.every(aCase => aCase.questionSetResultsAccessible)),
    );

  const handleChangeAccordion = id => {
    if (expandedQuestionCaseIds.includes(id)) {
      setExpandedQuestionCaseIds(expandedQuestionCaseIds.filter(currentId => currentId !== id));
    } else {
      setExpandedQuestionCaseIds(prev => [...prev, id]);
    }
  };

  const getSurveys = async () => {
    try {
      const { data } = await LocalApi.get(`/survey/answers`);
      setSurveyList(data);
      let questionSetCaseIds = [];
      const currentQSetIds = [];
      data.forEach(survey => {
        survey.questionSets.forEach(qSet => {
          currentQSetIds.push(qSet?.uniqueId);
          qSet.cases.forEach(cs => questionSetCaseIds.push(qSet.uniqueId + '_' + cs.caseId));
        });
      });
      setQuestionSetIds(currentQSetIds);
      setExpandedQuestionCaseIds([...questionSetCaseIds, ...staticQSetCaseIds]);
    } catch (err) {
      enqueueSnackbar(getError(err) || err.message, { variant: 'error' });
    }
  };

  const handleGiveOrCloseAccess = async () => {
    try {
      await LocalApi.post(`/survey/accessibility`, {
        surveyUniqueIds: surveyList.map(survey => survey.uniqueId),
        questionSetUniqueId: null,
        caseId: null,
        is_accessible: haveAllAccess ? 0 : 1,
      });
      getSurveys();
    } catch (err) {
      enqueueSnackbar(getError(err) || err.message, { variant: 'error' });
    }
  };

  const handleUploadToCloud = async () => {
    try {
      await LocalApi.post('/survey/sync');
      enqueueSnackbar('Successfully Updated', { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(getError(err) || err.message, { variant: 'error' });
    }
  };

  useEffect(() => {
    getSurveys();
  }, []);

  const filteredList = getFilteredList(surveyList);

  return (
    <div className='survey-web-container'>
      {isAdmin && (
        <div className='head-section'>
          <div className='title-part'>
            <h1>Survey Analytics</h1>
            <p>
              Participants’ answers will be updated on a real time basis. Click “Upload to Cloud” to
              make the analytics available for further review. You can also allow participants to
              access the final results by clicking on “Give Access”.
            </p>
          </div>
          <div className='actions-part'>
            <Button onClick={handleGiveOrCloseAccess} buttonType='btn btn-outline-blue'>
              {haveAllAccess ? 'Hide Visibility' : 'Allow Visibility'}
            </Button>
            <Button onClick={handleUploadToCloud} buttonType='btn btn-primary'>
              Upload to Cloud
            </Button>
          </div>
        </div>
      )}
      {filteredList?.length > 0 && (
        <div className='question-sets-container'>
          {filteredList.map((survey, idx) => {
            return (
              <div key={idx}>
                {survey.questionSets.map((questionSet, qSetIdx) => {
                  const actualQSetIdx = questionSetIds.findIndex(
                    setId => setId === questionSet.uniqueId,
                  );
                  return (
                    <div key={qSetIdx}>
                      {questionSet.cases.map((questionCase, idx) => {
                        const qSetCaseIdPair = questionSet.uniqueId + '_' + questionCase.caseId;
                        const isExpanded = expandedQuestionCaseIds.includes(qSetCaseIdPair);
                        return (
                          <SimpleAccordion
                            title={`${questionCase.caseId} - Question Set N${actualQSetIdx + 2} ${
                              !questionSet?.isMockSet
                                ? `(${questionCase?.questions?.length} questions)`
                                : ''
                            }`}
                            headContent={
                              !questionSet?.isMockSet &&
                              `Trigger: ${getQuestionSetTriggerText(
                                questionSet.triggerType,
                                questionSet.triggerValue,
                              )}`
                            }
                            questions={questionCase.questions}
                            expanded={isExpanded}
                            questionSet={questionSet}
                            questionCase={questionCase}
                            surveyUniqueId={survey.uniqueId}
                            handleChange={() => handleChangeAccordion(qSetCaseIdPair)}
                            BodyContent={SurveyAccordionBody}
                            noAccess={!isAdmin && !questionCase.questionSetResultsAccessible}
                            getSurveys={getSurveys}
                            key={idx}
                            isAdmin={isAdmin}
                            isIncompleted={questionSet.isIncompleted}
                            isMockSet={questionSet?.isMockSet}
                          />
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default SurveyList;
