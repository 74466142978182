import React from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

const pdfVersion = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
pdfjs.GlobalWorkerOptions.workerSrc = pdfVersion;

const PDFContent = ({ data }) => {
  const onDocumentLoadSuccess = document => {
    // console.log(document);
  };

  return (
    <div>
      <div className='col p-0'>
        <Document file={data.contentUrl} onLoadSuccess={onDocumentLoadSuccess} id='myElement2'>
          <Page pageNumber={1} id='myElement3' />
        </Document>
      </div>
    </div>
  );
};

export default PDFContent;
