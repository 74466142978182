import React, { useState } from 'react';
import DragList from 'shared/components/DragList';
import DragItem from 'shared/components/DragItem';
import EditingInput from 'shared/components/editingForms/EditingInput';
import EditingSelect from 'shared/components/editingForms/EditingSelect';
import removeIcon from 'assets/bin.svg';
import { reorder } from 'utils/appHelpers';
import SetsBlock from './SetsBlock';
import { SwitchIOSLike } from 'shared/components/SwitchIOSLike';
import RadioBox from 'shared/components/RadioBox';
import UploadImageRow from 'shared/components/UploadImageRow';
import { scanTypes, dicomTypes } from 'configs';
import { useEffect } from 'react';
import { ReactComponent as RemoveIcon } from 'assets/bin.svg';
import { confirmAlert } from 'react-confirm-alert';

const triggerTypes = [
  { name: 'Workshop Completion', value: '0' },
  { name: 'Case Completion', value: '1' },
  { name: 'Step Completion', value: '2' },
];

const questionTypes = [
  { name: 'Text Field', value: 3 },
  { name: 'Rate', value: 4 },
  { name: 'Single Select', value: 1 },
  { name: 'Multi Select', value: 2 },
  { name: 'Image Pointing', value: 5 },
];

const pairTypes = [{ name: 'Block if not required', value: 1 }, { name: 'Use', value: 2 }];
const imageTypes = [{ name: 'Dicom', value: 1 }];
// const imageTypes = [{ name: 'Dicom', value: 1 }, { name: 'Image', value: 2 }];

const emptyAnswer = { answer: '' };

const getEmptyQuestionObj = () => ({
  question: '',
  questionType: 1,
  surveySetQuestonAnswers: [{ ...emptyAnswer }],
  imageType: 1,
});

const getInitialSet = () => ({
  ipaD_Pair: '',
  mandatory: '',
  trigger: '',
  triggerValue: '',
  surveySetQuestons: [getEmptyQuestionObj()],
  imageType: 1,
});

const SurveyQuestionStep = ({ form, setForm, isInUse }) => {
  const [qSets, setQSets] = useState(form.surveySets ? [...form.surveySets] : [getInitialSet()]);
  const [currentSet, setCurrentSet] = useState(0);

  const onAddNewSet = () => {
    const tempSets = [...qSets];
    tempSets.push(getInitialSet());
    setQSets(tempSets);
  };

  const onRemoveSet = () => {
    confirmAlert({
      overlayClassName: 'with-icon',
      title: 'Confirmation Needed',
      message:
        'This will remove current Question Set and all its questions. As a result all events that use this Survey will no longer show this Question Set.\n Be noted that this is permanent operation and can not be recovered later.\n Are you sure want to remove this Question Set?',
      buttons: [
        {
          label: 'Cancel',
        },
        {
          label: 'Remove',
          onClick: () => {
            const tempSets = [...qSets];
            tempSets.splice(currentSet, 1);
            setCurrentSet(0);
            setQSets(tempSets);
          },
        },
      ],
    });
  };

  const handleSettingsChange = e => {
    const { name, value } = e.target;
    const tempSets = [...qSets];
    tempSets[currentSet][name] = value;
    setQSets(tempSets);
  };

  const handleQuestionChange = (index, e) => {
    const { name, value } = e.target;
    const tempSets = [...qSets];
    if (name === 'dicomType' && value === 'i2d') {
      tempSets[currentSet].surveySetQuestons[index].slice = 1;
    }
    tempSets[currentSet].surveySetQuestons[index][name] = value;
    setQSets(tempSets);
  };

  const onRemoveQuestion = index => {
    const tempSets = [...qSets];
    tempSets[currentSet].surveySetQuestons.splice(index, 1);
    setQSets(tempSets);
  };

  const onAddQuestion = () => {
    const tempSets = [...qSets];
    tempSets[currentSet].surveySetQuestons.push({ ...getEmptyQuestionObj() });
    setQSets(tempSets);
  };

  const onChangeQuestionOption = (pIndex, cIndex, e) => {
    const { name, value } = e.target;
    const tempSets = [...qSets];
    tempSets[currentSet].surveySetQuestons[pIndex].surveySetQuestonAnswers[cIndex][name] = value;
    setQSets(tempSets);
  };

  const onAddQuestionOption = index => {
    const tempSets = [...qSets];
    tempSets[currentSet].surveySetQuestons[index].surveySetQuestonAnswers.push({
      ...emptyAnswer,
    });
    setQSets(tempSets);
  };

  const onRemoveQuestionOption = (pIndex, cIndex) => {
    const tempSets = [...qSets];
    tempSets[currentSet].surveySetQuestons[pIndex].surveySetQuestonAnswers.splice(cIndex, 1);
    setQSets(tempSets);
  };

  const onDragEndQuestionOptions = (qIndex, e) => {
    if (!e.destination) return;
    const tempSets = [...qSets];

    tempSets[currentSet].surveySetQuestons[qIndex].surveySetQuestonAnswers = reorder(
      tempSets[currentSet].surveySetQuestons[qIndex].surveySetQuestonAnswers,
      e.source.index,
      e.destination.index,
    );
    setQSets(tempSets);
  };

  const activeSet = qSets[currentSet];

  const checkCaseCompleted = item => {
    if (
      !item.ipaD_Pair ||
      !item.mandatory ||
      !item.trigger ||
      !item.triggerValue ||
      !item.surveySetQuestons?.length
    ) {
      return false;
    }

    const filledQuestions = item.surveySetQuestons.filter(question => {
      const isSelect = Number(question.questionType) === 1 || Number(question.questionType) === 2;
      const isImage = Number(question.questionType) === 5;
      const answerFiltered = question.surveySetQuestonAnswers?.filter(i => i.answer);
      const typeValidation = isSelect
        ? answerFiltered.length === question.surveySetQuestonAnswers?.length
        : isImage
        ? (question.dicomType && question.imageType) || question.imageUrl
        : true;
      return question.question && question.questionType && typeValidation;
    });

    if (filledQuestions.length !== item.surveySetQuestons.length) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    setForm({ ...form, surveySets: qSets });
  }, [qSets]);

  return (
    <div className='px-2 lesson-question-create pb-4'>
      <h6 className='color-blue mb-3'>Survey Questions</h6>
      <SetsBlock
        items={qSets}
        activeItem={currentSet}
        onClickItem={setCurrentSet}
        isCompletedItem={checkCaseCompleted}
        onAddNewItem={onAddNewSet}
      />
      <div className='mb-3 mt-3 col-8 d-flex align-items-center justify-content-between'>
        <h6 className='color-blue mb-0 p-0'>Survey Questions Set {currentSet + 1} Settings</h6>
        <button
          disabled={qSets.length < 2}
          type='button'
          className='btn btn-text-remove d-flex align-items-center p-0'
          onClick={onRemoveSet}
        >
          Remove Question Set <RemoveIcon className='ml-2' width='12' />
        </button>
      </div>
      <div className='col-8 p-0 user-creation-form lesson-question-create course-question-create'>
        <ul className='p-0 mb-4 pb-1'>
          <li className='d-flex align-items-center'>
            <div className='col-4 pl-0'>
              <label className='m-0'>Trigger:</label>
            </div>
            <div className='d-flex w-100'>
              <EditingSelect
                name='trigger'
                onChange={handleSettingsChange}
                value={activeSet.trigger}
                items={triggerTypes}
                required={true}
                editing={true}
                useProperty='value'
                displayProperty='name'
                parentClassName='col mr-2'
                hideDefault={true}
              />
              <EditingInput
                type='text'
                name='triggerValue'
                value={activeSet.triggerValue}
                onChange={handleSettingsChange}
                className='col-5 p-0 min-100 ml-2'
                placeholder='Trigger Value'
                required
                editing={true}
              />
              {activeSet.trigger !== '2' && (
                <span className='mx-2 ml-3 d-flex align-items-center'>%</span>
              )}
            </div>
          </li>
          <li className='d-flex align-items-center'>
            <div className='col-4 pl-0'>
              <label className='m-0'>Mandatory:</label>
            </div>
            <SwitchIOSLike
              className='m-0'
              checkedState={!!activeSet.mandatory}
              handleChange={() =>
                handleSettingsChange({ target: { name: 'mandatory', value: !activeSet.mandatory } })
              }
            />
          </li>
          <li className='d-flex align-items-center'>
            <div className='col-4 pl-0'>
              <label className='m-0'>Pair iPad:</label>
            </div>
            <EditingSelect
              hideDefault={true}
              name='ipaD_Pair'
              onChange={handleSettingsChange}
              value={activeSet.ipaD_Pair}
              items={pairTypes}
              required={true}
              editing={true}
              useProperty='value'
              displayProperty='name'
            />
          </li>
        </ul>
        <h6 className='color-blue mb-4 mt-4'>Survey Questions Set {currentSet + 1} Questions</h6>
        {!!activeSet?.surveySetQuestons &&
          activeSet?.surveySetQuestons.map((item, index) => {
            const isI2dDicom = item.dicomType === 'i2d';
            const isLast = index + 1 === activeSet?.surveySetQuestons.length;
            const disableQuestion = index === 0 && activeSet?.surveySetQuestons.length < 2;
            const hasOptions = Number(item.questionType) === 1 || Number(item.questionType) === 2;
            const isImagePoint = Number(item.questionType) === 5;
            const options = item.surveySetQuestonAnswers;
            return (
              <React.Fragment key={index}>
                <ul className='p-0 mb-0 mb-2 mt-4'>
                  <div className='d-flex drag-item'>
                    <li className='d-flex align-items-center w-100'>
                      <div className='col-3 pl-0'>
                        <label className='m-0'>Question {index + 1}:</label>
                      </div>
                      <EditingInput
                        type='text'
                        name='question'
                        value={item.question}
                        onChange={handleQuestionChange.bind(null, index)}
                        className='p-0'
                        placeholder='Enter Question'
                        editing={!isInUse}
                      />
                    </li>
                    <button
                      onClick={onRemoveQuestion.bind(null, index)}
                      disabled={disableQuestion || isInUse}
                      type='button'
                      className='btn btn-remove ml-2'
                    >
                      <img src={removeIcon} alt='icon' />
                    </button>
                  </div>
                  <li className='d-flex align-items-center w-100'>
                    <div className='col-3 pl-0'>
                      <label className='m-0'>Answer {index + 1} Type:</label>
                    </div>
                    <EditingSelect
                      name='questionType'
                      onChange={handleQuestionChange.bind(null, index)}
                      value={item.questionType}
                      items={questionTypes}
                      useProperty='value'
                      displayProperty='name'
                      editing={!isInUse}
                      hideDefault={true}
                    />
                  </li>
                  {hasOptions && (
                    <DragList
                      items={options}
                      onDragEnd={onDragEndQuestionOptions.bind(null, index)}
                      rowClassName='mb-2'
                    >
                      {(row, aIndex) => {
                        return (
                          <DragItem
                            onItemRemoveDisabled={options.length < 2 || isInUse}
                            disabled={isInUse}
                            onItemRemove={() => onRemoveQuestionOption(index, aIndex)}
                            className='drag-is-course-feedback'
                          >
                            <li className='d-flex align-items-center mb-0 w-100'>
                              <div className='col-3 pl-0'>
                                <label className='m-0'>Answer Variant {aIndex + 1}*:</label>
                              </div>
                              <EditingInput
                                type='text'
                                name='answer'
                                value={row.answer}
                                onChange={onChangeQuestionOption.bind(null, index, aIndex)}
                                className='p-0'
                                placeholder='Enter Answer'
                                editing={!isInUse}
                              />
                            </li>
                          </DragItem>
                        );
                      }}
                    </DragList>
                  )}
                  {isImagePoint && (
                    <>
                      <li className='d-flex align-items-center hide-focus'>
                        <div className='col-3 pl-0'>
                          <label className='m-0'>Image Type:</label>
                        </div>
                        <div className='d-flex align-items-center form-radio-circle'>
                          {imageTypes.map((type, i) => {
                            return (
                              <RadioBox
                                className='mr-4'
                                name={type.name}
                                key={i}
                                label={type.name}
                                checked={Number(type.value) === Number(item.imageType)}
                                classNameLabel={'mb-0 fw-400'}
                                onChange={() =>
                                  handleQuestionChange(index, {
                                    target: { name: 'imageType', value: type.value },
                                  })
                                }
                              />
                            );
                          })}
                        </div>
                      </li>
                      {Number(item.imageType) === 1 && (
                        <li className='d-flex align-items-center'>
                          <div className='col-3 pl-0'>
                            <label className='m-0'>Dicom:</label>
                          </div>
                          <div className='d-flex w-100'>
                            <EditingSelect
                              name='frameType'
                              onChange={handleQuestionChange.bind(null, index)}
                              value={item.frameType}
                              items={scanTypes}
                              required={true}
                              editing={true}
                              useProperty='id'
                              displayProperty='name'
                              parentClassName='col mr-2'
                            />
                            <EditingSelect
                              name='dicomType'
                              onChange={handleQuestionChange.bind(null, index)}
                              value={item.dicomType}
                              items={dicomTypes}
                              required={true}
                              editing={true}
                              useProperty='id'
                              displayProperty='name'
                              parentClassName='col mr-2'
                            />
                            <div className='slice-input-container'>
                              <EditingInput
                                type='number'
                                name='slice'
                                value={item.slice}
                                onChange={handleQuestionChange.bind(null, index)}
                                className='slice-input p-0'
                                placeholder='Enter a Slice'
                                required={true}
                                editing={true}
                                disabled={isI2dDicom}
                              />
                            </div>
                          </div>
                        </li>
                      )}
                      {Number(item.imageType) === 2 && (
                        <div>
                          <UploadImageRow
                            title='Image:'
                            image={item.imageUrl}
                            name={'imageUrl'}
                            onSuccessUpload={img =>
                              handleQuestionChange(index, {
                                target: { name: 'imageUrl', value: img.url },
                              })
                            }
                          />
                        </div>
                      )}
                    </>
                  )}
                </ul>
                <div
                  className={`d-flex justify-content-${
                    hasOptions ? 'between' : 'end'
                  } text-right add-new-items-btns`}
                >
                  {hasOptions && (
                    <button
                      disabled={isInUse}
                      type='button'
                      className='btn btn-blue'
                      onClick={onAddQuestionOption.bind(null, index)}
                    >
                      Add Variant +
                    </button>
                  )}
                  {isLast && (
                    <button
                      type='button'
                      disabled={isInUse}
                      className='btn btn-outline-blue'
                      onClick={onAddQuestion}
                    >
                      Add Question +
                    </button>
                  )}
                </div>
              </React.Fragment>
            );
          })}
      </div>
    </div>
  );
};

export default SurveyQuestionStep;
