/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import ResponsiveImage from 'shared/components/ResponsiveImage';
import { getImageUrl } from 'utils/appHelpers';

const Author = ({
  data: { imageUrl = '', name = '', surname = '', profession = '', info = '' },
}) => (
  <div className='p-4'>
    <div className='author'>
      <div className='author-img'>
        <ResponsiveImage imgUrl={getImageUrl(imageUrl)} className='bg-light' />
      </div>
      <p className='mt-2 mb-0 weight-900 text-clip'>
        {name} {surname}
      </p>
      <p className='small text-secondary mb-0'>{profession}</p>
    </div>
  </div>
);

export default Author;
