import React from 'react';

const CourseStatistics = () => {
  return (
    <div>
      <div className='users-header d-flex justify-content-between align-items-center'>
        <div className='d-flex breadcrumbs'>
          <span>Course Statistics Page</span>
        </div>
      </div>
    </div>
  );
};

export default CourseStatistics;
