import React from 'react';
import EditingInput from 'shared/components/editingForms/EditingInput';
import EditingSelect from 'shared/components/editingForms/EditingSelect';
import removeIcon from 'assets/bin.svg';
import { reorder } from 'utils/appHelpers';
import DragList from 'shared/components/DragList';
import DragItem from 'shared/components/DragItem';

const questionTypes = [
  {
    name: 'Checkbox',
    value: 'checkbox',
  },
  {
    name: 'DropDown',
    value: 'radio',
  },
  {
    name: 'Rate',
    value: 'rating',
  },
  {
    name: 'Text Field',
    value: 'text',
  },
];

const getEmptyQuestionObj = () => ({
  question: '',
  feedbackType: 'checkbox',
  labels: [''],
});

const CourseFeedBackStep = ({ data, setData, isInUse, isRequired }) => {
  const questions =
    data.feedbacks && data.feedbacks.length ? data.feedbacks : [getEmptyQuestionObj()];

  const setQuestions = feedbacks => {
    setData({ ...data, feedbacks });
  };

  const onAddQuestion = () => {
    setQuestions([...questions, ...[getEmptyQuestionObj()]]);
  };

  const onChangeHandler = (index, { target }) => {
    const { name, value } = target;
    const tempData = [...questions];
    tempData[index][name] = value;
    setQuestions(tempData);
  };

  const onRemoveQuestion = index => {
    const tempData = [...questions];
    tempData.splice(index, 1);
    setQuestions(tempData);
  };

  const onChangeTextLabel = (index, { target }) => {
    const tempData = [...questions];
    tempData[index].labels = [target.value];
    setQuestions(tempData);
  };

  const onRadioLabelDragEnd = (index, e) => {
    if (!e.destination) return;
    const tempData = [...questions];
    tempData[index].labels = reorder(tempData[index].labels, e.source.index, e.destination.index);
    setQuestions(tempData);
  };

  const onRadioLabelRemove = (index, aIndex) => {
    const tempData = [...questions];
    tempData[index].labels.splice(aIndex, 1);
    setQuestions(tempData);
  };

  const onRadioLabelChange = (index, aIndex, { target }) => {
    const tempData = [...questions];
    tempData[index].labels[aIndex] = target.value;
    setQuestions(tempData);
  };

  const onAddRadioLabel = index => {
    const tempData = [...questions];
    tempData[index].labels.push('');
    setQuestions(tempData);
  };

  return (
    <div className='px-2 lesson-question-create course-question-create'>
      <h6 className='color-blue mb-3'>Course Feedback Settings</h6>
      <div className='col-8 p-0 user-creation-form mb-3'>
        {!!questions &&
          !!questions.length &&
          questions.map((item, index) => {
            const isLast = index + 1 === questions.length;
            const disableQuestion = index === 0 && questions.length < 2;
            const isCheckBox = item.feedbackType === 'checkbox';
            const isText = item.feedbackType === 'text';
            const isRadio = item.feedbackType === 'radio';
            const showTextField = isCheckBox || isText;
            return (
              <React.Fragment key={index}>
                <ul className='p-0 mb-0 mb-2 mt-4'>
                  <div className='d-flex drag-item'>
                    <li className='d-flex align-items-center w-100'>
                      <div className='col-3 pl-0'>
                        <label className='m-0'>Question {index + 1}*:</label>
                      </div>
                      <EditingInput
                        type='text'
                        name='question'
                        value={item.question}
                        onChange={onChangeHandler.bind(null, index)}
                        className='p-0'
                        placeholder='Enter Question'
                        required={isRequired}
                        editing={!isInUse}
                      />
                    </li>
                    <button
                      onClick={onRemoveQuestion.bind(null, index)}
                      disabled={disableQuestion || isInUse}
                      type='button'
                      className='btn btn-remove ml-2'
                    >
                      <img src={removeIcon} alt='icon' />
                    </button>
                  </div>
                  <li className='d-flex align-items-center w-100'>
                    <div className='col-3 pl-0'>
                      <label className='m-0'>Answer Type*:</label>
                    </div>
                    <EditingSelect
                      name='feedbackType'
                      onChange={onChangeHandler.bind(null, index)}
                      value={item.feedbackType}
                      items={questionTypes}
                      useProperty='value'
                      displayProperty='name'
                      required={isRequired}
                      editing={!isInUse}
                      hideDefault={true}
                    />
                  </li>
                  {showTextField && (
                    <li className='d-flex align-items-center w-100'>
                      <div className='col-3 pl-0'>
                        <label className='m-0'>{isText ? 'Placeholder' : 'Info'}:</label>
                      </div>
                      <EditingInput
                        type='text'
                        value={item.labels[0]}
                        onChange={onChangeTextLabel.bind(null, index)}
                        className='p-0'
                        placeholder={`Enter ${isText ? 'Placeholder' : 'Info'}`}
                        editing={!isInUse}
                      />
                    </li>
                  )}
                  {isRadio && (
                    <DragList
                      items={item.labels}
                      onDragEnd={onRadioLabelDragEnd.bind(null, index)}
                      rowClassName='mb-2'
                    >
                      {(row, aIndex) => {
                        return (
                          <DragItem
                            onItemRemoveDisabled={item.labels.length < 2 || isInUse}
                            disabled={isInUse}
                            onItemRemove={() => onRadioLabelRemove(index, aIndex)}
                            className='drag-is-course-feedback'
                          >
                            <li className='d-flex align-items-center mb-0 w-100'>
                              <div className='col-3 pl-0'>
                                <label className='m-0'>Answer Variant {aIndex + 1}*:</label>
                              </div>
                              <EditingInput
                                type='text'
                                value={row}
                                onChange={onRadioLabelChange.bind(null, index, aIndex)}
                                className='p-0'
                                placeholder='Enter Answer'
                                required={isRequired}
                                editing={!isInUse}
                              />
                            </li>
                          </DragItem>
                        );
                      }}
                    </DragList>
                  )}
                </ul>
                <div
                  className={`d-flex justify-content-${
                    isRadio ? 'between' : 'end'
                  } text-right add-new-items-btns`}
                >
                  {isRadio && (
                    <button
                      disabled={isInUse}
                      type='button'
                      className='btn btn-blue'
                      onClick={onAddRadioLabel.bind(null, index)}
                    >
                      Add Variant +
                    </button>
                  )}
                  {isLast && (
                    <button
                      type='button'
                      disabled={isInUse}
                      className='btn btn-outline-blue'
                      onClick={onAddQuestion}
                    >
                      Add Question +
                    </button>
                  )}
                </div>
              </React.Fragment>
            );
          })}
        {(!questions || !questions.length) && <div>No Feedback is configured for this course</div>}
      </div>
    </div>
  );
};

export default CourseFeedBackStep;
