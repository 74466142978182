import React from 'react';
import { Link } from 'react-router-dom';
import { IconSearch } from 'shared/components/Icons';
import { hasAccess } from 'utils/permissionHelper';

const RolesHeader = ({ onSearch }) => {
  return (
    <div className='users-header d-flex justify-content-between align-items-center'>
      <div className='d-flex'>
        {hasAccess('admins_roles_create') && (
          <Link className='btn btn-invite text-nowrap' to='/roles/new'>
            + Add New Role
          </Link>
        )}
      </div>
      <div className='d-flex'>
        <div className='search-block'>
          <input
            type='text'
            onChange={({ target }) => onSearch(target.value)}
            className='form-control'
            placeholder='Name / Description'
          />
          <IconSearch />
        </div>
      </div>
    </div>
  );
};

export default RolesHeader;
