import React from 'react';
import { Link } from 'react-router-dom';
import { IconSearch } from 'shared/components/Icons';
import { hasAccess } from 'utils/permissionHelper';

const Header = ({ onSearch, search }) => {
  return (
    <div className='users-header d-flex justify-content-between align-items-center'>
      <div className='d-flex'>
        {hasAccess('survey_create') && (
          <Link className='btn btn-invite' to='/survey/new'>
            + Create New Survey
          </Link>
        )}
      </div>
      <div className='d-flex'>
        <div className='search-block'>
          <input
            value={search}
            type='text'
            className='form-control'
            placeholder='Search ...'
            onChange={({ target }) => onSearch(target.value)}
          />
          <IconSearch />
        </div>
      </div>
    </div>
  );
};

export default Header;
