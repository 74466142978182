import React from 'react';
import { useSnackbar } from 'notistack';
import EditingInput from 'shared/components/editingForms/EditingInput';
import EditingTextArea from 'shared/components/editingForms/EditingTextArea';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import EditingSelect from 'shared/components/editingForms/EditingSelect';
import EditingCheckBox from 'shared/components/editingForms/EditingCheckBox';
import attachIcon from 'assets/attach.svg';

const subscriptionTypes = [
  { value: 1, name: 'Educational' },
  { value: 4, name: 'Event Container' },
  { value: 5, name: 'Marketing' },
  { value: 6, name: 'Product' },
];

const SubsGeneralStep = ({ form, setForm, data, setData }) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = ({ target }) => {
    const { name, value, checked, type } = target;
    let val;

    if (type === 'checkbox') {
      val = checked ? 1 : 0;
    } else {
      val = /^\d+$/.test(value) ? Number(value) : value;
    }

    setForm({ ...form, [name]: val });
  };

  const onImageUpload = async e => {
    try {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append('file', file);
      formData.append('isdraft', 'true');
      const res = await Api.post('/upload', formData);
      setData({ ...data, subsImage: res.data.data });
      setForm({ ...form, uploadImageId: res.data.data.id });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const lessonImg = data.subsImage && data.subsImage.url;

  return (
    <div className='px-2'>
      <h6 className='color-blue mb-3'>Subscription Plan General Settings</h6>
      <div className='col-8 p-0 user-creation-form'>
        <ul className='p-0 mb-0'>
          <li className='d-flex align-items-center'>
            <div className='col-4 pl-0'>
              <label className='m-0'>Subscription Plan Title*:</label>
            </div>
            <EditingInput
              type='text'
              name='title'
              value={form.title}
              onChange={handleChange}
              className='p-0'
              placeholder='Enter Name'
              required
              editing={true}
              maxLength='180'
            />
          </li>
          <li className='d-flex'>
            <div className='col-4 pl-0 pt-1'>
              <label className='m-0'>Subscription Plan Description*:</label>
            </div>
            <EditingTextArea
              type='text'
              name='description'
              value={form.description}
              onChange={handleChange}
              className='p-0 is-large'
              placeholder='Enter Description'
              required
              editing={true}
              access={true}
              maxLength='1500'
            />
          </li>
          <li className='d-flex align-items-center'>
            <div className='col-4 pl-0'>
              <label className='m-0'>Plan Image:</label>
            </div>
            <div className='upload-file-form w-100 overflow-hidden'>
              <input
                id='file'
                name='file'
                type='file'
                className='flex-fill hide'
                onChange={onImageUpload}
              />
              <label
                htmlFor='file'
                className={`d-flex justify-content-between align-items-center text-nowrap ${
                  lessonImg ? 'bg-transparent pl-0' : ''
                }`}
              >
                {lessonImg && <img height='40' className='mr-2' src={lessonImg} alt='img' />}
                {!lessonImg && 'Upload Image'}
                <div>
                  {lessonImg && 'Change Image'}
                  <img src={attachIcon} className='ml-2' alt='icon' />
                </div>
              </label>
            </div>
          </li>
          <li className='d-flex align-items-center'>
            <div className='col-4 pl-0'>
              <label className='m-0'>Plan Type*:</label>
            </div>
            <EditingSelect
              name='subscriptionType'
              onChange={handleChange}
              value={form.subscriptionType}
              items={subscriptionTypes}
              useProperty='value'
              displayProperty='name'
              required={true}
              editing={true}
              hideDefault={true}
            />
          </li>
          <li className='d-flex align-items-center'>
            <div className='col-4 pl-0'>
              <label className='m-0'>Expiring:</label>
            </div>
            <EditingCheckBox
              name='expirable'
              value={form.expirable}
              onChange={handleChange}
              className='p-0'
              checked={form.expirable}
              editing={true}
            />
          </li>
          <li className='d-flex align-items-center'>
            <div className='col-4 pl-0'>
              <label className='m-0'>Key Required:</label>
            </div>
            <EditingCheckBox
              name='keyRequired'
              value={form.keyRequired}
              onChange={handleChange}
              className='p-0'
              checked={Number(form.subscriptionType) !== 1 ? 0 : form.keyRequired}
              editing={true}
              disabled={Number(form.subscriptionType) !== 1}
            />
          </li>
          <li className='d-flex align-items-center'>
            <div className='col-4 pl-0'>
              <label className='m-0'>Validity Days*:</label>
            </div>
            <EditingInput
              type='number'
              name='courseDuration'
              value={!form.expirable ? '' : form.courseDuration}
              onChange={handleChange}
              className={`${!form.expirable ? 'opc-3' : ''} p-0`}
              placeholder={form.expirable ? `Enter number` : ''}
              required={form.expirable}
              editing={true}
              disabled={!form.expirable}
              access={true}
            />
            <span
              className={`font-weight-light min-50 text-right ${!form.expirable ? 'opc-3' : ''}`}
            >
              Days
            </span>
          </li>
          <li className='d-flex align-items-center'>
            <div className='col-4 pl-0'>
              <label className='m-0'>Plan Duration*:</label>
            </div>
            <EditingInput
              type='number'
              name='planDuration'
              value={form.planDuration}
              onChange={handleChange}
              className='p-0'
              placeholder='Enter number'
              required
              editing={true}
              access={true}
            />
            <span className='font-weight-light min-50 text-right'>Hours</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SubsGeneralStep;
