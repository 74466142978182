import React, { useEffect, useRef, useState } from 'react';
import Loading from 'shared/components/Loading';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import { useSnackbar } from 'notistack';
import { hangingProtocolToStepsStructure, tools } from './configs';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';

import ControlArea from './components/ControlArea';
import StepsArea from './components/StepsArea';
import HelpModal from './components/HelpModal';
import CanvasView from './components/CanvasView';
import { generateViewerData } from './utils';

const ProtocolViewer = ({ protocol, cases }) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const viewerOptions = useSelector(state => state.viewerOptions);
  const [fetch, setFetch] = useState();
  const [activeCase, setActiveCase] = useState(0);
  const [activeStep, setActiveStep] = useState(0);
  const [course, setCourse] = useState();

  const [activeTool, setActiveTool] = useState({});
  const [fullScreen, setFullScreen] = useState(false);
  const [openHelpModal, setOpenHelpModal] = useState(false);
  const steps = useRef([...hangingProtocolToStepsStructure(protocol)]);

  const lessonCases = cases;

  const fullScreenChange = () => {
    document.addEventListener('fullscreenchange', e => {
      setFullScreen(!!document.fullscreenElement);
    });
  };

  const handelActiveToolChange = tool => {
    setActiveTool(tool);
  };

  const stepChangeHandler = async step => {
    setActiveStep(step);
  };

  const getCases = async () => {
    try {
      if (!lessonCases || !lessonCases.length) return;
      const { caseId, caseUniqueId } = lessonCases[activeCase];
      const endpoint = `/cases/getcasebyid/${caseUniqueId || caseId}?lut=1&dicomType=all`;
      const { data } = await Api.get(endpoint);
      const cases = generateViewerData(data.data, {});
      setCourse({ title: protocol.name, protocol, cases });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onCaseChange = async value => {
    try {
      if (!course.cases[value]) {
        setFetch(true);
        const { caseId, caseUniqueId } = lessonCases[value];
        const endpoint = `/cases/getcasebyid/${caseUniqueId || caseId}?lut=1&dicomType=all`;
        const { data } = await Api.get(endpoint);
        const caseData = generateViewerData(data.data, {});
        const cases = [...course.cases, ...caseData];
        setCourse({ ...course, cases });
        setActiveCase(value);
        setFetch(false);
      } else {
        setActiveCase(value);
      }
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  useEffect(() => {
    fullScreenChange();
    getCases();
    //eslint-disable-next-line
  }, []);

  if (!course || fetch) return <Loading className='mt-5' />;

  const selectedCase = lessonCases[activeCase];
  const caseViews = course.cases[activeCase].views;
  const stepViews = steps.current[activeStep].views;
  const stepViewsData = Object.values(stepViews);
  const viewsCount = Object.values(stepViews).length;

  return (
    <div className='viewer d-flex flex-column flex-fill'>
      <ControlArea
        title={`Case: ${selectedCase.patientId}`}
        fullScreen={fullScreen}
        activeTool={activeTool}
        setActiveTool={handelActiveToolChange}
        viewerOptions={viewerOptions}
        tools={tools}
      />
      <div
        className={`viewer-area has-steps-and-header d-flex ${fullScreen ? 'fullscreen' : ''}`}
        key={`${activeCase}_${activeStep}`}
      >
        {stepViewsData.map((item, index) => (
          <CanvasView
            key={item.key + index}
            dataTomo={caseViews[`${item.key}_TOMO`]}
            data2D={caseViews[`${item.key}_2D`]}
            data3DQ={caseViews[`${item.key}_3DQ`]}
            step={item}
            activeTool={activeTool}
            setActiveTool={handelActiveToolChange}
            fullScreen={fullScreen}
            viewsCount={viewsCount}
            viewerOptions={viewerOptions}
          />
        ))}
      </div>
      <StepsArea
        cases={lessonCases}
        steps={steps.current}
        activeCase={activeCase}
        changeActiveCase={onCaseChange}
        activeStep={activeStep}
        changeActiveStep={stepChangeHandler}
        setActiveStep={setActiveStep}
        onHelpModalOpen={setOpenHelpModal}
        history={history}
      />
      {openHelpModal && <HelpModal onModalClose={() => setOpenHelpModal(false)} />}
    </div>
  );
};

export default ProtocolViewer;
