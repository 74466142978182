import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { tools } from './utils/configs';
import ControlArea from './components/ControlArea';
import './styles/app.scss';
import { CanvasView } from './components/CanvasView';
import BiradsModal from './components/BiradsModal';
import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';

const DICOM_SIDES = {
  R: 'right',
  L: 'left',
};

const DESIGNATOR_SIDE_KEYS = {
  left: 'leftDesignators',
  right: 'rightDesignators',
};

const Viewer = ({
  hasBIRADS,
  sections,
  caseId,
  title,
  dicomData,
  onUpdateGenuine,
  onUpdateSlices,
  patientId,
  onRemoveGaid,
  onGetDicom,
  imageLaterality,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const viewerOptions = useSelector(state => state.viewerOptions);
  const [activeTool, setActiveTool] = useState({});
  const [fullScreen, setFullScreen] = useState(false);
  const [caseScore, setCaseScore] = useState(null);
  const [caseData, setCaseData] = useState(null);

  const dicomLaterality = DICOM_SIDES[imageLaterality];
  const usedDesignatorSideKey = DESIGNATOR_SIDE_KEYS[dicomLaterality];

  const fullScreenChange = () => {
    document.addEventListener('fullscreenchange', e => {
      setFullScreen(!!document.fullscreenElement);
    });
  };

  const getCaseData = async () => {
    try {
      const { data } = await Api.get(`/cases/case/${patientId}`);
      if (data?.data) {
        setCaseScore(data.data.score);
        setCaseData(data.data);
      }
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  useEffect(() => {
    if (patientId) getCaseData();
  }, [patientId]);

  useEffect(() => {
    fullScreenChange();
    //eslint-disable-next-line
  }, []);

  return (
    <div className='viewer d-flex flex-column flex-fill'>
      <SnackbarProvider maxSnack={3}>
        <ControlArea
          caseScore={caseScore}
          setCaseScore={setCaseScore}
          title={title}
          fullScreen={fullScreen}
          activeTool={activeTool}
          setActiveTool={setActiveTool}
          viewerOptions={viewerOptions}
          tools={tools}
          patientId={patientId}
        />
        <div className={`viewer-area d-flex ${fullScreen ? 'fullscreen' : ''}`}>
          {activeTool.type === 'birads' && (
            <BiradsModal hasBIRADS={hasBIRADS} sections={sections} caseId={caseId} />
          )}
          <CanvasView
            outerUsedDesignators={caseData?.[usedDesignatorSideKey]}
            onGetDicom={onGetDicom}
            fullScreen={fullScreen}
            activeTool={activeTool}
            setActiveTool={setActiveTool}
            dicomData={dicomData}
            onUpdateGenuine={onUpdateGenuine}
            onRemoveGaid={onRemoveGaid}
            onUpdateSlices={onUpdateSlices}
          />
        </div>
      </SnackbarProvider>
    </div>
  );
};

export default Viewer;
