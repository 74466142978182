import { findDicom } from '../../LessonsNew/utils';

export const stepTypes = [{ name: 'Dicom', value: 'case' }, { name: 'Image', value: 'image' }];

export const getEmptyFrame = () => ({
  dicomId: null,
  imageId: null,
});

export const getEmptyStepObj = () => ({
  image: '',
  stepType: 'case',
  filename: '',
  title: '',
  leftFrame: '',
  rightFrame: '',
  caseLeftFrame: { ...getEmptyFrame() },
  caseRightFrame: { ...getEmptyFrame() },
});

export const getEmptyDescObj = () => ({
  title: '',
  descriptions: '',
});

export const getEmptyAnswerObj = () => ({
  sections: [{ ...getEmptyDescObj() }],
  steps: [{ ...getEmptyStepObj() }],
});

export const setInitialConfigs = (steps, dicoms) => {
  steps.forEach(step => {
    if (step.caseLeftFrame && step.caseLeftFrame.dicomId) {
      const dicom = dicoms.find(i => i.id === step.caseLeftFrame.dicomId);
      if (!dicom) return;
      let sliceIndex;
      if (dicom.dicomImages) {
        sliceIndex = dicom.dicomImages.findIndex(i => i.id === step.caseLeftFrame.imageId);
      }
      step.caseLeftFrame = {
        ...step.caseLeftFrame,
        imageType: dicom.imageType,
        imageLaterality: dicom.imageLaterality,
        viewPosition: dicom.viewPosition,
        slice: sliceIndex + 1,
      };
    }
    if (step.caseRightFrame && step.caseRightFrame.dicomId) {
      const dicom = dicoms.find(i => i.id === step.caseRightFrame.dicomId);
      if (!dicom) return;
      let sliceIndex;
      if (dicom.dicomImages) {
        sliceIndex = dicom.dicomImages.findIndex(i => i.id === step.caseRightFrame.imageId);
      }
      step.caseRightFrame = {
        ...step.caseRightFrame,
        imageType: dicom.imageType,
        imageLaterality: dicom.imageLaterality,
        viewPosition: dicom.viewPosition,
        slice: sliceIndex + 1,
      };
    }
  });
  return steps;
};

export const setDicomIds = (dicoms, form) => {
  if (!dicoms) return {};
  form.steps.forEach(step => {
    const leftForm = step.caseLeftFrame;
    const rightForm = step.caseRightFrame;
    if (leftForm?.imageType && leftForm?.imageLaterality && leftForm?.viewPosition) {
      const dicom = findDicom(dicoms, leftForm);
      if (dicom) {
        leftForm.dicomId = dicom.id;
        if (leftForm.slice) {
          const image = dicom.dicomImages[leftForm.slice - 1];
          if (image) leftForm.imageId = image.id;
        }
      }
    }
    if (rightForm?.imageType && rightForm?.imageLaterality && rightForm?.viewPosition) {
      const dicom = findDicom(dicoms, rightForm);
      if (dicom) {
        rightForm.dicomId = dicom.id;
        if (rightForm.slice) {
          const image = dicom.dicomImages[rightForm.slice - 1];
          if (image) rightForm.imageId = image.id;
        }
      }
    }
  });
  return form;
};
