import React from 'react';

const EditingCheckBox = ({
  name,
  label,
  value,
  onChange,
  className,
  checked,
  editing,
  disabled,
}) => {
  return editing ? (
    <div className={`align-items-center editing-checkbox-item d-flex ${className || ''}`}>
      <input
        type='checkbox'
        id={`radio${name}`}
        name={name}
        onChange={onChange}
        value={value}
        checked={checked}
        disabled={disabled}
      />
      <label htmlFor={`radio${name}`} className={disabled ? 'opc-5' : ''}>
        {label}
      </label>
    </div>
  ) : (
    <span className='result'>{value}</span>
  );
};

export default EditingCheckBox;
