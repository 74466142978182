import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { IconSearch } from 'shared/components/Icons';
import { hasAccess } from 'utils/permissionHelper';
import BackButton from 'shared/components/BackButton';

const Header = ({ history, onSearch, entityId }) => {
  return (
    <div className='users-header d-flex justify-content-between align-items-center'>
      <div className='d-flex align-items-center'>
        <BackButton history={history} route={`/entity/${entityId}`} />
        {hasAccess('entity_user_create') && (
          <Link className='btn btn-invite' to={`/entity/${entityId}/invite`}>
            + Invite New Member
          </Link>
        )}
        <div className='align-items-center d-flex mx-3 filters'>
          <NavLink className='mx-3' to={`/entity/${entityId}/users`} activeClassName='active'>
            Entity Users
          </NavLink>
        </div>
      </div>
      <div className='d-flex'>
        <div className='search-block'>
          <input
            type='text'
            className='form-control'
            onChange={({ target }) => onSearch(target.value)}
          />
          <IconSearch />
        </div>
      </div>
    </div>
  );
};

export default Header;
