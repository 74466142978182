import React from 'react';
import { Link } from 'react-router-dom';
import { IconClose } from 'shared/components/Icons';

const UsedDataModal = ({ onClose, state }) => {
  return (
    <div className='modal-box d-flex flex-column justify-content-between h-auto p-3'>
      <div className='d-flex justify-content-between align-items-center'>
        <p />
        <p className='title text-center mb-0'>
          Used in {state.type === 'events' ? 'Events' : 'Lessons'}
        </p>
        <button onClick={onClose} className='btn p-0 modal-close-btn'>
          <IconClose />
        </button>
      </div>
      <div className='mt-2 max-500-scroll list-wrapper'>
        {state.data.map((item, index) => {
          const link = state.type === 'events' ? `/event/${item.id}` : `/lesson/${item.id}/view`;
          return (
            <Link to={link} className='d-block fw-700 fz-17 text-decoration-none'>
              {item.name}
            </Link>
          );
        })}
      </div>
      <div className='mt-2 d-flex justify-content-end align-items-center'>
        <button className='btn btn-md btn-blue' onClick={onClose}>
          OK
        </button>
      </div>
    </div>
  );
};

export default UsedDataModal;
