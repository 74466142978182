import React from 'react';

import { lessonTypesObj } from 'app/Main/configs';
import { bindDurationMinutes, formatDate } from 'utils/appHelpers';

import { IconVideos } from 'shared/components/Icons';
import ModeStateLabel from 'shared/components/ModeStateLabel';
import EditingCheckBox from 'shared/components/editingForms/EditingCheckBox';

import IconActivePdf from 'assets/wizard/active_pdf.svg';
import ResponsiveImage from 'shared/components/ResponsiveImage';

const InfoIcon = ({ name, value, className }) => (
  <p className={`mr-2 mb-0 ${className || ''}`}>
    {name}: <b className='font-weight-normal'>{value}</b>
  </p>
);

const CourseLessonsRow = ({
  item,
  index,
  onSelectItem,
  activeIds = [],
  checkField = 'lessonId',
  defaultImageUrl,
}) => {
  const isPdf = item.contentType === 2;
  return (
    <div className='d-flex align-items-center course-lessons-row w-100'>
      <div className={`image-container ${!item.imageUrl ? 'no-image' : ''}`}>
        <ResponsiveImage
          imgUrl={item.imageUrl ? item.imageUrl : isPdf ? IconActivePdf : defaultImageUrl}
          alt='img'
        />
      </div>
      <div className='author-name mb-1 w-100'>
        <div className='d-flex justify-content-between'>
          <h5>
            {item.title} <span className='ml-2'>{lessonTypesObj[item.contentType]}</span>
          </h5>
          <ModeStateLabel mode={item.mode} />
        </div>
        <p className='note'>{item.info}</p>
        <div className='d-flex'>
          <InfoIcon
            name='Chapters'
            value={
              <strong className='font-weight-bold'>
                {item.chapters || '-'}
                <IconVideos color='#131F6B' height='11' />
              </strong>
            }
          />
          <InfoIcon name='Date' value={formatDate(item.createdAt) || '-'} />
          <InfoIcon name='Duration' value={bindDurationMinutes(item.duration) || '-'} />
          <InfoIcon name='Nickname' value={item.nickName || '-'} />
          <div className='ml-auto'>
            <EditingCheckBox
              onChange={onSelectItem.bind(null, item)}
              name={`check${index}`}
              checked={activeIds.includes(item[checkField])}
              editing={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseLessonsRow;
