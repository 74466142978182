import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';

import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import { modeStates } from 'app/Main/configs';

import CourseLessonsRow from './CourseLessonsRow';
import { IconSearch } from 'shared/components/Icons';
import { SwitchIOSLike } from 'shared/components/SwitchIOSLike';
import Loading from 'shared/components/Loading';
import { DEFAULT_IMG_URLS } from 'configs';

const getFiteredLessons = (lessons, { showDraft, activeIds, search }) => {
  let tempLessons = [...lessons];
  if (!showDraft) {
    tempLessons = tempLessons.filter(
      lesson => lesson.mode !== modeStates.DRAFT || activeIds.includes(lesson.lessonId),
    );
  }
  if (search) {
    tempLessons = tempLessons.filter(
      lesson =>
        lesson.title.toLowerCase().includes(search.trim()) ||
        lesson.info.toLowerCase().includes(search.trim()),
    );
  }

  tempLessons = tempLessons.sort(
    (a, b) => Number(activeIds.includes(b.lessonId)) - Number(activeIds.includes(a.lessonId)),
  );

  return tempLessons;
};

const CourseLessonsStep = ({ data, setData, course, form }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [showDraft, setShowDraft] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [search, setSearch] = useState('');
  const [activeIds, setActiveIds] = useState(
    data.activeLessons ? data.activeLessons.map(i => i.lessonId) : [],
  );

  const isCoursePublished = course && course.mode === modeStates.PUBLISHED;
  const isMarketingMaterial = form.isMarketingMaterial;

  const params = { isMarketingMaterial: isMarketingMaterial || null };

  const getLessons = async () => {
    try {
      setIsFetching(true);
      const res = await Api.get('/wizard/lessons', { params });
      setData({ ...data, lessons: res.data });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setIsFetching(false);
    }
  };

  const onSelectItem = item => {
    const ids = [...activeIds];
    if (ids.includes(item.lessonId)) {
      ids.splice(ids.indexOf(item.lessonId), 1);
    } else {
      if (form.isMarketingMaterial && activeIds?.length) {
        enqueueSnackbar('Only one lesson can be selected from Marketing Course', {
          variant: 'error',
        });
        return;
      }
      ids.push(item.lessonId);
    }
    setData({ ...data, activeLessons: ids.map(i => ({ lessonId: i })) });
    setActiveIds(ids);
  };

  const handleSwitchDraft = () => {
    if (isCoursePublished || isFetching) return;
    setShowDraft(!showDraft);
  };

  useEffect(() => {
    getLessons();
    //eslint-disable-next-line
  }, []);

  const filteredLessons = getFiteredLessons(data.lessons, { showDraft, activeIds, search });

  return (
    <>
      <div className='px-2'>
        <h6 className='color-blue mb-3'>Add Lessons</h6>
        <p>Choose Lessons that you want to be included in the course</p>
        <div className='col-8 mb-3 p-0'>
          <div className='search-block p-0'>
            <input
              type='text'
              onChange={({ target }) => setSearch(target.value)}
              className='form-control w-100'
              placeholder='Search lesson'
              value={search}
            />
            <IconSearch />
          </div>
        </div>
        <div className='p-2'>
          <SwitchIOSLike
            label='Show Draft Lessons'
            checkedState={showDraft}
            handleChange={handleSwitchDraft}
            disabled={isCoursePublished}
          />
        </div>
        <div className='lessons_container m-content flex-fill col-8 p-0'>
          {!isFetching ? (
            !!filteredLessons?.length &&
            filteredLessons.map((item, i) => {
              return (
                <label
                  key={i}
                  className='case-item lesson-item pointer w-100 d-flex align-items-center justify-content-between p-0'
                  htmlFor={`radiocheck${i}`}
                >
                  <CourseLessonsRow
                    defaultImageUrl={DEFAULT_IMG_URLS.lesson}
                    activeIds={activeIds}
                    item={item}
                    index={i}
                    onSelectItem={onSelectItem}
                  />
                </label>
              );
            })
          ) : (
            <Loading className='mt-5' />
          )}
        </div>
        {!isFetching && data.lessons?.length && (
          <p className='mt-4'>Selected Lessons: {activeIds.length}</p>
        )}
      </div>
    </>
  );
};

export default CourseLessonsStep;
