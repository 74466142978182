import React from 'react';
import attachIcon from 'assets/attach.svg';
import { Api } from 'utils/connectors';
import { useSnackbar } from 'notistack';
import { getError } from 'utils/appHelpers';
import removeIcon from 'assets/bin.svg';
import ResponsiveImage from './ResponsiveImage';

const UploadImageRow = ({
  title,
  image,
  name,
  onSuccessUpload,
  className,
  labelClassName = 'pl-0',
  showRemove,
  removeBanner,
  required,
  hideVisual,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const onImageUpload = async e => {
    try {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append('file', file);
      formData.append('isdraft', 'true');
      const res = await Api.post('/upload', formData);
      onSuccessUpload && onSuccessUpload(res.data.data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  return (
    <li className={`d-flex align-items-center ${className || ''}`}>
      <div className={`col-3 ${labelClassName}`}>
        <label className='m-0'>{title}</label>
      </div>
      <div className='upload-file-form w-100 overflow-hidden'>
        <input
          required={!image && required}
          id={name}
          name={name}
          type='file'
          className={`flex-fill ${hideVisual ? 'hidden_visual' : 'hide'}`}
          onChange={onImageUpload}
        />
        <label
          htmlFor={name}
          className={`d-flex justify-content-between align-items-center text-nowrap ${
            image ? 'bg-transparent pl-0' : ''
          }`}
        >
          <div>
            {image && <ResponsiveImage height='40' className='mr-2' imgUrl={image} alt='img' />}
          </div>
          {!image && 'Upload Image'}
          <div>
            {image && 'Change Image'}
            <img src={attachIcon} className='ml-2' alt='icon' />
          </div>
        </label>
      </div>
      {showRemove && (
        <div>
          <button
            onClick={removeBanner}
            type='button'
            className='btn btn-sm ml-2 lh-10 btn-red list-remove-btn'
          >
            <img width='10' src={removeIcon} alt='icon' />
          </button>
        </div>
      )}
    </li>
  );
};

export default UploadImageRow;
