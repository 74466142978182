/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import { formatDate } from 'utils/appHelpers';
import { lessonModes, lessonTypesObj } from 'app/Main/configs';

import ClickArea from 'shared/components/ClickArea';
import Loading from 'shared/components/Loading';
import ListActionButtons from 'shared/components/ListActionButtons';
import { hasAccess } from 'utils/permissionHelper';

const headers = [
  {
    name: 'Lesson Title',
    className: 'col-2',
    key: 'name',
  },
  {
    name: 'Nickname',
    className: 'col-2',
    key: 'nickname',
  },
  {
    name: 'Type',
    className: 'col-1',
    key: 'contentType',
  },
  {
    name: 'Status',
    className: 'col-1',
    key: 'mode',
  },
  {
    name: 'Used',
    className: 'col-1',
    key: 'used',
  },
  {
    name: 'Section',
    className: 'col-2',
    key: 'episodesCount',
  },
  {
    name: 'Created',
    className: 'col',
    key: 'createdAt',
  },
  {
    name: 'Actions',
    className: 'col',
    key: '',
  },
];

const LessonsLists = ({
  list,
  order,
  changeOrder,
  fetch,
  onItemRemoveConfirm,
  onItemRemoveDisabled,
}) => {
  if (fetch) return <Loading className='mt-5' />;
  if (!list || !list.length)
    return <div className='text-black-50 text-center mt-4'>No Results Found</div>;

  const handleOrderChange = item => {
    if (!item.key) return;
    changeOrder({
      key: item.key,
      order: !order.order,
    });
  };

  const disableOrHideEditActions = isMarketingMaterial => {
    if (isMarketingMaterial && !hasAccess('marketing_lessons_create')) {
      return true;
    }
    if (!isMarketingMaterial && !hasAccess('education_lessons_create')) {
      return true;
    }
    return false;
  };

  const actions = [
    {
      type: 'link',
      icon: 'view',
      title: 'View',
      to: item => `/lesson/${item.id}/view`,
    },
    {
      type: 'link',
      icon: 'edit',
      title: 'Edit',
      disable: item => disableOrHideEditActions(item.isMarketingMaterial),
      to: item => `/lesson/${item.id}/edit`,
    },
    {
      type: 'link',
      icon: 'copyAndCreate',
      title: 'Copy & Create',
      disable: item => disableOrHideEditActions(item.isMarketingMaterial),
      to: () => `/lessons/new`,
    },
    {
      hide: item => item.contentType !== 3,
      type: 'link',
      icon: 'heatmaps',
      title: 'Heatmap',
      to: item => `/lesson/${item.id}/heatmaps`,
    },
    {
      type: 'button',
      icon: 'delete',
      title: 'Remove',
      disabled: onItemRemoveDisabled,
      disable: item => disableOrHideEditActions(item.isMarketingMaterial),
      onClick: item => onItemRemoveConfirm(item),
    },
  ];

  return (
    <div className='users-lists'>
      <ul className='p-0'>
        <li className='d-flex list-header align-items-center'>
          {headers.map((item, index) => (
            <ClickArea
              key={item.name + index}
              className={`d-flex text-body justify-content-between align-items-center ${
                item.className
              }`}
              onClick={handleOrderChange.bind(null, item)}
            >
              {item.name}
              {item.key && order.orderKey === item.key && (
                <button
                  className={`btn ml-2 p-0 dropdown-toggle ${order.order ? '' : 'rotate-180'}`}
                />
              )}
            </ClickArea>
          ))}
        </li>
        {list &&
          list.map(item => {
            const isVideo = item.contentType === 1;
            const isCases = item.contentType === 3;
            return (
              <li key={item.id} className='d-flex list-item h-not-set'>
                <div className='d-flex col-2 align-items-center'>{item.name || '-'}</div>
                <div className='d-flex col-2 align-items-center'>{item.nickname || '-'}</div>
                <div className='d-flex col-1 align-items-center'>
                  {lessonTypesObj[item.contentType]}
                </div>
                <div className='d-flex col-1 align-items-center'>{lessonModes[item.mode]}</div>
                <div className='d-flex col-1 align-items-center'>{item.used || '-'}</div>
                <div className='d-flex col-2 align-items-center'>
                  {isVideo && <span>{item.episodesCount} Episodes</span>}
                  {isCases && <span>{item.caseCount} Cases</span>}
                  {!isVideo && !isCases && 1}
                </div>
                <div className='d-flex col align-items-center'>
                  {formatDate(item.createdAt) || '-'}
                </div>
                <div className='p-2 justify-content-start align-items-center w-100 d-flex action-buttons-container col'>
                  <ListActionButtons actions={actions} item={item} />
                </div>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default LessonsLists;
