import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Link, useParams, useHistory } from 'react-router-dom';
import Button from 'shared/components/Button';
import EntityItem from 'shared/components/EntityItem';
import { IconBin } from 'shared/components/Icons';
import ResponsiveImage from 'shared/components/ResponsiveImage';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import { formatDateWithSlashes } from 'utils/formatHelpers';
import { hasAccess } from 'utils/permissionHelper';

const DetailsBlock = ({
  className,
  title,
  data,
  handleOpenCreateLink,
  hideActions,
  showEntities = null,
}) => {
  const imageUrl = data?.images?.[0]?.imageUrl;
  const productPages = data?.pages || [];
  const [removeFetch, setRemoveFetch] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const history = useHistory();

  const onItemRemove = async () => {
    try {
      setRemoveFetch(true);
      await Api.delete(`/product/product/${params.id}`);
      enqueueSnackbar('Successfully removed', { variant: 'success' });
      history.push('/products');
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setRemoveFetch(false);
    }
  };

  const onItemRemoveConfirm = () => {
    if (data?.registeredUsers > 0) {
      confirmAlert({
        title: 'Can not be deleted',
        message: 'This Product is in use and can not be deleted now.',
        buttons: [{ label: 'OK' }],
      });
      return;
    }

    confirmAlert({
      title: 'Confirmation Required',
      message: 'Are you sure you want to delete this Product?',
      buttons: [
        {
          label: 'Cancel',
        },
        {
          label: 'Delete',
          onClick: () => onItemRemove(),
        },
      ],
    });
  };

  return (
    <div className='details-container'>
      <div className={`tags-view-block right-details-block ${className || ''}`}>
        <h1>{title}</h1>
        <div className='d-flex flex-wrap tag-wrap'>
          <ResponsiveImage alt={title} imgUrl={imageUrl} />
          <h6 className='name'>{data?.name}</h6>
        </div>
        <div className='list-block'>
          <div className='detail-item'>
            <span className='detail-label'>Updated At</span>
            <span className='dotted-row' />
            <span className='detail-value'>
              {formatDateWithSlashes(data?.updatedAt || data?.createdAt) || '-'}
            </span>
          </div>
          <div className='detail-item'>
            <Link to={`/product/${data?.id}/users`} className='detail-label underlined'>
              Registered Users
            </Link>
            <span className='dotted-row' />
            <Link
              to={`/product/${data?.id}/users`}
              className={`${data?.registeredUsers?.length && 'underlined'} detail-value`}
            >
              {data?.registeredUsers || '-'}
            </Link>
          </div>
          <div className='detail-item'>
            <Link to={`/product/${data?.id}/users?completed=1`} className='detail-label underlined'>
              Completed Users
            </Link>
            <span className='dotted-row' />
            <Link
              to={`/product/${data?.id}/users?completed=1`}
              className={`${data?.registeredUsers?.length && 'underlined'} detail-value`}
            >
              {data?.completedUsers || '-'}
            </Link>
          </div>
          <div className='detail-item'>
            <span className='detail-label'>Configured Pages</span>
            <span className='dotted-row' />
            <span className='detail-value'>{data?.pages?.length || '-'}</span>
          </div>
        </div>
        {!!productPages?.length && (
          <div className='details-pages-block'>
            <h1>Product Pages</h1>
            <ol className='pl-3'>
              {productPages.map((page, idx) => (
                <li className='product-page-item' key={idx}>
                  {page.title}
                </li>
              ))}
            </ol>
          </div>
        )}
        {!!data?.entities?.length && showEntities && (
          <>
            <h1>Entities</h1>
            <div className='right-side-entities'>
              {data.entities.map(({ entity }, idx) => (
                <EntityItem key={idx} type='small' entity={entity} />
              ))}
            </div>
          </>
        )}
      </div>
      {!hideActions && (
        <div className='actions-block'>
          <Link
            className={`btn-blue regular-action-btn text-nowrap ${!hasAccess('products_create') &&
              'global_disabled'}`}
            to={`/product/${params.id}/edit`}
          >
            Edit
          </Link>
          <Link
            to={`/product/${params.id}/statistics`}
            className='btn-blue regular-action-btn text-nowrap'
          >
            Statistics
          </Link>
          <Button
            className={`btn-blue regular-action-btn text-nowrap ${!hasAccess('products_create') &&
              'global_disabled'}`}
            onClick={handleOpenCreateLink}
          >
            Configure Link
          </Button>
          <Button
            disabled={!hasAccess('products_create')}
            className={`btn-sm btn-blue text-orange regular-action-btn text-nowrap fz-12 fw-600 d-flex align-items-center justify-content-center ${!hasAccess(
              'products_create',
            ) && 'global_disabled'}`}
            onClick={onItemRemoveConfirm}
          >
            <IconBin className='mr-2' />
            Delete
          </Button>
        </div>
      )}
    </div>
  );
};

export default DetailsBlock;
