/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import { DropDown, DropdownHeader, DropdownBody } from 'shared/components/DropDown';
import RadioBox from 'shared/components/RadioBox';

const FilterDropDown = ({
  title,
  data,
  activeItemId,
  onItemClick,
  className,
  name,
  showSearch,
}) => {
  const [search, setSearch] = useState('');

  const onSearchChange = e => {
    setSearch(e.target.value);
  };

  const filteredData = data.filter(i => i.name?.toLowerCase().includes(search.toLowerCase()));

  const activeItem = activeItemId && filteredData.find(i => i.id === activeItemId);

  return (
    <DropDown
      name={name || title}
      className={`export-drop-down d-flex align-items-center ${
        activeItem ? 'selected' : ''
      } ${className || ''}`}
    >
      <DropdownHeader className='align-items-center d-flex mr-3'>
        <span className='mr-2 d-flex'>{title}</span>
      </DropdownHeader>
      <DropdownBody>
        {showSearch && (
          <div className='filter-search-block dont-close-drop-menu'>
            <input
              type='text'
              placeholder='Search...'
              className='form-control'
              value={search}
              onChange={onSearchChange}
            />
          </div>
        )}
        <ul>
          {filteredData.map(item => (
            <li
              className='drop-item form-radio-circle'
              key={item.id}
              onClick={onItemClick.bind(null, item.id)}
            >
              <RadioBox
                label={item.name}
                onChange={() => {}}
                name={item.name}
                checked={item.id === activeItemId}
              />
            </li>
          ))}
        </ul>
      </DropdownBody>
    </DropDown>
  );
};

export default FilterDropDown;
