/**
 * Scrolls the container with the given id to its horizontal end.
 * @param {string} containerId - The id of the container to scroll.
 */
export const scrollHorizontallyToContainerEnd = containerId => {
  const containerElm = document.getElementById(containerId);
  if (containerElm) {
    requestAnimationFrame(() => {
      containerElm.scrollLeft = containerElm.scrollWidth;
    });
  }
};

export const scrollVerticallyToContainerEnd = containerId => {
  const containerElm = document.getElementById(containerId);
  if (containerElm) {
    requestAnimationFrame(() => {
      containerElm.scrollTop = containerElm.scrollHeight;
    });
  }
};

export function getTextWidth(text, font) {
  const canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement('canvas'));
  const context = canvas.getContext('2d');
  context.font = font;
  const metrics = context.measureText(text);
  return metrics.width;
}
