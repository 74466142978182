import React from 'react';
import { Link } from 'react-router-dom';
import { IconSearch } from 'shared/components/Icons';
import { hasAccess } from 'utils/permissionHelper';

const ProductsHeader = ({ search, onSearch }) => {
  return (
    <div className='users-header d-flex justify-content-between align-items-center'>
      <div className='d-flex'>
        {hasAccess('products_create') && (
          <Link className='btn btn-invite' to='/product/new'>
            Add New Product +
          </Link>
        )}
      </div>
      <div className='d-flex'>
        <div className='search-block'>
          <input
            type='text'
            value={search}
            onChange={({ target }) => onSearch(target.value)}
            className='form-control'
            placeholder='Search by Name'
          />
          <IconSearch />
        </div>
      </div>
    </div>
  );
};

export default ProductsHeader;
