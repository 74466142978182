/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import CourseRow from './CourseRow';
import { DEFAULT_IMG_URLS } from 'configs';

const CourseConfigurationStep = ({ form, setForm, courses }) => {
  const [activeIds, setActiveIds] = useState([...form.activeCourseIds]);

  const onToggleItemRequired = courseId => {
    if (activeIds.includes(courseId)) {
      const updatedActiveIds = activeIds.filter(id => id !== courseId);
      setActiveIds(updatedActiveIds);
      setForm({ ...form, courses: updatedActiveIds, activeCourseIds: updatedActiveIds });
    } else {
      const updatedActiveIds = [...activeIds, courseId];
      setActiveIds(updatedActiveIds);
      setForm({ ...form, courses: updatedActiveIds });
    }
  };

  useEffect(() => {
    setActiveIds([...form.activeCourseIds]);
  }, [courses]);

  return (
    <div className='col-9 px-2'>
      <div className='p-0 user-creation-form'>
        <h6 className='color-blue mb-3 mt-3 pl-4'>Product Page Courses Configuration</h6>
        <div className='courses_container product-courses-container vertical-custom_scrollbar_block thin-scrollbar m-content flex-fill'>
          {!!courses?.length &&
            courses?.map((item, i) => {
              return (
                <label
                  key={i}
                  className={`${activeIds.includes(item.courseId) &&
                    'first_ordered'} case-item lesson-item pointer w-100 d-flex justify-content-between p-0`}
                  htmlFor={`radiocheck${i}`}
                >
                  <CourseRow
                    defaultImageUrl={DEFAULT_IMG_URLS.course}
                    activeIds={activeIds}
                    item={item}
                    index={i}
                    onToggleItemRequired={onToggleItemRequired}
                    checkField='courseId'
                  />
                </label>
              );
            })}
        </div>
      </div>
      <ReactTooltip effect='solid' type='light' multiline={true} backgroundColor='#FFF' />
    </div>
  );
};

export default CourseConfigurationStep;
