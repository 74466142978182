import { courseModes } from 'app/Main/configs';
import React from 'react';
import ClickArea from 'shared/components/ClickArea';

const headers = [
  {
    name: 'Product Page',
    className: 'col-3',
    key: 'name',
  },
  {
    name: 'Status',
    className: 'col',
    key: 'mode',
  },
  {
    name: 'Registered',
    className: 'col',
    key: 'registeredUsers',
  },
  {
    name: 'Completed',
    className: 'col',
    key: 'completedUsers',
  },
];

export const ProductPagesList = ({ list }) => {
  return (
    <div className='users-lists w-100'>
      <ul className='p-0'>
        <li className='d-flex list-header align-items-center'>
          {headers.map((item, index) => (
            <ClickArea
              key={item.name + index}
              className={`d-flex text-body justify-content-between align-items-center ${
                item.className
              }`}
            >
              {item.name}
            </ClickArea>
          ))}
        </li>
        {list &&
          list.map(item => {
            return (
              <li key={item.id} className='d-flex list-item h-not-set'>
                <div className='d-flex col-3 align-items-center justify-content-between'>
                  {item.title}
                </div>
                <div className='col align-items-center d-flex'>{courseModes[item.mode]}</div>
                <div className='col align-items-center d-flex'>
                  {item?.registeredUsers ? `${item?.registeredUsers} Users` : '-'}
                </div>
                <div className='col align-items-center d-flex'>
                  {item?.completedUsers ? `${item?.completedUsers} Users` : '-'}
                </div>
              </li>
            );
          })}
      </ul>
    </div>
  );
};
