import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { Api } from 'utils/connectors';
import ImportForm from './components/ImportForm';
import Button from 'shared/components/Button';
import { getError, saveFile } from 'utils/appHelpers';
import ReportHeader from '../Reports/components/ReportHeader';

const ReportExport = ({ match }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [fetch, setFetch] = useState(false);
  const [type, setType] = useState(match.params.type || '');
  const [success, setSuccess] = useState(null);

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      setFetch(true);
      const { data } = await Api.get('/export/gds', {
        responseType: 'blob',
        timeout: 30000,
        params: {
          data: type,
        },
      });
      setSuccess(true);
      await saveFile(data, `${type}.csv`);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetch(false);
    }
  };

  return (
    <div>
      <ReportHeader />
      <div className='py-3 has-header'>
        <div className='col-12'>
          <h5 className='color-blue mb-3 col-12'>Export File</h5>
          {success === null && (
            <ImportForm fetch={fetch} handleSubmit={handleSubmit} type={type} setType={setType} />
          )}
          {success === true && (
            <div className='px-3 report-success-status'>
              <h4>Successfully Exported</h4>
              <p className='mb-2'>The download of generated file should start soon.</p>
              <Button type='button' className='btn-blue mt-1 px-4' onClick={() => setSuccess(null)}>
                Export Another File
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReportExport;
