import { confirmAlert } from 'react-confirm-alert';

const courseErrors = {
  no_info: '• Course description',
  duration: '• Course duration',
  faculties: '• Course faculties',
  lessons: '• Course lessons',
  feedbacks: '• Course feedbacks',
  certificate: '• Course certificate',
};

export const checkCourseForPublishing = course => {
  const errors = [];
  const feedbacks = course.courseFeedbacks || course.feedbacks;
  if (!course.info && !course.description) errors.push(courseErrors.no_info);
  if (!course.duration) errors.push(courseErrors.duration);
  if (!course.courseAuthors?.length && !course.authors?.length && !course.isMarketingMaterial)
    errors.push(courseErrors.faculties);
  if (!course.courseLessons?.length && !course.lessons?.length) errors.push(courseErrors.lessons);
  if (course.isEvaluationAvailable && !feedbacks?.length) errors.push(courseErrors.feedbacks);
  if (course.isCertificateAvailable && !course.certificateId) errors.push(courseErrors.certificate);
  return {
    isValid: !errors.length,
    errors,
    message: errors.join('\n'),
  };
};

export const showCourseInvalidAlert = message => {
  confirmAlert({
    title:
      'The following fields are missing. Please, fill them in, in order to publish the course.',
    message,
    buttons: [{ label: 'OK' }],
    overlayClassName: 'error-message-alert',
  });
};
