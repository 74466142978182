import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';
import { IconArrowLeft, IconArrowRight } from 'shared/components/Icons';
import RolesHeader from './components/RolesHeader';
import ReactPaginate from 'react-paginate';
import RolesLists from './components/RolesLists';
import useDebounce from 'shared/hooks/useDebounce';
import { confirmAlert } from 'react-confirm-alert';

const AdminsRoles = () => {
  const [removeFetch, setRemoveFetch] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [fetch, setFetch] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [roles, setRoles] = useState();
  const [search, setSearch] = useState();
  const [page, setPage] = useState(0);
  const [reqParams, setReqParams] = useState({
    order: false,
    orderKey: 'id',
  });

  const debouncedSearch = useDebounce(search, 500);

  const getRolesList = async toPage => {
    try {
      setFetch(true);
      const params = {
        ...reqParams,
        page: toPage + 1 || 1,
        limit: 8,
        search: debouncedSearch,
      };
      const { data } = await Api.get('/admin/roles', { params });
      setPageCount(data.data.pageCount);
      setRoles(data.data.results);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetch(false);
    }
  };

  const onPageChange = ({ selected }) => {
    setPage(selected);
    getRolesList(selected);
  };

  const handleSearchChange = text => {
    setSearch(text);
  };

  const onItemRemove = async item => {
    try {
      setRemoveFetch(true);
      await Api.delete(`/admin/role/${item.id}`);
      await getRolesList();
      enqueueSnackbar('Successfully removed', { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setRemoveFetch(false);
    }
  };

  const onItemRemoveConfirm = item => {
    if (item.userRoles?.length > 0 || item.assignedUsers > 0) {
      confirmAlert({
        overlayClassName: 'with-icon mid content_light',
        title: "Can't Delete",
        message:
          'You can’t delete this role as there are admins of this type in the system.\n\nChange the roles of assigned admin and then only delete the role.',
        buttons: [{ label: 'OK' }],
      });
      return;
    }

    confirmAlert({
      overlayClassName: 'with-icon danger content_light',
      title: 'Confirmation Needed',
      message: `Are you sure you want completely delete ${
        item.title
      } role and all its settings from the system?`,
      buttons: [
        {
          label: 'Cancel',
        },
        {
          label: 'Delete',
          onClick: () => onItemRemove(),
        },
      ],
    });
  };

  useEffect(() => {
    getRolesList();
    //eslint-disable-next-line
  }, [debouncedSearch, reqParams]);

  return (
    <div>
      <RolesHeader onSearch={handleSearchChange} />
      <div className='py-3 has-header'>
        <div className='col-12'>
          <RolesLists
            roles={roles}
            order={reqParams}
            changeOrder={setReqParams}
            fetch={fetch}
            onItemRemoveConfirm={onItemRemoveConfirm}
            onItemRemoveDisabled={removeFetch}
          />
        </div>
        {pageCount > 1 && !fetch && (
          <ReactPaginate
            previousLabel={<IconArrowLeft />}
            nextLabel={<IconArrowRight />}
            breakLabel={'...'}
            forcePage={page}
            breakClassName={'break-me'}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={2}
            onPageChange={onPageChange}
            containerClassName={'pagination show-arrow'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
          />
        )}
      </div>
    </div>
  );
};

export default AdminsRoles;
