import React from 'react';
import EditingInput from 'shared/components/editingForms/EditingInput';

const SurveyGeneralStep = ({ form, setForm }) => {
  const handleChange = ({ target }) => {
    const { name, value, checked, type } = target;
    const val = type === 'checkbox' ? (checked ? 1 : 0) : value;
    setForm({ ...form, [name]: val });
  };

  return (
    <div className='px-2'>
      <h6 className='color-blue mb-3'>Survey General Settings</h6>
      <div className='col-8 p-0 user-creation-form'>
        <ul className='p-0 mb-0'>
          <li className='d-flex align-items-center'>
            <div className='col-4 pl-0'>
              <label className='m-0'>Name:</label>
            </div>
            <EditingInput
              type='text'
              name='name'
              value={form.name}
              onChange={handleChange}
              className='p-0'
              placeholder='Enter Name'
              required
              editing={true}
              maxLength='180'
            />
          </li>
          <li className='d-flex align-items-center'>
            <div className='col-4 pl-0'>
              <label className='m-0'>Subject:</label>
            </div>
            <EditingInput
              type='text'
              name='subject'
              value={form.subject}
              onChange={handleChange}
              className='p-0'
              placeholder='Enter Subject'
              required
              editing={true}
              maxLength='180'
            />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SurveyGeneralStep;
