export const allFrontPermissions = [
  'home_view',
  'notification_read',
  'subscriptions_view',
  'subscriptions_join_request_view',
  'subscriptions_list_view',
  'subscription_view',
  'subscription_create',
  'subscription_request_confirm',
  'publish_subscription',
  'courses_statistics_view____hided',
  'courses_create',
  'courses_list_view',
  'course_view',
  'course_publish',
  'users_create',
  'users_edit',
  'users_list_view',
  'user_view',
  'user_progress_view',
  'user_test_history_view',
  'admin_create',
  'admins_list_view',
  'admin_view',
  'admins_roles_view',
  'admins_roles_create',
  'authors_list_view',
  'author_view',
  'author_create',
  'statistics_view____hided',
  'support_tickets_view',
  'system_emails_view',
  'system_spotlights_view',
  'system_spotlights_view_edit',
  'reports_import',
  'reports_export',
  'lessons_create',
  'lessons_list_view',
  'lesson_publish',
  'cases_list_view',
  'cases_create',
  'warehouse_cases_list_view',
  'warehouse_cases_create',
  'license_create',
  'license_list_view',
  'entity_create',
  'entity_list_view',
  'entity_user_create',
  'entity_user_list_view',
  'can_invite_entity_from_learners',
  'certificates_list_view',
  'certificates_create',
  'system_logs_view',
  'system_countries_view',
  'system_countries_create',
  'system_regions_view',
  'system_regions_create',
  'system_emails_edit',
  'events_list_view',
  'event_create',
  'protocol_create',
  'protocols_list_view',
  'devices_list_pairs_view',
  'devices_list_view',
  'device_remove',
  'device_pair',
  'room_360_list',
  'room_360_create',
  'surveys_list_view',
  'survey_create',
  'quiz_view',
  'quiz_create',
  'education_course_view',
  'education_course_create',
  'marketing_course_view',
  'marketing_course_create',
  'education_lessons_view',
  'education_lessons_create',
  'marketing_lessons_view',
  'marketing_lessons_create',
];

export const permissionsWithRolesNames = {
  'Super Admin': {
    add: ['icons_view', 'set_passed_course'],
    remove: [],
  },
  'Entity Manager': {
    add: ['entity_manager'],
    remove: ['entity_list_view'],
  },
  KOL: {
    add: ['kol_user'],
    remove: [],
  },
};
