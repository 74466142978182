/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { IconCheckBold } from 'shared/components/Icons';

const InfoIcon = ({ name, value, className }) => (
  <p className={`m-0 d-flex ${className || ''}`}>
    {name}: <b>{value}</b>
  </p>
);

const LessonCasesBlock = ({ cases, activeCase, onClickCase, isCompletedCase }) => {
  return (
    <div className='cases-block d-flex flex-wrap'>
      {cases &&
        cases.map((item, index) => {
          const isActive = activeCase === item.caseId;
          const isCompleted = isCompletedCase && isCompletedCase(item);
          return (
            <div
              onClick={() => onClickCase(item.caseId)}
              className={`case-block d-flex flex-column justify-content-between ${
                isActive ? 'active' : ''
              }`}
              key={item.caseUniqueId}
            >
              <div className='d-flex justify-content-between align-items-center'>
                <h4 className='mb-0'>Case {index + 1}</h4>
                {isCompleted && <IconCheckBold height='12' width='15' />}
              </div>
              <div className='d-flex justify-content-between'>
                <InfoIcon name='ID' value={item.patientId || '-'} />
                <InfoIcon name='Age' className='ml-2' value={item.patientAge || '-'} />
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default LessonCasesBlock;
