import React from 'react';
import ButtonLoading from 'shared/components/ButtonLoading';
import { hasAccess } from 'utils/permissionHelper';

const getMessageElement = (modeIsDraft, modeIsPublished, modeIsUnPublished, isEditing) => {
  return (
    <>
      {modeIsDraft &&
        (hasAccess('course_publish') ? (
          <p>
            If you are done with configurations then press Save Draft. <br /> Also if you are sure
            about completeness of the course configuration <br /> you can already Save and Publish
            the course. <br /> Press Back to make additional changes.
          </p>
        ) : (
          <p>If you are done with configurations then press Save Draft.</p>
        ))}
      {isEditing && modeIsPublished && (
        <p>
          Please note that changes are also going to be applied to enrolled <br /> users. Depending
          on their progress in the course the impact will be <br /> different. If you are ready, you
          can go ahead and Update the course. <br /> If you want to make further changes, click
          Back.
        </p>
      )}
      {isEditing && modeIsUnPublished && (
        <p>
          If you are ready, you can go ahead and Update the course. <br /> If you want to make
          additional changes, click Back.
        </p>
      )}
    </>
  );
};

const CourseCompleteStep = ({
  form,
  isEditing,
  onCourseSave,
  fetching,
  onSaveDraft,
  canSaveDraft,
}) => {
  const modeIsDraft = form.mode === 1;
  const modeIsPublished = form.mode === 2;
  const modeIsUnPublished = form.mode === 3;
  const buttonText = isEditing && !modeIsDraft ? 'Update' : 'Save and Publish';
  const isUpdate = isEditing && !modeIsDraft;

  return (
    <div className='d-flex flex-column lesson-complete-step text-center'>
      <h4>Course Setup is Complete</h4>
      {getMessageElement(modeIsDraft, modeIsPublished, modeIsUnPublished, isEditing)}
      <div>
        {canSaveDraft && (
          <button
            className='btn btn-outline-blue btn-border-2 mr-3 min-100'
            type='button'
            onClick={onSaveDraft}
            disabled={fetching}
          >
            {fetching === 1 ? <ButtonLoading /> : 'Save Draft'}
          </button>
        )}
        {hasAccess('lesson_publish') && (
          <button
            className='btn btn-primary min-100'
            type='button'
            onClick={() => onCourseSave('', isUpdate)}
            disabled={fetching}
          >
            {fetching === 2 ? <ButtonLoading /> : buttonText}
          </button>
        )}
      </div>
    </div>
  );
};

export default CourseCompleteStep;
