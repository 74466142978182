import React from 'react';
import Chip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/core/styles';

const CustomChip = withStyles(theme => ({
  root: {
    borderRadius: 24,
  },
}))(Chip);

const ModeStateLabel = ({ mode }) => {
  let label = '';
  let color = '';
  let textColor = '';

  switch (mode) {
    case 1:
      label = 'Draft';
      color = '#FFC84C1A';
      textColor = '#FFC84C';
      break;
    case 2:
      label = 'Published';
      color = '#41AF331A';
      textColor = '#41AF33';
      break;
    case 3:
      label = 'Unpublished';
      color = '#EFF4FB';
      textColor = '#60636C';
      break;
    default:
      label = '';
      color = '';
  }

  return <CustomChip label={label} style={{ backgroundColor: color, color: textColor }} />;
};

export default ModeStateLabel;
