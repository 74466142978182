import { QUESTION_TYPES_BY_VALUE, QUESTION_TYPES_OBJ } from './constants';

export const adaptQuestionGroupBody = group => {
  const res = {
    ...group,
    questions: group.questions.map(question => {
      const categorizedImages = [];
      if (question.type === QUESTION_TYPES_OBJ['image-category']) {
        question.imageColumns.forEach(col => {
          col.items.forEach(imgItem => {
            imgItem.category = col.name;
            categorizedImages.push({
              imageId: imgItem.imageId || imgItem.id,
              category: col.name,
              description: imgItem.description,
            });
          });
        });
      }
      return question.type === QUESTION_TYPES_OBJ['image-category']
        ? {
            questionType: QUESTION_TYPES_BY_VALUE[question.type],
            title: question.title,
            imageAnswers: [
              ...question.unCategorizedImages.map(image => ({
                imageId: image.imageId || image.id,
                description: image.description,
              })),
              ...categorizedImages,
            ],
          }
        : {
            questionType: QUESTION_TYPES_BY_VALUE[question.type],
            title: question.title,
            category: group.name,
            answers: [...question.answers.map(a => a.answer)],
            correctAnswers:
              question.type === QUESTION_TYPES_OBJ.yesno
                ? [question.yesno]
                : [...question.answers.filter(a => a.isRightAnswer).map(a => a.answer)],
          };
    }),
  };
  return res;
};

export const adaptQuestionGroups = groups => {
  return groups.map(group => adaptQuestionGroupBody(group));
};

const emptyAnswer = { answer: '' };

const getEmptyQuestionObj = () => ({
  question: '',
  answers: [{ ...emptyAnswer }],
  type: QUESTION_TYPES_OBJ.yesno,
});

export const adaptSettingQGroups = (groups, hasNoAnyQuestion) => {
  return groups.slice(0).map(group => ({
    ...group,
    questions: group.questions.length
      ? group.questions.map(q => {
          if (q.type === QUESTION_TYPES_OBJ['image-category']) {
            // const imageColumns = q.answersImageCategories.map((q) => retu({name: q.category, id: Date.now(), items: }))
            const unCategorizedImages = q.answersImageCategories
              ?.filter(item => !item.category)
              .map(item => ({
                ...item,
                url: item.imageUrl,
              }));
            const categorizedImages = q.answersImageCategories.filter(col => col.category);
            let columnNames = {};
            categorizedImages.forEach(imgCat => {
              if (!columnNames[imgCat.category]) {
                columnNames[imgCat.category] = imgCat.category;
              }
            });
            const imageColumns = [];
            categorizedImages.forEach((imgAnswer, idx) => {
              const imgCategoryIdx = imageColumns.findIndex(col => col.name === imgAnswer.category);
              if (imgCategoryIdx === -1) {
                imageColumns.push({
                  name: imgAnswer.category,
                  id: imgAnswer.id,
                  items: [
                    {
                      url: imgAnswer.imageUrl,
                      imageId: imgAnswer.imageId,
                      id: imgAnswer.imageId,
                      description: imgAnswer.description,
                    },
                  ],
                });
              } else {
                imageColumns[imgCategoryIdx].items.push({
                  url: imgAnswer.imageUrl,
                  imageId: imgAnswer.imageId,
                  id: imgAnswer.imageId,
                  description: imgAnswer.description,
                });
              }
            });
            return {
              ...q,
              unCategorizedImages,
              imageColumns,
            };
          } else if (q.type === QUESTION_TYPES_OBJ.yesno) {
            const yesnoValue = q.answersMulti.find(a => a.isRightAnswer)?.answer || null;
            return {
              ...q,
              answers: q.answersMulti,
              yesno: yesnoValue,
              correctAnswers: [yesnoValue],
            };
          } else {
            return { ...q, answers: q.answersMulti };
          }
        })
      : hasNoAnyQuestion
      ? []
      : [getEmptyQuestionObj()],
  }));
};
