/* eslint-disable */
export const tablesConfigs = {
  'vip-subscription': {
    table: [
      { name: 'Fiscal Year Executed', key: 'fiscalYearExecuted', className: 'col-2', type: 'number' },
      { name: 'Audience MD', key: 'audienceMD', className: 'col-2', type: 'text' },
      { name: 'Audience RT', key: 'audienceRT', className: 'col-2', type: 'text' },
      { name: 'City', key: 'city', className: 'col-2', type: 'text' },
      { name: 'State', key: 'state', className: 'col-2', type: 'text' },
      { name: 'KOL', key: 'kol', className: 'col-2', type: 'text' },
      { name: 'Franchise', key: 'franchise', className: 'col-2', type: 'text' },
      { name: 'Accredited', key: 'accredited', className: 'col-2', type: 'text' },
      { name: 'Credit', key: 'creditType', className: 'col-2', type: 'text' },
      { name: 'Evaluation Question 1', key: 'evaluationQuestion1', className: 'col-2', type: 'number' },
      { name: 'Evaluation Question 2', key: 'evaluationQuestion2', className: 'col-2', type: 'number' },
      { name: 'Evaluation Question 3', key: 'evaluationQuestion3', className: 'col-2', type: 'number' },
      { name: 'Evaluation Question 4', key: 'evaluationQuestion4', className: 'col-2', type: 'number' },
      { name: 'Evaluation Question 5', key: 'evaluationQuestion5', className: 'col-2', type: 'number' },
      { name: 'Email', key: 'email', className: 'col-2', type: 'text' },
      { name: 'First Name', key: 'firstName', className: 'col-2', type: 'text' },
      { name: 'Last Name', key: 'lastName', className: 'col-2', type: 'text' },
      { name: 'Institution', key: 'institution', className: 'col-2', type: 'text' },
      { name: 'Country', key: 'country', className: 'col-2', type: 'text' },
      { name: 'Institution Street Address', key: 'institutionAddress', className: 'col-2', type: 'text' },
      { name: 'ZipCode', key: 'zipCode', className: 'col-2', type: 'text' },
      { name: 'Preferred Phone', key: 'preferredPhone', className: 'col-2', type: 'text' },
      { name: 'Speciality', key: 'speciality', className: 'col-2', type: 'text' },
      { name: 'Other Speciality', key: 'otherSpeciality', className: 'col-2', type: 'text' },
      { name: 'Medical License #', key: 'medicalLicense', className: 'col-2', type: 'text' },
      { name: 'NPI #', key: 'npi', className: 'col-2', type: 'text' },
    ],
  },
  '3d-breast-tomosyntesis': {
    table: [
      { name: 'Fiscal Year Executed', key: 'fiscalYearExecuted', className: 'col-2', type: 'number' },
      { name: 'Audience MD', key: 'audienceMD', className: 'col-2', type: 'text' },
      { name: 'Audience RT', key: 'audienceRT', className: 'col-2', type: 'text' },
      { name: 'City', key: 'city', className: 'col-2', type: 'text' },
      { name: 'State', key: 'state', className: 'col-2', type: 'text' },
      { name: 'KOL', key: 'kol', className: 'col-2', type: 'text' },
      { name: 'Franchise', key: 'franchise', className: 'col-2', type: 'text' },
      { name: 'Accredited', key: 'accredited', className: 'col-2', type: 'text' },
      { name: 'Credit Type', key: 'creditType', className: 'col-2', type: 'text' },
      { name: 'Evaluation Question 1', key: 'evaluationQuestion1', className: 'col-2', type: 'number' },
      { name: 'Evaluation Question 2', key: 'evaluationQuestion2', className: 'col-2', type: 'number' },
      { name: 'Evaluation Question 3', key: 'evaluationQuestion3', className: 'col-2', type: 'number' },
      { name: 'Evaluation Question 4', key: 'evaluationQuestion4', className: 'col-2', type: 'number' },
      { name: 'Evaluation Question 5', key: 'evaluationQuestion5', className: 'col-2', type: 'number' },
      { name: 'Registration Code', key: 'registrationCode', className: 'col-2', type: 'text' },
      { name: 'Email', key: 'email', className: 'col-2', type: 'text' },
      { name: 'First Name', key: 'firstName', className: 'col-2', type: 'text' },
      { name: 'Last Name', key: 'lastName', className: 'col-2', type: 'text' },
      { name: 'Institution', key: 'institution', className: 'col-2', type: 'text' },
      { name: 'Institution Street Address', key: 'institutionAddress', className: 'col-2', type: 'text' },
      { name: 'Country', key: 'country', className: 'col-2', type: 'text' },
      { name: 'ZipCode', key: 'zipCode', className: 'col-2', type: 'text' },
      { name: 'Preferred Phone', key: 'preferredPhone', className: 'col-2', type: 'text' },
      { name: 'Speciality', key: 'speciality', className: 'col-2', type: 'text' },
      { name: 'Other Speciality', key: 'otherSpeciality', className: 'col-2', type: 'text' },
      { name: 'Medical License #', key: 'medicalLicense', className: 'col-2', type: 'text' },
      { name: 'NPI#', key: 'npi', className: 'col-2', type: 'text' },
      { name: 'Preferred Name on Certificate', key: 'preferredName', className: 'col-2', type: 'text' },
    ],
  },
  'low-dose-3d-mamography': {
    table: [
      { name: 'Fiscal Year Executed', key: 'fiscalYearExecuted', className: 'col-2', type: 'number' },
      { name: 'Audience MD', key: 'audienceMD', className: 'col-2', type: 'text' },
      { name: 'Audience RT', key: 'audienceRT', className: 'col-2', type: 'text' },
      { name: 'City', key: 'city', className: 'col-2', type: 'text' },
      { name: 'State', key: 'state', className: 'col-2', type: 'text' },
      { name: 'KOL', key: 'kol', className: 'col-2', type: 'text' },
      { name: 'Franchise', key: 'franchise', className: 'col-2', type: 'text' },
      { name: 'Accredited', key: 'accredited', className: 'col-2', type: 'text' },
      { name: 'Credit Type', key: 'creditType', className: 'col-2', type: 'text' },
      { name: 'Evaluation Question 1', key: 'evaluationQuestion1', className: 'col-2', type: 'number' },
      { name: 'Evaluation Question 2', key: 'evaluationQuestion2', className: 'col-2', type: 'number' },
      { name: 'Evaluation Question 3', key: 'evaluationQuestion3', className: 'col-2', type: 'number' },
      { name: 'Evaluation Question 4', key: 'evaluationQuestion4', className: 'col-2', type: 'number' },
      { name: 'Evaluation Question 5', key: 'evaluationQuestion5', className: 'col-2', type: 'number' },
      { name: 'Email', key: 'email', className: 'col-2', type: 'text' },
      { name: 'First Name', key: 'firstName', className: 'col-2', type: 'text' },
      { name: 'Last Name', key: 'lastName', className: 'col-2', type: 'text' },
      { name: 'Institution', key: 'institution', className: 'col-2', type: 'text' },
      { name: 'Institution Street Address', key: 'institutionAddress', className: 'col-2', type: 'text' },
      { name: 'Country', key: 'country', className: 'col-2', type: 'text' },
      { name: 'ZipCode', key: 'zipCode', className: 'col-2', type: 'text' },
      { name: 'Speciality', key: 'speciality', className: 'col-2', type: 'text' },
      { name: 'Preferred Phone', key: 'preferredPhone', className: 'col-2', type: 'text' },
      { name: 'Other Speciality', key: 'otherSpeciality', className: 'col-2', type: 'text' },
      { name: 'Medical License #', key: 'medicalLicense', className: 'col-2', type: 'text' },
      { name: 'NPI#', key: 'npi', className: 'col-2', type: 'text' },
    ],
  },
  '3d-mamography-interpretation': {
    table: [
      { name: 'Fiscal Year Executed', key: 'fiscalYearExecuted', className: 'col-2', type: 'number' },
      { name: 'Audience MD', key: 'audienceMD', className: 'col-2', type: 'text' },
      { name: 'Audience RT', key: 'audienceRT', className: 'col-2', type: 'text' },
      { name: 'City', key: 'city', className: 'col-2', type: 'text' },
      { name: 'State', key: 'state', className: 'col-2', type: 'text' },
      { name: 'KOL', key: 'kol', className: 'col-2', type: 'text' },
      { name: 'Franchise', key: 'franchise', className: 'col-2', type: 'text' },
      { name: 'Accredited', key: 'accredited', className: 'col-2', type: 'text' },
      { name: 'Credit Type', key: 'creditType', className: 'col-2', type: 'text' },
      { name: 'Evaluation Question 1', key: 'evaluationQuestion1', className: 'col-2', type: 'number' },
      { name: 'Evaluation Question 2', key: 'evaluationQuestion2', className: 'col-2', type: 'number' },
      { name: 'Evaluation Question 3', key: 'evaluationQuestion3', className: 'col-2', type: 'number' },
      { name: 'Evaluation Question 4', key: 'evaluationQuestion4', className: 'col-2', type: 'number' },
      { name: 'Evaluation Question 5', key: 'evaluationQuestion5', className: 'col-2', type: 'number' },
      { name: 'Registration Code', key: 'registrationCode', className: 'col-2', type: 'text' },
      { name: 'Email', key: 'email', className: 'col-2', type: 'text' },
      { name: 'First Name', key: 'firstName', className: 'col-2', type: 'text' },
      { name: 'Last Name', key: 'lastName', className: 'col-2', type: 'text' },
      { name: 'Institution', key: 'institution', className: 'col-2', type: 'text' },
      { name: 'Institution Street Address', key: 'institutionAddress', className: 'col-2', type: 'text' },
      { name: 'Country', key: 'country', className: 'col-2', type: 'text' },
      { name: 'ZipCode', key: 'zipCode', className: 'col-2', type: 'text' },
      { name: 'Speciality', key: 'speciality', className: 'col-2', type: 'text' },
      { name: 'Preferred Phone', key: 'preferredPhone', className: 'col-2', type: 'text' },
      { name: 'Other Speciality', key: 'otherSpeciality', className: 'col-2', type: 'text' },
      { name: 'Medical License #', key: 'medicalLicense', className: 'col-2', type: 'text' },
      { name: 'NPI#', key: 'npi', className: 'col-2', type: 'text' },
    ],
  },
  '3dqcourse': {
    table: [
      { name: 'Fiscal Year Executed', key: 'fiscalYearExecuted', className: 'col-2', type: 'number' },
      { name: 'Audience MD', key: 'audienceMD', className: 'col-2', type: 'text' },
      { name: 'Audience RT', key: 'audienceRT', className: 'col-2', type: 'text' },
      { name: 'City', key: 'city', className: 'col-2', type: 'text' },
      { name: 'State', key: 'state', className: 'col-2', type: 'text' },
      { name: 'KOL', key: 'kol', className: 'col-2', type: 'text' },
      { name: 'Franchise', key: 'franchise', className: 'col-2', type: 'text' },
      { name: 'Accredited', key: 'accredited', className: 'col-2', type: 'text' },
      { name: 'Credit Type', key: 'creditType', className: 'col-2', type: 'text' },
      { name: 'Evaluation Question 1', key: 'evaluationQuestion1', className: 'col-2', type: 'number' },
      { name: 'Evaluation Question 2', key: 'evaluationQuestion2', className: 'col-2', type: 'number' },
      { name: 'Evaluation Question 3', key: 'evaluationQuestion3', className: 'col-2', type: 'number' },
      { name: 'Evaluation Question 4', key: 'evaluationQuestion4', className: 'col-2', type: 'number' },
      { name: 'Evaluation Question 5', key: 'evaluationQuestion5', className: 'col-2', type: 'number' },
      { name: 'Email', key: 'email', className: 'col-2', type: 'text' },
      { name: 'First Name', key: 'firstName', className: 'col-2', type: 'text' },
      { name: 'Last Name', key: 'lastName', className: 'col-2', type: 'text' },
      { name: 'Institution', key: 'institution', className: 'col-2', type: 'text' },
      { name: 'Institution Street Address', key: 'institutionAddress', className: 'col-2', type: 'text' },
      { name: 'Country', key: 'country', className: 'col-2', type: 'text' },
      { name: 'ZipCode', key: 'zipCode', className: 'col-2', type: 'text' },
      { name: 'Speciality', key: 'speciality', className: 'col-2', type: 'text' },
      { name: 'Preferred Phone', key: 'preferredPhone', className: 'col-2', type: 'text' },
      { name: 'Other Speciality', key: 'otherSpeciality', className: 'col-2', type: 'text' },
      { name: 'Medical License #', key: 'medicalLicense', className: 'col-2', type: 'text' },
      { name: 'NPI#', key: 'npi', className: 'col-2', type: 'text' },
    ],
  },
  'dinner-program': {
    table: [
      { name: 'Fiscal Year Executed', key: 'fiscalYearExecuted', className: 'col-2', type: 'number' },
      { name: 'Quarter Executed', key: 'quarterExecuted', className: 'col-2', type: 'text' },
      { name: 'Date of Request', key: 'requestDate', className: 'col-2', type: 'date' },
      { name: 'Fiscal Year Request', key: 'fiscalYearRequest', className: 'col-2', type: 'text' },
      { name: 'Quarter Request', key: 'quarterRequest', className: 'col-2', type: 'text' },
      { name: 'Sales Rep', key: 'salesRep', className: 'col-2', type: 'text' },
      { name: 'City', key: 'city', className: 'col-2', type: 'text' },
      { name: 'State', key: 'state', className: 'col-2', type: 'text' },
      { name: 'Region', key: 'region', className: 'col-2', type: 'text' },
      { name: 'Area', key: 'area', className: 'col-2', type: 'text' },
      { name: 'Date Executed', key: 'executedDate', className: 'col-2', type: 'date' },
      { name: 'KOL', key: 'kol', className: 'col-2', type: 'text' },
      { name: 'Topic/Title', key: 'topic', className: 'col-2', type: 'text' },
      { name: 'Franchise', key: 'franchise', className: 'col-2', type: 'text' },
      { name: 'Accredited', key: 'accredited', className: 'col-2', type: 'text' },
      { name: 'Credit Type', key: 'creditType', className: 'col-2', type: 'text' },
      { name: 'Vendor', key: 'vendor', className: 'col-2', type: 'text' },
      { name: "RSVP'd", key: 'rsvpd', className: 'col-2', type: 'number' },
      { name: 'Actual Attended', key: 'actualAttended', className: 'col-2', type: 'number' },
      { name: 'MD/DO', key: 'mD_DO', className: 'col-2', type: 'number' },
      { name: 'NP/PA', key: 'nP_PA', className: 'col-2', type: 'number' },
      { name: 'RN/LPN', key: 'rN_LPN', className: 'col-2', type: 'number' },
      { name: 'Technologist', key: 'technologist', className: 'col-2', type: 'number' },
      { name: 'Other Degree', key: 'otherDegree', className: 'col-2', type: 'number' },
      { name: 'Other Decision Makers', key: 'otherDecisionMakers', className: 'col-2', type: 'number' },
      { name: 'Cost of Program', key: 'costOfProgram', className: 'col-2', type: 'number' },
      { name: 'Venue', key: 'venue', className: 'col-2', type: 'text' },
      { name: 'Demo', key: 'demo', className: 'col-2', type: 'number' },
      { name: 'Evaluation Question 1', key: 'evaluationQuestion1', className: 'col-2', type: 'number' },
      { name: 'Evaluation Question 2', key: 'evaluationQuestion2', className: 'col-2', type: 'number' },
      { name: 'Evaluation Question 3', key: 'evaluationQuestion3', className: 'col-2', type: 'number' },
      { name: 'Evaluation Question 4', key: 'evaluationQuestion4', className: 'col-2', type: 'number' },
      { name: 'Evaluation Question 5', key: 'evaluationQuestion5', className: 'col-2', type: 'number' },
    ],
  },
  'online-training': {
    table: [
      { name: 'Fiscal Year Executed', key: 'fiscalYearExecuted', className: 'col-2', type: 'number' },
      { name: 'Quarter Executed', key: 'quarterExecuted', className: 'col-2', type: 'text' },
      { name: 'Audience MD', key: 'audienceMD', className: 'col-2', type: 'text' },
      { name: 'KOL', key: 'kol', className: 'col-2', type: 'text' },
      { name: 'Topic/Title', key: 'topic', className: 'col-2', type: 'text' },
      { name: 'Franchise', key: 'franchise', className: 'col-2', type: 'text' },
      { name: 'Accredited', key: 'accredited', className: 'col-2', type: 'text' },
      { name: 'Credit Type', key: 'creditType', className: 'col-2', type: 'text' },
      { name: 'Vendor', key: 'vendor', className: 'col-2', type: 'text' },
      { name: 'Q1 - OUS (MD/DO)', key: 'q1OUS_MD_DO', className: 'col-2', type: 'number' },
      { name: 'Q2 - OUS (RT/Other)', key: 'q2OUS_RT_Other', className: 'col-2', type: 'number' },
      { name: 'Q3 - US (MD/DO)', key: 'q4OUS_RT_Other', className: 'col-2', type: 'number' },
      { name: 'Q4 - OUS (RT/Other)', key: 'q3US_MD_DO', className: 'col-2', type: 'number' },
      { name: 'Evaluation Question 1', key: 'evaluationQuestion1', className: 'col-2', type: 'number' },
      { name: 'Evaluation Question 2', key: 'evaluationQuestion2', className: 'col-2', type: 'number' },
      { name: 'Evaluation Question 3', key: 'evaluationQuestion3', className: 'col-2', type: 'number' },
      { name: 'Evaluation Question 4', key: 'evaluationQuestion4', className: 'col-2', type: 'number' },
      { name: 'Evaluation Question 5', key: 'evaluationQuestion5', className: 'col-2', type: 'number' },
    ],
  },
  'live-training': {
    table: [
      { name: 'Fiscal Year Executed', key: 'fiscalYearExecuted', className: 'col-2', type: 'number' },
      { name: 'Quarter Executed', key: 'quarterExecuted', className: 'col-2', type: 'text' },
      { name: 'Audience MD', key: 'audienceMD', className: 'col-2', type: 'text' },
      { name: 'City', key: 'city', className: 'col-2', type: 'text' },
      { name: 'State', key: 'state', className: 'col-2', type: 'text' },
      { name: 'Region', key: 'region', className: 'col-2', type: 'text' },
      { name: 'Area', key: 'area', className: 'col-2', type: 'text' },
      { name: 'KOL', key: 'kol', className: 'col-2', type: 'text' },
      { name: 'Topic/Title', key: 'topic', className: 'col-2', type: 'text' },
      { name: 'Franchise', key: 'franchise', className: 'col-2', type: 'text' },
      { name: 'Accredited', key: 'accredited', className: 'col-2', type: 'text' },
      { name: 'Credit Type', key: 'creditType', className: 'col-2', type: 'text' },
      { name: 'Vendor', key: 'vendor', className: 'col-2', type: 'text' },
      { name: 'Evaluation Question 1', key: 'evaluationQuestion1', className: 'col-2', type: 'number' },
      { name: 'Evaluation Question 2', key: 'evaluationQuestion2', className: 'col-2', type: 'number' },
      { name: 'Evaluation Question 3', key: 'evaluationQuestion3', className: 'col-2', type: 'number' },
      { name: 'Evaluation Question 4', key: 'evaluationQuestion4', className: 'col-2', type: 'number' },
      { name: 'Evaluation Question 5', key: 'evaluationQuestion5', className: 'col-2', type: 'number' },
    ],
  },
  'clinical-resource': {
    table: [
      { name: 'Fiscal Year Executed', key: 'fiscalYearExecuted', className: 'col-2', type: 'number' },
      { name: 'Quarter Executed', key: 'quarterExecuted', className: 'col-2', type: 'text' },
      { name: 'KOL', key: 'kol', className: 'col-2', type: 'text' },
      { name: 'Topic/Title', key: 'topic', className: 'col-3', type: 'text' },
      { name: 'Franchise', key: 'franchise', className: 'col-2', type: 'text' },
      { name: 'Whitepapers', key: 'whitePapers', className: 'col-2', type: 'number' },
      { name: 'Summary Cards', key: 'summaryCards', className: 'col-2', type: 'number' },
      { name: 'Bibliography Update', key: 'bibliographyUpdate', className: 'col-2', type: 'number' },
      { name: 'Presentations Creation', key: 'presentationsCreation', className: 'col-2', type: 'number' },
      { name: 'Case Studie', key: 'caseStudies', className: 'col-2', type: 'number' },
    ],
  },
  peer2peercall: {
    table: [
      { name: 'Fiscal Year Executed', key: 'fiscalYearExecuted', className: 'col-2', type: 'number' },
      { name: 'Quarter Executed', key: 'quarterExecuted', className: 'col-2', type: 'text' },
      { name: 'Date of Request', key: 'requestDate', className: 'col-2', type: 'date' },
      { name: 'Sales Rep', key: 'salesRep', className: 'col-2', type: 'text' },
      { name: 'City', key: 'city', className: 'col-2', type: 'text' },
      { name: 'State', key: 'state', className: 'col-2', type: 'text' },
      { name: 'Region', key: 'region', className: 'col-2', type: 'text' },
      { name: 'Area', key: 'area', className: 'col-2', type: 'text' },
      { name: 'Date Executed', key: 'executedDate', className: 'col-2', type: 'date' },
      { name: 'KOL', key: 'kol', className: 'col-2', type: 'text' },
      { name: 'Topic/Title', key: 'topic', className: 'col-2', type: 'text' },
      { name: 'Franchise', key: 'franchise', className: 'col-2', type: 'text' },
      { name: 'Email', key: 'email', className: 'col-2', type: 'text' },
      { name: 'First Name', key: 'firstName', className: 'col-2', type: 'text' },
      { name: 'Last Name', key: 'lastName', className: 'col-2', type: 'text' },
      { name: 'Institution', key: 'institution', className: 'col-2', type: 'text' },
      { name: 'Country', key: 'country', className: 'col-2', type: 'text' },
      { name: 'Preferred Phone', key: 'preferredPhone', className: 'col-2', type: 'text' },
    ],
  },
};
