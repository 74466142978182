import packageJson from './../../package.json';

const generic = {
  production: {
    url_protocol: 'https',
    ws_protocol: 'wss',
    api_domain: 'api.hologicace.com/',
    learner_domain: 'https://www.hologicace.com',
    content_domain: 'content.hologicace.com',
    room_360s_url: 'https://360s.hologicace.com/3dview.html',
  },
  stage: {
    url_protocol: 'https',
    ws_protocol: 'wss',
    api_domain: 'stage.hologicace.com/',
    learner_domain: 'https://user-stage.hologicace.com',
    content_domain: 'content-stage.hologicace.com',
    room_360s_url: 'https://360s.user-stage.hologicace.com/3dview.html',
  },
  development: {
    url_protocol: 'https',
    ws_protocol: 'wss',
    api_domain: 'dev.nodemo.site/',
    learner_domain: 'https://nodemo.site',
    content_domain: 'content.nodemo.site',
    room_360s_url: 'https://360s.nodemo.site/3dview.html',
  },
};

const getENV = () => {
  let env = process.env.REACT_APP_API_URL_ENV || process.env.NODE_ENV;
  env = env === 'test' ? 'development' : env;
  return env;
};

const main = generic[getENV() || 'development'];

export const HOST = {
  API: {
    VERSION: packageJson.version,
    URL: `${main.url_protocol}://${main.api_domain}`,
    WS: `${main.ws_protocol}://${main.api_domain}`,
    CONTENT: `${main.url_protocol}://${main.content_domain}`,
    LEARNER_DOMAIN: main.learner_domain,
    ROOM_360S_URL: main.room_360s_url,
    API_PREFIX: '',
    AUTH_HEADER: 'Authentication',
    AUTH_PREFIX: '',
  },
};
