import React from 'react';
import { IconSearch } from 'shared/components/Icons';

const Header = ({ search, onSearch, createNewPair }) => {
  return (
    <div className='users-header d-flex justify-content-between align-items-center'>
      <div className='d-flex'>
        <button className='btn btn-invite' onClick={createNewPair}>
          + Add New Pair
        </button>
      </div>
      <div className='d-flex'>
        <div className='search-block'>
          <input
            value={search}
            type='text'
            className='form-control'
            placeholder='Search by Device Name or ID'
            onChange={({ target }) => onSearch(target.value)}
          />
          <IconSearch />
        </div>
      </div>
    </div>
  );
};

export default Header;
