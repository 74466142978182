/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from 'shared/components/Button';
import { RegularInput } from 'shared/components/Input/RegularInput';
import { QuestionTypeLabel } from 'shared/components/QuestionTypeLabel';
import debounce, { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import { hasAccess } from 'utils/permissionHelper';

const Q_TYPES = [
  { type: 'multiSelect', title: 'Multi Select', value: 1 },
  { type: 'singleSelect', title: 'Single Select', value: 2 },
  { type: 'image', title: 'Image Categories', value: 4 },
  { type: 'boolean', title: 'Boolean', value: 3 },
];

export const RightSidebar = ({
  propertyDetails,
  courseId,
  firstBlockTitle,
  isEditState,
  handleSave,
  containerClassName,
  currentQGroup,
  selectedQuestion,
  handleSelectQuestion,
  haveAllRowsBorderOnHover,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [searchedQuestions, setSearchedQuestions] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [searchFilters, setSearchFilters] = useState([]);

  const handleChangeSearchFilters = filterValue => {
    const updatedFilters = searchFilters.includes(filterValue)
      ? searchFilters.filter(fltVal => fltVal !== filterValue)
      : [...searchFilters, filterValue];
    setSearchFilters(updatedFilters);
    getSearchedQuestions(searchValue, updatedFilters);
  };

  const getSearchedQuestions = async (searchValue, questionTypes) => {
    try {
      const params = {
        search: searchValue,
        questionTypes: questionTypes && questionTypes.join(','),
        group: currentQGroup.name,
      };
      const { data } = await Api.get(`/courses/${courseId}/question-groups/search`, { params });
      setSearchedQuestions(data.data.results);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const debouncedQuestionsSearch = useCallback(
    debounce(searchValue => {
      getSearchedQuestions(searchValue, searchFilters);
    }, 300),
    [],
  );

  const handleSearchQuestions = searchValue => {
    setSearchValue(searchValue);
    debouncedQuestionsSearch(searchValue);
  };

  useEffect(() => {
    getSearchedQuestions();
  }, []);

  return (
    <div className={`right_sidebar_container ${containerClassName}`}>
      <div className='side_regular_block quiz_prop_block'>
        <h6 className='side_block_title'>{firstBlockTitle}</h6>
        <div className='list_container'>
          {Object.keys(propertyDetails).map((key, idx) => {
            const prop = propertyDetails[key];
            return (
              <div key={idx} className='prop_list_item'>
                {prop.icon}
                <span>{prop.name}</span>
                <span className='prop_value'>{prop.value || '-'}</span>
              </div>
            );
          })}
        </div>
      </div>
      {isEditState ? (
        <Button onClick={handleSave} className='btn btn-primary w-100 fz-12'>
          Save
        </Button>
      ) : (
        <>
          {hasAccess('quiz_create') && (
            <Link
              to={{
                pathname: `/course/${courseId}/quiz/question-groups/config`,
                state: { selectedGroupId: currentQGroup.id },
              }}
            >
              <Button className='btn btn-blue w-100 fz-12'>Edit</Button>
            </Link>
          )}
        </>
      )}
      {!isEditState && (
        <div className='side_regular_block search_block'>
          <h6 className='side_block_title left_m_4'>Search in selected question group</h6>
          <RegularInput
            handleChange={({ target }) => handleSearchQuestions(target.value)}
            withSearchIcon
            placeholder='Search in question titles'
          />
          <div className='q_groups_block'>
            {Q_TYPES.map((q, idx) => (
              <QuestionTypeLabel
                isSelected={searchFilters.includes(q.value)}
                onClick={() => handleChangeSearchFilters(q.value)}
                key={idx}
                title={q.title}
              />
            ))}
          </div>
        </div>
      )}
      <div className='right_side_questions_block'>
        {searchedQuestions.map((question, idx) => {
          const isSelected = selectedQuestion?.id === question.questionId;
          return (
            <div
              style={{ borderColor: isSelected && '#54D8A8' }}
              onClick={() => handleSelectQuestion({ ...question, id: question.questionId })}
              className={`row_item ${isSelected && 'selected'} ${haveAllRowsBorderOnHover &&
                'have_border_on_hover'}`}
              key={idx}
            >
              <span className='q_order'>{idx + 1}</span>
              <span className='q_title'>{question.questionTitle}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};
