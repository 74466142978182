import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import Input from 'shared/components/Input';
import TextArea from 'shared/components/TextArea';
import Button from 'shared/components/Button';
import LandingSelect from 'shared/components/LandingSelect';
import AvatarsGroup from 'assets/product/avatars_group.png';

const categories = [{ title: 'Dosimetry', id: 'Dosimetry' }, { title: 'Oncology', id: 'Oncology' }];
const initialForm = {
  name: '',
  surname: '',
  email: '',
  subject: null,
  text: '',
};

const SendQuestionSection = () => {
  const [form, setForm] = useState(initialForm);

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = async e => {
    e.preventDefault();
  };

  return (
    <div className='question-submit-sec'>
      <div className='top-pic-block'>
        <img alt='group' src={AvatarsGroup} />
      </div>
      <div className='title-row'>
        <h3 className='section-title-dark-gray sm'>Still have questions?</h3>
        <p className='section-desc-gray sm mb-0'>
          Can’t find the answer you’re looking for? Please chat to our friendly team.
        </p>
      </div>
      <form onSubmit={handleSubmit} className='form-block'>
        <div className='inputs-row'>
          <Input
            onChange={handleChange}
            label='First Name'
            name='name'
            placeholder='First Name'
            value={form.name}
          />
          <Input
            onChange={handleChange}
            label='Last name'
            placeholder='Last name'
            name='surname'
            value={form.surname}
          />
        </div>
        <Input
          onChange={handleChange}
          label='Email'
          placeholder='your@company.com'
          name='email'
          type='email'
          value={form.email}
        />
        <LandingSelect
          onChange={handleChange}
          name='subject'
          topLabel='Category:'
          label='General Enquiry'
          items={categories}
          displayProperty='title'
          className='redesigned-select-container outlined-gray'
        />
        <TextArea
          onChange={handleChange}
          uiType='outlined-gray'
          label='Short Description'
          placeholder='Enter Description'
          name='text'
          value={form.text}
        />
        <Button className='btn-purple-filled w-100' type='submit'>
          Send message
        </Button>
      </form>
    </div>
  );
};

export default SendQuestionSection;
