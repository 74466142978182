import React from 'react';

import ClickArea from 'shared/components/ClickArea';
import Loading from 'shared/components/Loading';

import { IconBin, IconVideoPair } from 'shared/components/Icons';

const headers = [
  {
    name: 'Master',
    className: 'col-4',
    key: 'deviceName',
  },
  {
    name: 'Client',
    className: 'col-4',
    key: 'deviceID',
  },
  {
    name: 'Event',
    className: 'w-100 px-3',
    key: 'event',
  },
  {
    name: 'Actions',
    className: 'col-1',
  },
];

const Lists = ({
  list,
  order,
  changeOrder,
  fetch,
  onEditDevice,
  onItemRemoveConfirm,
  onItemRemoveDisabled,
}) => {
  if (fetch) return <Loading className='mt-5' />;

  if (!list || !list.length)
    return <div className='text-black-50 text-center mt-4'>No Results Found</div>;

  const handleOrderChange = item => {
    if (!item.key) return;
    changeOrder({
      key: item.key,
      order: !order.order,
    });
  };

  return (
    <div className='users-lists'>
      <ul className='p-0'>
        <li className='d-flex list-header align-items-center'>
          {headers.map((item, index) => (
            <ClickArea
              key={item.name + index}
              className={`text-body d-flex align-items-center ${item.className}`}
              onClick={handleOrderChange.bind(null, item)}
            >
              {item.name}
              {item.key && order.orderKey === item.key && (
                <button
                  className={`btn ml-2 p-0 dropdown-toggle ${order.order ? '' : 'rotate-180'}`}
                />
              )}
            </ClickArea>
          ))}
        </li>
        {list &&
          list.map((item, i) => (
            <li key={i} className='d-flex list-item h-not-set'>
              <div className='col-4 d-flex flex-column justify-content-center'>
                <p className='mb-1 mt-2'>{item.deviceName}</p>
                <p className='mb-1 fz-10 text-secondary'>{item.deviceID}</p>
              </div>
              <div className='col-4 d-flex flex-column justify-content-center'>
                <p className='mb-1 mt-2'>{item.pairedDevice?.deviceName}</p>
                <p className='mb-1 fz-10 text-secondary'>{item.pairedDevice?.deviceID}</p>
              </div>
              <div className='w-100 px-3 d-flex flex-column'>
                {item.events && !!item.events.length
                  ? item.events.map((item, i) => <span key={i}>{item.name}</span>)
                  : '-'}
              </div>
              <div className='col-1 p-2 d-flex align-items-center w-100 justify-content-start action-buttons'>
                <button
                  className='btn bg-white px-1 py-0 mx-1 tb-action-btn-sm action-item'
                  onClick={onEditDevice.bind(null, item)}
                >
                  <IconVideoPair />
                </button>
                <button
                  onClick={onItemRemoveConfirm.bind(null, item)}
                  disabled={onItemRemoveDisabled}
                  type='button'
                  className='btn ml-2 action-item remove'
                >
                  <IconBin />
                </button>
              </div>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default Lists;
