import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { Popover } from '@material-ui/core';

import {
  DotsIcon,
  IconBin,
  IconBirads,
  IconCheck,
  IconClose,
  IconCopyAndCreate,
  IconExport,
  IconEye,
  IconHeatmap,
  IconMore,
  IconProfit,
  IconSquareEdit,
  QuizIcon,
} from './Icons';

const icons = {
  edit: <IconSquareEdit />,
  view: <IconEye />,
  delete: <IconBin />,
  heatmaps: <IconHeatmap />,
  birads: <IconBirads />,
  export: <IconExport />,
  close: <IconClose color='#dc3545' />,
  check: <IconCheck color='#28a745' />,
  copyAndCreate: <IconCopyAndCreate />,
  profit: <IconProfit />,
  dots: <DotsIcon />,
  dotsWhite: <DotsIcon color='#fff' />,
  quizView: <QuizIcon />,
  hasQuizView: <QuizIcon color='#fff' />,
  more: <IconMore />,
};

const ListActionButtons = ({ actions, item, visibleButtonsCount = 3, onlyDots = false }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const visibleActions = actions.filter(action =>
    typeof action?.hide === 'function' ? !action.hide(item) : true,
  );
  const actionsInTableView = onlyDots
    ? []
    : visibleActions.length > visibleButtonsCount
    ? visibleActions.slice(0, visibleButtonsCount - 1)
    : visibleActions;
  const actionsInTableDropDown = onlyDots
    ? visibleActions
    : visibleActions.length > visibleButtonsCount
    ? visibleActions.slice(visibleButtonsCount - 1)
    : null;

  const handleClick = event => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = e => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div className='d-flex action-buttons'>
      {actionsInTableView?.map((action, i) => {
        const disableOption = () => typeof action.disable === 'function' && action.disable(item);
        const withSample = action.title === 'Copy & Create';
        if (action.hide && action.hide(item)) return null;

        if (action.type === 'link') {
          return (
            <Link
              className={`action-item ${action.getClassName && action.getClassName(item)} ${
                action.actionClassName ? action.actionClassName(item) : ''
              } ${disableOption() && 'global_disabled'}`}
              key={i}
              to={{
                pathname: action.to(item),
                state: {
                  sampleId: withSample && item.id,
                  editId: action.addIdInLocationState && action.addIdInLocationState(item),
                },
              }}
              target={action.target}
            >
              {icons[action.getIcon ? action.getIcon(item) : action.icon]} {action.text}
            </Link>
          );
        }

        return (
          <button
            key={i}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              action.onClick(item);
            }}
            disabled={action.disabled || disableOption()}
            type='button'
            className={`btn action-item ${(action.icon === 'delete' || action.icon === 'close') &&
              'remove'} ${(action.disabled || disableOption()) && 'global_disabled'}`}
          >
            {icons[action.icon]} {action.text}
          </button>
        );
      })}
      {actionsInTableDropDown && (
        <div>
          <button
            variant='contained'
            aria-describedby={id}
            onClick={handleClick}
            type='button'
            className={`btn action-item dots ${open && 'opened_popup'}`}
          >
            {open ? icons.dotsWhite : icons.dots}
          </button>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <div className='actions-popover-container'>
              {actionsInTableDropDown.map((action, i) => {
                const disableOption = () =>
                  typeof action.disable === 'function' && action.disable(item);
                const withSample = action.title === 'Copy & Create';
                if (action.hide && action.hide(item)) return null;

                const conditionalTitle =
                  typeof action.getTitle === 'function' ? action.getTitle(item) : null;

                if (action.type === 'link') {
                  return (
                    <Link
                      className={`drp-action-item dropdown-action-link-item ${
                        action.title
                      } ${(item.disabled || disableOption()) && 'global_disabled'}`}
                      key={i}
                      to={{
                        pathname: action.to(item),
                        state: { sampleId: withSample && item.id },
                      }}
                      target={action.target}
                    >
                      {conditionalTitle || action.title}
                    </Link>
                  );
                }

                return (
                  <button
                    key={i}
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      action.onClick(item);
                    }}
                    disabled={action.disabled || disableOption()}
                    type='button'
                    className={`btn drp-action-item dropdown-action-btn-item ${
                      action.title
                    } ${(action.disabled || disableOption()) && 'global_disabled'}`}
                  >
                    {conditionalTitle || action.title}
                  </button>
                );
              })}
            </div>
          </Popover>
        </div>
      )}
    </div>
  );
};

export default ListActionButtons;
