import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import { useSnackbar } from 'notistack';
import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';
import { IconBin } from 'shared/components/Icons';
import Lists from './components/Lists';
import { hasAccess } from 'utils/permissionHelper';

const RegionsView = ({ history, match, location }) => {
  const { enqueueSnackbar } = useSnackbar();
  const regionId = match.params.id;

  const [fetch, setFetch] = useState(false);
  const [order, setOrder] = useState({ order: false, orderKey: 'updatedAt' });
  const [regionDetails, setRegionDetails] = useState({});
  const [removeFetch, setRemoveFetch] = useState(false);

  const getRegionById = async () => {
    setFetch(true);
    try {
      const res = await Api.get(`/common/region/${regionId}`);
      const details = res.data.data;
      setRegionDetails(details);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
    setFetch(false);
  };

  const onItemRemove = async item => {
    try {
      setRemoveFetch(true);
      await Api.delete(`/common/region/${item.id}`);
      history.push('/system/regions');
      enqueueSnackbar('Successfully removed', { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setRemoveFetch(false);
    }
  };

  const onItemRemoveConfirm = item => {
    confirmAlert({
      title: 'Confirmation Required',
      message: 'Are you sure you want to delete this Region?',
      buttons: [
        {
          label: 'Cancel',
        },
        {
          label: 'Delete',
          onClick: () => onItemRemove(item),
        },
      ],
    });
  };

  useEffect(() => {
    getRegionById();
  }, []);

  return (
    <div>
      <div className='users-header d-flex justify-content-between align-items-center'>
        <div className='d-flex breadcrumbs'>
          <Link to='/system/regions'>All Regions</Link>
          <span className='mx-2'>/</span>
          <span>{regionDetails.name}</span>
        </div>
      </div>
      <div className='col-12 py-3 has-header'>
        <div className='col-12 sub_head'>
          <div className='col-8 pl-0 name_container'>
            <label className='m-0'>Region Name:</label>
            <span className='form_right_text'>{regionDetails?.name || ''}</span>
          </div>
          {hasAccess('system_regions_create') && (
            <div className='col-4 pl-2 d-flex'>
              <button
                disabled={removeFetch}
                onClick={() => onItemRemoveConfirm(regionDetails)}
                className='btn btn-sm btn-blue text-orange fz-12 mr-2 fw-600 w-100 d-flex align-items-center justify-content-center'
              >
                <IconBin className='mr-2' />
                Delete
              </button>
              <Link
                to={`/system/regions/${regionDetails.id}/edit`}
                className='btn btn-sm btn-blue ml-2 fz-12 fw-600 w-100'
              >
                Edit
              </Link>
            </div>
          )}
        </div>
        <h4 className='sub_title'>Countries</h4>
        <Lists
          data={regionDetails.countries}
          order={order}
          changeOrder={setOrder}
          fetch={fetch}
          region={regionDetails.name}
        />
      </div>
    </div>
  );
};

export default RegionsView;
