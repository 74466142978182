import React from 'react';
import ClickArea from 'shared/components/ClickArea';
import Loading from 'shared/components/Loading';
import moment from 'moment';

const headers = [
  {
    name: 'First Name',
    className: 'col',
    key: 'name',
  },
  {
    name: 'Last Name',
    className: 'col',
    key: 'lastName',
  },
  {
    name: 'Date',
    className: 'col-1',
    key: 'createdAt',
  },
  {
    name: 'Country',
    className: 'col-2',
    key: 'country',
  },
  {
    name: 'Ticket Number',
    className: 'col-1',
    key: false,
  },
  {
    name: 'Category',
    className: 'col-2',
    key: 'category',
  },
  {
    name: 'Description',
    className: 'col-3',
    key: false,
  },
];

const Lists = ({ data, order, changeOrder, fetch }) => {
  if (fetch) return <Loading className='mt-5' />;
  if (!data || !data.length)
    return <div className='text-black-50 text-center mt-4'>No Results Found</div>;

  const handleOrderChange = item => {
    if (!item.key) return;
    changeOrder({
      orderKey: item.key,
      order: !order.order,
    });
  };

  return (
    <div className='users-lists'>
      <ul className='p-0'>
        <li className='d-flex list-header align-items-center'>
          {headers.map((item, index) => (
            <ClickArea
              key={item.name + index}
              className={`d-flex text-body justify-content-between align-items-center ${
                item.className
              }`}
              onClick={handleOrderChange.bind(null, item)}
            >
              {item.name}
              {item.key && order.orderKey === item.key && (
                <button
                  className={`btn ml-2 p-0 dropdown-toggle ${order.order ? '' : 'rotate-180'}`}
                />
              )}
            </ClickArea>
          ))}
        </li>
        {data &&
          data.map((item, index) => {
            return (
              <li key={index} className='d-flex align-items-center list-item h-not-set'>
                <div className='col text-truncate' title={item.name}>
                  {item.name}
                </div>
                <div className='col text-truncate' title={item.lastName}>
                  {item.lastName}
                </div>
                <div className='col-1 p-0 text-center'>
                  {item.createdAt ? moment(item.createdAt).format('MM/DD/YYYY') : ''}
                </div>
                <div className='col-2'>{item.country || '-'}</div>
                <div className='col-1'>{item.ticketSeq}</div>
                <div className='col-2'>{item.category}</div>
                <div className='col-3'>{item.text}</div>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default Lists;
