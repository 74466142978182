/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { StepDeleteIcon, StepDragIcon } from 'shared/components/Icons';

export const StepItem = ({
  id,
  category,
  categoryTitle,
  size = 'small',
  handleSelectStep,
  selectedStepId,
  index,
  handleRemoveStep,
  color,
}) => {
  const onSelect = () => {
    handleSelectStep(index);
  };

  const isSelectedItem = id === selectedStepId;

  return (
    <div
      onClick={onSelect}
      id={id}
      style={{ borderColor: color }}
      className={`step_item_container ${isSelectedItem && 'selected'} ${size} ${category}`}
    >
      {isSelectedItem && (
        <div onClick={() => handleRemoveStep(index)} className='remove_step_btn'>
          <StepDeleteIcon />
        </div>
      )}
      {isSelectedItem && <StepDragIcon className='step_drag_btn' />}
      <div className='step_title'>Step {index + 1}</div>
      {categoryTitle && (
        <p style={{ color }} className={`category ${category} ${categoryTitle}`}>
          {categoryTitle}
        </p>
      )}
    </div>
  );
};
