import { combineReducers } from 'redux';
import { isAuthReducer, accountReducer } from 'app/Auth/actions';
import { viewerReducer } from 'shared/components/Viewer/actions';
import { uploadMediaReducer } from 'app/Main/routes/WarehouseCasesNew/actions';

// Load reducers

const rootReducer = combineReducers({
  isAuthenticated: isAuthReducer,
  account: accountReducer,
  viewerOptions: viewerReducer,
  uploader: uploadMediaReducer,
});

export default rootReducer;
