import React from 'react';
import { InputSearchIcon } from '../Icons';

export const RegularInput = ({
  className,
  inputClassName,
  placeholder,
  value,
  handleChange,
  withSearchIcon,
  onEnter = () => {},
  inputId,
  autoFocus = false,
  name,
}) => {
  return (
    <div className={`input-wrapper ${className}`}>
      <input
        name={name}
        autoFocus={autoFocus}
        id={inputId}
        variant='outlined'
        className={`regular_input ${inputClassName}`}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            e.preventDefault();
            onEnter();
          }
        }}
      />
      {withSearchIcon && <InputSearchIcon className='input-icon' />}
    </div>
  );
};
