import React from 'react';
import ClickArea from 'shared/components/ClickArea';
import Loading from 'shared/components/Loading';
import ListActionButtons from 'shared/components/ListActionButtons';
import { formatDate } from 'utils/appHelpers';
import { hasAccess } from 'utils/permissionHelper';

const headers = [
  {
    name: 'Survey Name',
    className: 'col',
    key: 'name',
  },
  {
    name: 'Date Created',
    className: 'col',
    key: 'createdAt',
  },
  {
    name: 'Question Sets',
    className: 'col',
    key: 'surveySets',
  },
  {
    name: 'Used in Workshops',
    className: 'col',
    key: 'used',
  },
  {
    name: 'Actions',
    className: 'p-2 d-flex action-buttons-container min_90 text_start',
    key: '',
  },
];

const SubscriptionsLists = ({
  list,
  order,
  changeOrder,
  fetch,
  onItemRemoveDisabled,
  onItemRemoveConfirm,
}) => {
  if (fetch) return <Loading className='mt-5' />;

  if (!list || !list.length)
    return <div className='text-black-50 text-center mt-4'>No Results Found</div>;

  const handleOrderChange = item => {
    if (!item.key) return;
    changeOrder({
      key: item.key,
      order: !order.order,
    });
  };

  const actions = [
    {
      type: 'link',
      icon: 'edit',
      to: item => `/survey/${item.id}/edit`,
      hide: () => !hasAccess('survey_create'),
    },
    {
      type: 'button',
      icon: 'delete',
      disabled: onItemRemoveDisabled,
      onClick: item => onItemRemoveConfirm(item),
      hide: () => !hasAccess('survey_create'),
    },
  ];

  return (
    <div className='users-lists'>
      <ul className='p-0'>
        <li className='d-flex list-header align-items-center'>
          {headers.map((item, index) => (
            <ClickArea
              key={item.name + index}
              className={`text-body d-flex align-items-center ${!item.key &&
                'justify-content-end'} ${item.className}`}
              onClick={handleOrderChange.bind(null, item)}
            >
              {item.name}
              {item.key && order.orderKey === item.key && (
                <button
                  className={`btn ml-2 p-0 dropdown-toggle ${order.order ? '' : 'rotate-180'}`}
                />
              )}
            </ClickArea>
          ))}
        </li>
        {list &&
          list.map(item => {
            return (
              <li key={item.id} className='d-flex list-item h-not-set'>
                <div className='d-flex col align-items-center'>{item.name}</div>
                <div className='d-flex col align-items-center'>{formatDate(item.createdAt)}</div>
                <div className='d-flex col align-items-center'>{item.surveySets?.length}</div>
                <div className='d-flex col align-items-center'>{item.used}</div>
                <div className='p-2 d-flex action-buttons-container min_90'>
                  <ListActionButtons actions={actions} item={item} />
                </div>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default SubscriptionsLists;
