import React from 'react';
import EditingInput from 'shared/components/editingForms/EditingInput';
import EditingTextArea from 'shared/components/editingForms/EditingTextArea';
import { IconBin } from 'shared/components/Icons';
import UploadImageRow from 'shared/components/UploadImageRow';

const getEmptyCME = () => ({
  title: '',
  link: '',
  uploadImageId: 0,
  description: '',
});

const EventCMEStep = ({ form, setForm }) => {
  const hasCME = form.cmEs && !!form.cmEs.length;

  const onAddCME = () => {
    const temp = { ...form };
    temp.cmEs = temp.cmEs || [];
    temp.cmEs.push(getEmptyCME());
    setForm(temp);
  };

  const removeCME = index => {
    const temp = { ...form };
    temp.cmEs.splice(index, 1);
    setForm(temp);
  };

  const handleChange = (index, { target }) => {
    const temp = { ...form };
    const { name, value } = target;
    temp.cmEs[index][name] = value;
    setForm(temp);
  };

  const onSuccessUpload = (index, image) => {
    const tempData = [...form.cmEs];
    tempData[index].uploadImageId = image.id;
    tempData[index].imageUrl = image.url;
    setForm({ ...form, cmEs: tempData });
  };

  return (
    <div className='px-2'>
      <h6 className='color-blue mb-3'>CME Configuration</h6>
      {!hasCME && (
        <p>There are no default CME configurations. You can start adding them manually.</p>
      )}
      <div>
        {form.cmEs &&
          form.cmEs.map((item, index) => {
            const isLast = index + 1 === form.cmEs.length;
            return (
              <div className='col-9 p-0 user-creation-form mb-4' key={index}>
                <p className='text-blue font-weight-bold mb-2'>CME {index + 1}</p>
                <ul className='p-0 mb-0'>
                  <li className='d-flex align-items-center'>
                    <div className='col-3 pl-0'>
                      <label className='m-0'>CME Title*:</label>
                    </div>
                    <EditingInput
                      type='text'
                      name='title'
                      value={item.title}
                      onChange={handleChange.bind(null, index)}
                      className='p-0'
                      placeholder='Enter Title'
                      required
                      editing={true}
                    />
                  </li>
                  <li className='d-flex align-items-center'>
                    <div className='col-3 pl-0'>
                      <label className='m-0'>CME Link*:</label>
                    </div>
                    <EditingInput
                      type='text'
                      name='link'
                      value={item.link}
                      onChange={handleChange.bind(null, index)}
                      className='p-0'
                      placeholder='Enter Link'
                      required
                      editing={true}
                    />
                  </li>
                  <UploadImageRow
                    title='CME Image:'
                    image={item.imageUrl}
                    name={`image-${index}`}
                    onSuccessUpload={onSuccessUpload.bind(null, index)}
                  />
                  <li className='d-flex'>
                    <div className='col-3 pl-0'>
                      <label className='m-0'>CME Description*:</label>
                    </div>
                    <EditingTextArea
                      type='text'
                      name='description'
                      value={item.description}
                      onChange={handleChange.bind(null, index)}
                      className='p-0 is-large'
                      placeholder='Enter Description'
                      required
                      editing={true}
                      access={true}
                    />
                  </li>
                </ul>
                <div className='mt-3 d-flex align-items-center justify-content-between'>
                  <button
                    type='button'
                    className='btn btn-sm pl-0 text-danger d-flex align-items-center'
                    onClick={removeCME.bind(null, index)}
                  >
                    <IconBin className={'mr-2'} />
                    Remove CME
                  </button>
                  {isLast && (
                    <button
                      type='button'
                      className='btn btn-outline-blue add-new-frames fz-14'
                      onClick={onAddCME}
                    >
                      Add CME +
                    </button>
                  )}
                </div>
              </div>
            );
          })}
      </div>
      {!hasCME && (
        <button type='button' className='btn btn-outline-blue px-5' onClick={onAddCME}>
          Add CME +
        </button>
      )}
    </div>
  );
};

export default EventCMEStep;
