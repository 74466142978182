import React from 'react';
import { Link } from 'react-router-dom';

export const ConditionalLinkWrapper = ({ to, className, children }) => {
  return to ? (
    <Link className={className} to={to}>
      {children}
    </Link>
  ) : (
    <div className={className}>{children}</div>
  );
};
