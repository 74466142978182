/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { IconCheckBold } from 'shared/components/Icons';

const InfoIcon = ({ name, value, className }) => (
  <p className={`m-0 d-flex ${className || ''}`}>
    {name}: <b className='ml-1'>{value}</b>
  </p>
);

const SetsBlock = ({
  items,
  activeItemIdx,
  onClickItem,
  isCompletedItem,
  onAddNewItem,
  noPlus,
}) => {
  return (
    <div className='cases-block d-flex flex-wrap'>
      {items &&
        items.map((item, index) => {
          const isActive = activeItemIdx === index;
          const isCompleted = isCompletedItem && isCompletedItem(item);
          return (
            <div
              onClick={() => onClickItem(index)}
              className={`case-block d-flex flex-column justify-content-between ${
                isActive ? 'active' : ''
              }`}
              key={index}
            >
              <div className='d-flex justify-content-between align-items-center'>
                <h4 className='mb-0 mr-2'>{item.name || '-'}</h4>
                {isCompleted && <IconCheckBold height='12' width='15' />}
              </div>
              <div className='d-flex justify-content-between'>
                <InfoIcon name='Q' value={item.questions?.filter(q => q.title).length || 0} />
              </div>
            </div>
          );
        })}
      {!noPlus && (
        <div
          onClick={onAddNewItem}
          className={`case-block d-flex align-items-center fz-40 pb-2 text-blue justify-content-center`}
        >
          +
        </div>
      )}
    </div>
  );
};

export default SetsBlock;
