import React, { useEffect, useState } from 'react';
import { closeFullscreen, openFullscreen } from '../utils';

import iconMax from 'assets/arrows/2p.svg';
import iconMin from 'assets/arrows/4a.svg';
import { getError } from 'utils/appHelpers';
import { useSnackbar } from 'notistack';
import { Api } from 'utils/connectors';
import { hasAccess } from 'utils/permissionHelper';

const ControlArea = ({
  tools,
  title,
  fullScreen,
  activeTool,
  setActiveTool,
  viewerOptions,
  disabled,
  patientId,
  caseScore,
  setCaseScore,
}) => {
  const enqueueSnackbar = useSnackbar();

  const toolClickHandle = async item => {
    const state =
      item.type === activeTool.type && !item.onTimeAction ? { type: 'none' } : { ...item };
    if (item.type === 'percent') {
      const percentage = await prompt('Enter case percentage');
      if (percentage) {
        try {
          await Api.put(`/cases/case/${patientId}`, { score: percentage });
          setCaseScore(percentage);
        } catch (err) {
          enqueueSnackbar(getError(err), { variant: 'error' });
        }
      }
      return;
    }
    setActiveTool(state);
  };

  return (
    <div className='control-area px-2 justify-content-center d-flex flex-column'>
      <div className='d-flex justify-content-between'>
        <div className='d-flex align-items-center pr-3'>
          <button className={`btn p-0`} onClick={fullScreen ? closeFullscreen : openFullscreen}>
            <img width='25' src={fullScreen ? iconMin : iconMax} alt='fullscreen' />
          </button>
          {title && <p className='ml-2 text-white m-0 opc-6'>{title}</p>}
        </div>
        <div className='d-flex align-items-center'>
          <div className='view-tools'>
            <div className='d-flex align-items-center'>
              {tools.map((item, idx) => {
                const isSeparator = item.type === 'separator';
                const canActive = viewerOptions && viewerOptions.canBeActive[item.type];
                const isActive = (activeTool.type === item.type && !item.onTimeAction) || canActive;
                const isDisabled =
                  (item.type === 'genuine' && !hasAccess('gaid_view')) ||
                  (item.type === 'RemoveGenius' && !hasAccess('gaid_create'));
                if (isSeparator) return <div key={item.type + idx} className='tool-separator' />;
                return (
                  <button
                    key={item.type}
                    title={item.title}
                    type='button'
                    className={`d-flex align-items-center btn text-white btn-sm mr-1 ${
                      isActive ? 'active' : ''
                    }`}
                    onClick={toolClickHandle.bind(null, item)}
                    disabled={disabled || isDisabled}
                  >
                    {item?.type === 'percent' && (
                      <span className='viewer-control-icon-txt'>{caseScore}</span>
                    )}
                    {item.icon && (
                      <img
                        height={item.height || '36'}
                        src={isActive ? item.iconActive : item.icon}
                        alt={item.name}
                        className={item.className}
                      />
                    )}
                  </button>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ControlArea;
