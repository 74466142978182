import React from 'react';
import ButtonLoading from 'shared/components/ButtonLoading';
import { hasAccess } from 'utils/permissionHelper';

export const SpotlightCompleteStep = ({ onSave, fetching }) => {
  return (
    <div className='d-flex flex-column lesson-complete-step text-center'>
      <h4>Spotlight Setup is Complete</h4>
      <p>
        If you are ready, you can go ahead and <span className='highlighted-action-text'>Save</span>{' '}
        the Spotlight. <br /> If you want to make additional changes, click{' '}
        <span className='highlighted-action-text'>Back</span>.
      </p>
      <div>
        {hasAccess('products_create') && (
          <button
            className='btn btn-primary min-100 m-auto'
            type='button'
            onClick={onSave}
            disabled={fetching}
          >
            {fetching ? <ButtonLoading /> : 'Save'}
          </button>
        )}
      </div>
    </div>
  );
};
