import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';
import SubscriptionsHeader from './components/SubscriptionsHeader';
import ReactPaginate from 'react-paginate';
import SubscriptionsLists from './components/SubscriptionsLists';
import { formatSubscription } from 'utils/formatHelpers';
import { IconArrowLeft, IconArrowRight } from 'shared/components/Icons';
import { confirmAlert } from 'react-confirm-alert';
import AdvancedFilters from 'shared/components/filters/AdvancedFilters';
import useFilters from 'shared/hooks/useFilters';
import PageTitle from 'shared/components/PageTitle';

const Subscriptions = ({ match }) => {
  const [removeFetch, setRemoveFetch] = useState(false);
  const [fetch, setFetch] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [subscriptions, setSubscriptions] = useState([]);
  const [pageCount, setPageCount] = useState(0);

  const { requestParams, filters, changeFilter, filterDependency } = useFilters({
    page: 0,
    order: false,
    orderKey: 'createdAt',
    search: '',
    limit: 8,
  });

  const downloadData = [
    {
      title: 'Export as CSV',
      url: `/subscription/getsubscriptions`,
      fileName: 'subscription_plans_list',
      type: 'csv',
      params: requestParams,
    },
  ];

  const getSubscriptions = async () => {
    try {
      setFetch(true);
      const { data } = await Api.get(`/subscription/getsubscriptions`, { params: requestParams });
      const tempData = data.data.results.map(item => formatSubscription(item));
      setPageCount(Math.ceil(data.data.count / 8));
      setSubscriptions(tempData);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetch(false);
    }
  };

  const onPageChange = ({ selected }) => {
    changeFilter({ page: selected });
  };

  const handleSearchChange = text => {
    changeFilter({ search: text });
  };

  const onChangeOrder = item => {
    if (!item.key) return;
    changeFilter({ order: !filters.order, orderKey: item.key });
  };

  const onItemRemove = async item => {
    try {
      setRemoveFetch(true);
      await Api.delete(`/subscription/${item.id}`);
      await getSubscriptions();
      enqueueSnackbar('Successfully removed', { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setRemoveFetch(false);
    }
  };

  const onItemRemoveConfirm = item => {
    if (item.totalUsers > 0) {
      confirmAlert({
        title: 'Can not be deleted',
        message: 'This subscription plan is in use and can not be deleted now.',
        overlayClassName: 'with-icon',
        buttons: [{ label: 'OK' }],
      });
      return;
    }

    confirmAlert({
      title: 'Confirmation Required',
      message: 'Are you sure you want to delete this Subscription?',
      overlayClassName: 'with-icon',
      buttons: [
        {
          label: 'CANCEL',
        },
        {
          label: 'REMOVE',
          onClick: () => onItemRemove(item),
        },
      ],
    });
  };

  useEffect(() => {
    getSubscriptions();
  }, [...filterDependency]);

  return (
    <div>
      <PageTitle match={match} />
      <SubscriptionsHeader onSearch={handleSearchChange} />
      <div className='py-3 has-header'>
        <AdvancedFilters
          downloadData={downloadData}
          withExport={true}
          filters={[]}
          onClearAll={() => {}}
        />
        <div className='col-12'>
          <SubscriptionsLists
            list={subscriptions}
            order={filters}
            changeOrder={onChangeOrder}
            fetch={fetch}
            onItemRemoveConfirm={onItemRemoveConfirm}
            onItemRemoveDisabled={removeFetch}
          />
        </div>
        {pageCount > 1 && !fetch && (
          <ReactPaginate
            previousLabel={<IconArrowLeft />}
            nextLabel={<IconArrowRight />}
            breakLabel={'...'}
            forcePage={Number(filters.page)}
            breakClassName={'break-me'}
            pageCount={pageCount}
            marginPagesDisplayed={3}
            pageRangeDisplayed={3}
            onPageChange={onPageChange}
            containerClassName={`pagination show-arrow`}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
          />
        )}
      </div>
    </div>
  );
};

export default Subscriptions;
