/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useRef, useEffect } from 'react';
import FontFaceObserver from 'fontfaceobserver';
import { getTextWidth } from 'utils/domHelpers';

export const EditingInputWithPostfix = props => {
  const { value: initialValue, postfix, onValueChange, name } = props;
  const [value, setValue] = useState(initialValue);
  const inputRef = useRef(null);
  const postfixRef = useRef(null);

  const updatePostfixPosition = async () => {
    if (inputRef.current && postfixRef.current) {
      const font = new FontFaceObserver('Roboto');
      font
        .load()
        .then(() => {
          const width = getTextWidth(value, '14px Roboto') + 13;
          postfixRef.current.style.left = `${width}px`;
        })
        .catch(() => {
          console.error('Roboto font is not available.');
        });
    }
  };

  const updatePostfixPositionOnChange = async () => {
    if (inputRef.current && postfixRef.current) {
      const width = (await getTextWidth(value, '14px Roboto')) + 13;
      postfixRef.current.style.left = `${width}px`;
    }
  };

  const handleInputChange = event => {
    const newValue = event.target.value;
    setValue(newValue);
    if (onValueChange) {
      onValueChange(event);
    }
    updatePostfixPositionOnChange();
  };

  const focusInput = () => {
    if (inputRef.current) {
      const input = inputRef.current;
      input.focus();
      const valueLength = input.value.length;
      input.setSelectionRange(valueLength, valueLength);
    }
  };

  useEffect(() => {
    updatePostfixPosition();
  }, [value]);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <div className='postfix-input-container input-group'>
      <input
        name={name}
        className='postfix-input flex-fill'
        type='text'
        ref={inputRef}
        value={value}
        onChange={handleInputChange}
      />
      <span onClick={focusInput} className='postfix' ref={postfixRef}>
        {postfix}
      </span>
    </div>
  );
};
