/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { IconDashUsers, IconDashHourCourse, IconDash3DQCourse } from 'shared/components/Icons';

const tabs = [
  {
    name: 'Total Users',
    key: 'total',
    className: 'orange',
    valueKey: 'totalUsers',
    icon: IconDashHourCourse,
  },
  {
    name: '8-Hour Course',
    key: '8hour',
    className: 'blue',
    valueKey: 'total8HourUsers',
    icon: IconDashUsers,
  },
  {
    name: '3DQ Course',
    key: '3dq',
    className: 'green',
    valueKey: 'total3DQUsers',
    icon: IconDash3DQCourse,
  },
];

const DashTabs = ({ activeTab, setActiveTab, data }) => {
  return (
    <div className='dash-tabs d-flex align-items-center'>
      {tabs.map(item => {
        const Icon = item.icon;
        const isActive = activeTab === item.key;
        return (
          <div
            className={`dash-tab d-flex align-items-center justify-content-between ${
              isActive ? 'active' : ''
            } dash-${item.className}`}
            key={item.key}
            onClick={() => {
              setActiveTab(item.key);
            }}
          >
            <div>
              <h4>{data[item.valueKey]}</h4>
              <span>{item.name}</span>
            </div>
            <Icon color={isActive ? '#fff' : undefined} />
          </div>
        );
      })}
    </div>
  );
};

export default DashTabs;
