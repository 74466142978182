import React, { useEffect, useState } from 'react';
import Loading from 'shared/components/Loading';
import RolesHeader from './components/RolesHeader';
import PermissionsList from './components/PermissionsList';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import { confirmAlert } from 'react-confirm-alert';
import { IconBin } from 'shared/components/Icons';
import { Link } from 'react-router-dom';
import { hasAccess } from 'utils/permissionHelper';

const PublishRow = ({ label, data, onClick }) => {
  return (
    <p
      className={`d-flex justify-content-between mb-2 ${
        onClick ? 'link-color pointer text-underline' : ''
      }`}
      onClick={onClick}
    >
      <span>{label}</span>
      <span>{data}</span>
    </p>
  );
};

const AdminsRoleView = ({ match, history }) => {
  const [fetching, setFetching] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [permissions, setPermissions] = useState();
  const [role, setRole] = useState();

  const getRoleData = async id => {
    try {
      const res = await Api.get(`/admin/role/${id}`);
      const data = res.data.data;
      const permissions = {};
      data.rolePermissions.forEach(item => {
        permissions[item.permissionItem.key] = item.accessLevel;
      });
      data.permissions = permissions;
      setRole(data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const getPermissions = async () => {
    try {
      const res = await Api.get(`/admin/permissions`);
      setPermissions(res.data.data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const getAccessCount = key => {
    return role.rolePermissions.reduce((accumulator, object) => {
      return accumulator + (object.accessLevel === key ? 1 : 0);
    }, 0);
  };

  const onGoUsersPage = () => {
    history.push(`/roles/${match.params.id}/users`);
  };

  const onItemRemove = async () => {
    try {
      setFetching(true);
      await Api.delete(`/admin/role/${role.id}`);
      enqueueSnackbar('Successfully removed', { variant: 'success' });
      history.push('/roles/all');
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetching(false);
    }
  };

  const onItemRemoveConfirm = roleTitle => {
    if (role.assignedUsers > 0) {
      confirmAlert({
        overlayClassName: 'with-icon mid content_light',
        title: "Can't Delete",
        message:
          'You can’t delete this role as there are admins of this type in the system.\n\nChange the roles of assigned admin and then only delete the role.',
        buttons: [{ label: 'OK' }],
      });
      return;
    }

    confirmAlert({
      overlayClassName: 'with-icon danger content_light',
      title: 'Confirmation Needed',
      message: `Are you sure you want completely delete ${roleTitle} role and all its settings from the system?`,
      buttons: [
        {
          label: 'Cancel',
        },
        {
          label: 'Delete',
          onClick: () => onItemRemove(),
        },
      ],
    });
  };

  useEffect(() => {
    if (match.params.id) getRoleData(match.params.id);
    getPermissions();
    //eslint-disable-next-line
  }, [match.params.id]);

  if (!role || !permissions) return <Loading className='mt-5' />;

  const viewPermission = getAccessCount(1);
  const editPermission = getAccessCount(2);
  const blockPermissions = permissions.length - role.rolePermissions.length;

  return (
    <div>
      <RolesHeader role={role} />
      <div className='has-header d-flex'>
        <div className='col-9'>
          <ul className='list-default list-settings'>
            <li className='d-flex align-items-center p-3'>
              <div className='col-4 pl-0 weight-700'>Role Name:</div>
              <div className='col weight-200'>{role.title}</div>
            </li>
            <li className='p-3'>
              <div className='col-4 pl-0 weight-700'>Role Description:</div>
              <div className='col pt-2 pl-0 weight-300'>{role.description}</div>
            </li>
          </ul>
        </div>
        <div className='col-3'>
          <div className='publish-block course-publish p-3 mb-2'>
            <PublishRow label='View Permissions:' data={viewPermission} />
            <PublishRow label='Edit Permissions:' data={editPermission} />
            <PublishRow label='Block Permissions:' data={blockPermissions} />
            <PublishRow label='Updated:' data={moment(role.updatedAt).format('MM/DD/YYYY')} />
            <PublishRow
              label='Assigned Users:'
              onClick={onGoUsersPage}
              data={role.assignedUsers || 0}
            />
          </div>
          {hasAccess('admins_roles_create') && (
            <div className='d-flex my-2 justify-conten-between'>
              <button
                disabled={fetching || role.isReadOnly}
                className={`btn btn-sm btn-blue text-orange fz-12 mr-2 fw-600 w-100 d-flex align-items-center justify-content-center ${(fetching ||
                  role.isReadOnly) &&
                  'global_disabled'}`}
                onClick={() => onItemRemoveConfirm(role.title)}
              >
                <IconBin className='mr-2' />
                Delete
              </button>
              <Link
                disabled={fetching || role.isReadOnly}
                to={`/roles/${role.id}/edit`}
                className={`btn btn-sm btn-blue fz-12 fw-600 w-100 ${(fetching ||
                  role.isReadOnly) &&
                  'global_disabled'}`}
              >
                Edit
              </Link>
            </div>
          )}
        </div>
      </div>
      <div className='col'>
        <h5 className='color-blue mb-3 mt-4'>Role Permissions</h5>
        <PermissionsList items={role.rolePermissions} />
      </div>
    </div>
  );
};

export default AdminsRoleView;
