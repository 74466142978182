import React from 'react';
import ButtonLoading from 'shared/components/ButtonLoading';

const FooterButtons = ({ onGoBackStep, onGoNextStep, activeStep, isLastStep, fetching }) => {
  return (
    <div
      className={`col-12 px-4 d-flex ${
        isLastStep ? 'justify-content-center' : 'justify-content-between'
      }`}
    >
      <button
        className='btn btn-blue min-100 fz-12 font-weight-bold py-2'
        type='button'
        onClick={onGoBackStep}
        disabled={activeStep === 0}
      >
        Back
      </button>
      {!isLastStep && (
        <button
          className='btn btn-blue min-100 fz-12 font-weight-bold py-2'
          type='submit'
          onClick={onGoNextStep}
          disabled={fetching}
        >
          {fetching ? <ButtonLoading /> : 'Next'}
        </button>
      )}
    </div>
  );
};

export default FooterButtons;
