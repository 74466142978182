import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import EditingInput from 'shared/components/editingForms/EditingInput';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import WizardImageUpload from 'shared/components/WizardImageUpload';

const EntityNew = ({ history, match }) => {
  const { id } = match.params;
  const { enqueueSnackbar } = useSnackbar();
  const [fetching, setFetching] = useState(false);
  const [form, setForm] = useState({
    name: '',
    address: '',
    uploadImageId: null,
    imageUrl: '',
  });
  const [data, setData] = useState({
    uploadImage: null,
  });

  const getEntityData = async () => {
    if (!id) return;
    try {
      setFetching(true);
      const { data } = await Api.get(`/entity/getentity/${id}`);
      if (data.data.managers && data.data.managers.length) {
        data.data.members = [...data.data.managers, ...(data.data.members || [])];
      }
      if (data?.data) {
        setForm({ ...data?.data });
      }
    } catch (err) {
      history.push(`/entities`);
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetching(false);
    }
  };

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setForm({ ...form, [name]: value });
  };

  const onFormSubmit = async e => {
    e.preventDefault();
    try {
      setFetching(true);
      const endpoint = id ? '/entity/update' : '/entity/addentity';
      const submitForm = id ? { ...form, originId: id } : form;
      await Api.post(endpoint, submitForm);
      enqueueSnackbar('Successfully created', { variant: 'success' });
      history.push('/entities');
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      setFetching(false);
    }
  };

  const onImageUpload = async e => {
    try {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append('file', file);
      formData.append('isdraft', 'true');
      const res = await Api.post('/upload', formData);
      setForm({
        ...form,
        uploadImageId: res?.data?.data?.id || null,
        imageUrl: res?.data?.data?.url || null,
      });
      setData({ uploadImage: res?.data?.data || null });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const entityImg = (data.uploadImage && data.uploadImage.url) || form?.imageUrl;

  useEffect(() => {
    if (id) getEntityData();
  }, [id]);

  return (
    <div>
      <div className='users-header d-flex justify-content-between align-items-center'>
        <div className='d-flex breadcrumbs'>
          <Link to='/entities'>Entities</Link>
          <span className='mx-2'>/</span>
          <span>Add New Entity</span>
        </div>
      </div>
      <form className='py-3 has-header' onSubmit={onFormSubmit}>
        <div className='col-7 user-creation-form'>
          <ul className='p-0 mb-0'>
            <li className='d-flex align-items-center'>
              <div className='col-3 pl-0'>
                <label className='m-0'>Name*:</label>
              </div>
              <EditingInput
                type='text'
                name='name'
                value={form.name}
                onChange={handleChange}
                className='p-0'
                placeholder='Enter Name'
                required
                editing={true}
              />
            </li>
            <li className='d-flex align-items-center'>
              <div className='col-3 pl-0'>
                <label className='m-0'>Address*:</label>
              </div>
              <EditingInput
                type='text'
                name='address'
                value={form.address}
                onChange={handleChange}
                className='p-0'
                placeholder='Enter Address'
                required
                editing={true}
              />
            </li>
            <WizardImageUpload image={entityImg} title='Logo' onImageUpload={onImageUpload} />
          </ul>
          <div className='text-right'>
            <button type='submit' disabled={fetching} className='btn btn-primary px-4 fz-12'>
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EntityNew;
