import React from 'react';
import { IconRight } from './Icons';

const ButtonLine = ({ children, disabled, type, className, onClick }) => (
  <button
    className={`btn btn-line mt-1 ${className || ''}`}
    onClick={onClick}
    type={type}
    disabled={disabled}
  >
    <div className='d-flex align-items-baseline'>
      <span>{children}</span> <IconRight className='ml-2' />
    </div>
  </button>
);

export default ButtonLine;
