import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

const IOSSwitch = withStyles(theme => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
    marginBottom: '0 !important',
    marginLeft: 0,

    '&.MuiSwitch-root': {
      margin: '0 !important',
    },
    '&.Mui-disabled': {
      margin: '100px !important',
    },
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#349A4B',
        opacity: 1,
        border: 'none',
      },
      '& $thumb': {
        backgroundColor: '#349A4B',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
    '&.Mui-disabled $thumb': {
      opacity: 0.2,
    },
  },
  thumb: {
    width: 18,
    height: 18,
    marginTop: 3,
    marginLeft: 4,
    backgroundColor: '#737578',
    border: '3px solid #fff',
  },
  track: {
    borderRadius: 26 / 2,
    backgroundColor: '#737578',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

export const SwitchIOSLike = withStyles({
  label: {
    marginLeft: '12px',
  },
})(({ classes, ...props }) => {
  const { handleChange, checkedState, className, disabled, label, name = 'checkedB' } = props;

  return (
    <FormControlLabel
      className={className}
      classes={{ label: classes.label }}
      control={
        <IOSSwitch
          checked={checkedState}
          onChange={disabled ? () => {} : handleChange}
          name={name}
          disabled={disabled}
        />
      }
      label={label}
    />
  );
});
