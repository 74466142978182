import { useEffect, useRef } from 'react';

const useDidUpdateEffect = (func, deps) => {
  const mounted = useRef();

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      func();
    }
  }, deps);
};

export default useDidUpdateEffect;
