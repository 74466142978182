import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import ReactModal from 'react-modal';
import IconCases from 'assets/course/select_authors.svg';
import CourseAuthorsStepModal from './CourseAuthorsStepModal';
import { getError, reorder } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import DragList from 'shared/components/DragList';
import DragItem from 'shared/components/DragItem';
import CourseAuthorsRow from './CourseAuthorsRow';

const CourseAuthorsStep = ({ data, setData }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [authorsModal, setAuthorsModal] = useState(false);
  const [authors, setAuthors] = useState();

  const getAuthors = async () => {
    try {
      const { data } = await Api.get('/wizard/authors');
      setAuthors(data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onSelectAuthors = items => {
    setData({ ...data, activeAuthors: items });
  };

  const onItemRemove = index => {
    const tempData = data.activeAuthors.splice(index, 1);
    setData({ ...data, activeAuthors: data.activeAuthors.splice(tempData) });
  };

  const onDragEnd = e => {
    if (!e.destination) return;
    const activeAuthors = reorder(data.activeAuthors, e.source.index, e.destination.index);
    setData({ ...data, activeAuthors });
  };

  useEffect(() => {
    getAuthors();
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <div className='px-2'>
        <h6 className='color-blue mb-3'>Manage Faculties</h6>
        <label className='col-7 upload-wizard-block pointer' onClick={() => setAuthorsModal(true)}>
          <div className='d-flex flex-column align-items-center'>
            <img src={IconCases} alt='icon' className='mb-2' />
            Select Faculties
          </div>
        </label>
        <div className='col-8 p-0'>
          <DragList onDragEnd={onDragEnd} items={data.activeAuthors} className='mt-3 mb-5'>
            {(item, index) => {
              return (
                <DragItem
                  key={index}
                  className='case-item with-drag pr-0'
                  onItemRemove={onItemRemove.bind(null, index)}
                >
                  <CourseAuthorsRow item={item} />
                </DragItem>
              );
            }}
          </DragList>
        </div>
      </div>
      <ReactModal isOpen={authorsModal} className='custom-modal'>
        <CourseAuthorsStepModal
          authors={authors}
          activeAuthors={data.activeAuthors}
          onSelectAuthors={onSelectAuthors}
          close={() => setAuthorsModal(false)}
        />
      </ReactModal>
    </>
  );
};

export default CourseAuthorsStep;
