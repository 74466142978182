import React from 'react';
import { Link } from 'react-router-dom';

const RolesHeader = ({ isUpdate }) => {
  return (
    <div className='users-header d-flex justify-content-between align-items-center'>
      <div className='d-flex breadcrumbs'>
        <Link to='/roles/all'>All Roles</Link>
        <span className='mx-2'>/</span>
        <span>{isUpdate ? 'Update Role' : 'Create New Role'}</span>
      </div>
    </div>
  );
};

export default RolesHeader;
