/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import Slider from 'react-slick';
import ResponsiveImage from 'shared/components/ResponsiveImage';
import { getImageUrl } from 'utils/appHelpers';

const EventCME = ({ event }) => {
  if (!event.cmEs || !event.cmEs.length) return null;

  const sliderSettings = {
    speed: 500,
    infinite: true,
    slidesToShow: event.cmEs?.length > 1 ? 2 : 1,
    slidesToScroll: 1,
  };

  return (
    <div className='event-cme' id='cmeSection'>
      <h5 className='py-2 mb-1 color-blue'>CME</h5>
      <div className='event-cme-list'>
        <Slider {...sliderSettings}>
          {event.cmEs &&
            event.cmEs.map(item => {
              return (
                <div key={item.id} className='p-4'>
                  <div className='cme-block d-flex flex-column'>
                    <div className='text-center bg-secondary img-area'>
                      <ResponsiveImage imgUrl={getImageUrl(item.imageUrl)} height='190' alt='o' />
                    </div>
                    <div className='info-area flex-1 d-flex flex-column justify-content-between'>
                      <p className='title '>{item.title}</p>
                      <p className='info'>{item.description}</p>
                    </div>
                  </div>
                </div>
              );
            })}
        </Slider>
      </div>
    </div>
  );
};

export default EventCME;
