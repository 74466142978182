import React from 'react';

const HomeHeader = () => {
  return (
    <div className='users-header d-flex justify-content-between align-items-center'>
      <div className='d-flex breadcrumbs'>
        <span>Dashboard</span>
      </div>
    </div>
  );
};

export default HomeHeader;
