import React from 'react';
import { NavLink } from 'react-router-dom';
import { hasAccess } from 'utils/permissionHelper';

const SubMenu = ({ menu }) => (
  <ul className='sub-menu users mt-2'>
    {menu.map(
      item =>
        hasAccess(item.permission) && (
          <li key={item.path}>
            <NavLink to={item.path} activeClassName='active'>
              {item.name}
            </NavLink>
          </li>
        ),
    )}
  </ul>
);

export default SubMenu;
