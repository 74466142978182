import React from 'react';
import ButtonLoading from 'shared/components/ButtonLoading';

const SurvayCompleteStep = ({
  canSaveDraft,
  fetching,
  onSaveDraft,
  onSaveData,
  form,
  isEditing,
}) => {
  const modeIsDraft = form.mode === 1;
  const buttonText = isEditing ? (modeIsDraft ? 'Save and Publish' : 'Update') : 'Publish';
  return (
    <div className='d-flex flex-column lesson-complete-step text-center'>
      <h4>Survey Setup is Complete</h4>
      <p>
        If you are ready, you can go ahead and <b>Publish</b> the Survey, if not, save <br /> it as
        draft. If you want to make further changes, click <b>Back</b>.
      </p>
      <div>
        {canSaveDraft && (
          <button
            className='btn btn-outline-blue btn-border-2 mr-3 min-100'
            type='button'
            onClick={onSaveDraft}
            disabled={fetching}
          >
            {fetching === 1 ? <ButtonLoading /> : 'Save Draft'}
          </button>
        )}
        <button
          className='btn btn-primary min-100'
          type='button'
          onClick={() => onSaveData()}
          disabled={fetching}
        >
          {fetching === 2 ? <ButtonLoading /> : buttonText}
        </button>
      </div>
    </div>
  );
};

export default SurvayCompleteStep;
