import React, { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import ReactPaginate from 'react-paginate';
import ReactModal from 'react-modal';
import { useSnackbar } from 'notistack';

import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';

import Header from './components/Header';
import Lists from './components/Lists';
import DevicePairModal from './components/DevicePairModal';
import AddDeviceModal from './components/AddDeviceModal';
import { IconArrowLeft, IconArrowRight } from 'shared/components/Icons';
import AdvancedFilters from 'shared/components/filters/AdvancedFilters';
import useFilters from 'shared/hooks/useFilters';

const filterPairState = [{ name: 'Paired', id: true }, { name: 'Unpaired', id: false }];

const initialFilters = () => {
  return { status: '' };
};

const Devices = () => {
  const [removeFetch, setRemoveFetch] = useState(false);
  const [devicePairState, setDevicePairState] = useState(false);
  const [fetch, setFetch] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState([]);
  const [addDeviceState, setAddDeviceState] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [prevSelectedMaster, setPrevSelectedMaster] = useState(null);

  const {
    requestParams,
    filters,
    dropFilters,
    changeFilter,
    changeDropFilter,
    clearDropFilter,
    filterDependency,
    originalState,
  } = useFilters(
    { page: 0, order: false, orderKey: 'createdAt', search: '', limit: 8 },
    { ...initialFilters() },
  );

  const downloadData = [
    {
      title: 'Export as CSV',
      url: `/devices/all`,
      fileName: 'devices_list',
      type: 'csv',
      params: requestParams,
    },
  ];

  const getData = async () => {
    try {
      setFetch(true);
      const { data } = await Api.get(`/devices/all`, { params: requestParams });
      setPageCount(Math.ceil(data.data.count / 8));
      setData(data.data.results);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetch(false);
    }
  };

  const onEditDevice = item => {
    setDevicePairState({
      masterDeviceId: item.id,
      clientDeviceId: item.pairedDevice?.id,
    });
  };

  const onItemRemove = async item => {
    try {
      setRemoveFetch(true);
      await Api.delete(`/devices/${item.id}`);
      enqueueSnackbar('Successfully removed', { variant: 'success' });
      await getData();
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setRemoveFetch(false);
    }
  };

  const onItemRemoveConfirm = item => {
    if (item.pairedDevice) {
      confirmAlert({
        message: `You can't remove the device, the device has a paired device, you need to unpair devices before removing them.`,
        buttons: [{ label: 'OK' }],
      });
      return;
    }

    confirmAlert({
      title: 'Confirmation Required',
      message: `Are you sure you want to remove device?`,
      buttons: [
        {
          label: 'Cancel',
        },
        {
          label: 'Delete',
          onClick: () => onItemRemove(item),
        },
      ],
    });
  };

  const onClearAllFilters = () => {
    clearDropFilter();
  };

  const onFilterChange = (name, id) => {
    changeDropFilter(name, id);
  };

  const onPageChange = ({ selected }) => {
    changeFilter({ page: selected });
  };

  const handleSearchChange = text => {
    changeFilter({ search: text });
  };

  const onChangeOrder = item => {
    if (!item.key) return;
    changeFilter({ order: !filters.order, orderKey: item.key });
  };

  const filtersOptions = [
    {
      type: 'dropdown',
      title: 'Pair State',
      name: 'status',
      data: filterPairState,
      value: dropFilters.status,
      onFilterChange: onFilterChange,
    },
  ];

  const handleAssignPair = selectedDevice => {
    setPrevSelectedMaster({ ...selectedDevice, masterDeviceId: selectedDevice?.id });
    setDevicePairState(prev => ({ ...prev, masterDeviceId: selectedDevice?.id }));
  };

  useEffect(() => {
    getData();
  }, [...filterDependency]);

  return (
    <div>
      <Header
        search={originalState.search}
        onSearch={handleSearchChange}
        createNewDevice={() => setAddDeviceState(true)}
      />
      <div className='py-3 has-header'>
        <AdvancedFilters
          downloadData={downloadData}
          withExport={true}
          filters={filtersOptions}
          onClearAll={onClearAllFilters}
        />
        <div className='col-12'>
          <Lists
            list={data}
            order={filters}
            changeOrder={onChangeOrder}
            fetch={fetch}
            onEditDevice={onEditDevice}
            onItemRemoveConfirm={onItemRemoveConfirm}
            onItemRemoveDisabled={removeFetch}
          />
        </div>
        {pageCount > 1 && !fetch && (
          <ReactPaginate
            previousLabel={<IconArrowLeft />}
            nextLabel={<IconArrowRight />}
            breakLabel={'...'}
            forcePage={Number(filters.page)}
            breakClassName={'break-me'}
            pageCount={pageCount}
            marginPagesDisplayed={3}
            pageRangeDisplayed={3}
            onPageChange={onPageChange}
            containerClassName={`pagination show-arrow`}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
          />
        )}
      </div>
      {!!devicePairState && (
        <ReactModal isOpen={true} className='custom-modal device-pair-modal'>
          <DevicePairModal
            close={() => setDevicePairState(false)}
            onSuccess={getData}
            initialState={devicePairState === true ? null : devicePairState}
            prevSelectedMaster={prevSelectedMaster}
          />
        </ReactModal>
      )}
      {!!addDeviceState && (
        <ReactModal isOpen={true} className='custom-modal device-pair-modal'>
          <AddDeviceModal
            onAssignPair={handleAssignPair}
            close={() => setAddDeviceState(false)}
            onSuccess={getData}
            initialState={addDeviceState === true ? null : addDeviceState}
          />
        </ReactModal>
      )}
    </div>
  );
};

export default Devices;
