/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';
import { useHistory } from 'react-router-dom';
import Loading from 'shared/components/Loading';
import RightInfo from './components/RightInfo';
import ReactModal from 'react-modal';
import UsedDataModal from './components/UsedDataModal';
import DicomSelectionModal from './components/DicomSelectionModal';

const getEmptyFrame = () => {
  return { imageLaterality: '', viewPosition: '', imageType: '', year: '' };
};

const getEmptyStep = (index = 1) => {
  return { step: index, frames: [{ ...getEmptyFrame() }, { ...getEmptyFrame() }] };
};

const ProtocolsView = ({ match, location }) => {
  const history = useHistory();
  const { id } = match.params;
  const sampleProtocolId = location?.state?.sampleId;
  const isEdit = !!match.params.id && !sampleProtocolId;
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState();
  const [showDicomSelectionModal, setShowDicomSelectionModal] = useState(false);
  const [activeStep, setActiveStep] = useState(1);
  const [usedDataModalState, setUsedDataModalState] = useState({ show: false, type: '', data: [] });
  const [form, setForm] = useState({
    name: '',
    steps: [{ ...getEmptyStep(1) }],
  });

  const getProtocol = async () => {
    try {
      const { data } = await Api.get(`/hanging-protocol/${id || sampleProtocolId}`);
      const body = { ...data.data, originId: id };
      setForm(body);
      setData(data.data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const handleShowUsedLessons = () => {
    setUsedDataModalState({
      show: true,
      type: 'lessons',
      data: form.usedInLessons,
    });
  };

  const handleShowUsedEvents = () => {
    setUsedDataModalState({
      show: true,
      type: 'events',
      data: form.usedInEvents,
    });
  };

  const onCaseSelect = cases => {
    if (!cases || !cases.length) return;
    history.push(`/protocol/${id || sampleProtocolId}/preview`, { protocol: data, cases });
  };

  const onCloseModal = () => {
    setUsedDataModalState({ show: false, type: '', data: [] });
  };

  useEffect(() => {
    if (isEdit || sampleProtocolId) getProtocol();
  }, []);

  if (isEdit && !data) return <Loading />;

  const activeStepForms = form.steps.find(i => i.step === activeStep)?.frames;
  const selectBoxClassName = activeStepForms?.length === 4 ? 'col-12' : 'w-48';

  return (
    <div>
      <div className='users-header d-flex justify-content-between align-items-center'>
        <div className='d-flex breadcrumbs'>
          <Link to='/protocols/all'>All Hanging Protocols</Link>
          <span className='mx-2'>/</span>
          <span>{isEdit ? 'Update' : 'Add New'} Hanging Protocol</span>
        </div>
      </div>
      <div className='py-3 has-header'>
        <div className='col-12 user-creation-form'>
          <div className='col-12 p-0 d-flex'>
            <ul className='p-0 mb-0 col-8'>
              <li className='d-flex align-items-center justify-content-between fw-400'>
                <div className='d-flex align-items-center col-10 p-0'>
                  <div className='col-3 pl-0'>
                    <label className='m-0'>Name:</label>
                  </div>
                  {form.name}
                </div>
              </li>
              <li className='d-flex align-items-center justify-content-between fw-400'>
                <div className='d-flex align-items-center col-10 p-0'>
                  <div className='col-3 pl-0'>
                    <label className='m-0'>Used in Lessons:</label>
                  </div>
                  {form.usedInLessons?.length}
                </div>
                <button
                  disabled={!form.usedInLessons?.length}
                  onClick={handleShowUsedLessons}
                  className='btn btn-white btn-sm fw-400 fz-13 rounded-5 color-dark min-100'
                >
                  See Lessons
                </button>
              </li>
              <li className='d-flex align-items-center justify-content-between fw-400'>
                <div className='d-flex align-items-center col-10 p-0'>
                  <div className='col-3 pl-0'>
                    <label className='m-0'>Used in Events:</label>
                  </div>
                  {form.usedInEvents?.length}
                </div>
                <button
                  disabled={!form.usedInEvents?.length}
                  onClick={handleShowUsedEvents}
                  className='btn btn-white btn-sm fw-400 fz-13 rounded-5 color-dark min-100'
                >
                  See Events
                </button>
              </li>
            </ul>
            <div className='col-4'>
              <RightInfo data={data} setShowDicomSelectionModal={setShowDicomSelectionModal} />
            </div>
          </div>
          <div className='protocol-steps is-view d-flex align-items-center'>
            {form.steps.map((item, index) => {
              const isActive = activeStep === item.step;
              return (
                <div
                  className={`protocol-step-box pointer ${isActive ? 'active' : ''}`}
                  onClick={setActiveStep.bind(null, item.step)}
                >
                  {index + 1}
                </div>
              );
            })}
          </div>
          <div className='protocol-frames mt-3 row d-flex'>
            {activeStepForms?.map((item, i) => {
              return (
                <div className='col' key={i}>
                  <p>Frame {i + 1}</p>
                  <div className='example-image'>
                    <img alt='Example' />
                  </div>
                  <div>
                    <ul className='p-0 mb-0 col-12 d-flex flex-wrap justify-content-between'>
                      <li className={`d-flex align-items-center pl-3 ${selectBoxClassName}`}>
                        <div className='p-0'>
                          <label className='m-0 fw-300'>Laterality*:</label>
                        </div>
                        <b className='fw-500 ml-2'>{item.imageLaterality || '-'}</b>
                      </li>
                      <li className={`d-flex align-items-center pl-3 ${selectBoxClassName}`}>
                        <div className='p-0'>
                          <label className='m-0 fw-300'>View Position*:</label>
                        </div>
                        <b className='fw-500 ml-2'>{item.viewPosition || '-'}</b>
                      </li>
                      <li className={`d-flex align-items-center pl-3 ${selectBoxClassName}`}>
                        <div className='p-0'>
                          <label className='m-0 fw-300'>Type*:</label>
                        </div>
                        <b className='fw-500 ml-2'>{item.imageType || '-'}</b>
                      </li>
                      <li className={`d-flex align-items-center pl-3 ${selectBoxClassName}`}>
                        <div className='p-0'>
                          <label className='m-0 fw-300'>Scan Year*:</label>
                        </div>
                        <b className='fw-500 ml-2'>{item.year || '-'}</b>
                      </li>
                    </ul>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {!!usedDataModalState.show && (
        <ReactModal isOpen={true} className='custom-modal used-data-modal'>
          <UsedDataModal onClose={onCloseModal} state={usedDataModalState} />
        </ReactModal>
      )}
      {showDicomSelectionModal && (
        <ReactModal isOpen={true} className='custom-modal'>
          <DicomSelectionModal
            close={() => setShowDicomSelectionModal(false)}
            onSelectCases={onCaseSelect}
          />
        </ReactModal>
      )}
    </div>
  );
};

export default ProtocolsView;
