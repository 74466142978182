import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { confirmAlert } from 'react-confirm-alert';
import { useSnackbar } from 'notistack';

import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';
import { lessonTypes } from 'app/Main/configs';
import useFilters from 'shared/hooks/useFilters';

import { IconArrowLeft, IconArrowRight } from 'shared/components/Icons';
import CoursesHeader from './components/CoursesHeader';
import CoursesLists from './components/CoursesLists';
import AdvancedFilters from 'shared/components/filters/AdvancedFilters';
import useDidUpdateEffect from 'shared/hooks/useDidUpdateEffect';
import { hasAccess } from 'utils/permissionHelper';
import { getIsOnlyHiddenFilter } from 'utils/filtersHelpers';

const filterState = [
  { name: 'Published', id: 2 },
  { name: 'Unpublished', id: 3 },
  { name: 'Draft', id: 1 },
];

const enrolledUsersDownloadData = {
  title: 'Enrolled Users',
  url: 'courses/exportcourses/1',
  type: 'pdf',
  fileName: 'enrolled_users',
};

const statusState = [{ name: 'Used', id: true }, { name: 'Unused', id: false }];

const initialFilters = ({ isMarketingMaterial = [] }) => {
  return {
    faculties: [],
    inuse: '',
    lesson_type: '',
    tags: [],
    mode: '',
    isMarketingMaterial,
  };
};

const Courses = ({ match, history }) => {
  const [authors, setAuthors] = useState([]);
  const [tags, setTags] = useState([]);
  const [removeFetch, setRemoveFetch] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [courses, setCourses] = useState([]);

  const [fetch, setFetch] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  const hasOnlyMarketingCoursesAccess =
    hasAccess('marketing_course_view') && !hasAccess('education_course_view');
  const hasOnlyEducationCoursesAccess =
    !hasAccess('marketing_course_view') && hasAccess('education_course_view');

  const {
    requestParams,
    filters,
    dropFilters,
    changeFilter,
    changeDropFilter,
    clearDropFilter,
    filterDependency,
    originalState,
  } = useFilters(
    {
      page: 0,
      order: false,
      orderKey: 'createdAt',
      search: '',
      limit: 8,
    },
    { ...initialFilters({ isMarketingMaterial: hasOnlyMarketingCoursesAccess ? 1 : [] }) },
  );

  const downloadData = [
    {
      title: 'Export as CSV',
      url: `/courses/getcourses`,
      fileName: 'courses_list',
      type: 'csv',
      params: requestParams,
    },
    enrolledUsersDownloadData,
  ];

  const getCourses = async () => {
    try {
      setFetch(true);
      const { data } = await Api.get(`/courses/getcourses`, { params: requestParams });
      setPageCount(Math.ceil(data.data.count / 8));
      setCourses(data.data.results);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetch(false);
    }
  };

  const onPageChange = ({ selected }) => {
    changeFilter({ page: selected });
  };

  const handleSearchChange = text => {
    changeFilter({ search: text });
  };

  const onChangeOrder = item => {
    if (!item.key) return;
    changeFilter({ order: !filters.order, orderKey: item.key });
  };

  const onItemRemove = async item => {
    try {
      setRemoveFetch(true);
      await Api.delete(`/courses/${item.id}`);
      await getCourses();
      enqueueSnackbar('Successfully removed', { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setRemoveFetch(false);
    }
  };

  const onItemRemoveConfirm = item => {
    if (item?.statistics?.registeredUsers?.total > 0) {
      confirmAlert({
        title: 'Can not be deleted',
        message: 'This Course is in use and can not be deleted now.',
        buttons: [{ label: 'OK' }],
      });
      return;
    }

    confirmAlert({
      title: 'Confirmation Required',
      message: 'Are you sure you want to delete this Course?',
      buttons: [
        {
          label: 'Cancel',
        },
        {
          label: 'Delete',
          onClick: () => onItemRemove(item),
        },
      ],
    });
  };

  const onClearAllFilters = () => {
    clearDropFilter();
    changeDropFilter('isMarketingMaterial', [null]);
  };

  const onFilterChange = (name, id) => {
    changeDropFilter(name, id);
  };

  const getAuthors = async () => {
    try {
      const { data } = await Api.get('/wizard/authors');
      const result = data.map(item => ({
        id: item.authorId,
        name: `${item.firstName} ${item.lastName}`,
      }));
      setAuthors(result);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const getTags = async () => {
    try {
      const { data } = await Api.get('/tag/tags', { params: { limit: 1000 } });
      const tagsList = data.data.results.map(item => ({ name: item.name, id: item.id }));
      setTags(tagsList);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const filtersOptions = [
    {
      type: 'multiDropdown',
      title: 'Faculty',
      name: 'faculties',
      data: authors,
      value: dropFilters.faculties,
      onFilterChange: onFilterChange,
    },
    {
      type: 'dropdown',
      title: 'State',
      name: 'mode',
      data: filterState,
      value: dropFilters.mode,
      onFilterChange: onFilterChange,
    },
    {
      type: 'dropdown',
      title: 'Status',
      name: 'inuse',
      data: statusState,
      value: dropFilters.inuse,
      onFilterChange: onFilterChange,
    },
    {
      type: 'dropdown',
      title: 'Lesson Type',
      name: 'lesson_type',
      data: lessonTypes,
      value: dropFilters.lesson_type,
      onFilterChange: onFilterChange,
    },
    {
      type: 'multiDropdown',
      title: 'Tags',
      name: 'tags',
      data: tags,
      value: dropFilters.tags,
      onFilterChange: onFilterChange,
    },
    {
      type: 'multiDropdown',
      title: 'Marketing Type',
      name: 'isMarketingMaterial',
      forceLabel: 'Show Marketing Materials',
      hide: !hasAccess('marketing_course_view') && hasAccess('education_course_view'),
      disabled: hasOnlyMarketingCoursesAccess,
      withoutSearch: true,
      data: [{ name: 'isMarketingMaterial', id: 1, forceLabel: 'Show Marketing Materials' }],
      value: dropFilters.isMarketingMaterial,
      onFilterChange: onFilterChange,
    },
  ];

  const isOnlyHiddenFilters = getIsOnlyHiddenFilter({
    filtersOptions,
    hiddenOptionsNames: ['isMarketingMaterial'],
  });

  useDidUpdateEffect(() => {
    getCourses();
  }, [...filterDependency]);

  useEffect(() => {
    if (hasAccess('authors_list_view')) {
      getAuthors();
    }
    getTags();
  }, []);

  useEffect(() => {
    if (hasOnlyMarketingCoursesAccess) changeDropFilter('isMarketingMaterial', [1]);
    if (hasOnlyEducationCoursesAccess) changeDropFilter('isMarketingMaterial', [null]);
  }, [hasOnlyEducationCoursesAccess, hasOnlyMarketingCoursesAccess]);

  return (
    <div>
      <CoursesHeader search={originalState.search} onSearch={handleSearchChange} />
      <div className='py-3 has-header'>
        <AdvancedFilters
          withExport
          hideFilterDetails={isOnlyHiddenFilters}
          downloadData={downloadData}
          filters={filtersOptions}
          onClearAll={onClearAllFilters}
        />
        <div className='col-12'>
          <CoursesLists
            list={courses}
            order={filters}
            changeOrder={onChangeOrder}
            fetch={fetch}
            onItemRemoveConfirm={onItemRemoveConfirm}
            onItemRemoveDisabled={removeFetch}
          />
        </div>
        {pageCount > 1 && !fetch && (
          <ReactPaginate
            previousLabel={<IconArrowLeft />}
            nextLabel={<IconArrowRight />}
            breakLabel={'...'}
            forcePage={Number(filters.page)}
            breakClassName={'break-me'}
            pageCount={pageCount}
            marginPagesDisplayed={3}
            pageRangeDisplayed={3}
            onPageChange={onPageChange}
            containerClassName={`pagination show-arrow`}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
          />
        )}
      </div>
    </div>
  );
};

export default Courses;
