import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { IconRight } from './Icons';

const LinkIcon = ({ children, to, className, onClick }) => (
  <Link to={to} className={className} onClick={onClick}>
    {children} <IconRight color='#131f6b' className='ml-1' />
  </Link>
);

export default memo(LinkIcon);
