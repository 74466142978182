import React, { useState } from 'react';
import InputGroup from 'shared/components/InputGroup';
import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';
import AuthBlock from '../components/AuthBlock';
import { IconPassword } from 'shared/components/Icons';
import SuccessInfo from '../components/SuccessInfo';
import Button from 'shared/components/Button';

const Reset = ({ match }) => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState();
  const [fetch, setFetch] = useState();
  const [pass, setPass] = useState({
    password: '',
    rpassword: '',
  });

  const authOptions = {
    title: 'New password',
    subTitle: 'Please enter new password.',
    links: { signup: true },
  };

  const passwordMatch = pass.password === pass.rpassword;

  const handleChange = ({ target }) => {
    const { name, value } = target;
    const tempAuth = { ...pass };
    tempAuth[name] = value;
    setPass(tempAuth);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setFetch(true);
    try {
      const body = {
        resetToken: match.params.code,
        password: pass.password,
      };
      await Api.post(`/auth/reset-password`, body);
      setSuccess(true);
    } catch (err) {
      setError(getError(err));
    } finally {
      setFetch(false);
    }
  };

  return (
    <AuthBlock
      {...authOptions}
      successBlock={
        success ? (
          <SuccessInfo
            title='Reset Successfully'
            subTitle='Please enter your email, so we can send you a code to reset your password'
            subTitleClassName=''
          />
        ) : null
      }
    >
      <form onSubmit={handleSubmit} className='mt-5'>
        <InputGroup
          type='password'
          name='password'
          onChange={handleChange}
          className='mb-4'
          placeholder='Password'
          value={pass.password}
          icon={<IconPassword />}
          autoFocus
          required
        />
        <InputGroup
          type='password'
          name='rpassword'
          onChange={handleChange}
          className='mb-4'
          placeholder='Confirm password'
          value={pass.rpassword}
          icon={<IconPassword />}
          required
        />
        {error && <div className='error-text'>{error}</div>}
        {!passwordMatch && pass.rpassword && <div className='error-text'>Passwords mismatch</div>}
        <Button
          className='btn btn-blue-dark w-100'
          type='submit'
          disabled={fetch || !passwordMatch}
        >
          Submit
        </Button>
      </form>
    </AuthBlock>
  );
};

export default Reset;
