import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { getError, imageUploadProgress } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import { infoKeys, makeDeIdentifiedValue } from './parser';
import UploadProgress from 'shared/components/UploadProgress';
import IconCases from 'assets/wizard/select_cases.svg';
import dicomParser from 'dicom-parser';
import removeIcon from 'assets/bin.svg';
import Loading from 'shared/components/Loading';
import { useSelector } from 'react-redux';
import { removeCaseFile, setCaseFiles, setCaseFilesProgress, startDownloading } from './actions';
import UploadInProgress from './components/UploadInProgress';
import { IconCheck, IconWarning } from 'shared/components/Icons';

const AccessionIdKey = 'x00080050';

const InfoBlock = ({ children, title }) => (
  <div className='card mb-3 dicom-info-block'>
    <div className='card-header'>{title}</div>
    <div className='card-body p-2 d-flex flex-wrap'>{children}</div>
  </div>
);

const InfoLine = ({ name, value }) => (
  <div className='col-6 px-1 border-right border-bottom d-flex justify-content-between'>
    <div className='col-4 p-0'>{name}:</div>
    <div className='col-4 p-0'>
      <span>{value?.original}</span>
    </div>
    <div className='col-4 p-0 text-info'>
      <span>{value?.decode}</span>
    </div>
  </div>
);

const WarehouseCasesNew = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [activeIndex, setActiveIndex] = useState(0);
  const [parsedAllData, setParsedData] = useState();
  const [dicomAllData, setDicomData] = useState();
  const [filesExist, setFilesExist] = useState([]);
  const uploader = useSelector(state => state.uploader);
  const files = uploader?.files;
  const uploadProgress = uploader?.progress;

  const parsedData = parsedAllData?.[files?.[activeIndex]?.name];
  const dicomData = dicomAllData?.[files?.[activeIndex]?.name];

  const setFiles = items => {
    setCaseFiles(items);
  };

  const parseByteArray = (fileName, byteArray) => {
    try {
      const dataSet = dicomParser.parseDicom(byteArray);
      const result = {};
      Object.keys(infoKeys).forEach(key => {
        const original = dataSet.string(key);
        if (!original) return;
        const decode = makeDeIdentifiedValue(original.length, infoKeys[key]);
        result[key] = { original, decode };
        const element = dataSet.elements[key];
        for (var i = 0; i < element.length; i++) {
          const text = decode || original;
          const char = text.length > i ? text.charCodeAt(i) : 32;
          dataSet.byteArray[element.dataOffset + i] = char;
        }
      });

      if (!result[AccessionIdKey]) {
        enqueueSnackbar('Accession Number is missing in file headers.', { variant: 'error' });
      }

      setParsedData(state => ({ ...state, [fileName]: result }));
      setDicomData(state => ({ ...state, [fileName]: dataSet }));
    } catch (err) {
      enqueueSnackbar('Not supported format of  DICOM file', { variant: 'error' });
      setFiles();
    }
  };

  const loadFile = file => {
    const reader = new FileReader();
    reader.onload = function() {
      const arrayBuffer = reader.result;
      const byteArray = new Uint8Array(arrayBuffer);
      parseByteArray(file.name, byteArray);
    };
    reader.readAsArrayBuffer(file);
  };

  const checkFilesExist = async files => {
    try {
      const fileNames = [...files].map(i => i.name);
      const { data } = await Api.post('/dicom/check-list', fileNames);
      setFilesExist(data.data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onFileSelect = async e => {
    try {
      const files = [...e.target.files];
      files.forEach((file, i) => {
        loadFile(file, i);
      });
      checkFilesExist(files);
      setFiles(files);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const startUploadDicom = async file => {
    if (filesExist.includes(file?.name)) {
      const files = onRemoveFile(file.name);
      if (files?.length) {
        startUploadDicom(files[0]);
      } else {
        startDownloading(false);
      }
      return;
    }

    const config = {
      onUploadProgress: imageUploadProgress.bind(null, percent => {
        setCaseFilesProgress(file.name, percent);
      }),
    };
    try {
      startDownloading(true);
      const blob = new Blob([dicomAllData[file.name].byteArray], { type: 'application/dicom' });
      const newFile = new File([blob], file.name, { type: 'application/dicom' });
      const formData = new FormData();
      formData.append('file', newFile);
      formData.append('isdraft', 'false');
      await Api.post('/upload', formData, config);
      enqueueSnackbar(`Successfully uploaded - ${file.name}`, { variant: 'success' });
      const files = onRemoveFile(file.name);
      if (files?.length) {
        startUploadDicom(files[0]);
      } else {
        startDownloading(false);
      }
    } catch (err) {
      startDownloading(false);
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setCaseFilesProgress(file.name, null);
    }
  };

  const startUploadDicoms = async e => {
    startUploadDicom(files[0]);
  };

  const onRemoveFile = (fileName, e) => {
    e?.stopPropagation();
    e?.preventDefault();
    const files = removeCaseFile(fileName);
    if (!files.length) {
      setParsedData();
    }
    const newIndex = activeIndex - 1;
    setActiveIndex(newIndex < 0 ? 0 : newIndex);
    setCaseFilesProgress(file.name, null);
    return files;
  };

  const file = files?.[activeIndex];

  useEffect(() => {
    if (files?.length && !dicomData) {
      files.forEach((file, i) => {
        loadFile(file, i);
      });
    }
  }, []);

  const isFetching = !!uploadProgress?.[file?.name];
  const uploadingStarted = !!uploader?.downloadStarted;
  const hasFiles = !!files.length;
  const fileExist = filesExist.includes(file?.name);

  if (uploadingStarted) return <UploadInProgress filesExist={filesExist} />;

  return (
    <div>
      <div className='users-header d-flex justify-content-between align-items-center'>
        <div className='d-flex breadcrumbs'>
          <span>Upload New DICOM</span>
        </div>
      </div>
      <div className='has-header'>
        <div className='d-flex mb-2'>
          {!!files?.length &&
            files.map((file, i) => {
              const isActive = activeIndex === i;
              const isFileExist = filesExist.includes(file?.name);
              return (
                <button
                  className={`btn btn-sm d-flex align-items-center fz-12 m-2 ${
                    isActive ? 'btn-primary' : 'btn-outline-blue'
                  }`}
                  key={i}
                  onClick={() => setActiveIndex(i)}
                >
                  {isFileExist ? <IconWarning /> : <IconCheck color='#28a745' />}
                  <span className='max-w-250 text-elips ml-2'>{file.name}</span>
                  {!isFetching && (
                    <div
                      onClick={onRemoveFile.bind(null, file.name)}
                      tabIndex='-1'
                      role='button'
                      disabled={isFetching}
                      className={`btn btn-sm d-flex  p-1 ml-2 justify-content-center align-items-center ${
                        isActive ? '' : 'btn-red'
                      }`}
                    >
                      <img width='10' src={removeIcon} alt='icon' />
                    </div>
                  )}
                  {isFetching && (
                    <Loading className='upload-spinner ml-2' classSpinner='spinner-border-sm' />
                  )}
                </button>
              );
            })}
        </div>
        <div className='d-flex'>
          <div className='px-2 col-7' key={files?.length}>
            <label className={`col-12 upload-wizard-block ${file ? '' : 'pointer'}`} htmlFor='file'>
              <input
                id='file'
                name='file'
                type='file'
                accept='.dcm'
                className='flex-fill hide'
                multiple='multiple'
                onChange={onFileSelect}
                disabled={file}
              />
              <div className='d-flex flex-column align-items-center'>
                <img src={IconCases} alt='icon' />
                {uploadProgress?.[file?.name] && (
                  <UploadProgress progress={uploadProgress?.[file?.name]} />
                )}
                {!file && 'Select Files'}
                {file && <span className='mr-2'>{file?.name}</span>}
                {fileExist && (
                  <p className='text-danger text-center px-4 mt-2'>
                    This file can not be uploaded, you should first delete the existing DICOM to be
                    able to upload a new one.
                  </p>
                )}
              </div>
            </label>
          </div>
          {dicomData && parsedData && hasFiles && (
            <div className='col-5'>
              <p className='text-lg font-weight-normal'>
                Dicom data headers are anonymized. Check anonymization results by navigating through
                DICOM tabs. Press Upload when ready.
              </p>
              <button
                className='btn btn-success'
                onClick={startUploadDicoms}
                disabled={
                  isFetching || !parsedData[AccessionIdKey] || (files?.length === 1 && fileExist)
                }
              >
                Upload Anonymized DICOMs
              </button>
            </div>
          )}
        </div>
        {parsedData && hasFiles && (
          <div className='px-2'>
            <InfoBlock title='Patient Information'>
              <InfoLine name='Patient Name' value={parsedData['x00100010']} />
              <InfoLine name='Patient ID' value={parsedData['x00100020']} />
              <InfoLine name='Patient Birth Date' value={parsedData['x00100030']} />
              <InfoLine name='Patient Sex' value={parsedData['x00100040']} />
            </InfoBlock>
            <InfoBlock title='Study Information'>
              <InfoLine name='Study Description' value={parsedData['x00081030']} />
              <InfoLine name='Protocol Name' value={parsedData['x00181030']} />
              <InfoLine name='Accession #' value={parsedData[AccessionIdKey]} />
              <InfoLine name='Study Id' value={parsedData['x00200010']} />
              <InfoLine name='Study Date' value={parsedData['x00080020']} />
              <InfoLine name='Study Time' value={parsedData['x00080030']} />
            </InfoBlock>
            <InfoBlock title='Series Information'>
              <InfoLine name='Series Description' value={parsedData['x0008103e']} />
              <InfoLine name='Series #' value={parsedData['x00200011']} />
              <InfoLine name='Modality' value={parsedData['x00080060']} />
              <InfoLine name='Body Part' value={parsedData['x00180015']} />
              <InfoLine name='Series Date' value={parsedData['x00080021']} />
              <InfoLine name='Series Time' value={parsedData['x00080031']} />
            </InfoBlock>
          </div>
        )}
      </div>
    </div>
  );
};

export default WarehouseCasesNew;
