import React from 'react';

const TagsViewBlock = ({ className, tags, title = 'Tags' }) => (
  <div className={`tags-view-block ${className || ''}`}>
    <h1>{title}</h1>
    <div className='d-flex flex-wrap tag-wrap'>
      {tags && tags?.length ? (
        tags.map((item, i) => {
          const tag = item.tag || item;
          return (
            <div className='tag-item' key={i}>
              {tag.label || tag.name}
            </div>
          );
        })
      ) : (
        <p className='description'>No tags are chosen for this course yet.</p>
      )}
    </div>
  </div>
);

export default TagsViewBlock;
