import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import store from './redux/store';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'scss/app.scss';
import App from './app';
import ReactModal from 'react-modal';
import 'react-confirm-alert/src/react-confirm-alert.css';

ReactModal.setAppElement('#root');
const snackOpts = { vertical: 'top', horizontal: 'right' };

render(
  <Provider store={store}>
    <SnackbarProvider maxSnack={3} anchorOrigin={snackOpts} autoHideDuration={3000}>
      <App />
    </SnackbarProvider>
  </Provider>,
  document.getElementById('root'),
);
