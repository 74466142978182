import React, { useEffect, useState } from 'react';
import Loading from 'shared/components/Loading';
import { Api } from 'utils/connectors';
import { useSnackbar } from 'notistack';
import { getError } from 'utils/appHelpers';
import ProtocolViewer from './components/ProtocolViewer';
import BackButton from 'shared/components/BackButton';

const ProtocolsPreview = ({ location, match, history }) => {
  const { id } = match.params;
  const { enqueueSnackbar } = useSnackbar();
  const [protocol, setProtocol] = useState(location.state?.protocol);
  const cases = location.state?.cases;

  const getProtocol = async () => {
    try {
      const { data } = await Api.get(`/hanging-protocol/${id}`);
      setProtocol(data.data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onBackClick = () => {
    history.goBack();
  };

  useEffect(() => {
    if (!protocol) {
      getProtocol();
    }
  }, []);

  if (!protocol) return <Loading />;

  return (
    <div className='course-view flex-1 d-flex flex-column position-relative'>
      <div className='header-section align-items-center d-flex w-100 px-4 justify-content-between'>
        <div className='d-flex align-items-center'>
          <div className='course-name border-0 pr-4 text-two-line d-flex align-items-center'>
            <BackButton onClick={onBackClick} className='mr-2' />
            (Preview) {protocol.name}
          </div>
        </div>
        <div className='d-flex align-items-center'>
          <button
            onClick={onBackClick}
            type='button'
            className='btn btn-md btn-outline-blue font-weight-bold btn-border-2'
          >
            Close
          </button>
        </div>
      </div>
      <ProtocolViewer protocol={protocol} cases={cases} />
    </div>
  );
};

export default ProtocolsPreview;
