import React from 'react';
import { Link } from 'react-router-dom';
import { IconSearch } from 'shared/components/Icons';
import { getError, saveFile } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import moment from 'moment';

const CourseUsersHeader = ({ course, onSearch, enqueueSnackbar }) => {
  const exportListClick = async item => {
    try {
      const { data } = await Api.get(`courses/exportcourses/1?courseId=${course.id}`, {
        responseType: 'blob',
        timeout: 30000,
      });
      const date = moment().format('YYYYMMDDHHmmss');
      const fileName = `completed_users_${date}.pdf`;
      await saveFile(data, fileName);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  return (
    <div className='users-header d-flex justify-content-between align-items-center'>
      <div className='d-flex breadcrumbs'>
        <Link to='/courses'>All Courses</Link>
        <span className='mx-2'>/</span>
        <Link to={`/course/${course.id}/view`}>{course.title}</Link>
        <span className='mx-2'>/</span>
        <span>Engaged Users</span>
      </div>
      <div className='d-flex'>
        <button onClick={exportListClick} className='btn p-0 mr-4'>
          Export
        </button>
        <div className='search-block'>
          <input
            type='text'
            onChange={({ target }) => onSearch(target.value)}
            className='form-control'
          />
          <IconSearch />
        </div>
      </div>
    </div>
  );
};

export default CourseUsersHeader;
