import React, { useEffect, useState } from 'react';
import { Api } from 'utils/connectors';
import ReportHeader from './components/ReportHeader';
import { useSnackbar } from 'notistack';
import { getError } from 'utils/appHelpers';
import Lists from './components/Lists';
import ReactPaginate from 'react-paginate';
import { IconArrowLeft, IconArrowRight } from 'shared/components/Icons';

const Reports = ({ match }) => {
  const [order, setOrder] = useState({ order: true, orderKey: '' });
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [fetch, setFetch] = useState(false);
  const [data, setData] = useState([]);
  const { type } = match.params;

  const getReportData = async () => {
    try {
      setFetch(true);
      const params = {
        ...order,
        page: page + 1,
        limit: 8,
      };
      const { data } = await Api.get(`/report/${type}`, { params });
      setPageCount(Math.ceil(data.data.count / 8));
      setData(data.data.results);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetch(false);
    }
  };

  const onPageChange = ({ selected }) => {
    setPage(selected);
  };

  useEffect(() => {
    setPage(0);
    setOrder({ order: true, orderKey: '' });
  }, [type]);

  useEffect(() => {
    getReportData();
  }, [order, page]);

  return (
    <div>
      <ReportHeader type={type} />
      <div className='has-header list-with-scroll'>
        <div className='col-12'>
          <Lists data={data} type={type} order={order} changeOrder={setOrder} />
        </div>
        {pageCount > 1 && !fetch && (
          <ReactPaginate
            previousLabel={<IconArrowLeft />}
            nextLabel={<IconArrowRight />}
            breakLabel={'...'}
            forcePage={page}
            breakClassName={'break-me'}
            pageCount={pageCount}
            marginPagesDisplayed={3}
            pageRangeDisplayed={3}
            onPageChange={onPageChange}
            containerClassName={`pagination show-arrow`}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
          />
        )}
      </div>
    </div>
  );
};

export default Reports;
