import React, { useEffect, useState } from 'react';
import { getError } from 'utils/appHelpers';
import Header from './components/Header';
import ReportForm from './components/ReportForm';
import { useSnackbar } from 'notistack';
import { Api } from 'utils/connectors';

const ReportsManage = ({ match }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [report, setReport] = useState();
  const { type, id } = match.params;
  const isEdit = !!match.params.id;

  const getReportData = async () => {
    try {
      const { data } = await Api.get(`/report/${type}/${id}`);
      setReport(data.data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  useEffect(() => {
    if (isEdit) getReportData();
    else setReport({});
  }, []);

  return (
    <div>
      <Header />
      <div className='col mt-2 pb-5 has-header'>{report && <ReportForm report={report} />}</div>
    </div>
  );
};

export default ReportsManage;
