import React, { useState, useEffect } from 'react';
import { Api } from 'utils/connectors';
import { courseTags } from 'configs';
import { useSnackbar } from 'notistack';
import { formatDate, getError } from 'utils/appHelpers';
import { courseModes } from 'app/Main/configs';
import Loading from 'shared/components/Loading';
import LessonViewHeader from './components/LessonViewHeader';
import PublishPreview from './components/PublishPreview';
import TagsViewBlock from '../CoursePreview/components/TagsViewBlock';
import CasesContent from './components/CasesContent';
import VideoContent from './components/VideoContent';
import ImageContent from './components/ImageContent';
import PDFContent from './components/PDFContent';

const LessonsView = ({ match, history }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState();

  const getLessonData = async () => {
    try {
      const { data } = await Api.get(`/courses/lesson/${match.params.id}`);
      if (data?.data) setData(data.data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      history.push('/lessons/all');
    }
  };

  useEffect(() => {
    getLessonData();
  }, [match.params.id]);

  if (!data) return <Loading />;

  const adminTags = data.adminTags;

  return (
    <div>
      <LessonViewHeader title={data.title} mode={data.mode} />
      <div className='d-flex has-header'>
        <div className='col-9'>
          <div className='px-1'>
            <h5 className='color-blue'>{data.title}</h5>
            {data.info && (
              <p>
                <span className='d-block mb-2 text-lg text-blue fw-400'>Lesson Description</span>
                <span className='d-block white-space-pre text-black'>{data.info}</span>
              </p>
            )}
            {!!data.usage?.length && (
              <div className='my-4'>
                <p className='text-blue text-lg mb-0 fw-700'>Courses</p>
                <div>
                  <table className='table table-custom'>
                    <thead>
                      <tr>
                        <th scope='col' className='p-1'>
                          Courses
                        </th>
                        <th scope='col'>Faculty</th>
                        <th scope='col'>Status</th>
                        <th scope='col'>Registered</th>
                        <th scope='col'>Completed</th>
                        <th scope='col'>Date Added</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.usage &&
                        data.usage.map((item, i) => {
                          return (
                            <tr key={i}>
                              <td>{item.title}</td>
                              <td className='text-nowrap'>{item.faculty}</td>
                              <td className='text-nowrap'>{courseModes[item.status]}</td>
                              <td className='text-nowrap'>
                                {item?.statistics?.registeredUsers?.total}
                              </td>
                              <td className='text-nowrap'>
                                {item?.statistics?.completedUsers?.total}
                              </td>
                              <td className='text-nowrap'>{formatDate(item.dateAdded)}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
            {data.contentType === 1 && <VideoContent data={data} />}
            {data.contentType === 2 && <PDFContent data={data} />}
            {data.contentType === 3 && <CasesContent data={data} />}
            {data.contentType === 4 && <ImageContent data={data} />}
          </div>
        </div>
        <div className='col-3 py-3'>
          <PublishPreview lesson={data} imageUrl={data.imageUrl} getLessonData={getLessonData} />
          <TagsViewBlock className='course-view-tags' title='Admin Tags' tags={adminTags} />
        </div>
      </div>
    </div>
  );
};

export default LessonsView;
