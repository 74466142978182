/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';
import { RemoveTypeCN } from 'shared/components/Icons';
import Button from 'shared/components/Button';
import ResponsiveImage from 'shared/components/ResponsiveImage';

export const SortableImgItem = props => {
  const {
    id,
    url,
    activeItemId,
    overItemId,
    size = 'medium',
    handleSelectItem = () => {},
    isSelected,
    handleRemoveItem = () => {},
  } = props;
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      onClick={() => handleSelectItem(id)}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    >
      <div
        className={`sortable_img_item ${size} ${overItemId === id && 'ml_130'} ${activeItemId ===
          id && 'active'}`}
      >
        {isSelected && (
          <Button
            onClick={e => {
              e.stopPropagation();
              handleRemoveItem(id);
            }}
            className='btn_transparent item_remove_icon'
          >
            <RemoveTypeCN />
          </Button>
        )}
        {activeItemId !== id && <ResponsiveImage imgUrl={url} alt={`uncategorized ${id}`} />}
      </div>
    </div>
  );
};
