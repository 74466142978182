import React from 'react';

const Select = ({
  name,
  value,
  onChange,
  onBlur,
  label,
  items,
  className,
  required,
  parentClassName,
  useProperty,
  displayProperty,
  hideDefault,
  disabled,
  emptyValueClassName = '',
  disabledType,
  lightGrayLabel,
}) => {
  const isValueFalsy = !value && value !== 0;
  return (
    <div className={`select-box col p-0 ${parentClassName || ''} disabled_bg_${disabledType}`}>
      <select
        onChange={onChange}
        className={`custom-select ${isValueFalsy && emptyValueClassName} ${className ||
          ''} ${lightGrayLabel && !value && 'light-gray'}`}
        onBlur={onBlur}
        value={value}
        name={name}
        id={name}
        required={required}
        autoComplete='off'
        disabled={disabled}
      >
        {!hideDefault && <option value=''>{label || 'Choose...'}</option>}
        {items &&
          items.length &&
          items.map((item, index) => (
            <option
              disabled={item.disabled}
              key={item[displayProperty] + index}
              value={item[useProperty] || item.id}
            >
              {item[displayProperty]}
            </option>
          ))}
      </select>
    </div>
  );
};

export default Select;
