/* eslint-disable eqeqeq */
import React from 'react';
import ClickArea from 'shared/components/ClickArea';
import Loading from 'shared/components/Loading';
import ListActionButtons from 'shared/components/ListActionButtons';
import { hasAccess } from 'utils/permissionHelper';
import { PERMISSION_ACCESS_LEVELS } from 'utils/permissions';

const headers = [
  {
    name: 'Role Name',
    className: 'col-2',
    key: 'title',
  },
  {
    name: 'Description',
    className: 'col-3',
    key: 'description',
  },
  {
    name: 'Permissions',
    className: 'col-3',
    key: 'rolePermissions',
  },
  {
    name: 'Used',
    className: 'col',
    key: 'userRole',
  },
  {
    name: 'Actions',
    className: 'p-2 d-flex min_132',
    key: '',
  },
];

const RolesLists = ({
  roles,
  order,
  changeOrder,
  fetch,
  onItemRemoveConfirm,
  onItemRemoveDisabled,
}) => {
  if (fetch) return <Loading className='mt-5' />;

  if (!roles || !roles.length)
    return <div className='text-black-50 text-center mt-4'>No Results Found</div>;

  const handleOrderChange = item => {
    if (!item.key) return;
    changeOrder({
      orderKey: item.key,
      order: !order.order,
    });
  };

  const actions = [
    { type: 'link', icon: 'view', title: 'View', to: item => `/roles/${item.id}/view` },
    {
      type: 'link',
      icon: 'edit',
      title: 'Edit',
      to: item => `/roles/${item.id}/edit`,
      disable: item => item.isReadOnly || !hasAccess('admins_roles_create'),
      hide: () => !hasAccess('admins_roles_create'),
    },
    {
      type: 'link',
      icon: 'copyAndCreate',
      title: 'Copy & Create',
      to: () => `/roles/new`,
      hide: () => !hasAccess('admins_roles_create'),
    },
    {
      type: 'button',
      icon: 'delete',
      title: 'Remove',
      disabled: onItemRemoveDisabled,
      disable: item => item.isReadOnly || !hasAccess('admins_roles_create'),
      onClick: item => onItemRemoveConfirm(item),
    },
  ];

  return (
    <div className='users-lists'>
      <ul className='p-0'>
        <li className='d-flex list-header align-items-center'>
          {headers.map((item, index) => (
            <ClickArea
              key={item.name + index}
              className={`text-body ${
                item.key ? 'justify-content-between d-flex align-items-center' : ''
              } ${item.className}`}
              onClick={handleOrderChange.bind(null, item)}
            >
              {item.name}
              {item.key && order.orderKey === item.key && (
                <button
                  className={`btn ml-2 p-0 dropdown-toggle ${order.order ? '' : 'rotate-180'}`}
                />
              )}
            </ClickArea>
          ))}
        </li>
        {roles &&
          roles.map(item => {
            const viewRolesCount = item.rolePermissions.filter(
              role => role.accessLevel === PERMISSION_ACCESS_LEVELS.View,
            ).length;
            const editRolesCount = item.rolePermissions.filter(
              role => role.accessLevel === PERMISSION_ACCESS_LEVELS.Edit,
            ).length;
            return (
              <li key={item.id} className='d-flex align-items-center list-item h-not-set'>
                <div className='col-2'>{item.title}</div>
                <div className='col-3'>{item.description}</div>
                <div className='col-3'>
                  View: {viewRolesCount}, Edit: {editRolesCount}{' '}
                </div>
                <div className='col'>{item.assignedUsers || 0} Users</div>
                <div className='p-2 d-flex min_132 align-items-center'>
                  <ListActionButtons actions={actions} item={item} />
                </div>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default RolesLists;
