import React from 'react';
import { useState } from 'react';
import AppChart from 'shared/components/Chart';
import Loading from 'shared/components/Loading';
import { IconArrowDown, IconArrowUp } from 'shared/components/Icons';

const BlockUserStatuses = ({ data, isTotal, fetching }) => {
  const [openState, setOpenState] = useState(true);
  const labels = isTotal
    ? ['Active', 'Inactive', 'Pending']
    : ['Completed', 'In Progress', 'Not Started'];
  const dataArr = isTotal
    ? [data.active, data.inactive, data.pending]
    : [data.completed, data.inProgress, data.notStarted];
  const total = dataArr.reduce((a, b) => a + b, 0);

  const props = {
    id: 'statusesChart',
    data: dataArr,
    labels: labels,
  };

  return (
    <div className={`dash-block block-user-statuses w-100  ${openState ? 'opened' : 'closed'}`}>
      <div className='align-items-center block-title d-flex justify-content-between'>
        {isTotal ? 'User Status' : 'Course Status'}
        <button className='btn p-0' onClick={() => setOpenState(state => !state)}>
          {openState ? <IconArrowDown /> : <IconArrowUp />}
        </button>
      </div>
      <div className='my-5'>
        {!fetching && (
          <div className='w-100'>
            <div className='d-flex align-items-center'>
              <div className='col-5 p-0 doughnut-chart'>
                <AppChart {...props} />
                <h4 className='center-text text-center'>
                  Total <br />
                  {total}
                </h4>
              </div>
              <div className='col-7 pr-0 d-flex flex-column justify-content-between'>
                <div className='d-flex justify-content-center'>
                  <ul className='col-9 mb-0 pl-3 status-explanation-list font-size-13'>
                    {labels.map((item, index) => (
                      <li key={item + index}>
                        <span className='d-block text-truncate d-flex justify-content-between'>
                          {item} <span className='font-weight-bold'>{dataArr[index]}</span>
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}
        {fetching && <Loading className='mt-5' />}
      </div>
    </div>
  );
};

export default BlockUserStatuses;
