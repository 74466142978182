import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';
import Header from './components/Header';
import certificateImage from 'assets/certificate-item.png';
import Loading from 'shared/components/Loading';
import moment from 'moment';
import removeIcon from 'assets/bin.svg';
import { IconEditButton } from 'shared/components/Icons';
import { confirmAlert } from 'react-confirm-alert';
import { hasAccess } from 'utils/permissionHelper';

const Certificates = ({ history }) => {
  const [fetch, setFetch] = useState(false);
  const [removeFetch, setRemoveFetch] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState([]);

  const getData = async () => {
    try {
      setFetch(true);
      const { data } = await Api.get(`/wizard/certificates`);
      setData(data.data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetch(false);
    }
  };

  const onItemEdit = id => {
    history.push(`/certificate/${id}`);
  };

  const onItemRemove = async id => {
    try {
      setRemoveFetch(true);
      await Api.delete(`/wizard/certificate/${id}`);
      await getData();
      enqueueSnackbar('Successfully removed', { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setRemoveFetch(false);
    }
  };

  const onItemRemoveConfirm = item => {
    if (item.usedCourses > 0) {
      confirmAlert({
        message: 'The certificate is used in course(s).',
        buttons: [{ label: 'OK' }],
      });
      return;
    }

    confirmAlert({
      title: 'Certificate Removal',
      message: 'Are you sure you want to remove the certificate?',
      buttons: [
        {
          label: 'CANCEL',
        },
        {
          label: 'REMOVE',
          onClick: () => onItemRemove(item.id),
        },
      ],
    });
  };

  useEffect(() => {
    getData();
  }, []);

  if (fetch) return <Loading className='mt-5' />;

  return (
    <div>
      <Header />
      <div className='py-3 has-header'>
        <div className='col-12 d-flex flex-wrap'>
          {data &&
            data.map(item => {
              const isFDA = item.template === 'fda';
              const isASRT = item.template === 'asrt';
              return (
                <div className='certificate-item' key={item.id}>
                  <img src={certificateImage} alt='icon' />
                  <div className='hover-block px-3 py-2'>
                    {hasAccess('certificates_create') && (
                      <button
                        type='button'
                        className='btn btn-sm btn-blue mr-2 font-weight-bold list-remove-btn'
                        onClick={onItemEdit.bind(null, item.id)}
                      >
                        <IconEditButton />
                      </button>
                    )}
                    {!(isFDA || isASRT) && hasAccess('certificates_create') && (
                      <button
                        disabled={removeFetch}
                        onClick={onItemRemoveConfirm.bind(null, item)}
                        type='button'
                        className='btn btn-sm btn-red list-remove-btn'
                      >
                        <img width='15' src={removeIcon} alt='icon' />
                      </button>
                    )}
                  </div>
                  <div className='info d-flex flex-column justify-content-between'>
                    <div className='name text-truncate-3'>{item.name}</div>
                    <div className='d-flex justify-content-around mt-2'>
                      <span>
                        <i>Date:</i> {moment(item.createdAt).format('MM/DD/YYYY')}
                      </span>
                      <span>
                        <i>Usage:</i> {item.usedCourses}
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Certificates;
