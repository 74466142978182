import React from 'react';
import { Link } from 'react-router-dom';
import { IconSearch } from 'shared/components/Icons';
import { hasAccess } from 'utils/permissionHelper';

const CoursesHeader = ({ search, onSearch }) => {
  return (
    <div className='users-header d-flex justify-content-between align-items-center'>
      <div className='d-flex'>
        {hasAccess('courses_create') && (
          <Link className='btn btn-invite' to='/courses/new'>
            + Add New Course
          </Link>
        )}
      </div>
      <div className='d-flex'>
        <div className='search-block'>
          <input
            type='text'
            value={search}
            onChange={({ target }) => onSearch(target.value)}
            className='form-control'
          />
          <IconSearch />
        </div>
      </div>
    </div>
  );
};

export default CoursesHeader;
