/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import moment from 'moment';

import DateRangePickerFilter from '../DateRangePickerFilter';
import FilterDropDown from './components/FilterDropDown';
import FilterDropDownMultiselect from './components/FilterDropDownMultiselect';
import ExportTableData from '../ExportTableData';
import { IconClose } from '../Icons';

const AdvancedFilters = ({
  filters,
  onClearAll,
  withExport,
  downloadData,
  hideFilterDetails,
  encounterZero,
}) => {
  const activeFilters = filters.filter(
    i =>
      (i.type === 'dropdown' &&
        (i.value || (encounterZero && i.value === 0) || i.value === false)) ||
      (i.type === 'multiDropdown' && i.value.length) ||
      (i.type === 'date' && i.value && i.value.start && i.value.end),
  );

  const onMultiSelectChange = (id, item) => {
    const items = [...item.value];
    if (items.includes(id)) {
      items.splice(items.indexOf(id), 1);
    } else {
      items.push(id);
    }
    item.onFilterChange(item.name, items);
  };

  const onDateChange = (date, item) => {
    if (!date) return;
    item.onFilterChange(item.name, date);
  };

  const onRemoveMultiSingleItem = (item, row) => {
    const result = [...item.value].filter(i => i !== row.id);
    item.onFilterChange(item.name, result);
  };

  return (
    <div className='advanced-filters'>
      <div
        className={`d-flex justify-content-between ${!activeFilters?.length && 'bottom_bordered'}`}
      >
        <div className='d-flex align-items-center'>
          {filters.map((item, i) => {
            if (item.hide) return null;
            if (item.type === 'date' && item.value === 'null') {
              item.value = null;
            }
            return (
              <React.Fragment key={i}>
                {item.type === 'dropdown' && (
                  <FilterDropDown
                    className={`${item.className || 'mr-4 ml-1'} filter-drop-down`}
                    title={item.title}
                    name={item.name}
                    data={item.data}
                    showSearch={item.showSearch}
                    activeItemId={item.value}
                    onItemClick={id => item.onFilterChange(item.name, id)}
                  />
                )}
                {item.type === 'multiDropdown' && (
                  <FilterDropDownMultiselect
                    className={`${item.className || 'mr-4 ml-1'} filter-drop-down ${item.disabled &&
                      'global_disabled'}`}
                    title={item.title}
                    name={item.name}
                    forceLabel={item.forceLabel}
                    data={item.data}
                    withoutSearch={item.withoutSearch}
                    activeItems={item.value}
                    onItemClick={id => onMultiSelectChange(id, item)}
                  />
                )}
                {item.type === 'date' && (
                  <DateRangePickerFilter
                    label={item.title}
                    onChange={date => onDateChange(date, item)}
                    value={item.value}
                  />
                )}
              </React.Fragment>
            );
          })}
        </div>
        {withExport && <ExportTableData downloadData={downloadData} />}
      </div>
      {!!activeFilters?.length && !hideFilterDetails && (
        <div className='d-flex active-filters align-items-center bottom_bordered'>
          <p className='mb-0'>Selected Filters</p>
          <div className='d-flex align-items-center flex-wrap'>
            {activeFilters.map((item, i) => {
              const activeItem = item.data.find(d => d.id === item.value);
              if (item.hide) return null;
              return (
                <div key={i} className='active-filter-block d-flex align-items-center'>
                  <span>
                    {item.type === 'dropdown' && (
                      <span>
                        {item.title}: {activeItem?.name || activeItem?.title}
                      </span>
                    )}
                    {item.type === 'multiDropdown' && (
                      <span className='d-flex flex-wrap align-items-center'>
                        {item.title}:
                        {item.value.map(id => {
                          const row = item.data.find(i => i.id === id);
                          return (
                            <span
                              className={`ml-1 sub-filter d-flex align-items-center ${item.disabled &&
                                'global_disabled'}`}
                            >
                              <span
                                className='mr-1 pointer'
                                onClick={onRemoveMultiSingleItem.bind(null, item, row)}
                              >
                                <IconClose width='6' />
                              </span>
                              {row?.forceLabel || row?.name}
                            </span>
                          );
                        })}
                      </span>
                    )}
                    {item.type === 'date' && (
                      <span>
                        {item.title}: {moment(item.value.start).format('MM/DD/YYYY')} -{' '}
                        {moment(item.value.end).format('MM/DD/YYYY')}
                      </span>
                    )}
                  </span>
                  <button
                    className={`btn p-0 ml-1 mb-1 no-shadow ${item.disabled && 'global_disabled'}`}
                    onClick={() => {
                      if (item.type === 'dropdown') {
                        item.onFilterChange(item.name, '');
                      }
                      if (item.type === 'multiDropdown') {
                        item.onFilterChange(item.name, []);
                      }
                      if (item.type === 'date') {
                        item.onFilterChange(item.name, null);
                      }
                    }}
                  >
                    <IconClose width='6' />
                  </button>
                </div>
              );
            })}
            <div
              className={`active-filter-block d-flex align-items-center ${activeFilters.some(
                filter => filter.disabled,
              ) && 'global_disabled'}`}
            >
              <span className='fw-500'>Clear All</span>
              <button className='btn p-0 ml-1 mb-1 no-shadow' onClick={onClearAll}>
                <IconClose width='6' />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export const structureDropFilter = body => {
  const filter = JSON.parse(JSON.stringify(body));
  if (filter.date) {
    filter.startDate = moment(filter.date.start).format('MM.DD.YYYY');
    filter.endDate = moment(filter.date.end).format('MM.DD.YYYY');
    delete filter.date;
  }

  Object.keys(filter).forEach(key => {
    const value = filter[key];
    if (Array.isArray(value)) {
      filter[key] = value.join(',');
    }
  });
  return filter;
};

export default AdvancedFilters;
