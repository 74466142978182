import React, { useState, useEffect, useRef } from 'react';
import Slider from 'react-rangeslider';
import 'react-rangeslider/umd/rangeslider.min.css';
import { IconAiPlay, IconPlayFill, IconSliceNext, IconSlicePrev } from 'shared/components/Icons';
import Loading from 'shared/components/Loading';
import pause from 'assets/pause.svg';
import speedIcon from 'assets/speed.svg';
import { getImagesPrePostSlices, getImagesSortedSlices, getPrimarySlices } from './PrePostSlices';

const stackWords = {
  CC: { top: 'H', bottom: 'F' },
  MLO: { top: 'M', bottom: 'L' },
  SCC: { top: 'M', bottom: 'L' },
};

const speeds = [
  { name: 'Low', value: 160 },
  { name: 'Medium', value: 80 },
  { name: 'High', value: 40 },
];

const modes = [
  { name: 'Genius AI Mode', value: 'genius' },
  { name: 'Normal Mode', value: 'normal' },
];

const StackControl = ({
  cornerstoneTools,
  element,
  cornerstone,
  imageType,
  setActiveTool,
  className,
  images,
  play,
  setPlay,
  isGenuine,
  setSelectedPoint,
}) => {
  const stackEl = useRef();
  const [speed, setSpeed] = useState(80);
  const [mode, setMode] = useState('normal');
  const [fetch, setFetch] = useState(false);
  const isAIMode = mode === 'genius';

  const playIndex = useRef({ index: 0, increase: true, images: undefined });
  const scale = useRef(0);
  const playInterval = useRef();

  const primarySlices = isAIMode ? getPrimarySlices(images) : [];
  const allPrePostSlices = isAIMode ? getImagesPrePostSlices(images) : [];
  const allSortedSlices = isAIMode ? getImagesSortedSlices(images) : [];
  const hasNextSlice = primarySlices.indexOf(playIndex.current.index) < primarySlices.length - 1;
  const hasPrevSlice = primarySlices.indexOf(playIndex.current.index) !== 0;

  const getStackData = () => {
    const stackData = cornerstoneTools.getToolState(element, 'stack');
    const { currentImageIdIndex, imageIds } = stackData.data[0];
    return { currentImageIdIndex, imageIds };
  };

  const { imageIds } = getStackData();

  const changeHandle = index => {
    if (fetch || play) return;
    if (!playIndex.current.images) {
      loadAllImages();
      return;
    }
    playIndex.current.index = index;
    scale.current = index;
    changeStackImage(playIndex.current.images, index);
    setSelectedPoint({});
    const toolData = cornerstoneTools.getToolState(element, 'GenuineTool');

    if (toolData) {
      toolData.data.forEach(item => {
        item.isActive = false;
        if (item.handles && item.handles.end) {
          item.handles.end.isActive = false;
        }
      });
    }
  };

  const changeStackImage = (images, index) => {
    if (images[index] !== undefined) {
      const viewport = cornerstone.getViewport(element);
      const stackData = cornerstoneTools.getToolState(element, 'stack');
      stackData.data[0].currentImageIdIndex = Number(index);
      cornerstone.displayImage(element, images[index], viewport);
    }
  };

  const playStack = images => {
    try {
      let { index, increase } = playIndex.current;
      index += increase ? 1 : -1;
      if (!imageIds[index]) {
        increase = !increase;
        index += increase ? 2 : -2;
      }
      changeStackImage(images, index);
      playIndex.current.index = index;
      playIndex.current.increase = increase;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  };

  const playStackSlices = images => {
    try {
      let { index, increase } = playIndex.current;
      const activeSlice = allSortedSlices.findIndex(i => i.includes(index));
      const nearPrimary = activeSlice !== -1 ? allSortedSlices[activeSlice] : allSortedSlices[0];
      const sliceImages = [...(nearPrimary || [])].sort((a, b) => a - b);
      const currentIndex = sliceImages.indexOf(index);
      index = sliceImages[currentIndex === -1 ? 0 : currentIndex + (increase ? 1 : -1)];
      if (!imageIds[index]) {
        increase = !increase;
        index = sliceImages[currentIndex === -1 ? 0 : currentIndex + (increase ? 1 : -1)];
      }
      changeStackImage(images, index);
      playIndex.current.index = index;
      playIndex.current.increase = increase;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  };

  const setPlayInterval = (speed, changedToAIMode) => {
    if (changedToAIMode || isAIMode) {
      playInterval.current = setInterval(playStackSlices, speed, playIndex.current.images);
    } else {
      playInterval.current = setInterval(playStack, speed, playIndex.current.images);
    }
  };

  const loadAllImages = async play => {
    setFetch(true);
    if (!playIndex.current.images) {
      const { imageIds } = getStackData();
      const imageLoads = imageIds.map(async item => await cornerstone.loadAndCacheImage(item));
      const images = await Promise.all(imageLoads);
      playIndex.current.images = images;
    }
    setFetch(false);
    if (play) setPlayInterval(speed);
  };

  const onElementScroll = e => {
    e.preventDefault();

    if (!playIndex.current.images) {
      if (fetch || play) return;
      loadAllImages();
      return;
    }

    const { imageIds } = getStackData();
    scale.current += e.deltaY * -0.01;
    scale.current = Math.min(Math.max(0, scale.current), imageIds.length);

    if (playIndex.current.index !== Math.floor(scale.current)) {
      changeHandle(Math.floor(scale.current));
    }
  };

  const onChangeStart = () => {
    // setActiveTool({ type: 'remove' });
  };

  const handleUserKeyPress = event => {
    const { keyCode } = event;
    if (keyCode === 32)
      setPlay(p => {
        return !p;
      });
  };

  const stopPlayerUnmount = () => {
    window.removeEventListener('keydown', handleUserKeyPress);
    setPlay(false);
    clearInterval(playInterval.current);
    playIndex.current = { index: 0, increase: true, images: undefined };
  };

  const onSpeedChange = async value => {
    setSpeed(value);
    if (play) {
      clearInterval(playInterval.current);
      setPlayInterval(value);
    }
  };

  const onPlayerModeChanges = value => {
    setMode(value);
    if (play) {
      clearInterval(playInterval.current);
      setPlayInterval(speed, value === 'genius');
    }
  };

  const renderLines = length => {
    const lines = [];
    for (let index = length - 1; index >= 0; index--) {
      const hasGenuie = images[index].geniusAIDataList?.length > 0;
      const hasPrimarySlice =
        hasGenuie || (hasGenuie && (!!images[index].postSlices || images[index].preSlices));
      const hasPrePostSlice = !hasPrimarySlice && allPrePostSlices.includes(index);
      lines.push(
        <span
          key={index}
          className={`line ${hasGenuie ? 'has-point' : ''} ${
            hasPrimarySlice ? 'has-primary-slice' : ''
          } ${hasPrePostSlice ? 'has-prepost-slice' : ''}`}
        />,
      );
    }
    return lines;
  };

  const onMovePrimarySlice = isNext => {
    setPlay(false);
    const currentIndex = primarySlices.indexOf(playIndex.current.index);
    const newIndex = currentIndex === -1 ? 0 : currentIndex + (isNext ? 1 : -1);
    changeHandle(primarySlices[newIndex]);
  };

  useEffect(() => {
    if (play) {
      element.onwheel = null;
      loadAllImages(true);
    } else {
      scale.current = playIndex.current.index;
      element.onwheel = onElementScroll;
      clearInterval(playInterval.current);
    }
    //eslint-disable-next-line
  }, [play]);

  useEffect(() => {
    playIndex.current = { index: 0, increase: true, images: undefined };
    scale.current = 0;
    loadAllImages();
    window.addEventListener('keydown', handleUserKeyPress);
    setTimeout(() => {
      images.map(item => cornerstone.loadAndCacheImage(item.url));
    }, 100);
    stopPlayerUnmount();
    return () => {
      stopPlayerUnmount();
    };
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <div
        className={`stack-control ${fetch ? 'is-loading' : ''} ${
          isAIMode ? 'genius-ai-mode' : ''
        } ${className || ''}`}
        ref={stackEl}
      >
        <div className='stack-lines d-flex flex-column justify-content-around'>
          {renderLines(imageIds.length)}
        </div>
        <div className='settings-btn'>
          <img src={speedIcon} alt='speed' className='speed-icon' />
          <div className='settings-options d-flex align-items-center'>
            {speeds.map(item => (
              <button
                key={item.value}
                className={`btn btn-sm btn-block ${speed === item.value ? 'active' : ''}`}
                onClick={() => onSpeedChange(item.value)}
              >
                {item.name}
              </button>
            ))}
          </div>
        </div>
        <div className='settings-btn'>
          <button
            className='btn play-btn pb-1'
            disabled={fetch}
            onClick={e => {
              setPlay(p => !p);
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            {fetch ? (
              <Loading className={' '} classSpinner='spinner-border-sm text-white' />
            ) : play ? (
              <img className='mr-1' src={pause} alt='pause' width='15' />
            ) : (
              <IconPlayFill color='#fff' />
            )}
          </button>
          {!play && isGenuine && (
            <div className='settings-options pt-0 d-flex align-items-center'>
              {modes.map(item => (
                <button
                  key={item.value}
                  className={`btn btn-sm btn-block text-nowrap ${
                    mode === item.value ? 'active' : ''
                  }`}
                  onClick={() => onPlayerModeChanges(item.value)}
                >
                  {item.name}
                </button>
              ))}
            </div>
          )}
        </div>
        <div className='stack-word'>{stackWords[imageType]?.top}</div>
        <Slider
          min={0}
          max={imageIds.length - 1}
          value={Number(getStackData().currentImageIdIndex)}
          onChange={changeHandle}
          onChangeStart={onChangeStart}
          orientation='vertical'
          tooltip={false}
          handleLabel={`${getStackData().currentImageIdIndex + 1}`}
        />
        <div className='stack-word'>{stackWords[imageType]?.bottom}</div>
        <div className='stack-num'>
          {Number(getStackData().currentImageIdIndex) + 1}/{imageIds.length}
        </div>
        {isAIMode && (
          <div className='d-flex flex-column stack-movie-buttons'>
            <button
              className='btn stack-next'
              disabled={!primarySlices.length || !hasNextSlice || fetch}
              onClick={onMovePrimarySlice.bind(null, true)}
            >
              <IconSliceNext />
            </button>
            <button
              className='btn stack-prev'
              disabled={!primarySlices.length || !hasPrevSlice || fetch}
              onClick={onMovePrimarySlice.bind(null, false)}
            >
              <IconSlicePrev />
            </button>
          </div>
        )}
        <div className='stack-tooltip'>Buffering in Process</div>
      </div>
      {play && isAIMode && (
        <div className='align-items-center d-flex playing-mode-alert text-nowrap'>
          <IconAiPlay /> <span className='ml-2'>Genius AI Mode</span>
        </div>
      )}
    </>
  );
};

export default StackControl;
