import React from 'react';
import ClickArea from 'shared/components/ClickArea';
import Loading from 'shared/components/Loading';
import ListActionButtons from 'shared/components/ListActionButtons';
import { gantryModels, patientPositions, roomScanAngels } from '../utils';
import { hasAccess } from 'utils/permissionHelper';

const headers = [
  {
    name: 'Room Name',
    className: 'col-2',
    key: 'name',
  },
  {
    name: 'Patient Model',
    className: 'col-2',
    key: 'model',
  },
  {
    name: 'Gantry Model',
    className: 'col-2',
    key: 'gantryName',
  },
  {
    name: 'BiopsyUsage',
    className: 'col-2',
    key: 'biopsyUsage',
  },
  {
    name: 'Scan Angle',
    className: 'col',
    key: 'scanAngle',
  },
  {
    name: 'Actions',
    className: 'p-2 d-flex action-buttons-container text_start',
    key: '',
  },
];

const SubscriptionsLists = ({
  list,
  order,
  changeOrder,
  fetch,
  onItemRemoveDisabled,
  onItemRemoveConfirm,
  downloadData,
}) => {
  if (fetch) return <Loading className='mt-5' />;

  if (!list || !list.length)
    return <div className='text-black-50 text-center mt-4'>No Results Found</div>;

  const handleOrderChange = item => {
    if (!item.key) return;
    changeOrder({
      key: item.key,
      order: !order.order,
    });
  };

  const actions = [
    {
      type: 'link',
      icon: 'edit',
      title: 'Edit',
      to: item => `/room/${item.id}/edit`,
      hide: () => !hasAccess('room_360_create'),
    },
    {
      type: 'link',
      icon: 'copyAndCreate',
      title: 'Copy & Create',
      to: () => `/rooms/new`,
      hide: () => !hasAccess('room_360_create'),
    },
    {
      type: 'button',
      icon: 'delete',
      title: 'Remove',
      disabled: onItemRemoveDisabled,
      onClick: item => onItemRemoveConfirm(item),
      hide: () => !hasAccess('room_360_create'),
    },
  ];

  return (
    <div className='users-lists'>
      <ul className='p-0'>
        <li className='d-flex list-header align-items-center'>
          {headers.map((item, index) => (
            <ClickArea
              key={item.name + index}
              className={`text-body d-flex align-items-center ${!item.key &&
                'justify-content-end'} ${item.className}`}
              onClick={handleOrderChange.bind(null, item)}
            >
              {item.name}
              {item.key && order.orderKey === item.key && (
                <button
                  className={`btn ml-2 p-0 dropdown-toggle ${order.order ? '' : 'rotate-180'}`}
                />
              )}
            </ClickArea>
          ))}
        </li>
        {list &&
          list.map(item => {
            return (
              <li key={item.id} className='d-flex list-item h-not-set'>
                <div className='d-flex col-2 align-items-center'>{item.name}</div>
                <div className='d-flex col-2 align-items-center'>
                  {patientPositions[item.position]}
                </div>
                <div className='d-flex col-2 align-items-center'>
                  {gantryModels[item.gantryName]}
                </div>
                <div className='d-flex col-2 align-items-center'>
                  {!item?.biopsyUsage ? 'Yes' : 'No'}
                </div>
                <div className='d-flex col align-items-center'>
                  {roomScanAngels[item.scanAngle]}
                </div>
                <div className='p-2 d-flex align-items-center justify-content-start action-buttons-container'>
                  <ListActionButtons actions={actions} item={item} />
                </div>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default SubscriptionsLists;
