import React from 'react';
import { IconWarning } from './Icons';

const NoAccess = ({ className }) => {
  return (
    <>
      <div className='users-header d-flex justify-content-between align-items-center'>
        <div className='d-flex breadcrumbs' />
      </div>
      <div
        className={`no-access min-vh-100 d-flex flex-column justify-content-center align-items-center ${className ||
          ''}`}
      >
        <h2 className='d-flex align-items-center'>
          <IconWarning color='#E88989' width={24} height={24} /> Access Denied
        </h2>
        <p className='text-center'>
          You don’t have access to this content. Please, contact the sharing person <br /> directly
          to solve the issue.
        </p>
      </div>
    </>
  );
};

export default NoAccess;
