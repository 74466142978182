/* eslint-disable no-useless-computed-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Button from 'shared/components/Button';
import debounce, { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import { RegularInput } from 'shared/components/Input/RegularInput';
import { QuestionTypeLabel } from 'shared/components/QuestionTypeLabel';
import { EmptyQuizConfigView } from './components/EmptyQuizConfigView';
import { EmptyQuestionGroupView } from './components/EmptyQuestionGroupView';
import Loading from 'shared/components/Loading';
import { StepItem } from './components/StepItem';
import { addColorBasedOnKey } from 'utils/quizHelpers';
import { QuestionAccordion } from 'shared/components/QuestionAccordion';
import { formatDateForQuiz } from 'utils/formatHelpers';
import { hasAccess } from 'utils/permissionHelper';
import { RESPONSE_STATUS_CODES } from 'configs';
import { RadioTypeView } from './components/SelectedPreviews/RadioTypeView';
import { MultiTypeView } from './components/SelectedPreviews/MultiTypeView';
import { BooleanTypeView } from './components/SelectedPreviews/BooleanTypeView';
import { ImageTypeView } from './components/SelectedPreviews/ImageTypeView';
import NavigableHeaderRoutes from 'shared/components/NavigableHeaderRoutes';

// Icons
import {
  IconSquareEditBolded,
  PassThresholdIcon,
  QuizCalendarIcon,
  RemoveIcon,
  TablerBatteryIcon,
  TablerNumberIcon,
  TimeLimitIcon,
} from 'shared/components/Icons';

const QUIZ_PROPERTIES = [
  { icon: <QuizCalendarIcon />, title: 'Last Updated', value: null, key: 'createdAt' },
  { icon: <TablerBatteryIcon />, title: 'Number of Attempts', value: null, key: 'attempts' },
  { icon: <TablerNumberIcon />, title: 'Number of Questions', value: null, key: 'questions' },
  { icon: <PassThresholdIcon />, title: 'Pass Threshold', value: null, key: 'passingThreshold' },
  { icon: <TimeLimitIcon />, title: 'Time Limit', value: null, key: 'timeLimit' },
  { icon: <RemoveIcon />, title: 'What Did I Wrong', value: null, key: 'showWhatIsWrong' },
];

const Q_TYPES = [
  { type: 'multiSelect', title: 'Multi Select', value: 1 },
  { type: 'singleSelect', title: 'Single Select', value: 2 },
  { type: 'image', title: 'Image Categories', value: 4 },
  { type: 'boolean', title: 'Boolean', value: 3 },
];

const QuestionPreviewViewComponent = {
  radio: RadioTypeView,
  multi: MultiTypeView,
  yesno: BooleanTypeView,
  ['image-category']: ImageTypeView,
};

export const CourseQuizView = ({ match, history }) => {
  const courseId = match.params.id;
  const { enqueueSnackbar } = useSnackbar();

  const [course, setCourse] = useState({});
  const [quizProperties, setQuizProperties] = useState(QUIZ_PROPERTIES);
  const [quizData, setQuizData] = useState(null);
  const [questionGroups, setQuestionGroups] = useState([]);
  const [isFetchingQuizData, setIsFetchingQuizData] = useState(false);
  const [isFetchingQGroups, setIsFetchingQGroups] = useState(false);
  const [selectedStep, setSelectedStep] = useState(null);
  const [searchedQuestions, setSearchedQuestions] = useState([]);
  const [overQuestion, setOverQuestion] = useState(null);
  const [searchFilters, setSearchFilters] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [selectedQuestion, setSelectedQuestion] = useState(null);

  const allQuestions = questionGroups.map(({ questions }) => questions).flat();

  const headerRouteItems = [
    { label: 'Courses', path: '/courses/all' },
    { label: course.title, path: `/course/${courseId}/view` },
    { label: 'Quiz' },
  ];

  const getCourseData = async () => {
    try {
      const { data } = await Api.get(`/courses/getcourse/${courseId}`);
      setCourse(data.data);
    } catch (err) {
      history.push('/no-access');
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const getQuizData = async () => {
    setIsFetchingQuizData(true);
    try {
      const { data } = await Api.get(`/courses/${courseId}/quiz`);
      if (data.data) {
        setQuizData(data.data);
        setQuizProperties(prev => {
          return QUIZ_PROPERTIES.map(prop => {
            prop.value =
              prop.key === 'createdAt'
                ? formatDateForQuiz(data?.data?.[prop.key])
                : data?.data?.[prop.key];
            return prop;
          });
        });
      }
    } catch (err) {
      if (err?.response?.status === RESPONSE_STATUS_CODES.accessDenied) {
        history.push('/no-access');
        return;
      }
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
    setIsFetchingQuizData(false);
  };

  const getQuestionGroups = async () => {
    setIsFetchingQGroups(true);
    try {
      const { data } = await Api.get(`/courses/${courseId}/question-groups`);
      setQuestionGroups(addColorBasedOnKey(data.data.filter(({ id }) => id), 'name'));
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
    setIsFetchingQGroups(false);
  };

  const getSearchedQuestions = async (searchValue, questionTypes) => {
    try {
      const params = {
        search: searchValue,
        questionTypes: questionTypes && questionTypes.join(','),
      };
      const { data } = await Api.get(`/courses/${courseId}/question-groups/search`, { params });
      setSearchedQuestions(data.data.results);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const handleSelectStep = step => {
    let selectedQuestion;

    if (step.questionId) {
      selectedQuestion = allQuestions.find(quest => quest.id === step.questionId);
    } else {
      const selectedGroup = questionGroups.find(gr => gr.name === step.questionGroup);

      if (selectedGroup && selectedGroup.questions && selectedGroup.questions.length > 0) {
        const randomIndex = Math.floor(Math.random() * selectedGroup.questions.length);
        selectedQuestion = selectedGroup.questions[randomIndex];
      }
    }
    setSelectedQuestion(selectedQuestion);
    setSelectedStep(step);
  };

  const debouncedQuestionsSearch = useCallback(
    debounce(searchValue => {
      getSearchedQuestions(searchValue, searchFilters);
    }, 300),
    [],
  );

  const handleSearchQuestions = searchValue => {
    setSearchValue(searchValue);
    debouncedQuestionsSearch(searchValue);
  };

  const handleChangeSearchFilters = filterValue => {
    const updatedFilters = searchFilters.includes(filterValue)
      ? searchFilters.filter(fltVal => fltVal !== filterValue)
      : [...searchFilters, filterValue];
    setSearchFilters(updatedFilters);
    getSearchedQuestions(searchValue, updatedFilters);
  };

  useEffect(() => {
    getCourseData();
    getQuizData();
    getQuestionGroups();
    getSearchedQuestions();
  }, []);

  const QuestionPreview = QuestionPreviewViewComponent[(selectedQuestion?.type)];

  return (
    <>
      <div className='users-header d-flex justify-content-between align-items-center'>
        <NavigableHeaderRoutes items={headerRouteItems} />
      </div>
      <div className='quiz_page_container'>
        <div className='quiz_view_content_block'>
          <div className='left_content'>
            <h2 className='title'>Quiz for:"{course.title}"</h2>
            <div className='d-flex flex-column empty_quiz_block'>
              {isFetchingQGroups || isFetchingQuizData ? (
                <Loading className='position-relative' />
              ) : (
                <>
                  {quizData ? (
                    <div className='quiz_block'>
                      <h6>Quiz Flow</h6>
                      <div className='steps_block_container'>
                        <div className='steps_block custom_scrollbar_block'>
                          {quizData.steps.map((step, idx) => {
                            const stepGroup = questionGroups.find(
                              group => group.name === step.questionGroup,
                            );
                            step.color = stepGroup && stepGroup.color;
                            return (
                              <StepItem
                                handleSelect={handleSelectStep}
                                selectedStep={selectedStep}
                                step={step}
                                timeLimit={step.timeLimit}
                                key={idx}
                                index={idx}
                              />
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <EmptyQuizConfigView courseId={courseId} />
                  )}{' '}
                  {selectedStep && selectedQuestion && (
                    <div className='selected-question-preview'>
                      <h6>Selected Question Preview</h6>
                      <div className='question-container result-container'>
                        <div className='question-block'>
                          <div className='question-head'>
                            <span className='q-number'>{selectedStep?.step}</span>
                            <p className='q-description'>{selectedQuestion?.title}</p>
                            <span className='q-number-in-all'>
                              <span>{selectedStep?.step}</span>/{quizData?.steps?.length}
                            </span>
                          </div>
                          <div className='question-body'>
                            {selectedQuestion && <QuestionPreview question={selectedQuestion} />}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {questionGroups.length ? (
                    <div className='q_groups_content'>
                      <div className='groups_accordions_container'>
                        {questionGroups.map((group, gIdx) => {
                          return (
                            <div className='g_accordions_block' key={group.id}>
                              <div className='qg_title_row'>
                                <h6>
                                  Question Group {gIdx + 1}: <b>{group.name}</b>
                                </h6>
                                <Link
                                  to={{
                                    pathname: `/course/${courseId}/quiz/question-groups/config`,
                                    state: { selectedGroupId: group.id },
                                  }}
                                >
                                  <IconSquareEditBolded />
                                </Link>
                                {gIdx === 0 && hasAccess('quiz_create') && (
                                  <Link
                                    className='ml-auto'
                                    to={`/course/${courseId}/quiz/question-groups/config`}
                                  >
                                    <Button className='btn-blue'>Add New Group +</Button>
                                  </Link>
                                )}
                              </div>
                              {group.questions.slice(0, 3).map((question, qIdx) => {
                                return (
                                  <QuestionAccordion
                                    color={group.color}
                                    courseId={courseId}
                                    question={question}
                                    key={question.id}
                                    index={qIdx}
                                  />
                                );
                              })}
                              <div
                                style={{ borderColor: group.color }}
                                className={'show_more_block'}
                              >
                                <Link
                                  to={{
                                    pathname: `/course/${courseId}/quiz/question-groups`,
                                    state: { selectedGroupId: group.id },
                                  }}
                                >
                                  Show More
                                </Link>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ) : (
                    <EmptyQuestionGroupView course={course} />
                  )}
                </>
              )}
            </div>
          </div>
          <div className='right_content'>
            <div className='side_regular_block quiz_prop_block'>
              <h6 className='side_block_title'>Quiz Properties</h6>
              <div className='list_container'>
                {quizProperties.map((prop, idx) => (
                  <div key={idx} className='prop_list_item'>
                    {prop.icon}
                    <span>{prop.title}</span>
                    <span className='prop_value'>
                      {prop.value || '-'}
                      {prop.title === 'Time Limit' && !!prop.value && ' min'}
                    </span>
                  </div>
                ))}
              </div>
            </div>
            {!quizData && hasAccess('quiz_create') && (
              <Link to={`/course/${courseId}/quiz-config`}>
                <Button className='btn btn-primary w-100 fz-12'>Add Quiz</Button>
              </Link>
            )}
            {!!quizData && (
              <div className='d-flex flex-column quiz_prop_buttons'>
                <div className='first_row d-flex'>
                  {hasAccess('quiz_create') && (
                    <Link className='w-50' to={`/course/${courseId}/quiz-config`}>
                      <Button className='btn-blue w-100 fz-12'>Edit</Button>
                    </Link>
                  )}
                  <Link
                    className={`${hasAccess('quiz_create') ? 'w-50' : 'w-100'} global_disabled`} // Temporary disabled globally
                    to={`/course/${courseId}/quiz-summary`}
                  >
                    <Button className='btn-blue w-100 fz-12'>Summary</Button>
                  </Link>
                </div>
                <Link to={`/course/${courseId}/quiz/question-groups`}>
                  <Button className='btn-blue w-100 fz-12'>Question Groups</Button>
                </Link>
              </div>
            )}
            <div
              className={`side_regular_block search_block ${!questionGroups.length &&
                'global_disabled'}`}
            >
              <h6 className='side_block_title left_m_4'>Search in all question groups</h6>
              <RegularInput
                value={searchValue}
                handleChange={({ target }) => handleSearchQuestions(target.value)}
                withSearchIcon
                placeholder='Search in question titles'
              />
              <div className='q_groups_block'>
                {Q_TYPES.map((q, idx) => (
                  <QuestionTypeLabel
                    isSelected={searchFilters.includes(q.value)}
                    onClick={() => handleChangeSearchFilters(q.value)}
                    key={idx}
                    title={q.title}
                  />
                ))}
              </div>
            </div>
            <div
              className={`right_side_questions_block ${!questionGroups.length &&
                'global_disabled'}`}
            >
              {searchedQuestions.map((question, idx) => {
                const currentQuestGroup = questionGroups.find(
                  group => group.id === question.groupId,
                );
                question.color = currentQuestGroup && currentQuestGroup.color;
                return (
                  <Link
                    key={idx}
                    className='color_unset no_hover_styles'
                    to={{
                      pathname: `/course/${courseId}/quiz/question-groups`,
                      state: { selectedQuestion: question },
                    }}
                  >
                    <div
                      style={{
                        borderColor:
                          overQuestion?.questionId === question.questionId
                            ? question.color
                            : 'transparent',
                      }}
                      onMouseOver={() => setOverQuestion(question)}
                      onMouseLeave={() => setOverQuestion(null)}
                      className={`row_item in_quiz_view`}
                      key={idx}
                    >
                      <span className='q_order'>{idx + 1}</span>
                      <span className='q_title'>{question.questionTitle}</span>
                    </div>
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
