import React, { Fragment } from 'react';
import { BIRADSIcon } from 'shared/components/Icons';

const BiradsModal = ({ hasBIRADS, sections, caseId }) => {
  const isEmptyBirads = !hasBIRADS || !sections?.length;

  return (
    <div className={`birads_preview_container ${isEmptyBirads && 'empty'}`}>
      {isEmptyBirads ? (
        <>
          <BIRADSIcon width='40' height='auto' />
          No Birads to Show
        </>
      ) : (
        <div className='content'>
          <h3 className='general_text'>PRIMARY FINDING for CASE : {caseId}</h3>
          <div className='description-container'>
            {sections?.length &&
              sections.map((item, i) => {
                return (
                  <Fragment key={i}>
                    <p className='m-0 mb-1 answer_title'>{item.title}</p>
                    <ul className='mb-4 pl-0'>
                      {item.descriptions &&
                        item.descriptions.map((desc, i) => {
                          return (
                            <Fragment key={i}>
                              {!!desc && <li className='description'>{desc}</li>}
                            </Fragment>
                          );
                        })}
                    </ul>
                  </Fragment>
                );
              })}
          </div>
        </div>
      )}
    </div>
  );
};

export default BiradsModal;
