export const headers = [
  {
    name: 'License Key',
    className: 'col',
    key: 'licenseKey',
  },
  {
    name: 'Entity',
    className: 'col',
    key: 'entity',
  },
  {
    name: 'Subscription Plan',
    className: 'col',
    key: 'subscription',
  },
  {
    name: 'Validity Period',
    className: 'col',
    key: 'startDate',
  },
  {
    name: 'Max. Assign',
    className: 'col',
    key: 'numOfSits',
  },
  {
    name: 'Used',
    className: 'col',
    key: 'used',
  },
  {
    name: 'Actions',
    className: 'd-flex align-items-center action-buttons-container p-2',
    key: 'actions',
  },
];
