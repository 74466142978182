import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { hasAccess } from 'utils/permissionHelper';

const filters = [{ name: 'All Certificates', path: '/certificates' }];

const Header = () => {
  return (
    <div className='users-header d-flex justify-content-between align-items-center'>
      <div className='d-flex'>
        {hasAccess('certificates_create') && (
          <Link className='btn btn-invite' to='/certificate/new'>
            + Add New Certificate
          </Link>
        )}
        <div className='align-items-center d-flex mx-3 filters'>
          {filters.map(item => (
            <NavLink key={item.path} className='mx-3' to={item.path} activeClassName='active'>
              {item.name}
            </NavLink>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Header;
