import React from 'react';

import { formatDate } from 'utils/appHelpers';

import ClickArea from 'shared/components/ClickArea';
import ListActionButtons from 'shared/components/ListActionButtons';
import Loading from 'shared/components/Loading';
import { hasAccess } from 'utils/permissionHelper';

const headers = [
  {
    name: 'Region',
    className: 'col',
    key: 'name',
  },
  {
    name: 'Countries',
    className: 'col',
    key: 'countries',
  },
  {
    name: 'Updated',
    className: 'col',
    key: 'updatedAt',
  },
  {
    name: 'Actions',
    className: 'p-2 justify-content-start d-flex action-buttons-container',
    key: '',
  },
];

const Lists = ({
  data,
  order,
  changeOrder,
  fetch,
  onItemRemoveDisabled,
  onItemRemoveConfirm = () => {},
}) => {
  if (fetch) return <Loading className='mt-5' />;
  if (!data || !data.length)
    return <div className='text-black-50 text-center mt-4'>No Results Found</div>;

  const actions = [
    {
      type: 'link',
      icon: 'edit',
      title: 'Edit',
      to: item => `/system/regions/${item.id}/edit`,
      hide: () => !hasAccess('system_regions_create'),
    },
    { type: 'link', icon: 'view', title: 'View', to: item => `/system/regions/${item.id}/view` },
    {
      type: 'button',
      icon: 'delete',
      title: 'Delete',
      disabled: onItemRemoveDisabled,
      onClick: item => onItemRemoveConfirm(item),
      hide: () => !hasAccess('system_regions_create'),
    },
  ];

  const handleOrderChange = item => {
    if (!item.key) return;
    changeOrder({
      orderKey: item.key,
      order: !order.order,
    });
  };

  return (
    <div className='users-lists'>
      <ul className='p-0'>
        <li className='d-flex list-header align-items-center'>
          {headers.map((item, index) => (
            <ClickArea
              key={item.name + index}
              className={`d-flex text-body justify-content-between align-items-center ${
                item.className
              }`}
              onClick={handleOrderChange.bind(null, item)}
            >
              {item.name}
              {item.key && order.orderKey === item.key && (
                <button
                  className={`btn ml-2 p-0 dropdown-toggle ${order.order ? '' : 'rotate-180'}`}
                />
              )}
            </ClickArea>
          ))}
        </li>
        {data &&
          data.map((item, index) => {
            return (
              <li key={index} className='d-flex align-items-center list-item h-not-set'>
                <div className='col text-truncate' title={item.name}>
                  {item.name}
                </div>
                <div className='col text-truncate'>{item.countries.length}</div>
                <div className='col'>{formatDate(item.updatedAt)}</div>
                <div className='p-2 justify-content-start align-items-center d-flex action-buttons-container'>
                  <ListActionButtons actions={actions} item={item} />
                </div>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default Lists;
