/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';

export const QuestionTypeLabel = ({ title, isSelected, onClick = () => {} }) => {
  return (
    <div onClick={onClick} className={`question_type_label ${isSelected && 'selected'}`}>
      {title}
    </div>
  );
};
