import React from 'react';
import { Link } from 'react-router-dom';
import { hasAccess } from 'utils/permissionHelper';
import { IconSearch } from 'shared/components/Icons';

const Header = ({ onSearch, noSearch = false }) => {
  const hasCreateAccess = hasAccess('system_regions_create');

  return (
    <div className='users-header d-flex justify-content-between align-items-center'>
      <div className='d-flex'>
        {!!hasCreateAccess && (
          <Link className='btn btn-invite fw-600' to='/system/regions/new'>
            + Add New Region
          </Link>
        )}
      </div>
      {!noSearch && (
        <div className='d-flex'>
          <div className='search-block'>
            <input
              type='text'
              onChange={({ target }) => onSearch(target.value)}
              className='form-control'
            />
            <IconSearch />
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
