import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const colorMap = {
  1: '#131F6B',
  2: '#131F6B',
  3: '#6BCC71',
};

const useStyles = makeStyles({
  root: {
    height: 7,
    borderRadius: 5,
  },
  colorPrimary: {
    borderRadius: 5,
    backgroundColor: 'transparent',
    width: '100%',
    outline: '6px solid #fff',
  },
  barColorPrimary: {
    borderRadius: 5,
    backgroundColor: props => colorMap[props.status],
  },
});

const LinearStatusProgress = props => {
  const { progress, status } = props;
  const classes = useStyles(props);

  return (
    <LinearProgress
      classes={{
        root: classes.root,
        colorPrimary: classes.colorPrimary,
        barColorPrimary: classes.barColorPrimary,
      }}
      variant='determinate'
      value={progress}
    />
  );
};

export default LinearStatusProgress;
