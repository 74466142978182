/* eslint-disable jsx-a11y/no-static-element-interactions */
import moment from 'moment';
import React from 'react';
import { IconCheckBold } from 'shared/components/Icons';

const InfoIcon = ({ name, value, className }) => (
  <p className={`m-0 d-flex ${className || ''}`}>
    {name}: <b>{value}</b>
  </p>
);

const EventProgramDateBlock = ({ data, activeItem, onClickItem, isCompletedItem }) => {
  const sorted = data.sort((a, b) => new Date(a) - new Date(b));
  return (
    <div className='cases-block d-flex flex-wrap'>
      {sorted &&
        sorted.map((item, index) => {
          const isActive = activeItem === item;
          const isCompleted = isCompletedItem && isCompletedItem(item);
          return (
            <div
              onClick={() => onClickItem(item)}
              className={`case-block d-flex flex-column justify-content-between ${
                isActive ? 'active' : ''
              }`}
              key={index}
            >
              <div className='d-flex justify-content-between align-items-center'>
                <h4 className='mb-0'>{moment(item).format('MMM D')}</h4>
                {isCompleted && <IconCheckBold height='12' width='15' />}
              </div>
              <div className='d-flex justify-content-between'>
                <InfoIcon name='Y' value={moment(item).format('YYYY')} />
                <InfoIcon name='D' className='ml-2' value={moment(item).format('dd')} />
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default EventProgramDateBlock;
